import { t } from "@lingui/macro";
import * as React from "react";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";

import { GetTalentProfile } from "../../../../actionTypes/talentProfile";
import { useApiSelector } from "../../../../components/Utils/hook";
import LoaderComp from "../../../../components/Utils/Loader";
import { ApiRequest } from "../../../../interfaces/api/request";
import { State } from "../../../../interfaces/state";

import styles from "./index.module.scss";

export const ContextItem: React.FC = () => {
  const { apiPendingRequests } = useApiSelector();
  const talentProfile = useSelector((state: State) => state.talentProfile);
  const loading = apiPendingRequests.some((req: ApiRequest) => [GetTalentProfile.REQUEST].includes(req.type));

  return (
    <div className={styles.card}>
      {loading || !talentProfile ? (
        <div>
          <LoaderComp />
        </div>
      ) : (
        <div>
          <div className={styles.cardHeader}>
            <h2 className={styles.title}>
              <ReactMarkdown
                components={{
                  p: React.Fragment,
                }}
              >{t`ContextItem.title`}</ReactMarkdown>
            </h2>
          </div>
          <div className={styles.cardBody}>
            {!!talentProfile.context ? talentProfile.context : t`ContextItem.text.missing`}
          </div>
        </div>
      )}
    </div>
  );
};
