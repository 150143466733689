import { CoreUser } from "../../interfaces/resources/coreUser";
import { DesiredPlace } from "../../interfaces/resources/desiredPlace";
import { Qualification } from "../../interfaces/resources/qualification";
import { Responsibility } from "../../interfaces/resources/responsibility";
import { Talent, QualificationFormProps } from "../../interfaces/resources/talent";
import { TalentProfile } from "../../interfaces/resources/talentProfile";

export function getInitialValues(
  coreUser: CoreUser | null,
  talent: Talent | null,
  talentProfile: TalentProfile | null,
  talentQualifications: Qualification[],
  qualificationIri: string,
): QualificationFormProps {
  let desiredPlaces: DesiredPlace[];
  let responsibilities: Responsibility[];
  let coreResponsibility: Responsibility | undefined;
  let notes: string | undefined;
  // RESPONSIBILITY PATCH
  if (talentProfile) {
    const tpResp = [...talentProfile.responsibilities];
    desiredPlaces = talentProfile.desiredPlaces;
    responsibilities = tpResp.map((r) => r.responsibility);
    coreResponsibility = talentProfile.coreResponsibility;
  } else {
    desiredPlaces = [];
    responsibilities = [];
    coreResponsibility = undefined;
    notes = undefined;
  }
  let qualification: any;
  if (talentQualifications && talentQualifications.length && talentQualifications[0]["@id"] === undefined) {
    qualification = talentQualifications[0];
  } else {
    qualification = talentQualifications.find((q) => (qualificationIri ? q["@id"] === qualificationIri : false));
  }

  return {
    "@id": talent ? talent["@id"] : "",
    qualification: qualification
      ? {
          ...qualification,
          coreUser: coreUser ? coreUser["@id"] : "",
          talentProfile: talentProfile ? talentProfile["@id"] : "",
          actualRemuneration: {
            fixedSalary: Number(
              qualification.actualRemuneration?.fixedSalary ? qualification.actualRemuneration.fixedSalary : 0,
            ),
            fullPackage: Number(
              qualification.actualRemuneration?.fullPackage ? qualification.actualRemuneration.fullPackage : 0,
            ),
          },
          expectedRemuneration: {
            fixedSalary: Number(
              qualification.expectedRemuneration?.fixedSalary ? qualification.expectedRemuneration.fixedSalary : 0,
            ),
            fullPackage: Number(
              qualification.expectedRemuneration?.fullPackage ? qualification.expectedRemuneration.fullPackage : 0,
            ),
          },
          bridges: qualification.bridges ? qualification.bridges : "",
          conclusion: qualification.conclusion ? qualification.conclusion : "",
          context: qualification.context ? qualification.context : "",
          storytelling: qualification.storytelling ? qualification.storytelling : "",
        }
      : {
          context: talentProfile ? talentProfile.context : "",
          ongoingProcesses: "",
          storytelling: "",
          actualRemuneration: {
            fixedSalary: 0,
            fullPackage: 0,
          },
          expectedRemuneration: {
            fixedSalary: talentProfile?.expectedRemuneration?.fixedSalary || 0,
            fullPackage: talentProfile?.expectedRemuneration?.fullPackage || 0,
          },
          applications: [],
          availabilityComplements: "",
          bridges: "",
          coreUser: coreUser ? coreUser["@id"] : "",
          idealJob: "",
          priorNotice: "",
          pitchedOffer: "",
          salaryComplements: "",
          startupMindset: "",
          strategy: "",
          talentProfile: talentProfile ? talentProfile["@id"] : "",
          timOpinion: "",
        },
    talentProfileRem: [
      talentProfile?.expectedRemuneration?.fixedSalary || 33,
      talentProfile?.expectedRemuneration?.fullPackage || 40,
    ],
    talentProfile: {
      ...talentProfile,
      "@id": talentProfile ? talentProfile["@id"] : "",
      desiredPlaces,
      responsibilities,
      coreResponsibility,
    },
    notes: notes,
  };
}
