export interface Sector {
  "@id": string;
  "@type": string;
  value: string;
  enabled: boolean;
  sectorType: SectorType;
}

export interface SectorType {
  "@id": string;
  "@type": string;
  id: number;
  value: string;
}

interface SectorOption {
  label: string;
  options: Sector[];
}

export const formatSectors = (sectors: Sector[]): SectorOption[] => {
  return sectors.reduce((result: SectorOption[], item: Sector) => {
    const sectorType = item.sectorType;
    if (result.some((e) => e.label === sectorType.value)) {
      result.forEach((e) => {
        if (e.label === sectorType.value && item.enabled === true) {
          e.options.push(item);
        }
      });
    } else {
      result.push({
        label: sectorType.value,
        options: [item],
      });
    }
    return result;
  }, []);
};
