import { t } from "@lingui/macro";
import cx from "classnames";
import * as React from "react";
import { FC } from "react";
import { useSelector } from "react-redux";
import { CallPendingTalents } from "../../components/CallPendingTalents";
import { MyOffers } from "../../components/MyOffers";
import { MyTalents } from "../../components/MyTalents";
import { NewSearchingTalents } from "../../components/NewSearchingTalents";
import { NoTimTalents } from "../../components/NoTimTalents";
import { coreUserRoles } from "../../interfaces/resources/coreUser";
import { State } from "../../interfaces/state";
import globalStyles from "../../styles/global.module.scss";
import styles from "./index.module.scss";

export const MyDashboardPage: FC = () => {
  const currentUser = useSelector((state: State) => state.currentUser);
  const isAm = currentUser?.roles.includes(coreUserRoles.am);
  const isTim = currentUser?.roles.includes(coreUserRoles.tim);
  const [missingCalendly, setMissingCalendly] = React.useState(false);
  React.useEffect(() => {
    if (!currentUser) {
      return;
    }
    setMissingCalendly(!!!currentUser.calendlyLink10 || !!!currentUser.calendlyLink20 || !!!currentUser.calendlyLink30);
  }, [currentUser]);

  return (
    <div className={styles.myTalentsPage}>
      <div className={globalStyles.mainTitleContainer}>
        <h1 className={globalStyles.mainTitle}>Mon tableau de bord</h1>
      </div>
      {missingCalendly ? (
        <div className={styles.warningContainer}>Il manque un ou plusieurs lien(s) calendly dans ton profil !</div>
      ) : null}
      <div className={styles.grid}>
        <div className={cx(styles.gridBlock, styles.block1)}>
          {isAm ? (
            <div className={styles.blockContent}>
              <MyOffers
                title={
                  <div className={styles.blockTitleContainer}>
                    <h2 className={styles.blockTitle}>{t`MyOffers.title`}</h2>
                  </div>
                }
              />
            </div>
          ) : null}
          {isTim ? (
            <div className={styles.blockContent}>
              <MyTalents
                title={
                  <div className={styles.blockTitleContainer}>
                    <h2 className={styles.blockTitle}>{t`MyTalents.title`}</h2>
                  </div>
                }
              />
            </div>
          ) : null}
        </div>
        <div className={cx(styles.gridBlock, styles.block2)}>
          <div className={cx(styles.blockContent)}>
            <CallPendingTalents />
          </div>
        </div>
        <div className={cx(styles.gridBlock, styles.block3)}>
          <div className={cx(styles.blockContent, styles.newTalentsBlock)}>
            <NewSearchingTalents
              isAm={isAm}
              title={
                <div className={styles.blockTitleContainer}>
                  <h2 className={styles.blockTitle}>
                    {isAm
                      ? "Les nouveaux Active Performer en recherche"
                      : "Tes nouveaux Active Performer et Active Neutral en recherche"}
                  </h2>
                </div>
              }
            />
          </div>
        </div>
        <div className={cx(styles.gridBlock, styles.block4)}>
          <div className={cx(styles.blockContent, styles.newTalentsBlock)}>
            <NoTimTalents
              title={
                <div className={styles.blockTitleContainer}>
                  <h1 className={styles.blockTitle}>Les Active Performer sans TIM</h1>
                </div>
              }
            />
          </div>
        </div>
        {isTim ? (
          <div className={cx(styles.gridBlock, styles.block5)}>
            <div className={cx(styles.blockContent, styles.newTalentsBlock)}>
              <NoTimTalents
                mode="inactive"
                title={
                  <div className={styles.blockTitleContainer}>
                    <h1 className={styles.blockTitle}>Les Inactive Performer et Inactive Neutral sans TIM</h1>
                  </div>
                }
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
