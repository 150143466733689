import { DesiredPlace } from "./desiredPlace";
import { ExperienceYears } from "./experienceYears";
import { Qualification } from "./qualification";
import { Remuneration } from "./remuneration";
import { Responsibility, TalentProfileResponsibility } from "./responsibility";
import { SalaryExpectations } from "./salaryExpectations";

export interface TalentProfile {
  "@id": string;
  context: string;
  coreResponsibility: Responsibility;
  currentJob?: string;
  currentCompany?: string;
  desiredPlaces: DesiredPlace[];
  expectedRemuneration?: Remuneration;
  experience: number;
  experienceYears: ExperienceYears;
  golden?: boolean;
  hubspotId?: string | null;
  lastQualification: Qualification | null;
  note: string | null;
  noQualifContacted?: boolean | null;
  qualifications: string[];
  responsibilities: TalentProfileResponsibility[];
  salaryExpectations: SalaryExpectations[];
  storyTelling: string;
  updatedAt?: string;
  lastTalentProfileEvent?: TalentProfileEvent | null;
  lastQualificationDate?: string;
  persona: string;
}

export interface TalentProfileEvent {
  "@id": string;
  createdAt: string;
  talentProfile: string;
}

export const personaStatuses = {
  offMarket: "off_market",
  activePerformer: "active_performer",
  activeNeutral: "active_neutral",
  activeDisoriented: "active_disoriented",
  inactivePerformer: "inactive_performer",
  inactiveNeutral: "inactive_neutral",
  inactiveDisoriented: "inactive_disoriented",
};
