import { LOCATION_CHANGE } from "redux-first-history";
import { CreateOfferTalentView, EditOfferTalentView, GetOfferTalentView } from "../../../../actionTypes/offers";

import { OfferTalentView } from "../../../../interfaces/resources/offer";
import { routes } from "../../../../routes";

export function offerTalentView(state: OfferTalentView | null = null, action: any): OfferTalentView | null {
  switch (action.type) {
    case CreateOfferTalentView.SUCCESS:
    case EditOfferTalentView.SUCCESS:
    case GetOfferTalentView.SUCCESS:
      return action.offerTalentView;
    case LOCATION_CHANGE:
      return [routes.offers.new].includes(action.payload.location.pathname) ? null : state;
    default:
      return state;
  }
}
