import { push, RouterRootState } from "connected-react-router";
import { parse } from "query-string";
import { AnyAction } from "redux";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  ApiAuthenticationLogin,
  ApiAuthenticationLogout,
  ApiAuthenticationRefresh,
  SwitchOn,
} from "../../../actionTypes/authentication";
import { GetCurrentUser } from "../../../actionTypes/coreUsers";

import { routes } from "../../../routes";
import { apiFailureAction } from "../actions";
import { getSuccessType, removeTokens, setTokens } from "../helpers";
import { loginLocationAction } from "./actions";
import * as apiClient from "./api";

export const getRouterState = (state: RouterRootState) => state.router;

export function* login(action: AnyAction): any {
  try {
    const response = yield call(apiClient.login, action.token);
    setTokens(response.data);
    const routerState: any = yield select(getRouterState);
    yield put(loginLocationAction(routerState.location.search));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* loginSuccess(action: AnyAction) {
  const searchParams: { next?: string } = parse(action.locationSearch);
  yield put(push(searchParams.next ? searchParams.next : routes.app.home));
}

export function* switchOn(action: AnyAction): any {
  try {
    const response = yield call(apiClient.switchOn, action.iri);
    setTokens(response.data, "switch");
    yield put({
      type: SwitchOn.SUCCESS,
    });
    yield put({
      type: GetCurrentUser.REQUEST,
    });
    put(push(routes.app.home));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* refresh(action: AnyAction): any {
  try {
    const response = yield call(apiClient.refresh, action.refreshToken);
    setTokens(response.data);
    push(routes.app.home);
    yield put({
      type: ApiAuthenticationRefresh.SUCCESS,
    });
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* logout(action: AnyAction) {
  try {
    yield call(apiClient.logout);
    yield put({
      type: getSuccessType(action),
    });
    removeTokens();
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* logoutSuccess(): any {
  const routerState: any = yield select(getRouterState);
  if (routerState.location.pathname !== routes.auth.login) {
    yield put(push(routes.auth.login));
  }
}

export default function* authenticationSaga() {
  yield takeLatest(ApiAuthenticationLogin.REQUEST, login);
  yield takeLatest(ApiAuthenticationLogout.REQUEST, logout);
  yield takeLatest(ApiAuthenticationRefresh.REQUEST, refresh);
  yield takeLatest(ApiAuthenticationLogin.SUCCESS, loginSuccess);
  yield takeLatest(ApiAuthenticationLogout.SUCCESS, logoutSuccess);
  yield takeLatest(SwitchOn.REQUEST, switchOn);
}
