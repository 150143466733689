import * as React from "react";
import PhoneInput from "react-phone-number-input";
import fr from "react-phone-number-input/locale/fr.json";
import "react-phone-number-input/style.css";
import { WrappedFieldInputProps, WrappedFieldMetaProps } from "redux-form";
import { Form } from "semantic-ui-react";

import "./index.scss";

interface OwnPhoneInputProps {
  error?: boolean;
  input: WrappedFieldInputProps;
  label?: string;
  meta: WrappedFieldMetaProps;
  placeholder?: string;
  required?: boolean;
}

const Phone = ({
  error,
  input: { name, onChange, value },
  label,
  placeholder,
  required,
  ...rest
}: OwnPhoneInputProps) => (
  <Form.Field error={!!error} name={name} required={required}>
    {label ? <label>{label}</label> : null}
    <PhoneInput {...rest} placeholder={placeholder} value={value} onChange={onChange} labels={fr} country="FR" />
  </Form.Field>
);

export default Phone;
