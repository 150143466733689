import { ActionType } from "../interfaces/api/actionType";

export const CreateCompanyImage: ActionType = {
  FAILURE: "CREATE_COMPANY_IMAGE_FAILURE",
  REQUEST: "CREATE_COMPANY_IMAGE_REQUEST",
  SUCCESS: "CREATE_COMPANY_IMAGE_SUCCESS",
};

export const DeleteCompanyImage: ActionType = {
  FAILURE: "DELETE_COMPANY_IMAGE_FAILURE",
  REQUEST: "DELETE_COMPANY_IMAGE_REQUEST",
  SUCCESS: "DELETE_COMPANY_IMAGE_SUCCESS",
};

export const GetCompanyCompanyImages: ActionType = {
  FAILURE: "GET_COMPANY_COMPANY_IMAGES_FAILURE",
  REQUEST: "GET_COMPANY_COMPANY_IMAGES_REQUEST",
  SUCCESS: "GET_COMPANY_COMPANY_IMAGES_SUCCESS",
};

export const GetCompanyImage: ActionType = {
  FAILURE: "GET_COMPANY_IMAGE_FAILURE",
  REQUEST: "GET_COMPANY_IMAGE_REQUEST",
  SUCCESS: "GET_COMPANY_IMAGE_SUCCESS",
};
