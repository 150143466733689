import { t } from "@lingui/macro";
import * as React from "react";
import Select from "react-select";
import { Accordion, Icon } from "semantic-ui-react";

import { GetResponsibilities } from "../../../actionTypes/responsibilities";
import { ApiFilter } from "../../../interfaces/api/filter";
import { ApiRequest } from "../../../interfaces/api/request";
import { Responsibility, formatResponsibilities } from "../../../interfaces/resources/responsibility";

import { defaultStyles } from "../../Form/Select";

interface Props extends ApiFilter<Responsibility> {
  activeIndexes?: number[];
  apiPendingRequests: ApiRequest[];
  handleClick?: (e: any, titleProps: any) => void;
  index?: number;
}

export const ResponsibilitiesFilter: React.FC<Props> = ({
  activeIndexes,
  apiPendingRequests,
  handleClick,
  index,
  items,
  onChange,
  selected,
}: Props) => {
  const active = activeIndexes ? activeIndexes.some((item: number) => item === index) : false;
  return (
    <div>
      <Accordion.Title active={active} index={index} onClick={handleClick}>
        <h3>
          {t`ResponsibilitiesFilter.title`}
          <Icon name={active ? "chevron up" : "chevron down"} />
        </h3>
      </Accordion.Title>
      <Accordion.Content active={active}>
        <Select
          getOptionLabel={(option: Responsibility): string => option.value}
          getOptionValue={(option: Responsibility): string => option["@id"]}
          isLoading={apiPendingRequests.some((item: ApiRequest) => GetResponsibilities.REQUEST === item.type)}
          isMulti={true}
          onChange={(options: any) =>
            onChange(
              options.map((item: any) => {
                return {
                  value: item["@id"],
                };
              }),
            )
          }
          options={formatResponsibilities(items)}
          placeholder={t`ResponsibilitiesFilter.placeholder`}
          styles={defaultStyles}
          value={selected}
        />
      </Accordion.Content>
    </div>
  );
};
