import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CompanyDetails } from "../../blocks/companyDetails";
import { CompanyInfo } from "../../blocks/companyInfo";
import LoaderComp from "../../components/Utils/Loader";

import { State } from "../../interfaces/state";
import { getCompanyAction } from "../../services/api/company/actions";
import { generateIriFromId } from "../../services/api/helpers";
import styles from "./index.module.scss";

export const CompanyPage: React.FC = () => {
  const params: any = useParams();
  const dispatch = useDispatch();
  const company = useSelector((state: State) => state.company);

  React.useEffect(() => {
    // get the application from id in params
    const iri = generateIriFromId("companies", params.id);
    if (params && company?.["@id"] !== iri) {
      dispatch(getCompanyAction(iri));
    }
  }, [params, dispatch, company]);

  if (!company) {
    return <LoaderComp />;
  }

  return (
    <div>
      <div className={styles.container}>
        <CompanyInfo />
        <CompanyDetails />
      </div>
    </div>
  );
};
