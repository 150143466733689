import React, { FC, useContext } from "react";
import Select from "react-select";
import DataExportContext from "../common/DataExportContext";

const FormatSelect: FC = () => {
  const {
    state: { format, allowedFormats },
    onFormatChange,
  } = useContext(DataExportContext);
  const options = allowedFormats.map((type) => ({ label: type.toUpperCase(), value: type }));

  const handleChange = (option: typeof options[-1] | undefined | null) => {
    if (!option || !option.value) {
      return;
    }
    onFormatChange(option.value);
  };

  const value = options.find((opt) => opt.value === format);

  return (
    <Select
      isMulti={false}
      isSearchable={true}
      onChange={handleChange as Select["onChange"]}
      options={options}
      value={value}
    />
  );
};

export default FormatSelect;
