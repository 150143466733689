import React from "react";
import { DataExportContextProps } from "./types";

const defaultContext: DataExportContextProps<unknown> = {
  exportData: () => {
    return;
  },
  onFormatChange: () => {
    return;
  },
  onValuesChange: () => {
    return;
  },
  resetValues: () => {
    return;
  },
  state: { initialValues: undefined, values: undefined, format: "csv", allowedFormats: [] },
};

const DataExportContext = React.createContext<DataExportContextProps<any>>(defaultContext);

export default DataExportContext;
