import { t } from "@lingui/macro";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Item, Segment } from "semantic-ui-react";

import { GetCompanies } from "../../../actionTypes/companies";
import { Company, companyStatuses } from "../../../interfaces/resources/company";
import { State } from "../../../interfaces/state";
import { getCompaniesAction } from "../../../services/api/company/actions";
import { extractPageNumberFromIri } from "../../../services/api/helpers";
import { applyFilterAction } from "../../../services/state/actions";
import { usePrevious } from "../../Utils/hook";

import LoaderComp from "../../Utils/Loader";
import { CompanyShort } from "./CompanyShort";

export const CompanyList: React.FC = () => {
  const dispatch = useDispatch();
  const { apiPendingRequests, companies, companyView, filterContext } = useSelector((state: State) => state);

  const prevFilterContext = usePrevious(filterContext);
  const loading = apiPendingRequests.length > 0 && apiPendingRequests.some((e: any) => e.type === GetCompanies.REQUEST);

  React.useEffect(() => {
    const handleOnScroll = () => {
      const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
      const scrollHeight =
        (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
      const clientHeight = document.documentElement.clientHeight || window.innerHeight;
      const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

      if (scrolledToBottom) {
        const nextPage =
          companyView && companyView["hydra:next"] ? extractPageNumberFromIri(companyView["hydra:next"]) : null;
        if (nextPage && !loading) {
          dispatch(
            applyFilterAction({
              multiple: false,
              name: "page",
              value: nextPage,
            }),
          );
        }
      }
    };
    window.addEventListener("scroll", handleOnScroll);

    return () => {
      window.removeEventListener("scroll", handleOnScroll);
    };
  }, [companyView, loading, dispatch]);

  React.useEffect(() => {
    if (prevFilterContext !== undefined && prevFilterContext !== filterContext) {
      dispatch(getCompaniesAction(filterContext));
    }
  }, [filterContext, prevFilterContext, dispatch]);

  return (
    <Segment basic>
      <Item.Group>
        {companies
          .sort((a: Company, b: Company) => {
            if (a.status === companyStatuses.closed && b.status !== companyStatuses.closed) {
              return -1;
            } else if (a.status !== companyStatuses.closed && b.status === companyStatuses.closed) {
              return 1;
            }
            return 0;
          })
          .map((item: Company, i: number) => (
            <CompanyShort key={`companylist-${item["@id"]}-${i}`} company={item} />
          ))}
      </Item.Group>
      {loading ? <LoaderComp /> : companies.length === 0 ? <div>{t`companies.empty`}</div> : null}
    </Segment>
  );
};
