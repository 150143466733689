import { AnyAction } from "redux";

import { GetQuizAnswers } from "../../../../actionTypes/quizAnswer";
import { GetTalent } from "../../../../actionTypes/talents";

import { QuizAnswer } from "../../../../interfaces/resources/quizAnswer";

export function quizAnswers(state: QuizAnswer[] = [], action: AnyAction) {
  switch (action.type) {
    case GetQuizAnswers.SUCCESS:
      return action.quizAnswers;
    case GetTalent.REQUEST:
      return [];
    default:
      return state;
  }
}
