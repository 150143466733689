import { CreateLinkedinResume, EditLinkedInProfile } from "../../../../actionTypes/linkedInProfile";
import { LinkedInProfile } from "../../../../interfaces/resources/linkedInProfile";
import { Talent } from "../../../../interfaces/resources/talent";

export function editLinkedInProfileAction(linkedInProfile: Partial<LinkedInProfile>) {
  return {
    linkedInProfile,
    type: EditLinkedInProfile.REQUEST,
  };
}

export function editLinkedInProfileSuccessAction(linkedInProfile: LinkedInProfile) {
  return {
    linkedInProfile,
    type: EditLinkedInProfile.SUCCESS,
  };
}

export function createLinkedinResumeAction(iri: string, file: string) {
  return {
    iri,
    file,
    type: CreateLinkedinResume.REQUEST,
  };
}

export function createLinkedinResumeSuccessAction(talent: Talent) {
  return {
    talent,
    type: CreateLinkedinResume.SUCCESS,
  };
}
