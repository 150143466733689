import { t } from "@lingui/macro";
import * as React from "react";
import { Accordion, Checkbox, Icon, Item } from "semantic-ui-react";

import { ApiFilter } from "../../../interfaces/api/filter";
import { ExperienceYears } from "../../../interfaces/resources/experienceYears";

interface Props extends ApiFilter<ExperienceYears> {
  activeIndexes?: number[];
  handleClick?: (e: any, titleProps: any) => void;
  index?: number;
}

export const ExperienceYearsFilter: React.FC<Props> = ({
  activeIndexes,
  handleClick,
  index,
  items,
  multiple,
  onChange,
  selected,
}: Props) => {
  const active = activeIndexes ? activeIndexes.some((item: number) => item === index) : false;
  return (
    <div>
      <Accordion.Title active={active} index={index} onClick={handleClick}>
        <h3>
          {t`ExperienceYearsFilter.title`}
          <Icon name={active ? "chevron up" : "chevron down"} />
        </h3>
      </Accordion.Title>
      <Accordion.Content active={active}>
        <Item.Group>
          {items.length > 0
            ? items.map((item: ExperienceYears) => (
                <Item key={item["@id"]}>
                  <Checkbox
                    checked={
                      Array.isArray(selected)
                        ? selected.some((e: string) => e === item["@id"])
                        : selected === item["@id"]
                    }
                    label={item.value}
                    multiple={!!multiple}
                    onChange={(_: any, data: any): void => onChange(data)}
                    value={item["@id"]}
                  />
                </Item>
              ))
            : null}
        </Item.Group>
      </Accordion.Content>
    </div>
  );
};
