import { OfferTalentView } from "../../../../interfaces/resources/offer";
import client from "../../client";

export function getOfferTalentView(iri: string) {
  return client.get(iri);
}

export function createOfferTalentView(offerTalentView: Partial<OfferTalentView>) {
  return client.post("/api/v2/offer_talent_views", {
    ...offerTalentView,
    elinoiAdvice: offerTalentView.elinoiAdvice || undefined,
  });
}

export function editOfferTalentView(offerTalentView: Partial<OfferTalentView>) {
  if (offerTalentView["@id"]) {
    return client.put(offerTalentView["@id"], offerTalentView);
  }
}
