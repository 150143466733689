import { t } from "@lingui/macro";
import * as Yup from "yup";
import { isConsecutive } from "./helpers";

declare module "yup" {
  interface ArraySchema<T> {
    salaryExpectation: (message?: any) => ArraySchema<T>;
  }
}

Yup.addMethod<Yup.ArraySchema<any, any, any>>(Yup.array, "salaryExpectation", function (this) {
  return this.min(1)
    .max(2)
    .nullable()
    .required()
    .test("consecutiveExpectations", t`yup.consecutiveExpectations`, (arr: any) => isConsecutive(arr));
});

Yup.setLocale({
  array: {
    min: ({ min }: any) => t({ id: "yup.array.min", values: { min } }),
    max: ({ max }: any) => t({ id: "yup.array.max", values: { max } }),
  },
  mixed: {
    required: () => t`yup.mixed.required`,
  },
  number: {
    min: ({ min }: any) => t({ id: "yup.number.min", values: { min } }),
    max: ({ max }: any) => t({ id: "yup.number.max", values: { max } }),
  },
  string: {
    email: ({ value }: any) => t({ id: "yup.string.email", values: { value } }),
    url: ({ value }: any) => t({ id: "yup.string.url", values: { value } }),
  },
});

export default Yup;
