import { ActionType } from "../interfaces/api/actionType";

export const GetHuntTalents: ActionType = {
  FAILURE: "GET_HUNT_TALENTS_FAILURE",
  REQUEST: "GET_HUNT_TALENTS_REQUEST",
  SUCCESS: "GET_HUNT_TALENTS_SUCCESS",
};

export const RemoveTalentFromHunt = {
  DEFAULT: "REMOVE_TALENT_FROM_HUNT",
};

export const SaveTalentOnContact = {
  DEFAULT: "SAVE_TALENT_ON_CONTACT",
};

export const DeleteTalentFromToContact = {
  DEFAULT: "DELETE_TALENT_FROM_TO_CONTACT",
};
