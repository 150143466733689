import { connect } from "react-redux";
import { Dispatch } from "redux";

import TalentList from "../../../components/Talents/TalentList";
import { FilterClause, FilterContext } from "../../../interfaces/api/filter";
import { getTalentsAction } from "../../../services/api/talent/actions";
import { applyFilterAction } from "../../../services/state/actions";

let filterContext: FilterContext;

const mapStateToProps = (state: any) => {
  filterContext = [
    ...state.filterContext,
    {
      multiple: false,
      name: "order",
      param: "lastLogin",
      value: "DESC",
    },
    { multiple: false, name: "itemsPerPage", value: 25 },
  ];
  return {
    apiPendingRequests: state.apiPendingRequests,
    filterContext: state.filterContext,
    talentView: state.talentView,
    talents: state.talents,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    applyFilter: (filterClause: FilterClause<any>) => dispatch(applyFilterAction(filterClause)),
    getTalents: () => dispatch(getTalentsAction(filterContext)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TalentList);
