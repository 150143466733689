import { t } from "@lingui/macro";
import * as React from "react";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { Button, Form, Header, Icon, Input, Message } from "semantic-ui-react";

import { GetCurrentUser } from "../../../actionTypes/coreUsers";

import { ApiError } from "../../../interfaces/api/error";
import { ApiRequest } from "../../../interfaces/api/request";
import { ApiSuccess } from "../../../interfaces/api/success";
import { Violation } from "../../../interfaces/api/violation";
import { CoreUser, coreUserRoles, isTalentManager } from "../../../interfaces/resources/coreUser";

import transCoreUser from "../../../translations/constants/coreUser";

import Avatar from "../../Form/Avatar";
import Phone from "../../Form/Phone";
import Select from "../../Form/Select";
import GmailMailto from "../../Utils/GmailMailto";

import "./index.scss";

interface Props {
  apiErrors: ApiError[];
  apiPendingRequests: ApiRequest[];
  apiSuccess: ApiSuccess[];
  currentUser: CoreUser;
  getCurrentUser: () => void;
  submitCoreUser: (req: Partial<CoreUser>, formName?: string) => void;
  state: "currentUser" | "coreUser";
  getCoreUsers: () => void;
  coreUser: CoreUser;
}

interface State {
  error: boolean | string;
  errors: Violation[];
  showFields: boolean;
  success: boolean;
}

const formName = "editCoreUser";

class Profile extends React.PureComponent<InjectedFormProps<CoreUser, Props> & Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      error: false,
      errors: [],
      showFields: false,
      success: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public showFields = () =>
    this.setState({
      showFields: true,
    });

  public handleSubmit = (values: any) => this.props.submitCoreUser(values, formName);

  public componentDidUpdate(prevProps: Props) {
    if (
      !prevProps.apiSuccess.some((e: ApiSuccess) => formName === e.form)
      && this.props.apiSuccess.some((e: ApiSuccess) => formName === e.form)
    ) {
      this.setState({ error: false, errors: [], success: true });
      if (this.props.state === "currentUser") {
        this.props.getCurrentUser();
      }
    }

    if (
      !prevProps.apiErrors.some((e: ApiError) => formName === e.form)
      && this.props.apiErrors.some((e: ApiError) => formName === e.form)
    ) {
      const error = this.props.apiErrors.find((e: ApiError) => formName === e.form);
      if (error) {
        this.setState({
          error: error.payload.message,
          errors: error.payload.violations || [],
          success: false,
        });
      }
    }
  }

  public render() {
    const { apiPendingRequests, currentUser, handleSubmit, coreUser, state } = this.props;
    const user = state === "currentUser" ? currentUser : coreUser;
    return (
      <div className="coreUserProfile">
        <Form
          loading={
            apiPendingRequests.length > 0
            && apiPendingRequests.some(
              (e: ApiRequest) => [GetCurrentUser.REQUEST].includes(e.type) && formName === e.form,
            )
          }
          success={this.state.success}
          error={!!this.state.error || this.state.errors.length > 0}
        >
          <div>
            <Message
              success
              header={t`CoreUserProfile.success.header`}
              content={state === "currentUser" ? t`CoreUserProfile.success.content` : t`EditCoreUser.success.content`}
              icon="check circle"
            />
            <Message
              error
              header={t`CoreUserProfile.error.header`}
              content={
                <div>
                  {t`CoreUserProfile.error.content`}: {this.state.error}{" "}
                </div>
              }
              icon="times circle"
            />
          </div>
          {user ? (
            <Header as="h2" textAlign="center">
              <Field name="imageFile" component={Avatar} default={user.image.url} rounded />
              <Header.Content>
                {user.firstname} {user.lastname}
              </Header.Content>
              <Header.Subheader>
                {this.state.showFields ? (
                  <div>
                    @<GmailMailto to={user.email} /> | Tel: {user.phone}
                    <Icon name="pencil" onClick={this.showFields()} />
                  </div>
                ) : (
                  <div className="info">
                    <Field
                      name="email"
                      component={Form.Input}
                      placeholder={t`coreUser.email`}
                      error={this.state.errors.some((item: Violation) => "email" === item.propertyPath)}
                    />
                    <Field
                      name="phone"
                      component={Phone}
                      placeholder={t`coreUser.phone`}
                      error={this.state.errors.some((item: Violation) => "phone" === item.propertyPath)}
                    />
                  </div>
                )}
              </Header.Subheader>
              <Header.Subheader>
                <Field
                  name="linkedInUrl"
                  component={Form.Input}
                  placeholder={t`coreUser.linkedInUrl`}
                  error={this.state.errors.some((item: Violation) => "linkedInUrl" === item.propertyPath)}
                  icon="linkedin square"
                />
              </Header.Subheader>
              {user !== currentUser && currentUser.roles.includes(coreUserRoles.admin) ? (
                <Header.Subheader>
                  <Field
                    name="roles"
                    component={Select}
                    placeholder={t`coreUser.roles`}
                    error={this.state.errors.some((item: Violation) => "roles" === item.propertyPath)}
                    getOptionLabel={(role: string) => t({ id: transCoreUser.roles[role] })}
                    getOptionValue={(role: string) => role}
                    options={Object.keys(transCoreUser.roles)}
                    value={user.roles}
                    isMulti={true}
                    isSearchable={true}
                    isClearable={true}
                  />
                </Header.Subheader>
              ) : null}
              <Header.Subheader className="field">
                <Field
                  name="calendlyLink10"
                  component={Input}
                  label={{
                    tag: true,
                    content: isTalentManager(user) ? t`coreUser.calendlyLink10.tim` : t`coreUser.calendlyLink10.am`,
                  }}
                  placeholder={t`coreUser.calendlyLink`}
                  error={this.state.errors.some((item: Violation) => "calendlyLink10" === item.propertyPath)}
                  icon="calendar outline"
                  iconPosition="left"
                  labelPosition="right"
                />
              </Header.Subheader>
              <Header.Subheader className="field">
                <Field
                  name="calendlyLink20"
                  component={Input}
                  label={{
                    tag: true,
                    content: isTalentManager(user) ? t`coreUser.calendlyLink20.tim` : t`coreUser.calendlyLink20.am`,
                  }}
                  placeholder={t`coreUser.calendlyLink`}
                  error={this.state.errors.some((item: Violation) => "calendlyLink20" === item.propertyPath)}
                  icon="calendar outline"
                  iconPosition="left"
                  labelPosition="right"
                />
              </Header.Subheader>
              <Header.Subheader className="field">
                <Field
                  name="calendlyLink30"
                  component={Input}
                  label={{
                    tag: true,
                    content: isTalentManager(user) ? t`coreUser.calendlyLink30.tim` : t`coreUser.calendlyLink30.am`,
                  }}
                  placeholder={t`coreUser.calendlyLink`}
                  error={this.state.errors.some((item: Violation) => "calendlyLink30" === item.propertyPath)}
                  icon="calendar outline"
                  iconPosition="left"
                  labelPosition="right"
                />
              </Header.Subheader>
            </Header>
          ) : null}
          <Button onClick={handleSubmit(this.handleSubmit)} content={t`CoreUserProfile.fields.submit`} />
        </Form>
      </div>
    );
  }
}

export default reduxForm<CoreUser, Props>({
  form: formName,
})(Profile);
