import { t } from "@lingui/macro";
import cx from "classnames";
import { format } from "date-fns";
import * as React from "react";
import { FaChevronDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Accordion, Icon } from "semantic-ui-react";

import { EditApplication } from "../../../../../actionTypes/applications";
import { GetTalentCard } from "../../../../../actionTypes/talentCards";
import { PersonProfileImg } from "../../../../../blocks/personProfileImg";
import { PhoneCallComp } from "../../../../../blocks/talentDetails/PhoneCalls/PhoneCall";
import { Application, applicationStatuses } from "../../../../../interfaces/resources/application";
import { ApplicationMessage } from "../../../../../interfaces/resources/applicationMessages";
import { CoreUser } from "../../../../../interfaces/resources/coreUser";
import { PhoneCall } from "../../../../../interfaces/resources/phoneCall";
import { Qualification } from "../../../../../interfaces/resources/qualification";
import { TalentCard } from "../../../../../interfaces/resources/talentCard";
import { TalentProfile } from "../../../../../interfaces/resources/talentProfile";
import { editApplicationAction } from "../../../../../services/api/applications/actions";

import { extractPageFromIri, generateIriFromId } from "../../../../../services/api/helpers";
import {
  createTalentCardAction,
  editTalentCardAction,
  getTalentCardAction,
} from "../../../../../services/api/talentCard/actions";

import globalStyles from "../../../../../styles/global.module.scss";
import { ModalWrapper } from "../../../../ModalWrapper";
import { TalentCardModal } from "../../../../TalentCardModal";
import { useApiSelector } from "../../../../Utils/hook";
import TalentCardForm from "../TalentCardForm";
import { initializeValues } from "../TalentCardForm/talentCardHelper";
import styles from "./index.module.scss";

interface Props {
  application: Application;
  formName: string;
  index: number;
  activeIndex: number;
  removeTalentCard: (iri: string) => void;
  onClickHeaderItem: (num: number) => void;
  switchToNextApplication: () => void;
}

export const ShortlistItem: React.FC<Props> = ({
  application,
  formName,
  index,
  activeIndex,
  removeTalentCard,
  onClickHeaderItem,
  switchToNextApplication,
}: Props) => {
  const { apiErrors, apiPendingRequests, apiSuccess } = useApiSelector();
  const dispatch = useDispatch();
  const currentUser: CoreUser = useSelector((state: any) => state.currentUser);
  const talentProfiles: { [iri: string]: TalentProfile } = useSelector((state: any) => state.talentProfiles);
  const phoneCalls: PhoneCall[] = useSelector((state: any) => state.shortlistPhoneCalls);
  const qualifications: { [iri: string]: Qualification } = useSelector((state: any) => state.qualifications);
  const talentCards: TalentCard[] = useSelector((state: any) => state.talentCards);
  const [importedTalentCard, setImportedTalentCard] = React.useState<TalentCard | null>(null);
  const [showNotes, setShowNotes] = React.useState<boolean>(false);
  const [showAppMessages, setShowAppMessages] = React.useState<boolean>(false);
  const talentProfile: TalentProfile | undefined =
    typeof application.talent.talentProfile === "string"
      ? talentProfiles[application.talent.talentProfile]
      : talentProfiles[application.talent.talentProfile["@id"]];
  const qualification: Qualification | null =
    talentProfile && talentProfile.lastQualification && qualifications[talentProfile.lastQualification["@id"]];
  const talentPhoneCalls = phoneCalls
    ? phoneCalls.filter((item: PhoneCall) => {
        const tId = typeof item.talent === "string" ? item.talent : item.talent?.["@id"];

        return tId === application.talent["@id"];
      })
    : [];
  const linkedInProfile = application.talent.linkedInProfile;
  const editingTalentCard = talentCards.some((item) => item.application["@id"] === application["@id"])
    ? talentCards.find((item) => item.application["@id"] === application["@id"])
    : undefined;
  const initialValues = editingTalentCard
    ? editingTalentCard
    : initializeValues(application, qualification, linkedInProfile, currentUser, importedTalentCard);

  const [openModal, setOpenModal] = React.useState(false);
  const onCloseModal = () => {
    setOpenModal(false);
  };
  const editApplication = (req: Partial<Application>) => req["@id"] && dispatch(editApplicationAction(req["@id"], req));
  const createTalentCard = (talentC: Partial<TalentCard>, form: string) => {
    dispatch(createTalentCardAction(talentC, form));
  };
  const editTalentCard = (talentC: Partial<TalentCard>, form: string) => {
    dispatch(editTalentCardAction(talentC, form));
  };

  const onSuccess = () => {
    setOpenModal(true);
  };

  const onClickImportTalentCard = () => {
    // dispatch the call to get the most recent talentCard
    const tcId = application?.talent.lastTalentCardId;
    if (!!tcId) {
      dispatch(getTalentCardAction(generateIriFromId("talent_cards", tcId)));
    }
  };

  React.useEffect(() => {
    // check for the call of the most recent talent card and sets it in the state
    const tcId = application?.talent.lastTalentCardId
      ? generateIriFromId("talent_cards", application?.talent.lastTalentCardId)
      : undefined;
    if (
      !!!importedTalentCard
      && apiSuccess.some((e) => e.type === GetTalentCard.SUCCESS && e.talentCard["@id"] === tcId)
    ) {
      apiSuccess.map((apiSucc) => {
        if (apiSucc.type === GetTalentCard.SUCCESS && apiSucc.talentCard["@id"] === tcId) {
          setImportedTalentCard(apiSucc.talentCard);
        }

        return apiSucc;
      });
    }
  }, [apiErrors, apiPendingRequests, apiSuccess, application, importedTalentCard]);

  React.useEffect(() => {
    const talentCardIri = application.talentCard?.["@id"];
    if (
      talentCardIri
      && !!!editingTalentCard
      && !apiPendingRequests.some((req) => req.type === GetTalentCard.REQUEST && req.iri === talentCardIri)
      && !apiErrors.some((err) => err.type === GetTalentCard.FAILURE && err.iri === talentCardIri)
      && !apiSuccess.some((suc) => suc.type === GetTalentCard.SUCCESS && suc.talentCard?.["@id"] === talentCardIri)
    ) {
      dispatch(getTalentCardAction(talentCardIri));
    }
  }, [apiErrors, apiPendingRequests, apiSuccess, application, editingTalentCard, dispatch]);

  return (
    <div className={cx("talentItem", styles.shortListItem)}>
      <div
        className={styles.itemHeader}
        onClick={() => {
          if (typeof onClickHeaderItem === "function") {
            onClickHeaderItem(index);
          }
        }}
      >
        {apiPendingRequests.length > 0
        && apiPendingRequests.some((req) => EditApplication.REQUEST === req.type && application["@id"] === req.iri) ? (
          <div className={styles.loaderCheckContainer}>
            <Icon loading name="spinner" style={{ float: "none" }} />
          </div>
        ) : applicationStatuses.shortlistReady === application.status ? (
          <div className={styles.loaderCheckContainer}>
            <Icon name="check" color="green" style={{ float: "none" }} />
          </div>
        ) : null}

        <div className={styles.talentImageContainer}>
          <PersonProfileImg
            image={application.talent.image}
            alt={`${application.talent.firstname} ${application.talent.lastname}`}
          />
        </div>
        <div className={styles.talentNameContainer}>
          <Link
            to={extractPageFromIri("talents", application.talent["@id"])}
            target="_blank"
            className={styles.talentNameLink}
            onClick={(e) => e.stopPropagation()}
          >
            {`${application.talent.firstname} ${application.talent.lastname}`}
          </Link>
        </div>

        <div>
          <button
            type="button"
            className={cx(styles.headerBtn, globalStyles.cta, globalStyles.darkCta, globalStyles.inverted)}
            disabled={!!editingTalentCard || !!importedTalentCard || !application.talent.lastTalentCardId}
            onClick={(e) => {
              e.stopPropagation();
              onClickImportTalentCard();
            }}
            title={
              !!editingTalentCard
                ? t`Shortlist.importTalentCard.cta.title.modification`
                : !!importedTalentCard
                ? t`Shortlist.importTalentCard.cta.title.imported`
                : !application.talent.lastTalentCardId
                ? t`Shortlist.importTalentCard.cta.title.noTalentCard`
                : undefined
            }
          >
            {t`Shortlist.importTalentCard.cta.text`}
          </button>
        </div>

        {talentPhoneCalls.length > 0 ? (
          <div>
            <button
              type="button"
              className={cx(styles.headerBtn, globalStyles.cta, globalStyles.primaryCta, globalStyles.inverted)}
              onClick={(e): void => {
                e.stopPropagation();
                setShowNotes(!showNotes);
              }}
            >
              {showNotes ? t`Shortlist.hideNotes` : t`Shortlist.seeNotes`}
            </button>
          </div>
        ) : null}

        {application.applicationMessages && application.applicationMessages.length > 0 ? (
          <div>
            <button
              type="button"
              className={cx(styles.headerBtn, globalStyles.cta, globalStyles.primaryCta, globalStyles.inverted)}
              onClick={(e): void => {
                e.stopPropagation();
                setShowAppMessages(!showAppMessages);
              }}
            >
              {showAppMessages ? t`Shortlist.hideAppMessages` : t`Shortlist.seeAppMessages`}
            </button>
          </div>
        ) : null}

        {application.talentCard?.["@id"] ? (
          <div>
            <button
              type="button"
              className={cx(styles.headerBtn, globalStyles.cta, globalStyles.primaryCta, globalStyles.inverted)}
              onClick={(e) => {
                e.stopPropagation();
                setOpenModal(true);
              }}
            >
              {t`Shortlist.previewTalentCard`}
            </button>
          </div>
        ) : null}

        {applicationStatuses.shortlistReady === application.status ? (
          <div>
            <button
              type="button"
              className={cx(styles.headerBtn, globalStyles.cta, globalStyles.primaryCta, globalStyles.inverted)}
              onClick={(e) => {
                e.stopPropagation();
                editApplication({ "@id": application["@id"], status: applicationStatuses.shortlisted });
              }}
            >
              {t`Shortlist.sendWithoutEmail`}
            </button>
          </div>
        ) : null}

        <div>
          <button
            type="button"
            className={cx(styles.headerBtn, globalStyles.cta, globalStyles.alertCta, globalStyles.inverted)}
            onClick={(e) => {
              e.stopPropagation();
              const talentItem = e.currentTarget.parentElement!.parentElement?.parentElement;
              talentItem!.style.display = "none";
              if (typeof removeTalentCard === "function") {
                removeTalentCard(application["@id"]);
              }
            }}
          >
            {t`Shortlist.talent.remove`}
          </button>
        </div>
        <div>
          <div className={cx(styles.chevronContainer, { [styles.isActive]: activeIndex === index })}>
            <FaChevronDown />
          </div>
        </div>
      </div>
      <div className={cx(styles.itemContentContainer, { [styles.isActive]: activeIndex === index })}>
        <div className={styles.itemContent}>
          <div className={styles.talentCardFormContainer}>
            <TalentCardForm
              application={application}
              editApplication={editApplication}
              currentUser={currentUser}
              form={formName}
              initialValues={initialValues}
              switchToNextApplication={switchToNextApplication}
              talentCard={editingTalentCard}
              createTalentCard={createTalentCard}
              editTalentCard={editTalentCard}
              talentProfile={talentProfile}
              onSuccess={onSuccess}
              mode="shortlist"
            />
          </div>
          <div>
            <div className={cx(styles.notesContainer, { [styles.show]: showNotes })}>
              <Accordion>
                {talentPhoneCalls.length > 0
                  ? talentPhoneCalls.map((item: PhoneCall, i) => (
                      <PhoneCallComp
                        key={`${item["@id"]}-${i}`}
                        phoneCall={item}
                        coreUser={currentUser}
                        readOnly={true}
                        shortlist={true}
                      />
                    ))
                  : null}
              </Accordion>
            </div>
            <div className={cx(styles.notesContainer, { [styles.show]: showAppMessages })}>
              <Accordion>
                {application.applicationMessages && application.applicationMessages.length > 0
                  ? application.applicationMessages.map(
                      (item: ApplicationMessage) =>
                        `${item.coreUser?.firstname ? item.coreUser.firstname : "[utilisateur supprimé]"} le  ${format(
                          new Date(item.createdAt),
                          "dd/MM",
                        )} : ${item.message}`,
                    )
                  : null}
              </Accordion>
            </div>
          </div>
        </div>
      </div>

      <ModalWrapper open={openModal} onCloseModal={onCloseModal}>
        <TalentCardModal
          talentCardIri={application.talentCard?.["@id"]}
          mode="talentCard"
          onCloseModal={onCloseModal}
        />
      </ModalWrapper>
    </div>
  );
};
