import { FilterContext } from "../../../interfaces/api/filter";
import { Application, ApplicationAccepted } from "../../../interfaces/resources/application";
import { ApplicationMessage } from "../../../interfaces/resources/applicationMessages";

import client from "../client";
import { generateFilterQuery, getCollectionStringValue, getStringValue } from "../helpers";

function formatApplicationData(data: Partial<Application>) {
  return {
    ...data,
    hunt: getStringValue(data.hunt, "@id"),
    offer: getStringValue(data.offer, "@id"),
    owner: getStringValue(data.owner, "@id"),
    talent: getStringValue(data.talent, "@id"),
  };
}

const formatApplicationAcceptedData = (req: Partial<ApplicationAccepted>) => ({
  ...req,
  am: getCollectionStringValue(req.coreUsers, "@id"),
  application: getStringValue(req.application, "@id"),
  offer: getStringValue(req.offer, "@id"),
  talent: getStringValue(req.talent, "@id"),
  tim: getStringValue(req.tim, "@id"),
});

export function getApplications(filterContext: FilterContext = [], page?: number) {
  let query = "";
  if (filterContext) {
    query = generateFilterQuery(
      page ? [...filterContext, { multiple: false, name: "page", value: page }] : filterContext,
    );
  } else {
    query = page ? `?page=${page}` : "";
  }
  return client.get(`/api/v2/applications${query}`);
}

export function getApplication(iri: string) {
  return client.get(iri);
}

export function deleteApplication(iri: string) {
  return client.delete(iri);
}

export function editApplication(iri: string, req: Partial<Application>, validation?: string) {
  return client.put(`${iri}${validation ? `/${validation}` : ""}`, formatApplicationData(req));
}

export function createApplication(application: Partial<Application>) {
  return client.post("/api/v2/applications", formatApplicationData(application));
}

export function postApplicationMessage(values: {
  message: string;
  coreUser: string;
  application: string;
  clientEnabled: boolean;
}): Promise<ApplicationMessage> {
  return client.post("/api/v2/application_messages", { ...values });
}
export function editApplicationMessage(iri: string, req: Partial<ApplicationMessage>): Promise<ApplicationMessage> {
  return client.put(`${iri}`, req);
}

export function getApplicationsAccepted(filterContext: FilterContext = [], page?: number) {
  let query = "";
  if (filterContext) {
    query = generateFilterQuery(
      page ? [...filterContext, { multiple: false, name: "page", value: page }] : filterContext,
    );
  } else {
    query = page ? `?page=${page}` : "";
  }
  return client.get(`/api/v2/application_accepteds${query}`);
}

export function createApplicationAccepted({ talent, ...applicationAccepted }: ApplicationAccepted) {
  return client.post("/api/v2/application_accepteds", {
    ...formatApplicationAcceptedData(applicationAccepted),
    talent: talent ? talent["@id"] : "",
  });
}

export function editApplicationAccepted({ talent, ...applicationAccepted }: ApplicationAccepted) {
  return client.put(`${applicationAccepted["@id"]}`, {
    ...formatApplicationAcceptedData(applicationAccepted),
    talent: talent ? talent["@id"] : "",
  });
}
