import { t } from "@lingui/macro";
import * as React from "react";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Message } from "semantic-ui-react";
import { GetCoreUsers } from "../../../actionTypes/coreUsers";
import { GetTalentProfile } from "../../../actionTypes/talentProfile";
import { GetTalent, GetTalents, SortTalent } from "../../../actionTypes/talents";
import { CardTalentMini } from "../../../blocks/cardTalentMini";
import { Talent, talentStatuses } from "../../../interfaces/resources/talent";
import { State } from "../../../interfaces/state";
import { getCoreUsersAction } from "../../../services/api/coreUser/actions";
import { getTalentsAction } from "../../../services/api/talent/actions";
import { TalentListLoadingPlaceholder, TalentLoadingPlaceholder } from "../../Design/LoadingPlaceholder";
import { useApiSelector, useGetResourceHook, usePrevious } from "../../Utils/hook";
import { NewTalentProfile } from "./NewTalentProfile";

export const SortNewTalents: FC = () => {
  const dispatch = useDispatch();

  const { apiErrors, apiPendingRequests, apiSuccess } = useApiSelector();

  const coreUsers = useSelector((state: State) => state.coreUsers);
  const talents = useSelector((state: State) => state.talents);
  const talentTotalItems = useSelector((state: State) => state.talentTotalItems);

  const [activeIndex, setActiveIndex] = React.useState<number>(0);
  const [currentTalent, setCurrentTalent] = React.useState<Talent | null>(null);
  const [error, setError] = React.useState<boolean | string>(false);
  const [success, setSuccess] = React.useState<boolean>(false);
  const [initLoading, setInitLoading] = React.useState(true);
  const previousApiErrors = usePrevious(apiErrors);
  const previousApiSuccess = usePrevious(apiSuccess);

  useGetResourceHook(
    apiErrors,
    apiPendingRequests,
    apiSuccess,
    GetCoreUsers,
    coreUsers,
    () => dispatch(getCoreUsersAction()),
    [],
  );

  React.useEffect(() => {
    dispatch(
      getTalentsAction([
        {
          multiple: false,
          name: "talentProfile.completed",
          value: true,
        },
        {
          multiple: true,
          name: "status",
          value: [talentStatuses.pending, talentStatuses.retry],
        },
        {
          multiple: false,
          name: "order",
          param: "talentProfile.updatedAt",
          value: "DESC",
        },
      ]),
    );
  }, [dispatch]);

  React.useEffect(() => {
    if (
      !!currentTalent
      && apiErrors.some((e) => e.type === SortTalent.FAILURE && e.iri === currentTalent["@id"])
      && (!!!previousApiErrors
        || !previousApiErrors.some((e) => e.type === SortTalent.FAILURE && e.iri === currentTalent["@id"]))
    ) {
      setError(
        apiErrors.reduce(
          (acc: boolean | string, e) =>
            e.type === SortTalent.FAILURE && e.iri === currentTalent["@id"] ? e.payload.message : acc,
          false,
        ),
      );
      setSuccess(false);
    }
  }, [previousApiErrors, apiErrors, currentTalent]);

  React.useEffect(() => {
    if (
      !!currentTalent
      && apiSuccess.some((e) => e.type === SortTalent.SUCCESS && !!e.talent && e.talent["@id"] === currentTalent["@id"])
      && (!!!previousApiSuccess
        || !previousApiSuccess.some(
          (e) => e.type === SortTalent.SUCCESS && !!e.talent && e.talent["@id"] === currentTalent["@id"],
        ))
    ) {
      setError(false);
      setSuccess(true);
    }
    if (!!!currentTalent && talents.length > 0 && apiSuccess.some((e) => e.type === GetTalents.SUCCESS)) {
      setCurrentTalent(talents[0]);
    }
  }, [previousApiSuccess, apiSuccess, currentTalent, talents]);

  const previousTalents = usePrevious(talents);
  React.useEffect(() => {
    if (apiSuccess.some((e) => SortTalent.SUCCESS === e.type)) {
      const newActiveIndex = activeIndex === talents.length ? activeIndex - 1 : activeIndex;
      setActiveIndex(newActiveIndex);
      setCurrentTalent(talents[newActiveIndex]);
    }
  }, [previousTalents, talents, activeIndex]);

  React.useEffect(() => {
    if (apiPendingRequests.some((e) => GetTalents.REQUEST === e.type) && initLoading) {
      setInitLoading(false);
    }
  }, [apiPendingRequests, initLoading]);

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const i = Number(e.currentTarget.dataset.index);
    setActiveIndex(i);
    setCurrentTalent(talents[i]);
  };

  const isTalentListLoading = apiPendingRequests.some((e) => GetTalents.REQUEST === e.type);
  const isTalentProfileLoading = apiPendingRequests.some(
    (item) => GetTalentProfile.REQUEST === item.type || GetTalent.REQUEST === item.type,
  );

  let talentListContent: JSX.Element;
  if (isTalentListLoading || initLoading) {
    talentListContent = <TalentListLoadingPlaceholder />;
  } else {
    talentListContent =
      talents.length > 0 ? (
        <div className="cardListCardsContainer">
          {talents.map((item: Talent, i: number) => {
            return (
              <div
                key={`sort-new-talent-panel-item-${i}`}
                onClick={handleClick}
                data-index={i}
                className="miniTalentCardContainer"
              >
                <CardTalentMini talent={item} isActive={i === activeIndex} mode="new" />
              </div>
            );
          })}
        </div>
      ) : (
        <div>
          <div className="emptyList">{t`SortNewTalent.list.empty`}</div>
        </div>
      );
  }

  return (
    <div className="sortContainer">
      <div className="controlPanel">
        <div className="cardList">
          <h2>{t({ id: "TalentSort.listTitle", values: { talentTotalItems } })}</h2>
          {talentListContent}
        </div>
      </div>
      <div className="mainBlock">
        {!!error ? (
          <Message
            error
            icon="times circle"
            header={t`SortTalent.error.title`}
            content={t`SortTalent.error.content` + error}
          />
        ) : null}
        {!!success ? (
          <Message
            success
            icon="check circle"
            header={t`SortTalent.success.title`}
            content={t`SortTalent.success.content`}
          />
        ) : null}
        {isTalentListLoading || isTalentProfileLoading || initLoading ? (
          <TalentLoadingPlaceholder />
        ) : talents.length === 0 ? (
          <div className="emptyProfile">{t`talents.empty`}</div>
        ) : (
          <NewTalentProfile talent={currentTalent} />
        )}
      </div>
    </div>
  );
};
