import cx from "classnames";
import * as React from "react";
import goldenvSvg from "./img/golden.svg";
import styles from "./index.module.scss";

export const GoldenTag = ({ className = "" }: { className?: string }): JSX.Element => (
  <div className={cx(className, styles.goldenImgContainer)}>
    <img src={goldenvSvg} alt="Tag talent golden" />
  </div>
);
