import { call, put, takeLatest } from "redux-saga/effects";

import { GetSchools } from "../../../actionTypes/school";
import { apiFailureAction } from "../actions";
import { getSuccessType } from "../helpers";
import * as apiClient from "./api";

export function* getSchools(action: any): any {
  try {
    const response: any = yield call(apiClient.getSchools, action.filterContext);
    yield put({
      filterContext: action.filterContext,
      schools: response.data["hydra:member"],
      type: getSuccessType(action),
    });
  } catch (e: any) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailureAction(e, action));
  }
}

export default function* schoolSaga() {
  yield takeLatest(GetSchools.REQUEST, getSchools);
}
