import * as React from "react";
import styles from "./index.module.scss";

interface Props {
  loaderSize?: number;
  textSize?: number;
  showText?: boolean;
  text?: React.ReactNode;
}

const LoaderComp: React.FunctionComponent<Props> = (props: Props) => {
  const { text = "", textSize = 18, showText = true, loaderSize = 40 } = props;
  return (
    <div className={styles.loaderContainer}>
      <div className={styles.loader} style={{ fontSize: loaderSize }}></div>
      {showText ? (
        <div className={styles.loadingText} style={{ fontSize: textSize }}>
          {!!text ? text : "Chargement..."}
        </div>
      ) : null}
    </div>
  );
};

export default LoaderComp;
