import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";

import { GetDesiredPlaces } from "../../../actionTypes/desiredPlaces";
import { DesiredPlace } from "../../../interfaces/resources/desiredPlace";
import { apiFailureAction } from "../actions";
import { extractNextPageNumberFromIri } from "../helpers";
import { getDesiredPlacesAction, getDesiredPlacesSuccessAction } from "./actions";
import * as apiClient from "./api";

export function* getDesiredPlaces(action: AnyAction) {
  try {
    const response: AxiosResponse<{
      "hydra:member": DesiredPlace[];
      "hydra:view"?: {
        "hydra:next"?: string;
      };
    }> = yield call(apiClient.getDesiredPlaces, action.page);
    yield put(getDesiredPlacesSuccessAction(response.data["hydra:member"], action));
    if (response.data["hydra:view"] && response.data["hydra:view"]["hydra:next"]) {
      yield put(getDesiredPlacesAction(extractNextPageNumberFromIri(response.data["hydra:view"]["hydra:next"])));
    }
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export default function* getDesiredPlacesSaga() {
  yield takeLatest(GetDesiredPlaces.REQUEST, getDesiredPlaces);
}
