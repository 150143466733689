import { t } from "@lingui/macro";
import * as React from "react";
import { Accordion, Icon, Item } from "semantic-ui-react";

import { GetCoreUsers } from "../../../actionTypes/coreUsers";
import CreateCoreUserContainer from "../../../containers/CoreUsers/CreateCoreUserContainer";
import { ApiRequest } from "../../../interfaces/api/request";
import { CoreUser } from "../../../interfaces/resources/coreUser";

import LoaderComp from "../../Utils/Loader";
import CoreUserShort from "../CoreUserShort";

import "./index.scss";

export interface CoreUsersListProps {
  coreUsers: CoreUser[];
  getCoreUsers: () => void;
  apiPendingRequests: ApiRequest[];
  newCoreUser: CoreUser;
  deleteCoreUser: (iri: string) => void;
  switchOn: (iri: string) => void;
}

class CoreUsersList extends React.PureComponent<CoreUsersListProps, any> {
  public constructor(props: any) {
    super(props);
    this.state = {
      active: false,
    };
  }
  public componentDidMount = () => this.props.getCoreUsers();
  public componentDidUpdate = (nextProps: any) => {
    if (nextProps.newCoreUser !== this.props.newCoreUser) {
      this.props.getCoreUsers();
    }
  };
  public renderItems = (items: CoreUser[]) => {
    if (items.length > 0) {
      return items.map((coreUser: any, i: number) => (
        <CoreUserShort
          switchOn={this.props.switchOn}
          key={i}
          deleteCoreUser={() => this.props.deleteCoreUser(coreUser["@id"])}
          coreUser={coreUser}
        />
      ));
    } else {
      return <div>{t`coreUsers.empty`}</div>;
    }
  };
  public render() {
    const isLoading =
      this.props.apiPendingRequests.length > 0
      && this.props.apiPendingRequests.some((e: any) => GetCoreUsers.REQUEST === e.type);
    const disabledCoreUsers = this.props.coreUsers.filter((coreUser: CoreUser) => !coreUser.enabled);
    const enabledCoreUsers = this.props.coreUsers.filter((coreUser: CoreUser) => coreUser.enabled);
    return (
      <div className="coreUserListContainer">
        <div className="headerContainer">
          <CreateCoreUserContainer />
          <h1 className="title">{t`CoreUsersList.title`}</h1>
        </div>
        <div className="itemsContainer">
          {isLoading ? (
            <LoaderComp />
          ) : (
            <div>
              <Item.Group>{this.renderItems(enabledCoreUsers)}</Item.Group>
              <Accordion>
                <Accordion.Title onClick={() => this.setState({ active: !this.state.active })}>
                  <h2>
                    {t`CoreUsersList.disabledCoreUser.title`}
                    <Icon name={this.state.active ? "chevron up" : "chevron down"} />
                  </h2>
                </Accordion.Title>
                <Accordion.Content active={this.state.active}>
                  <Item.Group>{this.renderItems(disabledCoreUsers)}</Item.Group>
                </Accordion.Content>
              </Accordion>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CoreUsersList;
