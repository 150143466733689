import { t } from "@lingui/macro";
import * as React from "react";
import Select from "react-select";
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import { coreUserRoles } from "../../../interfaces/resources/coreUser";

import transCoreUser from "../../../translations/constants/coreUser";

import { defaultStyles } from "../../Form/Select";

import "./index.scss";

interface Props {
  createCoreUser: (coreUser: any) => void;
}

export default class CoreUserInvitationModal extends React.PureComponent<Props, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      invitations: [],
      isOpen: false,
    };
  }

  public createUsers = () => {
    this.state.invitations.forEach((item: any) => {
      if (this.isEmail(item.email) && item.roles.length > 0) {
        this.props.createCoreUser({
          email: item.email,
          password: "",
          roles: item.roles.map((role: any) => role.value),
          username: item.email,
        });
      }
    });
    this.closeModal();
  };

  public isEmail = (email: string) => {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
  };

  public closeModal = () => this.setState({ isOpen: false });

  public openModal = () => {
    this.setState({
      invitations: [
        {
          email: "",
          roles: [],
        },
      ],
      isOpen: true,
    });
  };

  public handleChange = (data: any, key: number, type: string) => {
    this.setState((prevState: any) => {
      let invitations = [...prevState.invitations];
      invitations[key][type] = type === "email" ? data.value : data;
      if (invitations.slice(-1)[0].email !== "" || invitations.slice(-1)[0].roles.length > 0) {
        invitations = [...invitations, { email: "", roles: [] }];
      }
      return {
        invitations,
      };
    });
  };

  public render() {
    const cuRoles = [
      {
        label: transCoreUser.roles.ROLE_ADMIN,
        value: coreUserRoles.admin,
      },
      {
        label: transCoreUser.roles.ROLE_USER,
        value: coreUserRoles.user,
      },
      {
        label: transCoreUser.roles.ROLE_COACH,
        value: coreUserRoles.tim,
      },
      {
        label: transCoreUser.roles.ROLE_ACCOUNT_MANAGER,
        value: coreUserRoles.am,
      },
    ];

    return (
      <div className="coreUserInvitationContainer">
        <Modal
          onClose={this.closeModal}
          onOpen={this.openModal}
          open={this.state.isOpen}
          trigger={
            <Button>
              <Icon name="add square" />
              {t`CoreUserInvitationModal.button`}
            </Button>
          }
        >
          <Modal.Header>
            <Icon name="times" onClick={this.closeModal} />
            {t`CoreUserInvitationModal.modalTitle`}
          </Modal.Header>
          <Modal.Content>
            <Form>
              {this.state.invitations.map((item: any, key: number) => (
                <Form.Group key={key}>
                  <Form.Input
                    type="email"
                    placeholder={t`coreUser.email`}
                    onChange={(...args: any) => this.handleChange(args[1], key, "email")}
                    value={item.email}
                    width={6}
                  />
                  <Form.Field width={10}>
                    <Select
                      isMulti={true}
                      isSearchable={true}
                      isClearable={true}
                      placeholder={t`coreUser.roles`}
                      getOptionLabel={(option: any) => t({ id: option.label })}
                      onChange={(data: any) => this.handleChange(data, key, "roles")}
                      options={cuRoles}
                      styles={defaultStyles}
                      value={item.roles}
                    />
                  </Form.Field>
                </Form.Group>
              ))}
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.closeModal}>{t`Constant.cancel`}</Button>
            <Button positive onClick={this.createUsers}>
              {t`CoreUserInvitationModal.invite`}
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
