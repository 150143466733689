import { InterviewStep } from "../../../../interfaces/resources/interviewStep";
import { InterviewStepType } from "../../../../interfaces/resources/interviewStepType";
import client from "../../client";

function formatData({ interviewStepTypes, ...rest }: InterviewStep) {
  return {
    interviewStepTypes: interviewStepTypes.map((type: InterviewStepType) => type["@id"]),
    ...rest,
  };
}

export function getOfferInterviewSteps(iri: string) {
  return client.get(`${iri}/interview_steps`);
}

export function createOfferInterviewStep(step: InterviewStep) {
  return client.post("/api/v2/interview_steps", formatData(step));
}

export function deleteOfferInterviewStep(step: InterviewStep) {
  if (step["@id"]) {
    return client.delete(step["@id"]);
  }
}

export function editOfferInterviewStep(step: InterviewStep) {
  if (step["@id"]) {
    return client.put(step["@id"], formatData(step));
  }
}
