import * as React from "react";
import { useParams } from "react-router-dom";

import TalentContainer from "../../containers/Talents/TalentContainer";
import { generateIriFromId } from "../../services/api/helpers";

export const TalentShowScene: React.FC = (): JSX.Element => {
  const { id } = useParams();
  return (
    <div>
      <TalentContainer iri={generateIriFromId("talent", Number(id))} />
    </div>
  );
};
