import { t } from "@lingui/macro";
import cx from "classnames";
import * as React from "react";
import { BsHeartFill } from "react-icons/bs";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { GetTalentApplications } from "../../../../actionTypes/talentApplications";
import { getCompanyImage } from "../../../../components/App/Image";
import RoundImage from "../../../../components/Design/RoundImage";
import { useApiSelector, usePrevious } from "../../../../components/Utils/hook";
import LoaderComp from "../../../../components/Utils/Loader";
import { applicationStatusesInProcess } from "../../../../interfaces/resources/application";
import { Talent } from "../../../../interfaces/resources/talent";
import { State } from "../../../../interfaces/state";
import { hashes } from "../../../../routes";
import { extractIdFromIri } from "../../../../services/api/helpers";
import { getTalentApplicationsAction } from "../../../../services/api/talent/talentApplication/actions";
import globalStyles from "../../../../styles/global.module.scss";
import transApplication from "../../../../translations/constants/application";
import { LabelType } from "../../../../translations/constants/label";
import { Label } from "../../../labels";
import { PersonProfileImg } from "../../../personProfileImg";
import styles from "./index.module.scss";

interface Props {
  talent: Talent;
}

export const ActiveApplications: React.FC<Props> = (props: Props) => {
  const { talent } = props;
  const dispatch = useDispatch();
  const { apiErrors, apiPendingRequests } = useApiSelector();
  const previousTalent = usePrevious(talent);
  const applications = useSelector((state: State) => state.applications);
  const activeApplications = applications.filter((ap) => applicationStatusesInProcess.includes(ap.status));
  const isLoading = apiPendingRequests.some((req) => GetTalentApplications.REQUEST === req.type);

  React.useEffect(() => {
    const getTalentApplications = (tlnt: Talent) => {
      dispatch(
        getTalentApplicationsAction(
          tlnt["@id"],
          [
            {
              multiple: false,
              name: "order",
              param: "updated_at",
              value: "DESC",
            },
            {
              multiple: true,
              name: "status",
              value: applicationStatusesInProcess,
            },
          ],
          "applications",
        ),
      );
    };
    if (
      !apiErrors.some((e) => e.type === GetTalentApplications.FAILURE)
      && !apiPendingRequests.some((e) => e.type === GetTalentApplications.REQUEST)
      && talent?.["@id"]
      && (!previousTalent || previousTalent["@id"] !== talent["@id"])
    ) {
      getTalentApplications(talent);
    }
  }, [apiErrors, apiPendingRequests, talent, previousTalent, dispatch]);

  return (
    <div>
      <div className={styles.header}>
        <h2 className={styles.title}>
          <ReactMarkdown
            components={{
              p: React.Fragment,
            }}
          >{t`ActiveApplications.title`}</ReactMarkdown>
        </h2>
        <div>
          <Link
            to={hashes.talentDetails.manageApplications}
            className={cx(globalStyles.cta, globalStyles.secondaryCta, globalStyles.inverted)}
          >
            Gerer
          </Link>
        </div>
      </div>
      <div>
        {isLoading ? (
          <div>
            <LoaderComp />
          </div>
        ) : activeApplications.length ? (
          <ul className={styles.cardsList}>
            {activeApplications.map((ap, i) => {
              const appId = extractIdFromIri(ap["@id"]);
              const lateStage = talent.lateStageApplications?.filter((lap) => appId === lap.applicationId);
              const lateStyle = lateStage?.length ? styles[lateStage[0].stage] : "";
              return (
                <li key={`application-recap-comp-${ap["@id"]}-${i}`}>
                  <div className={styles.card}>
                    <div className={cx(styles.cardHeader, { [lateStyle]: !!lateStyle })}>
                      {ap.goodMatch ? (
                        <div>
                          <span className={styles.favHeart}>
                            <BsHeartFill />
                          </span>
                        </div>
                      ) : null}
                      {lateStage?.length ? (
                        <div>
                          {lateStage?.map((lap, li) => {
                            return (
                              <Label
                                key={`application-card-late-stage-label-${lap.applicationId}-${li}`}
                                type={lap.stage as keyof LabelType}
                                className={styles.lateLabel}
                              />
                            );
                          })}
                        </div>
                      ) : null}
                      {ap.offer.owners && ap.offer.owners.length > 0
                        ? ap.offer.owners.map((owner) => {
                            return (
                              <div className={styles.teammateItem} key={owner["@id"]}>
                                <div
                                  className={cx(styles.teammate, globalStyles.labelTooltipContainer, globalStyles.left)}
                                >
                                  <span className={globalStyles.labelTooltip}>{owner.firstname}</span>
                                  <PersonProfileImg image={owner.image} />
                                </div>
                              </div>
                            );
                          })
                        : null}
                    </div>
                    <div className={styles.cardBody}>
                      <div className={styles.imageNamesContainer}>
                        <div>
                          <RoundImage size="42px" {...getCompanyImage(ap.offer.company.image)} />
                        </div>
                        <div className={styles.namesContainer}>
                          <div className={styles.companyName}>{ap.offer.company.name}</div>
                          <div className={styles.offerTitle}>{ap.offer.title}</div>
                        </div>
                      </div>
                      <div className={styles.statusDateContainer}>
                        <span className={styles.status}>{t({ id: transApplication.status[ap.status] })}</span>{" "}
                        {/* {lastStatusEvent ? `(${format(new Date(lastStatusEvent.createdAt), "dd/MM/yyyy")})` : null} */}
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          <div>{t`ActiveApplications.list.empty`}</div>
        )}
      </div>
    </div>
  );
};
