import { AxiosError, AxiosResponse } from "axios";
import { FormikErrors } from "formik";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { AnyAction } from "redux";

import { ActionType } from "../../interfaces/api/actionType";
import { ApiError } from "../../interfaces/api/error";
import { FilterContext } from "../../interfaces/api/filter";
import { ApiRequest } from "../../interfaces/api/request";
import { ApiSuccess } from "../../interfaces/api/success";
import { Violation } from "../../interfaces/api/violation";
import { Application, applicationStatuses, LastLap } from "../../interfaces/resources/application";
import { getTalentsSummary } from "../../services/api/talent/api";

export const getViolationMessage = (
  fieldName: string,
  violations: Violation[],
  errors?: FormikErrors<any> | null,
): string | undefined => {
  const yupError = errors && fieldName.split(".").reduce((acc: any, val: any) => acc && acc[val], errors);
  const violation = violations.find((item: Violation) => fieldName === item.propertyPath);

  return yupError ? String(yupError) : violation ? violation.message : undefined;
};

export const useGetResourceHook = (
  apiErrors: ApiError[],
  apiPendingRequests: ApiRequest[],
  apiSuccess: ApiSuccess[],
  actionType: ActionType,
  value: any,
  callback: (...args: any) => void,
  callbackArgs: any[] = [],
): void => {
  useEffect(() => {
    if (
      (!value || (Array.isArray(value) && value.length === 0))
      && !apiPendingRequests.some((req: ApiRequest) => req.type === actionType.REQUEST)
      && !apiErrors.some((err: ApiError) => err.type === actionType.FAILURE)
      && !apiSuccess.some((suc: ApiSuccess) => suc.type === actionType.SUCCESS)
    ) {
      callback(...callbackArgs);
    }
  }, [actionType, apiErrors, apiPendingRequests, apiSuccess, callback, value, callbackArgs]);
};

export const useGetLastLap = (
  application: Application,
  callback: (obj: LastLap, ...args: unknown[]) => void,
  returnCallback?: () => void,
): void => {
  useEffect(() => {
    if (
      [applicationStatuses.interviews, applicationStatuses.shortlisted].includes(application.status)
      && application.interviewsAt
      && application.offer?.countInterviewSteps
    ) {
      const lapObj: LastLap = {
        last: application.offer.countInterviewSteps - application.interviewsAt.length === 0 ? true : false,
        beforeLast: application.offer.countInterviewSteps - application.interviewsAt.length === 1 ? true : false,
      };

      callback(lapObj);
    }

    return (): void => {
      if (typeof returnCallback === "function") {
        returnCallback();
      }
    };
  }, [application, callback, returnCallback]);
};

export const isLoading = (
  actionTypes: ActionType[],
  apiErrors: ApiError[],
  apiPendingRequests: ApiRequest[],
  apiSuccess: ApiSuccess[],
) => {
  return (
    apiPendingRequests.length > 0
    && apiPendingRequests.some((req: ApiRequest) =>
      actionTypes.map((actionType: ActionType) => actionType.REQUEST).includes(req.type),
    )
    && (apiErrors.length === 0
      || !apiErrors.some((err: ApiError) =>
        actionTypes.map((actionType: ActionType) => actionType.FAILURE).includes(err.type),
      ))
    && (apiSuccess.length === 0
      || !apiSuccess.some((suc: ApiSuccess) =>
        actionTypes.map((actionType: ActionType) => actionType.SUCCESS).includes(suc.type),
      ))
  );
};

export const usePrevious = <T>(value: T): T | undefined => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

export const useApiSelector = () => {
  const apiErrors: ApiError[] = useSelector((state: any) => state.apiErrors);
  const apiPendingRequests: ApiRequest[] = useSelector((state: any) => state.apiPendingRequests);
  const apiSuccess: ApiSuccess[] = useSelector((state: any) => state.apiSuccess);

  return { apiErrors, apiPendingRequests, apiSuccess };
};

export function useSearchParams<T extends Record<string, unknown>>(): { map: T; searchParams: URLSearchParams } {
  const location = useLocation();
  const [searchParams, setSearchParams] = useState(new URLSearchParams(location.search));

  const mapSearchParams = (col: string[][]) => col.reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {} as T);
  const [map, setMap] = useState(mapSearchParams(Array.from(searchParams.entries())));

  useEffect(() => {
    setSearchParams(new URLSearchParams(location.search));
  }, [location]);

  useEffect(() => {
    setMap(mapSearchParams(Array.from(searchParams.entries())));
  }, [searchParams]);

  return { map, searchParams };
}

type ApiCallFunc<U> = (...params: any[]) => Promise<AxiosResponse<U>>;

interface ApiCall<T extends ApiCallFunc<U>, U> {
  func: T;
  params?: Parameters<T>;
}

interface ApiCallOptions {
  subs: any[];
  blocked?: boolean;
}

export function useApi<T extends ApiCallFunc<any>, U = T extends ApiCallFunc<infer K> ? K : unknown>(
  { func, params }: ApiCall<T, U>,
  options?: ApiCallOptions,
) {
  const { blocked, subs } = { blocked: false, subs: [], ...options };

  const [data, setData] = useState<U | undefined>(undefined);
  const [error, setError] = useState<AxiosError<U> | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  function reset(): void {
    if (!loading) {
      setData(undefined);
      setError(undefined);
    }
  }

  useEffect(() => {
    if (blocked) {
      return;
    }

    (async (): Promise<void> => {
      setLoading(true);
      try {
        const res = await func(...(params || []));
        setData(res.data);
        setError(undefined);
      } catch (e: any) {
        setData(undefined);
        setError(e);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line
  }, subs);

  return { data, loading, error, reset };
}

export function useDebounce<T>(value: T, debounceTime = 300): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, debounceTime);

    return () => clearTimeout(handler);
  }, [value, setDebouncedValue, debounceTime]);

  return debouncedValue;
}

export function useTalentCount(debounceTimeMs = 0) {
  const context: FilterContext = useSelector((state: any) => state.filterContext);
  const debouncedContext = useDebounce(context, debounceTimeMs);

  const blocked = debouncedContext !== context || !context.length;
  const subs = [debouncedContext, context];

  const req = useApi({ func: getTalentsSummary, params: [context] }, { blocked, subs });

  const amount: number = (context.length && req.data && req.data["hydra:totalItems"]) || -1;
  const willUpdate = context !== debouncedContext;

  return {
    amount,
    meta: {
      context,
      debouncedContext,
      request: req,
      willUpdate,
    },
  };
}

// eslint-disable-next-line no-shadow
export enum ApiStatus {
  "default",
  "loading",
  "success",
  "error",
}

export function useApiStatus(
  actionType: ActionType,
  actionIdentifier: (action: AnyAction) => string | false,
  callback?: (status: ApiStatus) => void,
): [
  { status: ApiStatus; action?: AnyAction },
  React.Dispatch<React.SetStateAction<{ status: ApiStatus; action?: AnyAction }>>,
] {
  const { apiSuccess, apiErrors } = useApiSelector();

  const [apiStatus, setApiStatus] = useState<{ status: ApiStatus; action?: AnyAction }>({ status: ApiStatus.default });

  const prevApiErrors = usePrevious(apiErrors);
  useEffect(() => {
    if (prevApiErrors !== apiErrors && apiErrors.length > 0) {
      const currentFailures = apiErrors.filter((req: ApiError) => req.type === actionType.FAILURE);
      if (currentFailures.length > 0) {
        currentFailures.forEach((failureAction: ApiError) => {
          if (actionIdentifier(failureAction)) {
            setApiStatus({ status: ApiStatus.error, action: failureAction });
            callback && callback(ApiStatus.error);
          }
        });
      }
    }
  }, [prevApiErrors, apiErrors, apiStatus, actionType, actionIdentifier, callback]);

  const prevApiSuccess = usePrevious(apiSuccess);
  useEffect(() => {
    if (prevApiSuccess !== apiSuccess && apiSuccess.length > 0) {
      const currentSuccess = apiSuccess.filter((req: ApiSuccess) => req.type === actionType.SUCCESS);
      if (currentSuccess.length > 0) {
        currentSuccess.forEach((successAction: ApiSuccess) => {
          if (actionIdentifier(successAction)) {
            setApiStatus({ status: ApiStatus.success, action: successAction });
            callback && callback(ApiStatus.success);
          }
        });
      }
    }
  }, [prevApiSuccess, apiSuccess, apiStatus, actionType, actionIdentifier, callback]);

  return [apiStatus, setApiStatus];
}
