import * as React from "react";
import Select from "react-select";

import { Application } from "../../../../../../interfaces/resources/application";

import { readOnlyStyles } from "../../../../../Form/Select";

import "./index.scss";

export interface Props {
  applications: Application[];
}

export default class ContactTalentOffers extends React.Component<Props, any> {
  public render() {
    const { applications } = this.props;
    return (
      <div className="ContactTalentOffers">
        <Select
          value={applications.map((item: Application) => {
            return {
              key: item.offer["@id"],
              label: `${item.offer.company.name} - ${item.offer.title}`,
              value: item.offer["@id"],
            };
          })}
          styles={readOnlyStyles}
          isMulti={true}
          isDisabled={true}
          getOptionLabel={(option: any) => option.label}
        />
      </div>
    );
  }
}
