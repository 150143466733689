import { Talent } from "../../../interfaces/resources/talent";
import client from "../client";

export function getReferrals(talent: Talent) {
  return client.get(`/api/v2/referrals?referrer=${talent["@id"]}`);
}

export function getReferral(iri: string) {
  return client.get(iri);
}
