import anime from "animejs";
import React, { FC, PropsWithChildren, SyntheticEvent, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { AiOutlineCloseCircle } from "react-icons/ai";

import styles from "./index.module.scss";

export interface Props {
  open?: boolean;
  onCloseModal?: () => void;
}

const ActualModalWrapper: React.FC<React.PropsWithChildren> = (props) => {
  const { children } = props;
  const rootElem = document.getElementById("root");
  return rootElem ? createPortal(children, rootElem) : null;
};

export const ModalWrapper: FC<PropsWithChildren<Props>> = (props: PropsWithChildren<Props>) => {
  const { open, children, onCloseModal } = props;
  const $modalWrapper = useRef(null);
  const $modalContainer = useRef(null);
  const $modalCloseX = useRef(null);
  const [show, setShow] = useState(open);
  const [hidden, setHidden] = useState(!open);

  const showAnimation = (): void => {
    // document.body.style.overflow = "hidden";
    const tl = anime.timeline({
      duration: 300,
      easing: "easeInOutExpo",
    });

    tl.add({
      easing: "linear",
      opacity: 1,
      targets: $modalWrapper.current,
    }).add({
      duration: 800,
      opacity: [0, 1],
      targets: $modalContainer.current,
      translateY: [-10, 0],
    });
  };
  const hideAnimation = (): void => {
    const tl = anime.timeline({
      complete: () => {
        document.body.style.overflow = "";
        setHidden(true);
      },
      duration: 600,
      easing: "easeInOutExpo",
    });

    tl.add({
      opacity: 0,
      targets: $modalContainer.current,
      translateY: 20,
    }).add(
      {
        opacity: 0,
        targets: $modalWrapper.current,
      },
      0,
    );
  };

  const showModal = (): void => {
    setHidden(false);
    setShow(true);
  };
  const hideModal = (): void => {
    setShow(false);
  };

  useEffect(() => {
    if (open) {
      showModal();
    } else {
      hideModal();
    }
  }, [open]);

  const triggerCloseModal = () => {
    if (typeof onCloseModal === "function") {
      onCloseModal();
    }
  };

  const onClickWrapper = (event: SyntheticEvent<HTMLDivElement>) => {
    if (
      event.target === $modalWrapper.current
      || event.target === $modalContainer.current
      || event.target === $modalCloseX.current
    ) {
      triggerCloseModal();
    }
  };

  useEffect(() => {
    if (!show) {
      hideAnimation();
      return;
    }
    showAnimation();
  }, [show]);

  return hidden ? null : (
    <ActualModalWrapper>
      <div className={styles.modalWrapper} ref={$modalWrapper} style={{ opacity: 0 }} onClick={onClickWrapper}>
        <span className={styles.closeModalX} ref={$modalCloseX}>
          <AiOutlineCloseCircle />
        </span>
        <div ref={$modalContainer} className={styles.modalContainer}>
          {children}
        </div>
      </div>
    </ActualModalWrapper>
  );
};
