import { AnyAction } from "redux";
import { CreateApplication, EditApplication, SortApplication } from "../../../actionTypes/applications";
import { RemoveTalentFromHunt } from "../../../actionTypes/hunt";
import { CreateLinkedinResume, EditLinkedInProfile } from "../../../actionTypes/linkedInProfile";
import {
  ClearTalentNames,
  DeleteTalent,
  EditTalent,
  FilterTalentsByName,
  GetTalent,
  GetTalents,
  SortTalent,
  clearTalentsState,
} from "../../../actionTypes/talents";

import { Talent } from "../../../interfaces/resources/talent";

export function talent(state: Talent | null = null, action: AnyAction) {
  switch (action.type) {
    case GetTalent.SUCCESS:
    case EditTalent.SUCCESS:
    case CreateLinkedinResume.SUCCESS:
      return action.talent;
    case SortTalent.SUCCESS:
    case SortApplication.SUCCESS:
    case GetTalent.REQUEST:
      return null;
    case EditLinkedInProfile.SUCCESS:
      return state
        ? {
            ...state,
            linkedInProfile: action.linkedInProfile,
          }
        : null;
    case DeleteTalent.SUCCESS:
      return state;
    case clearTalentsState.DEFAULT:
      return null;
    default:
      return state;
  }
}

export function talents(state: any[] = [], action: AnyAction) {
  switch (action.type) {
    case GetTalents.SUCCESS:
      return action.talents;
    case SortTalent.SUCCESS:
    case RemoveTalentFromHunt.DEFAULT:
    case CreateApplication.SUCCESS:
    case EditTalent.SUCCESS:
    case SortApplication.SUCCESS:
      return action.validation === "reactivationStatus"
        ? [...state.map((e: Talent) => (action.talent && e["@id"] === action.talent["@id"] ? action.talent : e))]
        : state.filter((item: Talent) => action.talent && item["@id"] !== action.talent["@id"]);
    case EditApplication.SUCCESS:
      const iriTalentApplication = action.application.data?.talent?.["@id"] || action.application.talent?.["@id"];
      return state.filter((item: Talent) => item["@id"] !== iriTalentApplication);
    case GetTalent.SUCCESS:
    case CreateLinkedinResume.SUCCESS:
      const index = state.findIndex((a) => a["@id"] === action.talent["@id"]);
      const tals = [...state];
      if (index >= 0) {
        tals[index] = action.talent;
      } else {
        tals.push(action.talent);
      }
      return tals;
    case clearTalentsState.DEFAULT:
      return [];
    default:
      return state;
  }
}

export function talentView(state: any = null, action: AnyAction) {
  switch (action.type) {
    case GetTalents.SUCCESS:
      return action.hydraView || state;
    default:
      return state;
  }
}

export function talentTotalItems(state = 0, action: AnyAction) {
  switch (action.type) {
    case GetTalents.SUCCESS:
      return action.hydraTotalItems;
    case SortTalent.SUCCESS:
    case EditApplication.SUCCESS:
    case CreateApplication.SUCCESS:
    case RemoveTalentFromHunt.DEFAULT:
      return state - 1;
    default:
      return state;
  }
}

export function talentEdited(state: any = {}, action: AnyAction) {
  switch (action.type) {
    case EditTalent.SUCCESS:
      return action.talent;
    default:
      return state;
  }
}

export function talentNames(state: Talent[] = [], action: AnyAction) {
  switch (action.type) {
    case FilterTalentsByName.SUCCESS:
      return action.talentNames;
    case ClearTalentNames.DEFAULT:
      return [];
    default:
      return state;
  }
}
