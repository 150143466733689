import { AnyAction } from "redux";
import { LOCATION_CHANGE } from "redux-first-history";

import {
  CreateApplication,
  EditApplication,
  GetApplication,
  GetApplicationForCollection,
  GetApplications,
  GetApplicationsAccepted,
  SortApplication,
  CreateApplicationAccepted,
  EditApplicationAccepted,
} from "../../../actionTypes/applications";

import { GetCoreUserApplications } from "../../../actionTypes/coreUsers";
import { GetOffer } from "../../../actionTypes/offers";
import { GetTalentApplications } from "../../../actionTypes/talentApplications";
import { Application } from "../../../interfaces/resources/application";
import { routes } from "../../../routes";

export function applications(state: Application[] = [], action: AnyAction): Application[] {
  switch (action.type) {
    case GetTalentApplications.SUCCESS:
      return "applications" === action.stateKey ? action.talentApplications : state;
    case GetApplications.SUCCESS:
      return action.page || !!action.concat ? [...state, ...action.applications] : action.applications;
    case SortApplication.SUCCESS:
      return state.filter((item: Application) => item["@id"] !== action.application["@id"]);
    case CreateApplication.SUCCESS:
      return [...state, action.application];
    case GetApplication.SUCCESS:
    case EditApplication.SUCCESS:
    case GetApplicationForCollection.SUCCESS:
      const index = state.findIndex((a: Application) => a["@id"] === action.application["@id"]);
      const apps = [...state];
      if (index >= 0) {
        apps[index] = action.application;
      } else if (action.application.hasOwnProperty("@id")) {
        apps.push(action.application);
      }
      return apps;
    case CreateApplicationAccepted.SUCCESS:
    case EditApplicationAccepted.SUCCESS:
      const i = state.findIndex((a: Application) => a["@id"] === action.applicationAccepted.application);
      const newApps = [...state];
      if (i !== -1) {
        newApps[i].applicationAccepted = action.applicationAccepted;
      }
      return newApps;
    case LOCATION_CHANGE:
      return [routes.offers.show, routes.talents.show].includes(action.payload.location.pathname) ? [] : state;
    case GetApplications.REQUEST:
      return (typeof action.page === "number" && action.page > 1) || !!action.concat ? state : [];
    case GetCoreUserApplications.REQUEST:
      return [];
    default:
      return state;
  }
}

export function applicationsAccepted(state: Application[] = [], action: AnyAction): Application[] {
  switch (action.type) {
    case GetApplicationsAccepted.SUCCESS:
      return action.page ? [...state, ...action.applications] : action.applications;
    case LOCATION_CHANGE:
      return [routes.offers.show, routes.talents.show].includes(action.payload.location.pathname) ? [] : state;
    case GetApplicationsAccepted.REQUEST:
      return (typeof action.page === "number" && action.page > 1) || !!action.concat ? state : [];
    default:
      return state;
  }
}

export function applicationTotalItems(state = 0, action: AnyAction): number {
  switch (action.type) {
    case GetApplications.SUCCESS:
      return !!action.totalItems ? action.totalItems : state;
    case SortApplication.SUCCESS:
      return state - 1;
    default:
      return state;
  }
}

export function application(state: Application | null = null, action: AnyAction): Application | null {
  switch (action.type) {
    case GetApplication.SUCCESS:
      return action.application;
    case GetOffer.REQUEST:
      return null;
    default:
      return state;
  }
}
