import { t } from "@lingui/macro";
import cx from "classnames";
import * as React from "react";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GetTalentCard } from "../../actionTypes/talentCards";

import { PersonProfileImg } from "../../blocks/personProfileImg";
import { Offer } from "../../interfaces/resources/offer";
import { Talent } from "../../interfaces/resources/talent";
import { TalentCard } from "../../interfaces/resources/talentCard";
import { State } from "../../interfaces/state";
import { routes } from "../../routes";
import { extractPageFromIri } from "../../services/api/helpers";
import { getTalentCardAction } from "../../services/api/talentCard/actions";
import globalStyles from "../../styles/global.module.scss";
import transPhoneCall from "../../translations/constants/phoneCall";
import { useApiSelector } from "../Utils/hook";
import LoaderComp from "../Utils/Loader";
import errorStyles from "./error.module.scss";

import styles from "./index.module.scss";

export interface Props {
  talentCardIri?: string;
  mode?: "preview" | "talentCard";
  onCloseModal?: () => void;
}

export const TalentCardModal: React.FC<Props> = (props: Props) => {
  const { onCloseModal, talentCardIri, mode = "preview" } = props;
  const { apiPendingRequests } = useApiSelector();
  const dispatch = useDispatch();
  const talentCardState = useSelector((state: State) => state.talentCard);
  const talentCardsState = useSelector((state: State) => state.talentCards);
  const [initLoading, setInitLoading] = React.useState(true);
  const [talentCard, setTalentCard] = React.useState<TalentCard | null>(null);
  const talent: Talent = talentCard?.application.talent;
  const offer: Offer = talentCard?.application.offer;

  const renderSaralyBar = (slry: { fixedSalary: number; fullPackage: number }) => {
    const fixed = slry.fixedSalary;
    const full = slry.fullPackage;
    const variable = full - fixed;
    const perc = Math.round((fixed * 100) / full);
    const fixedPercent = perc > 80 ? 80 : perc;

    return !!!fixed || !!!full ? (
      <div className={styles.noticeContainer}>Non précisé</div>
    ) : (
      <div className={styles.barContainer}>
        <div className={styles.fixedPart} style={{ flexBasis: `${fixedPercent}%` }}>
          {fixed}k
        </div>
        <div className={styles.variablePart} style={{ flexBasis: `${100 - fixedPercent}%` }}>
          {variable}k
        </div>
      </div>
    );
  };

  const loading = initLoading || apiPendingRequests.some((apiRequest) => apiRequest.type === GetTalentCard.REQUEST);

  React.useEffect(() => {
    const tStateIndex = talentCardsState.findIndex((e: TalentCard) => talentCardIri === e["@id"]);
    const tCard =
      talentCardState?.["@id"] === talentCardIri
        ? talentCardState
        : tStateIndex >= 0
        ? talentCardsState[tStateIndex]
        : null;
    if (!!!tCard && talentCardIri) {
      dispatch(getTalentCardAction(talentCardIri));
    }
    setTalentCard(tCard);
    setInitLoading(false);
  }, [dispatch, talentCardIri, talentCardsState, talentCardState]);

  if (initLoading || loading) {
    return (
      <div className={errorStyles.errorModal}>
        <div className={errorStyles.errorContainer}>
          <div className={errorStyles.errorTitleContainer}>
            <h2 className={errorStyles.errorTitle}>{t`TalentCard.loading.title`}</h2>
          </div>
          <LoaderComp showText={false} />
        </div>
      </div>
    );
  }

  if (!!!talent || !!!talentCard || !!!offer) {
    return (
      <div className={errorStyles.errorModal}>
        <div className={errorStyles.errorContainer}>
          <div className={errorStyles.errorTitleContainer}>
            <h2 className={errorStyles.errorTitle}>{t`General.wording.error`}</h2>
          </div>
          {!!!talentCard ? (
            <p key={"error-notalentCard"} className={errorStyles.errorText}>
              {t`TalentCardModal.error.noTalentCard`}
            </p>
          ) : null}
          {!!!talent && !!talentCard ? (
            <p key={"error-notalent"} className={errorStyles.errorText}>
              {t`TalentCardModal.error.noTalent`}
            </p>
          ) : null}
          {!!!offer && !!talentCard ? (
            <p key={"error-nooffer"} className={errorStyles.errorText}>
              {t`TalentCardModal.error.noOffer`}
            </p>
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.modal}>
      <div className={styles.talentCardContainer}>
        <div className={styles.tcHeader}>
          <div className={styles.downloadOfferContainer}>
            <h4 className={styles.offerTitle}>{offer.name}</h4>
          </div>
          <div className={styles.talentProfileContainer}>
            <div className={cx(styles.imgContainer, globalStyles.imgProfileContainer)}>
              <div className={globalStyles.imgContent}>
                <PersonProfileImg image={talent.image} alt={`${talent.firstname} ${talent.lastname}`} />
              </div>
            </div>
            <div className={styles.talentInfosContainer}>
              <div className={styles.nameLinkedInContainer}>
                <div className={styles.nameContainer}>
                  <h2 className={styles.tcTitle}>
                    {talent.firstname} {talent.lastname}
                  </h2>
                </div>
              </div>
              <div>
                <span className={styles.talentInfo}>{talent.email}</span>
              </div>
              <div>
                <span className={styles.talentInfo}>{talent.phone}</span>
              </div>
            </div>
            <div className={styles.salaryAvailabilityContainer}>
              <div>
                <h3 className={styles.tcTitle}>{t`TalentCardModal.salary.title`}</h3>
                <div>{renderSaralyBar(talentCard.salary)}</div>
              </div>
              <div>
                {talentCard.availability ? (
                  <>
                    <h3 className={styles.tcTitle}>{t`TalentCardModal.availability.title`}</h3>
                    <div className={styles.noticeContainer}>
                      {t({ id: transPhoneCall.priorNotice[talentCard.availability] })
                        || t`qualification.priorNotice.empty`}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className={cx(styles.tcContent, globalStyles.hideScrollBar)}>
          <div className={styles.tcInnerContent}>
            {talentCard.description ? (
              <div key="tc-description-part" className={styles.tcPart}>
                <h3 className={styles.tcTitle}>{t`TalentCardModal.description.title`}</h3>
                <ReactMarkdown className={styles.tcText}>{talentCard.description}</ReactMarkdown>
              </div>
            ) : null}
            {talentCard.bridges ? (
              <div key="tc-bridges-part" className={styles.tcPart}>
                <h3 className={styles.tcTitle}>{t`TalentCardModal.bridges.title`}</h3>
                <ReactMarkdown className={styles.tcText}>{talentCard.bridges}</ReactMarkdown>
              </div>
            ) : null}
            {talentCard.positions ? (
              <div key="tc-positions-part" className={styles.tcPart}>
                <h3 className={styles.tcTitle}>{t`TalentCardModal.positions.title`}</h3>
                <ReactMarkdown className={styles.tcText}>{talentCard.positions}</ReactMarkdown>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {talentCard && talentCard.application && mode === "preview" ? (
        <div className={styles.modalPreviewCtaContainer}>
          <Link
            to={`${extractPageFromIri("applications", talentCard.application["@id"])}/talent_card`}
            className={cx(styles.primaryCta, globalStyles.cta, globalStyles.primaryCta)}
          >
            {t`Constant.update`}
          </Link>
        </div>
      ) : null}
      {mode === "talentCard" ? (
        <div className={styles.modalTalentCardCtaContainer}>
          <div>
            <Link
              to={routes.applications.dashboard}
              className={cx(styles.secondaryCta, globalStyles.cta, globalStyles.darkCta, globalStyles.inverted)}
            >
              {t`TalentCardModal.seeDashboardCta.text`}
            </Link>
          </div>
          <div>
            <Link
              to={extractPageFromIri("talents", talent["@id"])}
              className={cx(styles.secondaryCta, globalStyles.cta, globalStyles.darkCta, globalStyles.inverted)}
            >
              {t`TalentCardModal.seeTalentProfileCta.text`}
            </Link>
          </div>
          <div className={styles.updateCtaContainer}>
            <button
              type="button"
              onClick={() => {
                if (typeof onCloseModal === "function") {
                  onCloseModal();
                }
              }}
              className={cx(styles.primaryCta, globalStyles.cta, globalStyles.primaryCta)}
            >
              {t`TalentCardModal.shortlistCta.text`}
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};
