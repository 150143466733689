import { t } from "@lingui/macro";
import anime from "animejs";
import cx from "classnames";
import { differenceInDays, format, parseISO, setHours, setMinutes } from "date-fns";
import fr from "date-fns/locale/fr";
import * as React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { BsCalendar, BsHeartFill, BsPatchCheckFill, BsPatchExclamationFill } from "react-icons/bs";
import { HiMenu } from "react-icons/hi";
import { ImWhatsapp } from "react-icons/im";
import { IoClose } from "react-icons/io5";
import { useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";

import { GoldenTag } from "../../components/Design/GoldenTag";
import { ModalWrapper } from "../../components/ModalWrapper";
import { Tooltip } from "../../components/Tooltip";
import { useGetLastLap } from "../../components/Utils/hook";
import {
  Application,
  applicationClientStatus,
  applicationStatuses,
  LastLap,
} from "../../interfaces/resources/application";
import { offerStatuses } from "../../interfaces/resources/offer";
import { State } from "../../interfaces/state";
import { ColumnProps } from "../../pages/ApplicationsDashboardPage/Column/data";
import { extractIdFromIri, extractPageFromIri, generateIriFromId } from "../../services/api/helpers";
import globalStyles from "../../styles/global.module.scss";

import transApplication from "../../translations/constants/application";
import { labelType, LabelType } from "../../translations/constants/label";
import { Label } from "../labels";
import { ClientApplicationLabel } from "../labels/ClientApplicationLabel";
import { LateStageLabel } from "../labels/LateStageLabel";
import { PersonProfileImg } from "../personProfileImg";
import { ApplicationAcceptedModal } from "./ApplicationAcceptedModal";
import styles from "./index.module.scss";
import { InterviewItem } from "./InterviewsLabel";
import { MessageModalCta } from "./MessageModal";
import { RefuseModal } from "./RefuseModal";
import { SendTalentModal } from "./SendTalentModal";
import { UpdateModal } from "./UpdateModal";

interface Props {
  application: Application;
  column: ColumnProps;
  editApplication?: (iri: string, req: Partial<Application>) => void;
  onDateSelect: (date: Date, application: Application) => void;
  isRefusedMode?: boolean;
  modalOpen?: boolean;
  modalAppId?: string;
  setModalOpen?: any;
  showOfferInfo?: boolean;
}

type PanelMenu = "application" | "interviews" | undefined;

export const CardApplication: React.FC<Props> = ({
  application,
  column,
  editApplication = () => {
    return;
  },
  onDateSelect,
  isRefusedMode = false,
  modalOpen = false,
  modalAppId,
  setModalOpen,
  showOfferInfo = true,
}: Props) => {
  registerLocale("fr", fr);

  const currentUser = useSelector((state: State) => state.currentUser);

  const [rdvDate, setRdvDate] = React.useState<Date | null>(null);
  const [openPanel, setOpenPanel] = React.useState<PanelMenu>(undefined);
  const [isPanelOpen, setIsPanelOpen] = React.useState(false);
  const [hasUnreadMessage, setHasUnreadMessage] = React.useState<boolean>(false);
  const [isLastLap, setIsLastLap] = React.useState<boolean>(false);
  const [isBeforeLastLap, setIsBeforeLastLap] = React.useState<boolean>(false);
  const isToUpdate =
    !isRefusedMode
    && column.id === "column-0"
    && differenceInDays(new Date(), parseISO(application.createdAt)) > 3
    && application.status === "contacted";
  const [openSendTalentModal, setOpenSendTalentModal] = React.useState<boolean>(false);
  const $card = React.useRef<HTMLDivElement>(null);
  const $cardContent = React.useRef<HTMLDivElement>(null);
  const columnCondition = ["column-2", "column-a", "column-c"].includes(column.id);
  const statusCondition = [applicationStatuses.callBooked, applicationStatuses.interviews].includes(application.status);
  const lastStageApplications = (application.talent.lateStageApplications || []).filter(
    (ap) => generateIriFromId("applications", ap.applicationId) !== application["@id"],
  );

  const talentCardClientEvents = application.talentCard?.talentCardClientEvents;
  const refusedStatuses = [
    applicationStatuses.refusedElinoi,
    applicationStatuses.refusedStartup,
    applicationStatuses.refusedTalent,
  ];
  const whatsappPhone = !!application.talent.phone
    ? application.talent.phone.replace("+", "").replace(/\s/g, "")
    : undefined;

  const DatePickerCustomInput = React.forwardRef<HTMLButtonElement, { value?: string; onClick?: () => void }>(
    ({ value = "", onClick }, ref) => (
      <button className={styles.datePickerCustomBtn} onClick={onClick} ref={ref}>
        <span className={styles.datePickerIcon}>
          <BsCalendar />
        </span>
        <span className={styles.datePickerText}>{!!value ? value : "Sélectionner une date"}</span>
      </button>
    ),
  );

  // Si la candidature est acceptée mais qu'il manque une ou plusieurs infos, on affiche le CTA
  const applicationAcceptedMissingInfos =
    application.status === applicationStatuses.accepted
    && !(
      application.applicationAccepted?.commission
      && application.applicationAccepted?.fixedSalary
      && application.applicationAccepted?.fullPackage
      && application.applicationAccepted?.startDate
    );

  const onCloseSendTalentModal = () => {
    setOpenSendTalentModal(false);
  };
  const onClickHandleGoodMatch = () => {
    editApplication(application["@id"], { goodMatch: !application.goodMatch });
  };
  const toggleMenu = (type: PanelMenu) => {
    if (type !== openPanel) {
      setOpenPanel(type);
      setIsPanelOpen(true);
    } else {
      setIsPanelOpen(false);
    }
  };
  const addCall = (): void => {
    if (rdvDate) {
      editApplication(application["@id"], {
        status: applicationStatuses.callBooked,
        callBooked: format(rdvDate, "yyyy-MM-dd HH:mm:ss"),
      });
      setRdvDate(null);
    }
  };

  const showPanel = () => {
    if ($card.current) {
      $card.current.style.overflow = "hidden";
    }
    if ($cardContent.current) {
      $cardContent.current.style.boxShadow = "-1px 0 10px 0 rgba(0, 0, 0, 0.14)";
    }

    const tl = anime.timeline({
      duration: 400,
      easing: "cubicBezier(0.74, 0.04, 0.34, 0.95)",
    });

    tl.add({
      translateX: 80,
      targets: $cardContent.current,
    });
  };
  const hidePanel = () => {
    const tl = anime.timeline({
      duration: 400,
      easing: "cubicBezier(0.74, 0.04, 0.34, 0.95)",
      complete: () => {
        if ($card.current) {
          $card.current.style.overflow = "";
        }
        if ($cardContent.current) {
          $cardContent.current.style.boxShadow = "";
          $cardContent.current.style.transform = "";
        }
        setOpenPanel(undefined);
      },
    });

    tl.add({
      translateX: 0,
      targets: $cardContent.current,
    });
  };

  useGetLastLap(
    application,
    (obj: LastLap) => {
      setIsLastLap(obj.last);
      setIsBeforeLastLap(obj.beforeLast);
    },
    () => {
      setIsLastLap(false);
      setIsBeforeLastLap(false);
    },
  );

  React.useEffect(() => {
    if (!!!$cardContent.current) {
      return;
    }
    if (isPanelOpen) {
      showPanel();
    } else {
      hidePanel();
    }
  }, [isPanelOpen]);

  React.useEffect(() => {
    let hasUnread = false;
    application.applicationMessages?.forEach((apM) => {
      if (
        (!!!apM.coreUser || apM.coreUser["@id"] !== currentUser?.["@id"])
        && !apM.coreUserRead?.includes(currentUser?.["@id"] || "")
      ) {
        hasUnread = true;
      }
    });
    setHasUnreadMessage(hasUnread);
  }, [application]);
  return (
    <>
      <div className={styles.card} ref={$card}>
        <div className={styles.menuPanelContainer}>
          <span className={styles.applicationID}>id : {extractIdFromIri(application["@id"])}</span>
          <div className={styles.menuPanel}>
            {openPanel === "application" ? (
              <div key={`application-panel-${application["@id"]}`} className={styles.menuPanelContent}>
                <ul className={styles.menuPanelList}>
                  {column.id === "column-d" ? (
                    <li className={styles.menuPanelItem}>
                      <ApplicationAcceptedModal application={application}>
                        <div className={cx(styles.menuPanelItemContent, styles.acceptedMenuItemContent)}>
                          {t`applicationCard.menu.accepted`}
                        </div>
                      </ApplicationAcceptedModal>
                    </li>
                  ) : null}
                  {!["column-0", "column-1"].includes(column.id) ? (
                    <li className={styles.menuPanelItem}>
                      <Link
                        className={styles.menuPanelItemContent}
                        target="_blank"
                        rel="noopener noreferrer"
                        to={`${extractPageFromIri("applications", application["@id"])}/talent_card`}
                      >
                        Talent Card
                      </Link>
                    </li>
                  ) : null}
                  {["column-2", "column-3"].includes(column.id) && currentUser ? (
                    <li className={styles.menuPanelItem}>
                      <span
                        className={styles.menuPanelItemContent}
                        onClick={() => {
                          setOpenSendTalentModal(true);
                        }}
                      >{t`applicationCard.menu.send`}</span>
                    </li>
                  ) : null}
                  <li className={cx(styles.menuPanelItem, styles.refusedMenuPanelItem)}>
                    <RefuseModal
                      application={application}
                      editApplication={editApplication}
                      column={column}
                      status={column.refuseOptions[0]}
                      type={column.id === "column-1" ? "options" : "textfield"}
                    >
                      <div className={cx(styles.menuPanelItemContent, styles.refusedMenuItemContent)}>
                        {t`applicationCard.menu.refuse`}
                      </div>
                    </RefuseModal>
                  </li>
                </ul>
              </div>
            ) : openPanel === "interviews" ? (
              <div key={`interviews-panel-${application["@id"]}`} className={styles.menuPanelContent}>
                <ul className={styles.menuPanelList}>
                  {application.interviewsAt
                    && application.interviewsAt.map((int, i) => {
                      const interviewDate = parseISO(int);
                      const intDate = format(interviewDate, "dd/MM/yyyy");
                      return (
                        <li
                          key={`interview-item-${application["@id"]}-${int}-${i}`}
                          className={cx(styles.menuPanelItem)}
                        >
                          <InterviewItem
                            application={application}
                            interview={int}
                            interviews={application.interviewsAt}
                            editApplication={editApplication}
                          >
                            <div className={styles.menuPanelItemContent}>{intDate}</div>
                          </InterviewItem>
                        </li>
                      );
                    })}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
        <div className={styles.cardContent} ref={$cardContent}>
          <div
            className={cx(styles.headerCardContainer, styles.labelTooltipContainer, {
              [styles.lastLap]: isLastLap,
              [styles.beforeLastLap]: isBeforeLastLap,
              [styles.isUrgent]: isToUpdate,
            })}
          >
            {isToUpdate ? (
              <span
                className={cx(globalStyles.labelTooltip, styles.labelTooltip)}
              >{t`applicationCard.contacted.toUpdate`}</span>
            ) : null}
            <div className={styles.headerItem}>
              <div
                className={cx(styles.menu, {
                  [styles.applicationPanelOpen]: openPanel === "application",
                })}
                onClick={(): void => {
                  toggleMenu("application");
                }}
              >
                {openPanel === "application" ? <IoClose /> : <HiMenu />}
              </div>
            </div>
            <div className={cx(styles.headerItem, styles.teammateItem)}>
              {application.offer.owners && application.offer.owners.length
                ? application.offer.owners.map((owner) => (
                    <div className={styles.teammate} key={owner["@id"]}>
                      <Tooltip
                        content={
                          <div className={styles.tcCountTooltip}>
                            <div>{owner.firstname}</div>
                          </div>
                        }
                      >
                        <PersonProfileImg image={owner.image} />
                      </Tooltip>
                    </div>
                  ))
                : null}
            </div>
            {!!application?.clientContact ? (
              <div className={styles.headerItem}>
                <ClientApplicationLabel clientContact={application.clientContact} />
              </div>
            ) : null}
            {applicationAcceptedMissingInfos ? (
              <div className={styles.headerItem}>
                <ApplicationAcceptedModal application={application}>
                  <Label
                    key={`application-card-late-stage-label-${application["@id"]}-${application.status}`}
                    type={labelType.accepted_missing_infos}
                  />
                </ApplicationAcceptedModal>
              </div>
            ) : null}
            {refusedStatuses.includes(application.status) && (
              <>
                <div className={styles.headerItem}>
                  <div className={styles.refusedLabelContainer}>
                    <Label
                      key={`application-card-late-stage-label-${application["@id"]}-${application.status}`}
                      type={application.status as keyof LabelType}
                      tooltip={
                        application.refusedReason
                          ? !!t({ id: transApplication.refusedReason[application.refusedReason] })
                            ? t({ id: transApplication.refusedReason[application.refusedReason] })
                            : application.refusedReason
                          : "..."
                      }
                    />
                  </div>
                </div>
              </>
            )}
            {application.offer?.mode === "spontaneous" && (
              <div className={styles.headerItem}>
                <div>
                  <Label type="offer_spontaneous" />
                </div>
              </div>
            )}
            <div className={styles.headerItem}>
              <div className={styles.goodMatchContainer}>
                <BsHeartFill
                  className={cx(styles.noGoodMatch, { [styles.goodMatchIcon]: application.goodMatch })}
                  onClick={onClickHandleGoodMatch}
                />
              </div>
            </div>

            <div className={styles.headerItem}>
              <div className={styles.daysPassed}>
                {differenceInDays(
                  new Date(),
                  parseISO(
                    application.interviewAt && column.id !== "column-b"
                      ? application.interviewAt
                      : application.updatedAt,
                  ),
                )}{" "}
                j
              </div>
            </div>
          </div>
          <div className={styles.bodyCardContainer}>
            <div className={styles.offerTalentPartsContainer}>
              {showOfferInfo ? (
                <div className={styles.offerPartContainer}>
                  <h5 className={styles.companyNameContainer}>
                    <Link
                      className={styles.companyNameLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      to={extractPageFromIri("companies", application.offer.company["@id"])}
                    >
                      {application.offer.company.name}
                    </Link>
                  </h5>
                  <h4 className={styles.offerTitleContainer}>
                    <Link
                      className={styles.offerTitleLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      to={extractPageFromIri("offers", application.offer["@id"])}
                    >
                      {application.offer.title}
                    </Link>
                  </h4>
                  {application.offer.status === offerStatuses.standbyElinoi ? (
                    <div className={styles.offerLabelContainer}>
                      <Label type="offer_standby" />
                    </div>
                  ) : null}
                </div>
              ) : null}
              <div className={styles.talentPartContainer}>
                <div className={styles.talentPhotoContainer}>
                  <PersonProfileImg image={application.talent.image} />
                </div>
                <div className={styles.talentInfosContainer}>
                  <div>
                    <Link
                      to={extractPageFromIri("talents", application.talent["@id"])}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.name}
                    >{`${application.talent.firstname} ${application.talent.lastname}`}</Link>
                  </div>
                  {!!application.talent.phone && (
                    <div className={styles.phoneContainer}>
                      <span className={styles.infosSupp}>{application.talent.phone}</span>
                    </div>
                  )}
                  {application.talent.talentProfile?.golden || !!application.talent.phone ? (
                    <div className={styles.talentIconsContainer}>
                      {application.talent.talentProfile?.golden ? (
                        <div>
                          <GoldenTag className={styles.goldenTag} />
                        </div>
                      ) : null}
                      {!!application.talent.phone ? (
                        <div>
                          <a
                            className={styles.whatsappBtn}
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://wa.me/${whatsappPhone}`}
                            title={t`Action.whatsapp.contact.text`}
                          >
                            <ImWhatsapp />
                          </a>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              </div>

              {lastStageApplications?.length ? (
                <div className={styles.labelsContainer}>
                  {lastStageApplications?.map((lap, i) => {
                    return (
                      <LateStageLabel
                        key={`application-card-late-stage-label-${lap.applicationId}-${i}`}
                        lap={lap}
                        className={styles.labelContainer}
                      />
                    );
                  })}
                </div>
              ) : null}
            </div>
            <div className={styles.clientPartContainer}>
              {application.clientStatus
              && application.clientStatus !== applicationClientStatus.clientPreSend
              && application.status !== applicationStatuses.accepted ? (
                <div className={styles.clientStatusContainer}>
                  <div className={styles.clientStatusLabel}>Statut client</div>
                  <div>{t({ id: transApplication.clientStatus[application.clientStatus] })}</div>
                </div>
              ) : null}
              <div className={styles.clientTalentCardContainer}>
                {application.talentCard ? (
                  <div
                    className={cx(styles.clientTalentCardCountContainer, {
                      [styles.tcOpened]: talentCardClientEvents?.length,
                    })}
                  >
                    <Tooltip
                      content={
                        <div className={styles.tcCountTooltip}>
                          {talentCardClientEvents?.length ? (
                            <>
                              <div>Talent card ouverte ({talentCardClientEvents.length} fois)</div>
                              <div className={styles.clientTalentCardDetailCta}>
                                {talentCardClientEvents.length >= 5 ? <span>Les 5 + récentes :</span> : null}
                                <ul>
                                  {talentCardClientEvents.map((tcE, i) =>
                                    i < 5 ? (
                                      <li key={`${tcE["@id"]}-${i}`}>
                                        {!!tcE.clientContact?.firstname ? tcE.clientContact?.firstname : "[supprimé]"}{" "}
                                        le {format(parseISO(tcE.createdAt), "dd/MM à HH'h'mm")}
                                      </li>
                                    ) : null,
                                  )}
                                </ul>
                              </div>
                            </>
                          ) : (
                            <div>Talent card pas ouverte</div>
                          )}
                        </div>
                      }
                    >
                      {talentCardClientEvents?.length ? <BsPatchCheckFill /> : <BsPatchExclamationFill />}
                    </Tooltip>
                  </div>
                ) : null}
                <div>
                  <MessageModalCta application={application} hasUnreadMessage={hasUnreadMessage} />
                </div>
              </div>
            </div>
          </div>
          {(columnCondition || statusCondition) && !!!column.isDragDisabled ? (
            <div className={styles.footerCardContainer}>
              {application.interviewsAt && application.interviewsAt.length ? (
                <div className={cx(styles.footerItem, styles.interviewsAtCtaItem)}>
                  <div
                    className={cx(styles.interviewsListCta, {
                      [styles.interviewsPanelOpen]: openPanel === "interviews",
                    })}
                    onClick={(): void => {
                      toggleMenu("interviews");
                    }}
                  >
                    <span className={styles.interviewsAtCtaSvgContainer}>
                      {openPanel === "interviews" ? <IoClose /> : <AiOutlineUnorderedList />}
                    </span>
                    <span>{application.interviewsAt.length}</span>
                  </div>
                </div>
              ) : null}
              {column.id === "column-a" || (column.id === "column-2" && !application.nextCallBooked) ? (
                <div className={styles.footerItem}>
                  <div key={`date-picker-${column.id}-${application["@id"]}`}>
                    <DatePicker
                      className={styles.datePicker}
                      popperClassName={styles.datePopper}
                      timeClassName={() => styles.timeSelect}
                      selected={rdvDate}
                      onChange={(d): void => {
                        return setRdvDate(d as Date);
                      }}
                      onCalendarClose={() => {
                        if ("column-a" === column.id && rdvDate) {
                          onDateSelect(rdvDate, application);
                        } else {
                          addCall();
                        }
                      }}
                      showTimeSelect={"column-2" === column.id}
                      timeIntervals={10}
                      minTime={setHours(setMinutes(new Date(), 0), 9)}
                      maxTime={setHours(setMinutes(new Date(), 0), 20)}
                      dateFormat="dd/MM/yyyy HH:mm"
                      placeholderText="Sélectionner une date"
                      locale="fr"
                      customInput={<DatePickerCustomInput />}
                    />
                  </div>
                </div>
              ) : null}
              {(application.status === applicationStatuses.interviews && column.id !== "column-a")
              || (application.status === applicationStatuses.callBooked && application.nextCallBooked) ? (
                <div className={styles.footerItem}>
                  <div className={styles.callBookedTextContainer}>
                    <span className={styles.callBookedText}>
                      {application.interviewAt ? (
                        <>
                          Entretien dans{" "}
                          {differenceInDays(parseISO(application.interviewAt), new Date().setHours(0, 0, 0, 0))} jour(s)
                          ({format(parseISO(application.interviewAt), "dd/MM/yyyy")})
                        </>
                      ) : application.nextCallBooked ? (
                        <>Rendez-vous le {format(parseISO(application.nextCallBooked), "dd/MM à HH'h'mm")}</>
                      ) : null}
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
          {!!application.talent.talentProfile?.lastQualification
          && !!modalAppId
          && typeof setModalOpen === "function"
          && new Date(application.talent.talentProfile.lastQualification.createdAt) < new Date("03/03/2022")
          && application["@id"] === modalAppId ? ( // 03/03/2022 est la date de mise en prod de la nouvelle qualif -> les qualifs datant d'avant doivent être mises à jour
            <UpdateModal application={application} setModalOpen={setModalOpen} modalOpen={modalOpen} />
          ) : null}
        </div>
      </div>
      <ModalWrapper open={openSendTalentModal} onCloseModal={onCloseSendTalentModal}>
        <SendTalentModal
          application={application}
          onCloseModal={onCloseSendTalentModal}
          editApplication={editApplication}
        />
      </ModalWrapper>
    </>
  );
};
