export const routes = {
  admin: {
    userList: "/admin/users",
  },
  app: {
    home: "/",
    dashboard: "/dashboard",
  },
  applications: {
    dashboard: "/applications/dashboard",
    talentCard: "/applications/:id/talent_card",
  },
  auth: {
    login: "/login",
  },
  companies: {
    edit: "/companies/:id/edit",
    new: "/companies/new",
    search: "/companies/search",
    show: "/companies/:id",
  },
  coreUser: {
    profile: "/me",
  },
  hunts: {
    hunt: "/hunts/",
  },
  offers: {
    contactTalent: "/offers/:id/contact_talent",
    edit: "/offers/:id/edit",
    editAdvice: "/offers/:id/edit#advice",
    new: "/offers/new",
    newAdvice: "/offers/new#advice",
    search: "/offers/search",
    shortlist: "/offers/:id/shortlist",
    show: "/offers/:id",
    dashboard: "/offers/dashboard",
  },
  talents: {
    myTalents: "/talents/my-talents",
    noQualifTalents: "/talents/no-qualification",
    search: "/talents/search",
    show: "/talents/:id",
    sort: "/talents/sort_new",
    chasse: "talents/chasse",
  },
};

export const hashes = {
  companyDetails: {
    recap: "#",
    offers: "#offers",
    contacts: "#contacts",
  },
  offerDetails: {
    recap: "#",
    manageApplications: "#manage-applications",
    contacts: "#contacts",
  },
  dashboard: {
    preProcess: "#pre-process",
    inProcess: "#in-process",
  },
  talentDetails: {
    recap: "#",
    phoneCall: "#phoneCall",
    applicationList: "#applications",
    manageApplications: "#manage-applications",
    referrals: "#referrals",
    linkedInResume: "#linkedin-resume",
  },
};
