import { createBrowserHistory } from "history";
import { AnyAction, applyMiddleware, combineReducers, compose, legacy_createStore as createStore } from "redux";
import { createReduxHistoryContext } from "redux-first-history";
import { reducer as formReducer } from "redux-form";
import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";

import { CreateCompany } from "./actionTypes/companies";
import { CreateOffer } from "./actionTypes/offers";

import * as applicationReducers from "./services/api/applications/reducers";
import applicationSaga from "./services/api/applications/saga";
import * as authenticationReducer from "./services/api/authentication/reducers";
import authenticationSaga from "./services/api/authentication/saga";
import * as calendlyReducer from "./services/api/calendlyEvent/reducers";
import calendlyEventSaga from "./services/api/calendlyEvent/saga";
import companyImageSaga from "./services/api/company/companyImage/saga";
import * as contractReducers from "./services/api/company/contract/reducers";
import contractSaga from "./services/api/company/contract/saga";
import * as companyReducers from "./services/api/company/reducers";
import companySaga from "./services/api/company/saga";
import * as competenceReducers from "./services/api/competence/reducers";
import competenceSaga from "./services/api/competence/saga";
import * as contactReducers from "./services/api/contact/reducers";
import contactSaga from "./services/api/contact/saga";
import * as coreUserReducers from "./services/api/coreUser/reducers";
import coreUserSaga from "./services/api/coreUser/saga";
import * as desiredPlaceReducers from "./services/api/desiredPlaces/reducers";
import desiredPlaceSaga from "./services/api/desiredPlaces/saga";
import * as experienceYearsReducers from "./services/api/experienceYears/reducers";
import experienceYearsSaga from "./services/api/experienceYears/saga";
import * as huntReducers from "./services/api/hunt/reducers";
import huntSaga from "./services/api/hunt/saga";
import * as interviewStepTypeReducers from "./services/api/interviewStepType/reducers";
import interviewStepTypeSaga from "./services/api/interviewStepType/saga";
import * as offerApplicationReducers from "./services/api/offer/applications/reducers";
import offerApplicationsSaga from "./services/api/offer/applications/saga";
import * as interviewStepsReducers from "./services/api/offer/interviewStep/reducers";
import interviewStepSaga from "./services/api/offer/interviewStep/saga";
import * as offerInternalViewReducers from "./services/api/offer/offerInternalView/reducers";
import offerInternalViewSaga from "./services/api/offer/offerInternalView/saga";
import * as offerTalentViewReducers from "./services/api/offer/offerTalentView/reducers";
import offerTalentViewSaga from "./services/api/offer/offerTalentView/saga";
import * as offerReducers from "./services/api/offer/reducers";
import offerSaga from "./services/api/offer/saga";
import * as apiReducers from "./services/api/reducer";
import * as referralReducers from "./services/api/referral/reducers";
import referralSaga from "./services/api/referral/saga";
import * as responsibilityReducers from "./services/api/responsibilities/reducers";
import responsibilitySaga from "./services/api/responsibilities/saga";
import * as responsibilityTypesReducers from "./services/api/responsibilityType/reducers";
import responsibilityTypesSaga from "./services/api/responsibilityType/saga";
import apiSaga from "./services/api/saga";
import * as salaryExpectationsReducers from "./services/api/salaryExpectations/reducers";
import salaryExpectationsSaga from "./services/api/salaryExpectations/saga";
import * as schoolReducers from "./services/api/school/reducers";
import schoolSaga from "./services/api/school/saga";
import * as sectorReducers from "./services/api/sector/reducers";
import sectorSaga from "./services/api/sector/saga";
import * as talentLinkedInProfileReducers from "./services/api/talent/linkedInProfile/reducers";
import linkedInProfileSaga from "./services/api/talent/linkedInProfile/saga";
import * as phoneCallReducers from "./services/api/talent/phoneCalls/reducers";
import phoneCallsSaga from "./services/api/talent/phoneCalls/saga";
import * as quizAnswersReducer from "./services/api/talent/quizAnswers/reducer";
import quizAnswersSaga from "./services/api/talent/quizAnswers/saga";
import * as talentReducers from "./services/api/talent/reducers";
import talentSaga from "./services/api/talent/saga";
import * as talentApplicationReducers from "./services/api/talent/talentApplication/reducers";
import talentApplicationSaga from "./services/api/talent/talentApplication/saga";
import * as talentProfileReducers from "./services/api/talent/talentProfile/reducers";
import talentProfileSaga from "./services/api/talent/talentProfile/saga";
import * as talentQualificationReducers from "./services/api/talent/talentQualification/reducers";
import talentQualificationSaga from "./services/api/talent/talentQualification/saga";
import * as talentCardReducers from "./services/api/talentCard/reducers";
import talentCardSaga from "./services/api/talentCard/saga";
import * as huntStateReducers from "./services/state/hunt/reducers";
import * as stateReducers from "./services/state/reducers";

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const rootReducer = combineReducers({
  ...apiReducers,
  ...applicationReducers,
  ...authenticationReducer,
  ...calendlyReducer,
  ...companyReducers,
  ...competenceReducers,
  ...contactReducers,
  ...contractReducers,
  ...coreUserReducers,
  ...desiredPlaceReducers,
  form: formReducer.plugin({
    company: (state: any, action: AnyAction) => {
      if (CreateCompany.SUCCESS === action.type && "company" === action.form) {
        return undefined;
      }
      return state;
    },
    offer: (state: any, action: AnyAction) => {
      if (CreateOffer.SUCCESS === action.type && "offer" === action.form) {
        return undefined;
      }
      return state;
    },
  }),
  ...experienceYearsReducers,
  ...huntReducers,
  ...huntStateReducers,
  ...interviewStepsReducers,
  ...interviewStepTypeReducers,
  ...offerApplicationReducers,
  ...offerInternalViewReducers,
  ...offerReducers,
  ...offerTalentViewReducers,
  ...phoneCallReducers,
  ...quizAnswersReducer,
  ...referralReducers,
  ...responsibilityReducers,
  ...responsibilityTypesReducers,
  ...salaryExpectationsReducers,
  ...schoolReducers,
  ...sectorReducers,
  ...stateReducers,
  ...talentApplicationReducers,
  ...talentCardReducers,
  ...talentLinkedInProfileReducers,
  ...talentProfileReducers,
  ...talentQualificationReducers,
  ...talentReducers,
  router: routerReducer,
});

function* rootSaga() {
  yield all([
    apiSaga(),
    applicationSaga(),
    authenticationSaga(),
    calendlyEventSaga(),
    companyImageSaga(),
    companySaga(),
    competenceSaga(),
    contactSaga(),
    contractSaga(),
    coreUserSaga(),
    desiredPlaceSaga(),
    experienceYearsSaga(),
    huntSaga(),
    interviewStepSaga(),
    interviewStepTypeSaga(),
    linkedInProfileSaga(),
    offerApplicationsSaga(),
    offerInternalViewSaga(),
    offerSaga(),
    offerTalentViewSaga(),
    phoneCallsSaga(),
    quizAnswersSaga(),
    referralSaga(),
    responsibilitySaga(),
    responsibilityTypesSaga(),
    salaryExpectationsSaga(),
    schoolSaga(),
    sectorSaga(),
    talentApplicationSaga(),
    talentCardSaga(),
    talentProfileSaga(),
    talentQualificationSaga(),
    talentSaga(),
  ]);
}

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const attachPathNameToAction = (store: any) => (next: any) => (action: any) => {
  action.pathname = store.getState().router?.location?.pathname; //<-----passing pathname
  next(action);
};
export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(routerMiddleware, sagaMiddleware, attachPathNameToAction)),
);

export const history = createReduxHistory(store);

sagaMiddleware.run(rootSaga);
