import { AxiosResponse } from "axios";
import { push } from "connected-react-router";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import { CreateCompany, DeleteCompany, EditCompany, GetCompanies, GetCompany } from "../../../actionTypes/companies";
import { CreateCompanyImage, DeleteCompanyImage, GetCompanyCompanyImages } from "../../../actionTypes/companyImages";
import { HydraCollection } from "../../../interfaces/api/hydra-collection";

import { Company, CompanyImage } from "../../../interfaces/resources/company";
import { routes } from "../../../routes";
import { apiFailureAction } from "../actions";
import { extractPageFromIri } from "../helpers";
import * as apiClient from "./api";
import * as apiCompanyImage from "./companyImage/api";

export function* createCompany(action: any): any {
  try {
    const response = yield call(apiClient.createCompany, action.req);
    const company = response.data;

    for (const companyImage of action.req.companyImages) {
      companyImage.company = company["@id"];
      const ciResponse = yield call(apiCompanyImage.createCompanyImage, companyImage);
      yield put({
        companyImage: ciResponse.data,
        type: CreateCompanyImage.SUCCESS,
      });
    }

    yield put({
      company,
      form: action.form,
      type: CreateCompany.SUCCESS,
    });

    if (!!action.redirectOnSuccess) {
      const url =
        action.redirectOnSuccess === routes.companies.show
          ? extractPageFromIri("companies", company["@id"])
          : action.redirectOnSuccess;
      yield put(push(url));
    }
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* deleteCompany(action: any): any {
  try {
    const response = yield call(apiClient.deleteCompany, action.iri);
    const data = response.data;
    yield put({
      data,
      type: DeleteCompany.SUCCESS,
    });
    yield put(push(routes.companies.search));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* editCompany(action: any): any {
  try {
    const response = yield call(apiClient.editCompany, action.req, action.validation);
    const company = response.data;

    if (action.form) {
      const cIResponse = yield call(apiCompanyImage.getCompanyCompanyImages, action.req, action.filterContext);
      const originalCompanyImages: any = cIResponse.data;
      yield put({
        originalCompanyImages,
        type: GetCompanyCompanyImages.SUCCESS,
      });

      const companyImages: CompanyImage[] = action.req.companyImages || [];
      for (const companyImage of originalCompanyImages["hydra:member"]) {
        if (!(companyImages || []).some((e: any) => e.hasOwnProperty("@id") && e["@id"] === companyImage["@id"])) {
          const aResponse = yield call(apiCompanyImage.deleteCompanyImage, companyImage);
          yield put({
            companyImage: aResponse,
            type: DeleteCompanyImage.SUCCESS,
          });
        }
      }

      for (let companyImage of companyImages) {
        companyImage.company = action.req["@id"];
        if (!companyImage.hasOwnProperty("@id")) {
          const ciResponse = yield call(apiCompanyImage.createCompanyImage, companyImage);
          companyImage = Object.assign(companyImage, ciResponse.data);
          yield put({
            companyImage: ciResponse.data,
            type: CreateCompanyImage.SUCCESS,
          });
        }
      }
    }

    yield put({
      company,
      form: action.form,
      type: EditCompany.SUCCESS,
    });

    if (!!action.redirectOnSuccess) {
      const url =
        action.redirectOnSuccess === routes.companies.show
          ? extractPageFromIri("companies", company["@id"])
          : action.redirectOnSuccess;
      yield put(push(url));
    }
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* getCompanies(action: any): any {
  try {
    const response: AxiosResponse<HydraCollection<Company>> = yield call(apiClient.getCompanies, action.filterContext);
    const companies = response.data;
    yield put({
      companies,
      companyView: response.data["hydra:view"],
      type: GetCompanies.SUCCESS,
    });
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* getCompany(action: any): any {
  try {
    const response = yield call(apiClient.getCompany, action.iri);
    const company = response.data;
    yield put({
      company,
      type: GetCompany.SUCCESS,
    });
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export default function* companySaga() {
  yield takeLatest(GetCompanies.REQUEST, getCompanies);
  yield takeLatest(GetCompany.REQUEST, getCompany);
  yield takeLatest(EditCompany.REQUEST, editCompany);
  yield takeEvery(DeleteCompany.REQUEST, deleteCompany);
  yield takeEvery(CreateCompany.REQUEST, createCompany);
}
