import { GetInterviewStepTypes } from "../../../actionTypes/interviewStepType";
import { InterviewStepType } from "../../../interfaces/resources/interviewStepType";

export function interviewStepTypes(state: InterviewStepType[] = [], action: any) {
  switch (action.type) {
    case GetInterviewStepTypes.SUCCESS:
      return action.interviewStepTypes;
    default:
      return state;
  }
}
