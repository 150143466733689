import React, { PropsWithChildren } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import { coreUserRoles } from "../../interfaces/resources/coreUser";
import { routes } from "../../routes";

import { getCurrentUserAction } from "../../services/api/coreUser/actions";
import LoaderComp from "../Utils/Loader";

export const RequireAuth: React.FC<PropsWithChildren> = (props: PropsWithChildren) => {
  const { children } = props;
  const location = useLocation();

  const dispatch = useDispatch();

  const auth = useSelector((state: any) => state.auth);
  const currentUser = useSelector((state: any) => state.currentUser);

  React.useEffect(() => {
    if (currentUser === null) {
      dispatch(getCurrentUserAction());
    }
  }, [currentUser, dispatch]);

  const next = location ? `?next=${encodeURI(location.pathname)}` : "";
  const restrictedRoutes = [routes.admin.userList];
  const isAdmin = currentUser ? currentUser.roles.includes(coreUserRoles.admin) : null;
  return (
    <>
      {auth.isAuthed ? (
        !isAdmin && location && restrictedRoutes.includes(location.pathname) ? (
          <Navigate to={{ pathname: routes.app.home }} replace />
        ) : currentUser ? (
          children
        ) : (
          <LoaderComp />
        )
      ) : (
        <Navigate
          to={{
            pathname: routes.auth.login,
            search: next,
          }}
          replace
        />
      )}
    </>
  );
};
