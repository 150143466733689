import { t } from "@lingui/macro";
import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalProps, Accordion } from "semantic-ui-react";
import { GetTalentProfile } from "../../../../../actionTypes/talentProfile";
import TalentCardForm from "../../../../../components/Offers/Shortlist/Form/TalentCardForm";
import { initializeValues } from "../../../../../components/Offers/Shortlist/Form/TalentCardForm/talentCardHelper";
import { useApiSelector, usePrevious } from "../../../../../components/Utils/hook";
import { ApiRequest } from "../../../../../interfaces/api/request";
import { Application, applicationStatuses } from "../../../../../interfaces/resources/application";
import { CoreUser } from "../../../../../interfaces/resources/coreUser";
import { Offer, offerStatuses } from "../../../../../interfaces/resources/offer";
import { Qualification } from "../../../../../interfaces/resources/qualification";
import { Talent } from "../../../../../interfaces/resources/talent";
import { TalentCard } from "../../../../../interfaces/resources/talentCard";
import { TalentProfile } from "../../../../../interfaces/resources/talentProfile";
import { editApplicationAction } from "../../../../../services/api/applications/actions";
import { getTalentProfileAction } from "../../../../../services/api/talent/talentProfile/actions";
import {
  createTalentCardAction,
  editTalentCardAction,
  getTalentCardsAction,
} from "../../../../../services/api/talentCard/actions";
import ApplicationList from "./ApplicationList";
import "./index.scss";

interface Props extends Pick<ModalProps, "open" | "size" | "onClose"> {
  talent: Talent;
}
export const TalentCardModal: FC<Props> = (props: Props) => {
  const { open, size, onClose, talent } = props;
  const [currentApplication, setCurrentApplication] = useState<Application | null>(null);
  const prevCurrentApplication = usePrevious(currentApplication) || [];
  const offer: Offer | null = currentApplication ? currentApplication.offer : null;
  const iri = offer ? offer["@id"] : undefined;
  const { apiPendingRequests } = useApiSelector();
  const dispatch = useDispatch();
  const editApplication = (req: Partial<Application>): any => {
    return req["@id"] && dispatch(editApplicationAction(req["@id"], req));
  };
  const createTalentCard = (talentC: Partial<TalentCard>, form: string): void => {
    dispatch(createTalentCardAction(talentC, form));
  };
  const editTalentCard = (talentC: Partial<TalentCard>, form: string): void => {
    dispatch(editTalentCardAction(talentC, form));
  };
  const talentProfile: TalentProfile = useSelector((state: any) => state.talentProfile);
  const currentUser: CoreUser = useSelector((state: any) => state.currentUser);
  const talentCards: TalentCard[] = useSelector((state: any) => state.talentCards);
  const talentQualifications: Qualification[] = useSelector((state: any) => state.talentQualifications);
  const qualification =
    talentQualifications && talentQualifications.length > 0
      ? talentQualifications.find(
          (qualif) => talentProfile.lastQualification && qualif["@id"] === talentProfile.lastQualification["@id"],
        )
      : null;
  const editingTalentCard: TalentCard | undefined = currentApplication
    ? talentCards.some((item) => item.application["@id"] === currentApplication["@id"])
      ? talentCards.find((item) => item.application["@id"] === currentApplication["@id"])
      : undefined
    : undefined;

  const talentApps: Application[] = useSelector((state: any) => state.talentApplications);
  const prevTalentApps: Application[] = usePrevious(talentApps) || [];
  const [applications, setApplications] = useState<Application[] | []>(talentApps);
  const prevApplications = usePrevious(applications) || [];
  const appStatus = [
    applicationStatuses.toPitch,
    applicationStatuses.callBooked,
    applicationStatuses.awaitingShortlist,
  ];

  React.useEffect(() => {
    if (prevTalentApps !== talentApps) {
      setApplications(
        talentApps.filter(
          (application: Application) =>
            application.offer
            && [offerStatuses.activated, offerStatuses.standbyElinoi].includes(application.offer.status)
            && ![
              applicationStatuses.refusedElinoi,
              applicationStatuses.refusedStartup,
              applicationStatuses.refusedTalent,
            ].includes(application.status)
            && appStatus.includes(application.status),
        ),
      );
    }
  }, [talentApps, appStatus, prevTalentApps]);

  React.useEffect(() => {
    const getTalentCards = (offerIri: string) => {
      return dispatch(
        getTalentCardsAction([
          {
            multiple: false,
            name: "application.offer",
            value: offerIri,
          },
        ]),
      );
    };
    if (currentApplication !== prevCurrentApplication && offer && iri) {
      getTalentCards(iri);
    }
  }, [currentApplication, prevCurrentApplication, offer, iri, dispatch]);

  React.useEffect(() => {
    if (applications !== prevApplications && !currentApplication && applications.length > 0) {
      setCurrentApplication(applications[0]);
    }
  }, [applications, prevApplications, currentApplication]);

  React.useEffect(() => {
    const getTalentProfile = (talentIri: string) => dispatch(getTalentProfileAction(talentIri));

    if (currentApplication && !apiPendingRequests.some((e) => e.type === GetTalentProfile.REQUEST)) {
      const isRequesting = apiPendingRequests.some((req: ApiRequest) => req.type === GetTalentProfile.REQUEST);
      if (typeof talentProfile["@id"] === "string" && !isRequesting && !talentProfile) {
        getTalentProfile(talentProfile["@id"]);
      }
    }
  }, [currentApplication, talentProfile, apiPendingRequests, dispatch]);

  const formName = currentApplication ? `talent-card-${currentApplication["@id"]}` : "";
  const initialValues = editingTalentCard
    ? editingTalentCard
    : currentApplication
    ? initializeValues(currentApplication, qualification, talent.linkedInProfile, currentUser)
    : null;

  return (
    <Modal open={open} onClose={onClose} size={size} closeIcon>
      <Modal.Header>{t`phoneCall.type.filter_talent_card`}</Modal.Header>
      <Modal.Content className="modal-content" scrolling>
        <Modal.Description>
          <div className="offerListContainer">
            <h5>{t`PhoneCalls.toPitchOffers.title`}</h5>
            <Accordion.Title>
              <ApplicationList
                setCurrentApplication={setCurrentApplication}
                applications={applications}
                currentApplication={currentApplication}
              />
            </Accordion.Title>
            {currentApplication && currentUser && offer ? (
              <div className="talentModal">
                <h3>
                  {currentApplication.offer.company.name} - {currentApplication.offer.title}
                </h3>
                <TalentCardForm
                  editApplication={editApplication}
                  currentUser={currentUser}
                  talentCard={editingTalentCard}
                  createTalentCard={createTalentCard}
                  editTalentCard={editTalentCard}
                  talentProfile={talentProfile}
                  application={currentApplication}
                  form={formName}
                  initialValues={initialValues}
                  setCurrentApplication={setCurrentApplication}
                />
              </div>
            ) : null}
          </div>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};
