import { AnyAction } from "redux";
import { LOCATION_CHANGE } from "redux-first-history";

import { GetInterviewSteps } from "../../../../actionTypes/interviewStep";
import { GetOffer } from "../../../../actionTypes/offers";
import { InterviewStep } from "../../../../interfaces/resources/interviewStep";
import { routes } from "../../../../routes";

export function interviewSteps(state: InterviewStep[] = [], action: AnyAction) {
  switch (action.type) {
    case GetOffer.REQUEST:
      return [];
    case LOCATION_CHANGE:
      return [routes.offers.new].includes(action.payload.location.pathname) ? [] : state;
    case GetInterviewSteps.SUCCESS:
      return action.interviewSteps;
    default:
      return state;
  }
}
