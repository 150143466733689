import { GetOffer } from "../../../actionTypes/offers";
import { CreateTalentCard, EditTalentCard, GetTalentCard, GetTalentCards } from "../../../actionTypes/talentCards";

import { TalentCard } from "../../../interfaces/resources/talentCard";

export function talentCard(state: TalentCard | null = null, action: any) {
  switch (action.type) {
    case CreateTalentCard.SUCCESS:
    case EditTalentCard.SUCCESS:
    case GetTalentCard.SUCCESS:
      return action.talentCard;
    case CreateTalentCard.REQUEST:
    case EditTalentCard.REQUEST:
      return null;
    default:
      return state;
  }
}

export function talentCards(state: TalentCard[] = [], action: any) {
  switch (action.type) {
    case GetTalentCards.SUCCESS:
      return !!action.page ? [...state, ...action.talentCards] : action.talentCards;
    case CreateTalentCard.SUCCESS:
    case EditTalentCard.SUCCESS:
    case GetTalentCard.SUCCESS:
      const index = state.findIndex((e: TalentCard) => action.talentCard["@id"] === e["@id"]);
      if (index >= 0) {
        state.splice(index, 1, action.talentCard);
      } else {
        state.push(action.talentCard);
      }
      return [...state];
    case GetOffer.REQUEST:
      return [];
    default:
      return state;
  }
}
