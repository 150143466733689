import React, { FC, useState, PropsWithChildren } from "react";

import { Application, RefusedReason } from "../../../interfaces/resources/application";
import { ColumnProps } from "../../../pages/ApplicationsDashboardPage/Column/data";

import { RefuseOptionsModal } from "./RefuseOptionsModal";
import { RefuseTextFieldModal } from "./RefuseTextFieldModal";

interface RefuseModalProps {
  status: string;
  type?: "options" | "textfield" | "talentCard";
  className?: string;
  application: Application;
  column?: ColumnProps;
  editApplication: (iri: string, req: Partial<Application>) => void;
}

export const RefuseModal: FC<PropsWithChildren<RefuseModalProps>> = (props: PropsWithChildren<RefuseModalProps>) => {
  const { status, editApplication, application, column, children, className, type = "options" } = props;
  const [open, setOpen] = useState(false);

  const handleProceed = (refusedReason: RefusedReason, refusedStatus?: string): void => {
    setOpen(false);
    editApplication(application["@id"], { status: refusedStatus ? refusedStatus : status, refusedReason });
  };

  return (
    <>
      <div className={className} onClick={(): void => setOpen(true)}>
        {children}
      </div>
      {type === "options" ? (
        <RefuseOptionsModal
          onClose={(): void => setOpen(false)}
          open={open}
          onProceed={handleProceed}
          talent={application.talent}
        />
      ) : type === "textfield" ? (
        <RefuseTextFieldModal
          column={column}
          onClose={(): void => setOpen(false)}
          open={open}
          onProceed={handleProceed}
          talent={application.talent}
        />
      ) : null}
    </>
  );
};
