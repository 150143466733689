import { t } from "@lingui/macro";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Grid, Icon, Placeholder, Segment } from "semantic-ui-react";

import { SortApplication } from "../../../../actionTypes/applications";
import { GetHuntTalents } from "../../../../actionTypes/hunt";
import { Application, applicationStatuses } from "../../../../interfaces/resources/application";
import { Talent } from "../../../../interfaces/resources/talent";

import "./index.scss";

import { State } from "../../../../interfaces/state";
import {
  deleteApplicationAction,
  editApplicationAction,
  sortApplicationAction,
} from "../../../../services/api/applications/actions";
import { getTalentForCollectionAction } from "../../../../services/api/talent/actions";
import { deleteTalentFromToContactAction } from "../../../../services/state/hunt/actions";
import { useApiSelector } from "../../../Utils/hook";
import { ContactTalentCard } from "./ContactTalentCard";

export const TalentToContact: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const { apiPendingRequests } = useApiSelector();
  const handleContactTalent = (item: Talent) => {
    item.applications.forEach((app: Application) => {
      const isSorting =
        apiPendingRequests.length > 0
        && apiPendingRequests.some((e: any) => SortApplication.REQUEST === e.type && app["@id"] === e.iri);
      if (!isSorting) {
        sortApplication(app["@id"], { status: applicationStatuses.contacted }, "status");
      }
    });
  };

  const talentsToContact = useSelector((state: State) => state.talentsToContact);
  const huntState = useSelector((state: State) => state.huntState);
  const deleteApplication = (iri: string) => dispatch(deleteApplicationAction(iri));
  const deteleTalent = (iri: string) => dispatch(deleteTalentFromToContactAction(iri));
  const editApplication = (iri: string, req: any) => dispatch(editApplicationAction(iri, req));
  const getTalent = (iri: string) => dispatch(getTalentForCollectionAction(iri));
  const sortApplication = (iri: string, req: { status: string }, validation: string) =>
    dispatch(sortApplicationAction(iri, req, validation));

  const isLoading =
    apiPendingRequests.length > 0 && apiPendingRequests.some((e: any) => GetHuntTalents.REQUEST === e.type);
  return (
    <Card.Group className="TalentToContact" itemsPerRow={2}>
      {talentsToContact.length > 0 ? (
        talentsToContact.map((item: Talent, i: number) => {
          return (
            <Card key={i}>
              <ContactTalentCard
                talentId={item["@id"]}
                talentProfile={item.talentProfile}
                getTalent={getTalent}
                editApplication={editApplication}
                deleteApplication={deleteApplication}
                deteleTalent={deteleTalent}
                huntState={huntState}
              />
              <Button
                className="primaryButton"
                attached={true}
                onClick={(): void => handleContactTalent(item)}
                disabled={isLoading}
              >
                <Icon name="sign out alternate" />
                {t`ContactTalent.contact`}
              </Button>
            </Card>
          );
        })
      ) : (
        <div className="empty">
          {!isLoading ? (
            t`ContactTalent.empty`
          ) : (
            <div className="full-width">
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column>
                    <Segment raised>
                      <Placeholder>
                        <Placeholder.Header image>
                          <Placeholder.Line />
                          <Placeholder.Line />
                        </Placeholder.Header>
                        <Placeholder.Paragraph>
                          <Placeholder.Line length="medium" />
                          <Placeholder.Line length="medium" />
                          <Placeholder.Line length="medium" />
                          <Placeholder.Line length="medium" />
                          <Placeholder.Line length="short" />
                        </Placeholder.Paragraph>
                      </Placeholder>
                    </Segment>
                  </Grid.Column>
                  <Grid.Column>
                    <Segment raised>
                      <Placeholder>
                        <Placeholder.Header image>
                          <Placeholder.Line />
                          <Placeholder.Line />
                        </Placeholder.Header>
                        <Placeholder.Paragraph>
                          <Placeholder.Line length="medium" />
                          <Placeholder.Line length="medium" />
                          <Placeholder.Line length="medium" />
                          <Placeholder.Line length="medium" />
                          <Placeholder.Line length="short" />
                        </Placeholder.Paragraph>
                      </Placeholder>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Segment raised>
                      <Placeholder>
                        <Placeholder.Header image>
                          <Placeholder.Line />
                          <Placeholder.Line />
                        </Placeholder.Header>
                        <Placeholder.Paragraph>
                          <Placeholder.Line length="medium" />
                          <Placeholder.Line length="medium" />
                          <Placeholder.Line length="medium" />
                          <Placeholder.Line length="medium" />
                          <Placeholder.Line length="short" />
                        </Placeholder.Paragraph>
                      </Placeholder>
                    </Segment>
                  </Grid.Column>
                  <Grid.Column>
                    <Segment raised>
                      <Placeholder>
                        <Placeholder.Header image>
                          <Placeholder.Line />
                          <Placeholder.Line />
                        </Placeholder.Header>
                        <Placeholder.Paragraph>
                          <Placeholder.Line length="medium" />
                          <Placeholder.Line length="medium" />
                          <Placeholder.Line length="medium" />
                          <Placeholder.Line length="medium" />
                          <Placeholder.Line length="short" />
                        </Placeholder.Paragraph>
                      </Placeholder>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          )}
        </div>
      )}
    </Card.Group>
  );
};
