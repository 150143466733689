import { t } from "@lingui/macro";
import cx from "classnames";
import * as React from "react";
import { ModalWrapper } from "../../../../components/ModalWrapper";
import QualificationForm from "../../../../components/QualificationForm";
import globalStyles from "../../../../styles/global.module.scss";

import styles from "./index.module.scss";

interface Props {
  onCloseModal: () => void;
  qualificationIri: string;
}

interface CtaProps {
  qualificationIri: string;
}

export const EditQualificationItemModalCta: React.FC<CtaProps> = (props: CtaProps) => {
  const { qualificationIri } = props;
  const [openModal, setOpenModal] = React.useState(false);
  const closeModal = () => {
    setOpenModal(false);
  };
  return (
    <>
      <button
        className={cx(globalStyles.cta, globalStyles.primaryCta)}
        onClick={() => {
          setOpenModal(true);
        }}
      >
        {t`Constant.update`}
      </button>
      <ModalWrapper open={openModal} onCloseModal={closeModal}>
        <EditQualificationItemModal onCloseModal={closeModal} qualificationIri={qualificationIri} />
      </ModalWrapper>
    </>
  );
};

export const EditQualificationItemModal: React.FC<Props> = (props: Props) => {
  const { onCloseModal, qualificationIri } = props;

  return (
    <div className={styles.modal}>
      <QualificationForm edition={true} qualificationIri={qualificationIri} handleClose={onCloseModal} />
    </div>
  );
};
