import cx from "classnames";
import { FieldProps, useField } from "formik";
import * as React from "react";
import { Form, FormRadioProps } from "semantic-ui-react";
import { Violation } from "../../../../interfaces/api/violation";
import { getViolationMessage } from "../../../Utils/hook";
import ErrorMessage from "../ErrorMessage";

interface RadioFieldProps extends FieldProps, FormRadioProps {
  className: string;
  getOptionLabel?: (option: any, value: any) => string;
  getOptionValue?: (option: any) => string;
  item: any;
}

export const formatValue = (value: any, getOptionValue?: (option: any) => string) =>
  getOptionValue ? getOptionValue(value) : value;

export const formatLabel = (label?: any, value?: any, getOptionLabel?: (l: any, v: any) => string): any =>
  label ? (getOptionLabel ? getOptionLabel(label, value) : label) : null;

export const RadioField = ({
  error,
  field,
  form,
  getOptionLabel,
  getOptionValue,
  option,
  ...rest
}: RadioFieldProps): JSX.Element => {
  const [, meta] = useField(field.name);

  return (
    <Form.Radio
      {...field}
      checked={field.value === formatValue(option, getOptionValue)}
      error={(meta && meta.touched && !!meta.error) || !!error}
      onChange={(_e: any, data: any) => form.setFieldValue(field.name, data.value)}
      onBlur={() => form.setFieldTouched(field.name, true)}
      value={formatValue(option, getOptionValue)}
      label={formatLabel(option, field.value, getOptionLabel)}
      {...rest}
    />
  );
};

interface RadioProps extends FieldProps, FormRadioProps {
  name: string;
  options: any[];
  className?: string;
  getOptionLabel?: (option: any) => string;
  getOptionValue?: (option: any) => string;
  isRequired?: boolean;
  label?: string;
  optionsProps?: any[];
  violations?: Violation[];
}

const Radio: React.FC<RadioProps> = ({
  className,
  field,
  form,
  getOptionLabel,
  getOptionValue,
  isRequired = true,
  label,
  options,
  optionsProps,
  violations,
  ...rest
}: RadioProps) => {
  const [, meta] = useField(field.name);
  const error = violations && getViolationMessage(field.name, violations);
  return (
    <div className={cx(className, { error: (meta.touched && !!meta.error) || !!error })}>
      {label ? (
        <label className="formLabel">
          {label} {isRequired ? "*" : null}
        </label>
      ) : undefined}
      <div className="radio-options-container fields grouped radio">
        {options.map((option: any, idx) => (
          <RadioField
            error={error}
            field={field}
            form={form}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            key={`radio-${JSON.stringify(option)}-${idx}`}
            option={option}
            {...rest}
            {...(Array.isArray(optionsProps) && optionsProps.length > idx ? optionsProps[idx] : [])}
          />
        ))}
      </div>
      <ErrorMessage name={field.name} message={error} />
    </div>
  );
};

export default Radio;
