import { Qualification } from "../../../../interfaces/resources/qualification";
import client from "../../client";

export function getTalentQualification(qualificationIri: string) {
  return client.get(qualificationIri);
}

export function getTalentQualifications(talentIri: string) {
  return client.get(`${talentIri}/talent_profile/qualifications`);
}

const formatData = (values: Qualification) => {
  return {
    ...values,
    actualRemuneration: {
      fixedSalary: !!values.actualRemuneration.fixedSalary ? values.actualRemuneration.fixedSalary : undefined,
      fullPackage: !!values.actualRemuneration.fullPackage ? values.actualRemuneration.fullPackage : undefined,
    },
    expectedRemuneration: {
      fixedSalary: !!values.expectedRemuneration.fixedSalary ? values.expectedRemuneration.fixedSalary : undefined,
      fullPackage: !!values.expectedRemuneration.fullPackage ? values.expectedRemuneration.fullPackage : undefined,
    },
  };
};

export function createTalentQualification(talentQualification: Qualification, validation?: string) {
  return client.post(
    `/api/v2/talent_qualifications${validation ? `/${validation}` : ""}`,
    formatData(talentQualification),
  );
}

export function editTalentQualification(talentQualification: Qualification, validation?: string) {
  return client.put(
    `${talentQualification["@id"]}${validation ? `/${validation}` : ""}`,
    formatData(talentQualification),
  );
}

export function getChatGPT(qualificationIri: string) {
  return client.get(`${qualificationIri}/upgrade`);
}

export async function postTranscriptFile(file: any, talent: string, currentUser: string, iriQualif?: string) {
  const formData = new FormData();
  formData.append("audioFile", file);
  formData.append("coreUser", currentUser);
  iriQualif && formData.append("iriQualif", iriQualif);
  return client.post(`${talent}/transcript`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function postTranscriptText(text: string, talent: string, currentUser: string, iriQualif?: string) {
  return client.post(`${talent}/transcript`, { text: text, coreUser: currentUser, iriQualif: iriQualif });
}
