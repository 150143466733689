import { t } from "@lingui/macro";
import { FieldProps } from "formik";
import * as React from "react";
import { FormInputProps } from "semantic-ui-react";
import { Violation } from "../../../../interfaces/api/violation";

import { getViolationMessage } from "../../../Utils/hook";
import toBase64, { Base64File } from "../../../Utils/ToBase64";
import ErrorMessage from "../ErrorMessage";

interface Props extends FieldProps, FormInputProps {
  violations?: Violation[];
}

export const File = ({ field: { name }, form: { setFieldValue }, label, violations, ...rest }: Props) => {
  const error = violations && getViolationMessage(name, violations);
  const [base64URL, setBase64URL] = React.useState<string | undefined>(undefined);
  const handleFile = (files: File[]) => {
    toBase64(files).then((result: Base64File[]) => {
      setBase64URL(result[0].base64URL);
    });
  };

  React.useEffect(() => {
    setFieldValue(name, base64URL);
  }, [name, base64URL, setFieldValue]);

  return (
    <div className={`field ${rest.className}`}>
      <label className="formLabel">
        <>
          {label ? label : null}
          {label && rest.documentUrl ? " - " : null}
          {rest.documentUrl ? (
            <a href={rest.documentUrl} target="_blank" rel="noopener noreferrer">
              {t`File.see.document`}
            </a>
          ) : null}
        </>
      </label>
      <div className="ui input">
        <input
          accept={rest.accept}
          name={name}
          type="file"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            e.target.files && handleFile(Array.from(e.target.files))
          }
        />
      </div>
      <ErrorMessage name={name} message={error} />
    </div>
  );
};
