import { t } from "@lingui/macro";
import React from "react";

import AsyncSelect from "react-select/async";
import { Accordion, Icon, Message } from "semantic-ui-react";
import { ApiFilter } from "../../../interfaces/api/filter";
import { Education } from "../../../interfaces/resources/education";
import { getSchools } from "../../../services/api/school/api";
import { defaultStyles } from "../../Form/Select";

interface Props extends ApiFilter<Education> {
  activeIndexes?: number[];
  handleClick?: (e: any, titleProps: any) => void;
  index?: number;
  hunt?: boolean;
}

const promiseOptions: any = async (inputValue: string) => {
  const response = await getSchools([
    {
      multiple: false,
      name: "schoolfilter",
      value: (inputValue || "").trim(),
    },
  ]);
  return response.data["hydra:member"];
};

export const EducationFilter: React.FC<Props> = ({ activeIndexes, handleClick, index, onChange, hunt }: Props) => {
  const active = activeIndexes ? activeIndexes.some((item: number) => item === index) : false;
  return (
    <div>
      {!hunt ? (
        <Accordion.Title active={active} index={index} onClick={handleClick}>
          <h3>
            {t`Education.title`}
            <Icon name={active ? "chevron up" : "chevron down"} />
          </h3>
        </Accordion.Title>
      ) : null}
      <Accordion.Content active={active}>
        <AsyncSelect
          isMulti
          cacheOptions
          styles={defaultStyles}
          defaultOptions
          loadOptions={promiseOptions}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option["@id"]}
          onChange={(options: any) =>
            onChange(
              options.map((item: any) => {
                return {
                  value: item["@id"],
                };
              }),
            )
          }
        />
        <Message info>
          <p>{t`Education.warning`}</p>
        </Message>
      </Accordion.Content>
    </div>
  );
};
