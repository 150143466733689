import { GetQuizAnswers } from "../../../../actionTypes/quizAnswer";

import { QuizAnswer } from "../../../../interfaces/resources/quizAnswer";
import { Talent } from "../../../../interfaces/resources/talent";

export function getQuizAnswersAction(talent: Talent) {
  return {
    talent,
    type: GetQuizAnswers.REQUEST,
  };
}

export function getQuizAnswersActionSuccessAction(quizAnswers: QuizAnswer[]) {
  return {
    quizAnswers,
    type: GetQuizAnswers.SUCCESS,
  };
}
