import { connect } from "react-redux";
import { Dispatch } from "redux";

import OfferForm from "../../../../components/Offers/OfferForm";

import { getOfferAction } from "../../../../services/api/offer/actions";

const mapStateToProps = (state: any) => {
  return {
    apiErrors: state.apiErrors,
    apiPendingRequests: state.apiPendingRequests,
    apiSuccess: state.apiSuccess,
    company: state.company,
    offer: state.offer,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getOffer: (iri: string) => dispatch(getOfferAction(iri)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferForm);
