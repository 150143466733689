import MaterialUiSwitch, { SwitchProps } from "@material-ui/core/Switch";
import styled from "styled-components";

interface Switch extends SwitchProps {
  coloron: string;
  coloroff?: string;
}

export const Switch = styled(MaterialUiSwitch)<Switch>`
  height: 16px;
  padding: 0px;
  width: 28px;
  .MuiSwitch-switchBase {
    color: white;
    padding: 2px;
    &.Mui-checked {
      transform: translateX(11px);
      + .MuiSwitch-track {
        background-color: ${({ coloron }): string => `var(--${coloron})`};
        border-color: ${({ coloron }): string => `var(--${coloron})`};
        opacity: 1;
      }
    }
  }
  .MuiSwitch-thumb {
    box-shadow: none;
    height: 12px;
    width: 12px;
  }
  .MuiSwitch-track {
    background-color: ${({ coloroff }): string => (coloroff ? `var(--${coloroff})` : "grey")};
    border-color: ${({ coloroff }): string => (coloroff ? `var(--${coloroff})` : "grey")};
    border-radius: 8px;
    opacity: 1;
  }
`;
