import cx from "classnames";
import "easymde/dist/easymde.min.css";
import { FieldProps, useField } from "formik";
import * as React from "react";
import SimpleMDE from "react-simplemde-editor";
import { Form } from "semantic-ui-react";

import { Violation } from "../../../../interfaces/api/violation";
import { getViolationMessage } from "../../../Utils/hook";
import ErrorMessage from "../ErrorMessage";
import "./index.scss";

export const refreshMarkdownContent = () => {
  setTimeout(() => {
    (Array.from(document.querySelectorAll(".CodeMirror")) as (Element & { CodeMirror: CodeMirror.Editor })[]).map(
      (el) => el.CodeMirror.refresh(),
    );
  }, 50);
};

interface MarkdownProps extends FieldProps {
  charCounter: boolean;
  disabled?: boolean;
  error: boolean | string;
  maxLength: number;
  minLength: number;
  placeholder: string;
  showToolbar: boolean;
  violations?: Violation[];
}

const Markdown: React.FunctionComponent<MarkdownProps> = ({
  charCounter,
  disabled,
  field: { name },
  form: { setFieldValue, setFieldTouched },
  maxLength,
  minLength,
  placeholder,
  violations,
  ...rest
}: MarkdownProps) => {
  const [field, meta] = useField(name);
  const error = violations && getViolationMessage(name, violations);
  const status = () => (maxLength || minLength || charCounter ? ["characters"] : false);

  return (
    <Form.Field error={(meta.touched && !!meta.error) || !!error} disabled={!!disabled}>
      <SimpleMDE
        {...rest}
        className={cx("active")}
        value={field.value}
        onChange={(option: any) => {
          if (field.value !== option) {
            setFieldValue(name, option);
          }
        }}
        // @ts-ignore
        onBlur={() => setFieldTouched(name, true)}
        getMdeInstance={(instance) => {
          setTimeout(() => {
            instance.codemirror.refresh();
          }, 50);
        }}
        // The only available dict is en_US for now. See https://github.com/Ionaru/easy-markdown-editor/issues/78
        options={
          {
            maxLength,
            minHeight: "100px",
            minLength,
            placeholder,
            spellChecker: false,
            status: status(),
            toolbar: [
              "bold",
              "italic",
              "heading-1",
              "heading-2",
              "|",
              "unordered-list",
              "ordered-list",
              "link",
              "quote",
              "horizontal-rule",
              "|",
              "preview",
              "guide",
            ],
          } as EasyMDE.Options
        }
      />
      <ErrorMessage name={name} message={error} />
    </Form.Field>
  );
};

export default Markdown;
