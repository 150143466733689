import { CreateOfferInternalView, EditOfferInternalView, GetOfferInternalView } from "../../../../actionTypes/offers";

import { OfferInternalView } from "../../../../interfaces/resources/offer";

export function createOfferInternalViewAction(
  offerInternalView: Partial<OfferInternalView>,
  form?: string,
  redirectOnSuccess?: boolean | string,
) {
  return {
    form,
    offerInternalView,
    redirectOnSuccess,
    type: CreateOfferInternalView.REQUEST,
  };
}

export function editOfferInternalViewAction(
  offerInternalView: Partial<OfferInternalView>,
  form?: string,
  redirectOnSuccess?: boolean | string,
) {
  return {
    form,
    offerInternalView,
    redirectOnSuccess,
    type: EditOfferInternalView.REQUEST,
  };
}

export function getOfferInternalViewAction(iri: string) {
  return {
    iri,
    type: GetOfferInternalView.REQUEST,
  };
}

export function getOfferInternalViewSuccessAction(offerInternalView: OfferInternalView) {
  return {
    offerInternalView,
    type: GetOfferInternalView.SUCCESS,
  };
}
