import { t } from "@lingui/macro";
import cx from "classnames";
import { differenceInDays, format, parseISO } from "date-fns";
import * as React from "react";
import { FC } from "react";
import { Highlight, useInstantSearch } from "react-instantsearch-hooks-web";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import { algoliaAttributes } from "..";
import { CreateApplication, EditApplication } from "../../../actionTypes/applications";
import { EditOffer } from "../../../actionTypes/offers";
import { Label } from "../../../blocks/labels";
import { ApplicationAcceptedLabel } from "../../../blocks/labels/ApplicationAcceptedLabel";
import { LateStageLabel } from "../../../blocks/labels/LateStageLabel";
import { PersonaLabel } from "../../../blocks/labels/PersonaLabel";
import { SearchStatusLabel } from "../../../blocks/labels/SearchStatusLabel";
import { PersonProfileImg } from "../../../blocks/personProfileImg";
import { GoldenTag } from "../../../components/Design/GoldenTag";
import { Tooltip } from "../../../components/Tooltip";
import { useApiSelector, usePrevious } from "../../../components/Utils/hook";
import { Application, applicationStatuses } from "../../../interfaces/resources/application";
import { Offer } from "../../../interfaces/resources/offer";
import { LateStageApplication, searchStatuses, talentSegments } from "../../../interfaces/resources/talent";
import { State } from "../../../interfaces/state";
import { createApplicationAction, editApplicationAction } from "../../../services/api/applications/actions";
import { extractPageFromIri, generateIriFromId } from "../../../services/api/helpers";
import { editOfferAction } from "../../../services/api/offer/actions";
import globalStyles from "../../../styles/global.module.scss";
import { algoliaAttributesTrans } from "../../../translations/constants/algolia";
import transApplication from "../../../translations/constants/application";

import styles from "./index.module.scss";
interface Props {
  hitTalent: any;
  segment?: string;
  sendEvent?: any;
  className?: string;
  mode?: "complete" | "minimal";
}

type InfosState = false | "internal" | "linkedin";

export const AlgoliaTalentCard: FC<Props> = (props: Props) => {
  const { hitTalent, className = "", sendEvent, mode = "complete", segment } = props;
  const hitTp = hitTalent.talentProfile;
  const hitLr = hitTalent.linkedinResume;
  const { refresh } = useInstantSearch();
  const dispatch = useDispatch();
  const { apiSuccess } = useApiSelector();
  const prevApiSuccess = usePrevious(apiSuccess);
  const talentIri = generateIriFromId("talent", hitTalent.id);
  const currentUser = useSelector((state: State) => state.currentUser);
  const applications = useSelector((state: State) => state.applications);
  const offer = useSelector((state: State) => state.offer);
  const [clicked, setClicked] = React.useState(false);
  const [showInfos, setShowInfos] = React.useState<InfosState>(false);
  const [disabledState, setDisabledState] = React.useState<"application" | "discarded" | undefined>(undefined);
  const noQualification = !!!hitTp.lastQualification;
  const expRem = hitTp.expectedRemuneration
    ? hitTp.expectedRemuneration
    : hitTp.lastQualification?.expectedRemuneration
    ? hitTp.lastQualification?.expectedRemuneration
    : undefined;
  const varExpRem = !!expRem ? expRem.fullPackage - expRem.fixedSalary : undefined;
  const actRem = hitTp.lastQualification?.actualRemuneration;
  const varActRem = !!actRem ? actRem.fullPackage - actRem.fixedSalary : undefined;
  const [excerpt, setExcerpt] = React.useState<string | null>(null);
  const highConclu = hitTalent._highlightResult?.talentProfile?.lastQualification?.conclusion;
  const highContext = hitTalent._highlightResult?.talentProfile?.lastQualification?.context;
  const highBridges = hitTalent._highlightResult?.talentProfile?.lastQualification?.bridges;
  const highIdealJob = hitTalent._highlightResult?.talentProfile?.lastQualification?.idealJob;
  // Le tableau des highlights des experiences LinkedIn
  const highExps = hitTalent._highlightResult?.linkedinResume?.experiences;
  const highSummary = hitTalent._highlightResult?.linkedinResume?.summary;

  const [internalMatch, setInternalMatch] = React.useState(0);
  const [linkedinMatch, setLinkedinMatch] = React.useState(0);

  const editApplication = (iri: string, req: Partial<Application>): void => {
    dispatch(editApplicationAction(iri, req));
  };
  const createApplication = (req: Partial<Application>): void => {
    dispatch(createApplicationAction(req));
  };

  const addToDiscardedList = () => {
    if (offer) {
      editOffer({
        "@id": offer["@id"],
        discardedTalents: [...offer.discardedTalents, { ...hitTalent, ["@id"]: talentIri }],
      });
    }
  };

  const editOffer = (req: Partial<Offer>): void => {
    dispatch(editOfferAction(req));
  };
  const handleHuntTalent = (status: string, ops?: { [key: string]: any }): void => {
    if (!hitTalent) {
      return;
    }

    const goodMatch = ops && ops.goodMatch !== undefined ? ops.goodMatch : undefined;

    const application = applications.find((e: Application) => {
      return !!offer && !!e.offer && e.offer["@id"] === offer["@id"] && e.talent["@id"] === talentIri;
    });

    switch (status) {
      case applicationStatuses.toContact:
        application
          ? // should not be able to edit app
            editApplication(application["@id"], {
              owner: currentUser,
              status,
              goodMatch,
            })
          : createApplication({
              offer: ops?.["@type"] === "Offer" ? (ops as Offer) : offer ? offer : undefined,
              owner: currentUser,
              status,
              talent: { ...hitTalent, "@id": talentIri },
              goodMatch,
            });
        break;
      default:
        break;
    }
  };

  const onClickShowMore = (state: InfosState) => {
    setShowInfos(state);
  };

  React.useEffect(() => {
    const arr = [highConclu, highContext, highBridges, highIdealJob];
    let count = 0;

    arr?.forEach((exp: any) => {
      if (!!exp?.matchLevel && exp?.matchLevel !== "none") {
        count++;
      }
    });

    setInternalMatch(count);
  }, [highConclu, highContext, highBridges, highIdealJob]);

  React.useEffect(() => {
    let count = 0;

    highExps?.forEach((exp: any) => {
      if (exp?.description && !!exp?.description?.matchLevel && exp?.description?.matchLevel !== "none") {
        count++;
      }
      if (!!exp?.companyName?.matchLevel && exp?.companyName?.matchLevel !== "none") {
        count++;
      }
      if (!!exp?.jobTitle?.matchLevel && exp?.jobTitle?.matchLevel !== "none") {
        count++;
      }
    });
    if (!!highSummary?.matchLevel && highSummary.matchLevel !== "none") {
      count++;
    }

    setLinkedinMatch(count);
  }, [highExps, highSummary]);

  React.useEffect(() => {
    let conclu = hitTp?.lastQualification?.conclusion;
    if (!!!conclu) {
      return;
    }
    if (conclu.split(" ").length > 10) {
      conclu = `${conclu.split(" ").slice(0, 9).join(" ")}... `;
    }

    setExcerpt(conclu);
  }, [hitTp]);

  React.useEffect(() => {
    const apiSuccessIntersection = [...apiSuccess].slice(prevApiSuccess?.length);
    if (
      apiSuccessIntersection.some(
        (action) =>
          [CreateApplication.SUCCESS, EditApplication.SUCCESS].includes(action.type)
          && action.application?.talent["@id"] === talentIri,
      )
    ) {
      setDisabledState("application");
    } else if (
      apiSuccessIntersection.some(
        (action) =>
          [EditOffer.SUCCESS].includes(action.type)
          && action.offer?.discardedTalents.some((disT: Offer) => disT["@id"] === talentIri),
      )
    ) {
      setDisabledState("discarded");
    }
  }, [apiSuccess, prevApiSuccess]);

  return (
    <div className={cx(styles.card, { [className]: !!className })}>
      <div className={styles.headerCard}>
        {mode === "complete" ? (
          <div className={cx(styles.headerItem, styles.rightBorder)}>
            {hitTalent.talentManager?.firstname ? `TIM : ${hitTalent.talentManager.firstname}` : "Aucun TIM"}
          </div>
        ) : null}
        {!!hitTp?.persona && hitTp?.persona === "active_performer" ? (
          <div className={cx(styles.headerItem, styles.rightBorder, styles.noShrink)}>
            <PersonaLabel persona={hitTp.persona} />
          </div>
        ) : null}
        {hitTalent.searchStatus ? (
          <div className={cx(styles.headerItem, styles.rightBorder, styles.noShrink)}>
            <SearchStatusLabel talent={hitTalent} />
          </div>
        ) : null}
        {hitTalent.applicationsAccepted?.length ? (
          <div className={cx(styles.headerItem, styles.rightBorder, styles.noShrink)}>
            <ApplicationAcceptedLabel applicationsAccepted={hitTalent.applicationsAccepted} />
          </div>
        ) : null}
        {hitTalent.lateStageApplications?.map((lap: LateStageApplication, i: number) => {
          return (
            <div
              className={cx(styles.headerItem, styles.rightBorder, styles.noShrink)}
              key={`algolia-card-late-stage-label-${lap.applicationId}-${i}`}
            >
              <LateStageLabel lap={lap} className={styles.labelContainer} />
            </div>
          );
        })}
        {hitTp.coreResponsibility?.value ? (
          <div className={cx(styles.headerItem, styles.bold)}>{hitTp.coreResponsibility.value}</div>
        ) : null}
        <div className={cx(styles.headerItem, styles.expRemItem)}>
          {hitTp.experience ? <div>{`${hitTp.experience} ans`}</div> : null}
          {!!expRem ? <div>{`${expRem.fixedSalary}k+${varExpRem}k (${expRem.fullPackage}k)`}</div> : null}
        </div>
        {mode === "complete" ? (
          <div className={cx(styles.headerItem, styles.lastLoginItem, styles.itemText)}>
            <span>dernière connexion : {format(new Date(hitTalent.lastLogin), "dd/MM/yyyy")}</span>
            {!!hitTalent.talentProfile?.lastQualificationUpdate ? (
              <span>
                dernière qualif : {format(new Date(hitTalent.talentProfile.lastQualificationUpdate), "dd/MM/yyyy")}
              </span>
            ) : null}
          </div>
        ) : null}
        {differenceInDays(new Date(), parseISO(hitTalent.createdAt)) < 14 ? (
          <div className={cx(styles.headerItem, styles.rightBorder, styles.noShrink)}>
            <Tooltip
              content={
                <div className={styles.tcCountTooltip}>
                  <div>Le candidat s&apos;est inscrit il y a moins de 14 jours</div>
                </div>
              }
            >
              <Label type="blue" content="New" className=""></Label>
            </Tooltip>
          </div>
        ) : null}
        {differenceInDays(new Date(), parseISO(hitTalent.lastLogin)) < 14
        && differenceInDays(new Date(), parseISO(hitTalent.talentProfile.lastQualificationUpdate)) > 150
        && hitTalent.searchStatus !== searchStatuses.not_searching ? (
          <div className={cx(styles.headerItem, styles.rightBorder, styles.noShrink)}>
            <Tooltip
              content={
                <div className={styles.tcCountTooltip}>
                  <div>Le candidat s&apos;est réactivé il y a moins de 14 jours</div>
                </div>
              }
            >
              <Label type="yellow" content="Réac" className=""></Label>
            </Tooltip>
          </div>
        ) : null}
      </div>
      <div className={styles.bodyCard}>
        <div className={styles.talentShowContainer}>
          <div className={styles.talentPartContainer}>
            <div className={styles.talentPhotoContainer}>
              <PersonProfileImg image={hitTalent.image} />
            </div>
            <div className={styles.talentInfosContainer}>
              <div className={styles.nameGoldenContainer}>
                <div>
                  <Link
                    to={extractPageFromIri("talents", talentIri)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.name}
                    onClick={() => {
                      if (typeof sendEvent === "function") {
                        sendEvent("click", hitTalent, "nameLink");
                      }
                    }}
                  >
                    {`${hitTalent.firstname} ${hitTalent.lastname}`}
                  </Link>
                </div>
                {hitTalent.talentProfile.golden ? (
                  <div>
                    <GoldenTag className={styles.goldenTag} />
                  </div>
                ) : null}
              </div>
              {hitTp?.currentJob || hitTp?.currentCompany ? (
                <div className={cx(styles.text)}>
                  {hitTp?.currentJob} @ {hitTp?.currentCompany}
                </div>
              ) : null}
              {!!hitTalent.linkedInProfile ? (
                <div className={styles.infoLinkedInContainer}>
                  <a
                    className={cx(styles.linkedInLink, styles.infosSupp)}
                    href={hitTalent.linkedInProfile.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      if (typeof sendEvent === "function") {
                        sendEvent("click", hitTalent, "goToLinkedIn");
                      }
                    }}
                  >
                    {t`TalentInfo.goTolinkedInProfile`}
                  </a>
                </div>
              ) : null}
            </div>
          </div>
          <div className={styles.excerptCtasContainer}>
            {excerpt ? (
              <div className={styles.excerptContainer}>
                <div className={styles.searchTextTitle}>Conclusion (extrait)</div>
                <div className={styles.excerptTextContainer}>
                  <ReactMarkdown
                    components={{
                      p: React.Fragment,
                    }}
                    rehypePlugins={[rehypeRaw]}
                  >
                    {excerpt}
                  </ReactMarkdown>
                </div>
              </div>
            ) : null}
            {noQualification && segment === talentSegments.segment1 ? (
              <div className={styles.pendinCallText}>Talent en attente de qualification</div>
            ) : null}
          </div>
        </div>
        <div className={styles.infosTabContainer}>
          <div>
            <button
              type="button"
              className={cx(styles.tabCta, globalStyles.cta, globalStyles.inverted, {
                [styles.active]: showInfos === "internal",
              })}
              onClick={() => {
                if (typeof sendEvent === "function" && !(showInfos === "internal")) {
                  sendEvent("click", hitTalent, "seeInternalInfos");
                }
                onClickShowMore(showInfos === "internal" ? false : "internal");
              }}
            >
              <span>Infos internes</span>{" "}
              {internalMatch ? <span className={styles.matchBadge}>{internalMatch}</span> : null}
            </button>
          </div>
          {!!hitLr ? (
            <div>
              <button
                type="button"
                className={cx(styles.tabCta, globalStyles.cta, globalStyles.inverted, {
                  [styles.active]: showInfos === "linkedin",
                })}
                onClick={() => {
                  if (typeof sendEvent === "function" && !(showInfos === "linkedin")) {
                    sendEvent("click", hitTalent, "seeLinkedinInfos");
                  }
                  onClickShowMore(showInfos === "linkedin" ? false : "linkedin");
                }}
              >
                <span>Infos linkedIn</span>{" "}
                {linkedinMatch ? <span className={styles.matchBadge}>{linkedinMatch}</span> : null}
              </button>
            </div>
          ) : null}
        </div>
        {showInfos === "internal" ? (
          <div className={styles.fullInfosContainer} key={`${hitTalent.id}-full-info-internal`}>
            <div className={styles.searchTextContainer}>
              <div className={styles.searchTextTitle}>
                {t({
                  id: (algoliaAttributesTrans as { [key: string]: string })[algoliaAttributes.conclusion],
                })}{" "}
                :
              </div>
              {!!highConclu?.value ? (
                <ReactMarkdown
                  components={{
                    p: React.Fragment,
                  }}
                  rehypePlugins={[rehypeRaw]}
                >
                  {highConclu.value}
                </ReactMarkdown>
              ) : (
                "non renseigné"
              )}
            </div>
            <div className={styles.searchTextContainer}>
              <div className={styles.searchTextTitle}>{t`AlgoliaTalentCard.expectedRemuneration`} :</div>
              <div>
                Souhaitée :{" "}
                {!!expRem?.fixedSalary && !!expRem?.fullPackage
                  ? `${expRem.fixedSalary}k +  ${varExpRem}k (${expRem.fullPackage}k)`
                  : t`qualification.expectedRemuneration.empty`}
              </div>
              <div>
                Actuelle :{" "}
                {!!actRem?.fixedSalary && !!actRem?.fullPackage
                  ? `${actRem.fixedSalary}k +  ${varActRem}k (${actRem.fullPackage}k)`
                  : t`qualification.expectedRemuneration.empty`}
              </div>
            </div>
            <div className={styles.searchTextContainer}>
              <div className={styles.searchTextTitle}>
                {t({
                  id: (algoliaAttributesTrans as { [key: string]: string })[algoliaAttributes.idealJob],
                })}{" "}
                :
              </div>
              {!!highIdealJob?.value ? (
                <ReactMarkdown
                  components={{
                    p: React.Fragment,
                  }}
                  rehypePlugins={[rehypeRaw]}
                >
                  {highIdealJob.value}
                </ReactMarkdown>
              ) : (
                "non renseigné"
              )}
            </div>
            <div className={styles.searchTextContainer}>
              <div className={styles.searchTextTitle}>
                {t({
                  id: (algoliaAttributesTrans as { [key: string]: string })[algoliaAttributes.bridges],
                })}{" "}
                :
              </div>
              {!!highBridges?.value ? (
                <ReactMarkdown
                  components={{
                    p: React.Fragment,
                  }}
                  rehypePlugins={[rehypeRaw]}
                >
                  {highBridges.value}
                </ReactMarkdown>
              ) : (
                "non renseigné"
              )}
            </div>
            <div className={styles.searchTextContainer}>
              <div className={styles.searchTextTitle}>
                {t({
                  id: (algoliaAttributesTrans as { [key: string]: string })[algoliaAttributes.context],
                })}{" "}
                :
              </div>
              {!!highContext?.value ? (
                <ReactMarkdown
                  components={{
                    p: React.Fragment,
                  }}
                  rehypePlugins={[rehypeRaw]}
                >
                  {highContext.value}
                </ReactMarkdown>
              ) : (
                "non renseigné"
              )}
            </div>
          </div>
        ) : showInfos === "linkedin" ? (
          <div className={styles.fullInfosContainer} key={`${hitTalent.id}-full-info-linkedIn`}>
            <div className={styles.searchTextContainer}>
              <div className={styles.searchTextTitle}>Résumé :</div>
              <div>
                {!!hitLr?.summary ? (
                  <Highlight attribute={algoliaAttributes.linkedinSummary} hit={hitTalent} />
                ) : (
                  "aucun résumé"
                )}
              </div>
            </div>
            <div className={styles.searchTextContainer}>
              <div className={styles.searchTextTitle}>Experiences :</div>
              <div>
                {hitTalent.linkedinResume?.experiences?.map((exp: any, i: number) => {
                  const description = highExps?.[i]?.description?.value || "";
                  const jobTitle = highExps?.[i]?.jobTitle?.value || "";
                  const companyName = highExps?.[i]?.companyName?.value || "";

                  return (
                    <div key={`exp-${i}-${hitTalent.id}`} className={styles.searchTextContainer}>
                      <div className={cx(styles.searchTextTitle, styles.linkedinExpTitle)}>
                        <ReactMarkdown
                          components={{
                            p: React.Fragment,
                          }}
                          rehypePlugins={[rehypeRaw]}
                        >
                          {`${companyName} - ${jobTitle}`}
                        </ReactMarkdown>
                      </div>
                      {exp.startDate && exp.endDate ? (
                        <div className={styles.additionalInfosPart}>
                          ({format(parseISO(exp.startDate), "MM/yyyy")} - {format(parseISO(exp.endDate), "MM/yyyy")})
                        </div>
                      ) : null}
                      <div>
                        {!!description ? (
                          <ReactMarkdown
                            components={{
                              p: React.Fragment,
                            }}
                            rehypePlugins={[rehypeRaw]}
                          >
                            {description.replace(/^-/, "")}
                          </ReactMarkdown>
                        ) : (
                          "aucune description"
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {!!hitLr?.languages?.length ? (
              <div className={styles.searchTextContainer}>
                <div className={styles.searchTextTitle}>Languages :</div>
                <div>{hitLr.languages.map((l: any) => l?.name).join(", ")}</div>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      <div className={styles.actionCtasContainer}>
        <div className={cx(styles.actionItem, styles.leftAutoCta)}>
          <button
            onClick={(): void => {
              if (typeof sendEvent === "function") {
                sendEvent("click", hitTalent, "toContact");
              }
              setClicked(true);
              handleHuntTalent(applicationStatuses.toContact);
            }}
            className={cx(styles.actionCta, globalStyles.cta, globalStyles.inverted, globalStyles.primaryCta)}
            disabled={!!!offer || clicked}
            title={!!!offer ? "aucune offre sélectionnée" : undefined}
          >
            {t({ id: transApplication.status.to_contact })}
          </button>
        </div>
        <div className={styles.actionItem}>
          <button
            onClick={(): void => {
              if (typeof sendEvent === "function") {
                sendEvent("click", hitTalent, "toContact-goodMatch");
              }
              setClicked(true);
              handleHuntTalent(applicationStatuses.toContact, { goodMatch: true });
            }}
            className={cx(styles.actionCta, globalStyles.cta, globalStyles.inverted, globalStyles.aquaCta)}
            disabled={!!!offer || clicked}
            title={!!!offer ? "aucune offre sélectionnée" : undefined}
          >
            {t({ id: transApplication.status.good_match })}
          </button>
        </div>
        <div className={cx(styles.actionItem)}>
          <button
            onClick={(): void => {
              if (typeof sendEvent === "function") {
                sendEvent("click", hitTalent, "notInteresting");
              }
              setClicked(true);
              addToDiscardedList();
            }}
            className={cx(styles.actionCta, globalStyles.cta, globalStyles.inverted, globalStyles.alertCta)}
            disabled={!!!offer || clicked}
            title={!!!offer ? "aucune offre sélectionnée" : undefined}
          >
            {t`HuntTalent.notInteresting`}
          </button>
        </div>
      </div>
      {!!disabledState ? (
        <div className={styles.disabledState}>
          <div>
            {disabledState === "application"
              ? "La candidature a bien été crée."
              : "Le talent a bien été mis en “pas intéressant”"}
          </div>
          <div>
            Si je ne disparait pas refresh{" "}
            <button
              className={cx(globalStyles.cta, globalStyles.aquaCta, globalStyles.inverted)}
              type="button"
              onClick={() => {
                refresh();
              }}
            >
              <svg width="1rem" height="1rem" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <title>Refresh the search results</title>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                />
              </svg>
            </button>{" "}
            la liste
          </div>
        </div>
      ) : null}
    </div>
  );
};
