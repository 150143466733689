import { ActionType } from "../interfaces/api/actionType";

export const GetReferral: ActionType = {
  FAILURE: "GET_REFERRAL_FAILURE",
  REQUEST: "GET_REFERRAL_REQUEST",
  SUCCESS: "GET_REFERRAL_SUCCESS",
};

export const GetReferrals: ActionType = {
  FAILURE: "GET_REFERRALS_FAILURE",
  REQUEST: "GET_REFERRALS_REQUEST",
  SUCCESS: "GET_REFERRALS_SUCCESS",
};
