import { t } from "@lingui/macro";

interface Const {
  text: { [key: string]: string };
  empty: { [key: string]: string };
}

export const transQualification: Const = {
  text: {
    conclusion: t`qualification.conclusion`,
    idealJob: t`qualification.idealJob`,
    pitchedOffer: t`qualification.pitchedOffer`,
    context: t`qualification.context`,
    storytelling: t`qualification.storytelling`,
    bridges: t`qualification.bridges`,
    ongoingProcesses: t`qualification.ongoingProcesses`,
    priorNotice: t`qualification.priorNotice`,
    expectedRemuneration: t`qualification.expectedRemuneration`,
    formattedConclusion: t`qualification.formattedConclusion`,
  },
  empty: {
    conclusion: t`qualification.conclusion.empty`,
    idealJob: t`qualification.idealJob.empty`,
    pitchedOffer: t`qualification.pitchedOffer.empty`,
    storytelling: t`qualification.storytelling.empty`,
    context: t`qualification.context.empty`,
    bridges: t`qualification.bridges.empty`,
    ongoingProcesses: t`qualification.ongoingProcesses.empty`,
    priorNotice: t`qualification.priorNotice.empty`,
    expectedRemuneration: t`qualification.expectedRemuneration.empty`,
    formattedConclusion: t`qualification.formattedConclusion.empty`,
  },
};
