import { connect } from "react-redux";
import { Dispatch } from "redux";

import CoreUsersList from "../../../components/CoreUsers/CoreUsersList";
import { switchOnAction } from "../../../services/api/authentication/actions";
import { deleteCoreUserAction, getCoreUsersAction } from "../../../services/api/coreUser/actions";

const mapStateToProps = (state: any) => {
  return {
    apiPendingRequests: state.apiPendingRequests,
    coreUsers: state.coreUsers,
    newCoreUser: state.newCoreUser,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    deleteCoreUser: (iri: string) => dispatch(deleteCoreUserAction(iri)),
    getCoreUsers: () => dispatch(getCoreUsersAction()),
    switchOn: (iri: string) => dispatch(switchOnAction(iri)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CoreUsersList);
