import { GetInterviewStep, GetInterviewSteps } from "../../../../actionTypes/interviewStep";
import { InterviewStep } from "../../../../interfaces/resources/interviewStep";

export function interviewStepRequestAction(interviewStep: InterviewStep) {
  return {
    interviewStep,
    type: GetInterviewStep.REQUEST,
  };
}

export function interviewStepsRequestAction(offerIri: string) {
  return {
    offerIri,
    type: GetInterviewSteps.REQUEST,
  };
}

export function interviewStepsSuccessAction(interviewSteps: InterviewStep[]) {
  return {
    interviewSteps,
    type: GetInterviewSteps.SUCCESS,
  };
}
