import { AnyAction } from "redux";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import { CreateTalentCard, EditTalentCard, GetTalentCard, GetTalentCards } from "../../../actionTypes/talentCards";
import { apiFailureAction } from "../actions";
import { extractNextPageNumberFromIri } from "../helpers";
import * as apiClient from "./api";

export function* createTalentCard(action: AnyAction): any {
  try {
    const response = yield call(apiClient.createTalentCard, action.talentCard);
    yield put({
      form: action.form,
      talentCard: response.data,
      type: CreateTalentCard.SUCCESS,
    });
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* editTalentCard(action: AnyAction): any {
  try {
    const response = yield call(apiClient.editTalentCard, action.talentCard);
    yield put({
      form: action.form,
      talentCard: response.data,
      type: EditTalentCard.SUCCESS,
    });
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* getTalentCard(action: AnyAction): any {
  try {
    const response = yield call(apiClient.getTalentCard, action.iri);
    yield put({
      talentCard: response.data,
      type: GetTalentCard.SUCCESS,
    });
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* getTalentCards(action: AnyAction): any {
  try {
    const response = yield call(apiClient.getTalentCards, action.filterContext);
    yield put({
      page: action.page,
      talentCards: response.data["hydra:member"],
      type: GetTalentCards.SUCCESS,
    });
    if (response.data.hasOwnProperty("hydra:view") && response.data["hydra:view"].hasOwnProperty("hydra:next")) {
      yield put({
        page: extractNextPageNumberFromIri(response.data["hydra:view"]["hydra:next"]),
        type: GetTalentCards.REQUEST,
      });
    }
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export default function* talentCardSaga() {
  yield takeLatest(CreateTalentCard.REQUEST, createTalentCard);
  yield takeEvery(EditTalentCard.REQUEST, editTalentCard);
  yield takeEvery(GetTalentCard.REQUEST, getTalentCard);
  yield takeLatest(GetTalentCards.REQUEST, getTalentCards);
}
