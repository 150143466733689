import { t } from "@lingui/macro";
import * as React from "react";
import { Accordion, SearchProps } from "semantic-ui-react";

import { FilterClause, FilterContext } from "../../../interfaces/api/filter";
import { ApiRequest } from "../../../interfaces/api/request";
import { formatSectors, Sector } from "../../../interfaces/resources/sector";

import { CompanyNameFilter } from "../CompanyNameFilter";
import { CompanyStatusFilter } from "../CompanyStatusFilter";
import { HasActiveOffersFilter } from "../HasActiveOffersFilter";
import { SectorFilter } from "../SectorFilter";

interface Props {
  apiPendingRequests: ApiRequest[];
  applyFilter: (filterClause: FilterClause<any>) => void;
  filterContext: FilterContext;
  getSectors: () => void;
  resetFilter: () => void;
  sectors: Sector[];
}

export default class CompanyFilterHandler extends React.PureComponent<Props, any> {
  private debounceCompanyNameTimeout!: NodeJS.Timeout;
  private readonly debounceTimeMs = 300;

  constructor(props: any) {
    super(props);
    this.state = {
      activeIndexes: [0],
      text: "",
    };

    this.handleClick = this.handleClick.bind(this);
  }

  public resetOfferNameTimeout() {
    clearTimeout(this.debounceCompanyNameTimeout);
  }

  public debounce(callback: () => void) {
    this.resetOfferNameTimeout();

    this.debounceCompanyNameTimeout = setTimeout(() => {
      callback();
    }, this.debounceTimeMs);
  }

  public componentWillUnmount() {
    this.resetOfferNameTimeout();
  }

  public componentDidMount = (): void => {
    this.props.resetFilter();
    this.props.applyFilter({ multiple: false, name: "itemsPerPage", value: 15 });

    setTimeout(() => {
      const { sectors, getSectors } = this.props;

      if (sectors.length === 0) {
        getSectors();
      }
    }, 100);
  };

  public getSelected(type: string) {
    const filterClause = this.props.filterContext.filter((clause: FilterClause<any>) => type === clause.name)[0];
    return filterClause ? filterClause.value : [];
  }

  public changeFilterContextForCompany = (_: unknown, { value }: SearchProps): void => {
    this.setState({ text: value });

    this.debounce(() => {
      this.props.applyFilter({
        multiple: false,
        name: "name",
        value: value ? value.trim() : null,
      });
    });
  };

  public changeFilterContext = (type: string, _: any, data: any) => {
    let newValue: any;

    if (Array.isArray(data)) {
      newValue = data.map((elem: any) => (elem.hasOwnProperty("@id") ? elem["@id"] : elem.value));
    } else {
      newValue = data.hasOwnProperty("@id") ? data["@id"] : data.value;
    }

    const newClause: FilterClause<any> = {
      multiple: !!data.multiple || Array.isArray(data),
      name: type,
      value: newValue,
    };
    this.props.applyFilter(newClause);
  };

  public changeFilterContextForType = (type: string) => {
    return (item: any, data: any) => this.changeFilterContext(type, item, data);
  };

  public changeFilterContextForSelect = (name: string) => {
    return (value: any) =>
      this.props.applyFilter({
        multiple: true,
        name,
        value:
          value && value.length > 0
            ? value.map((elem: any) => (elem.hasOwnProperty("@id") ? elem["@id"] : elem.value))
            : [],
      });
  };

  public handleClick = (_: unknown, { index }: SearchProps): void => {
    const newIndexes = this.state.activeIndexes.some((item: number) => item === index)
      ? this.state.activeIndexes.filter((item: number) => item !== index)
      : [...this.state.activeIndexes, index];
    this.setState({ activeIndexes: newIndexes });
  };

  public render() {
    return (
      <div>
        <h2>{t`CompanySearchScene.filter`}</h2>
        <CompanyNameFilter
          apiPendingRequests={this.props.apiPendingRequests}
          text={this.state.text}
          onChange={this.changeFilterContextForCompany}
        />
        <Accordion fluid>
          <SectorFilter
            items={formatSectors(this.props.sectors)}
            selected={this.getSelected("sector")}
            activeIndexes={this.state.activeIndexes}
            index={1}
            handleClick={this.handleClick}
            onChange={this.changeFilterContextForSelect("sectors")}
            multiple={true}
          />
          <CompanyStatusFilter
            selected={this.getSelected("status")}
            onChange={this.changeFilterContextForSelect("status")}
            activeIndexes={this.state.activeIndexes}
            index={2}
            handleClick={this.handleClick}
            multiple={true}
          />
          <HasActiveOffersFilter
            selected={this.getSelected("hasActiveOffers")}
            onChange={this.changeFilterContextForType("hasActiveOffers")}
            activeIndexes={this.state.activeIndexes}
            index={3}
            handleClick={this.handleClick}
            multiple={false}
          />
        </Accordion>
      </div>
    );
  }
}
