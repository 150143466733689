import { t } from "@lingui/macro";
import cx from "classnames";

import * as React from "react";
import { BsInfoLg } from "react-icons/bs";
import { ModalWrapper } from "../../../components/ModalWrapper";
import globalStyles from "../../../styles/global.module.scss";
import styles from "./index.module.scss";

interface Props {
  mode?: "search";
  onCloseModal: () => void;
}

interface CtaProps {
  mode?: "search";
  className?: string;
}

export const AlgoliaHelpModalCta: React.FC<CtaProps> = (props: CtaProps) => {
  const { className = "", mode } = props;
  const [openModal, setOpenModal] = React.useState(false);
  const closeModal = () => {
    setOpenModal(false);
  };
  return (
    <>
      <div className={globalStyles.floatingCtaContainer}>
        <div className={globalStyles.floatingCtaContent}>
          <button
            type="button"
            className={cx(className, globalStyles.floatingCta)}
            onClick={() => {
              setOpenModal(true);
            }}
          >
            <BsInfoLg />
          </button>
        </div>
      </div>
      <ModalWrapper open={openModal} onCloseModal={closeModal}>
        <AlgoliaHelpModal mode={mode} onCloseModal={closeModal} />
      </ModalWrapper>
    </>
  );
};

export const AlgoliaHelpModal: React.FC<Props> = (props: Props) => {
  const { onCloseModal } = props;

  const closeModal = () => {
    if (typeof onCloseModal === "function") {
      onCloseModal();
    }
  };

  return (
    <div className={styles.modal}>
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <h3 className={styles.title}>{t`AlgoliaHelpModal.header.title`}</h3>
        </div>
        <div>
          <div className={styles.part}>
            <h6 className={styles.partTitle}>Recherche textuelle</h6>
            <p className={styles.text}>
              <strong>Recherche basique</strong> : Les résultats contenants les termes recherchés ressortiront. Exemple
              : <code>vente complexe</code> fera ressortir les résultats avec <code>vente</code> <b>ET</b>{" "}
              <code>complexe</code> peu importe leurs places dans le texte.
            </p>
            <p className={styles.text}>
              <strong>Recherche exacte</strong> : Utilisez les doubles quotes <code>&quot;</code> pour avoir les
              résultats qui correspondent exactement aux termes recherchés. Exemple :{" "}
              <code>&quot;vente complexe&quot;</code> fera ressortir les résultats avec <code>vente complexe</code> qui
              se suivent.
            </p>
            <p className={styles.text}>
              <strong>Mot interdit</strong> : Mettez un tiret <code>-</code> devant le mot, pour exclure les résultats
              contenant ce mot.
            </p>
            <p className={styles.text}>
              Exemple : la recherche <code>&quot;Tier 2&quot; -junior -sales</code> fera ressortir les talents Tier 2
              qui n&apos;ont pas les mots junior ou sales.
            </p>
            <h6 className={styles.subPartTitle}>Astuces d&apos;utilisation</h6>
            <ol>
              <li className={styles.text}>
                Retire tous les filtres pré-remplis de ton offre et effectue une recherche textuelle{" "}
                <b>la plus précise possible</b> (ex : &quot;business developer&quot; démarchage management foodtech)
              </li>
              <li className={styles.text}>
                Remplace dans ta première recherche les termes par des synonymes et alterne les mots-clés de la 1ère
                recherche avec des <b>synonymes</b>
                <ol type="a">
                  <li>commercial &quot;cold call&quot; &quot;team lead&quot; agroalimentaire</li>
                  <li>&quot;business developer&quot; &quot;cold call&quot; management foodtech</li>
                  <li>&quot;business developer&quot; démarchage &quot;team lead&quot; agroalimentaire etc...</li>
                </ol>
              </li>
              <li className={styles.text}>
                Au fur et à mesure que tu trouveras des profils pertinents, utilise des mots-clés pour{" "}
                <b>enrichir cette recherche</b>
              </li>
            </ol>
          </div>
          <div className={styles.part}>
            <h6 className={styles.partTitle}>Rôle principal / Rôle secondaire</h6>
            <p className={styles.text}>
              Certains talents ont un rôle principal qui n&apos;est pas listé dans leurs rôles secondaires (et
              inversement). Lorsque vous faites une recherche par rôle, il faut donc jouer avec les deux filtres.
            </p>

            <h6 className={styles.subPartTitle}>Astuces d&apos;utilisation</h6>
            <ol>
              <li className={styles.text}>
                Le rôle principal est visible sur la carte talent, mais pas les rôles secondaires.
              </li>
              <li className={styles.text}>
                Vous pouvez cumuler les options de rôles principaux. (pareil pour les rôles secondaires)
                <br />
                Exemple : si vous sélectionner Account Manager et Business Developer, vous verrez les talents qui ont
                soit AM ou BD en rôle principal.
              </li>
              <li className={styles.text}>
                <strong>Attention</strong> : Sélectionner un rôle principal <b>ET</b> un rôle secondaire réduira la
                liste de vos résultats car vous verrez les talents qui ont le rôle principal <b>ET</b> le rôle
                secondaire sélectionnés.
                <br />
                Exemple : Si vous cochez Account Manager dans rôle principal ET secondaire, vous verrez les talents qui
                ont à la fois AM en tant que rôle principal et qui ont aussi AM listé dans leurs rôles secondaires.
              </li>
            </ol>
            <p className={styles.text}>
              Dans la majorité des cas, filtrer sur uniquement l’un ou l’autre vous permettra d’avoir des résultats plus
              pertinents.
            </p>
          </div>
        </div>
        <div className={styles.ctaListContainer}>
          <div>
            <button
              type="button"
              onClick={closeModal}
              className={cx(globalStyles.cta, globalStyles.darkCta, globalStyles.inverted)}
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
