import { t } from "@lingui/macro";

interface Const {
  fundraisingStage: { [key: string]: string };
  searchUrgency: { [key: string]: string };
  searchStage: { [key: string]: string };
  issues: { [key: string]: string };
  status: { [key: string]: string };
}

const transCompany: Const = {
  fundraisingStage: {
    fundraising_a_series: t`fundraisingStage.fundraising_a_series`,
    fundraising_b_series: t`fundraisingStage.fundraising_b_series`,
    fundraising_c_series: t`fundraisingStage.fundraising_c_series`,
    fundraising_never: t`fundraisingStage.fundraising_never`,
    fundraising_seed: t`fundraisingStage.fundraising_seed`,
    fundraising_z_series: t`fundraisingStage.fundraising_z_series`,
  },
  searchUrgency: {
    urgency_high: t`searchUrgency.urgency_high`,
    urgency_low: t`searchUrgency.urgency_low`,
    urgency_medium: t`searchUrgency.urgency_medium`,
  },
  searchStage: {
    lost: t`searchStage.lost`,
    well_defined: t`searchStage.well_defined`,
    need_advice: t`searchStage.need_advice`,
  },
  issues: {
    quality: t`issues.quality`,
    quantity: t`issues.quantity`,
    selection: t`issues.selection`,
    level: t`issues.level`,
    time: t`issues.time`,
  },
  status: {
    called: t`company.status.called`,
    closed: t`company.status.closed`,
    contract_sent: t`company.status.contract_sent`,
    hunted: t`company.status.hunted`,
    incoming_lead: t`company.status.incoming_lead`,
    introduced_to: t`company.status.introduced_to`,
    physical_meeting: t`company.status.physical_meeting`,
    refused: t`company.status.refused`,
  },
};

export default transCompany;
