import { t } from "@lingui/macro";
import cx from "classnames";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { PersonProfileImg } from "../../../blocks/personProfileImg";
import { ModalWrapper } from "../../../components/ModalWrapper";

import { OfferSelect } from "../../../components/Utils/OfferSelect";
import { Application, applicationStatuses } from "../../../interfaces/resources/application";
import { coreUserRoles } from "../../../interfaces/resources/coreUser";
import { Offer } from "../../../interfaces/resources/offer";
import { Talent } from "../../../interfaces/resources/talent";
import { State } from "../../../interfaces/state";
import { createApplicationAction, editApplicationAction } from "../../../services/api/applications/actions";
import globalStyles from "../../../styles/global.module.scss";
import styles from "./index.module.scss";
import SelectedOfferList from "./OfferList";

interface Props {
  talent: Talent;
  onCloseModal: () => void;
}

interface CtaProps {
  talent: Talent;
  className?: string;
}

export interface PartialApplication {
  offer: Offer;
  status: string | undefined;
  goodMatch?: boolean;
}

export const OfferPushModalCta: React.FC<CtaProps> = (props: CtaProps) => {
  const { talent, className = "" } = props;
  const [openModal, setOpenModal] = React.useState(false);
  const closeModal = () => {
    setOpenModal(false);
  };
  return (
    <>
      <button
        type="button"
        className={cx(className, globalStyles.cta, globalStyles.primaryCta)}
        onClick={() => {
          setOpenModal(true);
        }}
      >
        {t`talent.pushOffer`}
      </button>
      <ModalWrapper open={openModal} onCloseModal={closeModal}>
        <OfferPushModal talent={talent} onCloseModal={closeModal} />
      </ModalWrapper>
    </>
  );
};

export const OfferPushModal: React.FC<Props> = (props: Props) => {
  const { onCloseModal, talent } = props;
  const dispatch = useDispatch();
  const currentUser = useSelector((state: State) => state.currentUser);
  const applicationsState = useSelector((state: State) => state.applications);
  const isAccountManager = !!currentUser?.roles.includes(coreUserRoles.am);
  const [applicationsList, setApplicationsList] = React.useState<PartialApplication[]>([]);

  const closeModal = () => {
    if (typeof onCloseModal === "function") {
      onCloseModal();
    }
  };
  const editApplication = (iri: string, req: Partial<Application>): void => {
    dispatch(editApplicationAction(iri, req));
  };
  const createApplication = (req: Partial<Application>): void => {
    dispatch(createApplicationAction(req));
  };

  const addApplicationToList = (offer: Offer) => {
    if (!applicationsList.some((item: PartialApplication) => item.offer["@id"] === offer["@id"])) {
      setApplicationsList([
        ...applicationsList,
        {
          offer,
          status: isAccountManager ? applicationStatuses.contacted : applicationStatuses.interesting,
        },
      ]);
    }
  };

  const removeApplicationFromList = (offer: Offer) => {
    const newAppList = [...applicationsList].filter((item: PartialApplication) => item.offer["@id"] !== offer["@id"]);
    setApplicationsList(newAppList);
  };
  const updateApplication = (offer: Offer, status: string, goodMatch?: boolean) => {
    const apps = [...applicationsList];
    const index = apps.findIndex((item: PartialApplication) => item.offer["@id"] === offer["@id"]);
    apps.splice(index, 1, {
      ...apps[index],
      status,
      goodMatch,
    });
    setApplicationsList(apps);
  };

  const handleSubmit = (status: string): void => {
    const newAppList = [...applicationsList].filter((application: PartialApplication) => application.status !== status);
    const applicationsToHandle = [...applicationsList].filter(
      (application: PartialApplication) => application.status === status,
    );
    applicationsToHandle.forEach((item: PartialApplication) => {
      const application = applicationsState.find(
        (e: Application) => e.offer["@id"] === item.offer["@id"] && e.talent["@id"] === talent["@id"],
      );
      application
        ? editApplication(application["@id"], {
            status,
            goodMatch: item.goodMatch,
            owner: currentUser,
          })
        : createApplication({
            offer: item.offer,
            status,
            talent,
            goodMatch: item.goodMatch,
            owner: currentUser,
          });
    });

    if (!!!newAppList.length) {
      closeModal();
      return;
    }
    setApplicationsList(newAppList);
  };

  return (
    <div className={styles.modal}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.titleContainer}>
            <h3 className={styles.title}>{t`OfferSelectModal.title`}</h3>
          </div>
          <div className={styles.talentInfo}>
            <div className={styles.talentPhotoContainer}>
              <PersonProfileImg image={talent.image} />
            </div>
            <div>
              {talent.firstname} {talent.lastname}
            </div>
          </div>
        </div>
        <div>
          <OfferSelect talent={talent} handleAction={(offer: Offer): void => addApplicationToList(offer)} />
          {applicationsList.length > 0 ? (
            <SelectedOfferList
              applications={applicationsList}
              isAccountManager={isAccountManager}
              removeApplication={(offer: Offer): void => removeApplicationFromList(offer)}
              updateApplication={(offer: Offer, status: string, goodMatch?: boolean): void =>
                updateApplication(offer, status, goodMatch)
              }
            />
          ) : (
            <div className={styles.emptyList}>{t`OfferSelectModal.empty`}</div>
          )}
        </div>
        <div className={styles.ctaListContainer}>
          <div className={styles.cancelCtaContainer}>
            <button
              type="button"
              onClick={closeModal}
              className={cx(globalStyles.cta, globalStyles.darkCta, globalStyles.inverted)}
            >
              {t`Constant.cancel`}
            </button>
          </div>
          <div>
            <button
              onClick={(): void => handleSubmit(applicationStatuses.contacted)}
              type="button"
              className={cx(globalStyles.cta, globalStyles.primaryCta)}
              disabled={
                isAccountManager
                  ? !applicationsList.some((item: PartialApplication) => item.status === applicationStatuses.contacted)
                  : !applicationsList.length
              }
            >
              {t`talent.pushOffer`}
            </button>
          </div>
          <div>
            <button
              onClick={(): void => handleSubmit(applicationStatuses.awaitingShortlist)}
              type="button"
              className={cx(globalStyles.cta, globalStyles.primaryCta)}
              disabled={
                !applicationsList.some(
                  (item: PartialApplication) => item.status === applicationStatuses.awaitingShortlist,
                )
              }
            >
              {t`OfferSelectModal.dispatch`}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
