import { t } from "@lingui/macro";
import { Formik } from "formik";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { OFFER_MAIN_FORM } from "..";

import { Company } from "../../../../interfaces/resources/company";
import { CoreUser } from "../../../../interfaces/resources/coreUser";
import { desiredPlacesRemote } from "../../../../interfaces/resources/desiredPlace";
import {
  Offer,
  OfferInternalView,
  OfferLocation,
  OfferMainForm,
  offerStatuses,
  OfferTalentView,
} from "../../../../interfaces/resources/offer";
import { State } from "../../../../interfaces/state";
import { routes } from "../../../../routes";
import { getStringValue } from "../../../../services/api/helpers";
import { createOfferAction, editOfferAction } from "../../../../services/api/offer/actions";

import Yup from "../../../App/Yup";
import { isReinitializeable } from "../../../Utils/helpers";
import { useApiSelector } from "../../../Utils/hook";
import { MainFormFields } from "./fields";
import "./index.scss";
import { OfferMainFormContext, OfferMainFormContextProps } from "./OfferMainFormContext";

interface Props {
  enableSwitchPage: (e: boolean) => void;
  formType: "creation" | "edition";
}

function getInitialValues(
  offer: Offer | null,
  offerInternalView: OfferInternalView | null,
  offerTalentView: OfferTalentView | null,
  currentUser: CoreUser | null,
  company: Company | null,
) {
  return {
    "@id": offer ? offer["@id"] : undefined,
    closingParty: offer ? offer.closingParty : undefined,
    commission: offer && offer.commission ? offer.commission : undefined,
    company: offer ? offer.company : company ? company : undefined,
    contacts: offer ? offer.contacts : [],
    coreResponsibility: offer ? offer.coreResponsibility : undefined,
    documentFile: undefined,
    experienceYears: offer ? offer.experienceYears : [],
    formule: offer && offer.formule ? offer.formule : undefined,
    level: offer && offer.level ? offer.level : undefined,
    offerInternalView: {
      "@id": offerInternalView ? offerInternalView["@id"] : undefined,
      expectedProfile: offerInternalView
        ? offerInternalView.expectedProfile
        : t`offer.offerInternalTalentView.expectedProfile.initValue`,
      outstandingAspects: offerInternalView
        ? offerInternalView.outstandingAspects
        : t`offer.offerInternalTalentView.outstandingAspects.initValue`,
    },
    offerLocations: offer
      ? (offer.offerLocations ?? []).reduce((arr: any[], offerLocation: OfferLocation) => {
          const locationIri = getStringValue(offerLocation.location, "@id");
          const locationValue = getStringValue(offerLocation.location, "value");
          if (
            locationIri
            && locationValue
            && !desiredPlacesRemote.includes(locationValue)
            && !arr.includes(locationIri)
            && locationIri !== getStringValue(offer.stapleLocation, "@id")
          ) {
            return [...arr, offerLocation.location];
          }
          return arr;
        }, [])
      : [],
    remotePreferences: offer
      ? (offer.offerLocations ?? []).reduce((arr: any[], offerLocation: OfferLocation) => {
          const locationValue = getStringValue(offerLocation.location, "value");
          if (locationValue && desiredPlacesRemote.includes(locationValue)) {
            return [...arr, offerLocation.location];
          }
          return arr;
        }, [])
      : [],
    offerTalentView: {
      "@id": offerTalentView ? offerTalentView["@id"] : undefined,
      context: offerTalentView ? offerTalentView.context : "",
      description: offerTalentView ? offerTalentView.description : "",
      expectedProfile: offerTalentView ? offerTalentView.expectedProfile : "",
      howToShine: offerTalentView ? offerTalentView.howToShine : "",
      requirements: offerTalentView ? offerTalentView.requirements : "",
      team: offerTalentView ? offerTalentView.team : "",
      toLearn: offerTalentView ? offerTalentView.toLearn : "",
      ytPitch: offerTalentView ? offerTalentView.ytPitch : "",
    },
    owners: offer && offer.owners.length > 0 ? offer.owners : undefined,
    recurring: offer ? offer.recurring : false,
    responsibilities: offer ? offer.responsibilities : [],
    salary: offer ? offer.salary : [],
    setUp: offer && offer.setUp ? offer.setUp : undefined,
    stapleLocation: offer ? offer.stapleLocation : undefined,
    status: offer ? offer.status : offerStatuses.desactivated,
    tier: offer ? offer.tier : undefined,
    title: offer ? offer.title : undefined,
    mode: offer && offer.mode ? offer.mode : "",
  };
}

const OfferMainFormSchema = Yup.object().shape({
  mode: Yup.string().required(),
  closingParty: Yup.mixed().when("mode", {
    is: "spontaneous",
    then: Yup.mixed().notRequired(),
    otherwise: Yup.mixed().required(),
  }),
  commission: Yup.number().when("mode", {
    is: "spontaneous",
    then: Yup.number().notRequired(),
    otherwise: Yup.number().typeError("set up must be a number").required(),
  }),
  company: Yup.mixed().required(),
  coreResponsibility: Yup.mixed().when("mode", {
    is: "spontaneous",
    then: Yup.mixed().notRequired(),
    otherwise: Yup.mixed().required(),
  }),
  experienceYears: Yup.array().when("mode", {
    is: "spontaneous",
    then: Yup.array().notRequired(),
    otherwise: Yup.array().required(),
  }),
  formule: Yup.string().when("mode", {
    is: "spontaneous",
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required(),
  }),
  level: Yup.mixed().when("mode", {
    is: "spontaneous",
    then: Yup.mixed().notRequired(),
    otherwise: Yup.mixed().required(),
  }),
  offerInternalView: Yup.object().when("mode", {
    is: "spontaneous",
    then: Yup.object().shape({
      expectedProfile: Yup.string().notRequired(),
      outstandingAspects: Yup.string().notRequired(),
    }),
    otherwise: Yup.object().shape({
      expectedProfile: Yup.string().required(),
      outstandingAspects: Yup.string().required(),
    }),
  }),
  offerTalentView: Yup.object().when("mode", {
    is: "spontaneous",
    then: Yup.object().shape({
      context: Yup.string().notRequired(),
      description: Yup.string().notRequired(),
      expectedProfile: Yup.string().notRequired(),
      requirements: Yup.string().notRequired(),
      howToShine: Yup.string().notRequired(),
      toLearn: Yup.string().notRequired(),
      team: Yup.string().notRequired(),
    }),
    otherwise: Yup.object().shape({
      context: Yup.string().required(),
      description: Yup.string().required(),
      expectedProfile: Yup.string().required(),
      requirements: Yup.string().required(),
      howToShine: Yup.string().required(),
      toLearn: Yup.string().required(),
      team: Yup.string().required(),
    }),
  }),
  owners: Yup.array().required(),
  responsibilities: Yup.array().when("mode", {
    is: "spontaneous",
    then: Yup.array().notRequired(),
    otherwise: Yup.array().required(),
  }),
  salary: Yup.array().when("mode", {
    is: "spontaneous",
    then: Yup.array().notRequired(),
    otherwise: Yup.array().required(),
  }),
  setUp: Yup.number().when("mode", {
    is: "spontaneous",
    then: Yup.number().notRequired(),
    otherwise: Yup.number().typeError("set up must be a number").required(),
  }),
  stapleLocation: Yup.mixed().when("mode", {
    is: "spontaneous",
    then: Yup.mixed().notRequired(),
    otherwise: Yup.mixed().required(),
  }),
  status: Yup.string().required(),
  tier: Yup.string().when("mode", {
    is: "spontaneous",
    then: Yup.string().notRequired().nullable(),
    otherwise: Yup.string().required(),
  }),
  title: Yup.string().required(),
});

export const MainForm: React.FC<Props> = ({ enableSwitchPage, formType }: Props) => {
  const dispatch = useDispatch();
  const [formState, update] = React.useState<OfferMainFormContextProps>({
    formSubmissionType: null,
  });

  const { apiPendingRequests, apiErrors, apiSuccess } = useApiSelector();
  const initialValues = useSelector((state: State) =>
    getInitialValues(state.offer, state.offerInternalView, state.offerTalentView, state.currentUser, state.company),
  );

  const submitOffer = (values: OfferMainForm, redirectOnSucces?: string) => {
    const offer = { ...values, offerLocations: [...values.offerLocations, ...values.remotePreferences] };
    dispatch(
      offer.hasOwnProperty("@id") && !!offer["@id"]
        ? editOfferAction(offer, OFFER_MAIN_FORM, undefined, redirectOnSucces)
        : createOfferAction(offer, OFFER_MAIN_FORM, redirectOnSucces),
    );
  };

  const handleSubmitAndGoToOffers = (values: OfferMainForm): void => {
    if (values.mode === "spontaneous") {
      submitOffer({ ...values }, routes.offers.show);
    } else {
      submitOffer(
        {
          ...values,
          status: "creation" === formType ? offerStatuses.desactivated : values.status,
        },
        routes.offers.show,
      );
    }
  };

  const handleSubmitAndEnableAdvicePage = (values: OfferMainForm): void => {
    submitOffer({ ...values });
  };

  return (
    <OfferMainFormContext.Provider value={{ state: formState, update }}>
      <Formik
        onSubmit={(values, { setSubmitting }): void => {
          values.mode === "one_shot" ? (values.recurring = false) : (values.recurring = true);
          switch (formState.formSubmissionType) {
            case "toOffers":
              handleSubmitAndGoToOffers(values);
              break;
            case "advice":
              handleSubmitAndEnableAdvicePage(values);
              break;
          }
          setTimeout(() => setSubmitting(false), 1000);
        }}
        validationSchema={OfferMainFormSchema}
        initialValues={initialValues}
        enableReinitialize={isReinitializeable(apiErrors, apiPendingRequests, apiSuccess)}
      >
        {(): JSX.Element => <MainFormFields formType={formType} enableSwitchPage={enableSwitchPage} />}
      </Formik>
    </OfferMainFormContext.Provider>
  );
};
