import client from "../client";
import { extractIdFromIri } from "../helpers";

export function login(token: string) {
  return client.post("/api/v2/admin/auth/login", {
    token,
  });
}

export function refresh(refreshToken: string) {
  return client.post("/api/v2/admin/auth/refresh", {
    refresh_token: refreshToken,
  });
}

export function logout() {
  return client.post("/api/v2/admin/auth/logout");
}

export function switchOn(iri: string) {
  return client.get(`/api/v2/admin/auth/switch/${extractIdFromIri(iri)}`);
}
