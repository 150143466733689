import { Application } from "./application";
import { CoreUser } from "./coreUser";
import { Talent } from "./talent";

export interface PhoneCall {
  "@id": string;
  applications: Application[];
  coreUser: CoreUser;
  createdAt: string;
  notes: string;
  title: string;
  talent?: string | Talent;
  type: string;
  applicationsToSubmit?: Application[];
  sent: boolean;
}

export const phoneCallTypes = {
  filter_ongoing_process: "filter_ongoing_process",
  filter_first_call: "filter_first_call",
  filter_follow_up: "filter_follow_up",
  filter_talent_card: "filter_talent_card",
  filter_reactivation: "filter_reactivation",
  filter_spontaneous_application: "filter_spontaneous_application",
};
