import { t } from "@lingui/macro";
import cx from "classnames";
import * as React from "react";
import styles from "./index.module.scss";

const bannerColor: { [key: string]: string } = {
  [t`offer.status.standby`]: "#699bf0",
};

interface Props {
  banner: string;
  small: boolean;
  verySmall: boolean;
}

const Banner = (props: Props): JSX.Element | null => {
  return bannerColor[props.banner] ? (
    <div
      className={cx(styles.banner, {
        [styles.smallBanner]: props.small,
        [styles.verySmallBanner]: props.verySmall,
      })}
      style={{ background: bannerColor[props.banner] }}
    >
      {props.banner}
    </div>
  ) : null;
};

export default Banner;
