import algoliasearch from "algoliasearch";
import * as React from "react";
import { Configure, Hits, InstantSearch, Pagination } from "react-instantsearch-hooks-web";
import { useSelector } from "react-redux";
import { talentSegments } from "../../interfaces/resources/talent";
import { State } from "../../interfaces/state";
import { AlgoliaTalentCard } from "../../pages/AlgoliaSearchPage/AlgoliaTalentCard";
import { extractIdFromIri } from "../../services/api/helpers";
import { NoResultsBoundary } from "../Algolia/NoResultsBoundary";
import styles from "./index.module.scss";

interface Props {
  title?: React.ReactNode;
  mode?: "inactive" | "active";
}

export const NoTimTalents: React.FC<Props> = (props: Props): JSX.Element => {
  const { title, mode = "active" } = props;
  const currentUser = useSelector((state: State) => state.currentUser);
  const personasQuery =
    mode === "active"
      ? "talentProfile.persona:active_performer"
      : "(talentProfile.persona:inactive_neutral OR talentProfile.persona:inactive_performer)";
  const filterQuery = `${personasQuery} AND noTalentManager: true`;

  const searchClient = algoliasearch(
    `${process.env.REACT_APP_ALGOLIA_APP_ID}`,
    `${process.env.REACT_APP_ALGOLIA_SECRET_KEY}`,
    {
      headers: {
        "X-Algolia-UserToken": currentUser?.["@id"]
          ? `coreUser-${extractIdFromIri(currentUser["@id"])}`
          : "unidentified_user",
      },
    },
  );
  const algoliaIndexName = `${process.env.REACT_APP_ALGOLIA_INDEX_PREFIX}${talentSegments.segment1}`;
  if (!!!algoliaIndexName) {
    throw new Error("REACT_APP_ALGOLIA_INDEX_PREFIX is missing");
  }

  return (
    <div>
      {!!title ? title : null}
      <div>
        <InstantSearch searchClient={searchClient} indexName={algoliaIndexName}>
          <Configure filters={filterQuery} hitsPerPage={3} />
          <NoResultsBoundary fallback={<div className={styles.emptyResults}>Aucun talent sans TIM</div>}>
            <div>
              <Hits
                hitComponent={({ hit, sendEvent }: any) => {
                  return (
                    <>
                      <AlgoliaTalentCard hitTalent={hit} sendEvent={sendEvent} mode="minimal" />
                    </>
                  );
                }}
              />
            </div>
            <div className={styles.paginationContainer}>
              <Pagination />
            </div>
          </NoResultsBoundary>
        </InstantSearch>
      </div>
    </div>
  );
};
