import { t } from "@lingui/macro";
import * as React from "react";
import Select from "react-select";
import { Accordion, Icon } from "semantic-ui-react";

import { companyStatuses } from "../../../interfaces/resources/company";
import transCompany from "../../../translations/constants/company";
import { defaultStyles } from "../../Form/Select";

interface Props {
  activeIndexes?: number[];
  handleClick?: (e: any, titleProps: any) => void;
  index?: number;
  multiple: boolean;
  onChange: (item: any, data: any) => void;
  selected: any;
}

interface Status {
  transId: string;
  value: string;
}

const items: Status[] = [
  {
    transId: transCompany.status.incoming_lead,
    value: companyStatuses.incomingLead,
  },
  {
    transId: transCompany.status.introduced_to,
    value: companyStatuses.introducedTo,
  },
  {
    transId: transCompany.status.hunted,
    value: companyStatuses.hunted,
  },
  {
    transId: transCompany.status.called,
    value: companyStatuses.called,
  },
  {
    transId: transCompany.status.physical_meeting,
    value: companyStatuses.physicalMeeting,
  },
  {
    transId: transCompany.status.contract_sent,
    value: companyStatuses.contractSent,
  },
  {
    transId: transCompany.status.closed,
    value: companyStatuses.closed,
  },
  {
    transId: transCompany.status.refused,
    value: companyStatuses.refused,
  },
];

export const CompanyStatusFilter: React.FC<Props> = ({
  activeIndexes,
  handleClick,
  index,
  multiple,
  onChange,
  selected,
}: Props) => {
  const active = activeIndexes ? activeIndexes.some((item: number) => item === index) : false;
  return (
    <div>
      <Accordion.Title active={active} index={index} onClick={handleClick}>
        <h3>
          {t`CompanyStatusFilter.title`}
          <Icon name={active ? "chevron up" : "chevron down"} />
        </h3>
      </Accordion.Title>
      <Accordion.Content active={active}>
        <Select
          getOptionLabel={(option: Status): string => t({ id: option.transId })}
          getOptionValue={(option: Status): string => option.value}
          isMulti={!!multiple}
          onChange={onChange}
          options={items}
          value={items.filter((elem: Status) =>
            Array.isArray(selected) ? selected.includes(elem.value) : selected === elem.value,
          )}
          styles={defaultStyles}
        />
      </Accordion.Content>
    </div>
  );
};
