import * as React from "react";
import styled from "styled-components";
import Banner from "../Banner";

const ImageCropper = styled.div<{ size: string }>`
  width: ${({ size }: { size: string }) => size};
  height: ${({ size }: { size: string }) => size};
  flex: 0 0 auto;
  overflow: hidden;
  border-radius: 50%;
  text-align: center;
  position: relative;
  box-shadow: 0 2px 8px 0 rgba(47, 83, 151, 0.14);
`;

const FittedImage = styled.img`
  display: inline-block;
  margin: 0 auto;
  height: 100%;
  width: auto;
`;

interface Props {
  className?: string;
  size: string;
  src: string;
  as?: any;
  onClick?: (i: any) => any;
  onError?: (i: any) => string;
  banner?: string;
  smallBanner?: boolean;
  verySmallBanner?: boolean;
}

const RoundImage = (props: Props): JSX.Element => (
  <ImageCropper size={props.size} className={props.className} onClick={props.onClick} as={props.as}>
    <FittedImage src={props.src} onError={props.onError} />
    {props.banner ? (
      <Banner banner={props.banner} small={!!props.smallBanner} verySmall={!!props.verySmallBanner} />
    ) : null}
  </ImageCropper>
);

export default RoundImage;
