import { OfferInternalView } from "../../../../interfaces/resources/offer";
import client from "../../client";

export function getOfferInternalView(iri: string) {
  return client.get(iri);
}

export function createOfferInternalView(offerInternalView: Partial<OfferInternalView>) {
  return client.post("/api/v2/offer_internal_views", offerInternalView);
}

export function editOfferInternalView(offerInternalView: Partial<OfferInternalView>) {
  if (offerInternalView["@id"]) {
    return client.put(offerInternalView["@id"], offerInternalView);
  }
}
