import { GetCompetences } from "../../../actionTypes/competences";
import { Competence } from "../../../interfaces/resources/competence";

export function competences(state: Competence[] = [], action: any) {
  switch (action.type) {
    case GetCompetences.SUCCESS:
      return action.competences;
    default:
      return state;
  }
}
