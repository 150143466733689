import { t } from "@lingui/macro";
import cx from "classnames";
import { format, isAfter, formatDistance, parseISO } from "date-fns";
import { fr } from "date-fns/locale";
import * as React from "react";
import { ImWhatsapp } from "react-icons/im";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import { GoldenTag } from "../../components/Design/GoldenTag";
import { getIsOutdated } from "../../components/Utils/helpers";
import { Talent } from "../../interfaces/resources/talent";
import { extractPageFromIri } from "../../services/api/helpers";
import globalStyles from "../../styles/global.module.scss";
import { Label } from "../labels";
import { PersonaLabel } from "../labels/PersonaLabel";
import { SearchStatusLabel } from "../labels/SearchStatusLabel";
import { PersonProfileImg } from "../personProfileImg";
import styles from "./index.module.scss";

interface Props {
  talent: Talent;
}

export const MyTalentItem: React.FC<Props> = (props: Props) => {
  const { talent } = props;
  const tProfile = talent.talentProfile;
  const whatsappPhone = !!talent.phone ? talent.phone.replace("+", "").replace(/\s/g, "") : undefined;
  const hubspotUrl = !!tProfile?.hubspotId
    ? `https://app.hubspot.com/contacts/4144470/contact/${tProfile.hubspotId}`
    : undefined;
  const coreResp = !!!tProfile
    ? ""
    : tProfile.coreResponsibility
    ? tProfile.coreResponsibility.value
    : tProfile.responsibilities?.[0]?.responsibility?.value;

  const expRem = tProfile.lastQualification?.expectedRemuneration;
  const varRem = !!expRem ? expRem.fullPackage - expRem.fixedSalary : undefined;
  const nextCalls =
    talent.nextCallsBooked && talent.nextCallsBooked.length
      ? talent.nextCallsBooked.filter((clb) => isAfter(parseISO(clb), new Date()))
      : [];
  const lastTalentProfileEvent = tProfile?.lastTalentProfileEvent;
  const lastQualificationDate = tProfile?.lastQualificationDate;
  const isOutdated = getIsOutdated(tProfile);

  return (
    <div className={styles.talentItem}>
      <div className={styles.header}>
        {tProfile && !!tProfile.persona && tProfile?.persona === "active_performer" ? (
          <div>
            <PersonaLabel persona={tProfile.persona} />
          </div>
        ) : null}
        {talent.searchStatus ? (
          <div>
            <SearchStatusLabel talent={talent} />
          </div>
        ) : null}
        {nextCalls.length ? (
          <div className={cx(styles.text)}>
            <span>Prochains calls :</span>
            <ul className={styles.callsList}>
              {nextCalls.map((cb, i) => (
                <li key={`next-call-${cb}-${i}`}>
                  <Label type="green" content={format(new Date(cb), "dd/MM/yyyy HH:mm")} />
                </li>
              ))}
            </ul>
          </div>
        ) : null}
        <div>
          <div className={cx(styles.text, styles.bold)}>{coreResp}</div>
        </div>
        {tProfile ? (
          <div>
            <div className={styles.text}>{`${tProfile.experience} ans`}</div>
          </div>
        ) : null}
        {!!expRem ? (
          <div>
            <div className={styles.text}>{`${expRem.fixedSalary}k+${varRem}k (${expRem.fullPackage}k)`}</div>
          </div>
        ) : null}
      </div>
      <div className={styles.body}>
        <div className={styles.imgInfosPart}>
          <div className={styles.imageContainer}>
            <PersonProfileImg image={talent.image} alt={`${talent.firstname} ${talent.lastname}`} />
          </div>
          <div className={styles.infosContainer}>
            <div className={styles.nameGoldenContainer}>
              <h4 className={styles.text}>
                <Link
                  to={extractPageFromIri("talents", talent["@id"])}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.name}
                >{`${talent.firstname} ${talent.lastname}`}</Link>
              </h4>
              {tProfile && tProfile.golden ? (
                <div className={styles.goldenContainer}>
                  <GoldenTag className={styles.goldenTag} />
                </div>
              ) : null}
            </div>
            {tProfile?.currentJob || tProfile?.currentCompany ? (
              <div className={cx(styles.text)}>
                {tProfile?.currentJob} @ {tProfile?.currentCompany}
              </div>
            ) : null}
            <div className={styles.text}>{talent.email}</div>
            <div className={styles.text}>{talent.phone}</div>
          </div>
        </div>
        <div className={styles.dataPart}>
          <div className={styles.dataItem}>
            <div className={styles.dataNb}>
              {talent.referrals && talent.referrals.length ? talent.referrals.length : "0"}
            </div>
            <div className={styles.dataName}>filleuls</div>
          </div>
          <div className={cx(styles.dataItem, styles.processItem)}>
            <div className={styles.dataNb}>{talent.processCount ?? "0"}</div>
            <div className={styles.dataName}>process</div>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.bottomCtasContainer}>
          {talent?.phone ? (
            <div>
              <a
                className={cx(styles.cta, globalStyles.cta, globalStyles.whatsappCta)}
                target="_blank"
                rel="noopener noreferrer"
                href={`https://wa.me/${whatsappPhone}`}
              >
                <ImWhatsapp /> {t`Action.whatsapp.contact.text`}
              </a>
            </div>
          ) : null}

          {talent.linkedInProfile?.url ? (
            <div>
              <a
                className={cx(styles.cta, globalStyles.cta, globalStyles.linkedInCta)}
                target="_blank"
                rel="noopener noreferrer"
                href={talent.linkedInProfile?.url}
              >
                {t`Action.linkedIn.seeProfile.text`}
              </a>
            </div>
          ) : null}

          {hubspotUrl ? (
            <div>
              <a
                className={cx(styles.cta, globalStyles.cta, globalStyles.hubspotCta)}
                target="_blank"
                rel="noopener noreferrer"
                href={hubspotUrl}
              >
                {t`Action.hubspot.seeProfile.text`}
              </a>
            </div>
          ) : null}
        </div>
        <div>
          <div className={cx({ [styles.outdated]: isOutdated })}>
            <div className={styles.text}>
              <ReactMarkdown
                components={{
                  p: React.Fragment,
                }}
              >
                {lastTalentProfileEvent
                  ? t({
                      id: "TalentInfo.updateParams.text",
                      values: {
                        date: formatDistance(parseISO(lastTalentProfileEvent.createdAt), new Date(), {
                          locale: fr,
                        }),
                      },
                    })
                  : t`TalentInfo.updateParams.empty.text`}
              </ReactMarkdown>
            </div>
            <div className={styles.text}>
              <ReactMarkdown
                components={{
                  p: React.Fragment,
                }}
              >
                {lastQualificationDate
                  ? t({
                      id: "TalentInfo.lastQualif.text",
                      values: {
                        date: formatDistance(parseISO(lastQualificationDate), new Date(), {
                          locale: fr,
                        }),
                      },
                    })
                  : t`TalentInfo.lastQualif.empty.text`}
              </ReactMarkdown>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div className={styles.text}>
              <ReactMarkdown
                components={{
                  p: React.Fragment,
                }}
              >
                {t({
                  id: "TalentInfo.lastLogin.text",
                  values: {
                    date: formatDistance(parseISO(talent.lastLogin), new Date(), { locale: fr }),
                  },
                })}
              </ReactMarkdown>
            </div>
            <div className={styles.text}>
              <ReactMarkdown
                components={{
                  p: React.Fragment,
                }}
              >
                {t({
                  id: "TalentInfo.createdAt.text",
                  values: {
                    date: formatDistance(parseISO(talent.createdAt), new Date(), { locale: fr }),
                  },
                })}
              </ReactMarkdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
