import { t } from "@lingui/macro";
import cx from "classnames";

import React, { FC } from "react";
import ReactMarkdown from "react-markdown";
import { useDispatch } from "react-redux";
import { AnyAction } from "redux";

import { Talent, talentReactivationStatuses } from "../../../interfaces/resources/talent";

import { editTalentAction } from "../../../services/api/talent/actions";
import globalStyles from "../../../styles/global.module.scss";
import styles from "./index.module.scss";

interface Props {
  talent: Talent;
  onCloseModal: () => void;
}

export const RemoveTalentModal: FC<Props> = (props: Props) => {
  const { talent, onCloseModal } = props;
  const dispatch = useDispatch();
  const editTalent = (iri: string, req: any): AnyAction => dispatch(editTalentAction(iri, req));

  const closeModal = () => {
    if (typeof onCloseModal === "function") {
      onCloseModal();
    }
  };

  const onConfirmation = (reactivationStatus: string) => {
    const val = {
      reactivationStatus,
      needReactivation: false,
    };
    editTalent(talent["@id"], { ...talent, ...val });
    closeModal();
  };

  return (
    <div className={styles.modal}>
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <h3 className={styles.title}>{t`RemoveTalentModal.title`}</h3>
        </div>
        <div>
          <ReactMarkdown className={styles.text}>
            {t({
              id: "RemoveTalentModal.text",
              values: { firstname: talent.firstname, lastname: talent.lastname },
            })}
          </ReactMarkdown>
        </div>
        <div className={styles.ctaListContainer}>
          <div className={styles.cancelCtaContainer}>
            <button type="button" onClick={closeModal} className={cx(globalStyles.cta, globalStyles.alertCta)}>
              {t`Constant.cancel`}
            </button>
          </div>
          <div>
            <button
              type="button"
              className={cx(globalStyles.cta, globalStyles.primaryCta)}
              onClick={() => {
                onConfirmation(talentReactivationStatuses.contacted);
              }}
            >
              {t`RemoveTalentModal.cta.contacted`}
            </button>
          </div>
          <div>
            <button
              type="button"
              className={cx(globalStyles.cta, globalStyles.primaryCta)}
              onClick={() => {
                onConfirmation("");
              }}
            >
              {t`RemoveTalentModal.cta.remove`}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
