import { t } from "@lingui/macro";
import cx from "classnames";
import * as React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Popup } from "semantic-ui-react";

import globalStyles from "../../../styles/global.module.scss";

interface Props {
  default: string;
  title?: string;
  ctaText?: string;
}

export const CopyClip: React.FC<Props> = (props) => {
  const { default: defaultValue, title, ctaText } = props;
  const [copied, setCopied] = React.useState(false);
  const [value, setValue] = React.useState(defaultValue);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 800);
  };

  return (
    <Popup
      trigger={
        <div>
          <input
            onChange={({ target: { value: targetValue } }) => {
              setValue(targetValue);
              setCopied(false);
            }}
            type="hidden"
            value={value}
          />
          <CopyToClipboard onCopy={handleCopy} text={value}>
            <div className={cx(globalStyles.cta, globalStyles.primaryCta)} title={title}>
              {ctaText ? ctaText : "Copier"}
            </div>
          </CopyToClipboard>
        </div>
      }
      position="right center"
      inverted
      size="mini"
      open={copied}
      content={t`CopyClip.success`}
    />
  );
};
