import { CreateCompany, DeleteCompany, EditCompany, GetCompanies, GetCompany } from "../../../actionTypes/companies";

import { FilterContext } from "../../../interfaces/api/filter";
import { Company } from "../../../interfaces/resources/company";

export function getCompaniesAction(filterContext: FilterContext) {
  return {
    filterContext,
    type: GetCompanies.REQUEST,
  };
}

export function getCompanyAction(iri: string) {
  return {
    iri,
    type: GetCompany.REQUEST,
  };
}

export function deleteCompanyAction(iri: string) {
  return {
    iri,
    type: DeleteCompany.REQUEST,
  };
}

export function createCompanyAction(req: Partial<Company>, form?: string, redirectOnSuccess?: boolean | string) {
  return {
    form,
    redirectOnSuccess,
    req,
    type: CreateCompany.REQUEST,
  };
}

export function editCompanyAction(
  req: Partial<Company>,
  form?: string,
  validation?: string,
  redirectOnSuccess?: boolean | string,
) {
  return {
    form,
    redirectOnSuccess,
    req,
    type: EditCompany.REQUEST,
    validation,
  };
}
