import { t } from "@lingui/macro";
import cx from "classnames";
import { differenceInDays, format, formatDistance, parseISO } from "date-fns";
import { fr } from "date-fns/locale";
import * as React from "react";
import { FC } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GoldenTag } from "../../components/Design/GoldenTag";
import { ModalWrapper } from "../../components/ModalWrapper";
import { getCorrectTalentProfile, getHeadline, getIsOutdated } from "../../components/Utils/helpers";
import { applicationLateStage } from "../../interfaces/resources/application";
import { Talent, talentReactivationStatuses } from "../../interfaces/resources/talent";
import { State } from "../../interfaces/state";
import { extractPageFromIri } from "../../services/api/helpers";
import { transTalent } from "../../translations/constants/talent";
import { Label } from "../labels";
import { PersonaLabel } from "../labels/PersonaLabel";
import { SearchStatusLabel } from "../labels/SearchStatusLabel";
import { PersonProfileImg } from "../personProfileImg";

import styles from "./index.module.scss";
import { RemoveTalentModal } from "./RemoveTalentModal";

interface Props {
  talent: Talent;
  showLinkedIn?: boolean;
  className?: string;
  isActive?: boolean;
  mode?: "hunt" | "reactivation" | "new" | "default";
}

export const CardTalentMini: FC<Props> = (props: Props) => {
  const { talent, showLinkedIn = true, className = "", isActive, mode = "default" } = props;
  const talentProfileState = useSelector((state: State) => state.talentProfile);
  const talentProfilesState = useSelector((state: State) => state.talentProfiles);
  const talentProfile = getCorrectTalentProfile(talent, talentProfileState, talentProfilesState);
  const [openRemoveTalentModal, setOpenRemoveTalentModal] = React.useState(false);
  const reactivationDayPassed =
    talent.lastReactivationStatus
    && differenceInDays(new Date().setHours(23, 59, 59, 59), parseISO(talent.lastReactivationStatus));
  const reactivationDate =
    talent.lastReactivationStatus && format(parseISO(talent.lastReactivationStatus), "dd/MM/yyyy");
  const isOutdated = getIsOutdated(talentProfile);
  const headline = getHeadline(talentProfile, talent.linkedInProfile);

  const onCloseRemoveTalentModal = () => {
    setOpenRemoveTalentModal(false);
  };

  return (
    <>
      <div className={cx(styles.card, { [styles.active]: !!isActive }, { [className]: !!className })}>
        <div className={styles.headerCard}>
          {isOutdated && mode !== "new" ? (
            <div className={styles.headerItem}>
              <Label type="red" content={t`Label.outdated`} />
            </div>
          ) : null}
          {!!talentProfile?.persona && talentProfile?.persona === "active_performer" && mode !== "new" ? (
            <div className={styles.headerItem}>
              <PersonaLabel persona={talentProfile.persona} />
            </div>
          ) : null}
          {talentProfile?.golden ? (
            <div className={styles.headerItem}>
              <GoldenTag className={styles.goldenLabel} />
            </div>
          ) : null}
          {mode !== "reactivation" && talent.lateStageApplications?.length ? (
            <div>
              {talent.lateStageApplications?.map((lap, i) => {
                const isBeforeLastStage = lap.stage === applicationLateStage.beforeLast;
                return (
                  <div key={`mini-talent-card-late-stage-label-${lap.applicationId}-${i}`}>
                    <Label
                      type={isBeforeLastStage ? "yellow" : "green"}
                      tooltip={lap.name}
                      position="left"
                      content={isBeforeLastStage ? t`Label.beforeLastLap` : t`Label.lastLap`}
                    />
                  </div>
                );
              })}
            </div>
          ) : null}
          {talent.searchStatus ? (
            <div>
              <SearchStatusLabel talent={talent} />
            </div>
          ) : null}
          {mode === "reactivation" && !!talent.reactivationStatus ? (
            <div className={styles.headerItem}>
              <Label
                type={
                  [talentReactivationStatuses.to_contact, talentReactivationStatuses.searching].includes(
                    talent.reactivationStatus,
                  )
                    ? "green"
                    : "yellow"
                }
                content={t({ id: transTalent.reactivationStatus[talent.reactivationStatus] })}
              />
            </div>
          ) : null}
          {mode === "reactivation"
          && talent.reactivationStatus === talentReactivationStatuses.to_contact
          && !!talent.previousReactivationStatus
          && [talentReactivationStatuses.searching, talentReactivationStatuses.passive_searching].includes(
            talent.previousReactivationStatus,
          ) ? (
            <div className={styles.headerItem}>
              <Label
                type={talentReactivationStatuses.searching === talent.previousReactivationStatus ? "green" : "yellow"}
                content={t({ id: transTalent.reactivationStatus[talent.previousReactivationStatus] })}
              />
            </div>
          ) : null}
          {!["hunt", "new"].includes(mode) ? (
            <div className={cx(styles.headerItem, styles.teammateItem)}>
              <div className={styles.teammateContainer}>
                {!!talent.talentManager ? (
                  <>
                    <span className={styles.teammateName}>{talent.talentManager.firstname}</span>
                    <div className={styles.teammateImgContainer}>
                      <PersonProfileImg image={talent.talentManager.image} />
                    </div>
                  </>
                ) : (
                  <span className={styles.teammateName}>{t`Constant.noTim`}</span>
                )}
              </div>
            </div>
          ) : null}
          {mode === "reactivation" && talent.reactivationStatus === talentReactivationStatuses.to_contact ? (
            <div className={styles.headerItem}>
              <div
                className={styles.removeCtaContainer}
                onClick={() => {
                  setOpenRemoveTalentModal(true);
                }}
              >
                <IoMdCloseCircle />
              </div>
            </div>
          ) : null}
        </div>
        <div className={styles.bodyCard}>
          <div className={styles.talentPartContainer}>
            <div className={styles.talentPhotoContainer}>
              <PersonProfileImg image={talent.image} />
            </div>
            <div className={styles.talentInfosContainer}>
              <div>
                <Link
                  to={extractPageFromIri("talents", talent["@id"])}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.name}
                >{`${talent.firstname} ${talent.lastname}`}</Link>
              </div>
              {!!talent.linkedInProfile && showLinkedIn ? (
                <div className={styles.infoLinkedInContainer}>
                  <a
                    className={cx({ [styles.linkedInLink]: !!talent.linkedInProfile?.url }, styles.infosSupp)}
                    href={talent.linkedInProfile.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {headline}
                  </a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {!!(talent.lastReactivationStatus && mode === "reactivation")
        || !!(talent.lastLogin && mode !== "reactivation") ? (
          <div className={styles.footerCard}>
            <div className={styles.footerItem}>
              <div>
                {mode === "reactivation" ? (
                  <span>
                    {talent.reactivationStatus === talentReactivationStatuses.to_contact
                      ? t({
                          id: "cardTalentMini.reactivation.date.toContact",
                          values: {
                            reactivationDayPassed,
                            reactivationDate,
                          },
                        })
                      : t({
                          id: "cardTalentMini.reactivation.date.newConnection",
                          values: {
                            reactivationDate,
                          },
                        })}
                  </span>
                ) : talent.lastLogin ? (
                  <span>
                    <ReactMarkdown
                      components={{
                        p: React.Fragment,
                      }}
                    >
                      {t({
                        id: "TalentInfo.lastLogin.text",
                        values: {
                          date: formatDistance(parseISO(talent.lastLogin), new Date(), { locale: fr }),
                        },
                      })}
                    </ReactMarkdown>
                  </span>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <ModalWrapper open={openRemoveTalentModal} onCloseModal={onCloseRemoveTalentModal}>
        <RemoveTalentModal talent={talent} onCloseModal={onCloseRemoveTalentModal} />
      </ModalWrapper>
    </>
  );
};
