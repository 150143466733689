import { ActionType } from "../interfaces/api/actionType";

export const CreateCoreUser: ActionType = {
  FAILURE: "CREATE_CORE_USER_FAILURE",
  REQUEST: "CREATE_CORE_USER_REQUEST",
  SUCCESS: "CREATE_CORE_USER_SUCCESS",
};

export const DeleteCoreUser: ActionType = {
  FAILURE: "DELETE_CORE_USER_FAILURE",
  REQUEST: "DELETE_CORE_USER_REQUEST",
  SUCCESS: "DELETE_CORE_USER_SUCCESS",
};

export const EditCoreUser: ActionType = {
  FAILURE: "EDIT_CORE_USER_FAILURE",
  REQUEST: "EDIT_CORE_USER_REQUEST",
  SUCCESS: "EDIT_CORE_USER_SUCCESS",
};

export const GetCoreUser: ActionType = {
  FAILURE: "GET_CORE_USER_FAILURE",
  REQUEST: "GET_CORE_USER_REQUEST",
  SUCCESS: "GET_CORE_USER_SUCCESS",
};

export const GetCoreUsers: ActionType = {
  FAILURE: "GET_CORE_USERS_FAILURE",
  REQUEST: "GET_CORE_USERS_REQUEST",
  SUCCESS: "GET_CORE_USERS_SUCCESS",
};

export const GetCoreUserApplications: ActionType = {
  FAILURE: "GET_CORE_USER_APPLICATIONS_FAILURE",
  REQUEST: "GET_CORE_USER_APPLICATIONS_REQUEST",
  SUCCESS: "GET_CORE_USER_APPLICATIONS_SUCCESS",
};

export const GetCurrentUser: ActionType = {
  FAILURE: "GET_CURRENT_USER_FAILURE",
  REQUEST: "GET_CURRENT_USER_REQUEST",
  SUCCESS: "GET_CURRENT_USER_SUCCESS",
};
