import { DeleteTalentFromToContact, RemoveTalentFromHunt, SaveTalentOnContact } from "../../../actionTypes/hunt";

import { Talent } from "../../../interfaces/resources/talent";

export function saveTalentOnContactAction(talent: Talent) {
  return {
    talent,
    type: SaveTalentOnContact.DEFAULT,
  };
}

export function removeTalentFromHuntAction(talent: Talent) {
  return {
    talent,
    type: RemoveTalentFromHunt.DEFAULT,
  };
}

export function deleteTalentFromToContactAction(iri: string) {
  return {
    iri,
    type: DeleteTalentFromToContact.DEFAULT,
  };
}
