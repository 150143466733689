import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";

import { GetResponsibilities } from "../../../actionTypes/responsibilities";
import { Responsibility } from "../../../interfaces/resources/responsibility";
import { apiFailureAction } from "../actions";
import { extractNextPageNumberFromIri } from "../helpers";
import { getResponsibilitiesSuccessAction } from "./actions";
import * as apiClient from "./api";

export function* getResponsibilities(action: AnyAction) {
  try {
    const response: AxiosResponse<{
      "hydra:member": Responsibility[];
      "hydra:totalItems": number;
      "hydra:view"?: {
        "hydra:next"?: string;
      };
    }> = yield call(apiClient.getResponsibilities, action.page);
    const responsibilities = response.data["hydra:member"];
    yield put(getResponsibilitiesSuccessAction(responsibilities, action));
    if (response.data["hydra:view"] && response.data["hydra:view"]["hydra:next"]) {
      yield put({
        page: extractNextPageNumberFromIri(response.data["hydra:view"]["hydra:next"]),
        type: GetResponsibilities.REQUEST,
      });
    }
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export default function* getResponsibilitiesSaga() {
  yield takeLatest(GetResponsibilities.REQUEST, getResponsibilities);
}
