import Yup from "../../../../components/App/Yup";

const PhoneCallSchema = Yup.object().shape({
  applicationsToConfirm: Yup.array().of(Yup.object()),
  applicationsToPitch: Yup.array().of(Yup.object()),
  applicationsToSubmit: Yup.array().of(Yup.object()),
  notes: Yup.string(),
  title: Yup.string().required(),
  type: Yup.string().oneOf(["filter_spontaneous_application", "filter_talent_card", "filter_reactivation"]).required(),
});

export default PhoneCallSchema;
