import { ActionType } from "../interfaces/api/actionType";

export const GetTalentApplication: ActionType = {
  FAILURE: "GET_TALENT_APPLICATION_FAILURE",
  REQUEST: "GET_TALENT_APPLICATION_REQUEST",
  SUCCESS: "GET_TALENT_APPLICATION_SUCCESS",
};

export const GetTalentApplications: ActionType = {
  FAILURE: "GET_TALENT_APPLICATIONS_FAILURE",
  REQUEST: "GET_TALENT_APPLICATIONS_REQUEST",
  SUCCESS: "GET_TALENT_APPLICATIONS_SUCCESS",
};
