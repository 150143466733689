import { t } from "@lingui/macro";
import cx from "classnames";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { Field, Form, Formik, FormikHelpers, FormikValues } from "formik";
import * as React from "react";
import { BsMailbox } from "react-icons/bs";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { PostApplicationMessage } from "../../../actionTypes/applications";
import Yup from "../../../components/App/Yup";
import RichTextEditor from "../../../components/Form/Formik/RichTextEditor";
import { ModalWrapper } from "../../../components/ModalWrapper";
import { Tooltip } from "../../../components/Tooltip";
import { useApiSelector, usePrevious } from "../../../components/Utils/hook";
import {
  Application,
  applicationClientStatus,
  applicationStatusesInProcess,
} from "../../../interfaces/resources/application";
import { ApplicationClientStatusEvent, ApplicationMessage } from "../../../interfaces/resources/applicationMessages";
import { State } from "../../../interfaces/state";
import { onPostApplicationMessage, onReadApplicationMessages } from "../../../services/api/applications/actions";
import { getOfferApplicationAction } from "../../../services/api/offer/applications/actions";
import globalStyles from "../../../styles/global.module.scss";
import transApplication from "../../../translations/constants/application";
import styles from "./index.module.scss";

interface Props {
  onCloseModal: () => void;
  application: Application;
}

interface CtaProps {
  hasUnreadMessage?: boolean;
  application: Application;
}

interface KeyNodeProps {
  type: "clientStatusEvent" | "clientMessage";
  node: ApplicationClientStatusEvent | ApplicationMessage;
}

export const MessageModalCta: React.FC<CtaProps> = (props: CtaProps) => {
  const { application, hasUnreadMessage = false } = props;
  const [openModal, setOpenModal] = React.useState(false);
  const closeModal = () => {
    setOpenModal(false);
  };
  return (
    <>
      <Tooltip
        content={
          <div className={styles.tooltipContent}>
            <div>Voir les messages (internes/clients)</div>
          </div>
        }
      >
        <button
          type="button"
          className={cx(globalStyles.cta, globalStyles.primaryCta, globalStyles.inverted, styles.cta, {
            [styles.unreadMessage]: hasUnreadMessage,
          })}
          onClick={() => {
            setOpenModal(true);
          }}
        >
          <BsMailbox /> <span className={styles.messageCount}>{application.applicationMessages?.length}</span>
        </button>
      </Tooltip>
      <ModalWrapper open={openModal} onCloseModal={closeModal}>
        <MessageModal onCloseModal={closeModal} application={application} />
      </ModalWrapper>
    </>
  );
};

export const MessageModal: React.FC<Props> = (props: Props) => {
  const { onCloseModal, application: applicationInit } = props;
  const dispatch = useDispatch();
  const applicationState = useSelector((state: State) => state.application);
  const application = applicationState?.["@id"] === applicationInit["@id"] ? applicationState : applicationInit;
  const currentUser = useSelector((state: State) => state.currentUser);
  const { apiErrors, apiSuccess } = useApiSelector();
  const prevApiErrors = usePrevious(apiErrors);
  const prevApiSuccess = usePrevious(apiSuccess);
  const resetFormRef = React.useRef<any>(null);
  const [showMessageForm, setShowMessageForm] = React.useState(false);
  const [showClientMessageCta, setShowClientMessageCta] = React.useState(false);
  const [messageType, setMessageType] = React.useState<"internal" | "client" | null>(null);
  const [isFormSubmitting, setIsFormSubmitting] = React.useState(false);
  const [unreadMessages, setUnreadMessages] = React.useState<ApplicationMessage[]>([]);
  const [applicationMessages, setApplicationMessages] = React.useState<[string, KeyNodeProps[]][]>([]);
  const validationSchema = Yup.object().shape({
    message: Yup.string().required(),
  });
  const renderMessages = (cMsg: KeyNodeProps[]) => {
    const newsItems: React.ReactNode[] = [];
    cMsg.sort((a, b) => {
      const dA = new Date(a.node.createdAt).getTime();
      const dB = new Date(b.node.createdAt).getTime();
      return dA - dB;
    });
    cMsg.forEach(({ node, type }) => {
      const hour = format(new Date(node.createdAt), "HH:mm", {
        locale: fr,
      });
      switch (type) {
        case "clientStatusEvent":
          const eventItem = node as ApplicationClientStatusEvent;
          const colTitle = t({ id: transApplication.clientStatus[eventItem.status] });
          const eventItemvalues = {
            colTitle,
            firstname: application.talent?.firstname,
            doer: String(eventItem.clientContact && eventItem.clientContact.firstname),
          };
          let eventItemTranslation = t({
            id: "MessageModal.wording.newsFeed.event.item.null",
            values: eventItemvalues,
          });
          if (eventItem.clientContact) {
            eventItemTranslation = t({
              id: "MessageModal.wording.newsFeed.event.item",
              values: eventItemvalues,
            });
          }
          newsItems.push(
            <li key={`${eventItem["@id"]}-${eventItem.status}`} className={styles.newsItem}>
              <div>
                <div>
                  <ReactMarkdown
                    components={{
                      p: React.Fragment,
                    }}
                  >
                    {eventItemTranslation}
                  </ReactMarkdown>
                </div>
              </div>
              <div className={styles.newsHourContainer}>{hour}</div>
            </li>,
          );
          break;
        case "clientMessage":
          const messageItem = node as ApplicationMessage;
          const messageItemValues = {
            doer: messageItem.clientContact
              ? String(messageItem.clientContact.firstname)
              : messageItem.coreUser
              ? String(messageItem.coreUser.firstname)
              : "",
          };

          let messageItemTranslation = "";
          if (messageItem.clientEnabled === true) {
            if (messageItem.coreUser && currentUser && currentUser["@id"] === messageItem.coreUser["@id"]) {
              messageItemTranslation = t({
                id: "MessageModal.wording.newsFeed.message.coreuser.item.you",
                values: messageItemValues,
              });
            } else if (messageItem.coreUser) {
              messageItemTranslation = t({
                id: "MessageModal.wording.newsFeed.message.coreuser.item",
                values: messageItemValues,
              });
            } else if (messageItem.clientContact) {
              messageItemTranslation = t({
                id: "MessageModal.wording.newsFeed.message.client.item",
                values: messageItemValues,
              });
            }
          } else {
            if (currentUser?.["@id"] === messageItem?.coreUser?.["@id"]) {
              messageItemTranslation = t`MessageModal.wording.newsFeed.internal.message.item.you`;
            } else {
              messageItemTranslation = messageItemTranslation = t({
                id: "MessageModal.wording.newsFeed.internal.message.coreuser.item",
                values: messageItemValues,
              });
            }
          }

          const unread =
            (!!!messageItem.coreUser || messageItem.coreUser["@id"] !== currentUser?.["@id"])
            && !messageItem.coreUserRead?.includes(currentUser?.["@id"] || "");

          newsItems.push(
            <li
              key={`${messageItem["@id"]}-${messageItem.createdAt}`}
              className={cx(styles.newsItem, { [styles.unread]: unread })}
            >
              <div>
                <div className={styles.headingText}>
                  <div>
                    <ReactMarkdown
                      components={{
                        p: React.Fragment,
                      }}
                    >
                      {messageItemTranslation}
                    </ReactMarkdown>
                  </div>
                  {messageItem.clientEnabled === true ? (
                    <div className={styles.clientVisibleInfo}>(visible par le client)</div>
                  ) : null}
                </div>
                <div>
                  <ReactMarkdown>{messageItem.message}</ReactMarkdown>
                </div>
              </div>
              <div className={styles.newsHourContainer}>{hour}</div>
            </li>,
          );
          break;
      }
    });
    return newsItems;
  };
  const closeModal = () => {
    if (typeof onCloseModal === "function") {
      onCloseModal();
    }
  };
  const onClickAddDebrief = (buttonType: string) => {
    if (!messageType || buttonType === messageType) {
      setShowMessageForm(!showMessageForm);
    }
  };
  const onSubmit = (
    values: FormikValues,
    formikHelpers: FormikHelpers<{
      message: string;
    }>,
  ) => {
    setIsFormSubmitting(true);
    resetFormRef.current = formikHelpers.resetForm;
    dispatch(
      onPostApplicationMessage({
        coreUserIri: currentUser?.["@id"] || "",
        applicationIri: application?.["@id"] || "",
        message: values.message,
        clientEnabled: messageType === "client",
      }),
    );
  };

  React.useEffect(() => {
    if (application) {
      const keyNodes: {
        [date: string]: KeyNodeProps[];
      } = {};
      const unreadNodes: ApplicationMessage[] = [];
      const evs = application.applicationClientStatusEvents || [];
      const msgs = application.applicationMessages || [];
      evs.forEach((ev) => {
        if (ev !== null && ev.status !== applicationClientStatus.clientPreSend) {
          const nodeDate = new Date(ev.createdAt);
          const keyDate = nodeDate.toDateString();
          const keyNode: KeyNodeProps = { type: "clientStatusEvent", node: ev };
          if (Array.isArray(keyNodes[keyDate])) {
            keyNodes[keyDate].push(keyNode);
          } else {
            keyNodes[keyDate] = [keyNode];
          }
        }
      });
      msgs.forEach((ev) => {
        if (ev !== null) {
          const nodeDate = new Date(ev.createdAt);
          const keyDate = nodeDate.toDateString();
          const keyNode: KeyNodeProps = { type: "clientMessage", node: ev };

          if (
            (!!!ev.coreUser || ev.coreUser["@id"] !== currentUser?.["@id"])
            && !ev.coreUserRead?.includes(currentUser?.["@id"] || "")
          ) {
            unreadNodes.push(ev);
          }
          if (Array.isArray(keyNodes[keyDate])) {
            keyNodes[keyDate].push(keyNode);
          } else {
            keyNodes[keyDate] = [keyNode];
          }
        }
      });

      const sorteKeyNodes = Object.entries(keyNodes).sort(
        (a, b) => new Date(a[0]).getTime() - new Date(b[0]).getTime(),
      );
      setUnreadMessages(unreadNodes);
      setApplicationMessages(sorteKeyNodes);

      // On montre la possibilité d'envoyer un compte rendu client si la candidature est en process
      setShowClientMessageCta(applicationStatusesInProcess.includes(application.status));
    }
  }, [application, currentUser]);

  React.useEffect(() => {
    if (unreadMessages.length && currentUser?.["@id"]) {
      dispatch(onReadApplicationMessages({ messages: unreadMessages, coreUserIri: currentUser?.["@id"] }));
    }
  }, [unreadMessages, currentUser, dispatch]);

  React.useEffect(() => {
    const apiSuccessIntersection = [...apiSuccess].slice(prevApiSuccess?.length);
    if (apiSuccessIntersection.some((action) => [PostApplicationMessage.SUCCESS].includes(action.type))) {
      dispatch(getOfferApplicationAction(application["@id"]));
      if (typeof resetFormRef.current === "function") {
        resetFormRef.current({ message: "" });
      }
      setIsFormSubmitting(false);
    }
  }, [apiErrors, apiSuccess, prevApiErrors, prevApiSuccess, dispatch, resetFormRef]);

  React.useEffect(() => {
    return () => {
      dispatch(getOfferApplicationAction(application["@id"]));
    };
  }, [dispatch]);

  return (
    <div className={styles.modal}>
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <h3 className={styles.title}>Les messages</h3>
        </div>
        <div className={cx(styles.newsFeedListContainer, { [styles.messageFormVisible]: showMessageForm })}>
          <ul className={styles.newsFeedList}>
            {!!applicationMessages
              ? applicationMessages.map((am, i) => {
                  const date = format(new Date(am[0]), "EEEE dd MMMM y", {
                    locale: fr,
                  });
                  return (
                    <li key={`newsFeed-container-${am[0]}-${i}`}>
                      <div className={styles.newsFeedDay}>{date}</div>
                      <div>
                        <ul className={styles.newsFeedInnerList}>{renderMessages(am[1])}</ul>
                      </div>
                    </li>
                  );
                })
              : null}
          </ul>
        </div>
        {showMessageForm ? (
          <div>
            <Formik onSubmit={onSubmit} initialValues={{ message: "" }} validationSchema={validationSchema}>
              {() => (
                <Form>
                  <div className={styles.messageFormContent}>
                    <div>
                      <Field
                        component={RichTextEditor}
                        uniqId={`message-modal-app-${application["@id"]}`}
                        required={true}
                        replaceIfEmpty={false}
                        name="message"
                        className={styles.messageTextarea}
                        placeholder="Écrivez une note..."
                        label={messageType === "client" ? "Envoyer au client" : "Laisser une note interne"}
                        inlineSubmitTrigger={
                          <div>
                            <button
                              type="submit"
                              disabled={isFormSubmitting}
                              className={cx(globalStyles.cta, {
                                [globalStyles.clientVisibleCta]: messageType === "client",
                                [globalStyles.aquaCta]: messageType === "internal",
                              })}
                            >
                              Envoyer
                            </button>
                          </div>
                        }
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        ) : null}
        <div className={styles.ctaListContainer}>
          {showClientMessageCta ? (
            <div>
              <button
                type="button"
                onClick={() => {
                  setMessageType("client");
                  onClickAddDebrief("client");
                }}
                className={cx(globalStyles.cta, globalStyles.clientVisibleCta, {
                  [globalStyles.inverted]: !(showMessageForm && messageType === "client"),
                })}
              >
                Compte rendu client
              </button>
            </div>
          ) : null}
          <div>
            <button
              type="button"
              onClick={() => {
                setMessageType("internal");
                onClickAddDebrief("internal");
              }}
              className={cx(globalStyles.cta, globalStyles.aquaCta, {
                [globalStyles.inverted]: !(showMessageForm && messageType === "internal"),
              })}
            >
              Note interne
            </button>
          </div>
          <div className={styles.ctaCloseContainer}>
            <button
              type="button"
              onClick={closeModal}
              className={cx(globalStyles.cta, globalStyles.darkCta, globalStyles.inverted)}
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
