import { FilterContext } from "../../../interfaces/api/filter";
import { TalentCard } from "../../../interfaces/resources/talentCard";

import client from "../client";
import { generateFilterQuery } from "../helpers";

export function createTalentCard(talentCard: Partial<TalentCard>) {
  return client.post("/api/v2/talent_cards", talentCard);
}

export function editTalentCard(talentCard: Partial<TalentCard>) {
  if (talentCard["@id"]) {
    return client.put(talentCard["@id"], talentCard);
  }
}

export function getTalentCard(iri: string) {
  return client.get(iri);
}

export function getTalentCards(filterContext: FilterContext) {
  return client.get(`/api/v2/talent_cards${encodeURI(generateFilterQuery(filterContext))}`);
}
