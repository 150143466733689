import { t } from "@lingui/macro";
import cx from "classnames";
import React from "react";
import { getUserImage } from "../../components/App/Image";

import styles from "./index.module.scss";

interface Props {
  classNames?: string;
  hasOverlay?: boolean;
  isRound?: boolean;
  image?: { url: string };
  alt?: string;
}

export const PersonProfileImg: React.FC<Props> = (props: Props) => {
  const { classNames = "", hasOverlay = false, isRound = true, image, alt } = props;

  return (
    <div
      className={cx(
        styles.personImgContainer,
        { [styles.hasOverlay]: hasOverlay, [styles.isRound]: isRound },
        classNames,
      )}
    >
      <div className={styles.personImgContent}>
        <img
          data-testid="img"
          {...getUserImage(image)}
          className={styles.personImg}
          alt={alt ? alt : t`alts.profile.photo`}
        />
      </div>
    </div>
  );
};
