import * as React from "react";
import { WrappedFieldInputProps, WrappedFieldMetaProps } from "redux-form";
import { CheckboxProps, Form } from "semantic-ui-react";

interface CheckboxFieldProps extends CheckboxProps {
  getOptionValue: (option: any) => string;
  input: WrappedFieldInputProps;
  meta: WrappedFieldMetaProps;
  item: any;
  options: any[];
}

const Checkbox = ({
  getOptionValue,
  input: { onChange, value, name },
  item,
  label,
  options,
  ...rest
}: CheckboxFieldProps) => {
  return (
    <Form.Checkbox
      {...rest}
      value={getOptionValue(item)}
      label={label ? label : item.value}
      name={name}
      checked={Array.isArray(value) && value.some((option: any) => getOptionValue(option) === getOptionValue(item))}
      onChange={(_: any, data: any) => {
        const newValue = value ? [...value] : [];
        const changedValue = options.find((option: any) => data.value === getOptionValue(option));
        if (data.checked) {
          newValue.push(changedValue);
        } else {
          newValue.splice(newValue.indexOf(changedValue), 1);
        }
        return onChange(newValue);
      }}
    />
  );
};

export default Checkbox;
