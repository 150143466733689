import { t } from "@lingui/macro";
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import Select, { ValueType } from "react-select";

import { GetOffers } from "../../../actionTypes/offers";
import { Application } from "../../../interfaces/resources/application";
import { Offer, offerStatuses } from "../../../interfaces/resources/offer";
import { Talent } from "../../../interfaces/resources/talent";
import { State } from "../../../interfaces/state";

import { getOffersAction } from "../../../services/api/offer/actions";
import { getTalentApplicationsAction } from "../../../services/api/talent/talentApplication/actions";
import { defaultStyles } from "../../Form/Select";
import { useGetResourceHook, useApiSelector } from "../hook";

interface Props {
  talent?: Talent;
  handleAction: (value: Offer) => void;
  placeholder?: string;
}

export const OfferSelect: React.FC<Props> = ({ handleAction, talent, placeholder }: Props) => {
  const { apiErrors, apiPendingRequests, apiSuccess } = useApiSelector();
  const dispatch = useDispatch();
  const offers = useSelector((state: State) => state.offers);
  const talentApplications = useSelector((state: State) => state.talentApplications);

  const getOffers = () => {
    dispatch(
      getOffersAction([
        {
          multiple: true,
          name: "status",
          value: [offerStatuses.activated, offerStatuses.standbyElinoi],
        },
        {
          multiple: true,
          name: "groups",
          value: ["internal:read:offer:select"],
        },
      ]),
    );
  };

  useGetResourceHook(apiErrors, apiPendingRequests, apiSuccess, GetOffers, [], getOffers);
  const isLoading = apiPendingRequests.some((item) => [GetOffers.REQUEST].includes(item.type));

  React.useEffect(() => {
    if (talent) {
      dispatch(getTalentApplicationsAction(talent["@id"], [], "talentApplications"));
    }
  }, [talent, dispatch]);
  return (
    <Select
      placeholder={placeholder ?? t`OfferTitleFilter.placeholder`}
      options={
        (talentApplications
          && offers.filter(
            (offer: Offer) =>
              !talentApplications.some((application: Application) => {
                return application.offer["@id"] === offer["@id"];
              }),
          ))
        || offers
      }
      isSearchable={true}
      isLoading={isLoading}
      getOptionLabel={(option: Offer): string => `${option.company.name} - ${option.title}`}
      getOptionValue={(option: Offer): string => option["@id"]}
      value={undefined}
      onChange={(value: ValueType<Offer, any>): void => handleAction(value as Offer)}
      styles={defaultStyles}
    />
  );
};
