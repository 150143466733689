import { t } from "@lingui/macro";
import { FC } from "react";
import * as React from "react";
import { useSelector } from "react-redux";
import { Item, Loader } from "semantic-ui-react";
import { GetTalentApplications } from "../../../../../../actionTypes/talentApplications";
import { getCompanyImage } from "../../../../../../components/App/Image";
import RoundImage from "../../../../../../components/Design/RoundImage";
import { ApiRequest } from "../../../../../../interfaces/api/request";
import { Application } from "../../../../../../interfaces/resources/application";
import { offerStatuses } from "../../../../../../interfaces/resources/offer";
import transOffer from "../../../../../../translations/constants/offer";
import "../../ApplicationOfferList";

interface Props {
  setCurrentApplication: (application: Application) => void;
  applications: Application[];
  currentApplication: Application | null;
}

const ApplicationOfferList: FC<Props> = ({ setCurrentApplication, applications, currentApplication }: Props) => {
  const apiPendingRequests: ApiRequest[] = useSelector((state: any) => state.apiPendingRequests);
  const isApplicationListLoading =
    apiPendingRequests.length > 0 && apiPendingRequests.some((e) => GetTalentApplications.REQUEST === e.type);

  const chooseApplication = (application: Application) => {
    setCurrentApplication(application);
  };

  const applicationList = applications.map((application: Application, i: number) => (
    <Item key={i} className={application === currentApplication ? "selected" : ""}>
      <RoundImage
        className="standbyOfferImage"
        size="30px"
        banner={
          application.offer.status === offerStatuses.standbyElinoi
            ? t({ id: transOffer.status[application.offer.status] })
            : undefined
        }
        verySmallBanner={application.offer.status === offerStatuses.standbyElinoi}
        {...getCompanyImage(application.offer.company.image)}
      />
      <Item.Content onClick={() => chooseApplication(application)}>
        <Item.Header className="item">
          <span className="emphasis">{application.offer.company.name}</span> &mdash; {application.offer.title}
        </Item.Header>
      </Item.Content>
    </Item>
  ));

  return isApplicationListLoading ? (
    <Loader active={true} inline="centered" size="medium">
      Loading
    </Loader>
  ) : applicationList.length > 0 ? (
    <Item.Group className="applicationOfferListContainer">{applicationList}</Item.Group>
  ) : (
    <p>{t`applications.empty`}</p>
  );
};

export default ApplicationOfferList;
