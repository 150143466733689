import { t } from "@lingui/macro";
import cx from "classnames";
import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, Placeholder, Segment } from "semantic-ui-react";

import { GetPhoneCalls } from "../../../actionTypes/phoneCalls";
import { GetTalentApplications } from "../../../actionTypes/talentApplications";
import { GetTalentQualifications } from "../../../actionTypes/talentQualification";
import QualificationForm from "../../../components/QualificationForm";
import { useApiSelector, useGetResourceHook, usePrevious } from "../../../components/Utils/hook";
import { CoreUser } from "../../../interfaces/resources/coreUser";
import { PhoneCall } from "../../../interfaces/resources/phoneCall";
import { Qualification } from "../../../interfaces/resources/qualification";
import { Talent } from "../../../interfaces/resources/talent";
import { TalentProfile } from "../../../interfaces/resources/talentProfile";
import { getPhoneCallsAction, submitPhoneCallAction } from "../../../services/api/talent/phoneCalls/actions";
import { getTalentApplicationsAction } from "../../../services/api/talent/talentApplication/actions";
import { getTalentQualificationsAction } from "../../../services/api/talent/talentQualification/actions";
import globalStyles from "../../../styles/global.module.scss";

import transPhoneCall from "../../../translations/constants/phoneCall";
import { QualificationItem } from "../QualificationItem";
import styles from "./index.module.scss";
import { PhoneCallComp } from "./PhoneCall";
import { PhoneCallForm } from "./PhoneCallForm";
import { PhoneCallFormValues } from "./PhoneCallForm/helpers";
import { TalentCardModal } from "./PhoneCallForm/TalentCardModal";

interface Props {
  readOnly?: boolean;
}

export type FormMode = "suivi" | "reactivation";

export const PhoneCalls: React.FC<Props> = ({ readOnly = false }: Props) => {
  const { apiErrors, apiPendingRequests, apiSuccess } = useApiSelector();
  const coreUser: CoreUser = useSelector((state: any) => state.currentUser);
  const phoneCalls: PhoneCall[] = useSelector((state: any) => state.phoneCalls);
  const talent: Talent | null = useSelector((state: any) => state.talent);
  const talentProfile: TalentProfile | null = useSelector((state: any) => state.talentProfile);
  const talentQualifications: Qualification[] = useSelector((state: any) => state.talentQualifications);
  const [formMode, setFormMode] = useState<FormMode>("suivi");
  const [isTCModalOpen, setIsTCModalOpen] = useState(false);

  const dispatch = useDispatch();
  const previousTalent = usePrevious(talent);
  const onClickFormModeCta = (mode: FormMode) => {
    setFormMode(mode);
  };

  const getTalentQualifications = (tal: Talent) => dispatch(getTalentQualificationsAction(tal["@id"]));
  useGetResourceHook(
    apiErrors,
    apiPendingRequests,
    apiSuccess,
    GetTalentQualifications,
    null,
    getTalentQualifications,
    [talent],
  );
  React.useEffect(() => {
    const getTalentApplications = (tlnt: Talent) => {
      dispatch(getTalentApplicationsAction(tlnt["@id"], [], "talentApplications"));
    };
    if (
      !apiErrors.some((e) => e.type === GetTalentApplications.FAILURE)
      && !apiPendingRequests.some((e) => e.type === GetTalentApplications.REQUEST)
      && talent
      && talent["@id"]
      && (!previousTalent || previousTalent["@id"] !== talent["@id"])
    ) {
      getTalentApplications(talent);
    }
  }, [apiErrors, apiPendingRequests, talent, previousTalent, dispatch]);

  React.useEffect(() => {
    if (
      !apiErrors.some((e) => e.type === GetPhoneCalls.FAILURE)
      && !apiPendingRequests.some((e) => e.type === GetPhoneCalls.REQUEST)
      && talent
      && talent["@id"]
      && (!previousTalent || previousTalent["@id"] !== talent["@id"])
    ) {
      dispatch(getPhoneCallsAction(talent["@id"]));
    }
  }, [apiErrors, apiPendingRequests, talent, previousTalent, dispatch]);

  const isLoading = apiPendingRequests.some((req) => req.type === GetPhoneCalls.REQUEST);

  const handlePhoneCallSubmit = (values: PhoneCallFormValues) => {
    dispatch(
      submitPhoneCallAction({
        ...values,
        applications: [...values.applicationsToSubmit, ...values.applicationsToConfirm, ...values.applicationsToPitch],
        coreUser,
        talent,
      }),
    );
  };

  return (
    <>
      <div>
        {!readOnly ? (
          <div className={styles.ctaHeaderContainer}>
            <div className={styles.ctaContainer}>
              <button
                onClick={() => {
                  onClickFormModeCta("suivi");
                }}
                className={cx(styles.cta, globalStyles.cta, { [styles.active]: formMode === "suivi" })}
                type="button"
              >
                {t({ id: transPhoneCall.type.filter_spontaneous_application })}
              </button>
            </div>
            <div className={styles.ctaContainer}>
              <button
                onClick={() => {
                  onClickFormModeCta("reactivation");
                }}
                className={cx(styles.cta, globalStyles.cta, { [styles.active]: formMode === "reactivation" })}
                type="button"
              >
                {t({ id: transPhoneCall.type.filter_reactivation })}
              </button>
            </div>
            <div className={cx(styles.ctaContainer, styles.talentCardCtaContainer)}>
              <button
                onClick={() => {
                  setIsTCModalOpen(true);
                }}
                className={cx(styles.talentCardCta, globalStyles.cta, globalStyles.darkCta)}
                type="button"
              >
                {t({ id: transPhoneCall.type.filter_talent_card })}
              </button>
            </div>
          </div>
        ) : null}
        <div className={styles.notesWrapper}>
          {formMode === "reactivation" ? (
            <QualificationForm edition={false} reactivation={true} setFormMode={setFormMode} />
          ) : (
            <div className={styles.noteListFormContainer}>
              <div>
                {talentQualifications && talentQualifications.length > 0 ? (
                  <div className={styles.qualifWrapper}>
                    {talentQualifications.map((qualification, i) => (
                      <div key={`${qualification["@id"]} - ${Math.random()}`}>
                        <QualificationItem
                          qualifState={i !== talentQualifications.length - 1 ? "reactivation" : "qualification"}
                          isLastQualif={i === 0}
                          size={i === 0 ? "normal" : "small"}
                          qualificationIri={qualification["@id"] || ""}
                        />
                      </div>
                    ))}
                  </div>
                ) : !isLoading ? (
                  <p>{t`qualification.empty`}</p>
                ) : null}
                {isLoading ? (
                  <Segment basic>
                    <Placeholder fluid>
                      <Placeholder.Header>
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                      </Placeholder.Header>
                      <Placeholder.Header>
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                      </Placeholder.Header>
                    </Placeholder>
                  </Segment>
                ) : phoneCalls.length ? (
                  <Accordion fluid className={styles.phoneCallContainer}>
                    {phoneCalls.map((item: PhoneCall) => (
                      <PhoneCallComp key={item["@id"]} phoneCall={item} coreUser={coreUser} readOnly={readOnly} />
                    ))}
                  </Accordion>
                ) : null}
              </div>
              {coreUser && talent && talentProfile ? (
                <div className={styles.noteFormContainer}>
                  <div>
                    <PhoneCallForm talent={talent} onSubmit={handlePhoneCallSubmit} readOnly={readOnly} />
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
      {!readOnly && talent ? (
        <TalentCardModal
          talent={talent}
          size="large"
          onClose={(): any => setIsTCModalOpen(false)}
          open={isTCModalOpen}
        />
      ) : null}
    </>
  );
};
