import {
  CreateTalentQualification,
  EditTalentQualification,
  GetChatGPT,
  GetTalentQualification,
  GetTalentQualifications,
  PostTranscript,
} from "../../../../actionTypes/talentQualification";

import { Qualification } from "../../../../interfaces/resources/qualification";

export function editTalentQualificationAction(
  req: Partial<Qualification>,
  talentIri?: string,
  form?: string,
  validation?: string,
) {
  return {
    form,
    req,
    talentIri,
    type: EditTalentQualification.REQUEST,
    validation,
  };
}

export function editTalentQualificationSuccessAction(talentQualification: Qualification, form?: string) {
  return {
    form,
    talentQualification,
    type: EditTalentQualification.SUCCESS,
  };
}

export function createTalentQualificationAction(
  req: Partial<Qualification>,
  talentIri: string,
  form?: string,
  validation?: string,
) {
  return {
    form,
    req,
    talentIri,
    type: CreateTalentQualification.REQUEST,
    validation,
  };
}

export function createTalentQualificationSuccessAction(talentQualification: Qualification, form?: string) {
  return {
    form,
    talentQualification,
    type: CreateTalentQualification.SUCCESS,
  };
}

export function getTalentQualificationAction(qualificationIri: string) {
  return {
    qualificationIri,
    type: GetTalentQualification.REQUEST,
  };
}

export function getTalentQualificationSuccessAction(talentQualification: Qualification) {
  return {
    talentQualification,
    type: GetTalentQualification.SUCCESS,
  };
}

export function getTalentQualificationsAction(talentIri: string) {
  return {
    talentIri,
    type: GetTalentQualifications.REQUEST,
  };
}

export function getTalentQualificationsSuccessAction(qualifications: Qualification[]) {
  return {
    qualifications,
    type: GetTalentQualifications.SUCCESS,
  };
}

export function getChatGPTAction(qualificationIri: string) {
  return {
    qualificationIri,
    type: GetChatGPT.REQUEST,
  };
}

export function getChatGPTSuccessAction(talentQualification: Qualification) {
  return {
    talentQualification,
    type: GetChatGPT.SUCCESS,
  };
}

export function submitTranscriptAction(
  file: any,
  talent: string,
  currentUser: string,
  transcriptType: string,
  iriQualif: string,
) {
  return {
    file,
    talent,
    currentUser,
    transcriptType,
    type: PostTranscript.REQUEST,
    iriQualif,
  };
}

export function postTranscriptSuccessAction(talentQualification: Qualification) {
  return {
    talentQualification,
    type: PostTranscript.SUCCESS,
  };
}
