import { parse as csvParse } from "json2csv";
import { HandlerParserMap } from "./types";

const parsers: HandlerParserMap = {
  csv: {
    parse: csvParse as any,
    type: "text/csv;charset=utf-8",
  },
  json: {
    parse: JSON.stringify,
    type: "text/json;charset=utf-8",
  },
};

export default parsers;
