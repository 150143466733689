import * as React from "react";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import { WrappedFieldInputProps, WrappedFieldMetaProps } from "redux-form";
import { Form } from "semantic-ui-react";

import "./index.scss";

interface DatePickerFieldProps extends ReactDatePickerProps {
  className?: string;
  input: WrappedFieldInputProps;
  meta: WrappedFieldMetaProps;
  dateFormat?: string;
  error?: boolean;
  label?: string;
}

const DatePicker = ({ className, error, label, input: { name, onChange, value }, ...rest }: DatePickerFieldProps) => {
  return (
    <Form.Field error={error} name={name} className={className}>
      {label ? <label>{label}</label> : null}
      <ReactDatePicker {...rest} selected={value} onChange={onChange} />
    </Form.Field>
  );
};

export default DatePicker;
