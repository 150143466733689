import { AnyAction } from "redux";

import { GetResponsibilities } from "../../../actionTypes/responsibilities";

import { Responsibility } from "../../../interfaces/resources/responsibility";

export function getResponsibilitiesAction(page?: number) {
  return {
    page,
    type: GetResponsibilities.REQUEST,
  };
}

export function getResponsibilitiesSuccessAction(responsibilities: Responsibility[], action: AnyAction) {
  return {
    page: action.page,
    responsibilities,
    type: GetResponsibilities.SUCCESS,
  };
}
