import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import { OfferDetails } from "../../blocks/offerDetails";
import { OfferInfo } from "../../blocks/offerInfo";
import LoaderComp from "../../components/Utils/Loader";
import { State } from "../../interfaces/state";
import { generateIriFromId } from "../../services/api/helpers";
import { getOfferAction } from "../../services/api/offer/actions";
import styles from "./index.module.scss";

export const OfferPage: React.FC = () => {
  const params: any = useParams();
  const dispatch = useDispatch();
  const offer = useSelector((state: State) => state.offer);

  React.useEffect(() => {
    // get the application from id in params
    const iri = generateIriFromId("offers", params.id);
    if (params && offer?.["@id"] !== iri) {
      dispatch(getOfferAction(iri));
    }
  }, [params, dispatch, offer]);

  if (!offer) {
    return <LoaderComp />;
  }

  return (
    <div>
      <div className={styles.container}>
        <OfferInfo offer={offer} />
        <OfferDetails />
      </div>
    </div>
  );
};
