import { t } from "@lingui/macro";

interface Const {
  label: { [key: string]: string };
}

const transInterviewStepType: Const = {
  label: {
    case_study: t`interview_step_type.case_study`,
    fit: t`interview_step_type.fit`,
    founder: t`interview_step_type.founder`,
    hr: t`interview_step_type.hr`,
    team_fit: t`interview_step_type.team_fit`,
  },
};

export default transInterviewStepType;
