import * as React from "react";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Segment } from "semantic-ui-react";

import { GetTalent, SortTalent } from "../../../../actionTypes/talents";
import { TalentDetails } from "../../../../blocks/talentDetails";
import TalentOfferList from "../../../../blocks/talentDetails/PhoneCalls/PhoneCallForm/TalentOfferList";
import { TalentInfo } from "../../../../blocks/talentInfo";
import { ApiError } from "../../../../interfaces/api/error";
import { ApiRequest } from "../../../../interfaces/api/request";
import { ApiSuccess } from "../../../../interfaces/api/success";
import { Talent } from "../../../../interfaces/resources/talent";
import { TalentProfile } from "../../../../interfaces/resources/talentProfile";
import { State } from "../../../../interfaces/state";
import { getTalentAction } from "../../../../services/api/talent/actions";

import { getTalentProfileAction } from "../../../../services/api/talent/talentProfile/actions";
import { TalentLoadingPlaceholder } from "../../../Design/LoadingPlaceholder";
import { useApiSelector, usePrevious } from "../../../Utils/hook";

import "../../../../styles/profile.scss";
import "../../../../styles/sort.scss";
import { TalentFilterButtons } from "../../TalentFilterButtons";
import styles from "./index.module.scss";

export type TalentProfileMode = "new";
export interface Props {
  isLoading?: boolean;
  talent: Talent | null;
}

export const NewTalentProfile: FC<Props> = ({ isLoading = false, talent }: Props) => {
  const dispatch = useDispatch();

  const { apiErrors, apiPendingRequests, apiSuccess } = useApiSelector();
  const talentProfiles = useSelector((state: State) => state.talentProfiles);
  const [talentProfile, setTalentProfile] = React.useState<TalentProfile | undefined>(undefined);
  const [linkedInHeadline, setLinkedInHeadline] = React.useState<string | undefined>(undefined);
  const [linkedInUrl, setLinkedInUrl] = React.useState<string | undefined>(undefined);
  const [currentJob, setCurrentJob] = React.useState(talentProfile?.currentJob || "");
  const [currentCompany, setCurrentCompany] = React.useState(talentProfile?.currentCompany || "");
  const isSortingTalent = apiPendingRequests.some((item: ApiRequest) => SortTalent.REQUEST === item.type);
  const previousTalent = usePrevious(talent);

  const onUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLinkedInUrl(e.target.value);
  };
  const onCurrentJobChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentJob(e.target.value);
  };
  const onCurrentCompanyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentCompany(e.target.value);
  };

  React.useEffect(() => {
    const talentTprofile = talent && talent.talentProfile;
    const pendingRequest = apiPendingRequests.some((e) => talentTprofile?.["@id"] === e.talentProfile);
    if (
      talent
      && typeof talent.talentProfile === "string"
      && !!!talentProfiles[talent.talentProfile]
      && !pendingRequest
    ) {
      dispatch(getTalentProfileAction(talent.talentProfile));
    } else if (talentTprofile && !!talentProfiles[talentTprofile["@id"]]) {
      setTalentProfile(talentProfiles[talentTprofile["@id"]]);
    } else if (talent && typeof talent.talentProfile == "object") {
      setTalentProfile(talent.talentProfile);
    }
  }, [talent, talentProfiles, apiPendingRequests, dispatch]);

  React.useEffect(() => {
    const getTalent = (iri: string) => dispatch(getTalentAction(iri));
    if (
      (talent && previousTalent !== talent)
      || (talent && talent.linkedInProfile.url !== previousTalent?.linkedInProfile?.url)
    ) {
      setLinkedInUrl(talent.linkedInProfile.url);
      setLinkedInHeadline(talent.linkedInProfile.headline);
    }
    const filteredApiSuccess = apiSuccess.filter((suc: ApiSuccess) => suc.type === GetTalent.SUCCESS);
    const lastApiSuccess = filteredApiSuccess.length > 0 ? filteredApiSuccess[filteredApiSuccess.length - 1] : null;

    const filteredApiErrors = apiErrors.filter((err: ApiError) => err.type === GetTalent.FAILURE);
    const lastApiError = filteredApiErrors.length > 0 ? filteredApiErrors[filteredApiErrors.length - 1] : null;

    if (
      !!talent
      && previousTalent !== talent
      && !apiPendingRequests.some((item: ApiRequest) => GetTalent.REQUEST === item.type && item.iri === talent["@id"])
      && !(lastApiSuccess && lastApiSuccess.iri === talent["@id"])
      && !(lastApiError && lastApiError.iri === talent["@id"])
    ) {
      getTalent(talent["@id"]);
    }
  }, [apiErrors, apiPendingRequests, apiSuccess, talent, previousTalent, dispatch]);

  React.useEffect(() => {
    if (talentProfile) {
      setCurrentJob(talentProfile.currentJob || "");
      setCurrentCompany(talentProfile.currentCompany || "");
    }
  }, [talentProfile]);

  if (!talent || !talentProfile || isLoading) {
    return <TalentLoadingPlaceholder />;
  }
  return (
    <div className="full-width">
      <Segment basic loading={isSortingTalent} className="full-width reset profileContainer">
        <TalentInfo
          talent={talent}
          linkedInProfile={talent.linkedInProfile}
          mode="new"
          linkedInUrl={linkedInUrl}
          currentJob={currentJob}
          onCurrentJobChange={onCurrentJobChange}
          currentCompany={currentCompany}
          onCurrentCompanyChange={onCurrentCompanyChange}
          linkedInHeadline={linkedInHeadline}
          onUrlChange={onUrlChange}
          readOnly={true}
        />
        <div className={styles.ctaListWrapper}>
          <div className={styles.ctasContainer}>
            <TalentFilterButtons
              linkedInHeadline={linkedInHeadline}
              talent={talent}
              currentJob={currentJob}
              currentCompany={currentCompany}
              linkedInUrl={linkedInUrl}
            />
          </div>
          <div className={styles.offerListContainer}>
            <TalentOfferList talent={talent} readOnly={true} />
          </div>
        </div>
        <TalentDetails readOnly={true} mode="new" />
      </Segment>
    </div>
  );
};
