import { t } from "@lingui/macro";
import React, { FC, FormEvent, MouseEvent, useState } from "react";
import { Button, CheckboxProps, Container, Form, Modal, ModalProps, Radio } from "semantic-ui-react";
import { getUserImage } from "../../../../components/App/Image";
import RoundImage from "../../../../components/Design/RoundImage";
import { refusedReason, RefusedReason } from "../../../../interfaces/resources/application";
import { Talent as TalentProps } from "../../../../interfaces/resources/talent";
import transApplication from "../../../../translations/constants/application";
import "./index.scss";

interface Props extends Pick<ModalProps, "open" | "onClose" | "size"> {
  talent: TalentProps;
  onProceed(reason: RefusedReason): void;
}

export const RefuseOptionsModal: FC<Props> = (props: Props) => {
  const { open, size, talent, onClose, onProceed } = props;
  const [reason, setReason] = useState<RefusedReason | null>(null);

  function handleChange(_: FormEvent, { value }: CheckboxProps): void {
    if (value && typeof value === "string") {
      setReason(value as RefusedReason);
    }
  }

  function handleClose(e: MouseEvent<HTMLElement>): void {
    if (onClose) {
      setReason(null);
      onClose(e, {});
    }
  }

  function handleProceed(): void {
    if (reason !== null) {
      onProceed(reason);
    }
  }

  function renderHeader(): JSX.Element {
    return <Modal.Header>{t`RefuseApplication.title`}</Modal.Header>;
  }

  function renderFields(): JSX.Element[] {
    return Object.keys(refusedReason).map((e) => {
      const value = e as RefusedReason;
      return (
        <Form.Field key={e}>
          <Radio
            label={t({ id: transApplication.refusedReason[e as RefusedReason] })}
            name="form"
            value={refusedReason[value]}
            checked={reason === refusedReason[value]}
            onChange={handleChange}
          />
        </Form.Field>
      );
    });
  }

  function renderActions(): JSX.Element {
    return (
      <Modal.Actions>
        <Button negative onClick={handleClose} content={t`Constant.cancel`} icon="times" />
        <Button
          primary
          onClick={handleProceed}
          disabled={reason === null}
          content={t`RefuseApplication.confirm`}
          icon="checkmark"
        />
      </Modal.Actions>
    );
  }

  return (
    <Modal open={open} size={size} onClose={onClose} className="refuseApplication">
      {renderHeader()}
      <Modal.Content>
        <Container className="profile">
          <RoundImage size="40px" {...getUserImage(talent.image)} />
          <p>
            {talent.firstname} {talent.lastname}
          </p>
        </Container>
        <Form>{renderFields()}</Form>
      </Modal.Content>
      {renderActions()}
    </Modal>
  );
};
