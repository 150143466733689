import { t } from "@lingui/macro";
import * as React from "react";
import { Accordion, Checkbox, CheckboxProps, Icon, Item } from "semantic-ui-react";
import { offerStatuses } from "../../../interfaces/resources/offer";

interface Props {
  activeIndexes?: number[];
  handleClick?: (e: any, titleProps: any) => void;
  index?: number;
  multiple: boolean;
  onChange: (data: any) => void;
  selected: any;
}

export const OfferStatusFilter: React.FC<Props> = ({
  activeIndexes,
  handleClick,
  index,
  multiple,
  onChange,
  selected,
}: Props) => {
  const active = activeIndexes ? activeIndexes.some((item: number) => item === index) : false;
  return (
    <div>
      <Accordion.Title active={active} index={index} onClick={handleClick}>
        <h3>
          {t`OfferStatusFilter.title`}
          <Icon name={active ? "chevron up" : "chevron down"} />
        </h3>
      </Accordion.Title>
      <Accordion.Content active={active}>
        <Item.Group>
          <Item>
            <Checkbox
              checked={Array.isArray(selected) ? false : selected === offerStatuses.activated}
              label={t`offer.status.activated`}
              multiple={multiple}
              onClick={(_: React.MouseEvent<HTMLInputElement, MouseEvent>, data: CheckboxProps): void => onChange(data)}
              value={offerStatuses.activated}
            />
          </Item>
          <Item>
            <Checkbox
              checked={Array.isArray(selected) ? false : selected === offerStatuses.standbyElinoi}
              label={t`offer.status.standbyElinoi`}
              multiple={multiple}
              onClick={(_: React.MouseEvent<HTMLInputElement, MouseEvent>, data: CheckboxProps): void => onChange(data)}
              value={offerStatuses.standbyElinoi}
            />
          </Item>
          <Item>
            <Checkbox
              checked={Array.isArray(selected) ? false : selected === offerStatuses.desactivated}
              label={t`offer.status.desactivated`}
              multiple={multiple}
              onClick={(_: React.MouseEvent<HTMLInputElement, MouseEvent>, data: CheckboxProps): void => onChange(data)}
              value={offerStatuses.desactivated}
            />
          </Item>
          <Item>
            <Checkbox
              checked={Array.isArray(selected)}
              label={t`OfferStatusFilter.all`}
              multiple={multiple}
              onClick={(_: React.MouseEvent<HTMLInputElement, MouseEvent>, data: CheckboxProps): void => onChange(data)}
            />
          </Item>
        </Item.Group>
      </Accordion.Content>
    </div>
  );
};
