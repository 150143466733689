import { t } from "@lingui/macro";
import lodash from "lodash";
import React, { useState } from "react";
import { Search, SearchProps, SearchResultData, SearchResultProps } from "semantic-ui-react";
import { FilterTalentsByName } from "../../../actionTypes/talents";
import { ApiRequest } from "../../../interfaces/api/request";
import { Talent } from "../../../interfaces/resources/talent";
import { extractPageFromIri } from "../../../services/api/helpers";
import { getUserImage } from "../../App/Image";

import "./index.scss";

interface Props {
  apiPendingRequests: ApiRequest[];
  compact: boolean;
  filterTalentsByName: (text: string) => void;
  onSelect?: (data: Talent) => void;
  applyFilter: (filterContext: any) => void;
  talentNames: Talent[];
}

export const TalentNameFilter: React.FC<Props> = ({
  apiPendingRequests,
  compact,
  filterTalentsByName,
  onSelect,
  applyFilter,
  talentNames,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState("");

  const handleResultSelect = (_: unknown, { result }: SearchResultData): void => {
    if (onSelect) {
      onSelect(result);
    }
  };

  const handleSearchChange = (_: unknown, { value: searchValue }: SearchProps): void => {
    setIsLoading(true);
    setValue(searchValue || "");

    setTimeout(() => {
      let text = (searchValue && searchValue.trim()) || "";
      if (text.charAt(0) === "+") {
        text = text.replace(/\.|\s*/g, "");
      } else if (text.charAt(0) === "0") {
        text = text.replace("0", "+33").replace(/\.|\s*/g, "");
      }
      if (!searchValue || searchValue.length < 1) {
        setIsLoading(false);
        setValue("");
      }

      if (!compact) {
        applyFilter({
          multiple: false,
          name: "nameorphone",
          value: text || null,
        });
      } else if (searchValue && searchValue.length > 1) {
        filterTalentsByName(text);
      }

      setIsLoading(false);
    }, 300);
  };

  return (
    <>
      <Search
        className="talentNameFilter"
        value={value}
        loading={isLoading || apiPendingRequests.some((item: ApiRequest) => FilterTalentsByName.REQUEST === item.type)}
        placeholder={t`TalentNameFilter.placeholder`}
        results={
          compact
            ? talentNames.map((talent: Talent) => ({
                image: getUserImage(talent.image),
                key: talent["@id"],
                title: `${talent.firstname} ${talent.lastname}`,
                url: extractPageFromIri("talents", talent["@id"]),
              }))
            : undefined
        }
        onSearchChange={lodash.debounce(handleSearchChange, 500, {
          leading: true,
        })}
        onResultSelect={handleResultSelect}
        showNoResults={compact}
        resultRenderer={(props: SearchResultProps): JSX.Element => (
          <a href={props.url}>
            <div className="image">
              <img {...(props.image as any)} alt={props.title} />
            </div>
            <div className="content">
              <div className="title">{props.title}</div>
            </div>
          </a>
        )}
      />
    </>
  );
};
