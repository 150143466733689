import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";

import { GetInterviewSteps } from "../../../../actionTypes/interviewStep";
import { InterviewStep } from "../../../../interfaces/resources/interviewStep";

import { apiFailureAction } from "../../actions";
import { interviewStepsSuccessAction } from "./actions";

import * as apiClient from "./api";

export function* getInterviewSteps(action: AnyAction) {
  try {
    const response: AxiosResponse<{ "hydra:member": InterviewStep[] }> = yield call(
      apiClient.getOfferInterviewSteps,
      action.offerIri,
    );
    yield put(interviewStepsSuccessAction(response.data["hydra:member"]));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export default function* interviewStepsSaga() {
  yield takeLatest(GetInterviewSteps.REQUEST, getInterviewSteps);
}
