import { t } from "@lingui/macro";
import { Field, Formik } from "formik";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Icon, Message } from "semantic-ui-react";

import Yup from "../../../../components/App/Yup";
import Input from "../../../../components/Form/Formik/Input";
import RichTextEditor from "../../../../components/Form/Formik/RichTextEditor";
import { isReinitializeable } from "../../../../components/Utils/helpers";
import { useApiSelector, usePrevious } from "../../../../components/Utils/hook";
import { ApiError } from "../../../../interfaces/api/error";
import { ApiRequest } from "../../../../interfaces/api/request";
import { ApiSuccess } from "../../../../interfaces/api/success";
import { Violation } from "../../../../interfaces/api/violation";
import { PhoneCall } from "../../../../interfaces/resources/phoneCall";
import { Talent } from "../../../../interfaces/resources/talent";
import { TalentProfile } from "../../../../interfaces/resources/talentProfile";

import { editPhoneCallAction } from "../../../../services/api/talent/phoneCalls/actions";

import TalentOfferList from "../PhoneCallForm/TalentOfferList";

import "./index.scss";

const validationSchema = Yup.object().shape({
  applicationsToSubmit: Yup.array().of(Yup.object()),
  notes: Yup.string(),
  title: Yup.string().required(),
  type: Yup.string().oneOf(["filter_spontaneous_application", "filter_talent_card", "filter_reactivation"]).required(),
});
interface Props {
  phoneCall: Partial<PhoneCall>;
  toggleEdit: (success: boolean) => void;
}

const EDIT_PHONE_CALL_FORM = "phoneCall";

const EditPhoneCall: React.FC<Props> = ({ toggleEdit, phoneCall }: Props) => {
  const dispatch = useDispatch();

  const [error, setError] = React.useState<boolean | string>(false);
  const [violations, setViolations] = React.useState<Violation[]>([]);
  const [success, setSuccess] = React.useState<boolean>(false);

  const { apiErrors, apiPendingRequests, apiSuccess } = useApiSelector();
  const talent: Talent = useSelector((state: any) => state.talent);
  const talentProfile: TalentProfile | null = useSelector((state: any) => state.talentProfile);

  const submitPhoneCall = (phnCall: Partial<PhoneCall>, req?: string) => dispatch(editPhoneCallAction(phnCall, req));

  const prevApiSuccess = usePrevious(apiSuccess);
  const prevApiErrors = usePrevious(apiErrors);

  React.useEffect(() => {
    if (
      prevApiSuccess
      && !prevApiSuccess.some((e: ApiSuccess) => EDIT_PHONE_CALL_FORM === e.form)
      && apiSuccess.some((e: ApiSuccess) => EDIT_PHONE_CALL_FORM === e.form)
    ) {
      setError(false);
      setViolations([]);
      setSuccess(true);
      toggleEdit(true);
    }
  }, [apiErrors, apiSuccess, prevApiSuccess, success, setError, setViolations, setSuccess, toggleEdit]);

  React.useEffect(() => {
    if (
      prevApiErrors
      && !prevApiErrors.some((e: ApiError) => EDIT_PHONE_CALL_FORM === e.form)
      && apiErrors.some((e: ApiError) => EDIT_PHONE_CALL_FORM === e.form)
    ) {
      const apiError = prevApiErrors.find((e: ApiError) => EDIT_PHONE_CALL_FORM === e.form);
      if (apiError) {
        setViolations(apiError.payload.violations || []);
        setSuccess(false);
      }
    }
  }, [apiErrors, prevApiErrors, setViolations, setSuccess]);

  const onSubmit = (values: Partial<PhoneCall>) => {
    submitPhoneCall(
      {
        ...values,
        applications: values.applicationsToSubmit,
        talent,
      },
      EDIT_PHONE_CALL_FORM,
    );
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ applicationsToSubmit: phoneCall.applications || [], ...phoneCall }}
      enableReinitialize={isReinitializeable(apiErrors, apiPendingRequests, apiSuccess)}
      validationSchema={validationSchema}
    >
      {({ handleSubmit }) => (
        <Form
          className="phoneCallFormContainer"
          loading={
            apiPendingRequests.length > 0
            && apiPendingRequests.some((item: ApiRequest) => EDIT_PHONE_CALL_FORM === item.form)
          }
          success={success}
          error={!!error || violations.length > 0}
        >
          <Message
            success
            header={t`PhoneCallForm.error.header`}
            content={t`PhoneCallForm.success.content`}
            icon="check circle"
          />
          <Message
            error
            header={t`PhoneCallForm.error.header`}
            content={
              <div>
                {t`PhoneCallForm.error.content`}: {error}
              </div>
            }
            icon="times circle"
          />
          <div className="editPhoneCallIconContainer">
            <Icon name="check circle" onClick={handleSubmit} />
          </div>
          <label>{t`phoneCall.title`}</label>
          <Field name="title" component={Input} placeholder={t`phoneCall.title`} required />
          <div className="formGroup formTextAreaGroup">
            <Field
              name="notes"
              component={RichTextEditor}
              label={t`phoneCall.notes`}
              placeholder={t`phoneCall.notes`}
            />
          </div>
          {!phoneCall.sent && talentProfile ? (
            <div>
              <h5>{t`qualification.labels.exigibleOffers`}</h5>
              <Field name="applicationsToSubmit" component={TalentOfferList} talent={talent} />
            </div>
          ) : null}
        </Form>
      )}
    </Formik>
  );
};

export default EditPhoneCall;
