import { call, put, takeLatest } from "redux-saga/effects";

import { CreateLinkedinResume, EditLinkedInProfile } from "../../../../actionTypes/linkedInProfile";

import { apiFailureAction } from "../../actions";
import { createLinkedinResumeSuccessAction, editLinkedInProfileSuccessAction } from "./actions";
import * as apiClient from "./api";

export function* editLinkedInProfile(action: any): any {
  try {
    const response = yield call(apiClient.editLinkedInProfile, action.linkedInProfile);
    const linkedInProfile = response.data;
    yield put(editLinkedInProfileSuccessAction(linkedInProfile));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* createLinkedinResume(action: any): any {
  try {
    const response = yield call(apiClient.createLinkedinResume, action.iri, action.file);
    const talent = response.data;
    yield put(createLinkedinResumeSuccessAction(talent));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export default function* linkedInProfileSaga() {
  yield takeLatest(EditLinkedInProfile.REQUEST, editLinkedInProfile);
  yield takeLatest(CreateLinkedinResume.REQUEST, createLinkedinResume);
}
