import { t } from "@lingui/macro";
import { format, parseISO, formatDistance } from "date-fns";
import frLocale from "date-fns/locale/fr";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Label } from "../.";
import { GetCoreUsers } from "../../../actionTypes/coreUsers";
import { Tooltip } from "../../../components/Tooltip";
import { useApiSelector, useGetResourceHook } from "../../../components/Utils/hook";
import { searchStatuses, searchStatusReasons, Talent } from "../../../interfaces/resources/talent";
import { State } from "../../../interfaces/state";
import { getCoreUsersAction } from "../../../services/api/coreUser/actions";
import { generateIriFromId } from "../../../services/api/helpers";
import { transTalent } from "../../../translations/constants/talent";
import styles from "./index.module.scss";

interface Props {
  talent: Talent;
  className?: string;
}

const TooltipContent: React.FC<Props> = (props: Props) => {
  const { talent } = props;
  const { apiErrors, apiPendingRequests, apiSuccess } = useApiSelector();
  const coreUsers = useSelector((state: State) => state.coreUsers);
  const dispatch = useDispatch();
  const statusEvent = !!talent?.lastTalentSearchStatusEvent
    ? talent.lastTalentSearchStatusEvent
    : talent?.talentSearchStatusEvents?.length
    ? talent.talentSearchStatusEvents[talent.talentSearchStatusEvents.length - 1]
    : undefined;
  const searchStatusLabelDate: string = !!statusEvent ? format(parseISO(statusEvent.createdAt), "dd/MM/yyyy") : "";
  const searchStatusData = !!statusEvent?.data ? JSON.parse(statusEvent.data) : null;
  const doerName =
    searchStatusData?.doer === "auto"
      ? "Auto"
      : searchStatusData?.doer === "talent"
      ? "Talent"
      : searchStatusData?.doer === "coreUser"
      ? coreUsers.filter((cu) => cu["@id"] === generateIriFromId("core_users", searchStatusData.doerId))[0]?.firstname
      : null;

  useGetResourceHook(
    apiErrors,
    apiPendingRequests,
    apiSuccess,
    GetCoreUsers,
    coreUsers,
    () => dispatch(getCoreUsersAction()),
    [],
  );

  if (!!!statusEvent) {
    return null;
  }
  return (
    <div className={styles.tooltip}>
      <div>{searchStatusLabelDate}</div>
      {doerName || statusEvent.reason ? (
        <div>
          {doerName ? <div>Par : {doerName}</div> : null}
          {statusEvent.reason ? (
            <div>Raison : {t({ id: transTalent.searchStatusReason[statusEvent.reason] })}</div>
          ) : null}
          {statusEvent.reason === searchStatusReasons.job_found ? (
            <div>
              {searchStatusData.jobFoundCompany ? (
                <>Entreprise : {searchStatusData.jobFoundCompany}</>
              ) : (
                "Entreprise : Non renseignée"
              )}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export const SearchStatusLabel: React.FC<Props> = (props: Props) => {
  const { talent, className } = props;
  const statusEvent = !!talent?.lastTalentSearchStatusEvent
    ? talent.lastTalentSearchStatusEvent
    : talent?.talentSearchStatusEvents?.length
    ? talent.talentSearchStatusEvents[talent.talentSearchStatusEvents.length - 1]
    : undefined;

  const type =
    talent.searchStatus === searchStatuses.searching
      ? "green"
      : talent.searchStatus === searchStatuses.not_searching
      ? "red"
      : talent.searchStatus === searchStatuses.passive_searching
      ? "yellow"
      : undefined;

  return (
    <Tooltip content={<TooltipContent talent={talent} />}>
      <Label
        type={type}
        content={`${t({ id: transTalent.searchStatus[talent.searchStatus] })} ${
          statusEvent && statusEvent.status === searchStatuses.not_searching
            ? `depuis ${formatDistance(new Date(statusEvent.createdAt), new Date(), {
                locale: frLocale,
              })}`
            : ""
        }`}
        className={!!className ? className : undefined}
      />
    </Tooltip>
  );
};
