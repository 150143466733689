import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCorrectTalent, getCorrectTalentProfile } from "../../../components/Utils/helpers";
import { TalentMode } from "../../../interfaces/resources/talent";
import { State } from "../../../interfaces/state";
import { getTalentQualificationsAction } from "../../../services/api/talent/talentQualification/actions";
import { LinkedInResume } from "../LinkedInResume";
import { QualificationItem } from "../QualificationItem";
import { ActiveApplications } from "./ActiveApplications";
import { CandidateProfileCard } from "./CandidateProfileCard";
import { ContextItem } from "./ContextItem";
import styles from "./index.module.scss";
import { TalentHistory } from "./TalentHistory";

interface Props {
  talentIri?: string;
  readOnly?: boolean;
  mode?: TalentMode;
}

const Recap: React.FC<Props> = ({ readOnly = false, mode, talentIri }: Props) => {
  const talentState = useSelector((state: State) => state.talent);
  const talentsState = useSelector((state: State) => state.talents);
  const talentProfileState = useSelector((state: State) => state.talentProfile);
  const talentProfilesState = useSelector((state: State) => state.talentProfiles);
  const talent = !!talentIri ? getCorrectTalent(talentIri, talentState, talentsState) : talentState;
  const talentProfile = getCorrectTalentProfile(talent, talentProfileState, talentProfilesState);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (talent) {
      dispatch(getTalentQualificationsAction(talent["@id"]));
    }
  }, [talent, dispatch]);

  return (
    <div className={styles.recapContainer}>
      <div className={styles.profileQualifContainer}>
        <div className={styles.qualifLinkedInContainer}>
          {talentProfile && talentProfile.lastQualification ? (
            <div>
              <QualificationItem />
            </div>
          ) : (
            <>
              <div>
                <ContextItem />
              </div>
              <div>
                <LinkedInResume />
              </div>
            </>
          )}
        </div>
        <div className={styles.profilActiveAppContainer}>
          <div>
            <CandidateProfileCard readOnly={readOnly} />
          </div>
          {talent ? (
            <div>
              <ActiveApplications talent={talent} />
            </div>
          ) : null}
        </div>
      </div>
      <div className={styles.histoSourceContainer}>
        {mode === "hunt" ? null : (
          <div>
            <TalentHistory talent={talent} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Recap;
