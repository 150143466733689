import { t } from "@lingui/macro";
import * as React from "react";
import { Accordion, Icon, Item, Checkbox, CheckboxProps } from "semantic-ui-react";

interface Props {
  selected: any;
  onChange: (data: any) => void;
  activeIndexes?: number[];
  handleClick?: (e: any, titleProps: any) => void;
  index?: number;
}

const filters: { [key: string]: string } = {
  true: t`GoldenTalentFilter.true`,
  false: t`GoldenTalentFilter.false`,
};

export const GoldenTalentFilter = (props: Props): JSX.Element => {
  const index = props.index;
  const active = props.activeIndexes ? props.activeIndexes.some((item: number) => item === index) : false;

  return (
    <div>
      <Accordion.Title active={active} index={index} onClick={props.handleClick}>
        <h3>
          {t`GoldenTalentFilter.title`}
          <Icon name={active ? "chevron up" : "chevron down"} />
        </h3>
      </Accordion.Title>
      <Accordion.Content active={active}>
        <Item.Group>
          {Object.keys(filters).map((item: string, i: number) => (
            <Item key={i}>
              <Checkbox
                label={t({ id: filters[item] })}
                value={item}
                checked={
                  Array.isArray(props.selected)
                    ? props.selected.some((e: string) => e === item)
                    : props.selected === item
                }
                onClick={(_: unknown, data: CheckboxProps): void =>
                  props.onChange({
                    value: data.checked ? item : null,
                  })
                }
              />
            </Item>
          ))}
        </Item.Group>
      </Accordion.Content>
    </div>
  );
};
