import { call, put, takeEvery } from "redux-saga/effects";

import { CreateCompanyImage } from "../../../../actionTypes/companyImages";
import { apiFailureAction } from "../../actions";

import * as apiClient from "./api";

export function* createCompanyImage(action: any): any {
  try {
    const response = yield call(apiClient.createCompanyImage, action.companyImage);
    yield put({
      companyImage: response.data,
      form: action.form,
      type: CreateCompanyImage.SUCCESS,
    });
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export default function* offerSaga() {
  yield takeEvery(CreateCompanyImage.REQUEST, createCompanyImage);
}
