import { t } from "@lingui/macro";
import React, { FC, PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { Modal } from "semantic-ui-react";
import { Application } from "../../../interfaces/resources/application";
import { extractPageFromIri } from "../../../services/api/helpers";

import styles from "./index.module.scss";

interface Props {
  application: Application;
  className?: string;
  setModalOpen: any;
  modalOpen: boolean;
}

export const UpdateModal: FC<PropsWithChildren<Props>> = ({
  setModalOpen,
  modalOpen,
  application,
}: PropsWithChildren<Props>) => {
  const handleClose = () => setModalOpen(false);
  return (
    <Modal size="tiny" open={modalOpen} onClose={handleClose} className={styles.talentUpdateModal}>
      <h1 className={styles.formTitle}>{t`updateModal.title`}</h1>
      <div className={styles.talentUpdateBody}>{t`updateModal.body`}</div>
      <Link to={extractPageFromIri("talents", application.talent["@id"])}>
        {application.talent.firstname} {application.talent.lastname}
      </Link>
    </Modal>
  );
};
