import { AnyAction } from "redux";
import {
  CreateOffer,
  DeleteOffer,
  EditOffer,
  FilterByOfferName,
  GetOffer,
  GetOffers,
  SendShortlist,
} from "../../../actionTypes/offers";

import { GetTalentOffers } from "../../../actionTypes/talents";
import { FilterContext } from "../../../interfaces/api/filter";
import { HydraCollection } from "../../../interfaces/api/hydra-collection";
import { InterviewStep } from "../../../interfaces/resources/interviewStep";
import {
  Offer,
  OfferAdviceForm,
  OfferInternalView,
  OfferMainForm,
  OfferTalentView,
} from "../../../interfaces/resources/offer";

export function createOfferAction(offer: OfferMainForm, form?: string, redirectOnSuccess?: boolean | string) {
  return {
    form,
    offer,
    redirectOnSuccess,
    type: CreateOffer.REQUEST,
  };
}

export function editOfferAction(
  offer: OfferMainForm | OfferAdviceForm | Partial<Offer>,
  form?: string,
  validation?: string,
  redirectOnSuccess?: boolean | string,
) {
  return {
    form,
    offer,
    redirectOnSuccess,
    type: EditOffer.REQUEST,
    validation,
  };
}

export function editOfferSuccessAction(
  action: AnyAction,
  offer: Offer,
  interviewSteps: InterviewStep[],
  offerInternalView: OfferInternalView,
  offerTalentView: OfferTalentView,
) {
  return {
    form: action.form,
    offer: {
      ...offer,
      interviewSteps,
      offerInternalView,
      offerTalentView,
    },
    type: EditOffer.SUCCESS,
  };
}

export function filterByOfferNameAction(filterContext: FilterContext) {
  return {
    filterContext,
    type: FilterByOfferName.REQUEST,
  };
}

export function filterByOfferNameSuccessAction(offerNames: Offer[]) {
  return {
    offerNames,
    type: FilterByOfferName.SUCCESS,
  };
}

export function getOfferAction(iri: string) {
  return {
    iri,
    type: GetOffer.REQUEST,
  };
}

export function getOfferSuccessAction(offer: Offer) {
  return {
    offer,
    type: GetOffer.SUCCESS,
  };
}

export function getOffersAction(filterContext: FilterContext) {
  return {
    filterContext,
    type: GetOffers.REQUEST,
  };
}

export function getTalentOffersAction(talentIri: string) {
  return {
    talentIri,
    type: GetTalentOffers.REQUEST,
  };
}

export function getOffersSuccessAction(data: HydraCollection<Offer>) {
  return {
    offers: data["hydra:member"],
    view: data["hydra:view"],
    type: GetOffers.SUCCESS,
  };
}

export function getTalentOffersSuccessAction(data: HydraCollection<Offer>) {
  return {
    offers: data["hydra:member"],
    type: GetTalentOffers.SUCCESS,
  };
}

export function deleteOfferAction(iri: string) {
  return {
    iri,
    type: DeleteOffer.REQUEST,
  };
}

export function deleteOfferSuccessAction(data: any) {
  return {
    data,
    type: DeleteOffer.SUCCESS,
  };
}

export function sendShortlistAction(values: any, form?: string) {
  return {
    form,
    type: SendShortlist.REQUEST,
    values,
  };
}

export function sendShortlistSuccessAction(action: AnyAction) {
  return {
    form: action.form,
    type: SendShortlist.SUCCESS,
  };
}
