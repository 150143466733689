import { AnyAction } from "redux";

import { FilterClause, FilterContext } from "../../interfaces/api/filter";
import { Hunt } from "../../interfaces/resources/hunt";

export const JWT_TOKEN_KEY = "jwt";
export const JWT_TOKEN_KEY_TEMP = "jwt_temp";
export const REFRESH_TOKEN_KEY = "refresh";

export function extractIdFromIri(iri: string): number {
  return Number(iri.split("/").slice(-1)[0]);
}

export function generateIriFromId(resource: string, id: number): string {
  return String("/api/v2/" + resource + "/" + id);
}

export function extractPageFromIri(resource: string, iri: string): string {
  return String(`/${resource}/${extractIdFromIri(iri)}`);
}

export function extractNextPageNumberFromIri(iri: string) {
  return Number(iri.split("=").slice(-1)[0]);
}

export function extractPageNumberFromIri(iri: string): number {
  return Number(iri.split("=").slice(-1)[0]);
}

export function identifyAction(action: AnyAction) {
  return action.type.split("_").slice(0, -1).join("_");
}

export function getSuccessType(action: AnyAction) {
  return `${identifyAction(action)}_SUCCESS`;
}

export function getFailType(action: AnyAction) {
  return `${identifyAction(action)}_FAILURE`;
}

export function getRequestType(action: AnyAction) {
  return `${identifyAction(action)}_REQUEST`;
}

export function setTokens(tokens: { refresh_token: string; token: string }, type = "login") {
  if (type === "login") {
    localStorage.setItem(JWT_TOKEN_KEY, tokens.token);
    localStorage.setItem(REFRESH_TOKEN_KEY, tokens.refresh_token);
  } else {
    localStorage.setItem(JWT_TOKEN_KEY_TEMP, localStorage.getItem(JWT_TOKEN_KEY) || "");
    localStorage.setItem(JWT_TOKEN_KEY, tokens.token);
  }
}

export function removeTokens(type = "login") {
  if (type === "login") {
    localStorage.removeItem(JWT_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  } else {
    localStorage.setItem(JWT_TOKEN_KEY, localStorage.getItem(JWT_TOKEN_KEY_TEMP) || "");
    localStorage.removeItem(JWT_TOKEN_KEY_TEMP);
  }
}

export function getAuthorizationHeader() {
  const token = localStorage.getItem(JWT_TOKEN_KEY);
  if (token) {
    return { Authorization: `Bearer ${token}` };
  }
  return {};
}

export function generateFilterQuery(filterContext: FilterContext) {
  let query = "?";

  for (const clauseName in filterContext) {
    if (filterContext.hasOwnProperty(clauseName)) {
      const clause: FilterClause<any> | undefined = filterContext[clauseName];
      if (clause) {
        if (clause.multiple && Array.isArray(clause.value)) {
          // eslint-disable-next-line
          clause.value.forEach((item: any) => (query += `${clause.name}[]=${item}&`));
        } else {
          if (clause.hasOwnProperty("param")) {
            if (Array.isArray(clause.param)) {
              // eslint-disable-next-line
              clause.param.forEach((elem: any) => {
                query += `${clause.name}[${elem}]=${clause.value[elem]}&`;
              });
            } else {
              query += `${clause.name}[${clause.param}]=${clause.value}&`;
            }
          } else {
            query += `${clause.name}=${clause.value}&`;
          }
        }
      }
    }
  }
  return query.slice(0, -1);
}

export function generateAlgoliaQuery(filterContext: FilterContext) {
  let query = "";
  for (const clauseName in filterContext) {
    if (filterContext.hasOwnProperty(clauseName)) {
      const clause: FilterClause<any> | undefined = filterContext[clauseName];
      if (clause) {
        query += `${clause.name}: ${clause.value}, `;
      }
    }
  }
  return { facetFilters: [query] };
}

export function updateFilterContext(filterContext: FilterContext, filterClause: FilterClause<any>) {
  let newValue: any;
  filterContext = filterContext.filter((clause: FilterClause<any>) => clause.name !== "page");
  if (filterContext.some((clause: FilterClause<any>) => clause.name === filterClause.name)) {
    const newFilterContext = filterContext.reduce((result: FilterContext, clause: FilterClause<any>) => {
      if (clause.name === filterClause.name) {
        if (!clause.multiple || clause.multiple !== filterClause.multiple) {
          // Means we try to filter on the same field but on a new configuration or on a unique search field
          // Choosing the latest filter
          if (filterClause.value) {
            result.push(filterClause);
          }
        } else if (clause.multiple) {
          if (null === filterClause.value) {
            return result;
          }
          // Means we are adding a new field to an existing clause
          if (Array.isArray(clause.value)) {
            if (Array.isArray(filterClause.value)) {
              // Values that are in both arrays and should not be touched
              const arrayIntersection: any[] = clause.value.filter((elem: any) => {
                return -1 !== filterClause.value.indexOf(elem);
              });
              const valuesToAdd: any[] = filterClause.value.filter((elem: any) => {
                return -1 === clause.value.indexOf(elem);
              });
              newValue = [...arrayIntersection, ...valuesToAdd];
              result.push({
                ...clause,
                value: newValue,
              });
            } else {
              if (clause.value.includes(filterClause.value)) {
                newValue = clause.value.filter((value: any) => value !== filterClause.value);
                if (newValue.length === 1) {
                  newValue = newValue[0];
                  result.push({
                    ...clause,
                    value: newValue,
                  });
                } else if (newValue.length === 0) {
                  newValue = undefined;
                } else {
                  result.push({
                    ...clause,
                    value: newValue,
                  });
                }
              } else {
                newValue = clause.value.slice();
                newValue.push(filterClause.value);
                result.push({
                  ...clause,
                  value: newValue,
                });
              }
            }
          } else {
            if (Array.isArray(filterClause.value)) {
              if (filterClause.value.includes(clause.value)) {
                result.push({
                  ...clause,
                  value: filterClause.value.filter((elem: any) => elem !== clause.value),
                });
              } else {
                newValue = filterClause.value.slice();
                newValue.push(clause.value);
                result.push({
                  ...clause,
                  value: newValue,
                });
              }
            } else {
              if (clause.value !== filterClause.value) {
                result.push({
                  ...clause,
                  value: [clause.value, filterClause.value],
                });
              }
              // No else case
              // Means we are unsetting the filter for this clause
            }
          }
        } else {
          result.push(clause);
        }
      } else {
        result.push(clause);
      }
      return result;
    }, []);
    return newFilterContext;
  } else {
    if (filterClause.value === null) {
      return filterContext;
    }
    return [...filterContext, filterClause];
  }
}

export function formatHunt(object: any): Hunt {
  return {
    "@id": object["@id"],
    discardedTalents: object.discardedTalents,
    name: object.name,
    owner: object.owner,
    value: JSON.parse(object.filters),
  };
}

export function getStringValue(value: any, field = "@id"): string | undefined {
  return value ? ("string" === typeof value ? value : value[field]) : undefined;
}

export function getCollectionStringValue<T>(
  collection: T[] | undefined,
  field = "@id",
): (string | undefined)[] | undefined {
  return typeof collection === "undefined"
    ? undefined
    : (collection || []).map((elem: T) => getStringValue(elem, field));
}
