import { call, put, takeLatest } from "redux-saga/effects";

import { GetContracts } from "../../../../actionTypes/contract";
import { apiFailureAction } from "../../actions";
import { getSuccessType } from "../../helpers";
import * as apiClient from "./api";

export function* getContracts(action: any): any {
  try {
    const response = yield call(apiClient.getContracts);
    yield put({
      contracts: response.data["hydra:member"],
      type: getSuccessType(action),
    });
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export default function* contractSaga() {
  yield takeLatest(GetContracts.REQUEST, getContracts);
}
