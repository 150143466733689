import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";

import { GetResponsibilityType, GetResponsibilityTypes } from "../../../actionTypes/responsibilityTypes";
import { ResponsibilityType } from "../../../interfaces/resources/responsibility";
import { apiFailureAction } from "../actions";
import { extractNextPageNumberFromIri } from "../helpers";
import {
  getResponsibilityTypesAction,
  getResponsibilityTypesSuccessAction,
  getResponsibilityTypeSuccessAction,
} from "./actions";
import * as apiClient from "./api";

export function* getResponsibilityType(action: AnyAction) {
  try {
    const response: AxiosResponse<ResponsibilityType> = yield call(apiClient.getResponsibilityType, action.iri);
    yield put(getResponsibilityTypeSuccessAction(response.data));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* getResponsibilityTypes(action: AnyAction) {
  try {
    const response: AxiosResponse<{
      "hydra:member": ResponsibilityType[];
      "hydra:view"?: {
        "hydra:next"?: string;
      };
      "hydra:totalItems": number;
    }> = yield call(apiClient.getResponsibilityTypes, action.page);
    yield put(getResponsibilityTypesSuccessAction(response.data["hydra:member"], action));
    if (response.data["hydra:view"] && response.data["hydra:view"]["hydra:next"]) {
      yield put(getResponsibilityTypesAction(extractNextPageNumberFromIri(response.data["hydra:view"]["hydra:next"])));
    }
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export default function* getResponsibilityTypesSaga() {
  yield takeLatest(GetResponsibilityType.REQUEST, getResponsibilityType);
  yield takeLatest(GetResponsibilityTypes.REQUEST, getResponsibilityTypes);
}
