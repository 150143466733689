import { ActionType } from "../interfaces/api/actionType";

export const CreateInterviewStep: ActionType = {
  FAILURE: "CREATE_INTERVIEW_STEP_FAILURE",
  REQUEST: "CREATE_INTERVIEW_STEP_REQUEST",
  SUCCESS: "CREATE_INTERVIEW_STEP_SUCCESS",
};

export const DeleteInterviewStep: ActionType = {
  FAILURE: "DELETE_INTERVIEW_STEP_FAILURE",
  REQUEST: "DELETE_INTERVIEW_STEP_REQUEST",
  SUCCESS: "DELETE_INTERVIEW_STEP_SUCCESS",
};

export const EditInterviewStep: ActionType = {
  FAILURE: "EDIT_INTERVIEW_STEP_FAILURE",
  REQUEST: "EDIT_INTERVIEW_STEP_REQUEST",
  SUCCESS: "EDIT_INTERVIEW_STEP_SUCCESS",
};

export const GetInterviewStep: ActionType = {
  FAILURE: "GET_INTERVIEW_STEP_FAILURE",
  REQUEST: "GET_INTERVIEW_STEP_REQUEST",
  SUCCESS: "GET_INTERVIEW_STEP_SUCCESS",
};

export const GetInterviewSteps: ActionType = {
  FAILURE: "GET_INTERVIEWS_STEP_FAILURE",
  REQUEST: "GET_INTERVIEWS_STEP_REQUEST",
  SUCCESS: "GET_INTERVIEWS_STEP_SUCCESS",
};
