import { t } from "@lingui/macro";
import cx from "classnames";
import { format, parseISO } from "date-fns";
import React, { FC, useEffect, useState } from "react";
import { BsHeartFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Popup, Step } from "semantic-ui-react";

import { ModalWrapper } from "../../../../components/ModalWrapper";
import { TalentCardModal } from "../../../../components/TalentCardModal";
import { usePrevious } from "../../../../components/Utils/hook";
import {
  Application,
  applicationLateStage,
  applicationStatuses,
  applicationStatusesRefused,
} from "../../../../interfaces/resources/application";
import { offerStatusesColors } from "../../../../interfaces/resources/offer";
import { StatusEvent } from "../../../../interfaces/resources/statusEvent";
import { editApplicationAction } from "../../../../services/api/applications/actions";
import { extractPageFromIri, generateIriFromId } from "../../../../services/api/helpers";
import globalStyles from "../../../../styles/global.module.scss";
import transApplication from "../../../../translations/constants/application";
import { CompanyImg } from "../../../companyImg";
import styles from "./index.module.scss";

interface RenderStepProps {
  application: Application;
  event: StatusEvent;
  icon?: JSX.Element;
  className?: string;
}

const RenderStep: FC<RenderStepProps> = ({ event, icon, className, application }: RenderStepProps): JSX.Element => {
  const step = (stepClassName?: string, stepIcon?: any): JSX.Element => (
    <Step
      className={cx(stepClassName, { [applicationStatuses.accepted]: event.status === applicationStatuses.accepted })}
    >
      {stepIcon ? stepIcon : null}
      <Step.Content>
        <Step.Title>{t({ id: transApplication.status[event.status] })}</Step.Title>
        <Step.Description>{format(parseISO(event.createdAt), "dd/MM/yyyy")}</Step.Description>
        {application.talent.lateStageApplications?.map((lap, i) => {
          const isBeforeLast = lap.stage === applicationLateStage.beforeLast;
          return generateIriFromId("applications", lap.applicationId) === application["@id"]
            && event.status === applicationStatuses.interviews ? (
            <span
              key={`applicationItem-late-stage-label-${lap.applicationId}-${i}`}
              className={cx(styles.lastLapLabelContainer, globalStyles.lastLapLabelContainer, {
                [globalStyles.beforeLastLapLabelContainer]: isBeforeLast,
              })}
            >
              {isBeforeLast ? t`Label.beforeLastLap` : t`Label.lastLap`}
            </span>
          ) : null;
        })}
      </Step.Content>
    </Step>
  );
  if (event.status.includes("refused")) {
    const refusedText =
      application.refusedReason && transApplication.refusedReason[application.refusedReason]
        ? t({ id: transApplication.refusedReason[application.refusedReason] })
        : application.refusedReason;

    return <Popup trigger={step("refused")} content={!!refusedText ? refusedText : "..."} position="top center" />;
  }
  return step(className, icon);
};

const shortlistArray = [applicationStatuses.shortlistReady, applicationStatuses.awaitingShortlist];

interface Props {
  application: Application;
}
const ApplicationItem: FC<Props> = ({ application }: Props) => {
  const [appStatEvents, setAppStatEvents] = useState<any[]>(application.applicationStatusEvents);
  const prevAppStatEvents = usePrevious(application.applicationStatusEvents);
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const editApplication = (iri: string, req: Partial<Application>) => dispatch(editApplicationAction(iri, req));
  const onClickSeeTalentCardCta = () => {
    setOpenModal(!openModal);
  };
  const onCloseModal = () => {
    setOpenModal(false);
  };

  React.useEffect(() => {
    if (appStatEvents !== prevAppStatEvents) {
      setAppStatEvents(application.applicationStatusEvents);
    }
  }, [application, appStatEvents, setAppStatEvents, prevAppStatEvents]);

  const onClickCancelRefuse = () => {
    if (appStatEvents.length >= 2) {
      editApplication(application["@id"], { status: appStatEvents[appStatEvents.length - 2].status });
    }
  };

  const onClickHandleGoodMatch = () => {
    editApplication(application["@id"], { goodMatch: !application.goodMatch });
  };

  useEffect(() => {
    if (application.offerViewed !== null && prevAppStatEvents !== application.applicationStatusEvents) {
      appStatEvents.push({ status: "offerSeen", createdAt: application.offerViewed });
    }
    if (prevAppStatEvents !== application.applicationStatusEvents) {
      appStatEvents.sort(function (a, b) {
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
      });
      setAppStatEvents(
        appStatEvents.reduce((accumulator: StatusEvent[], currentValue: StatusEvent, index: number) => {
          const nextIndex = appStatEvents[index + 1];
          if (
            !nextIndex
            || (!(shortlistArray.includes(currentValue.status) && shortlistArray.includes(nextIndex.status))
              && nextIndex.status !== currentValue.status)
          ) {
            accumulator.push(currentValue);
          }
          return accumulator;
        }, []),
      );
    }
  }, [appStatEvents, prevAppStatEvents, application, setAppStatEvents]);

  return application && application.offer ? (
    <>
      <div className={styles.applicationContainer}>
        <div className={styles.header}>
          <div className={styles.headerItem}>
            <span
              className={styles.statusIcon}
              style={{ backgroundColor: offerStatusesColors[application.offer.status] }}
            ></span>
          </div>
          <div className={cx(styles.headerItem, styles.companyImgContainer)}>
            <CompanyImg classNames={styles.companyImg} src={application.offer.company?.image?.url} />
          </div>
          <div className={cx(styles.headerItem, styles.companyOfferNameContainer)}>
            <span className={styles.companyName}>{application.offer.company.name}</span>
            <Link className={styles.offerNameLink} to={extractPageFromIri("offers", application.offer["@id"])}>
              {application.offer.title}
            </Link>
          </div>
          {!!application.talentCard || applicationStatusesRefused.includes(application.status) ? (
            <div className={cx(styles.headerItem, styles.previewUnrefusedCtasContainer)}>
              {!!application.talentCard ? (
                <div className={styles.previewUnrefusedCtaContainer}>
                  <button
                    type="button"
                    onClick={onClickSeeTalentCardCta}
                    className={cx(
                      styles.talentCardPreviewCta,
                      globalStyles.cta,
                      globalStyles.primaryCta,
                      globalStyles.inverted,
                    )}
                  >
                    {t`ApplicationItem.TalentCardCta.text`}
                  </button>
                  <ModalWrapper open={openModal} onCloseModal={onCloseModal}>
                    <TalentCardModal talentCardIri={application.talentCard?.["@id"]} />
                  </ModalWrapper>
                </div>
              ) : null}
              {applicationStatusesRefused.includes(application.status) ? (
                <div className={styles.previewUnrefusedCtaContainer}>
                  <button
                    type="button"
                    onClick={onClickCancelRefuse}
                    className={cx(
                      styles.talentCardPreviewCta,
                      globalStyles.cta,
                      globalStyles.primaryCta,
                      globalStyles.inverted,
                    )}
                  >
                    Dérefuser
                  </button>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        <div className={styles.body}>
          <div className={cx(styles.bodyItem, styles.goodMatchContainer)}>
            <BsHeartFill
              className={cx(styles.noGoodMatch, { [styles.goodMatchIcon]: application.goodMatch })}
              onClick={onClickHandleGoodMatch}
            />
          </div>
          {appStatEvents ? (
            <div className={cx(styles.bodyItem, styles.stepsListContainer)}>
              <Step.Group>
                {appStatEvents.map((statusEvent: StatusEvent) => (
                  <RenderStep
                    key={statusEvent["@id"] || `${statusEvent.status}-${statusEvent.createdAt}`}
                    event={statusEvent}
                    application={application}
                  />
                ))}
              </Step.Group>
            </div>
          ) : null}
        </div>
      </div>
    </>
  ) : null;
};

export default ApplicationItem;
