import { t } from "@lingui/macro";
import * as React from "react";
import { Link } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";
import { CompanyList } from "../../components/Companies/CompanyList";

import FilterHandlerCompanyContainer from "../../containers/Companies/FilterHandlerCompanyContainer";

import { routes } from "../../routes";

import "../../styles/search.scss";

export const CompanySearchScene: React.FC = (): JSX.Element => (
  <div className="searchContainer">
    <div className="searchTitle">
      {t`CompanySearchScene.title`}
      <Button className="primaryButton" floated="right" as={Link} to={routes.companies.new}>
        <Icon name="plus square outline" />
        {t`CompanySearch.call_to_action`}
      </Button>
    </div>
    <div className="contentContainer">
      <div className="searchFilters">
        <FilterHandlerCompanyContainer />
      </div>
      <div className="searchResults">
        <CompanyList />
      </div>
    </div>
  </div>
);
