import { t } from "@lingui/macro";
import cx from "classnames";
import { format, parseISO } from "date-fns";
import * as React from "react";
import { BiMessageRoundedDots } from "react-icons/bi";
import { FiChevronDown } from "react-icons/fi";

import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { ModalWrapper } from "../../components/ModalWrapper";
import LoaderComp from "../../components/Utils/Loader";
import { Company, companyStatuses } from "../../interfaces/resources/company";
import { offerMode, offerStatuses } from "../../interfaces/resources/offer";
import { State } from "../../interfaces/state";
import { editCompanyAction } from "../../services/api/company/actions";
import { extractPageFromIri } from "../../services/api/helpers";
import globalStyles from "../../styles/global.module.scss";
import transCompany from "../../translations/constants/company";
import { CompanyImg } from "../companyImg";
import { Label } from "../labels";
import { PersonProfileImg } from "../personProfileImg";
import { CompanyNoteModal } from "./CompanyNoteModal";
import { DeleteCompanyModalCta } from "./DeleteCompanyModal";

import styles from "./index.module.scss";

interface OfferStatsProps {
  active: number;
  oneShot: {
    active: number;
    accepted: number;
    desactivated: number;
  };
  recurring: {
    active: number;
    accepted: number;
    desactivated: number;
  };
}

export const CompanyInfo: React.FC = () => {
  const dispatch = useDispatch();
  const company = useSelector((state: State) => state.company);

  const [isOpenDropdown, setIsOpenDropdown] = React.useState(false);
  const [openNoteModal, setOpenNoteModal] = React.useState<boolean>(false);
  const [offerStats, setOfferStats] = React.useState<OfferStatsProps | null>(null);
  const statusList = Object.keys(companyStatuses);
  const editCompanyStatus = (req: Partial<Company>) => dispatch(editCompanyAction(req, "", "status"));
  const onCloseNoteModal = () => {
    setOpenNoteModal(false);
  };

  React.useEffect(() => {
    if (company && Array.isArray(company?.offers)) {
      const compO = company.offers;
      const oneShots = compO.filter((o) => o.mode === offerMode.one_shot);
      const recurring = compO.filter((o) => o.mode === offerMode.recurring);
      const stats: OfferStatsProps = {
        active: compO.filter((o) => [offerStatuses.activated, offerStatuses.standbyElinoi].includes(o.status)).length,
        oneShot: {
          active: oneShots.filter((o) => [offerStatuses.activated, offerStatuses.standbyElinoi].includes(o.status))
            .length,
          accepted: oneShots.filter((o) => o.status === offerStatuses.desactivated && o.applicationsAccepted?.length)
            .length,
          desactivated: oneShots.filter(
            (o) => o.status === offerStatuses.desactivated && !!!o.applicationsAccepted?.length,
          ).length,
        },
        recurring: {
          active: recurring.filter((o) => [offerStatuses.activated, offerStatuses.standbyElinoi].includes(o.status))
            .length,
          accepted: recurring.filter((o) => o.status === offerStatuses.desactivated && o.applicationsAccepted?.length)
            .length,
          desactivated: recurring.filter(
            (o) => o.status === offerStatuses.desactivated && !!!o.applicationsAccepted?.length,
          ).length,
        },
      };
      setOfferStats(stats);
    }
  }, [company, offerStatuses, offerMode]);

  return (
    <div className={styles.infoContainer}>
      {!!!company ? (
        <div className={styles.loaderCompContainer}>
          <LoaderComp />
        </div>
      ) : (
        <>
          <div className={styles.topContainer}>
            <div>
              {company.owner ? (
                <>
                  <div className={styles.ownerImgContainer}>
                    <PersonProfileImg alt={company.owner.firstname} image={company.owner.image} />
                  </div>
                  <div className={styles.ownerName}>{company.owner.firstname}</div>
                </>
              ) : (
                <div className={styles.text}>{t`CompanyInfo.noOwner.text`}</div>
              )}
            </div>
            <div className={styles.labelsContainer}>
              {offerStats?.active ? (
                <div>
                  <Label type="green" content={`Offres active (${offerStats.active})`} />
                </div>
              ) : null}
            </div>
            <div>
              <div>
                <a
                  className={cx(styles.cta, globalStyles.cta, globalStyles.primaryCta)}
                  href={`${extractPageFromIri("companies", company["@id"])}/edit`}
                >
                  {t`Constant.update`}
                </a>
              </div>
              <div>
                <DeleteCompanyModalCta company={company} className={styles.cta} />
              </div>
            </div>
          </div>
          <div className={styles.middleContainer}>
            <div className={styles.companyImgStatusContainer}>
              <div className={styles.companyImgContainer}>
                <CompanyImg src={company.image?.url} />
              </div>
              <div className={styles.statusDropdownWrapper}>
                <div className={styles.statusDropdownContainer}>
                  <div
                    className={cx(styles.statusDropdownCta, {
                      [styles.isOpen]: isOpenDropdown,
                    })}
                    onClick={() => {
                      setIsOpenDropdown(!isOpenDropdown);
                    }}
                  >
                    <span>{t({ id: transCompany.status[company.status] })}</span>
                    <FiChevronDown />
                  </div>
                  {isOpenDropdown ? (
                    <div className={styles.statusDropdownListContainer}>
                      <ul className={styles.statusDropdownList}>
                        {statusList.map((status, i) => {
                          const statusLitteral = (companyStatuses as any)[status];
                          const isDisabled = company.status === statusLitteral;
                          return (
                            <li key={`dropdown-list-${status}-${i}`}>
                              <div
                                className={cx(styles.statusDropdownItem, { [styles.disabled]: isDisabled })}
                                onClick={() => {
                                  if (!isDisabled) {
                                    editCompanyStatus({
                                      ...company,
                                      status: statusLitteral,
                                    });
                                    setIsOpenDropdown(false);
                                  }
                                }}
                              >
                                {t({ id: transCompany.status[statusLitteral] })}
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className={styles.contactInfosContainer}>
              <div>
                <h3 className={styles.nameTitle}>
                  <a
                    href={extractPageFromIri("companies", company["@id"])}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.nameLink}
                  >
                    {company.name}
                  </a>
                </h3>
              </div>
              <div>
                <div>{company.sectors.map((s) => s.value).join(" - ")}</div>
              </div>
            </div>
            {!!offerStats ? (
              <>
                <div>
                  <div className={styles.offersInfosBlock}>
                    <div className={styles.offersInfosName}>
                      Offres <br /> One shot
                    </div>
                    <div>ouvertes : {offerStats.oneShot.active}</div>
                    <div>closées : {offerStats.oneShot.accepted}</div>
                    <div>fermées : {offerStats.oneShot.desactivated}</div>
                  </div>
                </div>
                <div>
                  <div className={styles.offersInfosBlock}>
                    <div className={styles.offersInfosName}>Offres Récurrentes</div>
                    <div>ouvertes : {offerStats.recurring.active}</div>
                    <div>closées : {offerStats.recurring.accepted}</div>
                    <div>fermées : {offerStats.recurring.desactivated}</div>
                  </div>
                </div>
              </>
            ) : null}
            {company ? (
              <div className={styles.noteContainer}>
                <div className={styles.noteCtaContainer}>
                  <button
                    type="button"
                    className={cx(styles.noteCta, globalStyles.cta, globalStyles.aquaCta, globalStyles.inverted)}
                    onClick={() => {
                      setOpenNoteModal(true);
                    }}
                  >
                    <BiMessageRoundedDots /> Ajouter une note
                  </button>
                  <ModalWrapper open={openNoteModal} onCloseModal={onCloseNoteModal}>
                    <CompanyNoteModal company={company} onCloseModal={onCloseNoteModal} />
                  </ModalWrapper>
                </div>
                {company.note ? (
                  <div className={styles.noteTextContainer}>
                    <ReactMarkdown>{company.note}</ReactMarkdown>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
          <div className={styles.bottomContainer}>
            <div className={styles.bottomCtasContainer}>
              {company.website ? (
                <div>
                  <a
                    className={cx(globalStyles.cta, globalStyles.primaryCta)}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={company.website}
                  >
                    {t`Action.website.text`}
                  </a>
                </div>
              ) : null}

              {/* {hubspotUrl ? (
                <div>
                  <a
                    className={cx(globalStyles.cta, globalStyles.hubspotCta)}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={hubspotUrl}
                  >
                    {t`Action.hubspot.seeProfile.text`}
                  </a>
                </div>
              ) : null} */}
            </div>

            <div>
              <div>
                <div className={styles.text}>
                  <ReactMarkdown
                    components={{
                      p: React.Fragment,
                    }}
                  >
                    {t({
                      id: "CompanyInfo.createdAt.text",
                      values: {
                        date: format(parseISO(company.createdAt), "dd/MM/yyyy"),
                      },
                    })}
                  </ReactMarkdown>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
