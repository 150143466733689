import * as React from "react";
import { Link } from "react-router-dom";

import { Talent } from "../../../../../interfaces/resources/talent";

import { extractPageFromIri } from "../../../../../services/api/helpers";
import { getUserImage } from "../../../../App/Image";
import RoundImage from "../../../../Design/RoundImage";

import ContactTalentOffers from "../../TalentToContact/ContactTalentCard/ContactTalentOffers";

import "./index.scss";

interface Props {
  talent: Talent;
}

const ContactedTalentsShort: React.FunctionComponent<Props> = ({ talent }: Props) => (
  <Link className="ContactedTalentsShort" target="_blank" to={extractPageFromIri("talents", talent["@id"])}>
    <RoundImage className="picture" size="49px" {...getUserImage(talent.image)} />
    <div className="info">
      <div className="title">{`${talent.firstname} ${talent.lastname}`}</div>
      <div className="extra">
        <ContactTalentOffers applications={talent.applications} />
      </div>
    </div>
  </Link>
);

export default ContactedTalentsShort;
