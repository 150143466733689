import { t } from "@lingui/macro";
import { formatDistance, parseISO } from "date-fns";
import { fr } from "date-fns/locale";
import * as React from "react";
import { BsHeartFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Divider } from "semantic-ui-react";
import { GetHuntTalents } from "../../../../../actionTypes/hunt";
import { GetTalentForCollection } from "../../../../../actionTypes/talents";
import { ApiSuccess } from "../../../../../interfaces/api/success";

import { Application, applicationStatuses } from "../../../../../interfaces/resources/application";
import { huntType } from "../../../../../interfaces/resources/hunt";
import { StatusEvent } from "../../../../../interfaces/resources/statusEvent";
import { Talent } from "../../../../../interfaces/resources/talent";
import { TalentProfile } from "../../../../../interfaces/resources/talentProfile";
import { State } from "../../../../../interfaces/state";
import { extractPageFromIri } from "../../../../../services/api/helpers";
import { transTalent } from "../../../../../translations/constants/talent";
import { getUserImage } from "../../../../App/Image";

import RoundImage from "../../../../Design/RoundImage";

import { useApiSelector } from "../../../../Utils/hook";
import ContactTalentOffers from "./ContactTalentOffers";

import "./index.scss";

export interface AppProps {
  talentId: any;
  talentProfile: TalentProfile | undefined;
  editApplication: (iri: string, req: any) => void;
  deleteApplication: (iri: string) => void;
  huntState: string;
  deteleTalent: (iri: string) => void;
  getTalent: (iri: string) => void;
}

const CardOfferInfo = ({ talentProfile }: any) => (
  <>
    <div className="info">
      <h4>{t`talentProfile.experienceYears`}: &nbsp;</h4>
      {talentProfile && talentProfile.experience ? (
        <span className="label">{`${talentProfile && talentProfile.experience} ans`}</span>
      ) : (
        t`talentProfile.experienceYears.empty`
      )}
    </div>
    <div className="info">
      <h4>{t`talentProfile.salaryExpectations`}: &nbsp; </h4>
      {talentProfile && talentProfile.salaryExpectations
        ? talentProfile.salaryExpectations.map((sal: any) => {
            return (
              <span key={talentProfile["@id"]} className="label">
                {sal.value}
              </span>
            );
          })
        : t`talentProfile.salaryExpectations.empty`}
    </div>
  </>
);

const CardExtra = ({ lastLogin }: any) => (
  <>
    <div className="lastLogin">
      {t`TalentCard.lastLogin`}
      {" " + formatDistance(parseISO(lastLogin), new Date(), { locale: fr })}
    </div>
    <Divider />
  </>
);

export const ContactTalentCard: React.FC<AppProps> = ({
  talentId,
  deleteApplication,
  editApplication,
  huntState,
  deteleTalent,
  talentProfile,
  getTalent,
}: AppProps): JSX.Element => {
  const { apiSuccess, apiPendingRequests } = useApiSelector();
  const offer = useSelector((state: State) => state.offer);
  const talents = useSelector((state: State) => state.talentsToContact);
  const [application, setApplication] = React.useState<Application | null>(null);
  const [talent, setTalent] = React.useState<Talent | undefined>(undefined);
  React.useEffect(() => {
    if (
      talentId
      && apiSuccess.some((e: ApiSuccess) => e.type === GetHuntTalents.SUCCESS)
      && !apiSuccess.some((e: ApiSuccess) => e.type === GetTalentForCollection.SUCCESS && e.iri === talentId)
      && !apiPendingRequests.some((e: any) => e.type === GetTalentForCollection.REQUEST)
    ) {
      getTalent(talentId);
    }
  }, [apiSuccess, getTalent]);

  React.useEffect(() => {
    if (talents.length > 0 && !talent) {
      const tal = talents.find((ta) => ta["@id"] === talentId);
      setTalent(tal);
    }
  }, [talents, talentId, talent]);

  React.useEffect(() => {
    if (talent && talent && application === null) {
      const app = talent.applications?.find((a) => offer && a.offer["@id"] === offer["@id"]);
      if (app) {
        setApplication(app);
      }
    }
  }, [talent, setApplication]);

  const deleteTalent = (): void => {
    if (talent) {
      talent.applications.forEach((item: Application) => {
        const previousApplicationStatus = item.applicationStatusEvents
          .slice(1)
          .find((e: StatusEvent) => e.status !== applicationStatuses.toContact);
        if (previousApplicationStatus) {
          const req = {
            status: previousApplicationStatus.status,
          };
          editApplication(item["@id"], req);
        } else {
          deleteApplication(item["@id"]);
          deteleTalent(talent["@id"]);
        }
      });
    }
  };

  const applications = talent?.applications;
  let offerInfo: any = null;
  let offersList: any = null;
  if (huntState === huntType.offer) {
    offerInfo = <CardOfferInfo talentProfile={talentProfile} />;
  } else if (applications) {
    offersList = (
      <div className="offerList">
        <div className="title">
          {t`ContactTalentOffers.title`} ({applications.length})
        </div>
        <ContactTalentOffers applications={applications} />
      </div>
    );
  }

  const tal = talent;

  return (
    <div className={`ContactTalentCard ${huntState === huntType.offer ? "offerMode" : ""}`}>
      {tal ? (
        <div className="infoContainer">
          <div className="leftBlock">
            <Link to={extractPageFromIri("talents", tal["@id"])}>
              <RoundImage className="picture" size="117px" {...getUserImage(tal.image)} />
            </Link>
            <div className="statusLabel">{t({ id: transTalent.status[tal.status] })}</div>
          </div>
          <div className="rightBlock">
            <div className="top">
              <div className="content">
                <Link className="title to-contact-name-link" to={extractPageFromIri("talents", tal["@id"])}>
                  <h4>{tal.firstname + " " + tal.lastname}</h4>
                  {application && application.goodMatch ? (
                    <span className="favHeart">
                      <BsHeartFill />
                    </span>
                  ) : null}
                </Link>
                <a href={tal.linkedInProfile?.url} rel="noopener noreferrer" target="_blank">
                  {tal.talentProfile?.currentJob && tal.talentProfile?.currentCompany
                    ? `${tal.talentProfile.currentJob} @ ${tal.talentProfile?.currentCompany}`
                    : tal.linkedInProfile?.headline
                    ? tal.linkedInProfile.headline
                    : t`linkedInProfile.headline.empty`}
                </a>

                {offerInfo}
              </div>
              <div className="iconGroup">
                <Button onClick={deleteTalent} basic icon="close" />
              </div>
            </div>
            <div className="bottom">
              {huntState === huntType.offer && tal.lastLogin && talentProfile && (
                <CardExtra lastLogin={tal.lastLogin} />
              )}
            </div>
          </div>
        </div>
      ) : null}
      {offersList}
    </div>
  );
};
