import { GetCalendlyEvents } from "../../../actionTypes/calendlyEvents";
import { CalendlyEvent } from "../../../interfaces/resources/calendlyEvent";

export function calendlyEvents(state: CalendlyEvent[] = [], action: any): CalendlyEvent[] {
  switch (action.type) {
    case GetCalendlyEvents.SUCCESS:
      return action.calendlyEvents;
    default:
      return state;
  }
}
