import { t } from "@lingui/macro";
import cx from "classnames";
import * as React from "react";
import { IoClose } from "react-icons/io5";

import { PartialApplication } from "..";
import { getCompanyImage } from "../../../../components/App/Image";
import RoundImage from "../../../../components/Design/RoundImage";
import { applicationStatuses } from "../../../../interfaces/resources/application";
import { Offer } from "../../../../interfaces/resources/offer";
import { SalaryExpectations } from "../../../../interfaces/resources/salaryExpectations";
import globalStyles from "../../../../styles/global.module.scss";
import transApplication from "../../../../translations/constants/application";
import transOffer from "../../../../translations/constants/offer";
import styles from "./index.module.scss";

export interface Props {
  applications: PartialApplication[];
  isAccountManager: boolean;
  removeApplication: (offer: Offer) => void;
  updateApplication: (offer: Offer, status: string, goodMatch?: boolean) => void;
}

const SelectedOfferList: React.FC<Props> = ({
  applications,
  isAccountManager,
  removeApplication,
  updateApplication,
}: Props) => {
  return (
    <div className={styles.offerList}>
      {applications.map((item: PartialApplication, i: number) => (
        <div key={i} className={styles.card}>
          <RoundImage
            className="picture"
            size="49px"
            banner={item.offer.status ? t({ id: transOffer.status[item.offer.status] }) : undefined}
            smallBanner={item.offer.status === "standby"}
            {...getCompanyImage(item.offer.company.image)}
          />
          <div className={styles.titleInfosContainer}>
            <div className={styles.title}>
              {item.offer.company.name} - {item.offer.title}
            </div>
            <div className={styles.infos}>
              {item.offer.stapleLocation && item.offer.stapleLocation.value} |{" "}
              {item.offer.salary && item.offer.salary.map((e: SalaryExpectations) => e.value).join(", ")}
            </div>
          </div>
          <div className={styles.ctasContainer}>
            <div>
              <button
                type="button"
                onClick={(): void =>
                  updateApplication(
                    item.offer,
                    isAccountManager ? applicationStatuses.contacted : applicationStatuses.interesting,
                  )
                }
                className={cx(globalStyles.cta, globalStyles.primaryCta, globalStyles.inverted, {
                  [globalStyles.active]:
                    !!!item.goodMatch
                    && [applicationStatuses.interesting, applicationStatuses.contacted].includes(item.status || ""),
                })}
              >
                {t({
                  id: transApplication.status[
                    isAccountManager ? applicationStatuses.contacted : applicationStatuses.interesting
                  ],
                })}
              </button>
            </div>
            <div>
              <button
                type="button"
                onClick={(): void =>
                  updateApplication(
                    item.offer,
                    isAccountManager ? applicationStatuses.contacted : applicationStatuses.interesting,
                    true,
                  )
                }
                className={cx(
                  globalStyles.cta,
                  globalStyles.aquaCta,
                  globalStyles.inverted,
                  styles.goodMatchOfferButton,
                  {
                    [globalStyles.active]: !!item.goodMatch,
                  },
                )}
              >
                {t({ id: transApplication.status.good_match })}
              </button>
            </div>
            {isAccountManager ? (
              <div>
                <button
                  type="button"
                  onClick={(): void => updateApplication(item.offer, applicationStatuses.awaitingShortlist)}
                  className={cx(globalStyles.cta, globalStyles.primaryCta, globalStyles.inverted, {
                    [globalStyles.active]: item.status === applicationStatuses.awaitingShortlist,
                  })}
                >
                  {t({ id: transApplication.status[applicationStatuses.awaitingShortlist] })}
                </button>
              </div>
            ) : null}
            <div className={styles.close}>
              <button
                type="button"
                className={cx(globalStyles.cta, globalStyles.alertCta, globalStyles.inverted, styles.removeCta)}
                onClick={() => removeApplication(item.offer)}
              >
                <IoClose />
              </button>
              {/* <Icon name="close" onClick={(): void => removeApplication(item.offer)} /> */}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SelectedOfferList;
