import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { change, getFormValues } from "redux-form";

import CompanyForm, { COMPANY_FORM } from "../../../components/Companies/CompanyForm";
import { Company } from "../../../interfaces/resources/company";

import { editCompanyAction, getCompanyAction } from "../../../services/api/company/actions";
import { getContractsAction } from "../../../services/api/company/contract/actions";
import { getCoreUsersAction } from "../../../services/api/coreUser/actions";
import { getResponsibilitiesAction } from "../../../services/api/responsibilities/actions";
import { getSectorsAction } from "../../../services/api/sector/actions";

interface OwnProps {
  iri?: string;
}

const mapStateToProps = (state: any) => {
  return {
    apiErrors: state.apiErrors,
    apiPendingRequests: state.apiPendingRequests,
    apiSuccess: state.apiSuccess,
    company: state.company,
    contact: state.contact,
    contracts: state.contracts,
    coreUsers: state.coreUsers,
    currentUser: state.currentUser,
    formValues: getFormValues(COMPANY_FORM)(state),
    responsibilities: state.responsibilities,
    sectors: state.sectors,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
  return {
    changeFormValue: (field: string, value: any) => dispatch(change(COMPANY_FORM, field, value)),
    getCompany: () => (ownProps.iri ? dispatch(getCompanyAction(ownProps.iri)) : null),
    getContracts: () => dispatch(getContractsAction()),
    getCoreUsers: () => dispatch(getCoreUsersAction()),
    getResponsibilities: () => dispatch(getResponsibilitiesAction()),
    getSectors: () => dispatch(getSectorsAction()),
    submitCompany: (req: Partial<Company>, redirectOnSuccess?: boolean | string) =>
      dispatch(editCompanyAction(req, COMPANY_FORM, undefined, redirectOnSuccess)),
  };
};

const EditCompanyForm = (props: any) => {
  return <CompanyForm {...props} state="edition" initialValues={props.company} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCompanyForm);
