import { t } from "@lingui/macro";
import * as React from "react";
import styles from "./index.module.scss";

interface Props {
  number: number;
  type: string;
}
export const TalentData: React.FC<Props> = ({ number, type }: Props) => {
  const status: { [key: string]: { [key: string]: string } } = {
    singular: {
      contacted: t`talentData.contacted`,
      to_pitch: t`talentData.toPitch`,
      interviews: t`talentData.interviews`,
      shortlisted: t`talentData.shortlisted`,
      accepted: t`talentData.accepted`,
    },
    plural: {
      contacted: t`talentData.plural.contacted`,
      to_pitch: t`talentData.toPitch`,
      interviews: t`talentData.interviews`,
      shortlisted: t`talentData.shortlisted`,
      accepted: t`talentData.plural.accepted`,
    },
  };
  return (
    <div className={styles.container}>
      <div className={styles.number}>{number}</div>
      <div className={styles.type}>
        {number > 1 ? t({ id: status.plural[type] }) : t({ id: status.singular[type] })}
      </div>
    </div>
  );
};
