import cx from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { HistoryRouter as Router } from "redux-first-history/rr6";

import { Header } from "../../components/Header";
import { State } from "../../interfaces/state";
import { AlgoliaSearchPage } from "../../pages/AlgoliaSearchPage";
import { ApplicationsDashboard } from "../../pages/ApplicationsDashboardPage";
import { CompanyPage } from "../../pages/CompanyPage";
import { MyDashboardPage } from "../../pages/MyDashboardPage";
import { OfferPage } from "../../pages/OfferPage";
import { TalentCardPage } from "../../pages/TalentCardPage";
import { TalentMissingQualifPage } from "../../pages/TalentMissingQualifPage";
import { routes } from "../../routes";
import { CompanyCreateScene } from "../../scenes/CompanyCreateScene";
import { CompanyEditScene } from "../../scenes/CompanyEditScene";
import { CompanySearchScene } from "../../scenes/CompanySearchScene";
import { CoreUserListScene } from "../../scenes/CoreUserListScene";
import { CoreUserShowScene } from "../../scenes/CoreUserShowScene";
import { ErrorNotFoundScene } from "../../scenes/ErrorNotFoundScene";
import { InternalHuntContactsScene } from "../../scenes/InternalHuntContactsScene";
import { LoginScene } from "../../scenes/LoginScene";
import { OfferCreateScene } from "../../scenes/OfferCreateScene";
import { OfferEditScene } from "../../scenes/OfferEditScene";
import { OfferSearchScene } from "../../scenes/OfferSearchScene";
import { ShortListScene } from "../../scenes/ShortListScene";
import { TalentSearchScene } from "../../scenes/TalentSearchScene";
import { TalentShowScene } from "../../scenes/TalentShowScene";
import { TalentSortScene } from "../../scenes/TalentSortScene";
import { history } from "../../store";
import globalStyles from "../../styles/global.module.scss";
import { cleanLocalStorage } from "../../utils/localStorage";
import { RequireAuth } from "../RequireAuth";
import "./index.scss";
import "react-input-range/lib/css/index.css";

export const App: React.FC = () => {
  const auth = useSelector((state: State) => state.auth);
  React.useEffect(() => {
    cleanLocalStorage();
  }, []);
  return (
    <Router history={history}>
      {auth.isAuthed ? <Header /> : null}
      <div className={cx(globalStyles.pagesWrapper, { [globalStyles.hiddenHeader]: !auth.isAuthed })}>
        <Routes>
          <Route path={routes.auth.login} element={<LoginScene />} />
          <Route
            path={routes.talents.myTalents}
            element={
              <Navigate
                to={{
                  pathname: routes.app.dashboard,
                }}
                replace
              />
            }
          />
          <Route
            path={routes.offers.dashboard}
            element={
              <Navigate
                to={{
                  pathname: routes.app.dashboard,
                }}
                replace
              />
            }
          />
          <Route
            path={routes.admin.userList}
            element={
              <RequireAuth>
                <CoreUserListScene />
              </RequireAuth>
            }
          />
          <Route
            path={routes.app.home}
            element={
              <RequireAuth>
                <MyDashboardPage />
              </RequireAuth>
            }
          />
          <Route
            path={routes.applications.dashboard}
            element={
              <RequireAuth>
                <ApplicationsDashboard mode="suivi" />
              </RequireAuth>
            }
          />
          <Route
            path={routes.applications.talentCard}
            element={
              <RequireAuth>
                <TalentCardPage />
              </RequireAuth>
            }
          />
          <Route
            path={routes.companies.edit}
            element={
              <RequireAuth>
                <CompanyEditScene />
              </RequireAuth>
            }
          />
          <Route
            path={routes.companies.new}
            element={
              <RequireAuth>
                <CompanyCreateScene />
              </RequireAuth>
            }
          />
          <Route
            path={routes.companies.search}
            element={
              <RequireAuth>
                <CompanySearchScene />
              </RequireAuth>
            }
          />
          <Route
            path={routes.companies.show}
            element={
              <RequireAuth>
                <CompanyPage />
              </RequireAuth>
            }
          />
          <Route
            path={routes.coreUser.profile}
            element={
              <RequireAuth>
                <CoreUserShowScene />
              </RequireAuth>
            }
          />

          <Route
            path={routes.offers.contactTalent}
            element={
              <RequireAuth>
                <InternalHuntContactsScene />
              </RequireAuth>
            }
          />

          <Route
            path={routes.hunts.hunt}
            element={
              <RequireAuth>
                <AlgoliaSearchPage />
              </RequireAuth>
            }
          />
          <Route
            path={routes.offers.edit}
            element={
              <RequireAuth>
                <OfferEditScene />
              </RequireAuth>
            }
          />
          <Route
            path={routes.offers.new}
            element={
              <RequireAuth>
                <OfferCreateScene />
              </RequireAuth>
            }
          />
          <Route
            path={routes.offers.search}
            element={
              <RequireAuth>
                <OfferSearchScene />
              </RequireAuth>
            }
          />
          <Route
            path={routes.offers.shortlist}
            element={
              <RequireAuth>
                <ShortListScene />
              </RequireAuth>
            }
          />
          <Route
            path={routes.offers.show}
            element={
              <RequireAuth>
                <OfferPage />
              </RequireAuth>
            }
          />
          <Route
            path={routes.app.dashboard}
            element={
              <RequireAuth>
                <MyDashboardPage />
              </RequireAuth>
            }
          />
          <Route
            path={routes.talents.noQualifTalents}
            element={
              <RequireAuth>
                <TalentMissingQualifPage />
              </RequireAuth>
            }
          />
          <Route
            path={routes.talents.search}
            element={
              <RequireAuth>
                <TalentSearchScene />
              </RequireAuth>
            }
          />
          <Route
            path={routes.talents.show}
            element={
              <RequireAuth>
                <TalentShowScene />
              </RequireAuth>
            }
          />
          <Route
            path={routes.talents.sort}
            element={
              <RequireAuth>
                <TalentSortScene />
              </RequireAuth>
            }
          />
          <Route element={<ErrorNotFoundScene />} />
        </Routes>
      </div>
    </Router>
  );
};
