import React, { FC, useState, PropsWithChildren } from "react";
import { Modal } from "semantic-ui-react";
import { Application } from "../../../interfaces/resources/application";
import { ApplicationAcceptedForm } from "./form";

import styles from "./index.module.scss";

interface Props {
  application: Application;
  className?: string;
}

export const ApplicationAcceptedModal: FC<PropsWithChildren<Props>> = ({
  application,
  children,
  className,
}: PropsWithChildren<Props>) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  return (
    <>
      <div className={className} onClick={(): void => setOpen(true)}>
        {children}
      </div>
      <Modal size="tiny" open={open} onClose={handleClose} className={styles.talentAcceptedModal}>
        <span className={styles.closeModalCta} onClick={handleClose} />
        <ApplicationAcceptedForm closeModal={handleClose} application={application} />
      </Modal>
    </>
  );
};
