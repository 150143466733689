import { t } from "@lingui/macro";
import * as React from "react";
import { Button, Icon, Item } from "semantic-ui-react";

import { CoreUser } from "../../../interfaces/resources/coreUser";

import transCoreUser from "../../../translations/constants/coreUser";
import { getUserImage } from "../../App/Image";

import CoreUserEditionModal from "../CoreUserEditionModal";

export interface CoreUserShortProps {
  coreUser: CoreUser;
  deleteCoreUser: () => void;
  switchOn: (iri: string) => void;
}

export default class CoreUserShort extends React.PureComponent<CoreUserShortProps, any> {
  public render() {
    const { coreUser, switchOn } = this.props;
    return (
      <Item>
        <Item.Image avatar {...getUserImage(coreUser.image)} />
        <Item.Content>
          <div className="mainBlock">
            <Item.Header>
              {coreUser.firstname ? (
                <span>
                  {`${coreUser.firstname} ${coreUser.lastname}`} (<i>{coreUser.email}</i>)
                </span>
              ) : (
                <span>{coreUser.email}</span>
              )}
            </Item.Header>
            <Item.Description>
              {coreUser.roles.map((role: string) => t({ id: transCoreUser.roles[role] })).join(", ")}
            </Item.Description>
          </div>
          <Item.Extra>
            <Button icon="close" content={t`Constant.delete`} onClick={this.props.deleteCoreUser} />
            {coreUser.enabled ? (
              <div>
                <CoreUserEditionModal coreUser={coreUser} />
                <Button onClick={() => switchOn(coreUser["@id"])}>{t`CoreUserShort.switch`}</Button>
              </div>
            ) : (
              <span className="invitation">
                {t`coreuserList.invited`}
                <Icon name="paper plane outline" />
              </span>
            )}
          </Item.Extra>
        </Item.Content>
      </Item>
    );
  }
}
