import { FilterContext } from "../api/filter";
import { CoreUser } from "./coreUser";
import { Offer } from "./offer";
import { Talent } from "./talent";

export interface Hunt {
  "@id": string;
  name: string;
  owner: CoreUser;
  discardedTalents: Talent[];
  value: FilterContext;
}

export const huntType = {
  all: "all",
  hunt: "hunt",
  offer: "offer",
};

// remove things
export const pageType = {
  contactAllTalent: "contact_all_talent",
  contactTalent: "contact_talent",
  huntAllTalent: "hunt_all_talent",
  huntTalent: "hunt_talent",
};

export const huntFilterContext = {
  getHuntTalents: {
    all: (coreUser: CoreUser) => [
      {
        multiple: false,
        name: "owner",
        value: coreUser["@id"],
      },
    ],
    hunt: (hunt: Hunt) => [
      {
        multiple: false,
        name: "hunt",
        value: hunt["@id"],
      },
    ],
    offer: (coreUser: CoreUser, offer: Offer) => [
      {
        multiple: false,
        name: "owner",
        value: coreUser["@id"],
      },
      {
        multiple: false,
        name: "offer",
        value: offer["@id"],
      },
    ],
  },
};
