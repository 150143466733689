import { GetResponsibilities } from "../../../actionTypes/responsibilities";
import { Responsibility } from "../../../interfaces/resources/responsibility";

export function responsibilities(state: Responsibility[] = [], action: any) {
  switch (action.type) {
    case GetResponsibilities.SUCCESS:
      return !!action.page
        ? [...state, ...action.responsibilities.filter((e: Responsibility) => e.active)]
        : action.responsibilities.filter((e: Responsibility) => e.active);
    default:
      return state;
  }
}
