import { t } from "@lingui/macro";
import * as React from "react";
import { Search, SearchProps } from "semantic-ui-react";

import { GetCompanies } from "../../../actionTypes/companies";
import { ApiRequest } from "../../../interfaces/api/request";

interface Props {
  apiPendingRequests: ApiRequest[];
  onChange: (_: unknown, data: SearchProps) => void;
  text: string;
}

export const CompanyNameFilter: React.FC<Props> = ({ apiPendingRequests, onChange, text }: Props) => (
  <Search
    loading={apiPendingRequests.some((item: ApiRequest) => GetCompanies.REQUEST === item.type)}
    onSearchChange={onChange}
    placeholder={t`CompanyNameFilter.placeholder`}
    showNoResults={false}
    value={text}
  />
);
