import { t } from "@lingui/macro";
import cx from "classnames";
import { parseISO, differenceInDays, format } from "date-fns";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCompanyImage } from "../../components/App/Image";
import RoundImage from "../../components/Design/RoundImage";
import { hasUnreadMessageInApp } from "../../components/Utils/helpers";
import { Application, applicationStatuses } from "../../interfaces/resources/application";
import { Offer, offerMode, offerStatuses } from "../../interfaces/resources/offer";
import { Talent } from "../../interfaces/resources/talent";
import { State } from "../../interfaces/state";
import { columns } from "../../pages/ApplicationsDashboardPage/Column/data";
import { editApplicationAction } from "../../services/api/applications/actions";
import { extractPageFromIri } from "../../services/api/helpers";
import globalStyles from "../../styles/global.module.scss";
import { labelType } from "../../translations/constants/label";
import transOffer from "../../translations/constants/offer";
import { CardApplication } from "../cardApplication";

import { Label } from "../labels";
import styles from "./index.module.scss";
import { TalentData } from "./TalentData";

interface Props {
  offer: Offer;
}

export const CardOffer: React.FC<Props> = ({ offer }): JSX.Element => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: State) => state.currentUser);
  const applications = useSelector((state: State) => state.applications);
  const lastActivation = offer.lastActivationStatusDate
    ? differenceInDays(new Date(), parseISO(offer.lastActivationStatusDate))
    : undefined;
  const [hasUnreadMessage, setHasUnreadMessage] = React.useState(false);
  const [talentsLastLap, setTalentsLastLap] = React.useState<Talent[]>([]);
  const [applicationsInterviews, setApplicationsInterviews] = React.useState<Application[]>([]);
  const [applicationsShorlisted, setApplicationsShorlisted] = React.useState<Application[]>([]);
  const [showTalents, setShowTalents] = React.useState(false);
  const editApplication = (iri: string, req: Partial<Application>) => dispatch(editApplicationAction(iri, req));
  const onDateSelect = (date: Date, application: Application): void => {
    const dateString = format(date, "yyyy-MM-dd HH:mm:ss");
    if (null === application.interviewAt) {
      editApplication(application["@id"], {
        interviewAt: dateString,
        interviewsAt: [dateString],
        status: applicationStatuses.interviews,
      });
    } else {
      editApplication(application["@id"], {
        interviewAt: dateString,
        interviewsAt: application.interviewsAt === null ? [dateString] : [...application.interviewsAt, dateString],
      });
    }
  };

  React.useEffect(() => {
    const appShort: Application[] = [];
    const appInter: Application[] = [];
    const talentLast: Talent[] = [];
    const apps: Application[] = [];
    const offerApps = offer?.shortlistedAndInterviewsApplications || [];
    let unread = false;

    if (offer) {
      applications.forEach((sApp) => {
        if (sApp.offer?.["@id"] === offer?.["@id"]) {
          apps.push(sApp);
        }
      });
    }

    offerApps.forEach((oApp) => {
      const iApp = applications.findIndex((a: Application) => a["@id"] === oApp["@id"]);
      if (iApp < 0) {
        apps.push(oApp);
      }
    });

    apps.forEach((app) => {
      if (hasUnreadMessageInApp(app, currentUser)) {
        unread = true;
      }
      if (![applicationStatuses.interviews, applicationStatuses.shortlisted].includes(app.status)) {
        return;
      }

      switch (app.status) {
        case applicationStatuses.shortlisted:
          appShort.push(app);
          break;
        case applicationStatuses.interviews:
          if (app.interviewAt && differenceInDays(parseISO(app.interviewAt), new Date().setHours(0, 0, 0, 0)) >= 0) {
            appInter.push(app);
          } else {
            appShort.push(app);
          }
          break;
      }
      if (
        [applicationStatuses.interviews, applicationStatuses.shortlisted].includes(app.status)
        && app.interviewsAt
        && offer.countInterviewSteps
        && offer.countInterviewSteps - app.interviewsAt.length === 0
      ) {
        talentLast.push(app.talent);
      }
    });
    setHasUnreadMessage(unread);
    setApplicationsInterviews(appInter);
    setApplicationsShorlisted(appShort);
    setTalentsLastLap(talentLast);
  }, [offer, applications, currentUser]);

  return (
    <>
      <div className={styles.card}>
        <div className={styles.cardContent}>
          <div className={styles.headerCardContainer}>
            {offer.mode === "recurring" ? (
              <div className={styles.headerItem}>
                <Label
                  key={`offer-card-recurring-label-${offer["@id"]}-${offer.mode}`}
                  content={"recurrente"}
                  className={styles.interviewLabel}
                />
              </div>
            ) : null}
            {offer.status === offerStatuses.standbyElinoi ? (
              <div className={styles.headerItem}>
                <Label
                  key={`offer-card-recurring-label-${offer["@id"]}-${offer.status}`}
                  type={labelType.offer_standby}
                  content={"stand-by"}
                  className={styles.interviewLabel}
                />
              </div>
            ) : null}
            {talentsLastLap.length > 0 ? (
              <div className={styles.headerItem}>
                <Label
                  key={`offer-card-late-stage-label-${offer["@id"]}-${offer.status}`}
                  type={labelType.last_lap}
                  content={`dernier tour (${talentsLastLap.length})`}
                />
              </div>
            ) : null}
            {hasUnreadMessage ? (
              <div className={styles.headerItem}>
                <Label key={`offer-card-unread-message-${offer["@id"]}`} type="red" content="nouveaux messages" />
              </div>
            ) : null}
            {lastActivation ? (
              <div className={styles.headerItem}>
                <div
                  className={cx(styles.lastActivation, {
                    [styles.lateState1]: offer.mode !== offerMode.recurring && lastActivation >= 60,
                    [styles.lateState2]: offer.mode !== offerMode.recurring && lastActivation >= 90,
                  })}
                >
                  {lastActivation} j
                </div>
              </div>
            ) : null}
          </div>
          <div className={styles.bodyCardContainer}>
            <div className={styles.offerPartContainer}>
              <div className={styles.companyImgContainer}>
                <RoundImage
                  size="52px"
                  banner={
                    offer.status === offerStatuses.standbyElinoi
                      ? t({ id: transOffer.status[offer.status] })
                      : undefined
                  }
                  {...getCompanyImage(offer?.company?.image)}
                />
              </div>
              <div>
                <h5 className={styles.companyNameContainer}>
                  <Link
                    className={styles.companyNameLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    to={extractPageFromIri("companies", offer.company["@id"])}
                  >
                    {offer.company.name}
                  </Link>
                </h5>
                <h4 className={styles.offerTitleContainer}>
                  <Link
                    className={styles.offerTitleLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    to={`${extractPageFromIri("offers", offer["@id"])}#in-process`}
                  >
                    {offer.title}
                  </Link>
                </h4>
              </div>
            </div>
            <div className={styles.offerTalentsWrapper}>
              <div className={styles.offerTalentsContainer}>
                <div>
                  <TalentData number={applicationsShorlisted.length} type={applicationStatuses.shortlisted} />
                </div>
                <div>
                  <TalentData number={applicationsInterviews.length} type={applicationStatuses.interviews} />
                </div>
              </div>
              {applicationsShorlisted.length || applicationsInterviews.length ? (
                <div className={styles.offerTalentCtaContainer}>
                  <button
                    type="button"
                    className={cx(styles.offerTalentCta, globalStyles.cta, globalStyles.primaryCta, {
                      [styles.active]: showTalents,
                    })}
                    onClick={() => {
                      setShowTalents(!showTalents);
                    }}
                  >
                    Afficher les talents
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {showTalents ? (
        <div>
          <div className={styles.talentsListsContainer}>
            <div className={styles.talentsListContainer}>
              <span className={styles.talentsListTitle}>En attente de date</span>
              <div className={cx(styles.listContainer, globalStyles.hideScrollBar)}>
                <ul>
                  {applicationsShorlisted.map((app, i) => {
                    return (
                      <li key={`${app["@id"]}-${i}`}>
                        <CardApplication
                          application={{ ...app, offer }}
                          column={columns["column-a"]}
                          onDateSelect={onDateSelect}
                          showOfferInfo={false}
                          editApplication={editApplication}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className={styles.talentsListContainer}>
              <span className={styles.talentsListTitle}>Date prochaine entretien</span>
              <div className={cx(styles.listContainer, globalStyles.hideScrollBar)}>
                <ul>
                  {applicationsInterviews.map((app, i) => {
                    return (
                      <li key={`${app["@id"]}-${i}`}>
                        <CardApplication
                          application={{ ...app, offer }}
                          column={columns["column-b"]}
                          onDateSelect={onDateSelect}
                          showOfferInfo={false}
                          editApplication={editApplication}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
