import { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";

import { GetCompetences } from "../../../actionTypes/competences";
import { Competence } from "../../../interfaces/resources/competence";
import { apiFailureAction } from "../actions";
import { getCompetencesSuccessAction } from "./actions";
import * as apiClient from "./api";

export function* getCompetences(action: any) {
  try {
    const response: AxiosResponse<{
      "hydra:member": Competence[];
    }> = yield call(apiClient.getCompetences, action.filterContext);
    yield put(getCompetencesSuccessAction(response.data["hydra:member"], action));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export default function* competencesSaga() {
  yield takeLatest(GetCompetences.REQUEST, getCompetences);
}
