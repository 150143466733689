import { t } from "@lingui/macro";
import { RefusedReason } from "../../interfaces/resources/application";

interface Const {
  clientStatus: { [key: string]: string };
  refusedReason: { [key in RefusedReason]: string };
  status: { [key: string]: string };
}

const transApplication: Const = {
  refusedReason: {
    entrepreneurship_xp_required: t`Constant.application.refusedReason.entrepreneurship_xp_required`,
    grande_ecole_required: t`Constant.application.refusedReason.grande_ecole_required`,
    management_xp_required: t`Constant.application.refusedReason.management_xp_required`,
    missing_hardskills: t`Constant.application.refusedReason.missing_hardskills`,
    no_evolution: t`Constant.application.refusedReason.no_evolution`,
    salary_too_low: t`Constant.application.refusedReason.salary_too_low`,
    strategic_profile_required: t`Constant.application.refusedReason.strategic_profile_required`,
    too_junior: t`Constant.application.refusedReason.too_junior`,
    too_senior: t`Constant.application.refusedReason.too_senior`,
    other_application_accepted: t`Constant.application.refusedReason.other_application_accepted`,
    not_searching_job_found: t`Constant.application.refusedReason.not_searching_job_found`,
    other_no_notification: t`Constant.application.refusedReason.other_no_notification`,
  },
  status: {
    accepted: t`Constant.application.status.accepted`,
    applied: t`Constant.application.status.applied`,
    awaiting_shortlist: t`Constant.application.status.awaiting_shortlist`,
    call_booked: t`Constant.application.status.call_booked`,
    contacted: t`Constant.application.status.contacted`,
    good_match: t`Constant.application.status.good_match`,
    interesting: t`Constant.application.status.interesting`,
    interviews: t`Constant.application.status.interviews`,
    offer: t`Constant.application.status.offer`,
    refused_auto: t`Constant.application.status.refused_auto`,
    refused_elinoi: t`Constant.application.status.refused_elinoi`,
    refused_startup: t`Constant.application.status.refused_startup`,
    refused_talent: t`Constant.application.status.refused_talent`,
    shortlist_ready: t`Constant.application.status.shortlist_ready`,
    shortlisted: t`Constant.application.status.shortlisted`,
    to_contact: t`Constant.application.status.to_contact`,
    to_pitch: t`Constant.application.status.to_pitch`,
    offerSeen: t`Constant.application.status.offerSeen`,
  },
  clientStatus: {
    client_new: t`Constant.application.clientStatus.client_new`,
    client_contacted: t`Constant.application.clientStatus.client_contacted`,
    client_interviews: t`Constant.application.clientStatus.client_interviews`,
    client_hired: t`Constant.application.clientStatus.client_hired`,
    client_need_to_refused: t`Constant.application.clientStatus.client_need_to_refused`,
    client_refused: t`Constant.application.clientStatus.client_refused`,
  },
};

export default transApplication;
