import * as React from "react";
import { WrappedFieldInputProps, WrappedFieldMetaProps } from "redux-form";
import { Form, FormRadioProps } from "semantic-ui-react";

interface RadioFieldProps extends FormRadioProps {
  input: WrappedFieldInputProps;
  meta: WrappedFieldMetaProps;
  getOptionValue: (option: any) => string;
  item: any;
}

const Radio = ({ getOptionValue, input: { name, onChange, value }, item, label, ...rest }: RadioFieldProps) => (
  <Form.Radio
    {...rest}
    label={label ? label : null}
    name={name}
    checked={value === getOptionValue(item)}
    value={getOptionValue(item)}
    onChange={(_: any, data: any) => onChange(data.value)}
  />
);

export default Radio;
