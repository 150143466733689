import { AnyAction } from "redux";
import { GetExperienceYears } from "../../../actionTypes/experienceYears";
import { ExperienceYears } from "../../../interfaces/resources/experienceYears";

export function getExperienceYearsAction(page?: number) {
  return {
    page,
    type: GetExperienceYears.REQUEST,
  };
}

export function getExperienceYearsSuccessAction(experienceYears: ExperienceYears[], action: AnyAction) {
  return {
    experienceYears,
    page: action.page,
    type: GetExperienceYears.SUCCESS,
  };
}
