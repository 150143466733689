import { t } from "@lingui/macro";
import cx from "classnames";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { FieldProps, useField } from "formik";
import marked from "marked";
import RangeStatic from "quill";
import * as React from "react";
import { useQuill } from "react-quilljs";
import TurndownService from "turndown";

import { Violation } from "../../../../interfaces/api/violation";

import { getViolationMessage } from "../../../Utils/hook";
import ErrorMessage from "../ErrorMessage";

import "quill/dist/quill.snow.css";
import styles from "./index.module.scss";

interface RichTextProps extends FieldProps {
  charCounter?: boolean;
  className?: string;
  disabled?: boolean;
  error: boolean | string;
  inlineSubmitTrigger?: React.ReactNode;
  isRequired?: boolean;
  label?: React.ReactNode;
  labelClassName?: string;
  maxLength?: number;
  maxStack?: number;
  minLength?: number;
  placeholder?: string;
  replaceIfEmpty?: boolean;
  saveSpeed?: number;
  showToolbar?: boolean;
  smaller?: boolean;
  uniqId?: string | null;
  violations?: Violation[];
  reload?: boolean;
  setReload?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface SavedText {
  date: Date;
  value: string;
  version: number;
}

const RichTextEditor: React.FunctionComponent<RichTextProps> = ({
  charCounter,
  className = "",
  disabled,
  field: { name },
  form: { setFieldValue },
  inlineSubmitTrigger = null,
  isRequired = true,
  label,
  labelClassName = "",
  maxLength,
  maxStack = 10,
  minLength,
  placeholder = "",
  replaceIfEmpty = true,
  saveSpeed = 5000,
  smaller = false,
  uniqId = null,
  violations,
  reload,
  setReload,
}: RichTextProps) => {
  const uniqIdRef = React.useRef(uniqId);
  const isInit = React.useRef(true);
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);
  const isTimeoutRunning = React.useRef<boolean>(false);
  const skipTextSave = React.useRef<boolean>(false);
  const savedItemsRef = React.useRef<HTMLLIElement[]>([]);
  const [savedVersions, setSavedVersion] = React.useState<SavedText[]>([]);
  const [count, setCount] = React.useState<number>(0);
  const showCounter = React.useRef<boolean>(!!maxLength || !!minLength || !!charCounter);

  const textVersion = React.useRef<number>(1);
  const [field, meta] = useField(name);
  const valueRef = React.useRef(field.value);
  const error = violations && getViolationMessage(name, violations);
  const theme = "snow";
  const quillToolbar = React.useRef(null);
  const turndownService = React.useRef(new TurndownService()).current;
  const modules = {
    toolbar: {
      container: quillToolbar.current,
    },
  };
  const formats = ["bold", "underline", "italic", "header", "indent", "list", "link"];
  const { quill, quillRef } = useQuill({ modules, formats, theme, placeholder, readOnly: true });
  const getFromLocalStorage = (uId: string) => {
    const items = localStorage.getItem(uId);
    return items === null ? null : JSON.parse(items);
  };
  const isHtmlEmpty = (html: string) => {
    return html === "<p><br></p>" || html === "<p> </p>";
  };

  const countChar = () => {
    const plainText = quillRef.current.firstElementChild.innerText;
    const charCount = plainText.trim().split("").length;
    setCount(charCount);
  };

  const saveText = () => {
    if (!!!uniqIdRef.current || isInit.current) return;

    const quillInnerHtml = quillRef && quillRef.current && quillRef.current.firstElementChild.innerHTML;
    if (isHtmlEmpty(quillInnerHtml)) return;

    const item = {
      date: new Date(),
      value: quillInnerHtml,
      version: textVersion.current,
    };
    let itemsArr: SavedText[] = [];
    const savedItems = getFromLocalStorage(uniqIdRef.current);
    if (savedItems !== null) {
      itemsArr = savedItems;
      const lastItem = itemsArr[itemsArr.length - 1];
      if (lastItem.value === item.value) return;

      if (itemsArr.length >= maxStack) {
        itemsArr.shift();
      }
    }

    itemsArr.push(item);
    localStorage.setItem(uniqIdRef.current, JSON.stringify(itemsArr));
    setSavedVersion(itemsArr);

    textVersion.current += 1;
  };

  const saveTextLoop = () => {
    if (!!!uniqIdRef.current || isInit.current || isTimeoutRunning.current) return;
    if (skipTextSave.current) {
      skipTextSave.current = false;
      return;
    }
    if (timeoutRef.current !== null) clearTimeout(timeoutRef.current);

    isTimeoutRunning.current = true;
    timeoutRef.current = setTimeout(() => {
      saveText();
      isTimeoutRunning.current = false;
    }, saveSpeed);
  };

  const onBlurEvent = () => {
    if (timeoutRef.current !== null) clearTimeout(timeoutRef.current);
    if (isTimeoutRunning.current) {
      saveText();
      isTimeoutRunning.current = false;
    }
  };

  const onTextChange = React.useRef(() => {
    const txt = turndownService.turndown(quillRef.current.firstElementChild.innerHTML);
    if (valueRef.current !== txt) {
      isInit.current = false;
      valueRef.current = txt;
      setFieldValue(name, txt);
    }
    if (showCounter.current) {
      countChar();
    }
    saveTextLoop();
  }).current;

  const onSelectionChange = React.useRef((range: RangeStatic, oldRange: RangeStatic) => {
    if (range === null && oldRange !== null) {
      onBlurEvent();
    }
  }).current;

  const setContentEditor = React.useCallback(
    (txt: string, triggerBlur = true) => {
      if (!quill) {
        return { state: "error", pastedContent: null, message: "editor is null or undefined" };
      }
      quill.clipboard.dangerouslyPasteHTML(txt + "\n");
      if (triggerBlur) {
        quill.blur();
      }
      return { state: "success", pastedContent: txt };
    },
    [quill],
  );

  const onClickSavedVersionItem = (item: SavedText, _e: React.SyntheticEvent) => {
    skipTextSave.current = true;
    setContentEditor(item.value);
  };

  const getSavedVersionsItem = () => {
    const items = savedVersions.map((item) => {
      return (
        <li
          key={`item-${item.date}`}
          ref={(el) => {
            if (el && !savedItemsRef.current.includes(el)) {
              savedItemsRef.current.push(el);
            }
          }}
          onClick={(e: React.SyntheticEvent) => onClickSavedVersionItem(item, e)}
        >
          {format(new Date(item.date), "Ppp", { locale: fr })}
        </li>
      );
    });
    return items;
  };

  const getCounter = () => {
    const aboveMax = maxLength ? count > maxLength : false;
    const belowMin = minLength ? count < minLength : false;
    const hasError = aboveMax || belowMin;

    return (
      <>
        {minLength ? <span>{minLength} / </span> : null}
        <span className={cx({ [styles.countError]: hasError })}>{count}</span>
        {maxLength ? <span> / {maxLength}</span> : null}
      </>
    );
  };

  React.useEffect(() => {
    uniqIdRef.current = uniqId;
    if (!!!uniqId) return;
    const items = getFromLocalStorage(uniqId);
    setSavedVersion(items);
    if (items !== null && items.length) {
      textVersion.current = items[items.length - 1].version;
    }
  }, [uniqId]);

  React.useEffect(() => {
    if (quill) {
      quill.on("text-change", onTextChange);
      quill.on("selection-change", onSelectionChange);
    }

    return () => {
      if (quill) {
        quill.off("text-change", onTextChange);
        quill.off("selection-change", onSelectionChange);
      }
    };
  }, [quill, onTextChange, onSelectionChange]);

  React.useEffect(() => {
    if (quill && quill.getText() !== field.value && field.value === "") {
      setContentEditor(marked(field.value));
    }
  }, [quill, field.value, setContentEditor]);

  React.useEffect(() => {
    if (field.value && quill && quill.getText() !== field.value && reload) {
      quill.clipboard.dangerouslyPasteHTML(marked(field.value));
    }
    setReload && setReload(false);
  }, [quill, field.value, reload, setReload]);

  React.useEffect(() => {
    if (quill && isInit.current) {
      if (!!field.value) {
        setContentEditor(marked(field.value));
      } else if (!!uniqId && replaceIfEmpty) {
        const items = getFromLocalStorage(uniqId);
        if (!!items) {
          const lastItem = items[items.length - 1];
          setContentEditor(lastItem.value);
        }
      }
      quill.enable();
    }
  }, [quill, field.value, replaceIfEmpty, setContentEditor, uniqId]);

  return (
    <div
      className={cx("field", className, {
        error: (meta.touched && !!meta.error) || !!error,
        disabled: !!disabled,
      })}
    >
      <div className={styles.richTextHeader}>
        {label ? (
          <label className={`formLabel ${labelClassName}`}>
            {label} {isRequired ? "*" : null}
          </label>
        ) : null}
        <div ref={quillToolbar} className={styles.richTextToolbar}>
          <span className="ql-formats">
            <button className="ql-bold" title={t`RichTextEditor.formats.bold`}></button>
            <button className="ql-underline" title={t`RichTextEditor.formats.underline`}></button>
            <button className="ql-italic" title={t`RichTextEditor.formats.italic`}></button>
          </span>
          <span className="ql-formats">
            <button className="ql-list" value="ordered" title={t`RichTextEditor.formats.list.ordered`} />
            <button className="ql-list" value="bullet" title={t`RichTextEditor.formats.list.bullet`} />
          </span>
          <span className="ql-formats">
            <button className="ql-header" value="1" title={t`RichTextEditor.formats.header.1`} />
            <button className="ql-header" value="2" title={t`RichTextEditor.formats.header.2`} />
          </span>
          <span className="ql-formats">
            <button className="ql-indent" value="+1" title={t`RichTextEditor.formats.indent.plus`} />
            <button className="ql-indent" value="-1" title={t`RichTextEditor.formats.indent.minus`} />
          </span>
          <span className="ql-formats">
            <button className="ql-link" title={t`RichTextEditor.formats.link`} />
          </span>
          <span className="ql-formats">
            <button className="ql-clean" title={t`RichTextEditor.formats.clean`} />
          </span>
        </div>
      </div>
      <div
        ref={quillRef}
        id={uniqIdRef.current ? uniqIdRef.current : ""}
        className={cx(styles.richTextEditorContent, { [styles.smaller]: smaller })}
      ></div>
      {showCounter.current ? <div className={styles.counterContainer}>Caractères : {getCounter()}</div> : null}
      <div className={cx(styles.richTextEditorBottomContainer)}>
        <div className={styles.savedVersionsErrorContainer}>
          {savedVersions && savedVersions.length ? (
            <div className={styles.savedVersionContainer}>
              <div className={styles.savedVersionInnerContainer}>
                <span className={styles.savedVersionWording}>Versions sauvegardées</span>
                <ul className={styles.savedVersionList}>{getSavedVersionsItem()}</ul>
              </div>
            </div>
          ) : null}
          <ErrorMessage name={name} message={error} />
        </div>
        {!!inlineSubmitTrigger ? (
          <div className={styles.inlineSubmitTriggerContainer}>{inlineSubmitTrigger}</div>
        ) : null}
      </div>
    </div>
  );
};

export default RichTextEditor;
