import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";

import { CreateContact, DeleteContact, EditContact, SendFirstClientContactEmail } from "../../../actionTypes/contacts";
import { apiFailureAction } from "../actions";
import { createContactSuccessAction } from "./actions";
import * as apiClient from "./api";

export function* editContact(action: AnyAction): any {
  try {
    const response = yield call(apiClient.editContact, action.contact["@id"], action.contact);
    yield put({
      form: action.form,
      contact: response.data,
      type: EditContact.SUCCESS,
    });
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* createContact(action: any): any {
  try {
    const fc = [
      {
        multiple: false,
        name: "email",
        value: action.contact.email,
      },
    ];
    const contact = yield call(apiClient.getContact, fc);
    if (contact && contact.data["hydra:member"].length > 0) {
      const existingContact = contact.data["hydra:member"];
      action.contact["@id"] = existingContact[0]["@id"];
      const editAction = {
        contact: action.contact,
        type: EditContact.REQUEST,
        form: action.form,
      };
      yield call(editContact, editAction);
    } else {
      const response = yield call(apiClient.createContact, action.contact);
      yield put(createContactSuccessAction(response.data, action));
    }
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* deleteContact(action: AnyAction) {
  try {
    yield call(apiClient.deleteContact, action.iri);
    yield put({
      iri: action.iri,
      type: DeleteContact.SUCCESS,
    });
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* sendFirstClientContactEmail(action: AnyAction) {
  try {
    yield call(apiClient.sendFirstClientContactEmail, action.iri);
    yield put({
      iri: action.iri,
      type: SendFirstClientContactEmail.SUCCESS,
    });
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export default function* contactSaga() {
  yield takeLatest(CreateContact.REQUEST, createContact);
  yield takeLatest(EditContact.REQUEST, editContact);
  yield takeLatest(DeleteContact.REQUEST, deleteContact);
  yield takeLatest(SendFirstClientContactEmail.REQUEST, sendFirstClientContactEmail);
}
