import { t } from "@lingui/macro";
import * as React from "react";
import { Label } from "../.";
import { Tooltip } from "../../../components/Tooltip";
import { ClientContact } from "../../../interfaces/resources/clientContact";
import { applicationLabelTrans } from "../../../translations/constants/label";
import styles from "./index.module.scss";

interface Props {
  clientContact: ClientContact;
  className?: string;
}

const TooltipContent: React.FC<Props> = (props: Props) => {
  const { clientContact } = props;
  return (
    <div className={styles.tooltip}>
      <div>{`${clientContact.firstname} ${clientContact.lastname}`}</div>
    </div>
  );
};

export const ClientApplicationLabel: React.FC<Props> = (props: Props) => {
  const { clientContact, className } = props;

  return (
    <Tooltip content={<TooltipContent clientContact={clientContact} />}>
      <Label
        type="lightGreen"
        className={!!className ? className : undefined}
        content={t({ id: applicationLabelTrans.client_application })}
      />
    </Tooltip>
  );
};
