import { LOCATION_CHANGE } from "redux-first-history";
import { CreateContact, EditContact } from "../../../actionTypes/contacts";
import { Contact } from "../../../interfaces/resources/contact";

export function contact(state: Contact | null = null, action: any) {
  switch (action.type) {
    case CreateContact.SUCCESS:
    case EditContact.SUCCESS:
      return action.contact;
    case LOCATION_CHANGE:
      return null;
    default:
      return state;
  }
}
