import { t } from "@lingui/macro";

interface Const {
  tier: { [key: string]: string };
  status: { [key: string]: string };
  labelStatus: { [key: string]: string };
  level: { [key: string]: string };
  formule: { [key: string]: string };
  mode: { [key: string]: string };
}

const transOffer: Const = {
  tier: {
    tier_1: t`offer.tier.tier_1`,
    tier_2: t`offer.tier.tier_2`,
    tier_3: t`offer.tier.tier_3`,
    tier_4: t`offer.tier.tier_4`,
  },
  status: {
    standby_elinoi: t`offer.status.standbyElinoi`,
    activated: t`offer.status.activated`,
    desactivated: t`offer.status.desactivated`,
  },
  labelStatus: {
    activated: t`offer.labelStatus.activated`,
    closed: t`offer.labelStatus.closed`,
    desactivated: t`offer.labelStatus.desactivated`,
  },
  level: {
    junior: t`offer.level.junior`,
    senior: t`offer.level.senior`,
    manager: t`offer.level.manager`,
    head_of: t`offer.level.head_of`,
  },
  formule: {
    premium: t`offer.formule.premium`,
    classic: t`offer.formule.classic`,
    subscription: t`offer.formule.subscription`,
    replacement: t`offer.formule.replacement`,
  },
  mode: {
    recurring: t`offer.formule.recurring`,
    one_shot: t`offer.formule.oneShot`,
    spontaneous: t`offer.formule.spontaneous`,
  },
};

export default transOffer;
