import { CredentialResponse } from "@react-oauth/google";

import {
  ApiAuthenticationLogin,
  ApiAuthenticationLogout,
  ApiAuthenticationRefresh,
  GoogleAuthenticationLogin,
  SwitchOff,
  SwitchOn,
} from "../../../actionTypes/authentication";
import { removeTokens } from "../helpers";

export function loginAction(token: string) {
  return {
    token,
    type: ApiAuthenticationLogin.REQUEST,
  };
}

export function loginLocationAction(locationSearch: string) {
  return {
    locationSearch,
    type: ApiAuthenticationLogin.SUCCESS,
  };
}

export function refreshAction(refreshToken: string) {
  return {
    refreshToken,
    type: ApiAuthenticationRefresh.REQUEST,
  };
}

export function logoutAction() {
  return {
    type: ApiAuthenticationLogout.REQUEST,
  };
}

export const onSuccess = (response: CredentialResponse) => {
  return loginAction(response.credential || "");
};

export const onFailure = () => {
  return {
    type: GoogleAuthenticationLogin.FAILURE,
  };
};

export function switchOnAction(iri: string) {
  return {
    iri,
    type: SwitchOn.REQUEST,
  };
}

export function switchOffAction() {
  removeTokens("switch");
  return {
    type: SwitchOff.DEFAULT,
  };
}
