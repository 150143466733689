import { ActionType } from "../interfaces/api/actionType";

export const GetResponsibilityTypes: ActionType = {
  FAILURE: "GET_RESPONSIBILTITY_TYPES_FAILURE",
  REQUEST: "GET_RESPONSIBILTITY_TYPES_REQUEST",
  SUCCESS: "GET_RESPONSIBILTITY_TYPES_SUCCESS",
};

export const GetResponsibilityType: ActionType = {
  FAILURE: "GET_RESPONSIBILTITY_TYPE_FAILURE",
  REQUEST: "GET_RESPONSIBILTITY_TYPE_REQUEST",
  SUCCESS: "GET_RESPONSIBILTITY_TYPE_SUCCESS",
};
