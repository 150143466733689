import * as React from "react";
import { Message, Table } from "semantic-ui-react";
import { extractKeys } from "../../utils";
import TransformTableCell from "./CellBeautifier";

import style from "./index.module.scss";

interface Props<T> {
  values: T[];
}

const ErrorMessage = (
  <Message negative>
    <Message.Header>{"We're sorry we can't display your data"}</Message.Header>
    <p>Incorrect data provided</p>
  </Message>
);

function DataTable<T extends Record<string, unknown>>({ values }: Props<T>) {
  if (!Array.isArray(values) || values.length === 0) {
    return ErrorMessage;
  }

  const keys = extractKeys(values);

  const Header = (
    <Table.Header>
      <Table.Row>
        {keys.map((key) => (
          <Table.HeaderCell key={key}>{key}</Table.HeaderCell>
        ))}
      </Table.Row>
    </Table.Header>
  );

  const Cells = (
    <Table.Body>
      {values.map((item, i) => {
        return (
          <Table.Row key={`r-${i}`}>
            {keys.map((key, j) => {
              const value = (item as any)[key];
              return (
                <Table.Cell key={`c${j}`}>
                  <TransformTableCell value={value} />
                </Table.Cell>
              );
            })}
          </Table.Row>
        );
      })}
    </Table.Body>
  );

  return (
    <Table selectable className={style.table}>
      {Header}
      {Cells}
    </Table>
  );
}

export default DataTable;
