import { t } from "@lingui/macro";
import cx from "classnames";

import React, { FC } from "react";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";

import { initializeValues } from "../../../components/Offers/Shortlist/Form/TalentCardForm/talentCardHelper";
import { Application, applicationStatuses } from "../../../interfaces/resources/application";
import { TalentCard } from "../../../interfaces/resources/talentCard";
import { State } from "../../../interfaces/state";
import { createTalentCardAction } from "../../../services/api/talentCard/actions";
import globalStyles from "../../../styles/global.module.scss";
import styles from "./index.module.scss";

interface Props {
  application: Application;
  onCloseModal: () => void;
  editApplication: any;
}

export const SendTalentModal: FC<Props> = (props: Props) => {
  const { editApplication, application, onCloseModal } = props;
  const talent = application.talent;
  const currentUser = useSelector((state: State) => state.currentUser);
  const dispatch = useDispatch();
  const createTalentCard = (talentC: Partial<TalentCard>, form: string) => {
    dispatch(createTalentCardAction(talentC, form));
  };

  const closeModal = () => {
    if (typeof onCloseModal === "function") {
      onCloseModal();
    }
  };

  const onConfirmation = () => {
    if (currentUser && !application.talentCard) {
      createTalentCard(
        initializeValues(application, talent.talentProfile.lastQualification, talent.linkedInProfile, currentUser),
        `talent-card-${application["@id"]}`,
      );
    }
    editApplication(application["@id"], { status: applicationStatuses.shortlisted });
    closeModal();
  };

  return (
    <div className={styles.modal}>
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <h3 className={styles.title}>{t`SendTalentModal.title`}</h3>
        </div>
        <div>
          <p className={styles.text}>
            <ReactMarkdown
              components={{
                p: React.Fragment,
              }}
            >
              {t({
                id: "SendTalentModal.text",
                values: { firstname: application.talent.firstname, companyName: application.offer.company.name },
              })}
            </ReactMarkdown>
          </p>
        </div>
        <div className={styles.ctaListContainer}>
          <div>
            <button type="button" onClick={closeModal} className={cx(globalStyles.cta, globalStyles.alertCta)}>
              {t`Constant.cancel`}
            </button>
          </div>
          <div>
            <button type="button" className={cx(globalStyles.cta, globalStyles.primaryCta)} onClick={onConfirmation}>
              {t`SendTalentModal.cta.send`}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
