import { AnyAction } from "redux";
import { GetDesiredPlaces } from "../../../actionTypes/desiredPlaces";
import { DesiredPlace } from "../../../interfaces/resources/desiredPlace";

export function getDesiredPlacesAction(page?: number) {
  return {
    page,
    type: GetDesiredPlaces.REQUEST,
  };
}

export function getDesiredPlacesSuccessAction(desiredPlaces: DesiredPlace[], action: AnyAction) {
  return {
    desiredPlaces,
    page: action.page,
    type: GetDesiredPlaces.SUCCESS,
  };
}
