import { GetOfferApplication, GetOfferApplications } from "../../../../actionTypes/offers";
import { FilterContext } from "../../../../interfaces/api/filter";
import { Application } from "../../../../interfaces/resources/application";

export function getOfferApplicationsAction(iri: string, filterContext?: FilterContext, concat = false) {
  return {
    filterContext,
    iri,
    concat,
    type: GetOfferApplications.REQUEST,
  };
}

export function getOfferApplicationsSuccessAction(applications: Application[], concat = false) {
  return {
    applications,
    concat,
    type: GetOfferApplications.SUCCESS,
  };
}

export function getOfferApplicationAction(iri: string) {
  return {
    iri,
    type: GetOfferApplication.REQUEST,
  };
}

export function getOfferApplicationSuccessAction(application: Application, concat = false) {
  return {
    application,
    concat,
    type: GetOfferApplication.SUCCESS,
  };
}
