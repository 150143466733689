import * as React from "react";
import SelectComponent from "react-select";
import CreatableSelect from "react-select/creatable";
import { Props } from "react-select/src/Select";
import { ActionMeta, ValueType } from "react-select/src/types";
import { WrappedFieldInputProps } from "redux-form";
import { Form } from "semantic-ui-react";

export const defaultStyles: any = {
  control: (base: any) => ({
    ...base,
    "&:hover": {
      backgroundColor: "white",
      border: "1px solid #507bfc",
      borderRadius: "8px",
    },
    backgroundColor: "#f5f7fa",
    border: "1px solid #f5f7fa",
    borderRadius: "8px",
  }),
  menu: (base: any) => ({ ...base, zIndex: 3 }),
  multiValue: (base: any, state: any) => ({
    ...base,
    backgroundColor: state.data.isFixed ? "gray" : "#699bf0",
    border: "1px solid #699bf0",
    borderColor: state.data.isFixed ? "gray" : "#699bf0",
    borderRadius: "8px",
    color: "white",
    fontSize: "14px",
  }),
  multiValueLabel: (base: any, state: any) => ({
    ...base,
    color: "white",
    fontSize: "14px",
    padding: "5px 9px",
    paddingRight: state.data.isFixed ? "9px" : "0px",
  }),
  multiValueRemove: (base: any, state: any) => ({
    ...base,
    "&:hover": {
      backgroundColor: "#699bf0",
      color: "white",
      cursor: "pointer",
    },
    display: state.data.isFixed ? "none" : "flex",
  }),
  singleValue: (base: any) => ({
    ...base,
    color: "#707090",
  }),
};

export const readOnlyStyles: any = {
  control: () => ({
    border: "none",
  }),
  indicatorsContainer: () => ({
    display: "none",
  }),
  menu: () => ({
    display: "none",
  }),
  multiValue: (base: any) => ({
    ...base,
    backgroundColor: "#699bf0",
    border: "1px solid #699bf0",
    borderRadius: "8px",
    color: "white",
    fontSize: "12px",
    padding: "0 4px 0 2px",
  }),
  multiValueLabel: (base: any) => ({
    ...base,
    color: "white",
    fontSize: "12px",
    paddingRight: "0px",
  }),
  multiValueRemove: () => ({
    display: "none",
  }),
  singleValue: (base: any) => ({
    ...base,
    color: "#707090",
  }),
};

interface SelectFieldProps extends Props<{ label: string; value: string }> {
  className?: string;
  input: WrappedFieldInputProps;
  error?: boolean;
  label?: string;
  styles?: any;
}

type TValueType = ValueType<{ label: string; value: string; isFixed?: string }, any>;

const orderOptions = (values: any) => {
  return values.filter((v: any) => v.isFixed).concat(values.filter((v: any) => !v.isFixed));
};

const Select = ({
  className,
  error,
  input: { name, onChange, value },
  label,
  styles,
  ...rest
}: SelectFieldProps): JSX.Element => {
  const handleChange = (val: TValueType, actionMeta: ActionMeta<any>): void => {
    switch (actionMeta.action) {
      case "remove-value":
      case "pop-value":
        if (actionMeta.removedValue && actionMeta.removedValue.isFixed) {
          return;
        }
        break;
      case "clear":
        val = val && Array.isArray(val) ? val.filter((v) => v && v.isFixed) : val;
        break;
    }
    if (Array.isArray(val)) {
      val = orderOptions(val);
    }
    onChange(val);
  };
  return (
    <Form.Field name={name} error={error} className={className}>
      {label ? <label className="formLabel">{label}</label> : null}
      <SelectComponent
        {...rest}
        className={"select"}
        onChange={handleChange}
        value={value}
        styles={styles ? styles : defaultStyles}
        menuPlacement="auto"
      />
    </Form.Field>
  );
};

export const Creatable = ({
  className,
  error,
  input: { name, onChange, value },
  label,
  styles,
  ...rest
}: SelectFieldProps): JSX.Element => (
  <Form.Field name={name} error={error} className={className}>
    {label ? <label>{label}</label> : null}
    <CreatableSelect
      {...rest}
      className={"select"}
      onChange={onChange}
      value={value}
      styles={styles ? styles : defaultStyles}
    />
  </Form.Field>
);

export default Select;
