import { t } from "@lingui/macro";
import { useGoogleLogin } from "@react-oauth/google";
import { FC } from "react";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import logo from "../../images/logos/elinoi-logo-label-aqua.svg";
import { State } from "../../interfaces/state";
import { routes } from "../../routes";

import * as actions from "../../services/api/authentication/actions";
import styles from "./index.module.scss";

export const LoginComp: FC = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state: State) => state.auth);
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => dispatch(actions.loginAction(codeResponse.code)),
    flow: "auth-code",
  });

  if (auth.isAuthed) {
    return <Navigate to={routes.app.home} replace />;
  }

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.loginContainer}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="logo elinoï connect" />
        </div>
        <div className={styles.titleTextContainer}>
          <h4 className={styles.title}>{t`Login.Title`}</h4>
          <p className={styles.text}>{t`Login.text`}</p>
        </div>
        <div>
          <button className={styles.loginCta} onClick={() => login()}>
            Se connecter avec Google 🚀
          </button>
        </div>
      </div>
    </div>
  );
};
