import { AxiosResponse } from "axios";
import { push } from "connected-react-router";
import { AnyAction } from "redux";
import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";

import {
  CreateOffer,
  CreateOfferInternalView,
  CreateOfferTalentView,
  DeleteOffer,
  EditOffer,
  FilterByOfferName,
  GetOffer,
  GetOffers,
  SendShortlist,
} from "../../../actionTypes/offers";
import { GetTalentOffers } from "../../../actionTypes/talents";
import { HydraCollection } from "../../../interfaces/api/hydra-collection";

import { InterviewStep } from "../../../interfaces/resources/interviewStep";
import { Offer, OfferInternalView, OfferTalentView } from "../../../interfaces/resources/offer";
import { routes } from "../../../routes";

import { apiFailureAction } from "../actions";
import { extractPageFromIri } from "../helpers";
import {
  deleteOfferSuccessAction,
  editOfferSuccessAction,
  filterByOfferNameSuccessAction,
  getOffersSuccessAction,
  getOfferSuccessAction,
  getTalentOffersSuccessAction,
  sendShortlistSuccessAction,
} from "./actions";
import * as apiClient from "./api";
import { interviewStepsRequestAction } from "./interviewStep/actions";
import * as apiInterviewStep from "./interviewStep/api";
import { getOfferInternalViewAction } from "./offerInternalView/actions";
import * as apiOfferInternalView from "./offerInternalView/api";
import { getOfferTalentViewAction } from "./offerTalentView/actions";
import * as apiOfferTalentView from "./offerTalentView/api";

export function* createOffer(action: AnyAction) {
  try {
    const offerInternalView = action.offer.offerInternalView;
    delete action.offer.offerInternalView;

    const offerTalentView = action.offer.offerTalentView;
    delete action.offer.offerTalentView;

    const { data: apiOffer } = yield call(apiClient.createOffer, action.offer);
    let resOffer = { ...action.offer, "@id": apiOffer["@id"] };

    offerInternalView.offer = apiOffer["@id"];
    const { data: resOfferInternalView } = yield call(apiOfferInternalView.createOfferInternalView, offerInternalView);
    yield put({
      offerInternalView: resOfferInternalView,
      type: CreateOfferInternalView.SUCCESS,
    });
    resOffer = {
      ...resOffer,
      offerInternalView: resOfferInternalView,
    };

    offerTalentView.offer = apiOffer["@id"];
    const { data: resOfferTalentView } = yield call(apiOfferTalentView.createOfferTalentView, offerTalentView);
    yield put({
      offerTalentView: resOfferTalentView,
      type: CreateOfferTalentView.SUCCESS,
    });
    resOffer = {
      ...resOffer,
      offerTalentView: resOfferTalentView,
    };

    yield put({
      form: action.form,
      offer: {
        ...resOffer,
        offerInternalView: resOfferInternalView,
        offerTalentView: resOfferTalentView,
      },
      type: CreateOffer.SUCCESS,
    });

    if (!!action.redirectOnSuccess) {
      const url =
        action.redirectOnSuccess === routes.offers.show
          ? extractPageFromIri("offers", resOffer["@id"])
          : action.redirectOnSuccess;
      yield put(push("/"));
      yield put(push(url));
    }
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* editOffer(action: AnyAction): any {
  try {
    let interviewSteps: InterviewStep[] = [];
    if (action.offer.interviewSteps && action.offer.interviewSteps.length > 0) {
      const resOfferInterviewSteps = yield call(apiInterviewStep.getOfferInterviewSteps, action.offer["@id"]);
      const apiOfferInterviewSteps: InterviewStep[] = resOfferInterviewSteps.data["hydra:member"];

      const interviewStepsToDelete = apiOfferInterviewSteps.filter(
        (e: InterviewStep) => !action.offer.interviewSteps.some((f: InterviewStep) => f["@id"] === e["@id"]),
      );
      yield all(
        interviewStepsToDelete.map((step: InterviewStep) => call(apiInterviewStep.deleteOfferInterviewStep, step)),
      );

      for (const interviewStep of action.offer.interviewSteps) {
        interviewStep.offer = action.offer["@id"];
        let interviewStepResponse: AxiosResponse<InterviewStep>;
        if (interviewStep.hasOwnProperty("@id")) {
          interviewStepResponse = yield call(apiInterviewStep.editOfferInterviewStep, interviewStep);
        } else {
          interviewStepResponse = yield call(apiInterviewStep.createOfferInterviewStep, interviewStep);
        }
        interviewSteps = [...interviewSteps, interviewStepResponse.data];
      }
    }

    const resOfferInternalView: AxiosResponse<OfferInternalView> =
      action.offer && action.offer.offerInternalView
        ? yield call(apiOfferInternalView.editOfferInternalView, action.offer.offerInternalView)
        : {};

    const resOfferTalentView: AxiosResponse<OfferTalentView> =
      action.offer && action.offer.offerTalentView
        ? yield call(apiOfferTalentView.editOfferTalentView, action.offer.offerTalentView)
        : {};

    const response: AxiosResponse<Offer> = yield call(apiClient.editOffer, action.offer, action.validation);
    yield put(
      editOfferSuccessAction(
        action,
        response.data,
        interviewSteps,
        resOfferInternalView && (resOfferInternalView.data || undefined),
        resOfferTalentView && (resOfferTalentView.data || undefined),
      ),
    );
    if (!!action.redirectOnSuccess) {
      const url =
        action.redirectOnSuccess === routes.offers.show
          ? extractPageFromIri("offers", response.data["@id"])
          : action.redirectOnSuccess;
      yield put(push("/hacktrick")); // Small trick to force a location change event and destroy the form to start new
      yield put(push(url));
    }
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* filterByOfferName(action: AnyAction) {
  try {
    const response: AxiosResponse<{
      "hydra:member": Offer[];
      "hydra:totalItems": number;
      "hydra:view": any;
    }> = yield call(apiClient.getOffers, action.filterContext);
    yield put(filterByOfferNameSuccessAction(response.data["hydra:member"]));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* getOffer(action: AnyAction) {
  try {
    const response: AxiosResponse<Offer> = yield call(apiClient.getOffer, action.iri);
    const offer = response.data;

    yield put(interviewStepsRequestAction(offer["@id"]));

    yield put(getOfferTalentViewAction(offer.offerTalentView as unknown as string));
    yield put(getOfferInternalViewAction(offer.offerInternalView as unknown as string));

    yield put(getOfferSuccessAction(offer));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* getOffers(action: AnyAction) {
  try {
    const response: AxiosResponse<HydraCollection<Offer>> = yield call(apiClient.getOffers, action.filterContext);
    yield put(getOffersSuccessAction(response.data));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* getTalentOffers(action: AnyAction) {
  try {
    const response: AxiosResponse<HydraCollection<Offer>> = yield call(apiClient.getTalentOffers, action.talentIri);
    yield put(getTalentOffersSuccessAction(response.data));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* deleteOffer(action: AnyAction) {
  try {
    const response: AxiosResponse<any> = yield call(apiClient.deleteOffer, action.iri);
    yield put(deleteOfferSuccessAction(response.data));
    yield put(push(routes.offers.search));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* sendShortlist(action: AnyAction): any {
  try {
    const response = yield call(apiClient.sendShortlist, action.values);
    yield put(sendShortlistSuccessAction(action));
    yield put(push(extractPageFromIri("offers", response.data["@id"])));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export default function* offerSaga() {
  yield takeEvery(CreateOffer.REQUEST, createOffer);
  yield takeEvery(GetOffer.REQUEST, getOffer);
  yield takeLatest(EditOffer.REQUEST, editOffer);
  yield takeLatest(FilterByOfferName.REQUEST, filterByOfferName);
  yield takeLatest(GetOffers.REQUEST, getOffers);
  yield takeLatest(GetTalentOffers.REQUEST, getTalentOffers);
  yield takeLatest(DeleteOffer.REQUEST, deleteOffer);
  yield takeLatest(SendShortlist.REQUEST, sendShortlist);
}
