import { AnyAction } from "redux";
import {
  CreateCoreUser,
  DeleteCoreUser,
  EditCoreUser,
  GetCoreUserApplications,
  GetCoreUsers,
  GetCurrentUser,
} from "../../../actionTypes/coreUsers";
import { FilterContext } from "../../../interfaces/api/filter";
import { Application } from "../../../interfaces/resources/application";
import { CoreUser } from "../../../interfaces/resources/coreUser";

export function getCurrentUserAction() {
  return {
    type: GetCurrentUser.REQUEST,
  };
}

export function getCurrentUserSuccessAction(coreUser: CoreUser) {
  return {
    coreUser,
    type: GetCurrentUser.SUCCESS,
  };
}

export function getCoreUsersAction() {
  return {
    type: GetCoreUsers.REQUEST,
  };
}

export function getCoreUsersSuccessAction(coreUsers: CoreUser[]) {
  return {
    coreUsers,
    type: GetCoreUsers.SUCCESS,
  };
}

export function deleteCoreUserAction(iri: string) {
  return {
    iri,
    type: DeleteCoreUser.REQUEST,
  };
}

export function deleteCoreUserSuccessAction() {
  return {
    type: DeleteCoreUser.SUCCESS,
  };
}

export function createCoreUserAction(coreUser: CoreUser) {
  return {
    coreUser,
    type: CreateCoreUser.REQUEST,
  };
}

export function createCoreUserSuccessAction(coreUser: CoreUser) {
  return {
    coreUser,
    type: CreateCoreUser.SUCCESS,
  };
}

export function editCoreUserAction(coreUser: Partial<CoreUser>, form?: string, redirectOnSuccess?: boolean | string) {
  return {
    coreUser,
    form,
    redirectOnSuccess,
    type: EditCoreUser.REQUEST,
  };
}

export function editCoreUserSuccessAction(coreUser: CoreUser, action: AnyAction) {
  return {
    coreUser,
    form: action.form,
    type: EditCoreUser.SUCCESS,
  };
}

export function getCoreUserApplicationsAction(coreUser: CoreUser, filterContext?: FilterContext, page?: number) {
  return {
    coreUser,
    filterContext,
    page,
    type: GetCoreUserApplications.REQUEST,
  };
}

export function getCoreUserApplicationsSuccessAction(applications: Application[]) {
  return {
    applications,
    type: GetCoreUserApplications.SUCCESS,
  };
}
