import { t } from "@lingui/macro";
import cx from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import {
  Field,
  FieldArray,
  FieldArrayFieldsProps,
  InjectedFormProps,
  reduxForm,
  WrappedFieldArrayProps,
} from "redux-form";
import { Accordion, Button, Form, Icon, Message } from "semantic-ui-react";

import { OFFER_ADVICE_FORM, OFFER_MAIN_FORM } from "..";

import { GetInterviewStepTypes } from "../../../../actionTypes/interviewStepType";

import OfferAdviceFormModalContainer from "../../../../containers/Offers/OfferForm/OfferAdviceFormContainer/OfferAdviceFormModalContainer";
import { ApiError } from "../../../../interfaces/api/error";
import { ApiRequest } from "../../../../interfaces/api/request";
import { ApiSuccess } from "../../../../interfaces/api/success";
import { Violation } from "../../../../interfaces/api/violation";
import { InterviewStep } from "../../../../interfaces/resources/interviewStep";
import { InterviewStepType } from "../../../../interfaces/resources/interviewStepType";
import { Offer, OfferAdviceForm, OfferAdviceFormInterviewStep } from "../../../../interfaces/resources/offer";

import { routes } from "../../../../routes";
import transInterviewStepType from "../../../../translations/constants/interviewStepType";

import Checkbox from "../../../Form/Checkbox";
import RichTextEditor from "../../../Form/RichTextEditor";
import { useGetResourceHook } from "../../../Utils/hook";

import "./index.scss";

interface Props {
  apiErrors: ApiError[];
  apiPendingRequests: ApiRequest[];
  apiSuccess: ApiSuccess[];
  getInterviewStepTypes: () => void;
  interviewStepTypes: InterviewStepType[];
  offer: Offer;
  submitOffer: (req: OfferAdviceForm, form?: string, redirectOnSuccess?: boolean | string) => void;
}

const InterviewStepsComponent: React.FC<
  WrappedFieldArrayProps<InterviewStep> & {
    interviewStepTypes: InterviewStepType[];
    stepTemplate: InterviewStep;
    uniqId?: string | null;
  }
> = ({
  fields,
  interviewStepTypes,
  stepTemplate,
  uniqId,
}: WrappedFieldArrayProps<InterviewStep> & {
  interviewStepTypes: InterviewStepType[];
  stepTemplate: InterviewStep;
  uniqId?: string | null;
}) => {
  const [activeIndex, setActiveIndex] = React.useState<number>(0);

  React.useEffect(() => {
    if (!fields.get(activeIndex)) {
      setActiveIndex(fields.length);
    }
  }, [activeIndex, fields, setActiveIndex]);

  React.useEffect(() => {
    if (fields.length === 0) {
      fields.push(stepTemplate);
    }
  }, [fields, stepTemplate]);

  const handleAccordion = (_e: any, { index }: any) => {
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const addInterviewStep = () => {
    fields.push(stepTemplate);
    setActiveIndex(fields.length);
  };

  const removeInterviewStep = (index: number) => {
    fields.remove(index);
    if (index === activeIndex || activeIndex === fields.length - 1) {
      setActiveIndex(activeIndex - 1);
    }
  };

  return (
    <Accordion fluid>
      {fields.map((name: string, index: number, flds: FieldArrayFieldsProps<InterviewStep>) => {
        const fld = flds.get(index);
        return (
          <div className="formPart" key={index}>
            <Accordion.Title active={activeIndex === index} index={index} onClick={handleAccordion}>
              <Icon name="dropdown" />
              {index + 1} - {fld.title}
              {fields.length > 1 ? (
                <Icon
                  name="close"
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.stopPropagation();
                    removeInterviewStep(index);
                  }}
                />
              ) : null}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === index}>
              <div>
                <Field name={`${name}.title`} component={Form.Input} label={t`offer.interviewStep.title`} />
                <div className="field inline">
                  <Form.Group grouped className="radio">
                    {interviewStepTypes
                      && interviewStepTypes.map(
                        (option: InterviewStepType): React.ReactElement => (
                          <Field
                            name={`${name}.interviewStepTypes`}
                            component={Checkbox}
                            key={option.label}
                            label={
                              <Button
                                className={cx({
                                  active:
                                    fld.interviewStepTypes
                                    && fld.interviewStepTypes.some(
                                      (e: InterviewStepType): boolean => e.label === option.label,
                                    ),
                                })}
                              >
                                {t({ id: transInterviewStepType.label[option.label] })}
                              </Button>
                            }
                            getOptionValue={(opt: InterviewStepType) => opt.label}
                            item={option}
                            options={interviewStepTypes}
                          />
                        ),
                      )}
                  </Form.Group>
                </div>
                <div className="formGroup formTextAreaGroup">
                  <Field
                    name={`${name}.description`}
                    uniqId={uniqId ? `${uniqId}-${name}.description` : null}
                    component={RichTextEditor}
                    label={t`offer.interviewStep.description`}
                    maxLength={300}
                  />
                </div>
              </div>
            </Accordion.Content>
          </div>
        );
      })}
      <div className="formBtnContainer">
        <Button secondary className="secondaryButton" onClick={addInterviewStep}>
          <Icon name="plus square outline" />
          {t`OfferAdviceForm.addInterviewStep`}
        </Button>
      </div>
    </Accordion>
  );
};

const AdviceForm: React.FunctionComponent<InjectedFormProps<OfferAdviceForm, Props> & Props> = ({
  apiErrors,
  apiPendingRequests,
  apiSuccess,
  getInterviewStepTypes,
  handleSubmit,
  interviewStepTypes,
  submitOffer,
  offer,
}: InjectedFormProps<OfferAdviceForm, Props> & Props): React.ReactElement => {
  useGetResourceHook(
    apiErrors,
    apiPendingRequests,
    apiSuccess,
    GetInterviewStepTypes,
    interviewStepTypes,
    getInterviewStepTypes,
    [],
  );

  const [error, setError] = React.useState<boolean | string>(false);
  const [errors, setErrors] = React.useState<Violation[]>([]);
  const [success, setSuccess] = React.useState(false);
  const [modalIsVisible, setModalIsVisible] = React.useState(false);
  const [modalOnProceed, setModalOnProceed] = React.useState<() => void>();
  const uniqId = offer ? `${OFFER_ADVICE_FORM}-${offer["@id"]}` : null;

  React.useEffect(() => {
    if (apiSuccess.some((e: ApiSuccess) => OFFER_ADVICE_FORM === e.form)) {
      setError(false);
      setErrors([]);
      setSuccess(false);
    }

    if (apiErrors.some((e: ApiError) => OFFER_ADVICE_FORM === e.form)) {
      const apiError = apiErrors.find((e: ApiError) => OFFER_ADVICE_FORM === e.form);
      if (apiError) {
        setError(apiError.payload.message);
        setErrors(apiError.payload.violations || []);
        setSuccess(false);
        setModalIsVisible(false);
      }
    }
  }, [apiSuccess, apiPendingRequests, apiErrors]);

  const handleBeforeSubmit = (cb: (values: OfferAdviceForm) => void) => (values: any) => {
    setModalOnProceed(() => () => handleSubmit(cb)(values));
    setModalIsVisible(true);
  };

  const handleSubmitAndGoToProfile = (values: OfferAdviceForm) => {
    return submitOffer(
      {
        ...values,
        interviewSteps: values.interviewSteps!.map((e: OfferAdviceFormInterviewStep, i: number) => ({
          ...e,
          step: i + 1,
        })),
      },
      OFFER_ADVICE_FORM,
      routes.offers.show,
    );
  };

  const handleSubmitAndCreateNew = (values: OfferAdviceForm) => {
    return submitOffer(
      {
        ...values,
        interviewSteps: values.interviewSteps!.map((e: OfferAdviceFormInterviewStep, i: number) => ({
          ...e,
          step: i,
        })),
      },
      OFFER_ADVICE_FORM,
      routes.offers.new,
    );
  };

  return (
    <>
      <div className="offerFormAdviceContainer">
        <Form
          loading={
            apiPendingRequests.length > 0
            && apiPendingRequests.some((e: ApiRequest) => [OFFER_ADVICE_FORM, OFFER_MAIN_FORM].includes(String(e.form)))
          }
          success={success}
          error={!!error || errors.length > 0}
        >
          <div className="formPart">
            <div className="formGroup">
              <Field
                name="offerTalentView.ytProcess"
                component={Form.Input}
                label={<label className="formLabel">{t`offer.offerTalentView.ytProcess`}</label>}
                error={errors.some((item: Violation) => "ytProcess" === item.propertyPath)}
              />
            </div>
          </div>
          <div className="formPart">
            <h2 className="formPartTitle">{t`AdviceForm.title`}</h2>
            <Message
              success
              header={t`Talent.success.header`}
              content={t`Talent.success.content`}
              icon="check circle"
            />
            {error && (
              <Message
                error
                header={t`Offer.error.header`}
                content={
                  <div>
                    {t`Offer.error.content`}{" "}
                    {(error as string).split(".").map((e, i) => (!!e ? <Message.Item content={e} key={i} /> : null))}
                  </div>
                }
                icon="times circle"
              />
            )}
            <FieldArray
              name="interviewSteps"
              component={InterviewStepsComponent as any}
              props={{
                uniqId,
                interviewStepTypes,
                stepTemplate: {
                  description: "",
                  interviewStepTypes: [],
                  offer,
                  step: 0,
                  title: "",
                },
              }}
            />
          </div>
          <div className="formPart">
            <div className="formGroup formTextAreaGroup">
              <Field
                name="offerTalentView.elinoiAdvice"
                uniqId={uniqId ? `${uniqId}-offerTalentView.elinoiAdvice` : null}
                component={RichTextEditor}
                label={t`offer.elinoiAdvice`}
              />
            </div>
          </div>
          <div className="formBtnContainer">
            <div className="cancelBtncontainer">
              <Button negative as={Link} to={routes.offers.search} icon="times" content={t`Constant.cancel`} />
            </div>
            <div className="validateBtnsContainer">
              <Button
                secondary
                onClick={handleBeforeSubmit(handleSubmitAndGoToProfile)}
                content={t`OfferAdviceForm.fields.submitAndGoToProfile`}
              />
              <Button
                primary
                onClick={handleBeforeSubmit(handleSubmitAndCreateNew)}
                content={t`OfferAdviceForm.fields.submitAndGoToNewOfferForm`}
              />
            </div>
          </div>
        </Form>
      </div>
      <OfferAdviceFormModalContainer
        open={modalIsVisible}
        onProceed={modalOnProceed}
        onClose={() => setModalIsVisible(false)}
      />
    </>
  );
};

export default reduxForm<OfferAdviceForm, Props>({
  form: "OFFER_ADVICE_FORM",
})(AdviceForm);
