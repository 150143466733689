import { t } from "@lingui/macro";
import { format, parseISO } from "date-fns";
import * as React from "react";
import { Label } from "../.";
import { Tooltip } from "../../../components/Tooltip";
import { ApplicationAccepted } from "../../../interfaces/resources/application";
import styles from "./index.module.scss";

interface Props {
  applicationsAccepted: ApplicationAccepted[];
  className?: string;
}

const TooltipContent: React.FC<Props> = (props: Props) => {
  const { applicationsAccepted } = props;
  return (
    <div className={styles.tooltip}>
      {applicationsAccepted.map((apAcpt) => {
        const apDate = apAcpt.createdAt;
        const dateFormatted = format(parseISO(apDate), "dd/MM/yyyy");

        return (
          <div key={apAcpt["@id"]} className={styles.item}>
            <div className={styles.itemName}>
              {dateFormatted} - {apAcpt.offer?.name}
            </div>
            <div>
              AM :
              {apAcpt.coreUsers && apAcpt.coreUsers.length > 0
                ? apAcpt.coreUsers.map((coreUser) => <p key={coreUser["@id"]}>{coreUser.firstname}</p>)
                : "Aucun AM"}
            </div>
            <div>TIM : {apAcpt.tim?.firstname || "Aucun TIM"}</div>
          </div>
        );
      })}
    </div>
  );
};

export const ApplicationAcceptedLabel: React.FC<Props> = (props: Props) => {
  const { applicationsAccepted, className } = props;
  const lastAp = applicationsAccepted[0];
  const lastApDate = lastAp.createdAt;
  const dateFormatted = lastApDate ? format(parseISO(lastApDate), "dd/MM/yyyy") : null;

  return (
    <Tooltip content={<TooltipContent applicationsAccepted={applicationsAccepted} />}>
      <Label
        type="green"
        content={`${t`Constant.application.status.accepted`} (${dateFormatted})`}
        className={!!className ? className : undefined}
      />
    </Tooltip>
  );
};
