import { AnyAction } from "redux";
import { LOCATION_CHANGE } from "redux-first-history";
import { EditApplication } from "../../actionTypes/applications";
import { EditContact } from "../../actionTypes/contacts";
import { GetTalentCard } from "../../actionTypes/talentCards";
import { GetTalentProfile } from "../../actionTypes/talentProfile";
import { GetTalentQualification } from "../../actionTypes/talentQualification";
import { ApiError } from "../../interfaces/api/error";
import { ApiRequest } from "../../interfaces/api/request";
import { ApiSuccess } from "../../interfaces/api/success";
import { getFailType, getRequestType, getSuccessType } from "./helpers";

export function apiErrors(state: ApiError[] = [], action: AnyAction) {
  if (action.type === getFailType(action) && action.payload) {
    return [
      ...state,
      {
        ...(action as ApiError),
      },
    ];
  } else if (action.type === getRequestType(action) && action.form) {
    return state.filter((e: ApiError) => e.form !== action.form);
  } else if (LOCATION_CHANGE === action.type) {
    return [];
  } else if ("@@redux-form/DESTROY" === action.type) {
    return state.filter((e: ApiError) => !e.form || action.meta.form.indexOf(e.form) === -1);
  }
  return state;
}

export function apiSuccess(state: ApiSuccess[] = [], action: AnyAction) {
  if (action.type === getSuccessType(action)) {
    return [
      ...state,
      {
        ...(action as ApiSuccess),
      },
    ];
  } else if (action.type === getRequestType(action) && action.form) {
    return state.filter((e: ApiSuccess) => e.form !== action.form);
  } else if (LOCATION_CHANGE === action.type) {
    return [];
  } else if ("@@redux-form/DESTROY" === action.type) {
    return state.filter((e: ApiSuccess) => !e.form || action.meta.form.indexOf(e.form) === -1);
  }
  return state;
}

const validateReq = (req: ApiRequest, action: AnyAction) => {
  const validations: boolean[] = [];

  validations.push(req.type === getRequestType(action));

  switch (action.type) {
    case GetTalentProfile.SUCCESS:
      validations.push(req.talentProfile === action.talentProfile["@id"]);
      break;
    case GetTalentProfile.FAILURE:
      validations.push(req.talentProfile === action.talentProfile);
      break;
    case GetTalentCard.SUCCESS:
      validations.push(req.iri === action.talentCard["@id"]);
      break;
    case GetTalentCard.FAILURE:
      validations.push(req.iri === action.iri);
      break;
    case GetTalentQualification.SUCCESS:
      validations.push(req.qualificationIri === action.talentQualification["@id"]);
      break;
    case GetTalentQualification.FAILURE:
      validations.push(req.qualificationIri === action.talentQualificationIri);
      break;
  }
  return !validations.every((e: boolean) => e);
};

export function apiPendingRequests(state: ApiRequest[] = [], action: AnyAction) {
  if (action.type === getRequestType(action)) {
    return [...state, action];
  } else if (action.type === getSuccessType(action) || action.type === getFailType(action)) {
    return [
      ...state.filter((req) => {
        if (
          ([EditContact.FAILURE, EditContact.SUCCESS].includes(action.type)
            && req.contact["@id"] === action.contact["@id"])
          || ([EditApplication.FAILURE, EditApplication.SUCCESS].includes(action.type)
            && req.req
            && req.req.offer
            && req.req.talent
            && req.req.offer["@id"] === action.application.offer["@id"]
            && req.req.talent["@id"] === action.application.talent["@id"])
        ) {
          return false;
        }
        return validateReq(req, action);
      }),
    ];
  }

  return state;
}
