import { t } from "@lingui/macro";
import React, { useEffect, useState } from "react";
import { DropzoneState, useDropzone } from "react-dropzone";
import { WrappedFieldInputProps } from "redux-form";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";
import toBase64, { Base64File } from "../../Utils/ToBase64";

interface Props {
  input: WrappedFieldInputProps;
  formatValue?: (value: any) => any;
  getUrlValue?: (value: any) => any;
}

const getColor = (props: DropzoneState) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive || props.isFocused) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props: any) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const thumbsContainer: any = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb: any = {
  border: "1px solid #eaeaea",
  borderRadius: 2,
  boxSizing: "border-box",
  display: "inline-flex",
  height: 100,
  marginBottom: 8,
  marginRight: 8,
  padding: 4,
  width: 100,
};

const thumbInner = {
  backgroundColor: "#ffffff",
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  height: "100%",
  width: "auto",
};

const Dropzone: React.FunctionComponent<Props> = ({ input, getUrlValue, formatValue }: Props) => {
  const [files, setFiles] = useState<Base64File[]>([]);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const removeFile = (file: any) => () => {
    setTimeout(() => {
      const newFiles = files.filter((elem: any) => {
        if (file.hasOwnProperty("@id")) {
          return file["@id"] !== elem["@id"];
        }
        if (getUrlValue && getUrlValue(file)) {
          return getUrlValue(file) !== getUrlValue(elem);
        }
        return file;
      });
      setIsInitialized(newFiles.length > 0 ? false : true);
      setFiles(newFiles);
    }, 400);
  };

  const { getInputProps, getRootProps, isDragAccept, isDragActive, isDragReject, isFocused } = useDropzone({
    accept: "image/jpeg, image/png",
    onDropAccepted: (acceptedFiles: File[]) => {
      toBase64(acceptedFiles).then((result: Base64File[]) => {
        const filesURL: Base64File[] = [];
        result.forEach((file: Base64File) => {
          filesURL.push(formatValue ? formatValue(file.base64URL) : file.base64URL);
        });
        setFiles([...files, ...filesURL]);
      });
    },
  });

  const thumbs = files.map((file: any) => {
    return (
      <div style={thumb} key={(getUrlValue && getUrlValue(file)) || file["@id"]}>
        <div style={thumbInner}>
          <Icon style={{ position: "absolute", cursor: "pointer" }} name="times circle" onClick={removeFile(file)} />
          <img alt={file.name} src={getUrlValue && getUrlValue(file)} style={img} />
        </div>
      </div>
    );
  });

  useEffect(() => {
    if (files.length === 0 && !isInitialized && input.value) {
      setFiles(input.value);
    }

    input.onChange(files);
  }, [isInitialized, files, input]);

  return (
    <section className="container">
      <Container
        {...getRootProps({
          className: "dropzone",
          isDragAccept,
          isDragActive,
          isDragReject,
          isFocused,
        })}
      >
        <input {...getInputProps()} />
        <p>{t`Dropzone.text`}</p>
      </Container>
      <aside style={thumbsContainer}>{thumbs}</aside>
    </section>
  );
};

export default Dropzone;
