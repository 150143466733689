import { t } from "@lingui/macro";
import cx from "classnames";
import { Field, Formik, Form } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Message } from "semantic-ui-react";
import { GetCoreUsers } from "../../../actionTypes/coreUsers";

import Yup from "../../../components/App/Yup";
import Input from "../../../components/Form/Formik/Input";
import { Phone } from "../../../components/Form/Formik/Phone";
import Radio from "../../../components/Form/Formik/Radio";
import { SearchingReasonRadio } from "../../../components/Form/Formik/SearchingReasonRadio";
import Toggle from "../../../components/Form/Formik/Toggle";
import { useApiSelector, useGetResourceHook, usePrevious } from "../../../components/Utils/hook";
import { Violation } from "../../../interfaces/api/violation";
import { CoreUser, coreUserRoles } from "../../../interfaces/resources/coreUser";
import { TalentEditionFormProps, searchStatuses, searchStatusReasons } from "../../../interfaces/resources/talent";
import { State } from "../../../interfaces/state";
import { getCoreUsersAction } from "../../../services/api/coreUser/actions";
import { extractIdFromIri } from "../../../services/api/helpers";
import { editTalentAction } from "../../../services/api/talent/actions";
import globalStyles from "../../../styles/global.module.scss";
import { transTalent } from "../../../translations/constants/talent";

import styles from "./index.module.scss";

interface Props {
  closeModal: () => void;
}

export const EditTalentModal: FC<Props> = ({ closeModal }: Props) => {
  const EDIT_TALENT_FORM = "talent";
  const { apiErrors, apiSuccess, apiPendingRequests } = useApiSelector();
  const dispatch = useDispatch();
  const currentUser = useSelector((state: State) => state.currentUser);
  const talent = useSelector((state: State) => state.talent);
  const talentProfile = useSelector((state: State) => state.talentProfile);
  const coreUsers = useSelector((state: State) => state.coreUsers);
  const [error, setError] = useState<boolean | string>(false);
  const [errors, setErrors] = useState<Violation[]>([]);
  const [success, setSuccess] = useState(false);
  const [jobFoundCompany, setJobFoundCompany] = useState<string>(
    talent?.lastTalentSearchStatusEvent?.data
      ? JSON.parse(talent?.lastTalentSearchStatusEvent?.data)?.jobFoundCompany
      : "",
  );
  const [talentManager, setTalentManager] = React.useState<CoreUser | null | undefined>(talent?.talentManager);
  const editTalent = (req: TalentEditionFormProps) => {
    if (talent) {
      dispatch(editTalentAction(talent["@id"], req, EDIT_TALENT_FORM));
    }
  };
  const prevApiSuccess = usePrevious(apiSuccess);
  const prevApiErrors = usePrevious(apiErrors);
  const initialValues = {
    "@id": talent?.["@id"],
    email: talent?.email || "",
    firstname: talent?.firstname || "",
    lastname: talent?.lastname || "",
    linkedInProfile: {
      "@id": talent?.linkedInProfile["@id"],
      headline: talent?.linkedInProfile.headline || "",
      url: talent?.linkedInProfile.url || "",
    },
    phone: talent?.phone || "",
    prospect: talent?.prospect || "",
    searchComplements: talent?.searchComplements || "",
    searchStatus: talent?.searchStatus || "",
    sendProspect: talent?.sendProspect || false,
    talentProfile: {
      "@id": talentProfile ? talentProfile["@id"] : "",
      golden: (talentProfile ? talentProfile.golden : false) || false,
      currentJob: talentProfile?.currentJob || "",
      currentCompany: talentProfile?.currentCompany || "",
    },
    talentManager,
    searchStatusReason: !!talent?.lastTalentSearchStatusEvent?.reason
      ? talent.lastTalentSearchStatusEvent.reason
      : null,
    searchStatusData: {
      doer: "coreUser",
      doerId: currentUser && extractIdFromIri(currentUser["@id"]),
    },
  };
  const TalentEditionSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    firstname: Yup.string().required(),
    lastname: Yup.string().required(),
    linkedInProfile: Yup.object().shape({
      headline: Yup.string(),
      url: Yup.string().url(),
    }),
    phone: Yup.string().required(),
    searchComplements: Yup.string(),
    searchStatus: Yup.string().required(),
  });

  useGetResourceHook(
    apiErrors,
    apiPendingRequests,
    apiSuccess,
    GetCoreUsers,
    coreUsers,
    () => dispatch(getCoreUsersAction()),
    [],
  );

  useEffect(() => {
    if (
      prevApiSuccess
      && !prevApiSuccess.some((e) => EDIT_TALENT_FORM === e.form)
      && apiSuccess
      && apiSuccess.some((e) => EDIT_TALENT_FORM === e.form)
    ) {
      setError(false);
      setErrors([]);
      setSuccess(true);
      setTimeout(closeModal, 1000);
    }
  }, [apiSuccess, prevApiSuccess, closeModal, setError, setErrors, setSuccess]);

  useEffect(() => {
    if (
      (!prevApiErrors || !prevApiErrors.some((e) => EDIT_TALENT_FORM === e.form))
      && apiErrors
      && apiErrors.some((e) => EDIT_TALENT_FORM === e.form)
    ) {
      const apiError = apiErrors.find((e) => EDIT_TALENT_FORM === e.form);
      if (apiError) {
        setErrors(apiError.payload.violations || []);
        setError(apiError.payload.message);
        setSuccess(false);
      }
    }
  }, [apiErrors, prevApiErrors, setError, setErrors, setSuccess]);

  return (
    <div className={styles.modal}>
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <h1 className={styles.title}>{t`TalentEditionForm.title`}</h1>
        </div>
        <Formik
          onSubmit={(values: TalentEditionFormProps, { setSubmitting }): void => {
            editTalent({
              ...values,
              searchStatusReason:
                values.searchStatus !== searchStatuses.not_searching ? null : values.searchStatusReason,
              searchStatusData:
                values.searchStatus === searchStatuses.not_searching
                && values.searchStatusReason === searchStatusReasons.job_found
                  ? {
                      ...values.searchStatusData,
                      jobFoundCompany,
                    }
                  : {
                      ...values.searchStatusData,
                    },
              talentManager,
            });
            setTimeout(() => setSubmitting(false), 500);
          }}
          validationSchema={TalentEditionSchema}
          initialValues={initialValues}
        >
          {({ handleSubmit, isSubmitting, values, setFieldValue }): JSX.Element => (
            <>
              <Form className={cx("form", styles.form)} onSubmit={handleSubmit}>
                {success || error ? (
                  <div className={styles.messagesContainer}>
                    {success ? (
                      <Message
                        success
                        header={t`PropsTalentEditionFormProps.success.header`}
                        content={t`PropsTalentEditionFormProps.success.content`}
                        icon="check circle"
                      />
                    ) : null}
                    {error ? (
                      <Message
                        error
                        header={t`PropsTalentEditionFormProps.error.header`}
                        content={
                          <div>
                            {t`PropsTalentEditionFormProps.error.content`}: {error}
                          </div>
                        }
                        icon="times circle"
                      />
                    ) : null}
                  </div>
                ) : null}
                <div className="formGroup formInlineGroup double">
                  <Field
                    name="firstname"
                    component={Input}
                    label={t`contact.firstname`}
                    placeholder={t`contact.firstname.placeholder`}
                    violation={errors}
                  />
                  <Field
                    name="lastname"
                    component={Input}
                    label={t`contact.lastname`}
                    placeholder={t`contact.lastname.placeholder`}
                    violation={errors}
                  />
                </div>
                <div className="formGroup formInlineGroup double">
                  <Field
                    name="email"
                    component={Input}
                    label={t`contact.email`}
                    placeholder={t`contact.email.placeholder`}
                    violation={errors}
                  />
                  <Field
                    name="phone"
                    component={Phone}
                    label={t`contact.phone`}
                    placeholder={t`contact.phone.placeholder`}
                    violation={errors}
                  />
                </div>
                <div className="formGroup formInlineGroup double">
                  <Field
                    name="talentProfile.currentJob"
                    component={Input}
                    label={t`TalentEditionForm.currentJob.label`}
                    violation={errors}
                    isRequired={false}
                  />
                  <Field
                    name="talentProfile.currentCompany"
                    component={Input}
                    label={t`TalentEditionForm.currentCompany.label`}
                    violation={errors}
                    isRequired={false}
                  />
                </div>
                <div className="formGroup">
                  <Field
                    name="linkedInProfile.headline"
                    component={Input}
                    label={t`linkedInProfile.headline`}
                    placeholder={t`linkedInProfile.headline`}
                    violation={errors}
                    isRequired={false}
                  />
                </div>
                <div className="formGroup">
                  <Field
                    name="linkedInProfile.url"
                    component={Input}
                    label={t`linkedInProfile.url`}
                    placeholder={t`linkedInProfile.url`}
                    violation={errors}
                    isRequired={false}
                  />
                </div>
                <div className="formGroup">
                  <Field
                    name="talentProfile.golden"
                    component={Toggle}
                    label={t`TalentEditionForm.isGolden`}
                    className="field"
                  />
                </div>
                <div className="formGroup">
                  <label className="formLabel">Tim :</label>
                  <div className={styles.timListContainer}>
                    {coreUsers.reduce((arr: JSX.Element[], coreUser: CoreUser) => {
                      if (coreUser.roles.includes(coreUserRoles.tim) && coreUser.lastname && coreUser.enabled) {
                        return [
                          ...arr,
                          <div key={`assign-tim-cta-${coreUser["@id"]}`} className={styles.ctaContainer}>
                            <button
                              type="button"
                              onClick={() => setTalentManager(coreUser)}
                              className={cx(globalStyles.cta, globalStyles.primaryCta, globalStyles.inverted, {
                                [styles.activeTimCta]: talentManager?.["@id"] === coreUser?.["@id"],
                              })}
                            >
                              {`${coreUser.firstname} ${coreUser.lastname.charAt(0)}.`}
                            </button>
                          </div>,
                        ];
                      } else {
                        return arr;
                      }
                    }, [])}
                  </div>
                </div>
                <div className="formGroup">
                  <Field
                    name="searchStatus"
                    component={Radio}
                    className="inline"
                    label={t`talentCard.availability`}
                    getOptionLabel={(option: string): JSX.Element => (
                      <button
                        type="button"
                        className={cx(
                          styles.availabilityCta,
                          { [styles.active]: values.searchStatus === option },
                          globalStyles.cta,
                          globalStyles.primaryCta,
                          globalStyles.inverted,
                        )}
                      >
                        {t({ id: transTalent.searchStatus[option] })}
                      </button>
                    )}
                    getOptionValue={(option: string): string => option}
                    onClick={(_e: any, data: any): void => {
                      setFieldValue("sendProspect", data.value === "job_found");
                    }}
                    options={Object.values(searchStatuses)}
                    violations={errors}
                  />
                </div>
                {values.searchStatus === searchStatuses.not_searching ? (
                  <div className="formGroup">
                    <Field
                      component={SearchingReasonRadio}
                      name="searchStatusReason"
                      label="Raison"
                      options={Object.values(searchStatusReasons)}
                      getOptionLabel={(option: string): string => t({ id: transTalent.searchStatusReason[option] })}
                      getOptionValue={(option: string): string => {
                        return option;
                      }}
                      setJobFoundCompany={setJobFoundCompany}
                      jobFoundCompany={jobFoundCompany}
                      className={styles.searchStatusReasonsRadio}
                    />
                  </div>
                ) : null}
                <div className={styles.formBtnContainer}>
                  <div>
                    <button
                      type="button"
                      className={cx(globalStyles.cta, globalStyles.alertCta, globalStyles.inverted)}
                      onClick={closeModal}
                    >
                      {t`Constant.cancel`}
                    </button>
                  </div>
                  <div>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={cx(globalStyles.cta, globalStyles.primaryCta)}
                    >
                      {t`TalentEditionForm.submit`}
                    </button>
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};
