import { EditPhoneCall, GetPhoneCalls, PostPhoneCall, DeletePhoneCall } from "../../../../actionTypes/phoneCalls";
import { PhoneCall } from "../../../../interfaces/resources/phoneCall";

export function deletePhoneCallAction(phoneCall: PhoneCall) {
  return {
    phoneCall,
    type: DeletePhoneCall.REQUEST,
  };
}

export function deletePhoneCallSuccessAction(phoneCall: PhoneCall) {
  return {
    phoneCall,
    type: DeletePhoneCall.SUCCESS,
  };
}

export function getPhoneCallsAction(iri: string) {
  return {
    iri,
    type: GetPhoneCalls.REQUEST,
  };
}

export function submitPhoneCallAction(req: any) {
  return {
    req,
    type: PostPhoneCall.REQUEST,
  };
}

export function editPhoneCallAction(phoneCall: Partial<PhoneCall>, form?: string) {
  return {
    form,
    phoneCall,
    type: EditPhoneCall.REQUEST,
  };
}
