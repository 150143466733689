import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { all, call, put, race, take, takeEvery, takeLatest } from "redux-saga/effects";

import { GetTalentApplications } from "../../../../actionTypes/talentApplications";
import {
  CreateTalentQualification,
  EditTalentQualification,
  GetChatGPT,
  GetTalentQualification,
  GetTalentQualifications,
  PostTranscript,
} from "../../../../actionTypes/talentQualification";
import { Application, applicationStatuses } from "../../../../interfaces/resources/application";
import { Qualification } from "../../../../interfaces/resources/qualification";

import { apiFailureAction } from "../../actions";
import { createApplicationAction, deleteApplicationAction, editApplicationAction } from "../../applications/actions";
import { getStringValue } from "../../helpers";
import { getTalentApplicationsAction } from "../talentApplication/actions";
import {
  createTalentQualificationSuccessAction,
  editTalentQualificationSuccessAction,
  getChatGPTSuccessAction,
  getTalentQualificationsSuccessAction,
  getTalentQualificationSuccessAction,
  postTranscriptSuccessAction,
} from "./actions";
import * as apiClient from "./api";

export function* createTalentQualification(action: AnyAction) {
  try {
    const { applications, ...qualification } = action.req;
    const response: AxiosResponse<Qualification> = yield call(
      apiClient.createTalentQualification,
      qualification,
      action.validation,
    );
    yield put(getTalentApplicationsAction(action.talentIri));

    const { success } = yield race({
      failure: take(GetTalentApplications.FAILURE),
      success: take(GetTalentApplications.SUCCESS),
    });

    if (success && applications && applications.length > 0) {
      const apiApplications: Application[] = success.talentApplications;
      for (const application of applications) {
        const originalApplication = apiApplications.find((e: any) => e.offer["@id"] === application.offer["@id"]);
        if (originalApplication) {
          if (originalApplication.status === application.status) {
            continue;
          }
          yield put(
            editApplicationAction(originalApplication["@id"], {
              status: application.status,
              goodMatch: application.goodMatch,
              qualification: response.data["@id"],
            }),
          );
        } else {
          yield put(createApplicationAction({ ...application, qualification: response.data["@id"] }));
        }
      }
    }
    yield put(createTalentQualificationSuccessAction(response.data, action.form));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* editTalentQualification(action: AnyAction) {
  try {
    const { applications, ...qualification } = action.req;
    const response: AxiosResponse<Qualification> = yield call(
      apiClient.editTalentQualification,
      qualification,
      action.validation,
    );

    yield put(getTalentApplicationsAction(action.talentIri));

    const { success } = yield race({
      failure: take(GetTalentApplications.FAILURE),
      success: take(GetTalentApplications.SUCCESS),
    });

    if (success && applications && applications.length > 0) {
      const apiApplications: Application[] = success.talentApplications;

      const applicationsToDelete = apiApplications.filter(
        (app) =>
          app.qualification
          && app.qualification === qualification["@id"]
          && app.status === applicationStatuses.interesting
          && !applications.some((e: Application) => {
            return getStringValue(e.offer, "@id") === app.offer["@id"];
          }),
      );

      yield all(applicationsToDelete.map((app) => put(deleteApplicationAction(app["@id"]))));
      for (const application of applications) {
        const originalApplication = apiApplications.find((e: any) => {
          return e.offer["@id"] && e.offer["@id"] === getStringValue(application.offer, "@id");
        });
        if (originalApplication) {
          if (originalApplication.status === application.status) {
            continue;
          }
          yield put(
            editApplicationAction(originalApplication["@id"], {
              status: application.status,
              qualification: response.data["@id"],
            }),
          );
        } else {
          yield put(createApplicationAction({ ...application, qualification: response.data["@id"] }));
        }
      }
    }

    yield put(editTalentQualificationSuccessAction(response.data, action.form));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* getTalentQualifications(action: AnyAction) {
  try {
    const response: AxiosResponse<{ "hydra:member": Qualification[] }> = yield call(
      apiClient.getTalentQualifications,
      action.talentIri,
    );

    yield put(getTalentQualificationsSuccessAction(response.data["hydra:member"]));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* getTalentQualification(action: AnyAction) {
  try {
    const response: AxiosResponse<Qualification> = yield call(
      apiClient.getTalentQualification,
      action.qualificationIri,
    );

    yield put(getTalentQualificationSuccessAction(response.data));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* getChatGPT(action: AnyAction) {
  try {
    const response: AxiosResponse<Qualification> = yield call(apiClient.getChatGPT, action.qualificationIri);
    yield put(getChatGPTSuccessAction(response.data));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* postTranscript(action: AnyAction): any {
  if (action.transcriptType === "video") {
    try {
      const response: AxiosResponse<Qualification> = yield call(
        apiClient.postTranscriptFile,
        action.file,
        action.talent,
        action.currentUser,
        action.iriQualif,
      );
      yield put(postTranscriptSuccessAction(response.data));
    } catch (e) {
      yield put(apiFailureAction(e, action));
    }
  } else if (action.transcriptType === "text") {
    try {
      const response: AxiosResponse<Qualification> = yield call(
        apiClient.postTranscriptText,
        action.file,
        action.talent,
        action.currentUser,
        action.iriQualif,
      );
      yield put(postTranscriptSuccessAction(response.data));
    } catch (e) {
      yield put(apiFailureAction(e, action));
    }
  }
}

export default function* talentQualificationSaga() {
  yield takeLatest(CreateTalentQualification.REQUEST, createTalentQualification);
  yield takeLatest(EditTalentQualification.REQUEST, editTalentQualification);
  yield takeEvery(GetTalentQualification.REQUEST, getTalentQualification);
  yield takeLatest(GetTalentQualifications.REQUEST, getTalentQualifications);
  yield takeEvery(GetChatGPT.REQUEST, getChatGPT);
  yield takeLatest(PostTranscript.REQUEST, postTranscript);
}
