import { t } from "@lingui/macro";
import cx from "classnames";
import * as React from "react";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";

import { GetTalentProfile } from "../../../../actionTypes/talentProfile";
import { GetTalent } from "../../../../actionTypes/talents";
import { useApiSelector } from "../../../../components/Utils/hook";
import LoaderComp from "../../../../components/Utils/Loader";
import { ApiRequest } from "../../../../interfaces/api/request";
import { desiredPlacesRemote } from "../../../../interfaces/resources/desiredPlace";
import {
  ResponsibilityWithSeniority,
  TalentProfileResponsibility,
} from "../../../../interfaces/resources/responsibility";
import { State } from "../../../../interfaces/state";

import { transTalent } from "../../../../translations/constants/talent";
import { EditCandidateProfileModalCta } from "./EditCandidateProfileModal";
import styles from "./index.module.scss";

interface Props {
  readOnly?: boolean;
  form?: "qualification" | "talent";
}

interface ReducedResponsibility {
  responsibilityType: string;
  responsibilities: ResponsibilityWithSeniority[];
}

export const CandidateProfileCard: React.FC<Props> = ({ readOnly = false, form }: Props) => {
  const { apiPendingRequests } = useApiSelector();
  const talent = useSelector((state: State) => state.talent);
  const talentProfile = useSelector((state: State) => state.talentProfile);
  const loading = apiPendingRequests.some((req: ApiRequest) =>
    [GetTalent.REQUEST, GetTalentProfile.REQUEST].includes(req.type),
  );

  const talentResponsibilities = talentProfile?.responsibilities?.filter(
    (e) => e["@id"] !== talentProfile?.coreResponsibility?.["@id"],
  );
  const desiredPlaces = talentProfile?.desiredPlaces?.filter((e) => !desiredPlacesRemote.includes(e.value));
  const remotePlaces = talentProfile?.desiredPlaces?.filter((e) => desiredPlacesRemote.includes(e.value));
  const talentDesiredPlaces = desiredPlaces?.map((dsrP) => dsrP.value).join(", ");
  const talentRemotePlaces = remotePlaces?.map((dsrP) => dsrP.value).join(", ");
  const getReducedResponsibilities: (responsibilities: TalentProfileResponsibility[]) => ReducedResponsibility[] = (
    responsibilities,
  ): ReducedResponsibility[] =>
    responsibilities.reduce((result: ReducedResponsibility[], elem: TalentProfileResponsibility) => {
      const index = result.findIndex(
        (e: ReducedResponsibility) => e.responsibilityType === elem.responsibility.responsibilityType.value,
      );
      const elemResp: ResponsibilityWithSeniority = {
        ...elem.responsibility,
        seniority: elem.seniority,
      };
      if (index === -1) {
        result.push({
          responsibilities: [elemResp],
          responsibilityType: elem.responsibility.responsibilityType.value,
        });
      } else {
        result[index].responsibilities.push(elemResp);
      }
      return result;
    }, []);

  return (
    <div className={cx(styles.card, { [styles.noBorder]: form === "qualification" })}>
      {loading || !talent || !talentProfile ? (
        <div>
          <LoaderComp />
        </div>
      ) : (
        <div>
          <div className={styles.cardHeader}>
            <h2 className={styles.title}>
              <ReactMarkdown
                components={{
                  p: React.Fragment,
                }}
              >{t`CandidateProfileCard.title`}</ReactMarkdown>
            </h2>
          </div>
          <div className={cx()}>
            <ul className={styles.list}>
              {talentProfile.coreResponsibility ? (
                <li className={styles.coreResponsibilityItem}>
                  <strong>{t`CandidateProfileCard.coreResponsibility.label`}</strong> :{" "}
                  {talentProfile.coreResponsibility.value}
                </li>
              ) : null}
              <li>
                <strong>{t`CandidateProfileCard.experienceYears.label`}</strong> :{" "}
                {typeof talentProfile.experience === "number"
                  ? talentProfile.experience < 1
                    ? "< 1 an"
                    : talentProfile.experience === 1
                    ? "1 an"
                    : `${talentProfile.experience} ans`
                  : "Non renseigné"}
              </li>
              <li>
                <strong>{t`CandidateProfileCard.salaryExpectations.label`}</strong> :{" "}
                {talentProfile.expectedRemuneration?.fixedSalary && talentProfile.expectedRemuneration?.fullPackage
                  ? `${talentProfile.expectedRemuneration.fixedSalary}k fixe + ${
                      talentProfile.expectedRemuneration.fullPackage - talentProfile.expectedRemuneration.fixedSalary
                    }k variable`
                  : "Non renseigné"}
              </li>
              <li>
                <strong>{t`CandidateProfileCard.desiredPlaces.label`}</strong> : {talentDesiredPlaces}
              </li>
              <li>
                <strong>{t`CandidateProfileCard.remotePlaces.label`}</strong> :{" "}
                {remotePlaces?.length ? talentRemotePlaces : t`CandidateProfileCard.remotePlaces.empty`}
              </li>
              <li className={styles.respItem}>
                <strong>{t`CandidateProfileCard.responsibilites.label`}</strong> :
                <div className={styles.responsibilitiesContainer}>
                  {talentResponsibilities && talentResponsibilities.length ? (
                    <>
                      {getReducedResponsibilities(talentResponsibilities).map(
                        (reducedResp: ReducedResponsibility, i: number) => {
                          const { responsibilityType, responsibilities } = reducedResp;
                          return (
                            <div key={`${responsibilityType}-${i}`} className={styles.respTypeContainer}>
                              <div className={styles.respFamName}>{responsibilityType}</div>
                              <ul className={styles.respList}>
                                {responsibilities.map((resp, j) => (
                                  <li key={`${responsibilityType}-${resp["@id"]}-${j}`}>
                                    {resp.value} {resp.seniority ? `(${resp.seniority})` : null}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          );
                        },
                      )}
                    </>
                  ) : (
                    <div className={styles.emptyResponsibilities}>{t`TalentResponsibilities.empty`}</div>
                  )}
                </div>
              </li>
              <li>
                <strong>{t`CandidateProfileCard.refererWebsite.source`}</strong> :{" "}
                {talent.refererWebsite
                  ? t({ id: transTalent.referer[talent.refererWebsite] })
                  : t`CandidateProfileCard.refererWebsite.empty`}
              </li>
            </ul>
            {!readOnly ? (
              <div className={styles.editProfileCtaContainer}>
                <EditCandidateProfileModalCta talentProfile={talentProfile} />
              </div>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};
