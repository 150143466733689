import { ActionType } from "../interfaces/api/actionType";

export const CreateOffer: ActionType = {
  FAILURE: "CREATE_OFFER_FAILURE",
  REQUEST: "CREATE_OFFER_REQUEST",
  SUCCESS: "CREATE_OFFER_SUCCESS",
};

export const DeleteOffer: ActionType = {
  FAILURE: "DELETE_OFFER_FAILURE",
  REQUEST: "DELETE_OFFER_REQUEST",
  SUCCESS: "DELETE_OFFER_SUCCESS",
};

export const EditOffer: ActionType = {
  FAILURE: "EDIT_OFFER_FAILURE",
  REQUEST: "EDIT_OFFER_REQUEST",
  SUCCESS: "EDIT_OFFER_SUCCESS",
};

export const FilterByOfferName: ActionType = {
  FAILURE: "FILTER_BY_OFFER_NAME_FAILURE",
  REQUEST: "FILTER_BY_OFFER_NAME_REQUEST",
  SUCCESS: "FILTER_BY_OFFER_NAME_SUCCESS",
};

export const GetOffer: ActionType = {
  FAILURE: "GET_OFFER_FAILURE",
  REQUEST: "GET_OFFER_REQUEST",
  SUCCESS: "GET_OFFER_SUCCESS",
};

export const GetOfferApplications: ActionType = {
  FAILURE: "GET_OFFER_APPLICATIONS_FAILURE",
  REQUEST: "GET_OFFER_APPLICATIONS_REQUEST",
  SUCCESS: "GET_OFFER_APPLICATIONS_SUCCESS",
};

export const GetOfferApplication: ActionType = {
  FAILURE: "GET_OFFER_APPLICATION_FAILURE",
  REQUEST: "GET_OFFER_APPLICATION_REQUEST",
  SUCCESS: "GET_OFFER_APPLICATION_SUCCESS",
};

export const GetOffers: ActionType = {
  FAILURE: "GET_OFFERS_FAILURE",
  REQUEST: "GET_OFFERS_REQUEST",
  SUCCESS: "GET_OFFERS_SUCCESS",
};

export const GetTalentOffers: ActionType = {
  FAILURE: "GET_TALENT_OFFERS_FAILURE",
  REQUEST: "GET_TALENT_OFFERS_REQUEST",
  SUCCESS: "GET_TALENT_OFFERS_SUCCESS",
};

export const SendShortlist: ActionType = {
  FAILURE: "SEND_SHORTLIST_FAILURE",
  REQUEST: "SEND_SHORTLIST_REQUEST",
  SUCCESS: "SEND_SHORTLIST_SUCCESS",
};

export const CreateOfferInternalView: ActionType = {
  FAILURE: "CREATE_OFFER_INTERNAL_VIEW_FAILURE",
  REQUEST: "CREATE_OFFER_INTERNAL_VIEW_REQUEST",
  SUCCESS: "CREATE_OFFER_INTERNAL_VIEW_SUCCESS",
};

export const EditOfferInternalView: ActionType = {
  FAILURE: "EDIT_OFFER_INTERNAL_VIEW_FAILURE",
  REQUEST: "EDIT_OFFER_INTERNAL_VIEW_REQUEST",
  SUCCESS: "EDIT_OFFER_INTERNAL_VIEW_SUCCESS",
};

export const GetOfferInternalView: ActionType = {
  FAILURE: "GET_OFFER_INTERNAL_VIEW_FAILURE",
  REQUEST: "GET_OFFER_INTERNAL_VIEW_REQUEST",
  SUCCESS: "GET_OFFER_INTERNAL_VIEW_SUCCESS",
};

export const CreateOfferTalentView: ActionType = {
  FAILURE: "CREATE_OFFER_TALENT_VIEW_FAILURE",
  REQUEST: "CREATE_OFFER_TALENT_VIEW_REQUEST",
  SUCCESS: "CREATE_OFFER_TALENT_VIEW_SUCCESS",
};

export const EditOfferTalentView: ActionType = {
  FAILURE: "EDIT_OFFER_TALENT_VIEW_FAILURE",
  REQUEST: "EDIT_OFFER_TALENT_VIEW_REQUEST",
  SUCCESS: "EDIT_OFFER_TALENT_VIEW_SUCCESS",
};

export const GetOfferTalentView: ActionType = {
  FAILURE: "GET_OFFER_TALENT_VIEW_FAILURE",
  REQUEST: "GET_OFFER_TALENT_VIEW_REQUEST",
  SUCCESS: "GET_OFFER_TALENT_VIEW_SUCCESS",
};
