import cx from "classnames";
import { FieldProps, useField } from "formik";
import * as React from "react";
import PhoneInput from "react-phone-number-input";
import fr from "react-phone-number-input/locale/fr.json";
import "react-phone-number-input/style.css";

import { Violation } from "../../../../interfaces/api/violation";
import { getViolationMessage } from "../../../Utils/hook";
import ErrorMessage from "../ErrorMessage";
import styles from "./index.module.scss";

export interface PhoneInputProps extends FieldProps {
  className?: string;
  error?: string;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  violations?: Violation[];
}

export const Phone = ({
  className = "",
  field: { name },
  form,
  label,
  placeholder,
  isRequired = true,
  violations,
  ...rest
}: PhoneInputProps): JSX.Element => {
  const [field, meta] = useField(name);
  const error = violations && getViolationMessage(name, violations);
  return (
    <div className={cx(className, { error: (meta.touched && !!meta.error) || !!error })}>
      {label ? (
        <label className="formLabel">
          {label}
          {isRequired ? " *" : null}
        </label>
      ) : null}
      <PhoneInput
        {...rest}
        {...field}
        className={styles.phoneInput}
        defaultCountry="FR"
        placeholder={placeholder}
        labels={fr}
        required={isRequired}
        onChange={(value: any): void => form.setFieldValue(field.name, value)}
      />
      <ErrorMessage name={name} message={error} />
    </div>
  );
};
