import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { StylesProvider } from "@material-ui/core";
import { GoogleOAuthProvider } from "@react-oauth/google";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { fr } from "make-plural/plurals";
import * as React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import "./semantic.css";
import "./instantSearch-satellite.scss";

import { App } from "./components/App";
import { unregister } from "./registerServiceWorker";
import { store } from "./store";

import { messages } from "./translations/fr/messages";

i18n.loadLocaleData("fr", { plurals: fr });
i18n.load("fr", messages);
i18n.activate("fr");

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
root.render(
  <I18nProvider i18n={i18n}>
    <GoogleOAuthProvider clientId={String(process.env.REACT_APP_GOOGLE_OAUTH_ID)}>
      <StylesProvider injectFirst>
        <Provider store={store}>
          <App />
        </Provider>
      </StylesProvider>
    </GoogleOAuthProvider>
  </I18nProvider>,
);

unregister();
