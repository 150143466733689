import { GetReferral, GetReferrals } from "../../../actionTypes/referrals";

import { Referral } from "../../../interfaces/resources/referral";
import { Talent } from "../../../interfaces/resources/talent";

export function getReferralsAction(talent: Talent) {
  return {
    talent,
    type: GetReferrals.REQUEST,
  };
}

export function getReferralsSuccessAction(referrals: Referral[]) {
  return {
    referrals,
    type: GetReferrals.SUCCESS,
  };
}

export function getReferralAction(iri: string) {
  return {
    iri,
    type: GetReferral.REQUEST,
  };
}

export function getReferralSuccessAction(referral: Referral) {
  return {
    referral,
    type: GetReferral.SUCCESS,
  };
}
