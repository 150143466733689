import { t } from "@lingui/macro";

interface Const {
  priorNotice: { [key: string]: string };
  type: { [key: string]: string };
}

const transPhoneCall: Const = {
  priorNotice: {
    notice_1_month: t`priorNotice.notice_1_month`,
    notice_3_months: t`priorNotice.notice_3_months`,
    "notice_>3_months": t`priorNotice.notice_>3_months`,
    notice_asap: t`priorNotice.notice_asap`,
  },
  type: {
    filter_ongoing_process: t`phoneCall.type.filter_ongoing_process`,
    filter_first_call: t`phoneCall.type.filter_first_call`,
    filter_follow_up: t`phoneCall.type.filter_follow_up`,
    filter_talent_card: t`phoneCall.type.filter_talent_card`,
    filter_reactivation: t`phoneCall.type.filter_reactivation`,
    filter_spontaneous_application: t`phoneCall.type.filter_spontaneous_application`,
  },
};

export default transPhoneCall;
