import { AnyAction } from "redux";
import { CreateContact, DeleteContact, EditContact, SendFirstClientContactEmail } from "../../../actionTypes/contacts";
import { Contact } from "../../../interfaces/resources/contact";

export function createContactAction(contact: Partial<Contact>, form?: string) {
  return {
    contact,
    form,
    type: CreateContact.REQUEST,
  };
}

export function createContactSuccessAction(contact: Contact, action: AnyAction) {
  return {
    contact,
    form: action.form,
    type: CreateContact.SUCCESS,
  };
}

export function editContactAction(contact: Partial<Contact>, form?: string) {
  return {
    contact,
    form,
    type: EditContact.REQUEST,
  };
}

export function editContactSuccessAction(contact: Contact, action: AnyAction) {
  return {
    contact,
    form: action.form,
    type: EditContact.SUCCESS,
  };
}

export function deleteContactAction(iri: string) {
  return {
    iri,
    type: DeleteContact.REQUEST,
  };
}

export function sendFirstClientContactEmailAction(iri: string) {
  return {
    iri,
    type: SendFirstClientContactEmail.REQUEST,
  };
}
