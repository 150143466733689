import { t } from "@lingui/macro";
import cx from "classnames";
import { FieldProps, useFormikContext } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Item, Loader } from "semantic-ui-react";

import { GetTalentApplications } from "../../../../../actionTypes/talentApplications";
import { getCompanyImage } from "../../../../../components/App/Image";
import RoundImage from "../../../../../components/Design/RoundImage";
import { usePrevious } from "../../../../../components/Utils/hook";
import { ApiRequest } from "../../../../../interfaces/api/request";
import { Application, applicationStatuses } from "../../../../../interfaces/resources/application";
import { offerStatuses } from "../../../../../interfaces/resources/offer";
import { Talent } from "../../../../../interfaces/resources/talent";
import { getTalentApplicationsAction } from "../../../../../services/api/talent/talentApplication/actions";
import globalStyles from "../../../../../styles/global.module.scss";
import transApplication from "../../../../../translations/constants/application";
import transOffer from "../../../../../translations/constants/offer";

import { PhoneCallFormValues } from "../helpers";
import "./index.scss";
import styles from "./index.module.scss";

interface Props extends FieldProps<PhoneCallFormValues> {
  apiPendingRequests: ApiRequest[];
  statusToFilter: string[];
  stateKey: string;
  statuses: string[];
  talent: Talent;
}

export const ApplicationOfferListButtons: FC<Props> = ({
  stateKey,
  talent,
  field,
  statuses,
  statusToFilter,
}: Props) => {
  const fk = useFormikContext<PhoneCallFormValues>();
  const dispatch = useDispatch();
  const [applicationsToUpdate, setApplicationsToUpdate] = useState<Application[]>([]);
  const apiPendingRequests: ApiRequest[] = useSelector((state: any) => state.apiPendingRequests);
  const applications: Application[] = useSelector((state: any) =>
    state.talentApplications.filter(
      (application: Application) =>
        application.offer
        && [offerStatuses.activated, offerStatuses.standbyElinoi].includes(application.offer.status)
        && ![
          applicationStatuses.refusedElinoi,
          applicationStatuses.refusedStartup,
          applicationStatuses.refusedTalent,
        ].includes(application.status)
        && statusToFilter.includes(application.status),
    ),
  );
  const prevApplications = usePrevious(applications);

  const handleClick = (application: Application, status: string) => {
    const newState = [...applicationsToUpdate];
    const index = newState.findIndex((item) => item["@id"] === application["@id"]);

    if (index > -1) {
      const stateApplication = newState[index];
      if (stateApplication.status === status) {
        newState.splice(index, 1);
      } else {
        newState.splice(index, 1, { ...application, status });
      }
    } else {
      newState.push({ ...application, status });
    }

    setApplicationsToUpdate(newState);
    fk.setFieldValue(field.name as keyof PhoneCallFormValues, newState);
  };

  const isApplicationListLoading =
    apiPendingRequests.length > 0 && apiPendingRequests.some((e) => GetTalentApplications.REQUEST === e.type);

  useEffect(() => {
    const mount = !prevApplications && applications.length === 0;
    const update = !!prevApplications && prevApplications.length > 0 && applications.length === 0;

    if (mount || update) {
      dispatch(getTalentApplicationsAction(talent["@id"], undefined, stateKey));
    }
  }, [dispatch, prevApplications, applications, stateKey, talent]);

  return isApplicationListLoading ? (
    <Loader active={true} inline="centered" size="medium">
      Loading
    </Loader>
  ) : !!applications.length ? (
    <Item.Group className="applicationOfferListContainer">
      {applications.map((application: Application, i: number) => (
        <Item key={i}>
          <RoundImage
            className="standbyOfferImage"
            size="30px"
            banner={
              application.offer.status === offerStatuses.standbyElinoi
                ? t({ id: transOffer.status[application.offer.status] })
                : undefined
            }
            verySmallBanner={application.offer.status === offerStatuses.standbyElinoi}
            {...getCompanyImage(application.offer.company.image)}
          />
          <Item.Content>
            <Item.Header className="item">
              <span className="emphasis">{application.offer.company.name}</span> &mdash; {application.offer.title}
            </Item.Header>
          </Item.Content>
          <div className={styles.ctaContainer}>
            {statuses.map((status, oI) => {
              const isActive = applicationsToUpdate.some(
                (item: Application) => item["@id"] === application["@id"] && item.status === status,
              );

              const isRefusedStatuse = [
                applicationStatuses.refusedElinoi,
                applicationStatuses.refusedStartup,
                applicationStatuses.refusedTalent,
              ].includes(status);
              return (
                <div key={`${application["@id"]}-btn-action-${status}-${oI}`}>
                  <button
                    className={cx(
                      globalStyles.cta,
                      globalStyles.inverted,
                      { [globalStyles.primaryCta]: !isRefusedStatuse },
                      { [globalStyles.alertCta]: isRefusedStatuse },
                      { [globalStyles.active]: isActive },
                    )}
                    type="button"
                    onClick={() => handleClick(application, status)}
                  >
                    <span>{t({ id: transApplication.status[status] })}</span>
                  </button>
                </div>
              );
            })}
          </div>
        </Item>
      ))}
    </Item.Group>
  ) : (
    <p>{t`applications.empty`}</p>
  );
};
