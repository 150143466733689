import { t } from "@lingui/macro";
import cx from "classnames";
import { format, parseISO } from "date-fns";
import * as React from "react";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { Placeholder } from "semantic-ui-react";

import { GetChatGPT, GetTalentQualifications } from "../../../actionTypes/talentQualification";

import { useApiSelector } from "../../../components/Utils/hook";
import { CoreUser } from "../../../interfaces/resources/coreUser";
import { Qualification } from "../../../interfaces/resources/qualification";
import { TalentProfile } from "../../../interfaces/resources/talentProfile";
import { State } from "../../../interfaces/state";
import { getStringValue } from "../../../services/api/helpers";
import { getChatGPTAction } from "../../../services/api/talent/talentQualification/actions";
import globalStyles from "../../../styles/global.module.scss";

import transPhoneCall from "../../../translations/constants/phoneCall";
import { transQualification } from "../../../translations/constants/qualification";

import { EditQualificationItemModalCta } from "./EditQualificationItemModal";
import styles from "./index.module.scss";

interface Props {
  size?: "small" | "normal";
  isLastQualif?: boolean;
  qualificationIri?: string;
  qualifState?: "reactivation" | "qualification";
  talentProfile?: TalentProfile;
}
interface QualifTextPartProps {
  qualification: Qualification;
  partKey: keyof Qualification;
  currentUser?: CoreUser;
}

const QualifTextPart: React.FC<QualifTextPartProps> = (props: QualifTextPartProps) => {
  const { partKey, qualification } = props;
  const dispatch = useDispatch();
  const { apiPendingRequests, apiSuccess, apiErrors } = useApiSelector();
  const [loading, setLoading] = React.useState<boolean>(false);

  const onClickSendToChatGPT = () => {
    if (qualification) {
      dispatch(getChatGPTAction(qualification["@id"]));
    }
  };

  React.useEffect(() => {
    if (apiPendingRequests && apiPendingRequests.some((m) => m.type === GetChatGPT.REQUEST)) {
      setLoading(true);
    } else if (
      (apiSuccess && apiSuccess.some((m) => m.type === GetChatGPT.SUCCESS))
      || (apiErrors && apiErrors.some((m) => m.type === GetChatGPT.FAILURE))
    ) {
      setLoading(false);
    }
  }, [apiPendingRequests, apiSuccess]);
  return (
    <>
      <div className={cx(styles.partTitle, { [styles.conclusion]: partKey === "formattedConclusion" })}>
        {t({ id: transQualification.text[partKey] })}
        {partKey === "formattedConclusion" ? (
          <button
            type="button"
            className={cx(globalStyles.cta, globalStyles.darkCta, globalStyles.inverted, {
              ["loading"]: loading,
              ["button"]: loading,
              ["ui"]: loading,
            })}
            onClick={onClickSendToChatGPT}
          >
            Sauce Magique
          </button>
        ) : null}
      </div>
      <div className={styles.partText}>
        {qualification[partKey] ? (
          <ReactMarkdown>{(qualification as any)[partKey]}</ReactMarkdown>
        ) : (
          t({ id: transQualification.empty[partKey] })
        )}
      </div>
    </>
  );
};

export const QualificationItem: React.FunctionComponent<Props> = (props: Props) => {
  const {
    qualificationIri,
    talentProfile,
    isLastQualif = true,
    size = "normal",
    qualifState = "qualification",
  } = props;
  const { apiPendingRequests } = useApiSelector();
  const currentUser = useSelector((state: State) => state.currentUser);
  const talentQualifications = useSelector((state: State) => state.talentQualifications);
  const stateTalentProfile = useSelector((state: State) => state.talentProfile);
  const [showState, setShowState] = React.useState<"small" | "normal" | "all">(size);
  const [qualification, setQualification] = React.useState<Qualification | undefined>(undefined);
  const qualIri =
    qualificationIri
    || (stateTalentProfile && getStringValue(stateTalentProfile.lastQualification, "@id"))
    || (talentProfile && getStringValue(talentProfile.lastQualification, "@id"));

  const date = qualification && format(parseISO(qualification.createdAt), "dd/MM/yyyy");
  const author = qualification && qualification.coreUser && qualification.coreUser.firstname;
  const expectedRemuneration = qualification?.expectedRemuneration;
  const actualRemuneration = qualification?.actualRemuneration;
  const loading = apiPendingRequests && apiPendingRequests.some((e) => e.type === GetTalentQualifications.REQUEST);
  const showUpdateBtn = currentUser?.["@id"] === qualification?.coreUser?.["@id"];

  const onClickSeeMoreCta = () => {
    setShowState(showState === size ? "all" : size);
  };

  React.useEffect(() => {
    if (talentQualifications.length > 0) {
      setQualification(qualIri ? talentQualifications.find((q) => q["@id"] === qualIri) : talentQualifications[0]);
    }
  }, [talentQualifications, qualIri]);

  return (
    <div className={styles.container}>
      <div className={cx(styles.header, { [styles.hasUpdateCta]: showUpdateBtn && isLastQualif })}>
        <div className={styles.titleDateContainer}>
          <h2 className={styles.title}>
            <ReactMarkdown
              components={{
                p: React.Fragment,
              }}
            >
              {qualifState === "reactivation"
                ? isLastQualif
                  ? t`QualificationItem.Reactivation.title.last`
                  : t`QualificationItem.Reactivation.title`
                : isLastQualif
                ? t`QualificationItem.Qualification.title.last`
                : t`QualificationItem.Qualification.title`}
            </ReactMarkdown>
          </h2>
          {date && author ? <span>{t({ id: "qualification.coreUser", values: { date, author } })}</span> : null}
        </div>
        {isLastQualif && showUpdateBtn && qualification?.["@id"] ? (
          <div>
            <EditQualificationItemModalCta qualificationIri={qualification["@id"]} />
          </div>
        ) : null}
      </div>
      {loading ? (
        <Placeholder>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder>
      ) : qualification && qualification.coreUser ? (
        <div>
          <div className={cx(styles.partsContainer, { [styles.hidden]: showState === "small" })}>
            <div className={styles.part}>
              <div className={styles.partTitle}>{t`QualificationItem.expectedRemuneration`}</div>
              <div className={styles.partText}>
                <div>
                  Souhaitée :{" "}
                  {!!expectedRemuneration?.fixedSalary && !!expectedRemuneration?.fullPackage
                    ? `${expectedRemuneration.fixedSalary}k +  ${
                        expectedRemuneration.fullPackage - expectedRemuneration.fixedSalary
                      }k (${expectedRemuneration.fullPackage}k)`
                    : t`qualification.expectedRemuneration.empty`}
                </div>
                <div>
                  Actuelle :{" "}
                  {!!actualRemuneration?.fixedSalary && !!actualRemuneration?.fullPackage
                    ? `${actualRemuneration.fixedSalary}k +  ${
                        actualRemuneration.fullPackage - actualRemuneration.fixedSalary
                      }k (${actualRemuneration.fullPackage}k)`
                    : t`qualification.expectedRemuneration.empty`}
                </div>
              </div>
            </div>
            <div className={styles.part}>
              <QualifTextPart qualification={qualification} partKey="conclusion" />
            </div>
            <div className={styles.part}>
              <QualifTextPart qualification={qualification} partKey="idealJob" />
            </div>
          </div>
          {/* insérer les notes de phone call qui concernent les process en cours */}
          {/* contenu caché */}
          <div className={cx(styles.partsContainer, { [styles.hidden]: showState === size })}>
            <div className={styles.part}>
              <QualifTextPart qualification={qualification} partKey="context" />
            </div>
            <div className={styles.part}>
              <QualifTextPart qualification={qualification} partKey="storytelling" />
            </div>

            <div className={styles.part}>
              <QualifTextPart qualification={qualification} partKey="bridges" />
            </div>
            <div className={styles.part}>
              <div className={styles.partTitle}>{t`qualification.priorNotice`}</div>
              <div className={styles.partText}>
                {t({ id: transPhoneCall.priorNotice[qualification.priorNotice] }) || t`qualification.priorNotice.empty`}
              </div>
            </div>
          </div>
          <div className={styles.showStateCtaContainer}>
            <button
              type="button"
              className={cx(globalStyles.cta, globalStyles.darkCta, globalStyles.inverted)}
              onClick={onClickSeeMoreCta}
            >
              {showState === "all" ? "Voir moins" : "Voir plus"}
            </button>
          </div>
        </div>
      ) : (
        <div>{t`qualification.empty`}</div>
      )}
    </div>
  );
};
