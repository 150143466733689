import { t } from "@lingui/macro";
import prettyBytes from "pretty-bytes";
import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { Button } from "semantic-ui-react";

import DataExportContext from "../common/DataExportContext";
import parsers from "../common/parsers";

const DataExportTrigger: FC = () => {
  const {
    exportData,
    state: { values, format },
    prevState,
  } = useContext(DataExportContext);
  const { parse, type } = parsers[format];

  const computeFileSize = useCallback(() => {
    return () => (values.length > 0 ? new Blob([parse(values)]).size : 0);
  }, [values, parse]);

  const [fileSize, setFileSize] = useState(computeFileSize());

  useEffect(() => {
    if (prevState && (prevState.values !== values || prevState.format !== format)) {
      setFileSize(computeFileSize());
    }
  }, [format, values, computeFileSize, prevState]);

  const handleClick = () => {
    if (!values) {
      return;
    }
    exportData([parse(values)], type);
  };

  return (
    <Button
      onClick={handleClick}
      primary
      disabled={!values || (Array.isArray(values) && values.length === 0)}
      content={t({ id: "dataExport.button.export", values: { size: prettyBytes(fileSize) } })}
      icon="upload"
    />
  );
};

export default DataExportTrigger;
