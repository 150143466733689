import { PhoneCall } from "../../../../interfaces/resources/phoneCall";
import client from "../../client";
import { getStringValue } from "../../helpers";

export function deletePhoneCall(phoneCall: PhoneCall) {
  if (phoneCall["@id"]) {
    return client.delete(phoneCall["@id"]);
  }
}

export function getPhoneCalls(iri: string) {
  return client.get(`${iri}/phone_calls`);
}

export function postPhoneCall(req: Partial<PhoneCall>) {
  return client.post("/api/v2/phone_calls", {
    ...req,
    coreUser: getStringValue(req.coreUser, "@id"),
    talent: getStringValue(req.talent, "@id"),
  });
}

export function editPhoneCall(phoneCall: Partial<PhoneCall>, validation?: string) {
  return client.put(`${phoneCall["@id"]}${validation ? `/${validation}` : ""}`, {
    applications: phoneCall.applications,
    notes: phoneCall.notes,
    title: phoneCall.title,
  });
}
