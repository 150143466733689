import { t } from "@lingui/macro";
import React, { FC, useContext, useEffect, useState } from "react";
import Select from "react-select";
import { Option } from "react-select/src/filters";
import { Grid, Message } from "semantic-ui-react";
import { extractKeys } from "../../../utils";
import DataTable from "../../DataTable";
import { usePrevious } from "../../Utils/hook";
import DataExportContext from "../common/DataExportContext";
import DataExportTrigger from "../DataExportTrigger";
import FormatSelect from "../FormatSelect";

import style from "./index.module.scss";

const mapKeyToOption = (key: string) => ({ label: key, value: key });

const DataExportView: FC = () => {
  const {
    state: { values, initialValues },
    onValuesChange,
  } = useContext(DataExportContext);
  const keys = extractKeys(initialValues);
  const optionKeys = keys.map(mapKeyToOption);
  const [selectedKeys, setSelectedKeys] = useState(optionKeys);
  const prevSelectedKeys = usePrevious(selectedKeys);

  useEffect(() => {
    if (prevSelectedKeys && prevSelectedKeys !== selectedKeys) {
      const newValues = initialValues
        .map((val: any) => {
          return Object.keys(val).reduce((acc, key) => {
            return selectedKeys.find(({ label }) => label === key) ? { ...acc, [key]: val[key] } : acc;
          }, {});
        })
        .filter((val: any) => Object.keys(val).length > 0);

      onValuesChange(newValues);
    }
  }, [selectedKeys, prevSelectedKeys, values, initialValues, onValuesChange]);

  if (!Array.isArray(values)) {
    return <h4>Cannot process data, invalid format...</h4>;
  }

  const handleChange = (newValues: Option[]) => {
    setSelectedKeys(newValues || []);
  };

  return (
    <Grid>
      <Grid.Row columns={2} verticalAlign="middle" className={style["actions-row"]}>
        <Grid.Column width={2} className={style["actions-column"]}>
          <FormatSelect />
        </Grid.Column>
        <Grid.Column className={style["actions-column"]}>
          <DataExportTrigger />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Select options={optionKeys} isMulti value={selectedKeys} onChange={handleChange as any} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column className={style["table-container"]}>
          {selectedKeys.length > 0 || values.length > 0 ? (
            <DataTable values={values} />
          ) : (
            <Message warning>{t`dataExport.noFilter`}</Message>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default DataExportView;
