import { t } from "@lingui/macro";
import { FieldProps } from "formik";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import Select, { ValueType } from "react-select";
import { Placeholder, Segment } from "semantic-ui-react";

import { GetOffers } from "../../../actionTypes/offers";
import { FilterContext } from "../../../interfaces/api/filter";
import { ApiRequest } from "../../../interfaces/api/request";
import { Application, applicationStatuses } from "../../../interfaces/resources/application";
import { DesiredPlace } from "../../../interfaces/resources/desiredPlace";
import { Offer, offerStatuses } from "../../../interfaces/resources/offer";
import { Responsibility } from "../../../interfaces/resources/responsibility";
import { Talent, QualificationFormProps } from "../../../interfaces/resources/talent";
import { State } from "../../../interfaces/state";
import { getOffersAction } from "../../../services/api/offer/actions";

import { defaultStyles } from "../../Form/Select";
import { areTwoObjectArraysEqual } from "../../Utils/helpers";
import { useApiSelector, useGetResourceHook, usePrevious } from "../../Utils/hook";

import styles from "./index.module.scss";
import { OfferLine } from "./OfferLine";

const TalentOfferList: React.FC<FieldProps> = ({ field: { name, value }, form: { setFieldValue, values } }) => {
  const talentApplications = useSelector((state: State) => state.talentApplications);
  const [additionalOffers, setAdditionalOffers] = React.useState<Offer[]>([]);
  const [matchingOffers, setMatchingOffers] = React.useState<Offer[]>([]);
  const [offersToDisplay, setOffersToDisplay] = React.useState<Offer[]>([]);
  const [shouldRematchOffers, setShouldRematchOffers] = React.useState(true);

  const { apiErrors, apiSuccess, apiPendingRequests } = useApiSelector();
  const offers: Offer[] = useSelector((state: any) => state.offers);
  const talent: Talent = useSelector((state: any) => state.talent);

  const dispatch = useDispatch();

  const getOffers = (filterContext: FilterContext = []) => dispatch(getOffersAction(filterContext));
  useGetResourceHook(apiErrors, apiPendingRequests, apiSuccess, GetOffers, offers, getOffers, [
    [
      {
        multiple: true,
        name: "status",
        value: [offerStatuses.activated],
      },
    ],
  ]);

  const experienceYears = (values as QualificationFormProps).talentProfile.experience;
  const locations = (values as QualificationFormProps).talentProfile.desiredPlaces || [];
  const responsibilities = ((values as QualificationFormProps).talentProfile.responsibilities || []).map(
    (e: Responsibility) => e["@id"],
  );
  const salaryExpectations = (values as QualificationFormProps).talentProfileRem;
  const prevResponsibilities = usePrevious(responsibilities);
  const prevLocations = usePrevious(locations);
  const prevExperienceYears = usePrevious(experienceYears);
  const prevSalaryExpectations = usePrevious(salaryExpectations);
  React.useEffect(() => {
    if (
      !areTwoObjectArraysEqual(prevResponsibilities || [], responsibilities, (v1: string, v2: string) => v1 === v2)
      || !(salaryExpectations === prevSalaryExpectations)
      || !areTwoObjectArraysEqual(
        prevLocations || [],
        locations,
        (v1: DesiredPlace, v2: DesiredPlace) => v1["@id"] === v2["@id"],
      )
      || !(prevExperienceYears === experienceYears)
    ) {
      setShouldRematchOffers(true);
    }
  }, [
    locations,
    prevLocations,
    experienceYears,
    prevExperienceYears,
    salaryExpectations,
    prevSalaryExpectations,
    responsibilities,
    prevResponsibilities,
    setShouldRematchOffers,
  ]);

  React.useEffect(() => {
    const newOffersToDisplay = [...additionalOffers, ...matchingOffers];
    if (
      !areTwoObjectArraysEqual(newOffersToDisplay, offersToDisplay, (v1: Offer, v2: Offer) => v1["@id"] === v2["@id"])
    ) {
      setOffersToDisplay(newOffersToDisplay);
    }
  }, [offersToDisplay, additionalOffers, matchingOffers]);

  React.useEffect(() => {
    if (offers.length > 0 && shouldRematchOffers) {
      const salaryExp = salaryExpectations;
      setMatchingOffers(
        offers.filter((offer) => {
          const offerSalary = {
            min: offer.salary?.[0]?.bottomValue || 0,
            max: offer.salary?.[offer.salary?.length - 1]?.topValue || 151,
          };
          const offerExp = {
            min: offer.experienceYears?.[0]?.bottomValue || 0,
            max: offer.experienceYears?.[offer.experienceYears?.length - 1]?.topValue || 16,
          };
          return (
            locations.some((e) => offer.stapleLocation && e["@id"] === offer.stapleLocation["@id"])
            && typeof experienceYears === "number"
            && offerExp.min <= experienceYears
            && offerExp.max >= experienceYears
            && offerSalary.min <= salaryExp[1]
            && offerSalary.max >= salaryExp[1]
            && (offer.responsibilities || []).some((e) => responsibilities.includes(e["@id"]))
            && !!!talentApplications.some((ap) => ap.offer["@id"] === offer["@id"])
          );
        }),
      );
      setShouldRematchOffers(false);
    }
  }, [
    offers,
    matchingOffers,
    shouldRematchOffers,
    setShouldRematchOffers,
    locations,
    experienceYears,
    salaryExpectations,
    responsibilities,
  ]);

  const isLoading =
    apiPendingRequests.length > 0 && apiPendingRequests.some((item: ApiRequest) => GetOffers.REQUEST === item.type);

  const handleChange = (offer: Offer): void => {
    const newState = [...(value as Array<Partial<Application>>)];
    const index = newState.findIndex((item: Partial<Application>) => {
      return typeof item.offer === "string"
        ? item.offer === offer["@id"]
        : !!item.offer && item.offer["@id"] === offer["@id"];
    });
    if (index > -1) {
      newState.splice(index, 1);
    } else {
      newState.push({ offer, status: applicationStatuses.contacted, talent });
    }
    setFieldValue(name, newState);
  };

  if (isLoading) {
    return (
      <Segment basic loading>
        <Placeholder>
          <Placeholder.Header image>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
        </Placeholder>
        <Placeholder>
          <Placeholder.Header image>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
        </Placeholder>
      </Segment>
    );
  } else {
    return (
      <div className={styles.offerListSelectContainer}>
        <div className={styles.offerList}>
          {offersToDisplay.length > 0 && value ? (
            offersToDisplay.map((item: Offer) => {
              return (
                <div key={`qualif-offerlistContainer-${item["@id"]}`} className={styles.offerLineContainer}>
                  <OfferLine
                    offer={item}
                    checked={value.some((opt: Partial<Application>) =>
                      typeof opt.offer === "string"
                        ? opt.offer === item["@id"]
                        : opt.offer && opt.offer["@id"] === item["@id"],
                    )}
                    handleSelectProps={handleChange}
                  />
                </div>
              );
            })
          ) : (
            <div className={styles.emptyList}>{t`TalentOfferList.placeholder.empty`}</div>
          )}
        </div>
        <Select
          placeholder={t`TalentOfferList.placeholder.select`}
          options={offers.filter(
            (e: Offer) =>
              !offersToDisplay.some((i: Offer) => i["@id"] === e["@id"])
              // On filtre sur les candidatures du talent pour éviter le bug de re-sélection de candidature existante.
              && !talentApplications.some((ap) => ap.offer["@id"] === e["@id"]),
          )}
          isSearchable={true}
          getOptionLabel={(option: Offer): string => `${option.company.name} - ${option.title}`}
          getOptionValue={(option: Offer): string => option["@id"]}
          value={undefined}
          menuPlacement="auto"
          onChange={(val: ValueType<Offer, any>): void => setAdditionalOffers([val as Offer, ...additionalOffers])}
          styles={defaultStyles}
        />
      </div>
    );
  }
};

export default TalentOfferList;
