import React, { PropsWithChildren } from "react";
import { useInstantSearch } from "react-instantsearch-hooks-web";

interface Props {
  fallback: React.ReactNode;
}

export const NoResultsBoundary: React.FC<PropsWithChildren<Props>> = (props: PropsWithChildren<Props>): JSX.Element => {
  const { children, fallback } = props;
  const { results } = useInstantSearch();

  if (!results.__isArtificial && results.nbHits === 0) {
    return (
      <>
        {fallback}
        <div hidden>{children}</div>
      </>
    );
  }

  return <>{children}</>;
};
