import { GetTalentApplications } from "../../../../actionTypes/talentApplications";
import { FilterContext } from "../../../../interfaces/api/filter";
import { Application } from "../../../../interfaces/resources/application";

export function getTalentApplicationsAction(iri: string, filterContext?: FilterContext, stateKey?: string) {
  return {
    filterContext,
    iri,
    stateKey,
    type: GetTalentApplications.REQUEST,
  };
}

export function getTalentApplicationsSuccessAction(talentApplications: Application[], stateKey: string) {
  return {
    stateKey,
    talentApplications,
    type: GetTalentApplications.SUCCESS,
  };
}
