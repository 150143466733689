import { t } from "@lingui/macro";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion } from "semantic-ui-react";

import { FilterClause } from "../../../interfaces/api/filter";
import { formatSectors } from "../../../interfaces/resources/sector";
import { State } from "../../../interfaces/state";
import { getCompaniesAction } from "../../../services/api/company/actions";
import { getDesiredPlacesAction } from "../../../services/api/desiredPlaces/actions";
import { getExperienceYearsAction } from "../../../services/api/experienceYears/actions";
import { getResponsibilitiesAction } from "../../../services/api/responsibilities/actions";
import { getSalaryExpectationsAction } from "../../../services/api/salaryExpectations/actions";
import { getSectorsAction } from "../../../services/api/sector/actions";
import { applyFilterAction } from "../../../services/state/actions";

import { ExperienceYearsFilter } from "../ExperienceYearsFilter";
import { OfferNameFilter } from "../OfferNameFilter";
import { OfferStatusFilter } from "../OfferStatusFilter";
import { ResponsibilitiesFilter } from "../ResponsibilitiesFilter";
import { SalaryExpectationsFilter } from "../SalaryExpectationsFilter";
import { SectorFilter } from "../SectorFilter";

export const OfferFilterHandler: React.FC = (): JSX.Element => {
  let debounceOfferNameTimeout!: NodeJS.Timeout;
  const debounceTimeMs = 300;

  const dispatch = useDispatch();
  const [activeIndexes, setActiveIndexes] = React.useState([1]);
  const [text, setText] = React.useState("");

  const apiPendingRequests = useSelector((state: State) => state.apiPendingRequests);
  const companies = useSelector((state: State) => state.companies);
  const desiredPlaces = useSelector((state: State) => state.desiredPlaces);
  const experienceYears = useSelector((state: State) => state.experienceYears);
  const filterContext = useSelector((state: State) => state.filterContext);
  const responsibilities = useSelector((state: State) => state.responsibilities);
  const salarySuggestions = useSelector((state: State) => state.salaryExpectations);
  const sectors = useSelector((state: State) => state.sectors);
  const applyFilter = (fc: any) => dispatch(applyFilterAction(fc));

  const resetOfferNameTimeout = React.useCallback((): any => {
    clearTimeout(debounceOfferNameTimeout);
  }, [debounceOfferNameTimeout]);

  const debounce = (callback: () => void): void => {
    resetOfferNameTimeout();

    debounceOfferNameTimeout = setTimeout(() => {
      callback();
    }, debounceTimeMs);
  };

  const handleClick = (_: any, { index }: any): void => {
    const newIndexes = activeIndexes.some((item: number) => item === index)
      ? activeIndexes.filter((item: number) => item !== index)
      : [...activeIndexes, index];
    setActiveIndexes(newIndexes);
  };

  const getSelected = (type: string, items?: any): any => {
    const filterClause = filterContext.filter((clause: FilterClause<any>) => type === clause.name)[0];
    if (items) {
      return filterClause ? items.filter((item: any) => filterClause.value.some((e: any) => e === item["@id"])) : [];
    }
    return filterClause ? filterClause.value : [];
  };

  React.useEffect(() => {
    const getCompanies = () => dispatch(getCompaniesAction([]));
    const getDesiredPlaces = () => dispatch(getDesiredPlacesAction());
    const getExperienceYears = () => dispatch(getExperienceYearsAction());
    const getResponsibilities = () => dispatch(getResponsibilitiesAction());
    const getSalarySuggestions = () => dispatch(getSalaryExpectationsAction());
    const getSectors = () => dispatch(getSectorsAction());

    setTimeout(() => {
      if (companies.length === 0) {
        getCompanies();
      }
      if (experienceYears.length === 0) {
        getExperienceYears();
      }
      if (salarySuggestions.length === 0) {
        getSalarySuggestions();
      }
      if (responsibilities.length === 0) {
        getResponsibilities();
      }
      if (sectors.length === 0) {
        getSectors();
      }

      if (desiredPlaces.length === 0) {
        getDesiredPlaces();
      }
    }, 100);

    return () => {
      resetOfferNameTimeout();
    };
  }, [
    companies,
    experienceYears,
    salarySuggestions,
    responsibilities,
    sectors,
    desiredPlaces,
    resetOfferNameTimeout,
    dispatch,
  ]);

  React.useEffect(() => {
    if (filterContext.length === 0) {
      applyFilter({ multiple: false, name: "status", value: "activated" });
    }
  });

  const changeFilterContextForOffer = (_: unknown, { value }: { value: string }): void => {
    setText(value);
    debounce(() => {
      applyFilter({
        multiple: false,
        name: "name",
        value: value.trim() || null,
      });
    });
  };

  const changeFilterContext = (name: string, data: any): void => {
    let newValue: any;
    if (Array.isArray(data)) {
      newValue = data.map((elem: any) => (elem.hasOwnProperty("@id") ? elem["@id"] : elem.value));
    } else {
      newValue = data.hasOwnProperty("@id") ? data["@id"] : data.value;
    }

    const newClause: FilterClause<any> = {
      multiple: !!data.multiple || Array.isArray(data),
      name,
      value: newValue,
    };
    applyFilter(newClause);
  };

  const changeFilterContextForType = (type: string) => {
    return (data: any): void => changeFilterContext(type, data);
  };

  const changeFilterContextForSelect = (name: string) => {
    return (value: any): any => {
      const iris = (value || []).map((e: any) => {
        return e["@id"];
      });
      return applyFilter({
        multiple: true,
        name,
        value: iris,
      });
    };
  };

  return (
    <div>
      <h2>{t`CompanySearchScene.filter`}</h2>
      <OfferNameFilter apiPendingRequests={apiPendingRequests} text={text} onChange={changeFilterContextForOffer} />
      <Accordion fluid>
        <ResponsibilitiesFilter
          activeIndexes={activeIndexes}
          apiPendingRequests={apiPendingRequests}
          handleClick={handleClick}
          index={5}
          items={responsibilities}
          onChange={changeFilterContextForType("responsibilities")}
          selected={getSelected("responsibilities", responsibilities)}
        />
        <ExperienceYearsFilter
          activeIndexes={activeIndexes}
          handleClick={handleClick}
          index={4}
          items={experienceYears}
          multiple={true}
          onChange={changeFilterContextForType("experienceYears")}
          selected={getSelected("experienceYears")}
        />
        <SalaryExpectationsFilter
          activeIndexes={activeIndexes}
          handleClick={handleClick}
          index={3}
          items={salarySuggestions}
          multiple={true}
          onChange={changeFilterContextForType("salary")}
          selected={getSelected("salary")}
        />
        <SectorFilter
          activeIndexes={activeIndexes}
          handleClick={handleClick}
          index={6}
          items={formatSectors(sectors)}
          multiple={true}
          onChange={changeFilterContextForSelect("company.sectors")}
          selected={getSelected("company.sectors")}
        />
        <OfferStatusFilter
          activeIndexes={activeIndexes}
          handleClick={handleClick}
          index={2}
          multiple={false}
          onChange={changeFilterContextForType("status")}
          selected={getSelected("status")}
        />
      </Accordion>
    </div>
  );
};
