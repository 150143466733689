import cx from "classnames";
import React from "react";
import { Pagination } from "react-instantsearch-hooks-web";
import globalStyles from "../../styles/global.module.scss";
import { AlgoliaNbHits } from "./AlgoliaNbHits";
import { Hits } from "./Hits";
import styles from "./index.module.scss";
interface Props {
  segment?: string;
  defaultIsOpen?: boolean;
}

export const SingleIndex: React.FC<Props> = (props: Props) => {
  const { segment, defaultIsOpen = false } = props;
  const [isOpen, setIsOpen] = React.useState(defaultIsOpen);
  return (
    <>
      <div>
        <AlgoliaNbHits segment={segment} />
        <div className={styles.isOpenCtaContainer}>
          <button
            type="button"
            className={cx(globalStyles.cta, globalStyles.primaryCta, { [globalStyles.active]: isOpen })}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            {isOpen ? "cacher" : "afficher"}
          </button>
        </div>
      </div>
      {isOpen ? (
        <div className={styles.hitPaginationContainer}>
          <div>
            <Hits segment={segment} />
          </div>
          <div className={styles.paginationContainer}>
            <Pagination />
          </div>
        </div>
      ) : null}
    </>
  );
};
