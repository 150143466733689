import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardOffer } from "../../blocks/cardOffer";
import { FilterContext } from "../../interfaces/api/filter";
import { Offer } from "../../interfaces/resources/offer";
import { State } from "../../interfaces/state";
import { getOffersAction } from "../../services/api/offer/actions";
import styles from "./index.module.scss";

interface Props {
  title?: React.ReactNode;
}

export const MyOffers: React.FC<Props> = (props: Props): JSX.Element => {
  const { title } = props;
  const dispatch = useDispatch();
  const currentUser = useSelector((state: State) => state.currentUser);
  const filterContext = useSelector((state: State) => state.filterContext);
  const offers = useSelector((state: State) => state.offers);

  React.useEffect(() => {
    if (!!!currentUser) {
      return;
    }
    const getOffers = (fc: FilterContext) => dispatch(getOffersAction(fc));
    getOffers(
      filterContext.concat([
        {
          multiple: true,
          name: "owners",
          value: [currentUser["@id"]],
        },
        {
          multiple: true,
          name: "groups",
          value: ["internal:read:offer:list"],
        },
        {
          multiple: true,
          name: "status",
          value: ["activated", "standby_elinoi"],
        },
      ]),
    );
  }, [dispatch, currentUser, filterContext]);

  return (
    <div>
      {!!title ? title : null}
      <div className={styles.offersContainer}>
        <ul className={styles.offersList}>
          {offers.map((offer: Offer, i) => {
            return (
              <li key={`my-offers-${offer["@id"]}-${i}`}>
                <CardOffer offer={offer} />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
