import { TalentStatusEventsProps } from "./talentStatusEvents";

export interface CoreUser {
  "@id": string;
  email: string;
  enabled: boolean;
  username: string;
  password?: string;
  firstname?: string;
  lastname?: string;
  phone?: string;
  linkedInUrl?: string;
  calendlyLink10?: string;
  calendlyLink20?: string;
  calendlyLink30?: string;
  roles: string[];
  talentStatusEvents?: TalentStatusEventsProps[];
  image: {
    url: string;
  };
  signature?: string;
}

export const coreUserRoles = {
  admin: "ROLE_ADMIN",
  am: "ROLE_ACCOUNT_MANAGER",
  nb: "ROLE_NEW_BIZ",
  tim: "ROLE_COACH",
  user: "ROLE_USER",
};

export const isTalentManager = (coreUser: CoreUser) => {
  return coreUser.roles.includes(coreUserRoles.tim);
};
