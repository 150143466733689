import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import {
  CreateApplication,
  CreateApplicationAccepted,
  DeleteApplication,
  EditApplication,
  EditApplicationAccepted,
  GetApplicationsAccepted,
  GetApplication,
  GetApplicationForCollection,
  GetApplications,
  PostApplicationMessage,
  ReadApplicationMessages,
  SortApplication,
} from "../../../actionTypes/applications";
import { FilterContext } from "../../../interfaces/api/filter";
import { HydraCollection } from "../../../interfaces/api/hydra-collection";
import { Application, ApplicationAccepted } from "../../../interfaces/resources/application";
import { apiFailureAction } from "../actions";
import { extractNextPageNumberFromIri, getSuccessType } from "../helpers";
import {
  createApplicationAcceptedSuccessAction,
  editApplicationAcceptedAction,
  editApplicationAcceptedSuccessAction,
} from "./actions";
import * as apiClient from "./api";

export function* getApplication(action: AnyAction): any {
  try {
    const response = yield call(apiClient.getApplication, action.iri);
    const data = response.data;

    yield put({
      application: data,
      type: getSuccessType(action),
    });
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* deleteApplication(action: AnyAction): any {
  try {
    const response = yield call(apiClient.deleteApplication, action.iri);
    const data = response.data;

    yield put({
      data,
      iri: action.iri,
      type: DeleteApplication.SUCCESS,
    });
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* editApplication(action: AnyAction): any {
  try {
    const response = yield call(apiClient.editApplication, action.iri, action.req, action.validation);
    yield put({
      application: response.data,
      iri: action.iri,
      type: EditApplication.SUCCESS,
    });
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* getApplications(action: AnyAction): any {
  try {
    const response: AxiosResponse<{
      "hydra:member": Application[];
      "hydra:totalItems"?: number;
      "hydra:view"?: {
        "hydra:next"?: string;
        "hydra:last"?: string;
      };
    }> = yield call(apiClient.getApplications, action.filterContext, action.page);
    const applications = response.data["hydra:member"];
    const totalItems = response.data["hydra:totalItems"];
    const nextPage = !!response.data?.["hydra:view"]?.["hydra:next"]
      ? extractNextPageNumberFromIri(response.data["hydra:view"]["hydra:next"])
      : undefined;
    const lastPage = !!response.data?.["hydra:view"]?.["hydra:last"]
      ? extractNextPageNumberFromIri(response.data["hydra:view"]["hydra:last"])
      : undefined;
    yield put({
      applications,
      page: action.page,
      nextPage,
      lastPage,
      totalItems,
      filterContext: action.filterContext,
      concat: action.concat,
      uniqId: action.uniqId,
      type: GetApplications.SUCCESS,
    });
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* createApplication(action: AnyAction): any {
  const filter: FilterContext = [
    {
      multiple: false,
      name: "offer",
      value: action.req.offer["@id"],
    },
    {
      multiple: false,
      name: "talent",
      value: action.req.talent["@id"],
    },
  ];
  try {
    const existingApplication = yield call(apiClient.getApplications, filter);
    let response: AxiosResponse<Application>;
    if (existingApplication && existingApplication.data["hydra:member"].length > 0) {
      const app = existingApplication.data["hydra:member"][0];
      const editAction = {
        iri: app["@id"],
        req: action.req,
        type: EditApplication.REQUEST,
      };
      yield call(editApplication, editAction);
    } else {
      response = yield call(apiClient.createApplication, action.req);
      const application = response.data;
      yield put({
        application,
        talent: action.req.talent,
        type: CreateApplication.SUCCESS,
      });
    }
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* sortApplication(action: AnyAction): any {
  try {
    const response = yield call(apiClient.editApplication, action.iri, action.req, action.validation);
    const application = response.data;
    yield put({
      application,
      iri: action.iri,
      talent: application.talent,
      type: SortApplication.SUCCESS,
    });
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* postApplicationMessage(action: AnyAction): any {
  try {
    yield call(apiClient.postApplicationMessage, {
      message: action.message,
      coreUser: action.coreUserIri,
      application: action.applicationIri,
      clientEnabled: action.clientEnabled,
    });
    yield put({
      type: getSuccessType(action),
    });
  } catch (e: any) {
    if (!e.hasOwnProperty("response")) {
      throw new Error(e.message);
    }
    yield put(apiFailureAction(e, action));
  }
}

export function* readApplicationMessages(action: AnyAction): any {
  try {
    for (const apM of action.messages) {
      yield call(apiClient.editApplicationMessage, apM["@id"], {
        coreUserRead: [...apM.coreUserRead, action.coreUserIri],
      });
    }
    yield put({
      type: getSuccessType(action),
    });
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* getApplicationsAccepted(action: AnyAction): any {
  try {
    const response: AxiosResponse<{
      "hydra:member": Application[];
      "hydra:totalItems"?: number;
      "hydra:view"?: {
        "hydra:next"?: string;
        "hydra:last"?: string;
      };
    }> = yield call(apiClient.getApplications, action.filterContext, action.page);
    const applications = response.data["hydra:member"];
    const totalItems = response.data["hydra:totalItems"];
    const nextPage = !!response.data?.["hydra:view"]?.["hydra:next"]
      ? extractNextPageNumberFromIri(response.data["hydra:view"]["hydra:next"])
      : undefined;
    const lastPage = !!response.data?.["hydra:view"]?.["hydra:last"]
      ? extractNextPageNumberFromIri(response.data["hydra:view"]["hydra:last"])
      : undefined;
    yield put({
      applications,
      page: action.page,
      nextPage,
      lastPage,
      totalItems,
      filterContext: action.filterContext,
      concat: action.concat,
      uniqId: action.uniqId,
      type: GetApplicationsAccepted.SUCCESS,
    });
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* createApplicationAccepted(action: AnyAction) {
  try {
    const existingApplicationAccepted: AxiosResponse<HydraCollection<ApplicationAccepted>> = yield call(
      apiClient.getApplicationsAccepted,
      [
        {
          multiple: false,
          name: "offer",
          value: action.applicationAccepted.offer["@id"],
        },
        {
          multiple: false,
          name: "talent",
          value: action.applicationAccepted.talent["@id"],
        },
      ],
    );
    if (existingApplicationAccepted && existingApplicationAccepted.data["hydra:member"].length > 0) {
      action.applicationAccepted["@id"] = existingApplicationAccepted.data["hydra:member"][0]["@id"];
      yield call(editApplicationAccepted, editApplicationAcceptedAction(action.applicationAccepted, action.form));
    } else {
      const response: AxiosResponse<ApplicationAccepted> = yield call(
        apiClient.createApplicationAccepted,
        action.applicationAccepted,
      );
      yield put(createApplicationAcceptedSuccessAction(response.data, action));
    }
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* editApplicationAccepted(action: AnyAction) {
  try {
    const response: AxiosResponse<ApplicationAccepted> = yield call(apiClient.editApplicationAccepted, action.req);

    yield put(editApplicationAcceptedSuccessAction(response.data, action));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export default function* applicationsSaga() {
  yield takeLatest(GetApplication.REQUEST, getApplication);
  yield takeEvery(GetApplicationForCollection.REQUEST, getApplication);
  yield takeEvery(EditApplication.REQUEST, editApplication);
  yield takeEvery(GetApplications.REQUEST, getApplications);
  yield takeLatest(GetApplicationsAccepted.REQUEST, getApplicationsAccepted);
  yield takeEvery(SortApplication.REQUEST, sortApplication);
  yield takeEvery(CreateApplication.REQUEST, createApplication);
  yield takeEvery(DeleteApplication.REQUEST, deleteApplication);
  yield takeLatest(PostApplicationMessage.REQUEST, postApplicationMessage);
  yield takeLatest(ReadApplicationMessages.REQUEST, readApplicationMessages);
  yield takeLatest(CreateApplicationAccepted.REQUEST, createApplicationAccepted);
  yield takeLatest(EditApplicationAccepted.REQUEST, editApplicationAccepted);
}
