import { FieldProps, useField } from "formik";
import * as React from "react";
import { CheckboxProps as SUICheckboxProps, Form, FormCheckboxProps, Placeholder } from "semantic-ui-react";
import { Violation } from "../../../../interfaces/api/violation";
import { getStringValue } from "../../../../services/api/helpers";
import { getViolationMessage } from "../../../Utils/hook";
import ErrorMessage from "../ErrorMessage";

const PlaceholderBlock = () => (
  <Placeholder>
    <Placeholder.Line length="short" />
    <Placeholder.Line length="short" />
    <Placeholder.Line length="short" />
    <Placeholder.Line length="short" />
  </Placeholder>
);

interface CheckboxFieldProps extends FieldProps, FormCheckboxProps {
  className: string;
  getOptionLabel?: (option: any, value: any) => string | JSX.Element;
  getOptionValue?: (option: any) => any;
}

export const formatValue = (value: any, getOptionValue?: (option: any) => any): any =>
  getOptionValue ? getOptionValue(value) : value;

export const formatLabel = (label?: any, value?: any, getOptionLabel?: (l: any, v: any) => any): any =>
  label ? (getOptionLabel ? getOptionLabel(label, value) : label) : null;

export const CheckboxField = ({
  error,
  field,
  form,
  getOptionLabel,
  getOptionValue,
  option,
  ...rest
}: CheckboxFieldProps): JSX.Element => {
  const [, meta] = useField(field.name);
  const formatedValue = formatValue(option, getOptionValue);
  return (
    <Form.Checkbox
      {...field}
      checked={
        Array.isArray(field.value)
          ? field.value.some((opt: any) => {
              return (
                (getStringValue(formatValue(opt, getOptionValue), "@id") ?? formatValue(opt, getOptionValue))
                === (getStringValue(formatedValue, "@id") ?? formatedValue)
              );
            })
          : !!field.value
      }
      error={(meta && meta.touched && !!meta.error) || !!error}
      onChange={(_: React.FormEvent<HTMLInputElement>, data: SUICheckboxProps): void => {
        let newValue = field.value;
        if (Array.isArray(newValue)) {
          newValue = newValue ? newValue : [];
          if (data.checked) {
            newValue = [...newValue, data.value];
          } else {
            newValue.splice(newValue.indexOf(data.value), 1);
          }
        } else {
          newValue = data.checked;
        }
        form.setFieldValue(field.name, newValue);
      }}
      onBlur={(): void => form.setFieldTouched(field.name, true)}
      value={formatedValue}
      label={formatLabel(option, field.value, getOptionLabel)}
      {...rest}
    />
  );
};

interface CheckboxProps extends FieldProps, FormCheckboxProps {
  className?: string;
  getOptionLabel?: (option: any) => string | JSX.Element;
  getOptionValue?: (option: any) => any;
  isLoading: boolean;
  label?: string;
  name: string;
  options: any[];
  optionsProps?: any[];
  violations?: Violation[];
}

const Checkbox: React.FC<CheckboxProps> = ({
  className,
  field,
  form,
  getOptionLabel,
  getOptionValue,
  isLoading,
  label,
  options,
  optionsProps,
  violations,
}: CheckboxProps): JSX.Element => {
  const [, meta] = useField(field.name);
  const error = violations && getViolationMessage(field.name, violations);
  return (
    <Form.Field name={field.name} error={(meta.touched && !!meta.error) || !!error} className={className}>
      {label ? <label className="formLabel">{label}</label> : undefined}
      {isLoading ? (
        <PlaceholderBlock />
      ) : (
        <div className="checkbox-options-container fields grouped checkbox">
          {options.map((option: any, idx) => (
            <CheckboxField
              error={error}
              field={field}
              form={form}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              key={getStringValue(option, "@id") ?? formatLabel(option, field.value, getOptionLabel) ?? option}
              option={option}
              {...(Array.isArray(optionsProps) && optionsProps.length > idx ? optionsProps[idx] : [])}
            />
          ))}
        </div>
      )}
      <ErrorMessage name={field.name} message={error} />
    </Form.Field>
  );
};

export default Checkbox;
