import { LOCATION_CHANGE } from "redux-first-history";
import { GetReferral, GetReferrals } from "../../../actionTypes/referrals";
import { GetTalent } from "../../../actionTypes/talents";

import { Referral } from "../../../interfaces/resources/referral";

export function referrals(state: Referral[] = [], action: any) {
  switch (action.type) {
    case GetReferrals.SUCCESS:
      return action.referrals;
    case GetTalent.REQUEST:
      return [];
    default:
      return state;
  }
}

export function referral(state: Referral | null = null, action: any) {
  switch (action.type) {
    case GetReferral.SUCCESS:
      return action.referral;
    case GetTalent.REQUEST:
    case LOCATION_CHANGE:
      return null;
    default:
      return state;
  }
}
