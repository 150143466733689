import { t } from "@lingui/macro";
import cx from "classnames";
import { FieldProps } from "formik";
import * as React from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Button, Loader } from "semantic-ui-react";

import { Contact } from "../../../../interfaces/resources/contact";

import {
  ContactConfirmSoftDeletionModal,
  ContactImportCompanyContactsModal,
  ContactModal,
  ContactModalTrigger,
} from "../../../Contacts/ContactModal";
import GmailMailto from "../../../Utils/GmailMailto";

import styles from "./index.module.scss";

interface Props extends FieldProps {
  isLoading: boolean;
}

const removeContactFromValues = (ctnts: Contact[], iri: string): Contact[] => {
  ctnts.splice(
    ctnts.findIndex((e: Contact) => e["@id"] === iri),
    1,
  );
  return ctnts;
};

export const Contacts = ({
  field: { name, value },
  form: { setFieldValue, values },
  isLoading,
}: Props): JSX.Element => {
  return isLoading ? (
    <Loader />
  ) : (
    <>
      <ul className={styles.offerContactsList}>
        {value.map(
          (cont: Contact): JSX.Element => (
            <li key={`offer-contact-${cont["@id"]}`}>
              <div className={styles.offerContactItemContent}>
                <div className={styles.offerContactNameInfosContainer}>
                  <div className={styles.offerContactName}>{`${cont.firstname} ${cont.lastname}`}</div>
                  <div className={styles.offerContactInfos}>
                    <span>
                      @ <GmailMailto to={cont.email} />
                    </span>
                    <span>
                      Tel : <a href={`tel:${cont.phone}`}>{cont.phone}</a>
                    </span>
                  </div>
                </div>
                <div className={styles.offerContactActions}>
                  <ContactModal
                    contact={cont}
                    customTrigger={({ handleOpen }: ContactModalTrigger): JSX.Element => (
                      <button className={cx(styles.btn, styles.updateBtn)} onClick={handleOpen}>
                        {t`Constant.update`}
                      </button>
                    )}
                  />
                  <ContactConfirmSoftDeletionModal
                    customTrigger={({ handleOpen }: ContactModalTrigger): JSX.Element => (
                      <button className={cx(styles.btn, styles.cancelBtn)} onClick={handleOpen}>
                        {<RiDeleteBin6Line />}
                      </button>
                    )}
                    onPostConfirm={(): void => {
                      setFieldValue(name, removeContactFromValues(value, String(cont["@id"])));
                    }}
                  />
                </div>
              </div>
            </li>
          ),
        )}
      </ul>
      <div className={styles.offerContactsActionContainer}>
        <span className={styles.offerContacstActionLabel}>{t`ContactModal.action.label`}</span>
        <ContactImportCompanyContactsModal
          contacts={(values.company && values.company.contacts) || []}
          onPostConfirm={(contacts): void => {
            // check duplicates
            setFieldValue(name, [...value, ...contacts]);
          }}
          customTrigger={({ handleOpen }: ContactModalTrigger): JSX.Element => (
            <Button size="small" secondary onClick={handleOpen}>
              {t`ContactModal.import.trigger`}
            </Button>
          )}
        />
        <ContactModal
          customTrigger={({ handleOpen }: ContactModalTrigger): JSX.Element => (
            <Button size="small" onClick={handleOpen}>
              {t`ContactModal.create.trigger`}
            </Button>
          )}
        />
      </div>
    </>
  );
};
