import { t } from "@lingui/macro";
import * as React from "react";

import { Contact } from "../../../interfaces/resources/contact";
import GmailMailto from "../../Utils/GmailMailto";

import styles from "./index.module.scss";

interface Props {
  contacts: Contact[];
  contactsNumber: number;
  title: string;
}

export const ContactsList: React.FC<Props> = ({ contacts, contactsNumber, title }: Props) => {
  const index = Math.min(contacts.length, contactsNumber);
  return (
    <div className={styles.contactContainer}>
      <div className={styles.titleContainer}>
        <h5>{t({ id: title })}</h5>
      </div>
      <div>
        {contacts.length ? (
          <ul className={styles.list}>
            {contacts.slice(0, index).map((con, i) => (
              <li key={`${con["@id"]}-${i}`}>
                <div className={styles.name}>{`${con.firstname} ${con.lastname}`}</div>
                <div className={styles.infosContainer}>
                  {con.email ? (
                    <span>
                      <GmailMailto to={con.email} />
                    </span>
                  ) : null}
                  {con.phone ? (
                    <span>
                      <a href={`tel: ${con.phone}`}>{con.phone}</a>
                    </span>
                  ) : null}
                </div>
              </li>
            ))}
          </ul>
        ) : (
          t`offer.contacts.empty`
        )}
      </div>
    </div>
  );
};
