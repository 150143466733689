import { format, parseISO } from "date-fns";
import React, { useState, PropsWithChildren } from "react";
import ReactDatePicker from "react-datepicker";
import { Icon, Modal } from "semantic-ui-react";
import { Application } from "../../../interfaces/resources/application";

import styles from "./index.module.scss";

interface InterviewItemProps {
  application: Application;
  className?: string;
  editApplication: (iri: string, req: Partial<Application>) => void;
  interview: string;
  interviews: string[];
}
export const InterviewItem: React.FC<PropsWithChildren<InterviewItemProps>> = (
  props: PropsWithChildren<InterviewItemProps>,
): JSX.Element => {
  const { application, children, className, editApplication, interview, interviews } = props;
  const [open, setOpen] = useState(false);
  const onDateClick = (date: Date): void => {
    interviews[interviews.indexOf(interview)] = format(date, "yyyy-MM-dd HH:mm:ss");
    editApplication(application["@id"], {
      interviewsAt: interviews,
    });
    setOpen(false);
  };
  const interviewDate = parseISO(interview);
  const intDate = format(interviewDate, "dd/MM/yyyy");
  return (
    <>
      <div className={className} onClick={(): void => setOpen(true)}>
        {children}
      </div>
      <Modal size={"mini"} onClose={(): void => setOpen(false)} open={open}>
        <Icon
          name="close"
          onClick={(): void => {
            setOpen(false);
          }}
        />
        <Modal.Content>
          <div className={styles.currentDateContainer}>{intDate}</div>
          <ReactDatePicker
            dateFormat="dd/MM/yyyy"
            inline={true}
            onChange={(date: Date): void => {
              onDateClick(date);
            }}
            open={true}
            placeholderText="Change date"
            selected={new Date(interview)}
          />
        </Modal.Content>
      </Modal>
    </>
  );
};
