import { t } from "@lingui/macro";
import cx from "classnames";
import * as React from "react";
import { useDispatch } from "react-redux";
import { Button } from "semantic-ui-react";

import { SendFirstClientContactEmail } from "../../../actionTypes/contacts";
import { ApiError } from "../../../interfaces/api/error";
import { ApiSuccess } from "../../../interfaces/api/success";
import { Contact } from "../../../interfaces/resources/contact";
import { sendFirstClientContactEmailAction } from "../../../services/api/contact/actions";

import { CopyClip } from "../../Utils/CopyClip";
import GmailMailto from "../../Utils/GmailMailto";

import { useApiSelector, usePrevious } from "../../Utils/hook";
import styles from "./index.module.scss";

interface Props {
  contacts: Contact[];
  title: string;
}

interface EmailObject {
  [key: string]: "default" | "loading" | "success" | "error";
}

export const ContactsListExpanded: React.FC<Props> = ({ contacts, title }: Props) => {
  const dispatch = useDispatch();

  const { apiSuccess, apiErrors } = useApiSelector();

  const defaultObject =
    contacts && contacts.length > 0
      ? contacts.reduce((obj, contact: Contact): EmailObject => ({ ...obj, [contact["@id"]]: "default" }), {})
      : {};
  const [apiStatus, setApiStatus] = React.useState<EmailObject>(defaultObject);

  const prevApiErrors = usePrevious(apiErrors);
  React.useEffect(() => {
    if (prevApiErrors !== apiErrors && apiErrors.length > 0) {
      const currentFailures = apiErrors.filter((req: ApiError) => req.type === SendFirstClientContactEmail.FAILURE);
      if (currentFailures.length > 0) {
        currentFailures.forEach((failureAction: ApiError) => {
          const newApiStatus = { ...apiStatus };
          if (failureAction.iri && newApiStatus[failureAction.iri]) {
            newApiStatus[failureAction.iri] = "error";
            setApiStatus(newApiStatus);
          }
        });
      }
    }
  }, [prevApiErrors, apiErrors, apiStatus]);

  const prevApiSuccess = usePrevious(apiSuccess);
  React.useEffect(() => {
    if (prevApiSuccess !== apiSuccess && apiSuccess.length > 0) {
      const currentSuccess = apiSuccess.filter((req: ApiSuccess) => req.type === SendFirstClientContactEmail.SUCCESS);
      if (currentSuccess.length > 0) {
        currentSuccess.forEach((successAction: ApiSuccess) => {
          const newApiStatus = { ...apiStatus };
          if (successAction.iri && newApiStatus[successAction.iri]) {
            newApiStatus[successAction.iri] = "success";
            setApiStatus(newApiStatus);
          }
        });
      }
    }
  }, [prevApiSuccess, apiSuccess, apiStatus]);

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <h2 className={styles.title}>{t({ id: title })}</h2>
      </div>
      <div>
        {contacts.length ? (
          <ul className={styles.list}>
            {contacts.map((con, i) => (
              <li key={`${con["@id"]}-${i}`}>
                <div>
                  <div className={styles.name}>{`${con.firstname} ${con.lastname}`}</div>
                  <div className={styles.infosContainer}>
                    {con.email ? (
                      <span>
                        <GmailMailto to={con.email} />
                      </span>
                    ) : null}
                    {con.phone ? (
                      <span>
                        <a href={`tel: ${con.phone}`}>{con.phone}</a>
                      </span>
                    ) : null}
                    {con.role ? <span>Role: {con.role}</span> : null}
                  </div>
                </div>
                <div className={styles.ctasContainer}>
                  <div>
                    <CopyClip
                      default={`${process.env.REACT_APP_CLIENT_BASE_URL}/sign-in?regToken=${con.registrationToken}`}
                      title={t`ContactsListExpanded.copyClipTitle`}
                      ctaText="Copier le lien d'inscription"
                    />
                  </div>
                  <div>
                    <Button
                      className={cx(styles.sendEmailCta, {
                        success: apiStatus[con["@id"]] === "success",
                        errors: apiStatus[con["@id"]] === "error",
                      })}
                      disabled={apiStatus[con["@id"]] === "success" || apiStatus[con["@id"]] === "loading"}
                      loading={apiStatus[con["@id"]] === "loading"}
                      icon={
                        apiStatus[con["@id"]] === "success"
                          ? "check"
                          : apiStatus[con["@id"]] === "error"
                          ? "close"
                          : "send"
                      }
                      onClick={(): void => {
                        const newApiStatus = { ...apiStatus };
                        if (newApiStatus[con["@id"]]) {
                          dispatch(sendFirstClientContactEmailAction(con["@id"]));
                          newApiStatus[con["@id"]] = "loading";
                          setApiStatus(newApiStatus);
                        }
                      }}
                      primary
                      content="Envoyer l'email d'inscription"
                      title={t`ContactsListExpanded.title.firstClientContactEmail`}
                    />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          t`offer.contacts.empty`
        )}
      </div>
    </div>
  );
};
