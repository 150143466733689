import { t } from "@lingui/macro";
import * as React from "react";

import { FilterHandlerTalent } from "../../components/Filters/TalentFilterHandler";
import ListTalentContainer from "../../containers/Talents/ListTalentContainer";

import "../../styles/search.scss";

export const TalentSearchScene: React.FC = (): JSX.Element => (
  <div className="searchContainer">
    <div className="searchTitle">{t`TalentSearchScene.title`}</div>
    <div className="contentContainer">
      <div className="searchFilters">
        <FilterHandlerTalent />
      </div>
      <div className="searchResults">
        <ListTalentContainer />
      </div>
    </div>
  </div>
);
