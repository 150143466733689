import { t } from "@lingui/macro";
import * as React from "react";
import { Label } from "../.";
import { Tooltip } from "../../../components/Tooltip";
import { personaStatuses } from "../../../interfaces/resources/talentProfile";
import { transTalent } from "../../../translations/constants/talent";
import styles from "./index.module.scss";

interface Props {
  persona?: string;
  bold?: boolean;
}

export const PersonaLabel: React.FC<Props> = (props: Props) => {
  const { persona, bold = false } = props;
  let label = undefined;
  let tooltipContent = undefined;

  switch (persona) {
    case personaStatuses.offMarket:
      label = <Label bold={bold} type="black" content={t({ id: transTalent.persona[personaStatuses.offMarket] })} />;
      tooltipContent = (
        <div className={styles.tooltip}>
          <div>{t({ id: transTalent.persona[personaStatuses.offMarket] })}</div>
          <div>
            {
              "Talent placé ou en a trouvé ailleurs ou ayant cliqué sur “pas en recherche” sur la modale de réactiv - n'ayant pas cliqué sur en recherche active ou passive sur la modale ou n'ayant pas postulé après être passé off market"
            }
          </div>
        </div>
      );
      break;
    case personaStatuses.inactiveDisoriented:
      label = (
        <Label
          bold={bold}
          type="default"
          content={t({ id: transTalent.persona[personaStatuses.inactiveDisoriented] })}
        />
      );
      tooltipContent = (
        <div className={styles.tooltip}>
          <div>{t({ id: transTalent.persona[personaStatuses.inactiveDisoriented] })}</div>
          <div>
            {"Talent s'étant connecté depuis 1 mois et plus dont l'historique de candidature est jugé non performant"}
          </div>
        </div>
      );
      break;
    case personaStatuses.activeDisoriented:
      label = (
        <Label bold={bold} type="default" content={t({ id: transTalent.persona[personaStatuses.activeDisoriented] })} />
      );
      tooltipContent = (
        <div className={styles.tooltip}>
          <div>{t({ id: transTalent.persona[personaStatuses.activeDisoriented] })}</div>
          <div>
            {"Talent s'étant connecté il y a un mois ou moins dont l'historique de candidature est jugé non performant"}
          </div>
        </div>
      );
      break;
    case personaStatuses.inactiveNeutral:
      label = (
        <Label bold={bold} type="default" content={t({ id: transTalent.persona[personaStatuses.inactiveNeutral] })} />
      );
      tooltipContent = (
        <div className={styles.tooltip}>
          <div>{t({ id: transTalent.persona[personaStatuses.inactiveNeutral] })}</div>
          <div>
            {
              "Talent qui ne s'est pas connecté depuis 1 mois et plus et sur lequel l'historique de candidatures de nous donne pas d'info sur sa performance (0 ou 1 candidature réalisée)"
            }
          </div>
        </div>
      );
      break;
    case personaStatuses.activeNeutral:
      label = (
        <Label bold={bold} type="lightGreen" content={t({ id: transTalent.persona[personaStatuses.activeNeutral] })} />
      );
      tooltipContent = (
        <div className={styles.tooltip}>
          <div>{t({ id: transTalent.persona[personaStatuses.activeNeutral] })}</div>
          <div>
            {
              "Talent s'étant connecté il y a un mois ou moins et sur lequel l'historique de candidatures de nous donne pas d'info sur sa performance"
            }
          </div>
        </div>
      );
      break;
    case personaStatuses.inactivePerformer:
      label = (
        <Label bold={bold} type="red" content={t({ id: transTalent.persona[personaStatuses.inactivePerformer] })} />
      );
      tooltipContent = (
        <div className={styles.tooltip}>
          <div>{t({ id: transTalent.persona[personaStatuses.inactivePerformer] })}</div>
          <div>
            {
              "Talents qui ne s'est pas connecté depuis 1 mois et plus qui est golden ou ancien placé non off market ou ayant un historique de candidatures jugé performant"
            }
          </div>
        </div>
      );
      break;
    case personaStatuses.activePerformer:
      label = (
        <Label bold={bold} type="green" content={t({ id: transTalent.persona[personaStatuses.activePerformer] })} />
      );
      tooltipContent = (
        <div className={styles.tooltip}>
          <div>{t({ id: transTalent.persona[personaStatuses.activePerformer] })}</div>
          <div>
            {
              "Talent s'étant connecté il y a un mois et moins - golden ou ancien placé non off market ou ayant un histo de candidature jugé performant"
            }
          </div>
        </div>
      );
      break;
    default:
      label = null;
      break;
  }

  return !!label ? <Tooltip content={tooltipContent}>{label}</Tooltip> : null;
};
