import { FilterContext } from "../../../../interfaces/api/filter";
import {
  ResponsibilityWithSeniority,
  TalentProfileResponsibility,
} from "../../../../interfaces/resources/responsibility";
import { TalentProfile } from "../../../../interfaces/resources/talentProfile";
import client from "../../client";
import { generateFilterQuery, getCollectionStringValue, getStringValue } from "../../helpers";

export function editTalentProfile(talentProfile: TalentProfile, validation?: string, filterContext?: FilterContext) {
  const query = !!filterContext ? encodeURI(generateFilterQuery(filterContext)) : "";
  return client.put(`${talentProfile["@id"]}${validation ? `/${validation}` : ""}${query}`, {
    ...talentProfile,
    coreResponsibility: getStringValue(talentProfile.coreResponsibility, "@id"),
    desiredPlaces: getCollectionStringValue(talentProfile.desiredPlaces, "@id"),
    experienceYears: getStringValue(talentProfile.experienceYears, "@id"),
    salaryExpectations: getCollectionStringValue(talentProfile.salaryExpectations, "@id"),
  });
}

export function getTalentProfile(talentProfileIri: string, filterContext?: FilterContext) {
  const query = !!filterContext ? encodeURI(generateFilterQuery(filterContext)) : "";
  return client.get(`${talentProfileIri}${query}`);
}

export function createTalentProfileResponsibility(responsibility: ResponsibilityWithSeniority, talentProfile: string) {
  return client.post("/api/v2/talent_profile_responsibilities", {
    responsibility: responsibility["@id"],
    seniority: responsibility.seniority,
    talentProfile,
  });
}

export function deleteTalentProfileResponsibility(talentProfileResponsibility: TalentProfileResponsibility) {
  return client.delete(talentProfileResponsibility["@id"]);
}

export function editTalentProfileResponsibility(talentProfileResponsibility: TalentProfileResponsibility) {
  return client.put(talentProfileResponsibility["@id"], {
    talentProfileResponsibility: talentProfileResponsibility["@id"],
    seniority: talentProfileResponsibility.seniority,
  });
}
