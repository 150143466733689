import { t } from "@lingui/macro";

interface Const {
  status: { [key: string]: string };
}

const transReferral: Const = {
  status: {
    accepted: t`referral.status.accepted`,
    interviews: t`referral.status.interviews`,
    job_found: t`referral.status.job_found`,
    joined: t`referral.status.joined`,
    pending: t`referral.status.pending`,
    refused: t`referral.status.refused`,
  },
};

export default transReferral;
