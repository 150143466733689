import { t } from "@lingui/macro";
import * as React from "react";
import { Accordion, Checkbox, Icon, Item } from "semantic-ui-react";

interface Props {
  activeIndexes?: number[];
  handleClick?: (e: any, titleProps: any) => void;
  index?: number;
  multiple: boolean;
  onChange: (item: any, data: any) => void;
  selected: any;
}

export const HasActiveOffersFilter: React.FC<Props> = ({
  activeIndexes,
  handleClick,
  index,
  multiple,
  onChange,
  selected,
}: Props) => {
  const active = activeIndexes ? activeIndexes.some((item: number) => item === index) : false;
  return (
    <div>
      <Accordion.Title active={active} index={index} onClick={handleClick}>
        <h3>
          {t`hasActiveOffersFilter.title`}
          <Icon name={active ? "chevron up" : "chevron down"} />
        </h3>
      </Accordion.Title>
      <Accordion.Content active={active}>
        <Item.Group>
          <Item>
            <Checkbox
              checked={selected === "true"}
              label={t`hasActiveOffersFilter.active`}
              multiple={multiple}
              onClick={onChange}
              value={"true"}
            />
          </Item>
          <Item>
            <Checkbox
              checked={selected === "false"}
              label={t`hasActiveOffersFilter.inactive`}
              multiple={multiple}
              onClick={onChange}
              value={"false"}
            />
          </Item>
          <Item>
            <Checkbox
              checked={Array.isArray(selected)}
              label={t`hasActiveOffersFilter.all`}
              multiple={multiple}
              onClick={onChange}
              value={""}
            />
          </Item>
        </Item.Group>
      </Accordion.Content>
    </div>
  );
};
