import { FilterContext } from "../../../../interfaces/api/filter";
import { Company, CompanyImage } from "../../../../interfaces/resources/company";
import client from "../../client";
import { generateFilterQuery } from "../../helpers";

export function createCompanyImage(req: Partial<CompanyImage>) {
  return client.post("/api/v2/company_images", req);
}

export function deleteCompanyImage(companyImage: CompanyImage) {
  return client.delete(companyImage["@id"]);
}

export function getCompanyImage(companyImage: CompanyImage) {
  return client.get(companyImage["@id"]);
}

export function getCompanyCompanyImages(company: Company, filterContext?: FilterContext) {
  let query = "";
  if (filterContext) {
    query = encodeURI(generateFilterQuery(filterContext));
  }
  return client.get(`${company["@id"]}/company_images${query}`);
}
