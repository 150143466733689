import { AnyAction } from "redux";
import {
  CreateApplication,
  CreateApplicationAccepted,
  DeleteApplication,
  EditApplication,
  EditApplicationAccepted,
  GetApplicationsAccepted,
  GetApplication,
  GetApplicationForCollection,
  GetApplications,
  PostApplicationMessage,
  ReadApplicationMessages,
  SortApplication,
} from "../../../actionTypes/applications";
import { FilterContext } from "../../../interfaces/api/filter";
import { Application, ApplicationAccepted } from "../../../interfaces/resources/application";
import { ApplicationMessage } from "../../../interfaces/resources/applicationMessages";

export function getApplicationsAction(filterContext: FilterContext, page?: number, concat = false, uniqId = "") {
  return {
    filterContext,
    page,
    concat,
    uniqId,
    type: GetApplications.REQUEST,
  };
}

export function getApplicationsAcceptedAction(
  filterContext: FilterContext,
  page?: number,
  concat = false,
  uniqId = "",
) {
  return {
    filterContext,
    page,
    concat,
    uniqId,
    type: GetApplicationsAccepted.REQUEST,
  };
}

export function getApplicationAction(iri: string) {
  return {
    iri,
    type: GetApplication.REQUEST,
  };
}

export function getApplicationForCollectionAction(iri: string) {
  return {
    iri,
    type: GetApplicationForCollection.REQUEST,
  };
}

export function editApplicationAction(iri: string, req: any) {
  return {
    iri,
    req,
    type: EditApplication.REQUEST,
  };
}

export function sortApplicationAction(iri: string, req: { status: string }, validation?: string) {
  return {
    iri,
    req,
    type: SortApplication.REQUEST,
    validation,
  };
}

export function createApplicationAction(req: Partial<Application>) {
  return {
    req,
    type: CreateApplication.REQUEST,
  };
}

export function deleteApplicationAction(iri: string) {
  return {
    iri,
    type: DeleteApplication.REQUEST,
  };
}

interface OnPostApplicationMessageProps {
  message: string;
  applicationIri: string;
  coreUserIri: string;
  clientEnabled: boolean;
}
interface PostApplicationMessageProps extends OnPostApplicationMessageProps {
  type: string;
}
export const onPostApplicationMessage = (values: OnPostApplicationMessageProps): PostApplicationMessageProps => {
  return {
    message: values.message,
    applicationIri: values.applicationIri,
    coreUserIri: values.coreUserIri,
    clientEnabled: values.clientEnabled,
    type: PostApplicationMessage.REQUEST,
  };
};

interface onReadApplicationMessagesProps {
  messages: ApplicationMessage[];
  coreUserIri: string;
}
interface ReadApplicationMessagesProps extends onReadApplicationMessagesProps {
  type: string;
}

export const onReadApplicationMessages = (values: onReadApplicationMessagesProps): ReadApplicationMessagesProps => {
  return {
    messages: values.messages,
    coreUserIri: values.coreUserIri,
    type: ReadApplicationMessages.REQUEST,
  };
};

export function createApplicationAcceptedAction(applicationAccepted: Partial<ApplicationAccepted>, form?: string) {
  return {
    applicationAccepted,
    form,
    type: CreateApplicationAccepted.REQUEST,
  };
}

export function createApplicationAcceptedSuccessAction(applicationAccepted: ApplicationAccepted, action: AnyAction) {
  return {
    applicationAccepted,
    form: action.form,
    type: CreateApplicationAccepted.SUCCESS,
  };
}

export function editApplicationAcceptedAction(applicationAccepted: Partial<ApplicationAccepted>, form?: string) {
  return {
    req: applicationAccepted,
    form,
    type: EditApplicationAccepted.REQUEST,
  };
}

export function editApplicationAcceptedSuccessAction(applicationAccepted: ApplicationAccepted, action: AnyAction) {
  return {
    applicationAccepted,
    form: action.form,
    type: EditApplicationAccepted.SUCCESS,
  };
}
