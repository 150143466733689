import {
  CreateTalentProfileResponsibility,
  DeleteTalentProfileResponsibility,
  EditTalentProfile,
  EditTalentProfileResponsibility,
  GetTalentProfile,
} from "../../../../actionTypes/talentProfile";
import { FilterContext } from "../../../../interfaces/api/filter";
import {
  ResponsibilityWithSeniority,
  TalentProfileResponsibility,
} from "../../../../interfaces/resources/responsibility";

import { TalentProfile } from "../../../../interfaces/resources/talentProfile";

export function editTalentProfileAction(
  req: Partial<TalentProfile>,
  form?: string,
  validation?: string,
  filterContext?: FilterContext,
) {
  return {
    iri: req["@id"],
    form,
    req,
    filterContext,
    type: EditTalentProfile.REQUEST,
    validation,
  };
}

export function editTalentProfileSuccessAction(talentProfile: TalentProfile, form?: string) {
  return {
    iri: talentProfile["@id"],
    form,
    talentProfile,
    type: EditTalentProfile.SUCCESS,
  };
}

export function getTalentProfileAction(talentProfileIri: string, filterContext?: FilterContext) {
  return {
    filterContext,
    talentProfile: talentProfileIri,
    type: GetTalentProfile.REQUEST,
  };
}

export function getTalentProfileSuccessAction(talentProfile: TalentProfile) {
  return {
    talentProfile,
    type: GetTalentProfile.SUCCESS,
  };
}

export function createTalentProfileResponsibilityAction(
  responsibility: Partial<ResponsibilityWithSeniority>,
  talentProfile: string,
) {
  return {
    responsibility,
    talentProfile,
    type: CreateTalentProfileResponsibility.REQUEST,
  };
}

export function createTalentProfileResponsibilitySuccessAction(
  talentProfileResponsibility: TalentProfileResponsibility,
) {
  return {
    talentProfileResponsibility,
    type: CreateTalentProfileResponsibility.SUCCESS,
  };
}

export function deleteTalentProfileResponsibilityAction(responsibility: Partial<TalentProfileResponsibility>) {
  return {
    responsibility,
    type: DeleteTalentProfileResponsibility.REQUEST,
  };
}

export function deleteTalentProfileResponsibilitySuccessAction(talentProfileResponsibility: string) {
  return {
    talentProfileResponsibility,
    type: DeleteTalentProfileResponsibility.SUCCESS,
  };
}

export function editTalentProfileResponsibilityAction(responsibility: Partial<TalentProfileResponsibility>) {
  return {
    responsibility,
    type: EditTalentProfileResponsibility.REQUEST,
  };
}

export function editTalentProfileResponsibilitySuccessAction(responsibility: TalentProfileResponsibility) {
  return {
    responsibility,
    type: EditTalentProfileResponsibility.SUCCESS,
  };
}
