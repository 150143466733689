import { t } from "@lingui/macro";
import cx from "classnames";
import { parseISO } from "date-fns";
import format from "date-fns/format";
import { Field, Form, Formik } from "formik";
import * as React from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { CreateLinkedinResume } from "../../../actionTypes/linkedInProfile";

import { GetQuizAnswers } from "../../../actionTypes/quizAnswer";
import { File } from "../../../components/Form/Formik/File";
import { useApiSelector } from "../../../components/Utils/hook";
import LoaderComp from "../../../components/Utils/Loader";
import { ApiError } from "../../../interfaces/api/error";
import { LinkedinResume } from "../../../interfaces/resources/linkedinResume";
import { Talent } from "../../../interfaces/resources/talent";
import { createLinkedinResumeAction } from "../../../services/api/talent/linkedInProfile/actions";
import globalStyles from "../../../styles/global.module.scss";

import styles from "./index.module.scss";

export const LinkedInResume: React.FC = () => {
  const talent: Talent | null = useSelector((state: any) => state.talent);
  const [linkedinResume, setLinkedinResume] = React.useState<LinkedinResume | null>(null);
  const { apiPendingRequests } = useApiSelector();
  const dispatch = useDispatch();
  const addLinkedinResume = (iri: string, file: string) => dispatch(createLinkedinResumeAction(iri, file));
  const date = linkedinResume?.updatedAt && format(parseISO(linkedinResume.updatedAt), "dd/MM/yyyy");
  const loading = apiPendingRequests.some((req) => req.type === GetQuizAnswers.REQUEST);
  const initialValues = {
    documentFile: undefined,
  };
  const { apiErrors } = useApiSelector();
  const [error, setError] = React.useState<boolean | string>(false);
  const submitForm = (values: any) => {
    if (!!talent) {
      addLinkedinResume(talent["@id"], values);
    }
  };

  React.useEffect(() => {
    if (talent && talent.linkedinResumes) {
      setLinkedinResume(talent.linkedinResumes[0]);
    }
  }, [talent]);

  React.useEffect(() => {
    if (apiErrors.some((e: ApiError) => e.type === CreateLinkedinResume.FAILURE)) {
      const apiError = apiErrors.find((e) => CreateLinkedinResume.FAILURE === e.type);
      if (apiError) {
        setError(apiError.payload.message);
      }
    }
  }, [apiErrors, setError]);

  return (
    <div className={cx("blockContainer", styles.quizAnswerContainer)}>
      <div className={styles.header}>
        <div className={styles.titleDateContainer}>
          <h2 className={styles.title}>
            <ReactMarkdown
              components={{
                p: React.Fragment,
              }}
            >
              {t`LinkedInResume.title`}
            </ReactMarkdown>
          </h2>
          {date ? <span>Importé le : {date}</span> : null}
        </div>
        <div>
          <Formik initialValues={initialValues} onSubmit={submitForm}>
            {({ values }): JSX.Element => (
              <Form>
                <div className={styles.uploadFormContent}>
                  <div>
                    <Field
                      accept="application/pdf"
                      component={File}
                      documentUrl={talent && talent.documentFile}
                      name="documentFile"
                      className={styles.fileInput}
                    />
                  </div>
                  <div>
                    <button
                      type="submit"
                      className={cx(globalStyles.cta, globalStyles.primaryCta)}
                      disabled={!!!values.documentFile}
                    >
                      Importer le cv
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {error ? (
        <div>
          <div className={cx(styles.message, styles.errorMessage)}>
            <h4>{t`EditCandidateProfileModal.error.header`}</h4>
            <p>
              {t`EditCandidateProfileModal.error.content`} {error}
            </p>
          </div>
        </div>
      ) : null}{" "}
      {loading ? (
        <LoaderComp />
      ) : linkedinResume ? (
        <div>
          <div className={styles.part}>
            <div className={styles.partTitle}>{"⚠️ Vérifiez que le CV correspond au talent ⚠️"}</div>
            <div className={styles.partText}>
              Nom du CV importé :{" "}
              <strong>
                {!!linkedinResume.name
                  ? linkedinResume.name
                  : "une erreur est survenue dans le décodage du nom, vérifie bien les infos du CV"}
              </strong>
            </div>
          </div>
          {linkedinResume.summary ? (
            <div className={styles.part}>
              <div className={styles.partTitle}>Résumé</div>
              <div className={styles.partText}>{linkedinResume.summary}</div>
            </div>
          ) : null}
          {linkedinResume.experiences ? (
            <div className={styles.part}>
              <div className={styles.partTitle}>Expériences</div>
              <div>
                {linkedinResume.experiences.map((experience) => {
                  return (
                    <div className={styles.subPart} key={`${experience.companyName} - ${experience.jobTitle}`}>
                      <div className={styles.subPartTitle}>
                        {experience.companyName} - {experience.jobTitle}
                      </div>
                      {experience.city || (experience.startDate && experience.endDate) ? (
                        <div className={styles.additionalInfosPart}>
                          {experience.city ? <div>{experience.city}</div> : null}
                          {experience.startDate && experience.endDate ? (
                            <div>
                              ({format(parseISO(experience.startDate), "MM/yyyy")} -{" "}
                              {format(parseISO(experience.endDate), "MM/yyyy")})
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                      {experience.description ? (
                        <div className={styles.subPartText}>{experience.description}</div>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
          {linkedinResume.educations ? (
            <div className={styles.part}>
              <div className={styles.partTitle}>Formations</div>
              <div>
                {linkedinResume.educations.map((education) => {
                  return (
                    <div className={styles.subPart} key={education.schoolName}>
                      <div className={styles.subPartTitle}>
                        {education.schoolName ? education.schoolName : null} -{" "}
                        {education.degreeName ? education.degreeName : null}
                      </div>
                      {education.startDate && education.endDate ? (
                        <div className={styles.additionalInfosPart}>
                          {education.startDate && education.endDate ? (
                            <div>
                              ({format(parseISO(education.startDate), "MM/yyyy")} -{" "}
                              {format(parseISO(education.endDate), "MM/yyyy")})
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
          {linkedinResume.languages?.length > 0 ? (
            <div className={styles.part}>
              <div className={styles.partTitle}>Langues</div>
              <div className={styles.subPart}>
                {linkedinResume.languages.map((language) => {
                  return (
                    <div key={language.name}>
                      <h5>
                        {language.name}
                        {language.proficiency ? ` - ${language.proficiency}` : null}
                      </h5>
                      <span></span>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
