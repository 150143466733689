import { LOCATION_CHANGE } from "redux-first-history";

import { CreateApplication, EditApplication, SortApplication } from "../../../actionTypes/applications";
import { DeleteTalentFromToContact, GetHuntTalents } from "../../../actionTypes/hunt";
import { GetTalentForCollection } from "../../../actionTypes/talents";

import { applicationStatuses } from "../../../interfaces/resources/application";
import { pageType } from "../../../interfaces/resources/hunt";
import { Talent } from "../../../interfaces/resources/talent";

export function talentsToContact(state: Talent[] = [], action: any) {
  switch (action.type) {
    case GetHuntTalents.SUCCESS:
      return action.talentsToContact;
    case DeleteTalentFromToContact.DEFAULT:
      return state.filter((e: Talent) => e["@id"] !== action.iri);
    case CreateApplication.SUCCESS:
    case EditApplication.SUCCESS:
    case SortApplication.SUCCESS:
      if (action?.pathname?.includes(pageType.contactTalent) || action?.pathname?.includes(pageType.contactAllTalent)) {
        return action.application.status !== applicationStatuses.toContact
          ? state.filter((item: Talent) => item["@id"] !== action.application.talent["@id"])
          : state;
      } else if (
        action?.pathname?.includes(pageType.huntTalent)
        || action?.pathname?.includes(pageType.huntAllTalent)
        || action.application.status === applicationStatuses.toContact
      ) {
        const talent = state.find((e: Talent) => e["@id"] === action.application.talent["@id"]);
        return talent ? state : [...state, action.application.talent];
      }
      return state;
    case GetTalentForCollection.SUCCESS:
      const index = state.findIndex((a: Talent) => a["@id"] === action.talent["@id"]);
      const newTalents = [...state];
      if (index !== -1) {
        newTalents[index] = { ...newTalents[index], ...action.talent };
      }
      return newTalents;
    default:
      return state;
  }
}

export function contactedTalents(state: Talent[] = [], action: any) {
  switch (action.type) {
    case GetHuntTalents.SUCCESS:
      return action.contactedTalents;
    case SortApplication.SUCCESS:
      if (action.application.status === applicationStatuses.contacted) {
        const talent = action.application.talent;
        talent.applications = [action.application];
        return [talent, ...state];
      }
      return state;
    case LOCATION_CHANGE:
      return [];
    default:
      return state;
  }
}
