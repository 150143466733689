import { t } from "@lingui/macro";
import cx from "classnames";
import { format } from "date-fns";
import * as React from "react";
import { ImWhatsapp } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GoldenTag } from "../../components/Design/GoldenTag";
import { Talent } from "../../interfaces/resources/talent";
import { State } from "../../interfaces/state";
import { extractPageFromIri } from "../../services/api/helpers";
import { editTalentProfileAction } from "../../services/api/talent/talentProfile/actions";
import { transTalent } from "../../translations/constants/talent";
import { PersonProfileImg } from "../personProfileImg";

import styles from "./index.module.scss";

interface Props {
  talent: Talent;
}

export const NoQualifTalentItem: React.FC<Props> = (props: Props) => {
  const { talent } = props;
  const dispatch = useDispatch();
  const talentProfiles = useSelector((state: State) => state.talentProfiles);
  const tProfile = !!talentProfiles[talent.talentProfile["@id"]]
    ? talentProfiles[talent.talentProfile["@id"]]
    : talent.talentProfile;
  const whatsappPhone = !!talent.phone ? talent.phone.replace("+", "").replace(/\s/g, "") : undefined;
  const inputName = `noQualifContacted-${talent["@id"]}`;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!!tProfile) {
      dispatch(
        editTalentProfileAction(
          {
            "@id": tProfile["@id"],
            noQualifContacted: e.target.checked,
          },
          undefined,
          undefined,
          [
            {
              multiple: true,
              name: "groups",
              value: ["internal:read:talent_profile:noqualif:collection"],
            },
          ],
        ),
      );
    }
  };

  return (
    <div className={styles.talentItem}>
      <div className={styles.header}>
        {!!tProfile ? (
          <div className={styles.headerItem}>
            <input
              type="checkbox"
              name={inputName}
              id={inputName}
              className={styles.contactedInput}
              defaultChecked={!!tProfile.noQualifContacted}
              onChange={onChange}
            />
            <label htmlFor={inputName} className={cx(styles.contactedLabel, styles.itemText)}>
              {t`noQualifTalentItem.contactedLabel.text`}
            </label>
          </div>
        ) : null}
        {talent.linkedInProfile?.url ? (
          <div className={styles.headerItem}>
            <a
              className={cx(styles.linkedInLink)}
              href={talent.linkedInProfile.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t`noQualifTalentItem.seeLinkedIn`}
            </a>
          </div>
        ) : null}
        {talent.lastTalentStatusEvent ? (
          <div className={cx(styles.headerItem, styles.sortedItem, styles.itemText)}>
            <span>
              {t({
                id: "noQualifTalentItem.sortedDate",
                values: { date: format(new Date(talent.lastTalentStatusEvent.createdAt), "dd/MM/yyyy") },
              })}
            </span>
          </div>
        ) : null}
      </div>
      <div className={styles.body}>
        <div className={styles.imgInfosPart}>
          <div className={styles.imageContainer}>
            <PersonProfileImg image={talent.image} alt={`${talent.firstname} ${talent.lastname}`} />
          </div>
          <div className={styles.infosContainer}>
            <div className={styles.nameGoldenContainer}>
              <h4 className={styles.itemText}>
                <Link
                  to={extractPageFromIri("talents", talent["@id"])}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.name}
                >{`${talent.firstname} ${talent.lastname}`}</Link>
              </h4>
              {!!tProfile && tProfile.golden ? (
                <div className={styles.goldenContainer}>
                  <GoldenTag className={styles.goldenTag} />
                </div>
              ) : null}
            </div>
            <div className={cx(styles.itemText, styles.phoneContainer)}>
              <a
                className={styles.whatsappBtn}
                target="_blank"
                rel="noopener noreferrer"
                href={`https://wa.me/${whatsappPhone}`}
                title={t`Action.whatsapp.contact.text`}
              >
                <ImWhatsapp />
              </a>
              <span>{talent.phone}</span>
            </div>
            <div className={styles.itemText}>{talent.email}</div>
          </div>
        </div>
        <div className={styles.dataPart}>
          <div className={styles.dataItem}>
            <div className={styles.dataName}>{t`noQualifTalentItem.timAssigned`}</div>
            <div className={styles.dataNb}>{!!talent.talentManager ? talent.talentManager.firstname : "Aucun"}</div>
          </div>
          <div className={cx(styles.dataItem, styles.processItem)}>
            <div className={styles.dataName}>{t`noQualifTalentItem.status`}</div>
            <div className={styles.dataNb}>{t({ id: transTalent.status[talent.status] })}</div>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={cx(styles.footerItem, styles.signUpItem)}>
          <div className={styles.itemText}>
            {t({
              id: "noQualifTalentItem.signUpDate",
              values: { date: format(new Date(talent.createdAt), "dd/MM/yyyy") },
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
