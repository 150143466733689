import { t } from "@lingui/macro";

interface Const {
  callTypes: { [key: string]: string };
  queuedReason: { [key: string]: string };
  referer: { [key: string]: string };
  segments: { [key: string]: string };
  status: { [key: string]: string };
  searchStatus: { [key: string]: string };
  searchStatusReason: { [key: string]: string };
  reactivationStatus: { [key: string]: string };
  persona: { [key: string]: string };
}

export const transTalent: Const = {
  callTypes: {
    call_15_min: t`talent.callTypes.call_15_min`,
    call_20_min: t`talent.callTypes.call_20_min`,
    call_30_min: t`talent.callTypes.call_30_min`,
    call_45_min: t`talent.callTypes.call_45_min`,
  },
  queuedReason: {
    queued_reason_city: t`talent.queuedReason.city`,
    queued_reason_role: t`talent.queuedReason.role`,
    queued_reason_student: t`talent.queuedReason.student`,
  },
  referer: {
    "question.referrer.event": t`talent.question.referrer.event`,
    "question.referrer.google": t`talent.question.referrer.google`,
    "question.referrer.other": t`talent.question.referrer.other`,
    "question.referrer.press": t`talent.question.referrer.press`,
    "question.referrer.social.facebook": t`talent.question.referrer.social.facebook`,
    "question.referrer.social.instagram": t`talent.question.referrer.social.instagram`,
    "question.referrer.social.linkedin": t`talent.question.referrer.social.linkedin`,
    "question.referrer.sponsorship": t`talent.question.referrer.sponsorship`,
    "question.referrer.word_of_mouth": t`talent.question.referrer.word_of_mouth`,
    "question.referrer.wttj": t`talent.question.referrer.wttj`,
  },
  segments: {
    segment_1: t`talent.segments.segment_1`,
    segment_2: t`talent.segments.segment_2`,
    segment_4: t`talent.segments.segment_4`,
  },
  searchStatus: {
    job_found: t`talent.searchStatus.job_found`,
    job_found_elinoi: t`talent.searchStatus.job_found_elinoi`,
    not_searching: t`talent.searchStatus.not_searching`,
    passive_searching: t`talent.searchStatus.passive_searching`,
    searching: t`talent.searchStatus.searching`,
    urgent: t`talent.searchStatus.urgent`,
  },
  searchStatusReason: {
    job_found: t`talent.searchStatusReason.job_found`,
    keep_current_job: t`talent.searchStatusReason.keep_current_job`,
    project_launch: t`talent.searchStatusReason.project_launch`,
    other: t`talent.searchStatusReason.other`,
    application_accepted: t`talent.searchStatusReason.application_accepted`,
    application_created: t`talent.searchStatusReason.application_created`,
    applied: t`talent.searchStatusReason.applied`,
    inactive: t`talent.searchStatusReason.inactive`,
  },
  status: {
    status_accepted: t`talent.status.accepted`,
    status_blacklisted: t`talent.status.blacklisted`,
    status_call_booked: t`talent.status.call_booked`,
    status_to_c_level: t`talent.status.c_level`,
    status_denied: t`talent.status.denied`,
    status_pending: t`talent.status.pending`,
    status_queued: t`talent.status.queued`,
    status_to_call: t`talent.status.to_call`,
    status_to_onboard: t`talent.status.to_onboard`,
    status_to_test: t`talent.status.to_test`,
    status_retry: t`talent.status.retry`,
  },
  reactivationStatus: {
    to_contact: t`talent.reactivationStatus.toContact`,
    do_nothing: t`talent.reactivationStatus.doNothing`,
    refused: t`talent.reactivationStatus.refused`,
    searching: t`talent.reactivationStatus.activeSearching`,
    passive_searching: t`talent.reactivationStatus.passiveSearching`,
    not_searching: t`talent.reactivationStatus.notSearching`,
  },
  persona: {
    off_market: t`talent.persona.offMarket`,
    active_performer: t`talent.persona.activePerformer`,
    active_neutral: t`talent.persona.activeNeutral`,
    active_disoriented: t`talent.persona.activeDisoriented`,
    inactive_performer: t`talent.persona.inactivePerformer`,
    inactive_neutral: t`talent.persona.inactiveNeutral`,
    inactive_disoriented: t`talent.persona.inactiveDisoriented`,
  },
};
