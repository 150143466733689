import { LOCATION_CHANGE } from "redux-first-history";

import { SaveTalentOnContact } from "../../../actionTypes/hunt";
import { huntType } from "../../../interfaces/resources/hunt";
import { Talent } from "../../../interfaces/resources/talent";

export function talentsOnContact(state: Talent[] = [], action: any) {
  switch (action.type) {
    case SaveTalentOnContact.DEFAULT:
      return [...state, action.talent];
    case LOCATION_CHANGE:
      return [];
    default:
      return state;
  }
}
export function huntState(state: string | null = null, action: any) {
  switch (action.type) {
    case LOCATION_CHANGE:
      const pathname = action.payload.location.pathname;
      if (pathname.includes("offers")) {
        return huntType.offer;
      }
      return state;
    default:
      return state;
  }
}
