import { AnyAction } from "redux";
import { GetSalaryExpectations } from "../../../actionTypes/salaryExpectations";
import { SalaryExpectations } from "../../../interfaces/resources/salaryExpectations";

export function salaryExpectations(state: SalaryExpectations[] = [], action: AnyAction) {
  switch (action.type) {
    case GetSalaryExpectations.SUCCESS:
      return !!action.page ? [...state, ...action.salaryExpectations] : action.salaryExpectations;
    default:
      return state;
  }
}
