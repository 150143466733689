import { t } from "@lingui/macro";
import * as React from "react";
import { Header, Modal, ModalProps } from "semantic-ui-react";
import DataExport, { Props as DataExportProps } from "../";

interface Props<T> extends DataExportProps<T>, Pick<ModalProps, "open" | "onClose"> {}

export function DataExportModal<T>(props: Props<T>) {
  const { open, onClose, ...dataExportProps } = props;

  return (
    <Modal open={open} onClose={onClose} size="large">
      <Header content={t`dataExport.modal.title`} />
      <Modal.Content>{open && <DataExport {...dataExportProps} />}</Modal.Content>
    </Modal>
  );
}
