import { t } from "@lingui/macro";

export const algoliaAttributesTrans = {
  "talentProfile.experience": t`Algolia.attributes.experience`,
  "talentProfile.lastQualification.bridges": t`Algolia.attributes.bridges`,
  "talentProfile.lastQualification.context": t`Algolia.attributes.context`,
  "talentProfile.lastQualification.conclusion": t`Algolia.attributes.conclusion`,
  "talentProfile.lastQualification.idealJob": t`Algolia.attributes.idealJob`,
  "talentProfile.coreResponsibility.value": t`Algolia.attributes.coreResponsibility`,
  "talentProfile.responsibilities.responsibility.value": t`Algolia.attributes.responsibilities`,
  "talentProfile.expectedRemuneration.fullPackage": t`Algolia.attributes.exptectedFullPackage`,
  "talentProfile.golden": t`Algolia.attributes.golden`,
  "talentProfile.persona": t`Algolia.attributes.persona`,
  "talentProfile.desiredPlaces.value": t`Algolia.attributes.cities`,
  "educations.schoolName": t`Algolia.attributes.schools`,
  "talentManager.firstname": t`Algolia.attributes.tim`,
  "linkedinResume.experiences.companyName": t`Algolia.attributes.companyName`,
  "linkedinResume.languages.name": t`Algolia.attributes.languages`,
  noTalentManager: t`Algolia.attributes.noTalentManager`,
  beenPlaced: t`Algolia.attributes.beenPlaced`,
  searchStatus: t`Algolia.attributes.searchStatus`,
  status: t`Algolia.attributes.status`,
};
