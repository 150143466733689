import { t } from "@lingui/macro";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { Accordion } from "semantic-ui-react";
import { GetCompanies } from "../../../actionTypes/companies";
import { GetCoreUsers } from "../../../actionTypes/coreUsers";

import { GetDesiredPlaces } from "../../../actionTypes/desiredPlaces";
import { GetExperienceYears } from "../../../actionTypes/experienceYears";
import { GetResponsibilities } from "../../../actionTypes/responsibilities";
import { GetSalaryExpectations } from "../../../actionTypes/salaryExpectations";
import { FilterClause } from "../../../interfaces/api/filter";
import { CoreUser } from "../../../interfaces/resources/coreUser";
import { getCompaniesAction } from "../../../services/api/company/actions";
import { getCoreUsersAction } from "../../../services/api/coreUser/actions";
import { getDesiredPlacesAction } from "../../../services/api/desiredPlaces/actions";

import { getExperienceYearsAction } from "../../../services/api/experienceYears/actions";
import { getResponsibilitiesAction } from "../../../services/api/responsibilities/actions";
import { getSalaryExpectationsAction } from "../../../services/api/salaryExpectations/actions";
import { filterTalentsByNameAction } from "../../../services/api/talent/actions";
import { applyFilterAction } from "../../../services/state/actions";

import { useApiSelector, useGetResourceHook } from "../../Utils/hook";
import { CompanyFilter } from "../CompanyFilter";
import { DesiredPlacesFilter } from "../DesiredPlacesFilter";
import { EducationFilter } from "../EducationFilter";
import { ExperienceYearsFilter } from "../ExperienceYearsFilter";
import { GoldenTalentFilter } from "../GoldenTalentFilter";
import { GoodMatchFilter } from "../GoodMatchFilter";
import { ResponsibilitiesFilter } from "../ResponsibilitiesFilter";
import { SalaryExpectationsFilter } from "../SalaryExpectationsFilter";
import { SearchStatusFilter } from "../SearchStatusFilter";
import { TalentNameFilter } from "../TalentNameFilter";
import { TalentStatusFilter } from "../TalentStatusFilter";
import { TimFilter } from "../TimFilter";

export const FilterHandlerTalent: React.FC = () => {
  const dispatch = useDispatch();

  const [activeIndexes, setActiveIndexes] = React.useState([0]);

  const applyFilter = (fc: FilterClause<any>): AnyAction => dispatch(applyFilterAction(fc));
  const filterTalentsByName = (text: string): AnyAction => dispatch(filterTalentsByNameAction(text));
  const getDesiredPlaces = (): AnyAction => dispatch(getDesiredPlacesAction());
  const getExperienceYears = (): AnyAction => dispatch(getExperienceYearsAction());
  const getResponsibilities = (): AnyAction => dispatch(getResponsibilitiesAction());
  const getSalaryExpectations = (): AnyAction => dispatch(getSalaryExpectationsAction());
  const getCompanies = (): AnyAction => dispatch(getCompaniesAction([]));
  const getCoreUsers = (): AnyAction => dispatch(getCoreUsersAction());
  const { apiErrors, apiPendingRequests, apiSuccess } = useApiSelector();
  const desiredPlaces = useSelector((state: any) => state.desiredPlaces);
  const experienceYears = useSelector((state: any) => state.experienceYears);
  const filterContext = useSelector((state: any) => state.filterContext);
  const responsibilities = useSelector((state: any) => state.responsibilities);
  const salaryExpectations = useSelector((state: any) => state.salaryExpectations);
  const talentNames = useSelector((state: any) => state.talentNames);
  const companies = useSelector((state: any) => state.companies);
  const coreUsers = useSelector((state: any) => state.coreUsers);

  useGetResourceHook(apiErrors, apiPendingRequests, apiSuccess, GetDesiredPlaces, desiredPlaces, getDesiredPlaces);
  useGetResourceHook(apiErrors, apiPendingRequests, apiSuccess, GetCompanies, companies, getCompanies);
  useGetResourceHook(apiErrors, apiPendingRequests, apiSuccess, GetCoreUsers, companies, getCoreUsers);
  useGetResourceHook(
    apiErrors,
    apiPendingRequests,
    apiSuccess,
    GetExperienceYears,
    experienceYears,
    getExperienceYears,
  );
  useGetResourceHook(
    apiErrors,
    apiPendingRequests,
    apiSuccess,
    GetResponsibilities,
    responsibilities,
    getResponsibilities,
  );
  useGetResourceHook(
    apiErrors,
    apiPendingRequests,
    apiSuccess,
    GetSalaryExpectations,
    salaryExpectations,
    getSalaryExpectations,
  );
  const handleClick = (_: unknown, { index }: any): void => {
    setActiveIndexes(
      activeIndexes.some((item: number) => item === index)
        ? activeIndexes.filter((item: number) => item !== index)
        : [...activeIndexes, index],
    );
  };

  const changeFilterContext = (type: string, data: any): void => {
    let newValue: any;
    if (Array.isArray(data)) {
      newValue = data.map((elem: any) => (elem.hasOwnProperty("@id") ? elem["@id"] : elem.value));
    } else {
      newValue = data.school ? data.school["@id"] : data.hasOwnProperty("@id") ? data["@id"] : data.value;
    }

    const newClause: FilterClause<any> = {
      multiple: !!data.multiple || Array.isArray(data),
      name: type,
      value: newValue,
    };
    applyFilter(newClause);
  };

  const changeFilterContextForType = (type: string) => {
    return (data: any): void => {
      return changeFilterContext(type, data);
    };
  };

  const getSelected = (type: string, data?: any) => {
    const filterClause = filterContext.filter((clause: FilterClause<any>) => type === clause.name)[0];
    if (Array.isArray(data)) {
      if (data) {
        return filterClause ? data.filter((item: any) => filterClause.value.some((e: any) => e === item["@id"])) : [];
      }
      return filterClause ? filterClause.value : [];
    }
    return filterClause ? filterClause.value : null;
  };

  return (
    <div>
      <h2>{t`CompanySearchScene.filter`}</h2>
      <TalentNameFilter
        apiPendingRequests={apiPendingRequests}
        applyFilter={applyFilter}
        compact={false}
        filterTalentsByName={filterTalentsByName}
        talentNames={talentNames}
      />
      <Accordion fluid>
        <DesiredPlacesFilter
          activeIndexes={activeIndexes}
          handleClick={handleClick}
          index={1}
          items={desiredPlaces}
          onChange={changeFilterContextForType("talentProfile.desiredPlaces")}
          selected={getSelected("talentProfile.desiredPlaces", desiredPlaces)}
        />
        <CompanyFilter
          activeIndexes={activeIndexes}
          handleClick={handleClick}
          index={9}
          items={companies}
          onChange={changeFilterContextForType("applications.offer.company")}
          selected={getSelected("applications.offer.company", companies)}
        />
        <TimFilter
          activeIndexes={activeIndexes}
          handleClick={handleClick}
          index={10}
          items={coreUsers.filter((c: CoreUser) => c.roles.includes("ROLE_COACH"))}
          onChange={changeFilterContextForType("talentManager")}
          selected={getSelected("talentManager", coreUsers)}
        />
        <GoldenTalentFilter
          activeIndexes={activeIndexes}
          handleClick={handleClick}
          index={8}
          onChange={changeFilterContextForType("talentProfile.golden")}
          selected={getSelected("talentProfile.golden")}
        />
        <GoodMatchFilter
          activeIndexes={activeIndexes}
          handleClick={handleClick}
          index={12}
          onChange={changeFilterContextForType("applications.goodMatch")}
          selected={getSelected("applications.goodMatch")}
        />
        <ExperienceYearsFilter
          activeIndexes={activeIndexes}
          handleClick={handleClick}
          index={3}
          items={experienceYears}
          multiple={true}
          onChange={changeFilterContextForType("talentProfile.experienceYears")}
          selected={getSelected("talentProfile.experienceYears")}
        />
        <ResponsibilitiesFilter
          activeIndexes={activeIndexes}
          apiPendingRequests={apiPendingRequests}
          handleClick={handleClick}
          index={4}
          items={responsibilities}
          onChange={changeFilterContextForType("talentProfile.coreResponsibility")}
          selected={getSelected("talentProfile.coreResponsibility", responsibilities)}
        />
        <SalaryExpectationsFilter
          activeIndexes={activeIndexes}
          handleClick={handleClick}
          index={5}
          items={salaryExpectations}
          multiple={true}
          onChange={changeFilterContextForType("talentProfile.salaryExpectations")}
          selected={getSelected("talentProfile.salaryExpectations")}
        />
        <TalentStatusFilter
          activeIndexes={activeIndexes}
          handleClick={handleClick}
          index={6}
          multiple={true}
          onChange={changeFilterContextForType("status")}
          selected={getSelected("status")}
        />
        <SearchStatusFilter
          activeIndexes={activeIndexes}
          handleClick={handleClick}
          index={13}
          multiple={true}
          onChange={changeFilterContextForType("searchStatus")}
          selected={getSelected("searchStatus")}
        />
        <EducationFilter
          activeIndexes={activeIndexes}
          handleClick={handleClick}
          index={11}
          items={[]}
          onChange={changeFilterContextForType("educations.school")}
        />
      </Accordion>
    </div>
  );
};
