import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { GetTalent } from "../../actionTypes/talents";
import { TalentDetails } from "../../blocks/talentDetails";
import { TalentInfo } from "../../blocks/talentInfo";
import { TalentLoadingPlaceholder } from "../../components/Design/LoadingPlaceholder";
import { useApiSelector, useGetResourceHook } from "../../components/Utils/hook";
import { LinkedInProfile } from "../../interfaces/resources/linkedInProfile";
import { Talent, talentSegments, talentStatuses } from "../../interfaces/resources/talent";
import { TalentProfile } from "../../interfaces/resources/talentProfile";
import { State } from "../../interfaces/state";
import { clearTalentsStateAction, getTalentAction } from "../../services/api/talent/actions";
import styles from "./index.module.scss";
import { OfferPushModalCta } from "./OfferPushModal";

interface Props {
  deleteTalent: () => void;
  getTalent: (iri: string) => void;
  iri: string;
  linkedInProfile: LinkedInProfile;
  talent: Talent;
  talentProfile: TalentProfile;
}

export const TalentPage: React.FC<Props> = ({ deleteTalent, iri, linkedInProfile }: Props) => {
  const { apiErrors, apiPendingRequests, apiSuccess } = useApiSelector();
  const dispatch = useDispatch();
  const talent = useSelector((state: State) => (state.talent && state.talent["@id"] === iri ? state.talent : null));
  const getTalent = (talentIri: string): void => {
    dispatch(getTalentAction(talentIri));
  };
  useGetResourceHook(apiErrors, apiPendingRequests, apiSuccess, GetTalent, talent, getTalent, [iri]);
  const cleanState = () => {
    dispatch(clearTalentsStateAction());
  };

  React.useEffect(() => {
    return () => {
      cleanState();
    };
  }, []);

  return !talent ? (
    <TalentLoadingPlaceholder />
  ) : (
    <div className={styles.container}>
      <TalentInfo
        deleteTalent={deleteTalent}
        linkedInProfile={linkedInProfile}
        makeUnclickable={true}
        talent={talent}
      />
      <div className={styles.temporaryMessageContainer}>
        {[talentStatuses.toOnboard, talentStatuses.callBooked].includes(talent.status)
        && talent.segment === talentSegments.segment1 ? (
          <div className={styles.pendinCallText}>
            Talent en attente de qualification
            {talent.talentManager?.firstname ? ` avec ${talent.talentManager.firstname}` : null}
          </div>
        ) : null}
        {[talentStatuses.toOnboard, talentStatuses.callBooked].includes(talent.status) && talent.sorter ? (
          <div className={styles.pendinCallText}>
            {talent.sorter.firstname ? ` Trié par ${talent.sorter.firstname}` : null}
          </div>
        ) : null}
      </div>

      {[talentStatuses.toTest, talentStatuses.callBooked].includes(talent.status)
      && talent.segment === talentSegments.segment2 ? (
        <div className={styles.pendinCallText}>
          Talent en attente de call {talent.talentManager?.firstname ? ` avec ${talent.talentManager.firstname}` : null}
        </div>
      ) : null}
      <div className={styles.pushOfferCtaContainer}>
        <OfferPushModalCta className={styles.pushOfferCta} talent={talent} />
      </div>
      <TalentDetails />
    </div>
  );
};
