import { call, put, takeLatest } from "redux-saga/effects";

import { GetInterviewStepTypes } from "../../../actionTypes/interviewStepType";
import { apiFailureAction } from "../actions";
import { getSuccessType } from "../helpers";
import * as apiClient from "./api";

export function* getInterviewStepTypes(action: any): any {
  try {
    const response = yield call(apiClient.getInterviewStepTypes);
    yield put({
      interviewStepTypes: response.data["hydra:member"],
      type: getSuccessType(action),
    });
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export default function* interviewStepTypeSaga() {
  yield takeLatest(GetInterviewStepTypes.REQUEST, getInterviewStepTypes);
}
