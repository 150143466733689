import cx from "classnames";
import { FieldProps, useField } from "formik";
import * as React from "react";
import { FormInputProps } from "semantic-ui-react";
import { Violation } from "../../../../interfaces/api/violation";
import { getViolationMessage } from "../../../Utils/hook";
import ErrorMessage from "../ErrorMessage";
import styles from "./index.module.scss";

export interface InputProps extends FieldProps, FormInputProps {
  className?: string;
  id?: string;
  isSubLabel?: boolean;
  isRequired?: boolean;
  violations?: Violation[];
}

const Input: React.FC<InputProps> = ({
  className = "",
  disabled,
  field,
  form: { setFieldTouched },
  id,
  isSubLabel = false,
  isRequired = true,
  label,
  placeholder,
  type,
  violations,
}: InputProps) => {
  const [, meta] = useField(field.name);
  const error = violations && getViolationMessage(field.name, violations);

  return (
    <div className={cx(className, { error: (meta.touched && !!meta.error) || !!error, disabled })}>
      {label ? (
        <label className={cx("formLabel", { subLabel: !!isSubLabel })} htmlFor={field.name}>
          <>
            {label} {isRequired && !isSubLabel ? "*" : null}
          </>
        </label>
      ) : undefined}
      <div className="formInputContainer">
        <input
          {...field}
          id={id ? id : field.name}
          type={type}
          placeholder={placeholder}
          className={styles.input}
          onBlur={() => setFieldTouched(field.name, true)}
        />
      </div>
      <ErrorMessage name={field.name} message={error} />
    </div>
  );
};

export default Input;
