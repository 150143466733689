import { t } from "@lingui/macro";
import { differenceInBusinessDays, format } from "date-fns";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { GetTalents } from "../../actionTypes/talents";
import { NoQualifTalentItem } from "../../blocks/noQualifTalentItem";
import { DataExportModal } from "../../components/DataExport/DataExportModal";
import { useApiSelector } from "../../components/Utils/hook";
import LoaderComp from "../../components/Utils/Loader";
import { coreUserRoles } from "../../interfaces/resources/coreUser";
import { talentSortedStatuses, talentStatuses } from "../../interfaces/resources/talent";
import { State } from "../../interfaces/state";
import { getCoreUsersAction } from "../../services/api/coreUser/actions";
import { extractIdFromIri } from "../../services/api/helpers";
import { getTalentsAction } from "../../services/api/talent/actions";
import globalStyles from "../../styles/global.module.scss";
import { transTalent } from "../../translations/constants/talent";
import styles from "./index.module.scss";

export const TalentMissingQualifPage: React.FC = () => {
  const dispatch = useDispatch();
  const { apiPendingRequests } = useApiSelector();
  const talentProfiles = useSelector((state: State) => state.talentProfiles);
  const coreUsers = useSelector((state: State) => state.coreUsers)
    .filter((item) => item.roles.includes(coreUserRoles.tim) && item.enabled)
    .map((cu) => ({ label: cu.firstname, value: cu["@id"] }));
  const coreUsersOptions = [{ label: "Aucun", value: "none" }, ...coreUsers];
  const statusOptions = talentSortedStatuses.map((ts) => ({
    label: t({ id: transTalent.status[ts] }),
    value: ts,
  }));
  const [timFilter, setTimfilter] = React.useState<any[]>([]);
  const [statusFilter, setStatusfilter] = React.useState<any[]>([talentStatuses.toOnboard]);
  const [hideContacted, setHideContacted] = React.useState(true);
  const [contactedFilter, setContactedFilter] = React.useState<string[]>([]);
  const talents = useSelector((state: State) => state.talents);
  const filteredTalents = talents
    .filter((ta) => {
      if (
        !!!ta.lastTalentStatusEvent?.createdAt
        || differenceInBusinessDays(new Date(), new Date(ta.lastTalentStatusEvent.createdAt)) < 10
      ) {
        return false;
      }

      if (
        timFilter.length
        && !timFilter.includes(ta.talentManager?.["@id"])
        && !(timFilter.includes("none") && !!!ta.talentManager)
      ) {
        return false;
      }
      if (statusFilter.length && !statusFilter.includes(ta.status)) {
        return false;
      }

      if (hideContacted && contactedFilter.length && contactedFilter.includes(ta.talentProfile["@id"])) {
        return false;
      }
      return true;
    })
    .sort((a, b) => {
      const bDate = b.lastTalentStatusEvent?.createdAt;
      const aDate = a.lastTalentStatusEvent?.createdAt;
      return !!bDate && !!aDate ? new Date(bDate).getTime() - new Date(aDate).getTime() : 0;
    });

  const [dataExportModal, setDataExportModal] = React.useState(false);
  const [initLoading, setInitLoading] = React.useState(true);
  const talentsLoading = apiPendingRequests.some((e: any) => GetTalents.REQUEST === e.type);

  const onChangeHideContacted = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHideContacted(e.target.checked);
  };

  React.useEffect(() => {
    const contactedIri = Object.keys(talentProfiles).filter((talentProfileIri: string) => {
      return talentProfiles[talentProfileIri]?.noQualifContacted;
    });
    setContactedFilter(contactedIri);
  }, [talentProfiles]);

  React.useEffect(() => {
    dispatch(
      getTalentsAction([
        {
          multiple: true,
          name: "groups",
          value: ["internal:read:talents:noqualif:collection"],
        },
        {
          multiple: true,
          name: "status",
          value: talentSortedStatuses,
        },
      ]),
    );
    dispatch(getCoreUsersAction());
  }, [dispatch]);

  React.useEffect(() => {
    if (apiPendingRequests.some((e: any) => GetTalents.REQUEST === e.type) && initLoading) {
      setInitLoading(false);
    }
  }, [apiPendingRequests, initLoading]);

  return (
    <div className={styles.myTalentsPage}>
      <div className={globalStyles.mainTitleContainer}>
        <h1 className={globalStyles.mainTitle}>{t`TalentMissingQualifPage.title`}</h1>
      </div>
      {initLoading || talentsLoading ? (
        <div>
          <LoaderComp />
        </div>
      ) : (
        <>
          <div className={styles.subFiltersContainer}>
            <div>
              <div className={styles.hideContactedFilterContainer}>
                <input
                  type="checkbox"
                  name="hide-contacted-input"
                  id="hide-contacted-input"
                  className={styles.hideContactedInput}
                  defaultChecked={hideContacted}
                  onChange={onChangeHideContacted}
                />
                <label htmlFor="hide-contacted-input" className={styles.hideContactedLabel}>
                  {t`TalentMissingQualifPage.hideContacedFilterLabel.text`}
                </label>
              </div>
            </div>
          </div>
          <div className={styles.filterExportContainer}>
            <div>
              <Select
                classNamePrefix="tim-filter"
                isMulti={true}
                isSearchable={true}
                isClearable={true}
                placeholder="Tim assigné"
                onChange={(datas) => {
                  const values: any[] = [];
                  datas.map((d) => {
                    values.push(d.value);
                    return d;
                  });
                  setTimfilter(values);
                }}
                options={coreUsersOptions}
              />
            </div>
            <div>
              <Select
                classNamePrefix="status-filter"
                isMulti={true}
                isSearchable={true}
                isClearable={true}
                placeholder="Status du talent"
                onChange={(datas) => {
                  const values: any[] = [];
                  datas.map((d) => {
                    values.push(d.value);
                    return d;
                  });
                  setStatusfilter(values);
                }}
                defaultValue={[
                  { label: t({ id: transTalent.status[talentStatuses.toOnboard] }), value: talentStatuses.toOnboard },
                ]}
                options={statusOptions}
              />
            </div>
            <div className={styles.exportContainer}>
              <DataExportModal
                initialValues={filteredTalents.map((talent) => {
                  const data = {
                    [t`dataExport.noQualifTalents.firstName`]: talent.firstname || "",
                    [t`dataExport.noQualifTalents.lastName`]: talent.lastname || "",
                    [t`dataExport.noQualifTalents.email`]: talent.email || "",
                    [t`dataExport.noQualifTalents.phone`]: talent.phone || "",
                    [t`dataExport.noQualifTalents.status`]: talent.status || "",
                    [t`dataExport.noQualifTalents.assignedTalentManager`]:
                      (talent.talentManager && talent.talentManager.firstname) ?? "aucun",
                    [t`dataExport.noQualifTalents.sortedDate`]: talent.lastTalentStatusEvent
                      ? format(new Date(talent.lastTalentStatusEvent.createdAt), "dd/MM/yyyy")
                      : "",
                  };

                  if (talent.linkedInProfile) {
                    data[t`dataExport.noQualifTalents.linkedin.url`] = talent.linkedInProfile.url || "";
                  }

                  data[t`dataExport.noQualifTalents.admin.link`] = `https://admin.elinoi.com/talents/${extractIdFromIri(
                    talent["@id"],
                  )}`;
                  data[t`dataExport.noQualifTalents.signUpDate`] = talent.createdAt
                    ? format(new Date(talent.createdAt), "dd/MM/yyyy")
                    : "";

                  return data;
                })}
                onClose={(): void => {
                  setDataExportModal(false);
                }}
                open={dataExportModal}
                refreshOnInitialValuesUpdate
              />
              <button
                type="button"
                onClick={(): void => {
                  setDataExportModal(true);
                }}
                className={styles.dataExportBtn}
              >
                {t`TalentMissingQualifPage.exportCta.text`}
              </button>
            </div>
          </div>
          <div className={styles.pageContent}>
            <ul className={styles.talentsList}>
              {filteredTalents.map((talent, i) => {
                return (
                  <li key={`myTalentsPage-talent-item-${talent["@id"]}-${i}`} className={styles.talentItem}>
                    <NoQualifTalentItem talent={talent} />
                  </li>
                );
              })}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};
