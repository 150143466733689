import { t } from "@lingui/macro";
import { isWithinInterval, subMonths } from "date-fns";
import { ApiError } from "../../interfaces/api/error";
import { ApiRequest } from "../../interfaces/api/request";
import { ApiSuccess } from "../../interfaces/api/success";
import { Application } from "../../interfaces/resources/application";
import { CoreUser } from "../../interfaces/resources/coreUser";
import { LinkedInProfile } from "../../interfaces/resources/linkedInProfile";
import { Offer } from "../../interfaces/resources/offer";
import { Talent } from "../../interfaces/resources/talent";
import { TalentProfile } from "../../interfaces/resources/talentProfile";
import { identifyAction } from "../../services/api/helpers";

export function getTalentOfferUrl(offer: Offer) {
  return `${process.env.REACT_APP_TALENT_BASE_URL}/mon-compte/mes-opportunites/${offer.slug}`;
}

export const isReinitializeable = (
  apiErrors: ApiError[],
  apiPendingRequests: ApiRequest[],
  apiSuccess: ApiSuccess[],
) => {
  return !(
    apiErrors.some((e: ApiError) => identifyAction(e).match(/^(CREATE|EDIT).*/))
    || apiPendingRequests.some((e: ApiRequest) => identifyAction(e).match(/^(CREATE|EDIT).*/))
    || apiSuccess.some((e: ApiSuccess) => identifyAction(e).match(/^(CREATE|EDIT).*/))
  );
};

export const areTwoObjectArraysEqual = (
  arr1: any[],
  arr2: any[],
  objectEqualCallback: (v1: any, v2: any) => boolean,
) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  return arr1.reduce((acc, val) => {
    return acc && arr2.some((e) => objectEqualCallback(val, e));
  }, true);
};

export const getIsOutdated = (tp?: TalentProfile | null) => {
  const lastTalentProfileEvent = tp?.lastTalentProfileEvent;
  const lastQualificationDate = tp?.lastQualificationDate;
  const sixMonthsAgo = subMonths(new Date(), 6);
  const now = new Date();
  return !(
    (lastTalentProfileEvent
      && isWithinInterval(new Date(lastTalentProfileEvent.createdAt), {
        start: sixMonthsAgo,
        end: now,
      }))
    || (lastQualificationDate
      && isWithinInterval(new Date(lastQualificationDate), {
        start: sixMonthsAgo,
        end: now,
      }))
  );
};
export const getHeadline = (tp?: TalentProfile | null, lp?: LinkedInProfile | null) => {
  return tp?.currentJob && tp?.currentCompany
    ? `${tp.currentJob} @ ${tp.currentCompany}`
    : lp?.headline
    ? lp.headline
    : t`linkedInProfile.headline.empty`;
};

export const getCorrectTalentProfile = (
  talent: Talent | null,
  talentProfileState: TalentProfile | null,
  talentProfilesState: { [iri: string]: TalentProfile | undefined },
) => {
  const tpId = talent?.talentProfile["@id"];
  const talentProfile = tpId
    ? !!talentProfilesState[tpId]
      ? talentProfilesState[tpId]
      : talentProfileState?.["@id"] === tpId
      ? talentProfileState
      : talent?.talentProfile
    : null;
  return talentProfile;
};

export const getCorrectTalent = (talentIri: string, talentState: Talent | null, talentsState: Talent[] = []) => {
  const stateIndex = talentsState.findIndex((tal) => tal["@id"] === talentIri);
  const talent =
    talentState && talentIri === talentState?.["@id"] ? talentState : stateIndex >= 0 ? talentsState[stateIndex] : null;

  return talent;
};

export const hasUnreadMessageInApp = (ap: Application, currentUser: CoreUser | null): boolean => {
  let hasUnread = false;
  ap.applicationMessages?.forEach((apM) => {
    if (
      (!!!apM.coreUser || apM.coreUser["@id"] !== currentUser?.["@id"])
      && !apM.coreUserRead?.includes(currentUser?.["@id"] || "")
    ) {
      hasUnread = true;
    }
  });

  return hasUnread;
};
