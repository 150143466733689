import { t } from "@lingui/macro";
import * as React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { TalentStatusEventsProps } from "../../interfaces/resources/talentStatusEvents";
import { State } from "../../interfaces/state";
import { extractPageFromIri } from "../../services/api/helpers";
import { transTalent } from "../../translations/constants/talent";
import styles from "./index.module.scss";

export const CallPendingTalents: React.FC = (): JSX.Element => {
  const currentUser = useSelector((state: State) => state.currentUser);
  const [talentStatusEvents, setTalentStatusEvents] = React.useState<TalentStatusEventsProps[]>([]);
  React.useEffect(() => {
    if (!currentUser) {
      return;
    }
    if (Array.isArray(currentUser.talentStatusEvents)) {
      setTalentStatusEvents(currentUser.talentStatusEvents);
    }
  }, [currentUser]);
  return (
    <div>
      <div className={styles.titleContainer}>
        <h2 className={styles.title}>Tes talents en attente de call</h2>
      </div>
      <div>
        {Array.isArray(talentStatusEvents) && talentStatusEvents.length ? (
          <ul>
            {talentStatusEvents.map((tse, i) => {
              const data = tse.data ? JSON.parse(tse.data) : null;
              return tse.talent ? (
                <li key={`${tse.talent["@id"]}-name-${i}`} className={styles.grid}>
                  <div>
                    <Link
                      to={extractPageFromIri("talents", tse.talent["@id"])}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.name}
                    >{`${tse.talent.firstname} ${tse.talent?.lastname}`}</Link>
                  </div>
                  <div>
                    <div>{data ? t({ id: transTalent.callTypes[data.callType] }) : null}</div>
                    <div>{data ? (data.inHouse ? "Dans les bureaux" : "Au téléphone") : null}</div>
                  </div>
                  <div className={styles.noteContainer}>{data.note ? data.note : null}</div>
                </li>
              ) : null;
            })}
          </ul>
        ) : (
          <div className={styles.emptyResults}>Aucun talent en attente</div>
        )}
      </div>
    </div>
  );
};
