import { t } from "@lingui/macro";
import { Application, applicationStatuses } from "../../../interfaces/resources/application";
import { searchStatuses } from "../../../interfaces/resources/talent";

export interface ColumnProps {
  id: string;
  status: string | undefined; // (applicationStatuses)
  acceptedStatuses: string[]; // (default = [])
  applications: Application[]; // (default = [])
  refusedApplications: Application[]; // (default = [])
  isGrouped: boolean; // (default = false)
  groupData:
    | {
        key: string;
        values: { id: string; name: string; count?: number }[] | undefined;
      }
    | undefined; // (default = undefined)
  refuseOptions: string[]; // (default = [applicationStatuses.refusedElinoi, applicationStatuses.refusedTalent])
  isDragDisabled: boolean; // (default = false)
  hideSearchStatus: string[]; // (default = talentSearchStatus.not_searching)
  title: string;
}
export type ColumnsProps = {
  [key: string]: ColumnProps;
};

const defaultColumn: ColumnProps = {
  id: "",
  title: "",
  status: undefined,
  acceptedStatuses: [],
  applications: [],
  refusedApplications: [],
  isGrouped: false,
  isDragDisabled: false,
  groupData: undefined,
  refuseOptions: [
    applicationStatuses.refusedElinoi,
    applicationStatuses.refusedTalent,
    applicationStatuses.refusedStartup,
  ],
  hideSearchStatus: [],
};

export const columns: ColumnsProps = {
  "column-0": {
    ...defaultColumn,
    id: "column-0",
    title: t`ApplicationDashboard.columns.0.title`,
    acceptedStatuses: [applicationStatuses.interesting, applicationStatuses.contacted],
    isGrouped: true,
  },
  "column-1": {
    ...defaultColumn,
    id: "column-1",
    title: t`ApplicationDashboard.columns.1.title`,
    status: applicationStatuses.applied,
    refuseOptions: [applicationStatuses.refusedElinoi, applicationStatuses.refusedStartup],
    acceptedStatuses: [applicationStatuses.applied],
    hideSearchStatus: [searchStatuses.not_searching],
  },
  "column-2": {
    ...defaultColumn,
    id: "column-2",
    title: t`ApplicationDashboard.columns.2.title`,
    status: applicationStatuses.toPitch,
    acceptedStatuses: [applicationStatuses.toPitch, applicationStatuses.callBooked],
  },
  "column-3": {
    ...defaultColumn,
    id: "column-3",
    title: t`ApplicationDashboard.columns.3.title`,
    status: applicationStatuses.awaitingShortlist,
    acceptedStatuses: [applicationStatuses.awaitingShortlist, applicationStatuses.shortlistReady],
    isGrouped: true,
  },
  "column-a": {
    ...defaultColumn,
    id: "column-a",
    title: t`ApplicationDashboard.columns.a.title`,
    status: applicationStatuses.interviews,
    acceptedStatuses: [applicationStatuses.shortlisted, applicationStatuses.interviews],
    refuseOptions: [applicationStatuses.refusedStartup, applicationStatuses.refusedTalent],
  },
  "column-b": {
    ...defaultColumn,
    id: "column-b",
    status: applicationStatuses.interviews,
    refuseOptions: [applicationStatuses.refusedStartup, applicationStatuses.refusedTalent],
    acceptedStatuses: [applicationStatuses.interviews],
    title: t`ApplicationDashboard.columns.b.title`,
  },
  "column-c": {
    ...defaultColumn,
    id: "column-c",
    title: t`ApplicationDashboard.columns.c.title`,
    status: applicationStatuses.offer,
    refuseOptions: [applicationStatuses.refusedStartup, applicationStatuses.refusedTalent],
    acceptedStatuses: [applicationStatuses.offer],
  },
  "column-d": {
    ...defaultColumn,
    id: "column-d",
    title: t`ApplicationDashboard.columns.d.title`,
    status: applicationStatuses.accepted,
    refuseOptions: [applicationStatuses.refusedStartup, applicationStatuses.refusedTalent],
    acceptedStatuses: [applicationStatuses.accepted],
  },
};

export const columnOrders = {
  "pre-process": ["column-0", "column-1", "column-2", "column-3"],
  "in-process": ["column-a", "column-b", "column-c", "column-d"],
};
export const allColumnOrders = [...columnOrders["pre-process"], ...columnOrders["in-process"]];

export const columnTransitions: { [key: string]: string[] } = {
  "column-0": ["column-2", "column-3"],
  "column-1": ["column-2", "column-3"],
  "column-2": ["column-1", "column-3"],
  "column-3": ["column-1", "column-2"],
  "column-a": ["column-c", "column-d"],
  "column-b": ["column-c", "column-d"],
  "column-c": ["column-a", "column-b", "column-d"],
  "column-d": ["column-a", "column-b", "column-c"],
};
