import { ActionType } from "../interfaces/api/actionType";

export const CreateTalentCard: ActionType = {
  FAILURE: "CREATE_TALENT_CARD_FAILURE",
  REQUEST: "CREATE_TALENT_CARD_REQUEST",
  SUCCESS: "CREATE_TALENT_CARD_SUCCESS",
};

export const EditTalentCard: ActionType = {
  FAILURE: "EDIT_TALENT_CARD_FAILURE",
  REQUEST: "EDIT_TALENT_CARD_REQUEST",
  SUCCESS: "EDIT_TALENT_CARD_SUCCESS",
};

export const GetTalentCard: ActionType = {
  FAILURE: "GET_TALENT_CARD_FAILURE",
  REQUEST: "GET_TALENT_CARD_REQUEST",
  SUCCESS: "GET_TALENT_CARD_SUCCESS",
};

export const GetTalentCards: ActionType = {
  FAILURE: "GET_TALENT_CARDS_FAILURE",
  REQUEST: "GET_TALENT_CARDS_REQUEST",
  SUCCESS: "GET_TALENT_CARDS_SUCCESS",
};
