import { t } from "@lingui/macro";
import * as React from "react";
import { Link } from "react-router-dom";
import { Image, List } from "semantic-ui-react";

import { FilterContext } from "../../../../interfaces/api/filter";
import { Application } from "../../../../interfaces/resources/application";
import { Talent } from "../../../../interfaces/resources/talent";
import { extractPageFromIri } from "../../../../services/api/helpers";

import transApplication from "../../../../translations/constants/application";

import { getCompanyImage } from "../../../App/Image";

import "./index.scss";

interface Props {
  getTalentApplications?: (talent: Talent, filterContext?: FilterContext) => void;
  talent?: Talent;
  applications: Application[];
  handleModalClose?: () => void;
}

export class TalentApplicationList extends React.PureComponent<Props> {
  public componentDidMount = () => {
    if (this.props.applications.length === 0 && this.props.talent && this.props.getTalentApplications) {
      this.props.getTalentApplications(this.props.talent);
    }
  };

  public render() {
    const { applications } = this.props;
    return applications.length > 0 ? (
      <List className="applicationList">
        {applications.map((application: Application, index: number) => {
          return (
            application.offer && (
              <List.Item key={index}>
                <Link className="emphasis" to={extractPageFromIri("companies", application.offer.company["@id"])}>
                  <Image size="mini" {...getCompanyImage(application.offer.company.image)} />
                </Link>
                <List.Description>
                  <Link className="emphasis" to={extractPageFromIri("companies", application.offer.company["@id"])}>
                    {application.offer.company.name}
                  </Link>
                  <> &mdash; </>
                  <Link to={extractPageFromIri("offers", application.offer["@id"])}>{application.offer.title}</Link>
                  {" | "}
                  <span className="emphasis">{t({ id: transApplication.status[application.status] })}</span>
                </List.Description>
              </List.Item>
            )
          );
        })}
      </List>
    ) : (
      <div className="emptyList">{t`applications.empty`}</div>
    );
  }
}
