import { t } from "@lingui/macro";
import { format, formatDistance, parseISO } from "date-fns";
import { fr } from "date-fns/locale";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Icon, Item, Segment } from "semantic-ui-react";

import { GetReferrals } from "../../../actionTypes/referrals";
import { getUserImage } from "../../../components/App/Image";
import defaultImage from "../../../components/App/Image/img/user.png";
import { useApiSelector, usePrevious } from "../../../components/Utils/hook";
import { ApiError } from "../../../interfaces/api/error";
import { ApiRequest } from "../../../interfaces/api/request";
import { Referral } from "../../../interfaces/resources/referral";
import { Talent } from "../../../interfaces/resources/talent";
import { extractPageFromIri } from "../../../services/api/helpers";
import { getReferralsAction } from "../../../services/api/referral/actions";
import transReferral from "../../../translations/constants/referral";
import { transTalent } from "../../../translations/constants/talent";

import "./index.scss";

const Referrals: React.FC = () => {
  const talent: Talent | null = useSelector((state: any) => state.talent);
  const referrals: Referral[] = useSelector((state: any) => state.referrals);

  const { apiErrors, apiPendingRequests } = useApiSelector();
  const dispatch = useDispatch();

  const previousTalent = usePrevious(talent);
  React.useEffect(() => {
    if (
      !apiErrors.some((e: ApiError) => e.type === GetReferrals.FAILURE)
      && !apiPendingRequests.some((e: ApiRequest) => e.type === GetReferrals.REQUEST)
      && talent
      && talent["@id"]
      && (!previousTalent || previousTalent["@id"] !== talent["@id"])
    ) {
      dispatch(getReferralsAction(talent));
    }
  }, [apiErrors, apiPendingRequests, talent, previousTalent, dispatch]);

  const loading =
    apiPendingRequests.length > 0 && apiPendingRequests.some((e: ApiRequest) => GetReferrals.REQUEST === e.type);
  return (
    <Segment basic loading={loading}>
      {referrals && referrals.length > 0 ? (
        <Item.Group className="referralsContainer">
          {referrals.map((referral: Referral) => (
            <Item className="referral" key={referral["@id"]}>
              {referral.referee ? (
                <Item.Image avatar {...getUserImage(referral.referee.image)} />
              ) : (
                <Item.Image avatar src={defaultImage} />
              )}
              <Item.Content>
                <Item.Header>
                  {referral.referee ? (
                    <Link to={extractPageFromIri("talents", referral.referee["@id"])}>
                      {`${referral.referee.firstname} ${referral.referee.lastname}`}
                      <span className="statusLabel">{t({ id: transTalent.status[referral.referee.status] })}</span>
                    </Link>
                  ) : (
                    referral.refereeEmail
                  )}
                </Item.Header>
                <Item.Description>
                  {referral.referee ? (
                    <div>
                      <Icon name="linkedin square" />
                      <a href={referral.referee.linkedInProfile.url}>
                        {referral.referee.linkedInProfile.headline || t`linkedInProfile.headline.empty`}
                      </a>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </Item.Description>
                <Item.Extra>
                  <Icon name="calendar alternate" />
                  {formatDistance(parseISO(referral.createdAt), new Date(), { locale: fr })}
                </Item.Extra>
              </Item.Content>
              <div className={referral.status !== "job_found" ? "referralStatus" : "referralStatus positive"}>
                {t({ id: transReferral.status[referral.status] })}
                {referral.status === "job_found" ? (
                  <div className="date">{format(parseISO(referral.updatedAt), "dd/MM/yyyy")}</div>
                ) : null}
              </div>
            </Item>
          ))}
        </Item.Group>
      ) : (
        <div className="empty">{t`referrals.empty`}</div>
      )}
    </Segment>
  );
};

export default Referrals;
