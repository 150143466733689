import { connect } from "react-redux";
import { Dispatch } from "redux";
import { getFormValues } from "redux-form";

import { OFFER_ADVICE_FORM } from "../../../../components/Offers/OfferForm";
import AdviceForm from "../../../../components/Offers/OfferForm/AdviceForm";
import { InterviewStep } from "../../../../interfaces/resources/interviewStep";
import { Offer, OfferAdviceForm, OfferTalentView } from "../../../../interfaces/resources/offer";
import { getInterviewStepTypesAction } from "../../../../services/api/interviewStepType/actions";
import { editOfferAction } from "../../../../services/api/offer/actions";

function getInitialValues(
  offer: Offer | null,
  offerTalentView: OfferTalentView | null,
  interviewSteps: InterviewStep[],
) {
  return {
    "@id": offer ? offer["@id"] : undefined,
    interviewSteps,
    offerTalentView: {
      "@id": offerTalentView ? offerTalentView["@id"] : undefined,
      elinoiAdvice: offerTalentView ? offerTalentView.elinoiAdvice : "",
      ytProcess: offerTalentView ? offerTalentView.ytProcess : "",
    },
  };
}

const mapStateToProps = (state: any) => {
  return {
    apiErrors: state.apiErrors,
    apiPendingRequests: state.apiPendingRequests,
    apiSuccess: state.apiSuccess,
    enableReinitialize: true,
    formValues: getFormValues(OFFER_ADVICE_FORM)(state),
    initialValues: getInitialValues(state.offer, state.offerTalentView, state.interviewSteps),
    interviewStepTypes: state.interviewStepTypes,
    offer: state.offer,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getInterviewStepTypes: () => dispatch(getInterviewStepTypesAction()),
    submitOffer: (req: OfferAdviceForm, form?: string, redirectOnSuccess?: boolean | string) => {
      dispatch(editOfferAction(req, form, "", redirectOnSuccess));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdviceForm);
