import { t } from "@lingui/macro";
import * as React from "react";
import { Button, Item, Segment } from "semantic-ui-react";

import { GetTalents } from "../../../actionTypes/talents";

import { FilterClause, FilterContext } from "../../../interfaces/api/filter";
import { ApiRequest } from "../../../interfaces/api/request";
import { Talent } from "../../../interfaces/resources/talent";

import { extractPageNumberFromIri } from "../../../services/api/helpers";

import LoaderComp from "../../Utils/Loader";

import TalentShort from "./TalentShort";

interface Props {
  apiPendingRequests: ApiRequest[];
  talents: any;
  filterContext: FilterContext;
  getTalents: () => void;
  applyFilter: (filterContext: FilterClause<any>) => void;
  talentEdited?: Talent;
  talentView: any;
}

class TalentList extends React.PureComponent<Props, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      talents: [],
    };
  }

  public componentDidMount = () => {
    window.addEventListener("scroll", this.handleOnScroll);
    this.props.getTalents();
  };
  public componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleOnScroll);
  };

  public componentDidUpdate = (prevProps: any) => {
    if (prevProps.filterContext !== this.props.filterContext) {
      this.props.getTalents();
    }
    if (prevProps.talents !== this.props.talents) {
      if (prevProps.filterContext.some((e: FilterClause<any>) => e.name === "page" && e.value)) {
        this.setState((prevState: any) => {
          return {
            talents: [...prevState.talents, ...this.props.talents],
          };
        });
      } else {
        this.setState({ talents: this.props.talents });
      }
    }
  };

  public handleOnScroll = () => {
    const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
    const clientHeight = document.documentElement.clientHeight || window.innerHeight;
    const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

    if (scrolledToBottom) {
      this.loadMore();
    }
  };

  public loadMore = () => {
    const nextPage =
      this.props.talentView && this.props.talentView["hydra:next"]
        ? extractPageNumberFromIri(this.props.talentView["hydra:next"])
        : null;
    if (nextPage) {
      this.props.applyFilter({
        multiple: false,
        name: "page",
        value: nextPage,
      });
    }
  };

  public render(): JSX.Element {
    const loading =
      this.props.apiPendingRequests.length > 0
      && this.props.apiPendingRequests.some((e: any) => e.type === GetTalents.REQUEST);
    return (
      <Segment basic>
        <Item.Group>
          {this.state.talents.map((item: Talent, i: number) => (
            <TalentShort key={i} talent={item} />
          ))}
        </Item.Group>
        {loading ? <LoaderComp /> : this.state.talents.length === 0 ? <div>{t`TalentList.empty`}</div> : null}
        {this.props.talentView && this.props.talentView["hydra:next"] ? (
          <Button onClick={this.loadMore}>{t`TalentList.loadMore`}</Button>
        ) : null}
      </Segment>
    );
  }
}

export default TalentList;
