import { ActionType } from "../interfaces/api/actionType";

export const CreateContact: ActionType = {
  FAILURE: "CREATE_CONTACT_FAILURE",
  REQUEST: "CREATE_CONTACT_REQUEST",
  SUCCESS: "CREATE_CONTACT_SUCCESS",
};

export const EditContact: ActionType = {
  FAILURE: "EDIT_CONTACT_FAILURE",
  REQUEST: "EDIT_CONTACT_REQUEST",
  SUCCESS: "EDIT_CONTACT_SUCCESS",
};

export const DeleteContact: ActionType = {
  FAILURE: "DELETE_CONTACT_FAILURE",
  REQUEST: "DELETE_CONTACT_REQUEST",
  SUCCESS: "DELETE_CONTACT_SUCCESS",
};

export const GetContacts: ActionType = {
  FAILURE: "GET_CONTACTS_FAILURE",
  REQUEST: "GET_CONTACTS_REQUEST",
  SUCCESS: "GET_CONTACTS_SUCCESS",
};

export const SendFirstClientContactEmail: ActionType = {
  FAILURE: "SEND_FIRST_CLIENT_CONTACT_EMAIL_FAILURE",
  REQUEST: "SEND_FIRST_CLIENT_CONTACT_EMAIL_REQUEST",
  SUCCESS: "SEND_FIRST_CLIENT_CONTACT_EMAIL_SUCCESS",
};
