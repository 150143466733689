import { AnyAction } from "redux";
import { LOCATION_CHANGE } from "redux-first-history";

import { EditApplication } from "../../../actionTypes/applications";
import { GetInterviewSteps } from "../../../actionTypes/interviewStep";
import {
  CreateOffer,
  EditOffer,
  FilterByOfferName,
  GetOffer,
  GetOfferInternalView,
  GetOffers,
  GetOfferTalentView,
} from "../../../actionTypes/offers";
import { GetTalentOffers } from "../../../actionTypes/talents";
import { HydraView } from "../../../interfaces/api/hydra-collection";

import { Application } from "../../../interfaces/resources/application";
import { Offer } from "../../../interfaces/resources/offer";

import { routes } from "../../../routes";

export function offers(state: Offer[] = [], action: AnyAction): Offer[] {
  switch (action.type) {
    case GetOffers.SUCCESS:
      if (!action.view.hasOwnProperty("hydra:first") || action.view["@id"] === action.view["hydra:first"]) {
        return action.offers;
      }
      return [...state, ...action.offers];
    case GetOffer.SUCCESS:
      const index = state.findIndex((a) => a["@id"] === action.offer["@id"]);
      const ofrs = [...state];
      if (index >= 0) {
        ofrs[index] = action.offer;
      } else {
        ofrs.push(action.offer);
      }
      return ofrs;
    case GetOffers.FAILURE:
      return state;
    default:
      return state;
  }
}

export function talentOffers(state: Offer[] = [], action: AnyAction): Offer[] {
  switch (action.type) {
    case GetTalentOffers.SUCCESS:
      return action.offers;
    default:
      return state;
  }
}

export function offerView(state: HydraView | null = null, action: AnyAction) {
  switch (action.type) {
    case GetOffers.SUCCESS:
      return action.view || null;
    default:
      return state;
  }
}

export function offerNames(state: Offer[] = [], action: AnyAction): Offer[] {
  switch (action.type) {
    case FilterByOfferName.SUCCESS:
      return action.offerNames["hydra:member"];
    case FilterByOfferName.FAILURE:
      return state;
    default:
      return state;
  }
}

export function offer(state: Offer | null = null, action: AnyAction): Offer | null {
  switch (action.type) {
    case GetOffer.SUCCESS:
    case CreateOffer.SUCCESS:
    case EditOffer.SUCCESS:
      return action.offer;
    case GetInterviewSteps.SUCCESS:
      return state
        ? {
            ...state,
            interviewSteps: action.interviewSteps,
          }
        : state;
    case GetOfferInternalView.SUCCESS:
      return state
        ? {
            ...state,
            offerInternalView: action.offerInternalView,
          }
        : state;
    case GetOfferTalentView.SUCCESS:
      return state
        ? {
            ...state,
            offerTalentView: action.offerTalentView,
          }
        : state;
    case EditApplication.SUCCESS:
      if (
        state
        && state.applications
        && state.applications.some((application: Application) => application["@id"] === action.application["@id"])
      ) {
        const newApplications = [...state.applications];
        const index = state.applications.findIndex(
          (application: Application) => application["@id"] === action.application["@id"],
        );
        newApplications[index] = action.application;
        return {
          ...state,
          applications: newApplications,
        };
      }
      return state;
    case LOCATION_CHANGE:
      return [
        routes.app.home, // equal to /applications/dashboard
        routes.applications.dashboard,
        routes.offers.new,
        routes.offers.search,
      ].includes(action.payload.location.pathname)
        ? null
        : state;
    case GetOffer.FAILURE:
      return state;
    default:
      return state;
  }
}
