import { t } from "@lingui/macro";
import cx from "classnames";
import * as React from "react";
import { BsHeartFill } from "react-icons/bs";
import { Link } from "react-router-dom";

import { applicationStatuses } from "../../../../../../interfaces/resources/application";
import { Offer } from "../../../../../../interfaces/resources/offer";
import { extractPageFromIri } from "../../../../../../services/api/helpers";
import globalStyles from "../../../../../../styles/global.module.scss";
import transApplication from "../../../../../../translations/constants/application";
import { CompanyImg } from "../../../../../companyImg";
import styles from "./index.module.scss";

interface Props {
  checkedContacted: boolean;
  checkedAwaitingShortlist: boolean;
  handleSelectContacted: (offer: Offer, status: string, goodMatch?: boolean) => void;
  handleSelectAwaitingShortlist: (offer: Offer, status: string, goodMatch?: boolean) => void;
  offer: Offer;
  readOnly?: boolean;
}

export const OfferLine: React.FC<Props> = (props: Props) => {
  const {
    checkedAwaitingShortlist,
    checkedContacted,
    handleSelectAwaitingShortlist,
    handleSelectContacted,
    offer,
    readOnly = false,
  } = props;

  const [goodMatch, setGoodMatch] = React.useState<boolean | undefined>(false);
  const onClickContacted = (e: any): void => {
    e.stopPropagation();
    handleSelectContacted(offer, applicationStatuses.contacted, goodMatch);
  };
  const onClickAwaitingShortlist = (e: any): void => {
    e.stopPropagation();
    handleSelectAwaitingShortlist(offer, applicationStatuses.awaitingShortlist, goodMatch);
  };
  return (
    <div className={styles.offerLine}>
      <div className={styles.itemHeader}>
        <div className={styles.companyImgContainer}>
          <CompanyImg src={offer.company?.image?.url} alt={offer.title} />
        </div>
        <div className={styles.offerName}>
          <Link
            className={styles.offerNameLink}
            target="_blank"
            rel="noopener noreferrer"
            to={extractPageFromIri("offers", offer["@id"])}
          >
            {offer.name}
          </Link>
        </div>
        <div className={styles.salaryExpContainer}>
          <div>{offer.printSalaries}</div>
          <div>{offer.printExperienceYears}</div>
        </div>
      </div>
      <div className={styles.itemBody}>
        <div className={cx(styles.bodyItem, styles.goodMatchContainer)}>
          <BsHeartFill
            className={cx(styles.noGoodMatch, { [styles.goodMatchIcon]: goodMatch })}
            onClick={() => setGoodMatch(!goodMatch)}
          />
        </div>
        {!readOnly ? (
          <div className={styles.ctaContainer}>
            <div>
              <button
                type="button"
                className={cx(styles.cta, globalStyles.cta, globalStyles.primaryCta, globalStyles.inverted, {
                  [styles.active]: checkedContacted,
                })}
                onClick={onClickContacted}
              >
                <span>{t({ id: transApplication.status[applicationStatuses.contacted] })}</span>
              </button>
            </div>
            <div>
              <button
                type="button"
                className={cx(styles.cta, globalStyles.cta, globalStyles.primaryCta, globalStyles.inverted, {
                  [styles.active]: checkedAwaitingShortlist,
                })}
                onClick={onClickAwaitingShortlist}
              >
                <span>{t({ id: transApplication.status[applicationStatuses.awaitingShortlist] })}</span>
              </button>
            </div>
          </div>
        ) : null}
        <div className={styles.ownerContainer}>
          AM : {offer.owners && offer.owners.length ? offer.owners.map((owner) => owner.firstname) : null}
        </div>
      </div>
    </div>
  );
};
