import { GetCalendlyEvents } from "../../../actionTypes/calendlyEvents";
import { CalendlyEvent } from "../../../interfaces/resources/calendlyEvent";
import { Talent } from "../../../interfaces/resources/talent";

export function getCalendlyEventsAction(talent: Talent) {
  return {
    talent,
    type: GetCalendlyEvents.REQUEST,
  };
}

export function getCalendlyEventsActionSuccessAction(calendlyEvents: CalendlyEvent[]) {
  return {
    calendlyEvents,
    type: GetCalendlyEvents.SUCCESS,
  };
}
