import { FilterContext } from "../../../../interfaces/api/filter";
import client from "../../client";
import { generateFilterQuery } from "../../helpers";

export function getOfferApplications(iri: string, filterContext: FilterContext = []) {
  const query = encodeURI(generateFilterQuery(filterContext));
  return client.get(`${iri}/applications${query}`);
}

export function getOfferApplication(iri: string) {
  return client.get(iri);
}
