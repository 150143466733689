import { t } from "@lingui/macro";
import algoliasearch from "algoliasearch/lite";
import cx from "classnames";

import * as React from "react";
import { BsInfoLg } from "react-icons/bs";
import { FaRegLightbulb } from "react-icons/fa";
import {
  InstantSearch,
  Index,
  ClearRefinements,
  CurrentRefinements,
  HitsPerPage,
  RefinementList,
  SearchBox,
  Configure,
  RangeInput,
  RefinementListProps,
} from "react-instantsearch-hooks-web";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AlgoliaHelpModalCta } from "../../components/Algolia/algoliaHelpModal";
import { InsightsMiddleware } from "../../components/Algolia/InsightsMiddleware";
import { Refresh } from "../../components/Algolia/Refresh";
import { SingleIndex } from "../../components/Algolia/SingleIndex";
import { usePrevious } from "../../components/Utils/hook";
import LoaderComp from "../../components/Utils/Loader";
import { OfferSelect } from "../../components/Utils/OfferSelect";
import { Offer } from "../../interfaces/resources/offer";
import { talentSegments } from "../../interfaces/resources/talent";
import { State } from "../../interfaces/state";
import { extractIdFromIri, extractPageFromIri } from "../../services/api/helpers";
import { getHuntTalentsAction } from "../../services/api/hunt/actions";
import { getOfferAction } from "../../services/api/offer/actions";
import globalStyles from "../../styles/global.module.scss";
import { algoliaAttributesTrans } from "../../translations/constants/algolia";
import { transTalent } from "../../translations/constants/talent";
import { FilterPanel } from "./FilterPanel";
import styles from "./index.module.scss";

export const algoliaAttributes = {
  cities: "talentProfile.desiredPlaces.value",
  conclusion: "talentProfile.lastQualification.conclusion",
  context: "talentProfile.lastQualification.context",
  coreResponsibility: "talentProfile.coreResponsibility.value",
  responsibilities: "talentProfile.responsibilities.responsibility.value",
  experience: "talentProfile.experience",
  exptectedFullPackage: "talentProfile.expectedRemuneration.fullPackage",
  golden: "talentProfile.golden",
  idealJob: "talentProfile.lastQualification.idealJob",
  persona: "talentProfile.persona",
  schools: "educations.schoolName",
  searchStatus: "searchStatus",
  status: "status",
  bridges: "talentProfile.lastQualification.bridges",
  tim: "talentManager.firstname",
  noTalentManager: "noTalentManager",
  companyName: "linkedinResume.experiences.companyName",
  languages: "linkedinResume.languages.name",
  linkedinSummary: "linkedinResume.summary",
  linkedinExperiencesDescription: "linkedinResume.experiences.description",
  beenPlaced: "beenPlaced",
};

export const AlgoliaSearchPage: React.FC = () => {
  const dispatch = useDispatch();
  const selectedOffer = useSelector((state: State) => state.offer);
  const currentUser = useSelector((state: State) => state.currentUser);
  const talentsToContact = useSelector((state: State) => state.talentsToContact);
  const prevSelectedOffer = usePrevious(selectedOffer);
  const [showTips, setShowTips] = React.useState(false);
  const [initialUiState, setInitialUiState] = React.useState<{ [key: string]: any }>({});
  const [loading, setLoading] = React.useState(false);
  const [ruleOut, setRuleOut] = React.useState<string>("");
  const [toContactUrl, setToContactUrl] = React.useState<string>("");
  const searchClient = algoliasearch(
    `${process.env.REACT_APP_ALGOLIA_APP_ID}`,
    `${process.env.REACT_APP_ALGOLIA_SECRET_KEY}`,
    {
      headers: {
        "X-Algolia-UserToken": currentUser?.["@id"]
          ? `coreUser-${extractIdFromIri(currentUser["@id"])}`
          : "unidentified_user",
      },
    },
  );
  const algoliaIndexPrefix = process.env.REACT_APP_ALGOLIA_INDEX_PREFIX;
  const algoliaIndexes = React.useRef({
    one: `${algoliaIndexPrefix}${talentSegments.segment1}`,
    two: `${algoliaIndexPrefix}${talentSegments.segment2}`,
  }).current;
  if (!!!algoliaIndexPrefix) {
    throw new Error("REACT_APP_ALGOLIA_INDEX_PREFIX is missing");
  }

  const transformItemsSearchStatus: RefinementListProps["transformItems"] = React.useCallback(
    (items: any[]) => {
      return items.map((item) => ({
        ...item,
        label: t({ id: transTalent.searchStatus[item.label] }),
      }));
    },
    [transTalent.searchStatus],
  );

  const fetchOffer = (offer: Offer) => {
    dispatch(getOfferAction(offer["@id"]));
  };

  React.useEffect(() => {
    if (
      !!!currentUser
      || !!!selectedOffer
      || (prevSelectedOffer && selectedOffer["@id"] === prevSelectedOffer["@id"])
    ) {
      return;
    }
    const selectedfullPackage = selectedOffer.printSalaries?.replace(" - ", ":").replace(/k/gi, "");
    const selectedExperience = selectedOffer.printExperienceYears?.replace("-", ":").replace(/ans/gi, "");
    const indexState = {
      refinementList: {
        [algoliaAttributes.coreResponsibility]: selectedOffer.responsibilities.map((e) => e.value),
        [algoliaAttributes.cities]: selectedOffer.offerLocations?.map((e: any) => e.location.value) || [
          selectedOffer.stapleLocation.value,
        ],
      },
      range: {
        [algoliaAttributes.exptectedFullPackage]: selectedfullPackage,
        [algoliaAttributes.experience]: selectedExperience,
      },
    };
    const offerUiState = {
      [algoliaIndexes.one]: indexState,
      [algoliaIndexes.two]: indexState,
    };
    const filterQuery = "NOT applicationsOffersAndDiscardedOffers: " + extractIdFromIri(selectedOffer["@id"]);

    dispatch(
      getHuntTalentsAction([
        {
          multiple: true,
          name: "owners",
          value: [currentUser["@id"]],
        },
        {
          multiple: false,
          name: "offer",
          value: selectedOffer["@id"],
        },
      ]),
    );
    setLoading(true);
    setToContactUrl(`${extractPageFromIri("offers", selectedOffer["@id"])}/contact_talent`);
    setRuleOut(filterQuery);
    setInitialUiState(offerUiState);
  }, [selectedOffer, prevSelectedOffer, ruleOut, currentUser, algoliaIndexes, dispatch]);

  React.useEffect(() => {
    if (currentUser && selectedOffer) {
      dispatch(
        getHuntTalentsAction([
          {
            multiple: false,
            name: "owner",
            value: currentUser["@id"],
          },
          {
            multiple: false,
            name: "offer",
            value: selectedOffer["@id"],
          },
        ]),
      );
    }
  }, [selectedOffer, currentUser, dispatch]);

  React.useEffect(() => {
    if (loading) {
      setLoading(false);
    }
  }, [loading]);

  return (
    <>
      <div className={cx(globalStyles.mainTitleContainer, styles.mainTitleContainer)}>
        <h1 className={globalStyles.mainTitle}>{t`AlgoliaSearchPage.title`}</h1>
      </div>
      <div className={styles.tipsWrapper}>
        <div className={styles.tipsCtaContainer}>
          <button
            type="button"
            className={cx(styles.tipsCta, globalStyles.cta, globalStyles.alertCta, globalStyles.inverted, {
              [globalStyles.active]: showTips,
            })}
            onClick={() => {
              setShowTips(!showTips);
            }}
          >
            <FaRegLightbulb /> Astuces
          </button>
        </div>
        {showTips ? (
          <div className={cx(styles.tipsContainer, globalStyles.tipsContainer)}>
            Astuces :
            <ol>
              <li>
                La recherche textuelle est ton amie !{" "}
                <b>
                  (clique sur l&apos;aide en bas à droite pour un mini tuto{" "}
                  <span className={styles.tipsInfoIcon}>
                    <BsInfoLg />
                  </span>{" "}
                  )
                </b>
                <ol type="a">
                  <li>
                    Retire tous les filtres pré-remplis de ton offre et effectue une recherche textuelle la plus précise
                    possible (ex : &quot;business developer&quot; démarchage management foodtech)
                  </li>
                  <li>
                    Remplace dans ta première recherche les termes par des synonymes (ex : commercial &quot;cold
                    call&quot; &quot;team lead&quot; agroalimentaire)
                  </li>
                </ol>
              </li>
              <li>
                Commence avec une recherche très précise, puis élargis tes critères. (métiers connexes, entreprises
                concurrentes, etc..)
              </li>
              <li>
                Les options d&apos;un même filtre fonctionnent comme un &quot;OU&quot;. (exemple : Si je sélectionne
                comme rôles principaux Product Manager et CSM je verrai les talents qui sont Product Manager <b>OU</b>{" "}
                CSM)
              </li>
              <li>
                Entre eux, les filtres fonctionnent comme des &quot;ET&quot;. (exemple : Si je sélectionne comme rôle
                principal Product Manager et comme experience 6 ans, je verrai les talents qui sont Product Manager ET
                qui ont 6 ans d&apos;experience)
              </li>
            </ol>
          </div>
        ) : null}
      </div>
      <div className={styles.offerSelectCtaContainer}>
        <div className={styles.offerSelectContainer}>
          <OfferSelect handleAction={(o) => fetchOffer(o)} placeholder={t`AlgoliaSearch.offerSelect.placeholder`} />
        </div>
        <div>
          <Link
            to={toContactUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={cx(styles.toContactBtn, globalStyles.cta, globalStyles.primaryCta, {
              [globalStyles.disabledCta]: !!!selectedOffer,
            })}
            onClick={(e) => {
              if (!!!selectedOffer) {
                e.preventDefault();
              }
            }}
          >
            {!!!selectedOffer
              ? t`HuntTalent.talentToContact`
              : `${talentsToContact.length} ${t`HuntTalent.talentToContact`}`}
          </Link>
        </div>
      </div>
      {loading ? (
        <LoaderComp />
      ) : (
        <InstantSearch searchClient={searchClient} indexName={algoliaIndexes.one} initialUiState={initialUiState}>
          <InsightsMiddleware />
          <Configure filters={ruleOut !== "" ? ruleOut : undefined} />
          <div className={styles.searchWrapper}>
            <div className={styles.filtersContainer}>
              <div>
                <FilterPanel
                  header={t({
                    id: (algoliaAttributesTrans as { [key: string]: string })[algoliaAttributes.coreResponsibility],
                  })}
                >
                  <RefinementList
                    attribute={algoliaAttributes.coreResponsibility}
                    searchable={true}
                    searchablePlaceholder="Rechercher un rôle principal"
                    showMore={true}
                  />
                </FilterPanel>
              </div>
              <div>
                <FilterPanel
                  header={t({
                    id: (algoliaAttributesTrans as { [key: string]: string })[algoliaAttributes.exptectedFullPackage],
                  })}
                >
                  <RangeInput
                    classNames={{ input: styles.rangeInput }}
                    attribute={algoliaAttributes.exptectedFullPackage}
                  />
                </FilterPanel>
              </div>
              <div>
                <FilterPanel
                  header={t({
                    id: (algoliaAttributesTrans as { [key: string]: string })[algoliaAttributes.experience],
                  })}
                >
                  <RangeInput attribute={algoliaAttributes.experience} classNames={{ input: styles.rangeInput }} />
                </FilterPanel>
              </div>

              <div>
                <FilterPanel
                  header={t({
                    id: (algoliaAttributesTrans as { [key: string]: string })[algoliaAttributes.responsibilities],
                  })}
                  collapsed={true}
                >
                  <RefinementList
                    attribute={algoliaAttributes.responsibilities}
                    searchable={true}
                    searchablePlaceholder="Rechercher un rôle secondaire"
                    showMore={true}
                  />
                </FilterPanel>
              </div>

              <div>
                <FilterPanel
                  header={t({
                    id: (algoliaAttributesTrans as { [key: string]: string })[algoliaAttributes.companyName],
                  })}
                  collapsed={true}
                >
                  <RefinementList
                    attribute={algoliaAttributes.companyName}
                    searchable={true}
                    searchablePlaceholder="Rechercher une entreprise"
                    showMore={true}
                  />
                </FilterPanel>
              </div>

              <div>
                <FilterPanel
                  header={t({
                    id: (algoliaAttributesTrans as { [key: string]: string })[algoliaAttributes.cities],
                  })}
                  collapsed={true}
                >
                  <RefinementList
                    attribute={algoliaAttributes.cities}
                    searchable={true}
                    searchablePlaceholder="Rechercher une ville"
                    showMore={true}
                  />
                </FilterPanel>
              </div>

              <div>
                <FilterPanel
                  header={t({
                    id: (algoliaAttributesTrans as { [key: string]: string })[algoliaAttributes.schools],
                  })}
                  collapsed={true}
                >
                  <RefinementList
                    attribute={algoliaAttributes.schools}
                    searchable={true}
                    searchablePlaceholder="Rechercher une école"
                    showMore={true}
                  />
                </FilterPanel>
              </div>

              <div>
                <FilterPanel
                  header={t({
                    id: (algoliaAttributesTrans as { [key: string]: string })[algoliaAttributes.languages],
                  })}
                  collapsed={true}
                >
                  <RefinementList
                    attribute={algoliaAttributes.languages}
                    searchable={true}
                    searchablePlaceholder="Rechercher une langue"
                    showMore={true}
                  />
                </FilterPanel>
              </div>

              <div>
                <FilterPanel
                  header={t({
                    id: (algoliaAttributesTrans as { [key: string]: string })[algoliaAttributes.searchStatus],
                  })}
                  collapsed={true}
                >
                  <RefinementList
                    attribute={algoliaAttributes.searchStatus}
                    showMore={true}
                    transformItems={transformItemsSearchStatus}
                  />
                </FilterPanel>
              </div>

              <div>
                <FilterPanel
                  header={t({
                    id: (algoliaAttributesTrans as { [key: string]: string })[algoliaAttributes.persona],
                  })}
                  collapsed={true}
                >
                  <RefinementList attribute={algoliaAttributes.persona} sortBy={["name:asc"]} />
                </FilterPanel>
              </div>
            </div>

            <div className={styles.resultsRefineContainer}>
              <div>
                <SearchBox placeholder="Recherche par mots-clés" />
              </div>

              <div className={styles.resultRefineCtaContainer}>
                <HitsPerPage
                  title="Résultats"
                  items={[
                    { label: "10 par page", value: 10, default: true },
                    { label: "20 par page", value: 20 },
                  ]}
                />
                <div className={styles.refreshCtaContainer}>
                  <Refresh />
                </div>
              </div>

              <div className={styles.currentClearRefineContainer}>
                <CurrentRefinements
                  transformItems={(items) =>
                    items.map((item) => {
                      const attribute = item.label.startsWith("hierarchicalCategories") ? "Hierarchy" : item.label;
                      const label = t({
                        id: (algoliaAttributesTrans as { [key: string]: string })[item.attribute],
                      });
                      return {
                        ...item,
                        attribute,
                        label: `${label} `,
                      };
                    })
                  }
                />
                <ClearRefinements
                  className={styles.clearRefinements}
                  classNames={{
                    button: cx(globalStyles.cta, globalStyles.alertCta),
                  }}
                />
              </div>

              <div className={styles.indicesContainer}>
                <div>
                  <Index indexName={algoliaIndexes.one} indexId={"elinoi_index"}>
                    <SingleIndex segment={talentSegments.segment1} defaultIsOpen={true} />
                  </Index>
                </div>
                <div>
                  <Index indexName={algoliaIndexes.two} indexId={"elinoi_index"}>
                    <SingleIndex segment={talentSegments.segment2} />
                  </Index>
                </div>
              </div>
            </div>
          </div>
        </InstantSearch>
      )}

      <AlgoliaHelpModalCta />
    </>
  );
};
