import cx from "classnames";
import * as React from "react";
interface Props {
  to: string | string[];
  subject?: string;
  body?: string;
  bcc?: string | string[];
  cc?: string | string[];
  className?: string;
}

const url = "https://mail.google.com/mail/?extsrc=mailto&url=mailto";

export const GmailMailtoFunc = (props: Props) => {
  const queryParams =
    ":?"
    + Object.keys(props)
      .filter((key: string) => key !== "className")
      .map((key: string) => key + "=" + props[key as keyof Props])
      .join("&");
  return url + encodeURIComponent(queryParams);
};

const GmailMailto = (props: Props) => {
  const { className = "" } = props;
  return (
    <a
      className={cx({ [className]: !!className })}
      href={GmailMailtoFunc(props)}
      target="_blank"
      rel="external nofollow noopener noreferrer"
    >
      {props.to}
    </a>
  );
};

export default GmailMailto;
