import * as React from "react";

import { SortNewTalents } from "../../components/Talents/SortNewTalents";

export const TalentSortScene: React.FC = (): JSX.Element => {
  return (
    <div>
      <SortNewTalents />
    </div>
  );
};
