import { AnyAction } from "redux";
import {
  EditApplication,
  GetApplicationForCollection,
  GetApplications,
  CreateApplicationAccepted,
  EditApplicationAccepted,
} from "../../../actionTypes/applications";
import {
  CreateCoreUser,
  EditCoreUser,
  GetCoreUserApplications,
  GetCoreUsers,
  GetCurrentUser,
} from "../../../actionTypes/coreUsers";

import { Application } from "../../../interfaces/resources/application";
import { CoreUser } from "../../../interfaces/resources/coreUser";

export function currentUser(state: CoreUser | null = null, action: AnyAction): CoreUser | null {
  switch (action.type) {
    case GetCurrentUser.SUCCESS:
      return action.coreUser;
    default:
      return state;
  }
}

export function coreUsers(state: CoreUser[] = [], action: AnyAction): CoreUser[] {
  switch (action.type) {
    case GetCoreUsers.SUCCESS:
      return action.coreUsers;
    case EditCoreUser.SUCCESS:
      const newState = [...state];
      newState.splice(
        newState.findIndex((item: CoreUser) => item["@id"] === action.coreUser["@id"]),
        1,
        action.coreUser,
      );
      return newState;
    default:
      return state;
  }
}

export function newCoreUser(state: CoreUser | null = null, action: AnyAction): CoreUser | null {
  switch (action.type) {
    case CreateCoreUser.SUCCESS:
      return action.coreUser;
    default:
      return state;
  }
}

export function coreUserApplications(state: Application[] = [], action: AnyAction): Application[] {
  switch (action.type) {
    case GetCoreUserApplications.SUCCESS:
      return action.page ? [...state, ...action.applications] : action.applications;
    case EditApplication.SUCCESS:
    case GetApplicationForCollection.SUCCESS:
      const newState = [...state];
      const index = newState.findIndex((a: Application) => a["@id"] === action.application["@id"]);
      if (index > -1) {
        newState.splice(index, 1, action.application);
      }
      return newState;
    case CreateApplicationAccepted.SUCCESS:
    case EditApplicationAccepted.SUCCESS:
      const i = state.findIndex((a: Application) => a["@id"] === action.applicationAccepted.application);
      const newApps = [...state];
      if (i !== -1) {
        newApps[i].applicationAccepted = action.applicationAccepted;
      }
      return newApps;
    case GetCoreUserApplications.REQUEST:
      return typeof action.page === "number" && action.page > 1 ? state : [];
    case GetApplications.REQUEST:
      return [];
    default:
      return state;
  }
}
