import { t } from "@lingui/macro";
import cx from "classnames";
import { Field, Formik, Form, FormikHelpers } from "formik";
import * as React from "react";
import { FaPaperPlane } from "react-icons/fa";
import { Message } from "semantic-ui-react";

import { ApiError } from "../../../../../interfaces/api/error";
import { ApiRequest } from "../../../../../interfaces/api/request";
import { ApiSuccess } from "../../../../../interfaces/api/success";
import { Violation, FormState } from "../../../../../interfaces/api/violation";
import { Application, applicationStatuses } from "../../../../../interfaces/resources/application";
import { Contact } from "../../../../../interfaces/resources/contact";
import { Email } from "../../../../../interfaces/resources/email";
import { Offer } from "../../../../../interfaces/resources/offer";

import globalStyles from "../../../../../styles/global.module.scss";
import Yup from "../../../../App/Yup";
import Input from "../../../../Form/Formik/Input";
import RichTextEditor from "../../../../Form/Formik/RichTextEditor";
import { Creatable } from "../../../../Form/Formik/Select";
import { useApiSelector } from "../../../../Utils/hook";
import styles from "./index.module.scss";
interface Props {
  apiErrors: ApiError[];
  apiPendingRequests: ApiRequest[];
  apiSuccess: ApiSuccess[];
  applications: Application[];
  ignoredApplications: string[];
  offer: Offer;
  sendShortlist: (values: any, form: string) => void;
}

const validationSchema = Yup.object().shape({
  message: Yup.string().required(),
  primaryRecipients: Yup.array().required(),
  secondaryRecipients: Yup.array(),
  subject: Yup.string().required(),
});

const formName = "shortlistEmail";

const EmailForm: React.FC<Props> = ({ applications, offer, sendShortlist, ignoredApplications }: Props) => {
  const { apiErrors, apiPendingRequests, apiSuccess } = useApiSelector();

  const [violations, setViolations] = React.useState<Violation[]>([]);
  const [formState, setFormState] = React.useState<FormState>(undefined);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSent, setIsSent] = React.useState(false);

  const isSendDisabled =
    applications
    && !applications.some((application: Application) => application.status === applicationStatuses.shortlistReady);

  const ownHandleSubmit = (values: Email, { setSubmitting }: FormikHelpers<Email>): void => {
    sendShortlist(
      {
        ...values,
        ignoredApplications,
        offer: offer["@id"],
      },
      formName,
    );
    setTimeout(() => setSubmitting(false), 500);
  };

  React.useEffect(() => {
    setIsLoading(apiPendingRequests.length > 0 && apiPendingRequests.some((req: ApiRequest) => req.form === formName));
  }, [apiPendingRequests]);

  React.useEffect(() => {
    setIsSent(apiSuccess.length > 0 && apiSuccess.some((req: ApiRequest) => req.form === formName));
  }, [apiSuccess]);

  const contacts = [
    ...offer.contacts,
    ...offer.company.contacts.filter(
      (contact: Contact) => !offer.contacts.some((e: Contact) => e["@id"] === contact["@id"]),
    ),
  ].reduce((result: any, item: Contact) => {
    if (!result.some((e: any) => e.label === item.email)) {
      result.push({
        label: item.email,
        value: item.email,
      });
    }
    return result;
  }, []);

  React.useEffect(() => {
    if (apiSuccess.some((suc: ApiSuccess) => suc.form === formName)) {
      setViolations([]);
      setFormState("success");
    }
  }, [apiSuccess]);

  React.useEffect(() => {
    if (apiErrors.some((err: ApiError) => err.form === formName)) {
      const apiError = apiErrors.find((err: ApiError) => err.form === formName);
      if (apiError) {
        setViolations(apiError.payload.violations || []);
        setFormState("error");
      }
    }
  }, [apiErrors]);

  return (
    <Formik
      onSubmit={ownHandleSubmit}
      initialValues={
        {
          message: "",
          primaryRecipients: [],
          secondaryRecipients: [],
          subject: "",
        } as Email
      }
      validationSchema={validationSchema}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit} className={cx("form", styles.emailForm, { loading: isLoading })}>
          <div className={cx("formGroup", styles.shortlistEmailFormGroup)}>
            <Field
              name="primaryRecipients"
              component={Creatable}
              isClearable={true}
              isMulti={true}
              label={t`shortlist.primaryRecipients`}
              placeholder={t`shortlist.primaryRecipients.placeholder`}
              options={contacts}
              violations={violations}
            />
            <Field
              name="secondaryRecipients"
              component={Creatable}
              isMulti={true}
              label={t`shortlist.secondaryRecipients`}
              placeholder={t`shortlist.secondaryRecipients.placeholder`}
              options={contacts}
              violations={violations}
            />
            <Field
              name="subject"
              component={Input}
              label={t`shortlist.subject`}
              placeholder={t`shortlist.subject`}
              violations={violations}
            />
          </div>
          <div className="formGroup formTextAreaGroup">
            <Field
              name="message"
              component={RichTextEditor}
              uniqId={"corp-message-mail"}
              label={t`shortlist.message`}
              violations={violations}
              className="email"
              placeholder={t`shortlist.message`}
              replaceIfEmpty={false}
            />
          </div>
          {formState === "success" || formState === "error" ? (
            <div className={styles.messagesContainer}>
              {formState === "success" ? (
                <Message
                  success
                  content={t`shortlist.success.content`}
                  header={t`shortlist.success.header`}
                  icon="check circle"
                />
              ) : null}
              {formState === "error" ? (
                <Message
                  error
                  content={t`shortlist.error.content`}
                  header={t`shortlist.error.header`}
                  icon="times circle"
                />
              ) : null}
            </div>
          ) : null}
          <div className={styles.submitCtaContainer}>
            <button
              type="submit"
              disabled={isSendDisabled || isLoading || isSent}
              className={cx(globalStyles.cta, globalStyles.primaryCta)}
            >
              <FaPaperPlane /> {t`ShortlistForm.fields.send`}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EmailForm;
