import { ActionType } from "../interfaces/api/actionType";

export const CreateCompany: ActionType = {
  FAILURE: "CREATE_COMPANY_FAILURE",
  REQUEST: "CREATE_COMPANY_REQUEST",
  SUCCESS: "CREATE_COMPANY_SUCCESS",
};

export const DeleteCompany: ActionType = {
  FAILURE: "DELETE_COMPANY_FAILURE",
  REQUEST: "DELETE_COMPANY_REQUEST",
  SUCCESS: "DELETE_COMPANY_SUCCESS",
};

export const EditCompany: ActionType = {
  FAILURE: "EDIT_COMPANY_FAILURE",
  REQUEST: "EDIT_COMPANY_REQUEST",
  SUCCESS: "EDIT_COMPANY_SUCCESS",
};

export const GetCompanies: ActionType = {
  FAILURE: "GET_COMPANIES_FAILURE",
  REQUEST: "GET_COMPANIES_REQUEST",
  SUCCESS: "GET_COMPANIES_SUCCESS",
};

export const GetCompany: ActionType = {
  FAILURE: "GET_COMPANY_FAILURE",
  REQUEST: "GET_COMPANY_REQUEST",
  SUCCESS: "GET_COMPANY_SUCCESS",
};
