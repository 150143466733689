import { t } from "@lingui/macro";
import * as React from "react";
import { Icon } from "semantic-ui-react";

import { GetOffer } from "../../../actionTypes/offers";

import OfferAdviceFormContainer from "../../../containers/Offers/OfferForm/OfferAdviceFormContainer";
import { ApiError } from "../../../interfaces/api/error";
import { ApiRequest } from "../../../interfaces/api/request";
import { ApiSuccess } from "../../../interfaces/api/success";
import { Company } from "../../../interfaces/resources/company";
import { Offer } from "../../../interfaces/resources/offer";
import globalStyles from "../../../styles/global.module.scss";
import { MainForm } from "./MainForm";
import "./index.scss";

export const OFFER_MAIN_FORM = "OFFER_MAIN_FORM";
export const OFFER_ADVICE_FORM = "OFFER_ADVICE_FORM";

const transitions: { [key: string]: string } = {
  OFFER_ADVICE_FORM: OFFER_MAIN_FORM,
  OFFER_MAIN_FORM: OFFER_ADVICE_FORM,
};

interface Props {
  apiErrors: ApiError[];
  apiPendingRequests: ApiRequest[];
  apiSuccess: ApiSuccess[];
  company: Company | null;
  getOffer?: (iri: string) => void;
  iri?: string;
  offer: Offer | null;
  state: "creation" | "edition";
}

const OfferForm: React.FunctionComponent<Props> = ({
  apiErrors,
  apiPendingRequests,
  apiSuccess,
  company,
  getOffer,
  offer,
  iri,
  state,
}: Props) => {
  const [formPage, setFormPage] = React.useState<string>(OFFER_MAIN_FORM);
  const [switchPage, setSwitchPage] = React.useState<boolean>(false);

  const togglePage = React.useCallback(() => {
    setFormPage(transitions[formPage]);
  }, [formPage, setFormPage]);

  React.useEffect(() => {
    if (switchPage && offer) {
      setSwitchPage(false);
      togglePage();
    }
  }, [offer, setSwitchPage, switchPage, togglePage]);

  React.useEffect(() => {
    if (
      "edition" === state
      && iri
      && getOffer
      && apiErrors
      && apiPendingRequests
      && apiSuccess
      && !offer
      && !apiErrors.some((err: ApiError) => err.type === GetOffer.FAILURE)
      && !apiPendingRequests.some((req: ApiRequest) => req.type === GetOffer.REQUEST)
      && !apiSuccess.some((suc: ApiSuccess) => suc.type === GetOffer.SUCCESS)
    ) {
      getOffer(iri);
    }
  }, [apiErrors, apiPendingRequests, apiSuccess, getOffer, offer, iri, state]);

  const titleVar = "creation" === state ? (!!company ? company.name : null) : offer && offer.title;

  return (
    <div className="offerFormContainer">
      <div className="navigation">
        <Icon name="chevron left" disabled={formPage === OFFER_MAIN_FORM} onClick={togglePage} />
        <Icon name="chevron right" disabled={formPage === OFFER_ADVICE_FORM || !offer} onClick={togglePage} />
      </div>
      <div className={globalStyles.mainTitleContainer}>
        <h1 className={globalStyles.mainTitle}>
          {"creation" === state
            ? !!company
              ? t({ id: "OfferForm.createWithStartup.title", values: { titleVar } })
              : t`OfferForm.create.title`
            : t({ id: "OfferForm.edition.title", values: { titleVar } })}
        </h1>
      </div>

      {formPage === OFFER_ADVICE_FORM ? (
        <OfferAdviceFormContainer />
      ) : (
        <MainForm formType={state} enableSwitchPage={setSwitchPage} />
      )}
    </div>
  );
};

export default OfferForm;
