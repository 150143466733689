import { Application } from "./application";
import { Company } from "./company";
import { Contact } from "./contact";
import { CoreUser } from "./coreUser";
import { DesiredPlace } from "./desiredPlace";
import { ExperienceYears } from "./experienceYears";
import { InterviewStep } from "./interviewStep";
import { InterviewStepType } from "./interviewStepType";
import { Responsibility, ResponsibilityType } from "./responsibility";
import { SalaryExpectations } from "./salaryExpectations";
import { Talent } from "./talent";

export interface Offer {
  "@id": string;
  "@type": string;
  applications?: Application[];
  applicationsAccepted?: Application[];
  closingParty?: CoreUser;
  commission: number;
  company: Company;
  contacts: Contact[];
  coreResponsibility: Responsibility;
  countInterviewSteps?: number;
  createdAt: string;
  discardedTalents: Talent[];
  document: {
    url: string;
  };
  experienceYears: ExperienceYears[];
  formule: string;
  interviewSteps: InterviewStep[];
  interestingContactedApplicationCount?: number;
  isMarketplace?: boolean;
  level: string;
  lastActivationStatusDate?: string | null;
  lastDesactivationStatusDate?: string | null;
  mode?: string;
  offerInternalView: OfferInternalView;
  offerLocations: OfferLocation[];
  offerStatusEvents?: OfferStatusEvent[];
  offerTalentView: OfferTalentView;
  owners: CoreUser[];
  printExperienceYears: string;
  printSalaries: string;
  recurring: boolean;
  responsibilities: Responsibility[];
  responsibilityType: ResponsibilityType;
  salary: SalaryExpectations[];
  setUp: number;
  shortlistedAndInterviewsApplications?: Application[];
  slug: string;
  stapleLocation: DesiredPlace;
  status: string;
  tier: string;
  title: string;
  name: string;
  token: string;
}

export interface OfferInternalView {
  "@id": string;
  expectedProfile: string;
  outstandingAspects: string;
}

export interface OfferTalentView {
  "@id": string;
  context: string;
  description: string;
  elinoiAdvice: string;
  expectedProfile: string;
  howToShine: string;
  requirements: string;
  team: string;
  toLearn: string;
  ytPitch: string;
  ytProcess: string;
}

export interface OfferStatusEvent {
  "@id": string;
  "@type": string;
  createdAt: string;
  status: string;
}

export interface OfferMainForm {
  "@id"?: string;
  company?: Company;
  contacts: Contact[];
  coreResponsibility?: Responsibility;
  experienceYears: ExperienceYears[];
  mode: string;
  offerLocations: OfferLocation[];
  remotePreferences: OfferLocation[];
  offerInternalView: {
    expectedProfile: string;
    outstandingAspects: string;
  };
  offerTalentView: {
    context: string;
    description: string;
    expectedProfile: string;
    howToShine: string;
    requirements: string;
    team: string;
    toLearn: string;
  };
  owner?: CoreUser;
  recurring: boolean;
  responsibilities: Responsibility[];
  salary: SalaryExpectations[];
  stapleLocation?: DesiredPlace;
  status: string;
  tier?: string;
  title?: string;
  level?: string;
}

export interface OfferAdviceForm {
  "@id"?: string;
  interviewSteps: OfferAdviceFormInterviewStep[];
  offerTalentView: {
    "@id"?: string;
    elinoiAdvice: string;
  };
}

export interface OfferAdviceFormInterviewStep {
  "@id"?: string;
  description: string;
  interviewStepTypes: InterviewStepType[];
  step: number;
  title: string;
}

export type OfferSummary = Pick<Offer, "@id" | "title" | "company">;

export interface OfferLocation {
  "@context": string;
  "@id": string;
  "@type": string;
  offer: string;
  location: string;
  isStapleLocation: boolean;
}

export const offerStatuses = {
  activated: "activated",
  standbyElinoi: "standby_elinoi",
  desactivated: "desactivated",
};

export const offerLabelStatuses = {
  activated: "activated",
  desactivated: "desactivated",
  closed: "closed",
};

export const offerStatusesColors: { [key: string]: string } = {
  activated: "var(--positive-green)",
  desactivated: "var(--negative-red)",
  standby_elinoi: "var(--dark-green)",
};

export const offerLevel = {
  junior: "junior",
  senior: "senior",
  manager: "manager",
  headOf: "head_of",
};

export const offerFormule: { [key: string]: string } = {
  premium: "premium",
  classic: "classic",
  subscription: "subscription",
  replacement: "replacement",
};

export const offerMode = {
  recurring: "recurring",
  one_shot: "one_shot",
  spontaneous: "spontaneous",
};
