import { t } from "@lingui/macro";
import cx from "classnames";
import { format, parseISO } from "date-fns";
import * as React from "react";
import ReactMarkdown from "react-markdown";
import { useDispatch } from "react-redux";
import { Accordion, Icon, Confirm, Dropdown, Label } from "semantic-ui-react";

import { TalentApplicationList } from "../../../../components/Applications/components/TalentApplicationList";
import { CoreUser } from "../../../../interfaces/resources/coreUser";
import { PhoneCall } from "../../../../interfaces/resources/phoneCall";
import { deletePhoneCallAction } from "../../../../services/api/talent/phoneCalls/actions";
import transPhoneCall from "../../../../translations/constants/phoneCall";

import EditPhoneCall from "../EditPhoneCall";

import "./index.scss";

interface Props {
  coreUser: CoreUser;
  phoneCall: PhoneCall;
  readOnly: boolean;
  shortlist?: boolean;
}

export const PhoneCallComp: React.FC<Props> = ({ coreUser, phoneCall, readOnly, shortlist }: Props) => {
  const [editing, setEditing] = React.useState<boolean>(false);
  const [deletion, setDeletion] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean>(false);
  const [active, setActive] = React.useState<boolean>(false);
  const dispatch = useDispatch();

  const deletePhoneCall = () => {
    dispatch(deletePhoneCallAction(phoneCall));
    setDeletion(false);
  };

  const toggleEdit = (succ: boolean) => {
    setEditing(!editing);
    setSuccess(succ);
  };

  const onClickPhoneCall = () => {
    setActive(!active);
  };

  return (
    <div className={cx({ phoneCallCard: true, shortlist: !!shortlist })}>
      {editing ? (
        <EditPhoneCall phoneCall={phoneCall} toggleEdit={toggleEdit} />
      ) : (
        <div>
          <Accordion.Title active={active} index={phoneCall["@id"]} onClick={() => onClickPhoneCall()}>
            <span className="author">
              <Icon name={active ? "chevron up" : "chevron down"} />
              <Icon name="calendar alternate outline" />
              {t({
                id: "PhoneCall.by",
                values: {
                  date: format(parseISO(phoneCall.createdAt), "dd/MM/yyyy"),
                  name: phoneCall.coreUser ? phoneCall.coreUser.firstname : "",
                },
              })}
            </span>

            {!shortlist ? (
              <div>
                <div style={{ display: "flex" }}>
                  <h4>{t({ id: transPhoneCall.type[phoneCall.type] })}</h4>
                  {phoneCall.coreUser.roles.includes("ROLE_COACH") ? (
                    <Label content="TIM" color="red" />
                  ) : phoneCall.coreUser.roles.includes("ROLE_ACCOUNT_MANAGER") ? (
                    <Label content="AM" color="yellow" />
                  ) : null}
                </div>

                <span className="editionHeader">
                  <Dropdown icon="ellipsis horizontal" style={{ float: "right" }}>
                    <Dropdown.Menu style={{ zIndex: 1 }}>
                      {!readOnly && coreUser && phoneCall.coreUser && coreUser["@id"] === phoneCall.coreUser["@id"] ? (
                        <Dropdown.Header
                          icon="pencil"
                          content={t`Constant.update`}
                          onClick={() => toggleEdit(false)}
                          style={{ cursor: "pointer" }}
                        />
                      ) : null}
                      <Dropdown.Header
                        icon="close"
                        content={t`Constant.delete`}
                        onClick={() => setDeletion(true)}
                        style={{ cursor: "pointer" }}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                  <Confirm open={deletion} onCancel={() => setDeletion(false)} onConfirm={deletePhoneCall} />
                  {success ? (
                    <span
                      style={{
                        color: success ? "green" : "black",
                        float: "right",
                      }}
                    >
                      {t`OfferPitch.saved`}
                    </span>
                  ) : (
                    ""
                  )}
                </span>
              </div>
            ) : null}
            <h5>{phoneCall.title}</h5>
          </Accordion.Title>
          <Accordion.Content active={active}>
            <h5>{t`phoneCall.notes`}</h5>
            <ReactMarkdown>{phoneCall.notes}</ReactMarkdown>
            {!shortlist ? (
              <div>
                <h5>{t`phoneCall.applications`}</h5>
                <TalentApplicationList applications={phoneCall.applications} />
              </div>
            ) : null}
          </Accordion.Content>
        </div>
      )}
    </div>
  );
};
