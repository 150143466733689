import { FilterContext } from "../../interfaces/api/filter";
import { Application, applicationStatuses, applicationStatusesRefused } from "../../interfaces/resources/application";
import { CoreUser, coreUserRoles } from "../../interfaces/resources/coreUser";
import { DashboardMode } from "../../interfaces/resources/dashboard";
import { Offer, offerStatuses } from "../../interfaces/resources/offer";
import { Talent } from "../../interfaces/resources/talent";
import { ColumnsProps, allColumnOrders, columns } from "./Column/data";

export const getFilterContext = (
  isRefusedMode: boolean,
  isTimMode: boolean,
  mode?: DashboardMode,
  currentUser?: CoreUser | null,
  offer?: Offer | null,
  talent?: Talent | null,
): FilterContext => {
  const defaultStatuses = [
    applicationStatuses.applied,
    applicationStatuses.callBooked,
    applicationStatuses.toPitch,
    applicationStatuses.awaitingShortlist,
    applicationStatuses.shortlistReady,
    applicationStatuses.shortlisted,
    applicationStatuses.interviews,
    applicationStatuses.offer,
  ];
  const statuses = {
    multiple: true,
    name: "status",
    value: isRefusedMode
      ? applicationStatusesRefused
      : mode === "offer" || mode === "talent"
      ? [...defaultStatuses, applicationStatuses.interesting, applicationStatuses.contacted]
      : defaultStatuses,
  };

  const defaultFilterContext = [
    {
      multiple: false,
      name: "order",
      param: "updatedAt",
      value: "DESC",
    },
    { multiple: false, name: "itemsPerPage", value: 50 },
    statuses,
  ];
  const defaultCoreUserFilterContext = [
    ...defaultFilterContext,
    {
      multiple: true,
      name: "offer.status",
      value: [offerStatuses.activated, offerStatuses.standbyElinoi],
    },
  ];

  const filterContext =
    offer && mode === "offer"
      ? [
          ...defaultFilterContext,
          {
            multiple: false,
            name: "offer",
            value: offer["@id"],
          },
        ]
      : talent && mode === "talent"
      ? [
          ...defaultFilterContext,
          {
            multiple: false,
            name: "talent",
            value: talent["@id"],
          },
        ]
      : currentUser
      ? currentUser.roles.includes(coreUserRoles.am) && !isTimMode
        ? [
            ...defaultCoreUserFilterContext,
            {
              multiple: true,
              name: "offer.owners",
              value: [currentUser["@id"]],
            },
          ]
        : [
            ...defaultCoreUserFilterContext,
            {
              multiple: false,
              name: "talent.talentManager",
              value: currentUser["@id"],
            },
          ]
      : [];

  return filterContext;
};

export const getAcceptedFilterContext = (
  mode?: DashboardMode,
  currentUser?: CoreUser | null,
  offer?: Offer | null,
  talent?: Talent | null,
): FilterContext => {
  const defaultFilterContext = [
    {
      multiple: false,
      name: "order",
      param: "updatedAt",
      value: "DESC",
    },
    {
      multiple: true,
      name: "status",
      value: applicationStatuses.accepted,
    },
  ];

  const filterContext =
    offer && mode === "offer"
      ? [
          ...defaultFilterContext,
          {
            multiple: false,
            name: "offer",
            value: offer["@id"],
          },
        ]
      : talent && mode === "talent"
      ? [
          ...defaultFilterContext,
          {
            multiple: false,
            name: "talent",
            value: talent["@id"],
          },
        ]
      : currentUser
      ? currentUser.roles.includes(coreUserRoles.am)
        ? [
            ...defaultFilterContext,
            {
              multiple: true,
              name: "applicationAccepted.ams",
              value: [currentUser["@id"]],
            },
          ]
        : [
            ...defaultFilterContext,
            {
              multiple: false,
              name: "applicationAccepted.tim",
              value: currentUser["@id"],
            },
          ]
      : [];

  return filterContext;
};

export const getTimInterestingContactedFilterContext = (
  currentUser?: CoreUser | null,
  isTimMode?: boolean,
): FilterContext => {
  const defaultFilterContext = [
    {
      multiple: false,
      name: "order",
      param: "updatedAt",
      value: "DESC",
    },
    {
      multiple: true,
      name: "status",
      value: [applicationStatuses.interesting, applicationStatuses.contacted],
    },
    {
      multiple: true,
      name: "offer.status",
      value: [offerStatuses.activated, offerStatuses.standbyElinoi],
    },
  ];

  const filterContext =
    currentUser && (!!!currentUser.roles.includes(coreUserRoles.am) || isTimMode)
      ? [
          ...defaultFilterContext,
          {
            multiple: false,
            name: "talent.talentManager",
            value: currentUser["@id"],
          },
        ]
      : [];

  return filterContext;
};

export const sortApplicationsForColumns = (applications: Application[]): ColumnsProps => {
  const newCols: ColumnsProps = Object.assign({}, columns);

  for (const columnId of allColumnOrders) {
    const column = newCols[columnId];
    column.applications = applications.reduce((arr: Application[], a: Application) => {
      if (a && column.acceptedStatuses.includes(a.status) && !column.hideSearchStatus.includes(a.talent.searchStatus)) {
        if (a.interviewAt !== null && ["column-a", "column-b"].includes(column.id)) {
          const date = new Date(a.interviewAt);
          date.setHours(0, 0, 0, 0);
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          if (
            ("column-a" === column.id && date.getTime() < today.getTime())
            || ("column-b" === column.id && date.getTime() >= today.getTime())
          ) {
            return [...arr, a];
          }
        } else {
          if (column.id === "column-b") {
            return arr;
          }
          return [...arr, a];
        }
      }
      return arr;
    }, []);

    column.refusedApplications = applications.reduce((arr: Application[], a: Application) => {
      if (
        a
        && a.previousStatus
        && column.refuseOptions.includes(a.status)
        && column.acceptedStatuses.includes(a.previousStatus)
      ) {
        if (a.interviewAt !== null) {
          const date = new Date(a.interviewAt);
          date.setHours(0, 0, 0, 0);
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          if (
            !["column-a", "column-b"].includes(column.id)
            || ("column-a" === column.id && date.getTime() < today.getTime())
            || ("column-b" === column.id && date.getTime() >= today.getTime())
          ) {
            return [...arr, a];
          } else {
            return arr;
          }
        }
        return [...arr, a];
      }
      return arr;
    }, []);
  }

  return newCols;
};

const checkPhoneNumber = (num: string | undefined, s: string): boolean => {
  if (!!!num) {
    return false;
  }
  const numberWithoutSpaces = num.replace(/\.|\s*/g, "");
  if (s.charAt(0) === "+") {
    return numberWithoutSpaces.includes(s);
  } else if (s.charAt(0) === "0") {
    return numberWithoutSpaces.replace("+33", "0").includes(s);
  }
  return false;
};

export const filterApplicationArray = (applications: Application[], s: string): Application[] =>
  applications.filter((application: Application) => {
    const lowerSearch = s.toLowerCase();
    if (
      application.offer.title.toLowerCase().includes(lowerSearch)
      || application.offer.company.name.toLowerCase().includes(lowerSearch)
      || `${application.talent.firstname} ${application.talent.lastname}`.toLowerCase().includes(lowerSearch)
      || checkPhoneNumber(application.talent.phone, s)
    ) {
      return true;
    }
    return false;
  });

export const getApplicationsNumber = (
  as:
    | Application[]
    | {
        [key: string]: Application[];
      },
): number =>
  Array.isArray(as) ? as.length : Object.keys(as).reduce((result: number, key: string) => result + as[key].length, 0);
