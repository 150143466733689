import { t } from "@lingui/macro";
import cx from "classnames";
import * as React from "react";

import { LabelType, applicationLabelTrans } from "../../translations/constants/label";

import styles from "./index.module.scss";

interface Props {
  type?: keyof LabelType;
  content?: string;
  className?: string;
  tooltip?: string;
  position?: "left" | "right" | "center";
  bold?: boolean;
}

export const Label: React.FC<Props> = (props: Props) => {
  const { type, tooltip, className = "", content, position = "center", bold } = props;
  const typeStyle = type ? (bold ? cx(styles[type], styles.bold) : styles[type]) : "";
  return (
    <div
      className={cx(className, styles.label, {
        [typeStyle]: !!type,
        [styles[position]]: !!tooltip,
        [styles.tooltipContainer]: !!tooltip,
      })}
    >
      {!!tooltip ? <span className={styles.tooltip}>{tooltip}</span> : null}
      <span>
        {content ? content : !!type && !!applicationLabelTrans[type] ? t({ id: applicationLabelTrans[type] }) : null}
      </span>
    </div>
  );
};
