import { t } from "@lingui/macro";
import cx from "classnames";
import * as React from "react";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { CoreUser, coreUserRoles } from "../../../interfaces/resources/coreUser";
import { Talent, talentCallTypes, talentSegments, talentStatuses } from "../../../interfaces/resources/talent";
import { State } from "../../../interfaces/state";
import { extractIdFromIri } from "../../../services/api/helpers";
import { sortTalentAction } from "../../../services/api/talent/actions";
import globalStyles from "../../../styles/global.module.scss";
import { transTalent } from "../../../translations/constants/talent";

import styles from "./index.module.scss";

export interface Props {
  linkedInHeadline?: string;
  linkedInUrl?: string;
  currentJob?: string;
  currentCompany?: string;
  talent: Talent | null;
}

interface CoreUserOption extends CoreUser {
  label: string;
  value: string;
}

type Interlocutor =
  | CoreUserOption
  | {
      label: string;
      value: string;
    }
  | null;

export interface GroupedOption {
  label: string;
  options:
    | CoreUserOption[]
    | {
        label: string;
        value: string;
      }[];
}

export const TalentFilterButtons: FC<Props> = ({ talent, linkedInUrl, currentJob, currentCompany }: Props) => {
  const dispatch = useDispatch();
  const coreUsers = useSelector((state: State) => state.coreUsers);
  const callTypesOption = Object.values(talentCallTypes).map((ct) => {
    return {
      label: t({ id: transTalent.callTypes[ct] }),
      value: ct,
    };
  });

  const [cat, setCat] = React.useState<string | null>(null);
  const [interlocutor, setInterlocutor] = React.useState<Interlocutor>(null);
  const [callType, setCallType] = React.useState<string | null>(callTypesOption[1].value);
  const [inHouse, setInHouse] = React.useState<boolean | null>(null);
  const [note, setNote] = React.useState<string | null>(null);
  const [groupedOption, setGroupedOptions] = React.useState<GroupedOption[]>([]);
  const [otherGroup] = React.useState<GroupedOption>({ label: "Autre", options: [{ value: "none", label: "Aucun" }] });
  const showInterlocutor = !!cat && talentSegments.segment1 === cat;
  const interlocutorValide = !!interlocutor && interlocutor.value !== "none";
  const showCallTypeNote = showInterlocutor && interlocutorValide;

  const onClickSegment = (seg: string | null) => {
    setCat(seg);
    if (!!!seg || [talentSegments.segment4, talentSegments.segment2].includes(seg)) {
      setInterlocutor(null);
    }
  };

  const onClickInterlocutor = (interloc: Interlocutor) => {
    setInterlocutor(interloc);
  };

  const onClickValidate = () => {
    if (!talent) {
      return;
    }
    const req = {
      linkedInProfile: {
        ...(talent && talent.linkedInProfile),
        id: talent ? talent.linkedInProfile.id : "",
        url: linkedInUrl,
      },
      talentProfile: {
        "@id": talent?.talentProfile["@id"],
        currentJob,
        currentCompany,
      },
      segment: cat,
      status:
        cat === talentSegments.segment1
          ? talentStatuses.toOnboard
          : cat === talentSegments.segment2
          ? talentStatuses.accepted
          : talentStatuses.refused,
      talentManager: interlocutorValide ? interlocutor : undefined,
      eventData: interlocutorValide
        ? {
            coreUserId: extractIdFromIri((interlocutor as CoreUser)["@id"]),
            data:
              interlocutorValide && !!callType
                ? {
                    callType,
                    note,
                    inHouse: !!inHouse,
                  }
                : null,
          }
        : null,
    };
    dispatch(sortTalentAction(talent["@id"], req));
  };

  React.useEffect(() => {
    const amGroup: GroupedOption = {
      label: "AM",
      options: [],
    };
    const timGroup: GroupedOption = {
      label: "TIM",
      options: [],
    };
    coreUsers.forEach((cu) => {
      if (!cu.enabled) {
        return;
      }
      const lastNameLetter = cu.lastname?.split("")[0];
      const name = !!cu.firstname ? `${cu.firstname}${lastNameLetter ? ` ${lastNameLetter}.` : ""}` : "nom manquant";
      const cuOption = {
        ...cu,
        label: name,
        value: cu["@id"],
      };
      if (cu.roles.includes(coreUserRoles.am)) {
        amGroup.options.push(cuOption);
      } else if (cu.roles.includes(coreUserRoles.tim)) {
        timGroup.options.push(cuOption);
      }
    });
    setGroupedOptions([otherGroup, timGroup, amGroup]);
  }, [coreUsers, otherGroup]);

  React.useEffect(() => {
    if (!!!interlocutor || interlocutor.value === "none") {
      setCallType(callTypesOption[1].value);
      setInHouse(null);
    }
  }, [interlocutor]);

  return (
    <div className={styles.talentFilterCtasContainer}>
      <div>
        <div className={styles.sectionTitleContainer}>
          <h6 className={styles.sectionTitle}>Choisir une catégorie :</h6>
        </div>
        <div className={styles.segmentsCtaContainer}>
          {[talentSegments.segment1, talentSegments.segment2, talentSegments.segment4].map((seg, i) => {
            return (
              <div key={`${seg}-${i}`}>
                <button
                  title={
                    seg === talentSegments.segment1
                      ? "Au moins une offre => on parle au talent"
                      : seg === talentSegments.segment2
                      ? "On a pas d'offre actuellement mais le talent correspond à nos critères"
                      : "Trop éloigné de nos standards, on est sûrs qu'on ne le placera jamais"
                  }
                  type="button"
                  className={cx(
                    globalStyles.cta,
                    { [globalStyles.primaryCta]: seg !== talentSegments.segment4 },
                    { [globalStyles.alertCta]: seg === talentSegments.segment4 },
                    globalStyles.inverted,
                    {
                      [globalStyles.active]: seg === cat,
                    },
                  )}
                  onClick={() => {
                    onClickSegment(seg);
                  }}
                >
                  {t({ id: transTalent.segments[seg] })}
                </button>
              </div>
            );
          })}
        </div>
      </div>
      {showInterlocutor ? (
        <div>
          <div className={styles.sectionTitleContainer}>
            <h6 className={styles.sectionTitle}>Choisir un interlocuteur :</h6>
          </div>
          <div className={styles.interlocutorSelectContainer}>
            <Select
              defaultValue={otherGroup.options[0]}
              isMulti={false}
              isSearchable={true}
              isClearable={true}
              placeholder="interlocuteur..."
              options={groupedOption}
              onChange={(op) => {
                onClickInterlocutor(op);
              }}
            />
          </div>
        </div>
      ) : null}
      {showCallTypeNote ? (
        <div className={styles.callTypeNoteContainer}>
          <div className={styles.callTypeContainer}>
            <div className={styles.sectionTitleContainer}>
              <h6 className={styles.sectionTitle}>Type d&apos;échange :</h6>
            </div>
            <div className={styles.interlocutorSelectContainer}>
              <Select
                defaultValue={callTypesOption[1]}
                isMulti={false}
                isSearchable={true}
                isClearable={true}
                placeholder="call..."
                options={callTypesOption}
                onChange={(op) => {
                  setCallType(op?.value || null);
                }}
              />
            </div>
            <div className={styles.inHouseContainer}>
              <input type="checkbox" name="in-house" id="inHouse" onChange={(e) => setInHouse(e.target.checked)} />
              <label htmlFor="inHouse">dans les bureaux</label>
            </div>
          </div>
          <div className={styles.noteContainer}>
            <div className={styles.sectionTitleContainer}>
              <h6 className={styles.sectionTitle}>Ajouter une note :</h6>
            </div>
            <div className={styles.textareaNoteContainer}>
              <textarea
                className={styles.textareaNote}
                name="additionalNote"
                id="additionalNote"
                onChange={(e) => setNote(e.target.value)}
              />
            </div>
          </div>
        </div>
      ) : null}
      <div className={styles.ctaContainer}>
        <div className={cx(styles.filterButtonTooltipContainer, globalStyles.labelTooltipContainer, globalStyles.top)}>
          {cat === talentSegments.segment1 ? (
            <span className={globalStyles.labelTooltip}>Un mail de demande de prise de RDV sera envoyé</span>
          ) : cat === talentSegments.segment2 ? (
            <span className={globalStyles.labelTooltip}>Un mail d&apos;acceptation dans la communauté sera envoyé</span>
          ) : cat === talentSegments.segment4 ? (
            <span className={globalStyles.labelTooltip}>Un mail de refus sera envoyé</span>
          ) : null}
          <button
            type="button"
            className={cx(globalStyles.cta, globalStyles.primaryCta)}
            onClick={() => {
              onClickValidate();
            }}
            disabled={!!!cat}
          >
            Valider et trier
          </button>
        </div>
      </div>
    </div>
  );
};
