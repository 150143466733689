import { t } from "@lingui/macro";
import { format, parseISO } from "date-fns";
import * as React from "react";

import { EventProps } from "..";
import { transTalent } from "../../../../../translations/constants/talent";

import styles from "./index.module.scss";

interface Props {
  event: EventProps;
}

export const HistoryItem: React.FC<Props> = (props) => {
  const { event } = props;

  return (
    <div className={styles.eventContent}>
      <div className={styles.title}>
        {event.type === "segment"
          ? t({ id: transTalent.segments[event.label] })
          : t({ id: transTalent.status[event.label] })}
      </div>
      <div className={styles.date}>
        {event.date ? format(parseISO(event.date), "dd/MM/yyyy à HH:mm") : "aucune date"}
      </div>
    </div>
  );
};
