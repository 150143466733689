import { CreateTalentCard, EditTalentCard, GetTalentCard, GetTalentCards } from "../../../actionTypes/talentCards";
import { FilterContext } from "../../../interfaces/api/filter";
import { TalentCard } from "../../../interfaces/resources/talentCard";

export function createTalentCardAction(talentCard: Partial<TalentCard>, form?: string) {
  return {
    form,
    talentCard,
    type: CreateTalentCard.REQUEST,
  };
}

export function editTalentCardAction(talentCard: Partial<TalentCard>, form?: string) {
  return {
    form,
    talentCard,
    type: EditTalentCard.REQUEST,
  };
}

export function getTalentCardAction(iri: string) {
  return {
    iri,
    type: GetTalentCard.REQUEST,
  };
}

export function getTalentCardsAction(filterContext: FilterContext) {
  return {
    filterContext,
    type: GetTalentCards.REQUEST,
  };
}
