import { t } from "@lingui/macro";
import cx from "classnames";
import { Field, Formik, Form, useFormikContext } from "formik";
import * as React from "react";
import { BiUpload } from "react-icons/bi";
import { BsInfoCircle } from "react-icons/bs";
import { FaCheck, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { Message, Popup } from "semantic-ui-react";

import { GetCompetences } from "../../actionTypes/competences";
import { GetDesiredPlaces } from "../../actionTypes/desiredPlaces";
import { GetResponsibilities } from "../../actionTypes/responsibilities";

import { PostTranscript } from "../../actionTypes/talentQualification";
import { FormMode } from "../../blocks/talentDetails/PhoneCalls";
import { CandidateProfileCard } from "../../blocks/talentDetails/Recap/CandidateProfileCard";
import { ApiError } from "../../interfaces/api/error";
import { FilterContext } from "../../interfaces/api/filter";
import { ApiRequest } from "../../interfaces/api/request";
import { ApiSuccess } from "../../interfaces/api/success";
import { Violation } from "../../interfaces/api/violation";
import { phoneCallTypes } from "../../interfaces/resources/phoneCall";
import { Qualification } from "../../interfaces/resources/qualification";
import {
  QualificationFormProps,
  talentReactivationStatuses,
  talentSegments,
  talentStatuses,
} from "../../interfaces/resources/talent";

import { State } from "../../interfaces/state";
import { getCompetencesAction } from "../../services/api/competence/actions";
import { getDesiredPlacesAction } from "../../services/api/desiredPlaces/actions";
import { getResponsibilitiesAction } from "../../services/api/responsibilities/actions";
import {
  createQualificationAction,
  createReactivationAction,
  editQualificationAction,
  editTalentAction,
} from "../../services/api/talent/actions";
import { submitPhoneCallAction } from "../../services/api/talent/phoneCalls/actions";
import { submitTranscriptAction } from "../../services/api/talent/talentQualification/actions";
import globalStyles from "../../styles/global.module.scss";

import transPhoneCall from "../../translations/constants/phoneCall";
import Yup from "../App/Yup";
import Input from "../Form/Formik/Input";

import Radio from "../Form/Formik/Radio";
import RichTextEditor from "../Form/Formik/RichTextEditor";
import { useApiSelector, useGetResourceHook, usePrevious } from "../Utils/hook";
import LoaderComp from "../Utils/Loader";
import { getInitialValues } from "./helpers";
import styles from "./index.module.scss";
import TalentOfferList from "./TalentOfferList";

interface Props {
  edition: boolean;
  reactivation?: boolean;
  qualificationIri?: string;
  handleClose?: () => void;
  setFormMode?(mode: FormMode): void;
}

const validationSchema = Yup.object().shape({
  qualification: Yup.object().shape({
    context: Yup.string().required(),
    actualRemuneration: Yup.object().shape({
      fixedSalary: Yup.number()
        .integer("Veuillez rentrer un nombre entier")
        .transform((value) => (isNaN(value) ? undefined : value))
        .required(),
      fullPackage: Yup.number()
        .integer("Veuillez rentrer un nombre entier")
        .transform((value) => (isNaN(value) ? undefined : value))
        .required()
        .max(999)
        .min(Yup.ref("fixedSalary")),
    }),
    expectedRemuneration: Yup.object().shape({
      fixedSalary: Yup.number()
        .integer("Veuillez rentrer un nombre entier")
        .transform((value) => (isNaN(value) ? undefined : value))
        .required(),
      fullPackage: Yup.number()
        .integer("Veuillez rentrer un nombre entier")
        .transform((value) => (isNaN(value) ? undefined : value))
        .required()
        .max(999)
        .min(Yup.ref("fixedSalary")),
    }),
    priorNotice: Yup.string(),
    bridges: Yup.string(),
    idealJob: Yup.string(),
    conclusion: Yup.string(),
  }),
});

const validationSchemaDecline = Yup.object().shape({
  qualification: Yup.object().shape({
    conclusion: Yup.string().required(),
  }),
  talentProfile: Yup.object().shape({
    coreResponsibility: Yup.object()
      .shape({
        "@id": Yup.string(),
        value: Yup.string(),
      })
      .required(),
    desiredPlaces: Yup.array()
      .of(
        Yup.object().shape({
          "@id": Yup.string(),
          value: Yup.string(),
        }),
      )
      .required(),
    responsibilities: Yup.array().of(
      Yup.object().shape({
        "@id": Yup.string(),
        value: Yup.string(),
      }),
    ),
  }),
});

const priorNoticeOptions = ["notice_asap", "notice_1_month", "notice_3_months", "notice_>3_months"];

export const QUALIFICATION_FORM = "QUALIFICATION_FORM";

type FormSubmissionType = "" | "accept" | "decline" | "edition" | "reactivation" | "creation" | "transcript";

const QualificationForm: React.FC<Props> = ({
  edition,
  handleClose,
  reactivation,
  qualificationIri,
  setFormMode,
}: Props) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<boolean>(false);
  const [violations, setViolations] = React.useState<Violation[]>([]);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [success, setSuccess] = React.useState(false);
  const [reload, setReload] = React.useState<boolean>(false);
  const [transcriptType, setTranscriptType] = React.useState<null | "text" | "video">(null);
  const [formSubmission, setFormSubmission] = React.useState<FormSubmissionType>("");

  const { apiErrors, apiPendingRequests, apiSuccess } = useApiSelector();
  const competences = useSelector((state: State) => state.competences);
  const currentUser = useSelector((state: State) => state.currentUser);
  const desiredPlaces = useSelector((state: State) => state.desiredPlaces);
  const responsibilities = useSelector((state: State) => state.responsibilities);
  const talent = useSelector((state: State) => state.talent);
  const talentProfile = useSelector((state: State) => state.talentProfile);
  const talentQualifications = useSelector((state: State) => state.talentQualifications);
  const prevtalentQualifications = usePrevious(talentQualifications);
  const uniqId = React.useRef(`${talent?.["@id"]}-${QUALIFICATION_FORM}`).current;
  const [initialValues, setInitialValues] = React.useState<any>(null);
  const [qualification, setQualification] = React.useState<Qualification | undefined>(undefined);
  const [iriQualif, setIriQualif] = React.useState<string>("");
  const [errorMsg, setErrorMsg] = React.useState<string | null>(null);
  const [file, setFile] = React.useState<File | null>(null);
  const dispatch = useDispatch();
  const createReactivation = (req: QualificationFormProps, form: string): AnyAction =>
    dispatch(createReactivationAction(req, form));
  const createQualification = (req: QualificationFormProps, form: string): AnyAction =>
    dispatch(createQualificationAction(req, form));

  const editQualification = (req: QualificationFormProps, form: string): AnyAction =>
    dispatch(editQualificationAction(req, form));
  const getCompetences = (filterContext: FilterContext = []): AnyAction =>
    dispatch(getCompetencesAction(filterContext));
  const editTalent = (iri: string, req: any, form: any): AnyAction => dispatch(editTalentAction(iri, req, form));

  const getDesiredPlaces = (): AnyAction => dispatch(getDesiredPlacesAction());
  const getResponsibilities = (): AnyAction => dispatch(getResponsibilitiesAction());

  useGetResourceHook(apiErrors, apiPendingRequests, apiSuccess, GetCompetences, competences, getCompetences);
  useGetResourceHook(apiErrors, apiPendingRequests, apiSuccess, GetDesiredPlaces, desiredPlaces, getDesiredPlaces);

  useGetResourceHook(
    apiErrors,
    apiPendingRequests,
    apiSuccess,
    GetResponsibilities,
    responsibilities,
    getResponsibilities,
  );
  const prevApiSuccess = usePrevious(apiSuccess);
  const prevApiErrors = usePrevious(apiErrors);

  React.useEffect(() => {
    if (
      prevApiSuccess
      && !prevApiSuccess.some((e) => QUALIFICATION_FORM === e.form)
      && apiSuccess.some((e: ApiSuccess) => QUALIFICATION_FORM === e.form)
      && !apiErrors.some((e: ApiError) => e.form === QUALIFICATION_FORM)
    ) {
      setError(false);
      setViolations([]);
      setSuccess(true);
    }
  }, [apiErrors, apiSuccess, prevApiSuccess, success, setError, setViolations, setSuccess]);
  React.useEffect(() => {
    if (
      prevApiErrors
      && !prevApiErrors.some((e) => QUALIFICATION_FORM === e.form)
      && apiErrors.some((e: ApiError) => QUALIFICATION_FORM === e.form)
    ) {
      const apiError = apiErrors.find((e) => QUALIFICATION_FORM === e.form);
      if (apiError) {
        setViolations(apiError.payload.violations || []);
        setSuccess(false);
      }
    }
  }, [apiErrors, prevApiErrors, setViolations, setSuccess]);

  React.useEffect(() => {
    if (apiErrors.some((e: ApiError) => PostTranscript.FAILURE === e.type)) {
      const apiError = apiErrors.find((e) => PostTranscript.FAILURE === e.type);
      if (apiError) {
        setError(true);
        setErrorMessage(apiError.payload.message || "");
        setSuccess(false);
      }
      setTimeout(() => {
        setError(false);
        setErrorMessage(null);
      }, 2000);
    }
  }, [apiErrors, prevApiErrors, setErrorMessage, setSuccess]);

  // React.useEffect(() => {
  //   if (apiSuccess.some((e: ApiSuccess) => PostTranscript.SUCCESS === e.type)) {
  //     setError(false);
  //     setErrorMessage(null);
  // setSuccess(true);
  // if (talent) {
  //   getTalent(talent["@id"]);
  // }
  // }
  // setTimeout(() => {
  //   setSuccess(false);
  // }, 2000);
  // }, [apiSuccess, setErrorMessage, setSuccess, setError]);

  React.useEffect(() => {
    setIsLoading(
      apiPendingRequests.length > 0
        && apiPendingRequests.some(
          (req: ApiRequest) => req.form === QUALIFICATION_FORM || req.type === PostTranscript.REQUEST,
        ),
    );
  }, [apiPendingRequests, isLoading]);

  const handleReactivationSubmit = (values: QualificationFormProps): void => {
    if (!!!talent) {
      throw new Error("Aucun talent associé");
    }
    if (!!!currentUser) {
      throw new Error("CoreUser manquant");
    }
    if (!isLoading) {
      createReactivation(
        { ...values, talentProfile: { ...values.talentProfile, responsibilities: [] } },
        QUALIFICATION_FORM,
      );
    }
    const val = {
      reactivationStatus: [
        talentReactivationStatuses.passive_searching,
        talentReactivationStatuses.searching,
        talentReactivationStatuses.to_contact,
      ].includes(talent.reactivationStatus || "")
        ? talentReactivationStatuses.contacted
        : talent.reactivationStatus,
      needReactivation: false,
    };
    editTalent(
      talent["@id"],
      {
        ...talent,
        ...val,
        talentManager: currentUser["@id"],
      },
      "reactivation",
    );
    if (values.notes) {
      dispatch(
        submitPhoneCallAction({
          notes: values.notes,
          title: "Process en cours",
          type: phoneCallTypes.filter_ongoing_process,
          coreUser: currentUser,
          talent,
        }),
      );
    }

    if (handleClose) {
      handleClose();
    }
    if (setFormMode) {
      setFormMode("suivi");
    }
  };

  const handleEditionSubmit = (values: QualificationFormProps): void => {
    editQualification({ ...values }, QUALIFICATION_FORM);
    if (values.notes) {
      dispatch(
        submitPhoneCallAction({
          notes: values.notes,
          title: "Process en cours",
          type: phoneCallTypes.filter_ongoing_process,
          coreUser: currentUser,
          talent,
        }),
      );
    }
    if (handleClose) {
      handleClose();
    }
  };

  const onSubmit = (values: QualificationFormProps): void => {
    createQualification(
      {
        ...values,
        talentProfile: {
          ...values.talentProfile,
          responsibilities: [],
        },
      },
      QUALIFICATION_FORM,
    );
    if (values.notes) {
      dispatch(
        submitPhoneCallAction({
          notes: values.notes,
          title: "Process en cours",
          type: phoneCallTypes.filter_ongoing_process,
          coreUser: currentUser,
          talent,
        }),
      );
    }
    if (handleClose) {
      handleClose();
    }
  };

  const handleSubmitAndAccept = (values: QualificationFormProps): void => {
    onSubmit({
      ...values,
      status: talentStatuses.accepted,
    });
  };

  const handleSubmitAndDecline = (values: QualificationFormProps): void => {
    onSubmit({
      ...values,
      status: talentStatuses.refused,
      segment: talentSegments.segment4,
    });
  };

  React.useEffect(() => {
    setQualification(
      talentQualifications.find((e) =>
        !!talentProfile && talentProfile.lastQualification
          ? e["@id"] === talentProfile.lastQualification["@id"]
          : false,
      ),
    );
    if (edition) {
      setIriQualif(qualificationIri || "");
    } else {
      setIriQualif((talentProfile && talentProfile.lastQualification && talentProfile.lastQualification["@id"]) || "");
    }
  }, [talentQualifications, talentProfile, qualificationIri, edition]);

  React.useEffect(() => {
    if (!apiSuccess.some((e: ApiSuccess) => e.type === PostTranscript.SUCCESS)) {
      setInitialValues(
        getInitialValues(currentUser, talent, talentProfile, talentQualifications, (qualification && iriQualif) || ""),
      );
    } else {
      setInitialValues(
        getInitialValues(currentUser, talent, talentProfile, talentQualifications, talentQualifications[0]["@id"]),
      );
    }
  }, [currentUser, talent, talentProfile, talentQualifications, qualification, iriQualif, apiSuccess]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files?.length) {
      if (e.target.files[0].type !== "audio/mpeg") {
        setErrorMsg("Le fichier doit être un fichier audio");
        return;
      } else if (e.target.files[0].size / 1000000 > 20) {
        setErrorMsg("Le fichier doit être inférieur à 20 Mo");
      } else {
        setErrorMsg(null);
      }
      setFile(e.target.files[0]);
    }
  };
  const UpdateFieldsTranscript = () => {
    const { setFieldValue } = useFormikContext();
    React.useEffect(() => {
      if (
        apiSuccess.some((e: ApiSuccess) => e.type === PostTranscript.SUCCESS)
        && talentQualifications !== prevtalentQualifications
      ) {
        const qualif = talentQualifications[0];
        setReload(true);
        setFieldValue("qualification.conclusion", qualif.conclusion);
        setFieldValue("qualification.idealJob", qualif.idealJob);
        setFieldValue("qualification.storytelling", qualif.storytelling);
        setFieldValue("qualification.bridges", qualif.bridges);
        setFieldValue("qualification.priorNotice", qualif.priorNotice);
        setFieldValue("qualification.actualRemuneration.fixedSalary", qualif.actualRemuneration.fixedSalary);
        setFieldValue("qualification.actualRemuneration.fullPackage", qualif.actualRemuneration.fullPackage);
        setFieldValue("qualification.expectedRemuneration.fixedSalary", qualif.expectedRemuneration.fixedSalary);
        setFieldValue("qualification.expectedRemuneration.fullPackage", qualif.expectedRemuneration.fullPackage);
      }
    }, [currentUser, talent, talentProfile, talentQualifications, qualification, iriQualif, apiSuccess]);
    return null;
  };

  const handleAddTranscript = (text: string | null) => {
    if (talent && file && currentUser && transcriptType === "video") {
      dispatch(submitTranscriptAction(file, talent["@id"], currentUser["@id"], transcriptType, iriQualif));
    } else if (talent && currentUser && text && transcriptType === "text") {
      dispatch(submitTranscriptAction(text, talent["@id"], currentUser["@id"], transcriptType, iriQualif));
    }
  };

  return (
    <>
      {isLoading ? (
        <div className={styles.loaderComp}>
          <LoaderComp />
        </div>
      ) : null}
      <div className={cx(styles.qualificationFormWrapper, { [styles.loading]: isLoading })}>
        {initialValues ? (
          <Formik
            onSubmit={(values): void => {
              if (!!!talent || !!!currentUser) {
                throw new Error("Erreur : Talent ou CoreUser manquant");
              }
              switch (formSubmission) {
                case "accept":
                  handleSubmitAndAccept(values);
                  break;
                case "decline":
                  handleSubmitAndDecline(values);
                  break;
                case "edition":
                  handleEditionSubmit(values);
                  break;
                case "reactivation":
                  handleReactivationSubmit({
                    ...values,
                    qualification: {
                      ...values.qualification,
                      coreUser: currentUser["@id"],
                    },
                  });
                  break;
                case "transcript":
                  handleAddTranscript(values.qualification.transcript);
                  break;
              }
            }}
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={() =>
              Yup.lazy((_values: any) => {
                if ("decline" === formSubmission || talentStatuses.refused === talent?.status) {
                  return validationSchemaDecline;
                }
                return validationSchema;
              })
            }
          >
            {({ handleSubmit, values }): JSX.Element => {
              return (
                <Form onSubmit={handleSubmit} className={cx("form", { loading: isLoading })}>
                  <div className={styles.formHeaderContainer}>
                    <h2 className={styles.formTitle}>
                      {reactivation ? t`QualificationFom.title.reactivation` : t`QualificationFom.title.qualification`}
                    </h2>
                  </div>
                  {errorMsg && <div className={styles.errorMsg}>{errorMsg}</div>}

                  <div className={styles.transcriptTypeContainer}>
                    <button
                      onClick={() => setTranscriptType("text")}
                      className={cx(globalStyles.cta, globalStyles.primaryCta, styles.transcriptCta, {
                        [globalStyles.inverted]: transcriptType !== "text",
                      })}
                    >
                      Utiliser un transcript Texte
                    </button>
                    <button
                      onClick={() => setTranscriptType("video")}
                      className={cx(globalStyles.cta, globalStyles.primaryCta, styles.transcriptCta, {
                        [globalStyles.inverted]: transcriptType !== "video",
                      })}
                    >
                      Transcrire une Vidéo
                    </button>
                  </div>
                  {transcriptType === "text" && (
                    <div>
                      <div className={cx(styles.overflow)}>
                        <Field
                          name="qualification.transcript"
                          component={RichTextEditor}
                          uniqId={`${uniqId}-qualification.transcript`}
                          label={t`qualification.transcript`}
                          placeholder={t`qualification.transcript.placeholder`}
                          replaceIfEmpty={false}
                        />
                      </div>
                      <button
                        onClick={() => setFormSubmission("transcript")}
                        className={cx(styles.cta, globalStyles.cta, globalStyles.primaryCta, styles.textTranscript)}
                        type="submit"
                        disabled={isLoading || errorMsg !== null || !values.qualification.transcript}
                      >
                        <BiUpload />
                        Transcript
                      </button>
                    </div>
                  )}
                  {transcriptType === "video" && (
                    <div className={styles.uploadFormContent}>
                      <input type="file" onChange={handleChange} className={styles.fileInput}></input>
                      <button
                        onClick={() => handleAddTranscript(null)}
                        className={cx(styles.cta, globalStyles.cta, globalStyles.primaryCta)}
                        type="button"
                        disabled={isLoading || !file || errorMsg !== null}
                      >
                        <BiUpload />
                        Transcript
                      </button>
                      <Popup
                        on="click"
                        content={
                          <div>
                            <div>
                              <h5>
                                Upload l&apos;enregistrement du call talent pour générer automatiquement une
                                qualification{" "}
                              </h5>
                              <p>
                                N&apos;oublie pas{" "}
                                <a href="https://www.freeconvert.com/fr/mp4-to-mp3" target="_blank" rel="noreferrer">
                                  de convertir ton fichier en mp3
                                </a>
                              </p>
                            </div>
                            <p>
                              Tu peux également{" "}
                              <a href="https://www.freeconvert.com/fr/mp3-compressor" target="_blank" rel="noreferrer">
                                compresser ton fichier
                              </a>{" "}
                            </p>
                          </div>
                        }
                        trigger={<BsInfoCircle className={styles.infoButton} />}
                        wide
                      />
                    </div>
                  )}
                  <div className={styles.formSidesContainer}>
                    <div className={styles.leftSide}>
                      <div className="formPart">
                        {/* Exercice des ponts */}
                        <div className="formGroup formTextAreaGroup">
                          <Field
                            name="qualification.bridges"
                            component={RichTextEditor}
                            uniqId={`${uniqId}-qualification.bridges`}
                            isRequired={true}
                            label={t`qualification.bridges`}
                            placeholder={t`qualification.bridges.placeholder`}
                            replaceIfEmpty={false}
                            reload={reload}
                            setReload={setReload}
                          />
                        </div>
                        {/* Priorisation des critères de recherche */}
                        <div className="formGroup formTextAreaGroup">
                          <Field
                            name="qualification.idealJob"
                            component={RichTextEditor}
                            uniqId={`${uniqId}-qualification.idealJob`}
                            label="Priorisation des critères de recherche"
                            placeholder="Challenger les Must Have / Nice to Have / No Go"
                            replaceIfEmpty={false}
                            isRequired={false}
                            reload={reload}
                            setReload={setReload}
                          />
                        </div>
                        {/* Conclusion */}
                        <div className="formGroup formTextAreaGroup">
                          <Field
                            name="qualification.conclusion"
                            component={RichTextEditor}
                            uniqId={`${uniqId}-qualification.conclusion`}
                            label={t`qualification.conclusion`}
                            placeholder={t`qualification.conclusion`}
                            replaceIfEmpty={false}
                            reload={reload}
                            setReload={setReload}
                          />
                        </div>
                      </div>
                      <div className="formPart">
                        <div className="formPartTitle">Partie pro</div>
                        <div className="formGroup formTextAreaGroup"></div>
                        <div className="formGroup formInlineGroup double">
                          <div>
                            <div className={cx("formGroup", styles.salariesGroupsContainer)}>
                              <div>
                                <h3 className="formSubPartTitle">{t`qualification.remuneration.actual.short`}</h3>
                                <div className="formGroup formInlineGroup double">
                                  <Field
                                    className={styles.salaryInputLabel}
                                    component={Input}
                                    isSubLabel={true}
                                    label={t`remuneration.fixed`}
                                    name="qualification.actualRemuneration.fixedSalary"
                                    placeholder={t`remuneration.fixed.placeholder`}
                                    type="number"
                                  />
                                  <Field
                                    className={styles.salaryInputLabel}
                                    component={Input}
                                    isSubLabel={true}
                                    label={t`remuneration.fullPackage`}
                                    name="qualification.actualRemuneration.fullPackage"
                                    placeholder={t`remuneration.fullPackage.placeholder`}
                                    type="number"
                                  />
                                </div>
                              </div>
                              <div>
                                <h3 className="formSubPartTitle">{t`qualification.remuneration.expected.short`}</h3>
                                <div className="formGroup formInlineGroup double">
                                  <Field
                                    name="qualification.expectedRemuneration.fixedSalary"
                                    component={Input}
                                    isSubLabel={true}
                                    type="number"
                                    label={t`remuneration.fixed`}
                                    placeholder={t`remuneration.fixed.placeholder`}
                                  />
                                  <Field
                                    name="qualification.expectedRemuneration.fullPackage"
                                    component={Input}
                                    isSubLabel={true}
                                    type="number"
                                    label={t`remuneration.fullPackage`}
                                    placeholder={t`remuneration.fullPackage.placeholder`}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="formGroup">
                              <Field
                                name="qualification.priorNotice"
                                component={Radio}
                                className={cx("fieldClientFacing", styles.availabilityField)}
                                label={t`qualification.priorNotice`}
                                getOptionLabel={(option: string): string =>
                                  t({ id: transPhoneCall.priorNotice[option] })
                                }
                                getOptionValue={(option: string): string => option}
                                options={priorNoticeOptions}
                                violations={violations}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="formPart">
                        <div className={styles.formSubmitCtasContainer}>
                          {edition || reactivation ? (
                            <div>
                              <button
                                className={cx(styles.formSubmitCta, globalStyles.cta, globalStyles.primaryCta)}
                                type="submit"
                                onClick={() => setFormSubmission(edition ? "edition" : "reactivation")}
                                disabled={isLoading}
                              >
                                <FaCheck /> <span>{t`QualificationForm.edition.submit`}</span>
                              </button>
                            </div>
                          ) : (
                            <>
                              <div>
                                <button
                                  className={cx(styles.formSubmitCta, globalStyles.cta, globalStyles.primaryCta)}
                                  type="submit"
                                  onClick={() => setFormSubmission("accept")}
                                  disabled={isLoading}
                                >
                                  <FaCheck /> <span>{t`qualification.labels.submitAccept`}</span>
                                </button>
                              </div>

                              <div className={styles.refuseCtaContainer}>
                                <button
                                  className={cx(styles.formSubmitCta, globalStyles.cta, globalStyles.alertCta)}
                                  type="submit"
                                  onClick={() => setFormSubmission("decline")}
                                  disabled={isLoading}
                                >
                                  <FaTimes /> <span>{t`qualification.labels.submitDecline`}</span>
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="formPart" style={{ marginBottom: "12px" }}>
                        {/* Contexte */}
                        <div className="formGroup formTextAreaGroup">
                          <Field
                            name="qualification.context"
                            component={RichTextEditor}
                            uniqId={`${uniqId}-qualification.context`}
                            label={t`qualification.context`}
                            placeholder={t`qualification.context.placeholder`}
                            replaceIfEmpty={false}
                            isRequired={false}
                          />
                        </div>
                        {/* Parcours */}
                        <div className="formGroup formTextAreaGroup">
                          <Field
                            name="qualification.storytelling"
                            component={RichTextEditor}
                            className="fieldClientFacing"
                            uniqId={`${uniqId}-qualification.storytelling`}
                            label={t`QualificationForm.storytelling`}
                            placeholder={t`QualificationForm.storytelling.placeholder`}
                            replaceIfEmpty={false}
                            reload={reload}
                            setReload={setReload}
                            isRequired={false}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={cx(styles.rightSide, styles.stickyContainer)}>
                      <div className={styles.stickyBlock}>
                        <CandidateProfileCard form="qualification" />
                        <div className="formGroup">
                          <Field name="qualification.applications" component={TalentOfferList} />
                        </div>
                        <div className="formGroup">
                          <Field
                            name="notes"
                            component={RichTextEditor}
                            label="Process"
                            uniqId={`${uniqId}-notes`}
                            replaceIfEmpty={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.formFooterContainer}>
                    {success || error ? (
                      <div className={styles.messagesContainer}>
                        {success ? (
                          <Message
                            success
                            header={
                              reactivation
                                ? t`QualificationForm.success.header.reactivation`
                                : t`QualificationForm.success.header`
                            }
                            content={t`QualificationForm.success.content`}
                            icon="check circle"
                          />
                        ) : null}
                        {error ? (
                          <Message
                            error
                            header={t`QualificationForm.error.header`}
                            content={
                              <div>
                                {t`QualificationForm.error.content`} {errorMessage}
                              </div>
                            }
                            icon="times circle"
                          />
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                  <UpdateFieldsTranscript />
                </Form>
              );
            }}
          </Formik>
        ) : null}
      </div>
    </>
  );
};

export default QualificationForm;
