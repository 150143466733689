import { t } from "@lingui/macro";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Segment } from "semantic-ui-react";

import { GetTalentApplications } from "../../../actionTypes/talentApplications";
import { useApiSelector, usePrevious } from "../../../components/Utils/hook";
import { ApiError } from "../../../interfaces/api/error";
import { ApiRequest } from "../../../interfaces/api/request";
import { Application } from "../../../interfaces/resources/application";
import { Talent } from "../../../interfaces/resources/talent";
import { getTalentApplicationsAction } from "../../../services/api/talent/talentApplication/actions";

import ApplicationItem from "./ApplicationItem";
import styles from "./index.module.scss";
import "./index.scss";

const Applications: React.FC = () => {
  const { apiErrors, apiPendingRequests } = useApiSelector();
  const talent: Talent | null = useSelector((state: any) => state.talent);
  const applications: Application[] = useSelector((state: any) => state.talentApplications);
  const dispatch = useDispatch();

  const previousTalent = usePrevious(talent);
  React.useEffect(() => {
    const getTalentApplications = (tlnt: Talent) => {
      dispatch(
        getTalentApplicationsAction(
          tlnt["@id"],
          [
            {
              multiple: false,
              name: "order",
              param: "updated_at",
              value: "DESC",
            },
          ],
          "talentApplications",
        ),
      );
    };
    if (
      !apiErrors.some((e: ApiError) => e.type === GetTalentApplications.FAILURE)
      && !apiPendingRequests.some((e: ApiRequest) => e.type === GetTalentApplications.REQUEST)
      && talent
      && talent["@id"]
      && (!previousTalent || previousTalent["@id"] !== talent["@id"])
    ) {
      getTalentApplications(talent);
    }
  }, [apiErrors, apiPendingRequests, talent, previousTalent, dispatch]);

  const isLoading =
    apiPendingRequests.length > 0 && apiPendingRequests.some((e) => GetTalentApplications.REQUEST === e.type);
  return (
    <Segment basic loading={isLoading} className="talentApplicationsContainer">
      {applications && applications.length > 0 ? (
        <ul className={styles.applicationsList}>
          {applications.map((item) => (
            <li key={`application-item-${item["@id"]}`} className={styles.applicationItem}>
              <ApplicationItem application={item} />
            </li>
          ))}
        </ul>
      ) : (
        <div className="empty">{t`applications.empty`}</div>
      )}
    </Segment>
  );
};

export default Applications;
