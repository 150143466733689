export interface Responsibility {
  "@id": string;
  active: boolean;
  value: string;
  customType?: string;
  seniority?: string;
  responsibilityType: ResponsibilityType;
}

export interface ResponsibilityWithSeniority extends Responsibility {
  seniority?: string;
}

export interface ResponsibilityType {
  "@id": string;
  value: string;
}

export interface TalentProfileResponsibility {
  "@id": string;
  seniority: string;
  responsibility: Responsibility;
  talentProfile: string;
}

interface ResponsibilityOption {
  label: string;
  options: Responsibility[];
}

export const formatResponsibilities = (responsibilities: Responsibility[]): ResponsibilityOption[] => {
  return responsibilities.reduce((result: ResponsibilityOption[], item: Responsibility) => {
    const responsibilityType = item.responsibilityType;
    if (result.some((e) => e.label === responsibilityType.value)) {
      result.forEach((e) => {
        if (e.label === responsibilityType.value) {
          e.options.push(item);
        }
      });
    } else {
      result.push({
        label: responsibilityType.value,
        options: [item],
      });
    }
    return result;
  }, []);
};
