import { ActionType } from "../interfaces/api/actionType";

export const DeletePhoneCall: ActionType = {
  FAILURE: "DELETE_PHONE_CALL_FAILURE",
  REQUEST: "DELETE_PHONE_CALL_REQUEST",
  SUCCESS: "DELETE_PHONE_CALL_SUCCESS",
};

export const GetPhoneCalls: ActionType = {
  FAILURE: "GET_PHONE_CALLS_FAILURE",
  REQUEST: "GET_PHONE_CALLS_REQUEST",
  SUCCESS: "GET_PHONE_CALLS_SUCCESS",
};

export const PostPhoneCall: ActionType = {
  FAILURE: "POST_PHONE_CALL_FAILURE",
  REQUEST: "POST_PHONE_CALL_REQUEST",
  SUCCESS: "POST_PHONE_CALL_SUCCESS",
};

export const EditPhoneCall: ActionType = {
  FAILURE: "EDIT_PHONE_CALL_FAILURE",
  REQUEST: "EDIT_PHONE_CALL_REQUEST",
  SUCCESS: "EDIT_PHONE_CALL_SUCCESS",
};
