import cx from "classnames";
import * as React from "react";
import { FC } from "react";
import { BiChevronDown } from "react-icons/bi";

import styles from "./index.module.scss";

interface Props {
  header?: React.ReactNode;
  collapsed?: boolean;
}

export const FilterPanel: FC<React.PropsWithChildren<Props>> = (props: React.PropsWithChildren<Props>) => {
  const { children, header, collapsed = false } = props;
  const [closed, setClosed] = React.useState(collapsed);
  const onClickToggle = () => {
    setClosed(!closed);
  };
  return (
    <div className={cx(styles.panel, { [styles.collapsed]: closed })}>
      <div className={styles.header} onClick={onClickToggle}>
        {header ? <div>{header}</div> : null}
        <div className={styles.chevron}>
          <BiChevronDown />
        </div>
      </div>
      <div className={styles.body}>{children}</div>
    </div>
  );
};
