import algoliasearch from "algoliasearch";
import { getTime, subDays } from "date-fns";
import * as React from "react";
import { Configure, Hits, InstantSearch, Pagination } from "react-instantsearch-hooks-web";
import { useSelector } from "react-redux";
import { talentSegments } from "../../interfaces/resources/talent";
import { State } from "../../interfaces/state";
import { AlgoliaTalentCard } from "../../pages/AlgoliaSearchPage/AlgoliaTalentCard";
import { extractIdFromIri } from "../../services/api/helpers";
import { NoResultsBoundary } from "../Algolia/NoResultsBoundary";
import styles from "./index.module.scss";

interface Props {
  title?: React.ReactNode;
  isAm?: boolean;
}

export const NewSearchingTalents: React.FC<Props> = (props: Props): JSX.Element => {
  const { title, isAm = false } = props;
  const currentUser = useSelector((state: State) => state.currentUser);
  const subbedDays = 3;
  const timeLimit = React.useRef(getTime(subDays(new Date(), subbedDays)) / 1000).current;
  const personasQuery = isAm
    ? "talentProfile.persona:active_performer"
    : `(talentProfile.persona:active_neutral OR talentProfile.persona:active_performer) AND talentManager.firstname:${currentUser?.firstname}`;
  const filterQuery = `${personasQuery} AND lastTalentSearchStatusEvent.createdAtTimestamp >= ${timeLimit} AND (searchStatus:searching OR searchStatus:passive_searching)`;

  const searchClient = algoliasearch(
    `${process.env.REACT_APP_ALGOLIA_APP_ID}`,
    `${process.env.REACT_APP_ALGOLIA_SECRET_KEY}`,
    {
      headers: {
        "X-Algolia-UserToken": currentUser?.["@id"]
          ? `coreUser-${extractIdFromIri(currentUser["@id"])}`
          : "unidentified_user",
      },
    },
  );
  const algoliaIndexName = `${process.env.REACT_APP_ALGOLIA_INDEX_PREFIX}${talentSegments.segment1}`;
  if (!!!algoliaIndexName) {
    throw new Error("REACT_APP_ALGOLIA_INDEX_PREFIX is missing");
  }

  return (
    <div>
      {!!title ? title : null}
      <div>
        <InstantSearch searchClient={searchClient} indexName={algoliaIndexName}>
          <Configure filters={filterQuery} hitsPerPage={3} />
          <NoResultsBoundary fallback={<div className={styles.emptyResults}>Pas de nouveaux talents en recherche</div>}>
            <div>
              <Hits
                hitComponent={({ hit, sendEvent }: any) => {
                  return (
                    <>
                      <AlgoliaTalentCard hitTalent={hit} sendEvent={sendEvent} mode="minimal" />
                    </>
                  );
                }}
              />
            </div>
            <div className={styles.paginationContainer}>
              <Pagination />
            </div>
          </NoResultsBoundary>
        </InstantSearch>
      </div>
    </div>
  );
};
