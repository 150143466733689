import { EditPhoneCall, GetPhoneCalls, PostPhoneCall, DeletePhoneCall } from "../../../../actionTypes/phoneCalls";
import { PhoneCall } from "../../../../interfaces/resources/phoneCall";

export function phoneCalls(state: PhoneCall[] = [], action: any) {
  switch (action.type) {
    case GetPhoneCalls.SUCCESS:
      return action.phoneCalls;
    case EditPhoneCall.SUCCESS:
      return [
        ...state.filter((phoneCall: PhoneCall) => phoneCall["@id"] !== action.phoneCall["@id"]),
        action.phoneCall,
      ];
    case DeletePhoneCall.SUCCESS:
      state.splice(
        state.findIndex((e: PhoneCall) => action.phoneCall["@id"] === e["@id"]),
        1,
      );
      return [...state];
    case PostPhoneCall.SUCCESS:
      return [action.phoneCall, ...state];
    default:
      return state;
  }
}

export function shortlistPhoneCalls(state: PhoneCall[] = [], action: any) {
  switch (action.type) {
    case GetPhoneCalls.SUCCESS:
      return [...action.phoneCalls, ...state];
    default:
      return state;
  }
}
