import { t } from "@lingui/macro";
import * as React from "react";
import { Label } from "../.";
import { Tooltip } from "../../../components/Tooltip";
import { LateStageApplication } from "../../../interfaces/resources/talent";
import { applicationLabelTrans, LabelType } from "../../../translations/constants/label";
import styles from "./index.module.scss";

interface Props {
  lap: LateStageApplication;
  className?: string;
}

const TooltipContent: React.FC<Props> = (props: Props) => {
  const { lap } = props;
  return (
    <div className={styles.tooltip}>
      <div>{t({ id: applicationLabelTrans[lap.stage as keyof LabelType] })}</div>
      <div>{lap.name}</div>
    </div>
  );
};

export const LateStageLabel: React.FC<Props> = (props: Props) => {
  const { lap, className } = props;

  return (
    <Tooltip content={<TooltipContent lap={lap} />}>
      <Label
        type={["last_lap", "offer_lap"].includes(lap.stage) ? "green" : "yellow"}
        className={!!className ? className : undefined}
        content={t({ id: applicationLabelTrans[lap.stage as keyof LabelType] })}
      />
    </Tooltip>
  );
};
