import { t } from "@lingui/macro";
import React from "react";
import { useInstantSearch } from "react-instantsearch-hooks-web";
import { transTalent } from "../../translations/constants/talent";
import styles from "./index.module.scss";

interface Props {
  segment?: string;
}

export const AlgoliaNbHits: React.FC<Props> = (props: Props) => {
  const { segment } = props;
  const { results } = useInstantSearch();
  return (
    <div className={styles.nbHitsTitle}>
      <span className={styles.nb}>{results.nbHits}</span> talents{" "}
      {segment ? ` ${t({ id: transTalent.segments[segment] })}` : null}
    </div>
  );
};
