import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";

import { GetOfferApplication, GetOfferApplications } from "../../../../actionTypes/offers";
import { Application } from "../../../../interfaces/resources/application";
import { apiFailureAction } from "../../actions";
import { getSuccessType } from "../../helpers";
import { getOfferApplicationsSuccessAction } from "./actions";
import * as apiClient from "./api";

export function* getOfferApplications(action: AnyAction) {
  try {
    const response: AxiosResponse<{ "hydra:member": Application[] }> = yield call(
      apiClient.getOfferApplications,
      action.iri,
      action.filterContext,
    );
    const applications = response.data["hydra:member"];
    yield put(getOfferApplicationsSuccessAction(applications, action.concat));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* getOfferApplication(action: AnyAction): any {
  try {
    const response = yield call(apiClient.getOfferApplication, action.iri);
    const data = response.data;
    yield put({
      application: data,
      type: getSuccessType(action),
    });
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export default function* offerApplicationsSaga() {
  yield takeLatest(GetOfferApplications.REQUEST, getOfferApplications);
  yield takeLatest(GetOfferApplication.REQUEST, getOfferApplication);
}
