import { t } from "@lingui/macro";
import * as React from "react";
import { Link } from "react-router-dom";
import { Button, Icon, Item } from "semantic-ui-react";

import { Offer, offerStatuses, offerStatusesColors } from "../../../interfaces/resources/offer";
import { extractPageFromIri } from "../../../services/api/helpers";
import transOffer from "../../../translations/constants/offer";
import { getCompanyImage } from "../../App/Image";

import RoundImage from "../../Design/RoundImage";
import ResponsibilitiesCompact from "../../Responsibilities/ResponsibilitiesCompact";
import styles from "./index.module.scss";

interface Props {
  offer: Offer;
}

class OfferShort extends React.PureComponent<Props> {
  public render() {
    const { offer } = this.props;
    return (
      <Item className={styles.offerShortItem}>
        <Link className={styles.offerShortLink} target="_blank" to={extractPageFromIri("offers", offer["@id"])}></Link>
        <Item.Image>
          <RoundImage
            size="65px"
            banner={
              offer.status === offerStatuses.standbyElinoi ? t({ id: transOffer.status[offer.status] }) : undefined
            }
            {...getCompanyImage(offer.company.image)}
          />
        </Item.Image>
        <Item.Content>
          <Item.Header>
            <Icon name="circle" size="small" style={{ color: offerStatusesColors[offer.status] }} />
            {offer.company && offer.company.name} &mdash; {offer.title}
          </Item.Header>
          <Item.Description>
            {`${offer.printExperienceYears} | ${offer.printSalaries} |`}
            {` ${offer.stapleLocation && offer.stapleLocation.value}`}
            <Button
              className={styles.offerShortEditLink}
              target="_blank"
              as={Link}
              to={extractPageFromIri("offers", offer["@id"]) + "/edit"}
            >
              <Icon name="pencil" />
              {t`Constant.update`}
            </Button>
          </Item.Description>
          <Item.Extra>
            {offer.responsibilities && <ResponsibilitiesCompact responsibilities={offer.responsibilities} />}
          </Item.Extra>
        </Item.Content>
      </Item>
    );
  }
}

export default OfferShort;
