import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import {
  CreateTalentProfileResponsibility,
  DeleteTalentProfileResponsibility,
  EditTalentProfile,
  EditTalentProfileResponsibility,
  GetTalentProfile,
} from "../../../../actionTypes/talentProfile";
import { TalentProfile } from "../../../../interfaces/resources/talentProfile";

import { apiFailureAction } from "../../actions";
import {
  createTalentProfileResponsibilitySuccessAction,
  deleteTalentProfileResponsibilitySuccessAction,
  editTalentProfileResponsibilitySuccessAction,
  editTalentProfileSuccessAction,
  getTalentProfileSuccessAction,
} from "./actions";
import * as apiClient from "./api";

export function* editTalentProfile(action: AnyAction) {
  try {
    const talentProfile = action.req;
    const response: AxiosResponse<TalentProfile> = yield call(
      apiClient.editTalentProfile,
      talentProfile,
      action.validation,
      action.filterContext,
    );
    yield put(editTalentProfileSuccessAction(response.data, action.form));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* getTalentProfile(action: AnyAction) {
  try {
    const response: AxiosResponse<TalentProfile> = yield call(
      apiClient.getTalentProfile,
      action.talentProfile,
      action.filterContext,
    );

    yield put(getTalentProfileSuccessAction(response.data));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* createTalentProfileResponsibility(action: any): any {
  try {
    const response = yield call(
      apiClient.createTalentProfileResponsibility,
      action.responsibility,
      action.talentProfile,
    );
    const talentProfileResponsibility = response.data;
    yield put(createTalentProfileResponsibilitySuccessAction(talentProfileResponsibility));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* deleteTalentProfileResponsibility(action: any): any {
  try {
    const response = yield call(apiClient.deleteTalentProfileResponsibility, action.responsibility);
    const talentProfileResponsibility = response.config.url;
    yield put(deleteTalentProfileResponsibilitySuccessAction(talentProfileResponsibility));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* editTalentProfileResponsibility(action: any): any {
  try {
    const response = yield call(apiClient.editTalentProfileResponsibility, action.responsibility);
    const talentProfileResponsibility = response.data.talentProfileResponsibility;
    yield put(editTalentProfileResponsibilitySuccessAction(talentProfileResponsibility));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export default function* talentProfileSaga() {
  yield takeLatest(EditTalentProfile.REQUEST, editTalentProfile);
  yield takeEvery(GetTalentProfile.REQUEST, getTalentProfile);
  yield takeEvery(CreateTalentProfileResponsibility.REQUEST, createTalentProfileResponsibility);
  yield takeEvery(DeleteTalentProfileResponsibility.REQUEST, deleteTalentProfileResponsibility);
  yield takeEvery(EditTalentProfileResponsibility.REQUEST, editTalentProfileResponsibility);
}
