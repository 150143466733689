import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";

import { GetReferral, GetReferrals } from "../../../actionTypes/referrals";
import { Referral } from "../../../interfaces/resources/referral";
import { apiFailureAction } from "../actions";
import { getReferralsSuccessAction, getReferralSuccessAction } from "./actions";
import * as apiClient from "./api";

export function* getReferrals(action: AnyAction) {
  try {
    const response: AxiosResponse<{ "hydra:member": Referral[] }> = yield call(apiClient.getReferrals, action.talent);
    yield put(getReferralsSuccessAction(response.data["hydra:member"]));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* getReferral(action: AnyAction) {
  try {
    const response: AxiosResponse<Referral> = yield call(apiClient.getReferral, action.iri);
    yield put(getReferralSuccessAction(response.data));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export default function* referralSaga() {
  yield takeLatest(GetReferrals.REQUEST, getReferrals);
  yield takeLatest(GetReferral.REQUEST, getReferral);
}
