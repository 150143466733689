import { t } from "@lingui/macro";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid } from "semantic-ui-react";
import { GetHuntTalents } from "../../../actionTypes/hunt";

import { FilterContext } from "../../../interfaces/api/filter";
import { ApiRequest } from "../../../interfaces/api/request";
import { Application } from "../../../interfaces/resources/application";
import { huntFilterContext, huntType } from "../../../interfaces/resources/hunt";

import { State } from "../../../interfaces/state";
import { extractIdFromIri } from "../../../services/api/helpers";
import { getHuntTalentsAction } from "../../../services/api/hunt/actions";
import { getOfferAction } from "../../../services/api/offer/actions";
import { DataExportModal } from "../../DataExport/DataExportModal";
import { CopyClip } from "../../Utils/CopyClip";

import "./index.scss";

import { getTalentOfferUrl } from "../../Utils/helpers";
import { useApiSelector, usePrevious } from "../../Utils/hook";
import LoaderComp from "../../Utils/Loader";
import { ContactedTalents } from "./ContactedTalentsList";
import { TalentToContact } from "./TalentToContact";

interface Props {
  iri: string;
}

export const ContactTalent: React.FC<Props> = ({ iri }: Props) => {
  const dispatch = useDispatch();
  const { apiPendingRequests } = useApiSelector();
  const [dataExportModal, setDataExportModal] = React.useState(false);
  const currentUser = useSelector((state: State) => state.currentUser);
  const offer = useSelector((state: State) => state.offer);
  const talentsOnContact = useSelector((state: State) => state.talentsOnContact);
  const talentsToContact = useSelector((state: State) => state.talentsToContact);
  const huntState = useSelector((state: State) => state.huntState);
  const prevOffer = usePrevious(offer);

  const getHuntTalents = (filterContext: FilterContext) => {
    dispatch(getHuntTalentsAction(filterContext));
  };
  const getOffer = (id: string) => dispatch(getOfferAction(id));

  React.useEffect(() => {
    if (
      currentUser
      && huntState === huntType.offer
      && prevOffer !== offer
      && !apiPendingRequests.some((e: ApiRequest) => GetHuntTalents.REQUEST === e.type)
    ) {
      offer === null ? getOffer(iri) : getHuntTalents(huntFilterContext.getHuntTalents.offer(currentUser, offer));
    }
  }, [huntState, getOffer, iri, offer, getHuntTalents, currentUser]);

  let headerContent: any;
  const isLoading = false;
  if (huntState === huntType.offer && offer) {
    headerContent = (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <div>{`${offer.company.name} - ${offer.title}`}</div>
          <CopyClip
            default={getTalentOfferUrl(offer)}
            ctaText={t`OfferInfo.talentUrl.cta.text`}
            title={t`OfferInfo.talentUrl.cta.title`}
          />
        </div>
      </>
    );
  }

  const exportable = [...talentsOnContact, ...talentsToContact].reduce(
    (acc, talent) => [
      ...acc,
      ...(Array.isArray(talent.applications) ? talent.applications : []).map(
        ({ offer: appOffer, talent: appTalent, goodMatch }: Application) => {
          const headline =
            appTalent.talentProfile?.currentJob && appTalent.talentProfile?.currentJob
              ? `${appTalent.talentProfile.currentJob} @ ${appTalent.talentProfile.currentCompany}`
              : appTalent.linkedInProfile?.headline
              ? appTalent.linkedInProfile.headline
              : "";

          return {
            [t`dataExport.contactTalents.firstName`]: appTalent.firstname,
            [t`dataExport.contactTalents.lastName`]: appTalent.lastname,
            [t`dataExport.contactTalents.email`]: appTalent.email,
            [t`dataExport.contactTalents.phone`]: appTalent.phone,
            [t`dataExport.contactTalents.linkedin.url`]: appTalent.linkedInProfile.url,
            [t`dataExport.contactTalents.linkedin.headline`]: headline.replace(/"/g, ""),
            [t`dataExport.contactTalents.admin.link`]: `https://admin.elinoi.com/talents/${extractIdFromIri(
              appTalent["@id"],
            )}`,
            [t`dataExport.contactTalents.talentManagerFirstName`]: appTalent.talentManager
              ? appTalent.talentManager.firstname
              : "",
            [t`dataExport.contactTalents.talentManagerEmail`]: appTalent.talentManager
              ? appTalent.talentManager.email
              : "",
            [t`dataExport.contactTalents.ownerFirstName`]: appOffer.owners.length
              ? appOffer.owners.map((owner) => owner.firstname)
              : "",
            [t`dataExport.contactTalents.ownerEmail`]: appOffer.owners.length
              ? appOffer.owners.map((owner) => owner.email)
              : "",
            [t`dataExport.contactTalents.company`]: appOffer.company.name,
            [t`dataExport.contactTalents.offer`]: appOffer.title,
            [t`dataExport.contactTalents.goodMatch`]: goodMatch === null ? "" : goodMatch,
          };
        },
      ),
    ],
    [] as any[],
  );

  return (
    <div className="ContactTalent">
      {!isLoading ? (
        <>
          <DataExportModal
            initialValues={exportable}
            refreshOnInitialValuesUpdate
            open={dataExportModal}
            onClose={() => setDataExportModal(false)}
          />
          <div className="title">
            {headerContent}
            <Button basic icon="upload" onClick={() => setDataExportModal(true)} />
          </div>
          <Grid>
            <Grid.Column width={12}>
              <TalentToContact />
            </Grid.Column>
            <Grid.Column width={4}>
              <ContactedTalents />
            </Grid.Column>
          </Grid>
        </>
      ) : (
        <LoaderComp />
      )}
    </div>
  );
};
