import { LOCATION_CHANGE } from "redux-first-history";
import { CreateOfferInternalView, EditOfferInternalView, GetOfferInternalView } from "../../../../actionTypes/offers";

import { OfferInternalView } from "../../../../interfaces/resources/offer";
import { routes } from "../../../../routes";

export function offerInternalView(state: OfferInternalView | null = null, action: any): OfferInternalView | null {
  switch (action.type) {
    case CreateOfferInternalView.SUCCESS:
    case EditOfferInternalView.SUCCESS:
    case GetOfferInternalView.SUCCESS:
      return action.offerInternalView;
    case LOCATION_CHANGE:
      return [routes.offers.new].includes(action.payload.location.pathname) ? null : state;
    default:
      return state;
  }
}
