import { connect } from "react-redux";
import { Dispatch } from "redux";

import CoreUserInvitationModal from "../../../components/CoreUsers/CoreUserInvitationModal";
import { createCoreUserAction } from "../../../services/api/coreUser/actions";

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    createCoreUser: (coreUser: any) => dispatch(createCoreUserAction(coreUser)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CoreUserInvitationModal);
