import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";

import { GetSalaryExpectations } from "../../../actionTypes/salaryExpectations";

import { SalaryExpectations } from "../../../interfaces/resources/salaryExpectations";
import { apiFailureAction } from "../actions";
import { extractNextPageNumberFromIri } from "../helpers";

import { getSalaryExpectationsSuccessAction } from "./actions";
import * as apiClient from "./api";

export function* getSalaryExpectations(action: AnyAction) {
  try {
    const response: AxiosResponse<{
      "hydra:member": SalaryExpectations[];
      "hydra:totalItems": number;
      "hydra:view"?: {
        "hydra:next"?: string;
      };
    }> = yield call(apiClient.getSalaryExpectations, action.page);
    yield put(getSalaryExpectationsSuccessAction(response.data["hydra:member"], action));
    if (response.data["hydra:view"] && response.data["hydra:view"]["hydra:next"]) {
      yield put({
        page: extractNextPageNumberFromIri(response.data["hydra:view"]["hydra:next"]),
        type: GetSalaryExpectations.REQUEST,
      });
    }
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export default function* getSalaryExpectationsSaga() {
  yield takeLatest(GetSalaryExpectations.REQUEST, getSalaryExpectations);
}
