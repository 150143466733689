import cx from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import {
  Application,
  applicationStatuses,
  applicationStatusesInProcess,
  applicationStatusesRefused,
} from "../../../interfaces/resources/application";
import { offerStatuses } from "../../../interfaces/resources/offer";

import { State } from "../../../interfaces/state";
import { CompanyOfferData } from "./CompanyOfferData";
import { CompanyOfferItem } from "./CompanyOfferItem";

import styles from "./index.module.scss";

interface ApplicationData {
  accepted: Application[];
  process: Application[];
  refused: Application[];
}

export const Offers = () => {
  const company = useSelector((state: State) => state.company);
  const offersState = useSelector((state: State) => state.offers);
  const sortOrder = [offerStatuses.activated, offerStatuses.standbyElinoi, offerStatuses.desactivated];
  const offersSorted = company?.offers.sort((a, b) => sortOrder.indexOf(a.status) - sortOrder.indexOf(b.status));
  const [applicationData, setApplicationData] = React.useState<ApplicationData>({
    accepted: [],
    process: [],
    refused: [],
  });

  React.useEffect(() => {
    const process: Application[] = [];
    const refused: Application[] = [];
    const accepted: Application[] = [];

    const companyOffers = offersState.filter((o) => o.company["@id"] === company?.["@id"]);
    companyOffers.forEach((co) => {
      co.applications?.forEach((coAp) => {
        if (coAp.status === applicationStatuses.accepted) {
          accepted.push(coAp);
        } else if (applicationStatusesInProcess.includes(coAp.status)) {
          process.push(coAp);
        } else if (applicationStatusesRefused.includes(coAp.status)) {
          refused.push(coAp);
        }
      });
    });
    setApplicationData({ process, refused, accepted });
  }, [company, offersState]);

  return (
    <div className={styles.offersWrapper}>
      <div className={cx(styles.part, styles.offersPart)}>
        <div className={styles.partTitle}>Offres</div>
        <div className={styles.partContent}>
          {offersSorted?.map((offer) => (
            <div key={`company-offer-item-${offer["@id"]}`} className={styles.offerItemContainer}>
              <CompanyOfferItem offerIri={offer["@id"]} />
            </div>
          ))}
        </div>
      </div>
      <div className={cx(styles.part, styles.numbersPart)}>
        <div className={styles.partTitle}>Chiffres</div>
        <div className={styles.partContent}>
          <div className={styles.numberContainer}>
            <CompanyOfferData applications={applicationData.accepted} size="large" status="accepted" />
          </div>
          <div className={styles.numberContainer}>
            <CompanyOfferData applications={applicationData.process} size="large" status="process" />
          </div>
          <div className={styles.numberContainer}>
            <CompanyOfferData applications={applicationData.refused} size="large" status="refusedProcess" />
          </div>
          <div className={styles.numberContainer}>
            <CompanyOfferData applications={applicationData.refused} size="large" status="refusedPreProcess" />
          </div>
        </div>
      </div>
    </div>
  );
};
