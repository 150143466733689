import { t } from "@lingui/macro";
import cx from "classnames";
import { Field, Formik, Form } from "formik";

import React, { FC } from "react";
import { useDispatch } from "react-redux";
import Yup from "../../../components/App/Yup";
import RichTextEditor from "../../../components/Form/Formik/RichTextEditor";
import { Company } from "../../../interfaces/resources/company";

import { TalentProfile } from "../../../interfaces/resources/talentProfile";
import { editCompanyAction } from "../../../services/api/company/actions";
import globalStyles from "../../../styles/global.module.scss";
import styles from "./index.module.scss";

interface Props {
  company: Company;
  onCloseModal: () => void;
}

const validationSchema = Yup.object().shape({
  note: Yup.string().nullable(),
});

export const CompanyNoteModal: FC<Props> = (props: Props) => {
  const { company, onCloseModal } = props;
  const dispatch = useDispatch();

  const onSubmit = (values: Partial<TalentProfile>): void => {
    dispatch(
      editCompanyAction({
        "@id": company["@id"],
        ...values,
      }),
    );
    closeModal();
  };

  const closeModal = () => {
    if (typeof onCloseModal === "function") {
      onCloseModal();
    }
  };

  return (
    <div className={styles.modal}>
      <div className={styles.container}>
        <Formik
          onSubmit={onSubmit}
          initialValues={{ note: !!company.note ? company.note : undefined }}
          validationSchema={validationSchema}
        >
          {({ handleSubmit }): JSX.Element => (
            <Form onSubmit={handleSubmit}>
              <div className={styles.titleContainer}>
                <h3 className={styles.title}>{t`NoteModal.company.title`}</h3>
              </div>
              <div className={styles.noteFieldContainer}>
                <Field
                  component={RichTextEditor}
                  name="note"
                  uniqId={`talentProfile-${company["@id"]}-notes`}
                  replaceIfEmpty={false}
                />
              </div>
              <div className={styles.ctaListContainer}>
                <div>
                  <button
                    type="button"
                    className={cx(globalStyles.cta, globalStyles.darkCta, globalStyles.inverted)}
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    {t`Constant.cancel`}
                  </button>
                </div>
                <div>
                  <button type="submit" className={cx(globalStyles.cta, globalStyles.primaryCta)}>
                    {t`NoteModal.cta.validate`}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
