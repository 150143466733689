import { Application } from "../../../../../interfaces/resources/application";
import { CoreUser } from "../../../../../interfaces/resources/coreUser";
import { Education } from "../../../../../interfaces/resources/education";
import { LinkedInProfile } from "../../../../../interfaces/resources/linkedInProfile";
import { Qualification } from "../../../../../interfaces/resources/qualification";
import { TalentCard } from "../../../../../interfaces/resources/talentCard";

export const initializeValues = (
  application: Application,
  qualification: Qualification | null | undefined,
  linkedInProfile: LinkedInProfile,
  currentUser: CoreUser | null,
  importedTalentCard?: TalentCard | null,
) => {
  return {
    application: application["@id"],
    availability: qualification && qualification.priorNotice ? qualification.priorNotice : "",
    bridges: importedTalentCard ? importedTalentCard.bridges : "",
    description: importedTalentCard ? importedTalentCard.description : "",
    coreSender: currentUser ? currentUser["@id"] : undefined,
    positions: importedTalentCard
      ? importedTalentCard.positions
      : qualification && qualification.storytelling
      ? qualification.storytelling
      : "",
    timOpinion: importedTalentCard
      ? importedTalentCard.timOpinion
      : qualification && qualification.timOpinion
      ? qualification.timOpinion
      : "",
    availabilityComplements:
      qualification && qualification.availabilityComplements ? qualification.availabilityComplements : "",
    educations:
      linkedInProfile && linkedInProfile.educations
        ? linkedInProfile.educations.map((item: Education) => `${item.schoolName} - (${item.endDate})`).join("\n")
        : "default",
    salary: {
      fixedSalary: importedTalentCard ? importedTalentCard.salary.fixedSalary : 0,
      fullPackage: importedTalentCard ? importedTalentCard.salary.fullPackage : 0,
    },
    salaryComplements: qualification && qualification.salaryComplements ? qualification.salaryComplements : "",
  };
};
