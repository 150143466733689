import cx from "classnames";
import { FieldProps, useField } from "formik";
import * as React from "react";
import SelectComponent, { ValueType, StylesConfig } from "react-select";
import CreatableSelect from "react-select/creatable";
import { Props as RSProps } from "react-select/src/Select";
import { Form } from "semantic-ui-react";
import { CSSObject } from "styled-components";

import ErrorMessage from "../ErrorMessage";

export const defaultStyles: StylesConfig<any, any> = {
  control: (base: CSSObject) => ({
    ...base,
    "&:hover": {
      boxShadow: "inset 0 0 0 1px #888",
    },
    boxShadow: "inset 0 0 0 1px #f5f7fa",
    backgroundColor: "#f5f7fa",
    borderWidth: 0,
    borderRadius: 0,
    transition: "box-shadow 0.1s ease-in-out",
  }),
  menu: (base: CSSObject) => ({ ...base, zIndex: 3 }),
  valueContainer: (base: CSSObject) => ({ ...base, padding: "4px" }),
  multiValue: (base: CSSObject, state: any) => ({
    ...base,
    color: "#fdfdfd",
    boxShadow: `inset 0 0 0 1px ${state.data.isFixed ? "#acacac" : "#699bf0"}`,
    backgroundColor: state.data.isFixed ? "gray" : "#699bf0",
    borderRadius: "4px",
    borderWidth: 0,
  }),
  multiValueLabel: (base: CSSObject, state: any) => ({
    ...base,
    color: "#fdfdfd",
    fontSize: "13px",
    letterSpacing: "0.02em",
    paddingTop: "2px",
    paddingBottom: "2px",
    paddingLeft: "5px",
    paddingRight: state.data.isFixed ? "5px" : "0px",
  }),
  multiValueRemove: (base: CSSObject, state: any) => ({
    ...base,
    "&:hover": {
      backgroundColor: "#699bf0",
      color: "white",
      cursor: "pointer",
    },
    paddingLeft: "3px",
    paddingRight: "3px",
    display: state.data.isFixed ? "none" : "flex",
  }),
  singleValue: (base: CSSObject) => ({
    ...base,
    color: "#707090",
  }),
  indicatorsContainer: (base: CSSObject) => ({
    ...base,
    padding: 0,
  }),
};

interface Props extends FieldProps, RSProps<any, any> {
  extraText?: string;
  icon: string;
  label?: string;
  options: any[];
  parse?: (option: any) => any;
  isMulti?: boolean;
}

export const Creatable = ({
  className,
  error,
  field: { name, value },
  form: { setFieldValue, setFieldTouched },
  label,
  parse,
  styles,
  isMulti,
  menuPlacement = "auto",
  ...rest
}: Props): JSX.Element => {
  const [, meta] = useField(name);
  return (
    <Form.Field name={name} error={(meta.touched && !!meta.error) || !!error} className={className}>
      {label ? <label className="formLabel">{label}</label> : null}
      <CreatableSelect
        {...rest}
        isMulti={isMulti}
        value={value as ValueType<any, any>}
        className="select"
        menuPlacement={menuPlacement}
        onChange={(option: any) => {
          if (!option && isMulti) {
            setFieldValue(name, []);
          } else if (value !== option) {
            if (parse) {
              option = parse(option);
            }
            setFieldValue(name, option);
          }
        }}
        onBlur={() => setFieldTouched(name, true)}
        styles={styles ? styles : defaultStyles}
      />
      <ErrorMessage name={name} />
    </Form.Field>
  );
};

export const Select = ({
  className,
  error,
  field: { name, value },
  form: { setFieldValue, setFieldTouched },
  label,
  parse,
  styles,
  isMulti,
  menuPlacement = "auto",
  ...rest
}: Props): JSX.Element => {
  const [, meta] = useField(name);
  return (
    <div className={cx(className, { error: (meta.touched && !!meta.error) || !!error })}>
      {label ? <label className="formLabel">{label}</label> : null}
      <SelectComponent
        {...rest}
        isMulti={isMulti}
        value={(parse && value ? parse(value) : value) as ValueType<any, any>}
        className="select"
        menuPlacement={menuPlacement}
        onChange={(option: any): void => {
          if (isMulti && !option) {
            setFieldValue(name, []);
          }
          if (value !== option) {
            if (parse) {
              option = parse(option);
            }
            setFieldValue(name, option);
          }
        }}
        onBlur={(): void => setFieldTouched(name, true)}
        styles={styles ? styles : defaultStyles}
      />
      <ErrorMessage name={name} />
    </div>
  );
};
