import { t } from "@lingui/macro";
import cx from "classnames";
import * as React from "react";
import {
  Application,
  applicationStatusesInProcess,
  applicationStatusesPreProcess,
} from "../../../../interfaces/resources/application";
import styles from "./index.module.scss";

interface Props {
  applications: Application[];
  status: "accepted" | "refusedPreProcess" | "refusedProcess" | "process";
  size?: "large" | "normal";
}
export const CompanyOfferData: React.FC<Props> = (props: Props) => {
  const { applications, status, size = "normal" } = props;
  const number =
    status === "refusedPreProcess"
      ? applications.filter((ap) => ap.previousStatus && applicationStatusesPreProcess.includes(ap.previousStatus))
          .length
      : status === "refusedProcess"
      ? applications.filter((ap) => ap.previousStatus && applicationStatusesInProcess.includes(ap.previousStatus))
          .length
      : applications.length;
  const statusTrans: { [key: string]: { [key: string]: string } } = {
    singular: {
      accepted: t`CompanyOffer.data.accepted`,
      refusedPreProcess: t`CompanyOffer.data.refusedPreProcess`,
      refusedProcess: t`CompanyOffer.data.refusedProcess`,
      process: t`CompanyOffer.data.process`,
    },
    plural: {
      accepted: t`CompanyOffer.data.accepted.plural`,
      refusedPreProcess: t`CompanyOffer.data.refusedPreProcess.plural`,
      refusedProcess: t`CompanyOffer.data.refusedProcess.plural`,
      process: t`CompanyOffer.data.process.plural`,
    },
  };
  return (
    <div className={cx(styles.container, { [styles.zero]: number < 1, [styles.large]: size === "large" })}>
      <div className={styles.number}>{number}</div>
      <div className={styles.type}>
        {number > 1 ? t({ id: statusTrans.plural[status] }) : t({ id: statusTrans.singular[status] })}
      </div>
    </div>
  );
};
