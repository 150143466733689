import * as React from "react";
import { useParams } from "react-router-dom";

import EditCompanyContainer from "../../containers/Companies/EditCompanyContainer";
import { generateIriFromId } from "../../services/api/helpers";

export const CompanyEditScene: React.FC = (): JSX.Element => {
  const { id } = useParams();
  return (
    <div>
      <EditCompanyContainer iri={generateIriFromId("companies", Number(id))} />
    </div>
  );
};
