import { AnyAction } from "redux";

import { EditApplication } from "../../../../actionTypes/applications";
import { GetOffer, GetOfferApplication, GetOfferApplications } from "../../../../actionTypes/offers";
import { Application } from "../../../../interfaces/resources/application";

export function offerApplications(state: Application[] = [], action: AnyAction) {
  switch (action.type) {
    case GetOfferApplications.SUCCESS:
      return !!action.concat ? [...state, ...action.applications] : action.applications;
    case GetOffer.REQUEST:
      return [];
    case EditApplication.SUCCESS:
      state.splice(
        state.findIndex((e: Application) => action.application["@id"] === e["@id"]),
        1,
        action.application,
      );
      return [...state];
    case GetOfferApplication.SUCCESS:
      const index = state.findIndex((a: Application) => a["@id"] === action.application["@id"]);
      const apps = [...state];
      if (index >= 0) {
        apps[index] = action.application;
      } else if (action.application.hasOwnProperty("@id")) {
        apps.push(action.application);
      }
      return apps;
    default:
      return state;
  }
}
