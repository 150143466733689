import cx from "classnames";
import * as React from "react";
import { WrappedFieldInputProps, WrappedFieldMetaProps } from "redux-form";
import { Button, Form, FormRadioProps } from "semantic-ui-react";

import "./index.scss";

interface RadioFieldProps extends FormRadioProps {
  className?: string;
  input: WrappedFieldInputProps;
  meta: WrappedFieldMetaProps;
  getOptionValue: (option: any) => string;
  label?: string;
  item: any;
}

interface ToggleButtonFieldProps extends FormRadioProps {
  input: WrappedFieldInputProps;
  meta: WrappedFieldMetaProps;
  checkedLabel: string;
  uncheckedLabel: string;
}

const Toggle = ({ className, field, form: { setFieldValue }, label, ...rest }: RadioFieldProps) => {
  return (
    <div className={cx({ [className || ""]: !!className })}>
      {label ? <label className="formLabel">{label}</label> : null}
      <Form.Radio
        {...rest}
        className={className}
        name={field.name}
        checked={!!field.value}
        toggle
        onChange={(_: any, data: any) => {
          setFieldValue(field.name, data.checked);
        }}
      />
    </div>
  );
};

export const ToggleButton = ({
  checkedLabel,
  field,
  form: { setFieldValue },
  uncheckedLabel,
  ...rest
}: ToggleButtonFieldProps) => (
  <Button.Group className="toggle" {...rest}>
    <Button
      onClick={() => setFieldValue(field.name, false)}
      className={`${field.value === false ? "active" : ""}`}
      content={uncheckedLabel}
      type="button"
    />
    <Button
      onClick={() => setFieldValue(field.name, true)}
      className={`${field.value === true ? "active" : ""}`}
      content={checkedLabel}
      type="button"
    />
  </Button.Group>
);

export default Toggle;
