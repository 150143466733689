import * as React from "react";
import { useParams } from "react-router-dom";

import Shortlist from "../../components/Offers/Shortlist";
import { generateIriFromId } from "../../services/api/helpers";

export const ShortListScene: React.FC = (): JSX.Element => {
  const { id } = useParams();
  return (
    <div>
      <Shortlist iri={generateIriFromId("offers", Number(id))} />
    </div>
  );
};
