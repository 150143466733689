import { t } from "@lingui/macro";
import cx from "classnames";
import { format, parseISO } from "date-fns";
import * as React from "react";
import { Link } from "react-router-dom";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { Button, Form, Message, Modal } from "semantic-ui-react";

import { GetCompany } from "../../../actionTypes/companies";
import { GetCoreUsers, GetCurrentUser } from "../../../actionTypes/coreUsers";
import { GetResponsibilities } from "../../../actionTypes/responsibilities";
import { GetSectors } from "../../../actionTypes/sectors";

import { ApiError } from "../../../interfaces/api/error";
import { ApiRequest } from "../../../interfaces/api/request";
import { ApiSuccess } from "../../../interfaces/api/success";
import { Violation } from "../../../interfaces/api/violation";
import { Company, companyStatuses } from "../../../interfaces/resources/company";
import { Contact } from "../../../interfaces/resources/contact";
import { CoreUser, coreUserRoles } from "../../../interfaces/resources/coreUser";
import { Responsibility } from "../../../interfaces/resources/responsibility";
import { formatSectors, Sector } from "../../../interfaces/resources/sector";
import { routes } from "../../../routes";
import globalStyles from "../../../styles/global.module.scss";
import transCompany from "../../../translations/constants/company";

import { ContactModal } from "../../Contacts/ContactModal";
import Avatar from "../../Form/Avatar";
import DatePicker from "../../Form/DatePicker";
import Dropzone from "../../Form/Dropzone";
import Radio from "../../Form/Radio";
import RichTextEditor from "../../Form/RichTextEditor";
import Select from "../../Form/Select";
import { useGetResourceHook } from "../../Utils/hook";

import "./index.scss";
import styles from "./index.module.scss";

interface Props {
  apiErrors: ApiError[];
  apiPendingRequests: ApiRequest[];
  apiSuccess: ApiSuccess[];
  changeFormValue: (field: string, value: any) => void;
  company?: Company;
  contact: Contact;
  coreUsers: CoreUser[];
  currentUser: CoreUser;
  formValues: any;
  getCompany?: () => void;
  getCoreUsers: () => void;
  getResponsibilities: () => void;
  getSectors: () => void;
  responsibilities: Responsibility[];
  sectors: Sector[];
  state: "creation" | "edition";
  submitCompany: (req: Partial<Company>, redirectOnSuccess?: string | boolean) => void;
}

export const COMPANY_FORM = "COMPANY_FORM";

interface Status {
  transId: string;
  value: string;
}

const items: Status[] = [
  {
    transId: transCompany.status.incoming_lead,
    value: companyStatuses.incomingLead,
  },
  {
    transId: transCompany.status.introduced_to,
    value: companyStatuses.introducedTo,
  },
  {
    transId: transCompany.status.hunted,
    value: companyStatuses.hunted,
  },
  {
    transId: transCompany.status.called,
    value: companyStatuses.called,
  },
  {
    transId: transCompany.status.physical_meeting,
    value: companyStatuses.physicalMeeting,
  },
  {
    transId: transCompany.status.contract_sent,
    value: companyStatuses.contractSent,
  },
  {
    transId: transCompany.status.closed,
    value: companyStatuses.closed,
  },
  {
    transId: transCompany.status.refused,
    value: companyStatuses.refused,
  },
];

const CompanyForm: React.FunctionComponent<InjectedFormProps<Company, Props> & Props> = ({
  apiErrors,
  apiPendingRequests,
  apiSuccess,
  change,
  handleSubmit,
  changeFormValue,
  company,
  getCompany,
  contact,
  coreUsers,
  getCoreUsers,
  currentUser,
  formValues,
  responsibilities,
  getResponsibilities,
  sectors,
  getSectors,
  state,
  submitCompany,
}: InjectedFormProps<Company, Props> & Props) => {
  const [createOffer, setCreatOffer] = React.useState<boolean>(false);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean | string>(false);
  const [errors, setErrors] = React.useState<Violation[]>([]);
  const [newContacts, setNewContacts] = React.useState<Contact[]>([]);
  const [success, setSuccess] = React.useState<boolean>(false);
  let uniqId = state === "creation" ? `${COMPANY_FORM}-new` : null;

  if (company) {
    uniqId = `${COMPANY_FORM}-${company["@id"]}`;
  }

  const ownHandleSubmit = (values: any): void => {
    setCreatOffer(false);
    values.companyImages.length > 0 ? submitCompany(values, routes.companies.show) : setShowModal(true);
  };

  const handleSubmitAndAddOffer = (values: any): void => {
    setCreatOffer(true);
    values.companyImages.length > 0 ? submitCompany(values, routes.offers.new) : setShowModal(true);
  };

  const ownHandleSubmitWithoutImage = (values: any): void => {
    setShowModal(false);
    return submitCompany(values, routes.companies.show);
  };

  const handleSubmitAndAddOfferWithoutImage = (values: any): void => {
    setShowModal(false);
    return submitCompany(values, routes.offers.new);
  };

  React.useEffect(() => {
    if (apiSuccess.some((e: ApiSuccess) => COMPANY_FORM === e.form)) {
      setError(false);
      setErrors([]);
      setSuccess(false);
    }

    if (apiErrors.some((e: ApiError) => COMPANY_FORM === e.form)) {
      const apiError = apiErrors.find((e: ApiError) => COMPANY_FORM === e.form);
      if (apiError) {
        setError(apiError.payload.message);
        setErrors(apiError.payload.violations || []);
        setSuccess(false);
      }
    }
  }, [apiSuccess, apiPendingRequests, apiErrors, change]);

  React.useEffect(() => {
    if (
      "edition" === state
      && getCompany
      && apiErrors
      && apiPendingRequests
      && apiSuccess
      && !company
      && !apiErrors.some((err: ApiError) => err.type === GetCompany.FAILURE)
      && !apiPendingRequests.some((req: ApiRequest) => req.type === GetCompany.REQUEST)
      && !apiSuccess.some((suc: ApiSuccess) => suc.type === GetCompany.SUCCESS)
    ) {
      getCompany();
    }
  }, [apiErrors, apiPendingRequests, apiSuccess, getCompany, company, state]);

  React.useEffect(() => {
    if (
      currentUser
      && (currentUser.roles.indexOf(coreUserRoles.am) !== -1 || currentUser.roles.indexOf(coreUserRoles.nb) !== -1)
      && formValues
      && !formValues.owner
    ) {
      change("owner", currentUser);
    }
  }, [change, currentUser, formValues]);

  React.useEffect(() => {
    if (contact && !newContacts.some((ct: Contact) => ct["@id"] === contact["@id"])) {
      const contacts: Contact[] = [...newContacts, contact];
      setNewContacts(contacts);

      if (formValues) {
        change("contacts", [...(formValues.contacts || []), ...contacts]);
      }
    }
  }, [change, contact, formValues, newContacts, setNewContacts]);

  useGetResourceHook(apiErrors, apiPendingRequests, apiSuccess, GetSectors, sectors, getSectors, []);
  useGetResourceHook(
    apiErrors,
    apiPendingRequests,
    apiSuccess,
    GetResponsibilities,
    responsibilities,
    getResponsibilities,
    [],
  );
  useGetResourceHook(apiErrors, apiPendingRequests, apiSuccess, GetCoreUsers, coreUsers, getCoreUsers, []);

  let contactOptions: Contact[] = [...newContacts];

  if (company) {
    contactOptions = [...contactOptions, ...company.contacts];
  }

  contactOptions = contactOptions.map((elem: Contact) => {
    return { ...elem, key: elem["@id"] };
  });
  const name = company && company.name;

  return (
    <div className="companyFormContainer">
      <div className={globalStyles.mainTitleContainer}>
        <h1 className={globalStyles.mainTitle}>
          {"creation" === state ? t`CompanyForm.title.create` : t({ id: "CompanyForm.title.edit", values: { name } })}
        </h1>
      </div>
      <Form
        loading={
          apiPendingRequests.length > 0
          && (apiPendingRequests.some((e: ApiRequest) => COMPANY_FORM === e.form)
            || ("edition" === state && apiPendingRequests.some((e: ApiRequest) => GetCompany.REQUEST === e.type)))
        }
        success={success}
        error={!!error || errors.length > 0}
      >
        <Message
          success
          header={t`CompanyForm.success.header`}
          content={t`CompanyForm.success.content`}
          icon="check circle"
        />
        <Message
          error
          header={t`CompanyForm.error.header`}
          content={
            <div>
              {t`CompanyForm.error.content`}: {error}
            </div>
          }
          icon="times circle"
        />
        <div className="compagnyFormImageNameStatusContainer">
          <div className="compagnyImageContainer">
            <Field name="imageFile" component={Avatar} default={company ? company.image.url : ""} />
          </div>
          <div className="compagnyNameStatusContainer">
            <Field
              name="name"
              component={Form.Input}
              label={t`company.name`}
              placeholder={t`company.name`}
              error={errors.some((item: Violation) => "name" === item.propertyPath)}
            />
            <Field
              name="status"
              component={Select}
              isSearchable={true}
              isMulti={false}
              label={t`company.status`}
              placeholder={t`company.status.placeholder`}
              error={errors.some((item: Violation) => "status" === item.propertyPath)}
              getOptionLabel={(option: { label: string; value: string }): string =>
                t({ id: transCompany.status[option.value] })
              }
              options={items}
              format={(value: string): { label: string; value: string } => ({
                label: t({ id: transCompany.status[value] }),
                value,
              })}
              parse={(val: { label: string; value: string }): string => val.value}
            />
          </div>
        </div>
        <Field
          name="website"
          component={Form.Input}
          type="url"
          label={t`company.website`}
          placeholder={t`company.website`}
          error={errors.some((item: Violation) => "website" === item.propertyPath)}
          onBlur={(e: any): void => {
            e.preventDefault();
            if ("https://" === e.currentTarget.value || "http://" === e.currentTarget.value) {
              changeFormValue("website", "");
            }
          }}
          onFocus={(e: any): void => {
            if ("" === e.currentTarget.value) {
              changeFormValue("website", "https://");
            }
          }}
        />
        <Field
          name="sectors"
          component={Select}
          label={t`company.sector`}
          placeholder={t`company.sector.placeholder`}
          error={errors.some((item: Violation) => "sector" === item.propertyPath)}
          getOptionLabel={(option: Sector): string | null => option.value}
          getOptionValue={(option: Sector): string => option["@id"]}
          isLoading={apiPendingRequests.some((e: ApiRequest) => GetSectors.REQUEST === e.type)}
          options={formatSectors(sectors)}
          isMulti={true}
        />
        <div className="formGroup formTextAreaGroup">
          <Field
            name="foundersBackground"
            component={RichTextEditor}
            uniqId={uniqId ? `${uniqId}-foundersBackground` : null}
            replaceIfEmpty={false}
            label={t`company.foundersBackground`}
            placeholder={t`company.foundersBackground.placeholder`}
            error={errors.some((item: Violation) => "foundersBackground" === item.propertyPath)}
          />
          <Field
            name="description"
            component={RichTextEditor}
            uniqId={uniqId ? `${uniqId}-description` : null}
            replaceIfEmpty={false}
            label={t`company.description`}
            placeholder={t`company.description.placeholder`}
            error={errors.some((item: Violation) => "description" === item.propertyPath)}
          />
          <Field
            name="specificities"
            component={RichTextEditor}
            uniqId={uniqId ? `${uniqId}-specificities` : null}
            replaceIfEmpty={false}
            label={t`company.specificities`}
            placeholder={t`company.specificities.placeholder`}
            error={errors.some((item: Violation) => "specificities" === item.propertyPath)}
          />
        </div>
        <div className="formPart">
          <h2 className="formPartTitle">{t`company.keyFigures.head`}</h2>
          <div className="formGroup formInlineGroup keyFiguresInlineGroup double">
            <Field
              name="creationDate"
              component={DatePicker}
              label={t`company.creationDate`}
              error={errors.some((item: Violation) => "creationDate" === item.propertyPath)}
              dateFormat={"dd/MM/yyyy"}
              format={(value: string): Date | undefined => (!!value ? parseISO(value) : undefined)}
              parse={(value: Date): string | null => (value ? format(value, "yyyy-MM-dd") : null)}
            />
            <Field
              name="fundsRaisedAmount"
              component={Form.Input}
              type="string"
              step="0.1"
              label={t`company.fundsRaisedAmount`}
              placeholder={t`company.fundsRaisedAmount`}
              error={errors.some((item: Violation) => "fundsRaisedAmount" === item.propertyPath)}
              onBlur={(e: any): void => {
                e.preventDefault();
                changeFormValue("fundsRaisedAmount", Number(parseFloat(e.currentTarget.value.replace(/,/, "."))));
              }}
            />
          </div>
          <div className="formGroup formInlineGroup keyFiguresInlineGroup double">
            <Field
              name="employeesNumber"
              component={Form.Input}
              type="number"
              label={t`company.employeesNumber`}
              placeholder={t`company.employeesNumber`}
              error={errors.some((item: Violation) => "employeesNumber" === item.propertyPath)}
              parse={(value: string): number | "" => (value === "" || Number(value) < 0 ? "" : Number(value))}
            />
            <Field
              name="employeesAverageAge"
              component={Form.Input}
              type="number"
              label={t`company.employeesAverageAge`}
              placeholder={t`company.employeesAverageAge`}
              error={errors.some((item: Violation) => "employeesAverageAge" === item.propertyPath)}
              parse={(value: string): number | "" => (value === "" || Number(value) < 0 ? "" : Number(value))}
            />
          </div>
        </div>
        <div className="formPart">
          <h2 className="formPartTitle">{t`company.companyImages.head`}</h2>
          <Field
            name="companyImages"
            component={Dropzone}
            label={t`company.companyImages`}
            error={errors.some((item: Violation) => "companyImages" === item.propertyPath)}
            formatValue={(value: any) => ({
              imageFile: value,
            })}
            getUrlValue={(value: any) => value.imageFile || value.image.url}
          />
        </div>
        <div className="formPart">
          <h2 className="formPartTitle">{t`company.contacts.head`}</h2>
          <Field
            name="owner"
            component={Select}
            isMulti={false}
            isSearchable={true}
            label={t`company.owner`}
            placeholder={t`company.owner.placeholder`}
            error={errors.some((item: Violation) => "owner" === item.propertyPath)}
            getOptionLabel={(coreUser: CoreUser): string => `${coreUser.firstname} ${coreUser.lastname}`}
            getOptionValue={(coreUser: CoreUser): string => coreUser["@id"]}
            isLoading={apiPendingRequests.some((e: ApiRequest) =>
              [GetCurrentUser.REQUEST, GetCoreUsers.REQUEST].includes(e.type),
            )}
            options={coreUsers.filter(
              (item: CoreUser) =>
                (item.roles.includes(coreUserRoles.am) || item.roles.includes(coreUserRoles.nb)) && item.enabled,
            )}
          />
          <Field
            name="contacts"
            component={Select}
            isMulti={true}
            isSearchable={true}
            label={t`company.contacts`}
            placeholder={t`company.contacts.placeholder`}
            error={errors.some((item: Violation) => "contacts" === item.propertyPath)}
            getOptionLabel={(opt: Contact): string => `${opt.firstname} ${opt.lastname}`}
            getOptionValue={(opt: Contact): string => opt["@id"]}
            isLoading={apiPendingRequests.some((e: ApiRequest) => [GetCompany.REQUEST].includes(e.type))}
            options={contactOptions}
          />
          <ContactModal />
        </div>
        <div className="formPart">
          <h2 className="formPartTitle">{t`company.contract.head`}</h2>
          <div className="formGroup">
            <Field
              name="closingParty"
              component={Select}
              isMulti={false}
              isSearchable={true}
              label={t`company.closingParty`}
              placeholder={t`company.closingParty.placeholder`}
              error={errors.some((item: Violation) => "closingParty" === item.propertyPath)}
              getOptionLabel={(coreUser: CoreUser): string => `${coreUser.firstname} ${coreUser.lastname}`}
              getOptionValue={(coreUser: CoreUser): string => coreUser["@id"]}
              isLoading={apiPendingRequests.some((e: ApiRequest) =>
                [GetCurrentUser.REQUEST, GetCoreUsers.REQUEST].includes(e.type),
              )}
              options={coreUsers.filter(
                (item: CoreUser) =>
                  (item.roles.includes(coreUserRoles.am) || item.roles.includes(coreUserRoles.nb)) && item.enabled,
              )}
            />
          </div>
        </div>
        <div className="formPart">
          <h2 className="formPartTitle">{t`company.jobboard.title`}</h2>
          <div className="formGroup">
            <div className={styles.radioInlineFieldContainer}>
              {[true, false].map((opt: boolean) => (
                <Field
                  name="isJobBoard"
                  component={Radio}
                  key={`company-form-${opt}`}
                  error={errors.some((violation: Violation) => "isJobBoard" === violation.propertyPath)}
                  getOptionValue={(option: boolean): boolean => option}
                  label={
                    <button
                      type="button"
                      className={cx(styles.radioBtn, { [styles.active]: formValues && formValues.isJobBoard === opt })}
                    >
                      {opt.toString()}
                    </button>
                  }
                  item={opt}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="formBtnContainer">
          <div className="cancelBtncontainer">
            <Button negative as={Link} to={routes.companies.search} content={t`Constant.cancel`} icon="times" />
          </div>
          <div className="validateBtnsContainer">
            <Button secondary content={t`CompanyForm.fields.submit`} onClick={handleSubmit(ownHandleSubmit)} />
            {"creation" === state ? (
              <Button
                primary
                content={t`CompanyForm.fields.submitAddOffer`}
                icon="plus square outline"
                onClick={handleSubmit(handleSubmitAndAddOffer)}
              />
            ) : null}
          </div>
        </div>
        <Modal
          dimmer="blurring"
          open={showModal}
          className="modalWrapper"
          closeOnEscape={!showModal}
          onClose={(): void => setShowModal(false)}
        >
          <Modal.Header className="modalHeader">{t`company.picture.profile`}</Modal.Header>
          <Modal.Actions>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              {!createOffer ? (
                <Button
                  icon="arrow left"
                  content={t`CompanyFormModal.fields.submit`}
                  onClick={handleSubmit(ownHandleSubmitWithoutImage)}
                />
              ) : "creation" === state ? (
                <Button
                  primary
                  content={t`CompanyFormModal.fields.submitAddOffer`}
                  icon="plus square outline"
                  onClick={handleSubmit(handleSubmitAndAddOfferWithoutImage)}
                />
              ) : null}
              <Button onClick={(): void => setShowModal(false)} content={t`CompanyFormModal.fields.cancel`} />
            </div>
          </Modal.Actions>
        </Modal>
      </Form>
    </div>
  );
};

export default reduxForm<Company, Props>({
  form: "COMPANY_FORM",
})(CompanyForm);
