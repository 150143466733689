import { FilterContext } from "../../../interfaces/api/filter";
import { Company } from "../../../interfaces/resources/company";
import { Contact } from "../../../interfaces/resources/contact";
import { Responsibility } from "../../../interfaces/resources/responsibility";
import { Sector } from "../../../interfaces/resources/sector";
import client from "../client";
import { generateFilterQuery, getStringValue, getCollectionStringValue } from "../helpers";

function formatCompanyData(req: any) {
  return {
    ...req,
    closingParty: getStringValue(req.closingParty, "@id"),
    contacts: getCollectionStringValue<Contact>(req.contacts, "@id"),
    contract: getStringValue(req.contract, "@id"),
    fundraisingStage: getStringValue(req.fundraisingStage, "value"),
    owner: getStringValue(req.owner, "@id"),
    potentialJobs: getCollectionStringValue<Responsibility>(req.potentialJobs, "@id"),
    sectors: getCollectionStringValue<Sector>(req.sectors, "@id"),
    status: getStringValue(req.status, "value"),
    turnover: req.turnover ? String(req.turnover) : undefined,
  };
}
export function getCompanies(filterContext: FilterContext) {
  const query = encodeURI(generateFilterQuery(filterContext));
  return client.get(`/api/v2/companies${query}`);
}

export function getCompany(iri: string) {
  return client.get(iri);
}

export function deleteCompany(iri: string) {
  return client.delete(iri);
}

export function createCompany(req: Partial<Company>) {
  return client.post("/api/v2/companies", formatCompanyData(req));
}

export function editCompany(req: Partial<Company>, validation?: string) {
  if (req["@id"]) {
    return client.put(`${req["@id"]}${validation ? `/${validation}` : ""}`, formatCompanyData(req));
  }
}
