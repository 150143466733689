import { AnyAction } from "redux";
import { LOCATION_CHANGE } from "redux-first-history";
import { GetTalentProfile } from "../../../../actionTypes/talentProfile";
import {
  CreateTalentQualification,
  EditTalentQualification,
  GetChatGPT,
  GetTalentQualification,
  GetTalentQualifications,
  PostTranscript,
} from "../../../../actionTypes/talentQualification";
import { Qualification } from "../../../../interfaces/resources/qualification";

import { routes } from "../../../../routes";

export function talentQualification(state: Qualification | null = null, action: any) {
  switch (action.type) {
    case GetTalentQualification.SUCCESS:
    case EditTalentQualification.SUCCESS:
    case CreateTalentQualification.SUCCESS:
      return action.talentQualification;
    case GetTalentProfile.REQUEST:
      return null;
    default:
      return state;
  }
}

export function qualifications(state: { [iri: string]: Qualification } = {}, action: AnyAction) {
  switch (action.type) {
    case EditTalentQualification.SUCCESS:
    case GetTalentQualification.SUCCESS:
      return {
        ...state,
        [action.talentQualification["@id"]]: action.talentQualification,
      };
    case GetTalentQualifications.SUCCESS:
      return {
        ...state,
        ...action.qualifications.reduce(
          (acc: { [iri: string]: Qualification }, value: Qualification) => ({ ...acc, [value["@id"]]: value }),
          {},
        ),
      };
    default:
      return state;
  }
}

export function talentQualifications(state: Qualification[] = [], action: AnyAction) {
  switch (action.type) {
    case GetTalentQualifications.SUCCESS:
      return action.qualifications;
    case CreateTalentQualification.SUCCESS:
    case GetChatGPT.SUCCESS:
    case PostTranscript.SUCCESS:
      return [action.talentQualification, ...state];
    case EditTalentQualification.SUCCESS:
      state.splice(
        state.findIndex((e) => action.talentQualification["@id"] === e["@id"]),
        1,
        action.talentQualification,
      );
      return [...state];
    case LOCATION_CHANGE:
      return [routes.talents.show].includes(action.payload.location.pathname) ? [] : state;
    default:
      return state;
  }
}
