import { GetContracts } from "../../../../actionTypes/contract";
import { Contract } from "../../../../interfaces/resources/contract";

export function contracts(state: Contract[] = [], action: any) {
  switch (action.type) {
    case GetContracts.SUCCESS:
      return action.contracts;
    default:
      return state;
  }
}
