import { parsePhoneNumberFromString } from "libphonenumber-js";
import React, { FC, PropsWithChildren } from "react";
import { Flag, FlagNameValues, Icon } from "semantic-ui-react";
import Yup from "../App/Yup";

type Props = PropsWithChildren<{ value: unknown }>;

export const RenderAny: FC<Props> = ({ value }: Props) => <>{value}</>;
export const RenderAnyObject: FC<Props> = ({ value }: Props) =>
  typeof value === "object" ? <>{JSON.stringify(value)}</> : null;

export const RenderEmail: FC<Props> = ({ value }: Props) => {
  const isEmail = Yup.string().email().required().isValidSync(value);
  return isEmail ? <a href={`mailto:${value}`}>{value as string}</a> : null;
};

export const RenderBoolean: FC<Props> = ({ value }: Props) => {
  const isBoolean = Yup.bool().required().isValidSync(value);

  return isBoolean ? <Icon name={value ? "check" : "close"} color={value ? "green" : "red"} /> : null;
};

export const RenderPhone: FC<Props> = ({ value }: Props) => {
  if (typeof value !== "string") {
    return null;
  }

  const parsed = parsePhoneNumberFromString(value);
  if (!parsed || !parsed.isValid()) {
    return null;
  }

  const flag = parsed.country ? parsed.country.toLowerCase() : undefined;

  return (
    <a href={`tel:${parsed.formatInternational()}`}>
      {flag && <Flag name={flag as FlagNameValues} />}
      {parsed.formatNational()}
    </a>
  );
};

function TransformTableCell(props: Props) {
  const { value } = props;
  const renderers: Array<FC<Props>> = [RenderEmail, RenderPhone, RenderBoolean, RenderAnyObject, RenderAny];

  return renderers.reduce<ReturnType<FC>>(
    (current, renderer) => (current === null ? renderer({ value }) : current),
    null,
  );
}

export default TransformTableCell;
