import { t } from "@lingui/macro";
import cx from "classnames";
import { format } from "date-fns";
import * as React from "react";
import { DragDropContext, DragStart, DropResult } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Menu, Radio, Search, Segment, Tab, CheckboxProps } from "semantic-ui-react";

import { EditApplication, GetApplications } from "../../actionTypes/applications";

import { useApiSelector, usePrevious } from "../../components/Utils/hook";
import { FilterContext } from "../../interfaces/api/filter";
import { ApiRequest } from "../../interfaces/api/request";
import { Application, applicationStatuses } from "../../interfaces/resources/application";
import { coreUserRoles } from "../../interfaces/resources/coreUser";
import { DashboardMode } from "../../interfaces/resources/dashboard";
import { State } from "../../interfaces/state";
import {
  editApplicationAction,
  getApplicationsAction,
  getApplicationsAcceptedAction,
} from "../../services/api/applications/actions";
import { getOffersAction } from "../../services/api/offer/actions";

import { history } from "../../store";
import globalStyles from "../../styles/global.module.scss";
import { Column } from "./Column";
import { ColumnProps, columnOrders, columnTransitions, columns } from "./Column/data";
import { getAcceptedFilterContext, getFilterContext, sortApplicationsForColumns } from "./helpers";

import styles from "./index.module.scss";
import "./index.scss";

interface Props {
  mode?: DashboardMode;
}

export const ApplicationsDashboard: React.FC<Props> = (props: Props) => {
  const { mode } = props;
  const dispatch = useDispatch();
  const editApplication = (iri: string, req: Partial<Application>) => dispatch(editApplicationAction(iri, req));

  const { apiPendingRequests, apiSuccess } = useApiSelector();
  const currentUser = useSelector((state: State) => state.currentUser);
  const offer = useSelector((state: State) => state.offer);
  const offers = useSelector((state: State) => state.offers);
  const talent = useSelector((state: State) => state.talent);
  const applicationTotalItems = useSelector((state: State) => state.applicationTotalItems);
  const applicationsState = useSelector((state: State) => state.applications);
  const offerApplicationsState = useSelector((state: State) => state.offerApplications);
  const applicationsAcceptedState = useSelector((state: State) => state.applicationsAccepted);

  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalAppId, setModalAppId] = React.useState<string>("");
  const [search, setSearch] = React.useState("");
  const [isRefusedMode, setIsRefusedMode] = React.useState(false);
  const [isTimMode, setIsTimMode] = React.useState(false);
  const [validDestinations, setValidDestinations] = React.useState<any>(null);
  const [cols, setCols] = React.useState<typeof columns>(JSON.parse(JSON.stringify(columns)));
  const [columnOrder, setColumnOrder] = React.useState(
    columnOrders[history.location.hash === "#in-process" ? "in-process" : "pre-process"],
  );

  const previousCurrentUser = usePrevious(currentUser);
  const previousIsRefusedMode = usePrevious(isRefusedMode);
  const previousIsTimMode = usePrevious(isTimMode);
  const previousMode = usePrevious(mode);
  const previousApiSuccess = usePrevious(apiSuccess);

  const onChange = (_: any, data: any): void => setSearch(data.value);

  const onTabChange = (_: any, data: any): void => setColumnOrder(data.panes[data.activeIndex].columnOrder);

  const handleSwitch = (s: boolean): void => setIsRefusedMode(s);

  const handleSwitchTim = (switchTimChecked: boolean) => {
    setIsTimMode(switchTimChecked);
  };

  const onDragStart = (start: DragStart): void => setValidDestinations(columnTransitions[start.source.droppableId]);

  const onDragEnd = (result: DropResult): void => {
    setValidDestinations(null);
    const { destination, source, draggableId } = result;

    if (!destination || destination.droppableId === source.droppableId) {
      return;
    }
    const destinationColumn: ColumnProps = cols[destination.droppableId];

    if (destinationColumn.status) {
      editApplication(draggableId, { status: destinationColumn.status });
    }

    if (destinationColumn.status === "to_pitch") {
      setModalOpen(true);
      setModalAppId(result.draggableId);
    }
  };

  const onDateSelect = (date: Date, application: Application): void => {
    const dateString = format(date, "yyyy-MM-dd HH:mm:ss");
    if (null === application.interviewAt) {
      editApplication(application["@id"], {
        interviewAt: dateString,
        interviewsAt: [dateString],
        status: applicationStatuses.interviews,
      });
    } else {
      editApplication(application["@id"], {
        interviewAt: dateString,
        interviewsAt: application.interviewsAt === null ? [dateString] : [...application.interviewsAt, dateString],
      });
    }
  };

  const paneContent = (
    <>
      <div className={styles.loadedApplicationContainer}>
        {!!applicationTotalItems ? (
          <span>
            Candidatures chargées : {applicationsState.length} / {applicationTotalItems}
          </span>
        ) : null}
      </div>
      <div className="menu">
        <Search
          onSearchChange={onChange}
          open={false}
          placeholder={t`ApplicationDashboard.placeholder`}
          value={search}
        />
        <div className={cx(styles.extraTools, "extraTools")}>
          <div>
            <Radio
              toggle
              label={t`ApplicationDashboard.switch`}
              onChange={(_: React.FormEvent<HTMLInputElement>, data: CheckboxProps): void =>
                handleSwitch(Boolean(data.checked))
              }
              checked={isRefusedMode}
            />
          </div>
          {currentUser?.roles.includes(coreUserRoles.tim) && currentUser?.roles.includes(coreUserRoles.am) ? (
            <div>
              <Radio
                toggle
                label={t`ApplicationDashboard.switch.tim`}
                onChange={(_: React.FormEvent<HTMLInputElement>, data: CheckboxProps): void =>
                  handleSwitchTim(Boolean(data.checked))
                }
                checked={isTimMode}
              />
            </div>
          ) : null}
        </div>
      </div>
      <Segment basic>
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
          <div className="columnsContainer">
            {columnOrder.map((columnId: string) => {
              const column = cols[columnId];
              let columnApplications;
              if (isRefusedMode && column.refusedApplications) {
                columnApplications = column.refusedApplications;
              } else {
                columnApplications = column.applications;
              }
              const isDropDisabled = validDestinations !== null && !validDestinations.includes(columnId);
              return (
                <Column
                  modalOpen={modalOpen}
                  modalAppId={modalAppId}
                  setModalOpen={setModalOpen}
                  applications={columnApplications}
                  column={column}
                  editApplication={editApplication}
                  editingCards={
                    apiPendingRequests.length > 0
                    && apiPendingRequests.some((e: ApiRequest) => EditApplication.REQUEST === e.type)
                      ? apiPendingRequests.filter((e: ApiRequest) => EditApplication.REQUEST === e.type)
                      : []
                  }
                  isDropDisabled={isDropDisabled}
                  isRefusedMode={isRefusedMode}
                  isTimMode={isTimMode}
                  mode={mode}
                  key={column.id}
                  onDateSelect={onDateSelect}
                  search={search}
                />
              );
            })}
          </div>
        </DragDropContext>
      </Segment>
    </>
  );

  const panes = [
    {
      columnOrder: columnOrders["pre-process"],
      menuItem: (
        <Menu.Item as={Link} to="#pre-process" key="pre-process">
          {t`ApplicationDashboard.tabs.preProcess`}
        </Menu.Item>
      ),
      render: (): JSX.Element => (
        <Tab.Pane attached={false} className="boardContainer">
          {paneContent}
        </Tab.Pane>
      ),
    },
    {
      columnOrder: columnOrders["in-process"],
      menuItem: (
        <Menu.Item as={Link} to="#in-process" key="in-process">
          {t`ApplicationDashboard.tabs.inProcess`}
        </Menu.Item>
      ),
      render: (): JSX.Element => (
        <Tab.Pane attached={false} className="boardContainer">
          {paneContent}
        </Tab.Pane>
      ),
    },
  ];

  React.useEffect(() => {
    const apiSuccessIntersection = apiSuccess.slice(previousApiSuccess?.length);
    const resueModeChanged = previousIsRefusedMode !== undefined && previousIsRefusedMode !== isRefusedMode;
    const timModeChanged = previousIsTimMode !== undefined && previousIsTimMode !== isTimMode;
    const modeChanged = previousMode !== mode;
    const uniqId = `dashboard-process-apps-${mode !== undefined ? mode : "suivi"}`;

    if (
      previousCurrentUser !== currentUser
      || resueModeChanged
      || timModeChanged
      || apiSuccessIntersection.some((action) => GetApplications.SUCCESS === action.type && uniqId === action.uniqId)
    ) {
      const filterContext = getFilterContext(isRefusedMode, isTimMode, mode, currentUser, offer, talent);
      const apSucc = apiSuccessIntersection.filter(
        (action) => GetApplications.SUCCESS === action.type && uniqId === action.uniqId,
      )[0];
      const doNotConcat = resueModeChanged || modeChanged || timModeChanged; // si on vient de changer le mode, on remplace les candidatures du state
      const nextPage = apSucc?.nextPage;
      if (!apSucc || (!!apSucc && !!nextPage)) {
        dispatch(getApplicationsAction(filterContext, nextPage, !doNotConcat, uniqId));
      }
    }

    if (previousCurrentUser !== currentUser) {
      const acceptedFilterConext = getAcceptedFilterContext(mode, currentUser, offer, talent);
      dispatch(getApplicationsAcceptedAction(acceptedFilterConext, undefined, true, "dashboard-accepted-apps"));
    }
  }, [
    currentUser,
    dispatch,
    mode,
    offer,
    talent,
    apiSuccess,
    previousApiSuccess,
    previousCurrentUser,
    previousIsRefusedMode,
    previousIsTimMode,
    previousMode,
    isRefusedMode,
    isTimMode,
  ]);

  React.useEffect(() => {
    const apps = JSON.parse(JSON.stringify(applicationsState));

    applicationsAcceptedState.map((oApp) => {
      const appId = oApp["@id"];
      const iApp = apps.findIndex((a: Application) => a["@id"] === appId);
      if (iApp < 0) {
        apps.push(oApp);
      }
      return oApp;
    });

    offerApplicationsState.map((oApp) => {
      const appId = oApp["@id"];
      const iApp = apps.findIndex((a: Application) => a["@id"] === appId);
      if (iApp < 0) {
        apps.push(oApp);
      }
      return oApp;
    });
    const newCols = sortApplicationsForColumns(apps);
    newCols["column-0"].isGrouped =
      mode === "suivi" && !isRefusedMode && !!currentUser?.roles.includes(coreUserRoles.am) && !isTimMode;
    newCols["column-0"].groupData =
      mode === "suivi"
        ? {
            key: "offer.@id",
            values: offers.map((o) => ({
              id: o["@id"],
              name: o.name,
              count: o.interestingContactedApplicationCount,
            })),
          }
        : undefined;

    setCols(newCols);
  }, [applicationsState, offerApplicationsState, applicationsAcceptedState, offers, mode, isRefusedMode, currentUser]);

  React.useEffect(() => {
    if (!!!currentUser || mode !== "suivi") {
      return;
    }
    const getOffers = (fc: FilterContext) => dispatch(getOffersAction(fc));
    getOffers([
      {
        multiple: false,
        name: "owners",
        value: currentUser["@id"],
      },
      {
        multiple: true,
        name: "groups",
        value: ["internal:read:offer:dashboard"],
      },
      {
        multiple: true,
        name: "status",
        value: ["activated", "standby_elinoi"],
      },
    ]);
  }, [dispatch, currentUser, mode]);

  return (
    <div className="applicationDashboardContainer">
      <div className={globalStyles.mainTitleContainer}>
        <h1 className={globalStyles.mainTitle}>
          {mode === "offer"
            ? t`ApplicationDashboard.title.offer`
            : mode === "talent"
            ? t`ApplicationDashboard.title.talent`
            : t`ApplicationDashboard.title`}
        </h1>
      </div>
      <Tab
        defaultActiveIndex={history.location.hash === "#in-process" ? 1 : 0}
        menu={{ secondary: true, style: { justifyContent: "center" } }}
        onTabChange={onTabChange}
        panes={panes}
      />
    </div>
  );
};
