import { t } from "@lingui/macro";
import cx from "classnames";
import { parseISO, format } from "date-fns";
import * as React from "react";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GetOffer } from "../../../../actionTypes/offers";
import { useApiSelector } from "../../../../components/Utils/hook";
import LoaderComp from "../../../../components/Utils/Loader";
import {
  Application,
  applicationStatuses,
  applicationStatusesInProcess,
  applicationStatusesRefused,
} from "../../../../interfaces/resources/application";
import { offerMode, offerStatuses } from "../../../../interfaces/resources/offer";
import { State } from "../../../../interfaces/state";
import { extractPageFromIri } from "../../../../services/api/helpers";
import { getOfferAction } from "../../../../services/api/offer/actions";
import transOffer from "../../../../translations/constants/offer";
import { CompanyImg } from "../../../companyImg";
import { Label } from "../../../labels";
import { OfferStatusLabel } from "../../../labels/OfferStatusLabel";
import { CompanyOfferData } from "../CompanyOfferData";

import styles from "./index.module.scss";

interface Props {
  offerIri: string;
}

interface ApplicationData {
  accepted: Application[];
  process: Application[];
  refused: Application[];
}

export const CompanyOfferItem: React.FC<Props> = (props: Props): JSX.Element => {
  const { offerIri = "" } = props;
  const { apiPendingRequests } = useApiSelector();
  const dispatch = useDispatch();
  const [applicationData, setApplicationData] = React.useState<ApplicationData>({
    accepted: [],
    process: [],
    refused: [],
  });
  const offersState = useSelector((state: State) => state.offers);
  const offer = offersState.find((o) => o["@id"] === offerIri);
  const isDesactivated = offer?.status === offerStatuses.desactivated;
  const statusDate =
    !isDesactivated && offer?.lastActivationStatusDate
      ? format(parseISO(offer.lastActivationStatusDate), "dd/MM/yyyy")
      : isDesactivated && offer.lastDesactivationStatusDate
      ? format(parseISO(offer.lastDesactivationStatusDate), "dd/MM/yyyy")
      : undefined;

  const loading = apiPendingRequests.some((ap) => ap.type === GetOffer.REQUEST && ap.iri === offerIri);

  React.useEffect(() => {
    if (!!!offer) {
      dispatch(getOfferAction(offerIri));
    }
  }, [offer, offerIri, dispatch]);

  React.useEffect(() => {
    const process: Application[] = [];
    const refused: Application[] = [];
    const accepted: Application[] = [];

    if (offer?.applications?.length) {
      offer.applications.forEach((ap) => {
        if (ap.status === applicationStatuses.accepted) {
          accepted.push(ap);
        } else if (applicationStatusesInProcess.includes(ap.status)) {
          process.push(ap);
        } else if (applicationStatusesRefused.includes(ap.status)) {
          refused.push(ap);
        }
      });
    }
    setApplicationData({ process, refused, accepted });
  }, [offer, offerStatuses]);

  return loading || !!!offer ? (
    <div className={cx(styles.card, styles.loading)}>
      <LoaderComp />
    </div>
  ) : (
    <>
      <div
        className={cx(styles.card, {
          [styles.closedState]: applicationData.accepted.length > 0 && offer.status === offerStatuses.desactivated,
          [styles.desactivatedState]:
            applicationData.accepted.length <= 0 && offer.status === offerStatuses.desactivated,
        })}
      >
        <div className={styles.cardContent}>
          <div className={styles.headerCardContainer}>
            {offer.status ? (
              <div className={styles.headerItem}>
                <OfferStatusLabel offer={offer} />
              </div>
            ) : null}
            {offer.mode === offerMode.recurring ? (
              <div className={styles.headerItem}>
                <Label
                  key={`offer-card-recurring-label-${offer["@id"]}-${offer.mode}`}
                  content={t({ id: transOffer.mode[offer.mode] })}
                  className={styles.interviewLabel}
                />
              </div>
            ) : null}
            {statusDate ? (
              <div className={cx(styles.headerItem, styles.statusDateItem)}>
                <div>
                  {t({
                    id: "CompanyOfferItem.statusDate",
                    values: {
                      status: !isDesactivated
                        ? "ouverte"
                        : isDesactivated && applicationData.accepted.length > 0
                        ? "closée"
                        : "fermée",
                      date: statusDate,
                    },
                  })}
                </div>
              </div>
            ) : null}
          </div>
          <div className={styles.bodyCardContainer}>
            <div className={styles.offerPartContainer}>
              <div className={styles.companyImgContainer}>
                <CompanyImg src={offer.company?.image?.url} />
              </div>
              <div>
                <h4 className={styles.offerTitleContainer}>
                  <Link
                    className={styles.offerTitleLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    to={`${extractPageFromIri("offers", offer["@id"])}#in-process`}
                  >
                    {offer.title}
                  </Link>
                </h4>
                <div className={styles.infosContainer}>
                  <div>
                    <ReactMarkdown
                      components={{
                        p: React.Fragment,
                      }}
                    >
                      {offer.printExperienceYears
                        ? t({
                            id: "CompanyOfferItem.info.experience",
                            values: {
                              experience: offer.printExperienceYears,
                            },
                          })
                        : t`CompanyOfferItem.info.experience.empty`}
                    </ReactMarkdown>
                  </div>
                  <div>
                    <ReactMarkdown
                      components={{
                        p: React.Fragment,
                      }}
                    >
                      {offer.printSalaries
                        ? t({
                            id: "CompanyOfferItem.info.salaries",
                            values: {
                              salaries: offer.printSalaries,
                            },
                          })
                        : t`CompanyOfferItem.info.salaries.empty`}
                    </ReactMarkdown>
                  </div>
                  <div>
                    <ReactMarkdown
                      components={{
                        p: React.Fragment,
                      }}
                    >
                      {offer.setUp || offer.commission
                        ? t({
                            id: "CompanyOfferItem.info.contract",
                            values: {
                              setUp: offer.setUp || "-",
                              com: offer.commission || "-",
                            },
                          })
                        : t`CompanyOfferItem.info.contract.empty`}
                    </ReactMarkdown>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.dataPartContainer}>
              <div>
                <CompanyOfferData applications={applicationData.refused} status="refusedPreProcess" />
              </div>
              <div>
                <CompanyOfferData applications={applicationData.refused} status="refusedProcess" />
              </div>
              <div>
                <CompanyOfferData applications={applicationData.process} status="process" />
              </div>
              <div>
                <CompanyOfferData applications={applicationData.accepted} status="accepted" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
