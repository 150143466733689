import { t } from "@lingui/macro";
import cx from "classnames";
import * as React from "react";
import { Droppable, DroppableProvided, DroppableStateSnapshot } from "react-beautiful-dnd";

import { useDispatch, useSelector } from "react-redux";
import { GetApplications } from "../../../actionTypes/applications";
import { useApiSelector, usePrevious } from "../../../components/Utils/hook";
import { ApiRequest } from "../../../interfaces/api/request";
import { Application } from "../../../interfaces/resources/application";
import { coreUserRoles } from "../../../interfaces/resources/coreUser";
import { DashboardMode } from "../../../interfaces/resources/dashboard";
import { State } from "../../../interfaces/state";
import { getApplicationsAction } from "../../../services/api/applications/actions";
import globalStyles from "../../../styles/global.module.scss";
import { ApplicationsGroup } from "../ApplicationsGroup";
import { filterApplicationArray, getApplicationsNumber, getTimInterestingContactedFilterContext } from "../helpers";
import { ColumnProps } from "./data";
import styles from "./index.module.scss";

interface ListProps {
  setModalOpen: any;
  modalOpen: boolean;
  modalAppId: string;
  applications: Application[];
  column: ColumnProps;
  editApplication: (iri: string, req: Partial<Application>) => void;
  editingCards: ApiRequest[];
  isRefusedMode: boolean;
  isTimMode: boolean;
  onDateSelect: (date: Date, application: Application) => void;
}

export interface GroupedApp {
  id?: string;
  name?: string;
  count?: number;
  applications: Application[];
}

interface Props extends ListProps {
  isDropDisabled: boolean;
  search: string;
  mode: DashboardMode | undefined;
}

export const Column: React.FC<Props> = (props: Props) => {
  const {
    modalAppId,
    setModalOpen,
    modalOpen,
    column,
    editApplication,
    isRefusedMode,
    isTimMode,
    isDropDisabled,
    onDateSelect,
    search,
    applications,
    mode,
  } = props;
  const currentUser = useSelector((state: State) => state.currentUser);
  const dispatch = useDispatch();
  const { apiSuccess, apiPendingRequests } = useApiSelector();
  const previousIsRefusedMode = usePrevious(isRefusedMode);
  const previousApiSuccess = usePrevious(apiSuccess);
  const previousApiPendingRequests = usePrevious(apiPendingRequests);
  const [allowTrigger, setAllowTrigger] = React.useState(true);
  const filteredApps = filterApplicationArray(applications, search);
  const uniqId = "dashboard-process-tim-interesting-app";
  const groupedApplications = (apps: Application[]): GroupedApp[] => {
    if (!column.isGrouped) {
      return [{ applications: apps }];
    }
    const vals = column.groupData?.values || [];
    const groupedApps: GroupedApp[] = [];
    const tempIriArray: string[] = [];
    if (!!!vals?.length) {
      apps.map((ap) => {
        const iri = ap.offer["@id"];
        const name = ap.offer.name;
        if (tempIriArray.indexOf(iri) < 0) {
          tempIriArray.push(iri);
          vals.push({
            id: iri,
            name,
          });
        }
        return ap;
      });
    }

    vals?.map((val) => {
      const valAps = apps.filter((ap) => ap.offer["@id"] === val.id).map((mAp) => mAp);
      const valObj = {
        name: val.name,
        id: val.id,
        count: val.count,
        applications: valAps,
      };
      groupedApps.push(valObj);
      return val;
    });

    return groupedApps;
  };

  const groupedApps = groupedApplications(filteredApps);

  const filteredLength = getApplicationsNumber(filteredApps);
  const totalLength = getApplicationsNumber(applications);

  const getInterestingContactedApplications = () => {
    if (currentUser && allowTrigger && !applications.length) {
      const filterContext = getTimInterestingContactedFilterContext(currentUser, isTimMode);
      dispatch(getApplicationsAction(filterContext, undefined, true, uniqId));
    }
  };

  React.useEffect(() => {
    const apiSuccessIntersection = apiSuccess.slice(previousApiSuccess?.length);
    const apiPendingIntersection = apiPendingRequests.slice(previousApiPendingRequests?.length);
    const refuseModeChanged = previousIsRefusedMode !== undefined && previousIsRefusedMode !== isRefusedMode;
    if (
      apiSuccessIntersection.some((action) => GetApplications.SUCCESS === action.type && uniqId === action.uniqId)
      || apiPendingIntersection.some((action) => GetApplications.REQUEST === action.type && uniqId === action.uniqId)
    ) {
      setAllowTrigger(false);
    }
    if (refuseModeChanged) {
      setAllowTrigger(true);
    }
  }, [apiSuccess, previousIsRefusedMode, apiPendingRequests, previousApiPendingRequests, isRefusedMode]);

  return (
    <div className="columnContainer">
      <h3 className="title">
        {t({ id: column.title })} (
        {filteredLength !== totalLength ? (
          <span>
            {filteredLength} {t`ApplicationDashboard.Column.of`} {totalLength}
          </span>
        ) : (
          totalLength
        )}
        )
      </h3>
      <Droppable droppableId={column.id} isDropDisabled={isDropDisabled}>
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
          <div
            className={snapshot.isDraggingOver ? "applicationWrapper draggingOver" : "applicationWrapper"}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <div className="cardList">
              {column.id === "column-0"
              && (!!!currentUser?.roles.includes(coreUserRoles.am) || isTimMode)
              && !applications.length ? (
                <div className={styles.getAppsCtaContainer}>
                  <button
                    type="submit"
                    onClick={getInterestingContactedApplications}
                    className={cx(globalStyles.cta, globalStyles.primaryCta, globalStyles.smaller)}
                  >
                    Charger
                  </button>
                </div>
              ) : null}
              {allowTrigger
              && column.id === "column-0"
              && (!!!currentUser?.roles.includes(coreUserRoles.am) || isTimMode)
              && mode === "suivi"
                ? null
                : groupedApps.map((gApps, i) => {
                    return (
                      <ApplicationsGroup
                        key={`goupApps-${column.id}-${gApps.id ? `-${gApps.id}` : ""}-${i}`}
                        mode={mode}
                        modalAppId={modalAppId}
                        modalOpen={modalOpen}
                        setModalOpen={setModalOpen}
                        groupedApp={gApps}
                        column={column}
                        editApplication={editApplication}
                        isRefusedMode={isRefusedMode}
                        onDateSelect={onDateSelect}
                      />
                    );
                  })}
            </div>
            <div
              className={cx(styles.placeHolderContainer, {
                [styles.hidden]: isDropDisabled,
              })}
            >
              {provided.placeholder}
            </div>
          </div>
        )}
      </Droppable>
    </div>
  );
};
