import * as React from "react";
import { useLocation, useParams } from "react-router-dom";
import { ContactTalent } from "../../components/Hunt/ContactTalent";

import { generateIriFromId } from "../../services/api/helpers";

export const InternalHuntContactsScene: React.FC = (): JSX.Element => {
  const location = useLocation();
  const { id } = useParams();
  return (
    <div className="searchContainer">
      <ContactTalent iri={generateIriFromId(location.pathname.includes("offers") ? "offers" : "hunts", Number(id))} />
    </div>
  );
};
