import { ActionType } from "../interfaces/api/actionType";

export const EditTalentQualification: ActionType = {
  FAILURE: "EDIT_TALENT_QUALIFICATION_FAILURE",
  REQUEST: "EDIT_TALENT_QUALIFICATION_REQUEST",
  SUCCESS: "EDIT_TALENT_QUALIFICATION_SUCCESS",
};

export const CreateTalentQualification: ActionType = {
  FAILURE: "CREATE_TALENT_QUALIFICATION_FAILURE",
  REQUEST: "CREATE_TALENT_QUALIFICATION_REQUEST",
  SUCCESS: "CREATE_TALENT_QUALIFICATION_SUCCESS",
};

export const GetTalentQualification: ActionType = {
  FAILURE: "GET_TALENT_QUALIFICATION_FAILURE",
  REQUEST: "GET_TALENT_QUALIFICATION_REQUEST",
  SUCCESS: "GET_TALENT_QUALIFICATION_SUCCESS",
};

export const GetTalentQualifications: ActionType = {
  FAILURE: "GET_TALENT_QUALIFICATIONS_FAILURE",
  REQUEST: "GET_TALENT_QUALIFICATIONS_REQUEST",
  SUCCESS: "GET_TALENT_QUALIFICATIONS_SUCCESS",
};

export const GetChatGPT: ActionType = {
  FAILURE: "GET_CHAT_GPT_FAILURE",
  REQUEST: "GET_CHAT_GPT_REQUEST",
  SUCCESS: "GET_CHAT_GPT_SUCCESS",
};

export const PostTranscript: ActionType = {
  FAILURE: "POST_TRANSCRIPT_FAILURE",
  REQUEST: "POST_TRANSCRIPT_REQUEST",
  SUCCESS: "POST_TRANSCRIPT_SUCCESS",
};
