import * as React from "react";
import { List, Popup } from "semantic-ui-react";

import { Responsibility, formatResponsibilities } from "../../../interfaces/resources/responsibility";

interface Props {
  responsibilities: Responsibility[];
}
const ResponsibilitiesCompact = ({ responsibilities }: Props) => {
  return (
    <span>
      {formatResponsibilities(responsibilities).map((group: any, i: number) => (
        <Popup
          key={group.label}
          flowing
          content={
            <List>
              {group.options.map((resp: Responsibility) => (
                <List.Item key={resp["@id"]}>{resp.value}</List.Item>
              ))}
            </List>
          }
          trigger={
            <span>
              {i > 0 && ", "}
              {group.label} ({group.options.length})
            </span>
          }
        />
      ))}
    </span>
  );
};

export default ResponsibilitiesCompact;
