import { ActionType } from "../interfaces/api/actionType";

export const EditTalentProfile: ActionType = {
  FAILURE: "EDIT_TALENT_PROFILE_FAILURE",
  REQUEST: "EDIT_TALENT_PROFILE_REQUEST",
  SUCCESS: "EDIT_TALENT_PROFILE_SUCCESS",
};

export const GetTalentProfile: ActionType = {
  FAILURE: "GET_TALENT_PROFILE_FAILURE",
  REQUEST: "GET_TALENT_PROFILE_REQUEST",
  SUCCESS: "GET_TALENT_PROFILE_SUCCESS",
};

export const CreateTalentProfileResponsibility: ActionType = {
  FAILURE: "CREATE_TALENT_PROFILE_RESPONSIBILITY_FAILURE",
  REQUEST: "CREATE_TALENT_PROFILE_RESPONSIBILITY_REQUEST",
  SUCCESS: "CREATE_TALENT_PROFILE_RESPONSIBILITY_SUCCESS",
};

export const DeleteTalentProfileResponsibility: ActionType = {
  FAILURE: "DELETE_TALENT_PROFILE_RESPONSIBILITY_FAILURE",
  REQUEST: "DELETE_TALENT_PROFILE_RESPONSIBILITY_REQUEST",
  SUCCESS: "DELETE_TALENT_PROFILE_RESPONSIBILITY_SUCCESS",
};

export const EditTalentProfileResponsibility: ActionType = {
  FAILURE: "EDIT_TALENT_PROFILE_RESPONSIBILITY_FAILURE",
  REQUEST: "EDIT_TALENT_PROFILE_RESPONSIBILITY_REQUEST",
  SUCCESS: "EDIT_TALENT_PROFILE_RESPONSIBILITY_SUCCESS",
};
