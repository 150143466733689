import { t } from "@lingui/macro";
import cx from "classnames";
import { Field, useFormikContext } from "formik";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AnyAction } from "redux";
import { Button, Form, Message } from "semantic-ui-react";
import { OFFER_MAIN_FORM } from "..";
import { GetCompanies } from "../../../../actionTypes/companies";
import { CreateContact, EditContact } from "../../../../actionTypes/contacts";
import { GetCoreUsers, GetCurrentUser } from "../../../../actionTypes/coreUsers";
import { GetDesiredPlaces } from "../../../../actionTypes/desiredPlaces";
import { GetExperienceYears } from "../../../../actionTypes/experienceYears";
import { GetOffer } from "../../../../actionTypes/offers";
import { GetResponsibilities } from "../../../../actionTypes/responsibilities";
import { GetSalaryExpectations } from "../../../../actionTypes/salaryExpectations";

import { ApiError } from "../../../../interfaces/api/error";
import { ApiRequest } from "../../../../interfaces/api/request";
import { ApiSuccess } from "../../../../interfaces/api/success";
import { FormState, Violation } from "../../../../interfaces/api/violation";
import { Company } from "../../../../interfaces/resources/company";
import { Contact } from "../../../../interfaces/resources/contact";
import { CoreUser, coreUserRoles } from "../../../../interfaces/resources/coreUser";
import { DesiredPlace, desiredPlacesRemote } from "../../../../interfaces/resources/desiredPlace";
import { ExperienceYears } from "../../../../interfaces/resources/experienceYears";
import {
  OfferLocation,
  offerStatuses,
  offerLevel,
  offerFormule,
  offerMode,
} from "../../../../interfaces/resources/offer";
import { Responsibility } from "../../../../interfaces/resources/responsibility";
import { SalaryExpectations } from "../../../../interfaces/resources/salaryExpectations";
import { State } from "../../../../interfaces/state";
import { routes } from "../../../../routes";
import { getCompaniesAction } from "../../../../services/api/company/actions";
import { getCoreUsersAction } from "../../../../services/api/coreUser/actions";
import { getDesiredPlacesAction } from "../../../../services/api/desiredPlaces/actions";
import { getExperienceYearsAction } from "../../../../services/api/experienceYears/actions";
import { getStringValue } from "../../../../services/api/helpers";
import { getResponsibilitiesAction } from "../../../../services/api/responsibilities/actions";
import { getSalaryExpectationsAction } from "../../../../services/api/salaryExpectations/actions";
import globalStyles from "../../../../styles/global.module.scss";
import transOffer from "../../../../translations/constants/offer";

import Checkbox from "../../../Form/Formik/Checkbox";
import { Contacts } from "../../../Form/Formik/Contacts";
import { File } from "../../../Form/Formik/File";
import Input from "../../../Form/Formik/Input";
import Radio from "../../../Form/Formik/Radio";
import RichTextEditor from "../../../Form/Formik/RichTextEditor";
import { Select } from "../../../Form/Formik/Select";
import { isLoading, useApiSelector, useGetResourceHook, usePrevious } from "../../../Utils/hook";
import styles from "./index.module.scss";
import MainFormModal from "./MainFormModal";
import { OfferMainFormContext } from "./OfferMainFormContext";

import "./index.scss";

interface Props {
  enableSwitchPage: (e: boolean) => void;
  formType: "creation" | "edition";
}

const tierOptions = ["tier_1", "tier_2", "tier_3", "tier_4"];

export const MainFormFields: React.FC<Props> = ({ formType, enableSwitchPage }: Props) => {
  const { values, setFieldValue, handleSubmit, isValid, isSubmitting, errors } = useFormikContext<any>();
  const { state: contextState, update } = React.useContext(OfferMainFormContext);

  const companies = useSelector((state: State) => state.companies);
  const contact = useSelector((state: State) => state.contact);
  const coreUsers = useSelector((state: State) => state.coreUsers);
  const desiredPlacesState = useSelector((state: State) => state.desiredPlaces);
  const desiredPlaces = desiredPlacesState.filter((e) => !desiredPlacesRemote.includes(e.value));
  const remotePreferences = desiredPlacesState.filter((e) => desiredPlacesRemote.includes(e.value));
  const experienceYears = useSelector((state: State) => state.experienceYears);
  const offer = useSelector((state: State) => state.offer);
  const responsibilities = useSelector((state: State) => state.responsibilities);
  const salaryExpectations = useSelector((state: State) => state.salaryExpectations);

  const { apiPendingRequests, apiErrors, apiSuccess } = useApiSelector();

  const dispatch = useDispatch();

  const getCompanies = (): AnyAction =>
    dispatch(getCompaniesAction([{ multiple: false, name: "status", value: "closed" }]));
  const getCoreUsers = (): AnyAction => dispatch(getCoreUsersAction());
  const getDesiredPlaces = (): AnyAction => dispatch(getDesiredPlacesAction());
  const getExperienceYears = (): AnyAction => dispatch(getExperienceYearsAction());
  const getResponsibilities = (): AnyAction => dispatch(getResponsibilitiesAction());
  const getSalaryExpectations = (): AnyAction => dispatch(getSalaryExpectationsAction());

  const [coreResponsibility, setCoreResponsibility] = React.useState<Responsibility | null>(null);
  const [formState, setFormState] = React.useState<FormState>(undefined);
  const [formSubmission, setFormSubmission] = React.useState<string | null>(null);
  const [modalIsVisible, setModalIsVisible] = React.useState(false);
  const [modalOnProceed, setModalOnProceed] = React.useState<() => void>();
  const [violations, setViolations] = React.useState<Violation[]>([]);

  const oldApiSuccess = usePrevious(apiSuccess);
  const oldCompany = usePrevious(values.company);
  const oldContact = usePrevious(contact);
  const oldFormSubmission = usePrevious(formSubmission);

  const sortedResponsibilities =
    responsibilities
    && responsibilities.reduce((result: { label: string; options: Responsibility[] }[], item: Responsibility) => {
      if (coreResponsibility && coreResponsibility["@id"] === item["@id"]) {
        return result; // Do not push item that already is the coreResponsibility
      }
      const respType = item.responsibilityType;
      if (result.some((e: any) => e.label === respType.value)) {
        result.forEach((e: any) => {
          if (e.label === respType.value) {
            e.options.push(item);
          }

          return undefined;
        });
      } else {
        result.push({
          label: respType.value,
          options: [item],
        });
      }
      return result;
    }, []);
  let uniqId = formType === "creation" ? `${OFFER_MAIN_FORM}-new` : null;

  React.useEffect(() => {
    if (apiSuccess.some((e: ApiSuccess) => OFFER_MAIN_FORM === e.form)) {
      setFormState("success");
      setViolations([]);
      const timer = setTimeout(() => {
        setFormState(undefined);
      }, 10000);
      return (): void => clearTimeout(timer);
    }

    if (apiErrors.some((e: ApiError) => OFFER_MAIN_FORM === e.form)) {
      const apiError = apiErrors.find((e: ApiError) => OFFER_MAIN_FORM === e.form);
      if (apiError) {
        setViolations(apiError.payload.violations || []);
        setModalIsVisible(false);
      }
    }
  }, [apiSuccess, apiPendingRequests, apiErrors]);

  React.useEffect(() => {
    if (isSubmitting && null !== formSubmission) {
      if (isValid) {
        setModalOnProceed(() => (): void => {
          update({ ...contextState, formSubmissionType: formSubmission });
          handleSubmit();
          enableSwitchPage(true);
        });
        setModalIsVisible(true);
        setFormSubmission(null);
        window.scrollTo(0, 0);
      } else {
        if (document.getElementsByClassName("error")[1]) {
          window.scrollTo({
            top: (document.getElementsByClassName("error")[1] as any).offsetTop,
          });
        }
        setFormSubmission(null);
        update({ ...contextState, formSubmissionType: null });
      }
    }
  }, [
    oldFormSubmission,
    formSubmission,
    enableSwitchPage,
    contextState,
    update,
    handleSubmit,
    isValid,
    isSubmitting,
    errors,
  ]);

  React.useEffect(() => {
    if (offer && offer.offerLocations && values && !values.offerLocations) {
      setFieldValue(
        "offerLocations",
        offer.offerLocations.map((e: OfferLocation) =>
          getStringValue(values.stapleLocation, "@id") === getStringValue(e.location, "@id") ? undefined : e.location,
        ),
      );
    }
  }, [setFieldValue, values, offer]);

  React.useEffect(() => {
    if (formType === "creation" && values.company && values.company !== oldCompany) {
      setFieldValue("contacts", Array.from(new Set([...values.company.contacts, ...values.contacts])));
    }
  }, [formType, values.company, values.contacts, setFieldValue, oldCompany]);

  React.useEffect(() => {
    const apiSuccessIntersection = apiSuccess.slice(oldApiSuccess?.length);
    if (
      contact
      && apiSuccessIntersection.some((action) => [CreateContact.SUCCESS, EditContact.SUCCESS].includes(action.type))
    ) {
      let newContacts = values.contacts;
      let isDeleted: boolean | number = false;
      if (apiSuccessIntersection.some((action: ApiSuccess) => [EditContact.SUCCESS].includes(action.type))) {
        isDeleted = newContacts.findIndex((e: Contact) => e["@id"] === contact["@id"]);
        if (typeof isDeleted === "number" && isDeleted >= 0) {
          newContacts.splice(isDeleted, 1);
        }
      }
      if (
        isDeleted !== -1
        && apiSuccessIntersection.some((action: ApiSuccess) =>
          [CreateContact.SUCCESS, EditContact.SUCCESS].includes(action.type),
        )
      ) {
        newContacts = [...newContacts, contact];
      }
      setFieldValue("contacts", newContacts);
    }
  }, [values, setFieldValue, offer, contact, apiSuccess, oldApiSuccess, oldContact]);

  useGetResourceHook(apiErrors, apiPendingRequests, apiSuccess, GetCompanies, companies, getCompanies, []);
  useGetResourceHook(apiErrors, apiPendingRequests, apiSuccess, GetCoreUsers, coreUsers, getCoreUsers, []);
  useGetResourceHook(apiErrors, apiPendingRequests, apiSuccess, GetDesiredPlaces, desiredPlaces, getDesiredPlaces, []);
  useGetResourceHook(
    apiErrors,
    apiPendingRequests,
    apiSuccess,
    GetExperienceYears,
    experienceYears,
    getExperienceYears,
    [],
  );
  useGetResourceHook(
    apiErrors,
    apiPendingRequests,
    apiSuccess,
    GetResponsibilities,
    responsibilities,
    getResponsibilities,
    [],
  );
  useGetResourceHook(
    apiErrors,
    apiPendingRequests,
    apiSuccess,
    GetSalaryExpectations,
    salaryExpectations,
    getSalaryExpectations,
    [],
  );

  if (offer) {
    uniqId = `${OFFER_MAIN_FORM}-${offer["@id"]}`;
  }

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        loading={
          apiPendingRequests.length > 0
          && (apiPendingRequests.some((e: ApiRequest) => OFFER_MAIN_FORM === e.form)
            || ("edition" === formType && apiPendingRequests.some((e: ApiRequest) => GetOffer.REQUEST === e.type)))
        }
        error={"error" === formState}
        success={"success" === formState}
      >
        <Message
          success
          content={t`talentCard.success.content`}
          header={t`talentCard.success.header`}
          icon="check circle"
        />
        <Message error content={t`talentCard.error.content`} header={t`talentCard.error.header`} icon="times circle" />
        <div className="formPart">
          <div className="formGroup">
            <Field
              component={Select}
              getOptionLabel={(comp: Company): string => comp.name}
              getOptionValue={(comp: Company): string => comp["@id"]}
              isLoading={isLoading([GetCompanies], apiErrors, apiPendingRequests, apiSuccess)}
              isMulti={false}
              isSearchable={true}
              label={t`offer.company`}
              name="company"
              options={companies}
              placeholder={t`offer.company.placeholder`}
              violations={violations}
            />
          </div>
          <div className="formGroup">
            <Field component={Input} label={t`offer.title`} name="title" violations={violations} />
          </div>
          <div className="formGroup">
            <Field
              accept="application/pdf"
              component={File}
              documentUrl={offer && offer.document && offer.document.url}
              label={t`offer.documentFile`}
              name="documentFile"
              violations={violations}
            />
          </div>
          <div className="formGroup">
            <Field
              component={Input}
              isRequired={false}
              label={t`offer.offerTalentView.ytPitch`}
              name="offerTalentView.ytPitch"
              violations={violations}
            />
          </div>
        </div>
        <div className="formPart">
          <h2 className="formPartTitle">{t`offer.type.head`}</h2>
          <div className="formGroup formInlineGroup">
            <div className={cx("field inline", styles.radioInlineFieldContainer)}>
              <Field
                component={Radio}
                placeholder={t`offer.mode`}
                label={t`offer.mode`}
                name="mode"
                options={Object.values(offerMode)}
                getOptionLabel={(option: string, value?: string): JSX.Element => (
                  <button type="button" className={cx(styles.radioBtn, { [styles.active]: value === option })}>
                    {t({ id: transOffer.mode[option] })}
                  </button>
                )}
                violations={violations}
              />
            </div>
          </div>
          <div className="formGroup formInlineGroup">
            <div className={cx("field inline", styles.radioInlineFieldContainer)}>
              <Field
                name="isMarketplace"
                label={t`offer.isMarketplace`}
                component={Radio}
                violation={violations}
                options={[
                  { label: "oui", value: true },
                  { label: "non", value: false },
                ]}
                getOptionValue={(option: { label: string; value: boolean }) => option.value}
                getOptionLabel={(option: { label: string; value: boolean }) => (
                  <button
                    type="button"
                    className={cx(
                      styles.availabilityCta,
                      globalStyles.cta,
                      globalStyles.primaryCta,
                      globalStyles.inverted,
                      { [globalStyles.active]: values.isMarketplace?.toString() === option.value.toString() },
                    )}
                  >
                    {option.label}
                  </button>
                )}
              />
            </div>
          </div>
        </div>
        <div className="formPart">
          <h2 className="formPartTitle">{t`offer.offerTalentView.description.head`}</h2>
          <div className="formGroup formTextAreaGroup">
            <Field
              component={RichTextEditor}
              label={t`offer.offerTalentView.context`}
              maxLength={480}
              name="offerTalentView.context"
              placeholder={t`offer.offerTalentView.context.placeholder`}
              replaceIfEmpty={false}
              uniqId={uniqId ? `${uniqId}-offerTalentView.context` : null}
              violations={violations}
            />
            <Field
              component={RichTextEditor}
              label={t`offer.offerTalentView.description`}
              maxLength={1000}
              name="offerTalentView.description"
              placeholder={t`offer.offerTalentView.description.placeholder`}
              replaceIfEmpty={false}
              uniqId={uniqId ? `${uniqId}-offerTalentView.description` : null}
              violations={violations}
            />
            <Field
              component={RichTextEditor}
              label={t`offer.offerTalentView.expectedProfile`}
              name="offerTalentView.expectedProfile"
              replaceIfEmpty={false}
              uniqId={uniqId ? `${uniqId}-offerTalentView.expectedProfile` : null}
              violations={violations}
            />
            <Field
              component={RichTextEditor}
              label={t`offer.offerTalentView.requirements`}
              name="offerTalentView.requirements"
              replaceIfEmpty={false}
              uniqId={uniqId ? `${uniqId}-offerTalentView.requirements` : null}
              violations={violations}
            />
            <Field
              component={RichTextEditor}
              label={t`offer.offerTalentView.howToShine`}
              name="offerTalentView.howToShine"
              placeholder={t`offer.offerTalentView.howToShine.placeholder`}
              replaceIfEmpty={false}
              uniqId={uniqId ? `${uniqId}-offerTalentView.howToShine` : null}
              violations={violations}
            />
            <Field
              component={RichTextEditor}
              label={t`offer.offerTalentView.toLearn`}
              name="offerTalentView.toLearn"
              placeholder={t`offer.offerTalentView.toLearn.placeholder`}
              replaceIfEmpty={false}
              uniqId={uniqId ? `${uniqId}-offerTalentView.toLearn` : null}
              violations={violations}
            />
            <Field
              component={RichTextEditor}
              label={t`offer.offerTalentView.team`}
              maxLength={220}
              name="offerTalentView.team"
              placeholder={t`offer.offerTalentView.team.placeholder`}
              replaceIfEmpty={false}
              uniqId={uniqId ? `${uniqId}-offerTalentView.team` : null}
              violations={violations}
            />
          </div>
        </div>
        <div className="formPart">
          <h2 className="formPartTitle">{t`offer.pitch.head`}</h2>
          <div className="formGroup formTextAreaGroup">
            <Field
              component={RichTextEditor}
              label={t`offer.offerInternalView.expectedProfile`}
              name="offerInternalView.expectedProfile"
              placeholder={t`offer.offerInternalTalentView.expectedProfile.placeholder`}
              replaceIfEmpty={false}
              uniqId={uniqId ? `${uniqId}-offerInternalView.expectedProfile` : null}
              violations={violations}
            />
            <Field
              component={RichTextEditor}
              label={t`offer.offerInternalView.outstandingAspects`}
              name="offerInternalView.outstandingAspects"
              placeholder={t`offer.offerInternalTalentView.outstandingAspects.placeholder`}
              replaceIfEmpty={false}
              uniqId={uniqId ? `${uniqId}-offerInternalView.outstandingAspects` : null}
              violations={violations}
            />
          </div>
        </div>
        <div className="formPart">
          <h2 className="formPartTitle">{t`offer.contacts.head`}</h2>
          <div className="formGroup">
            <Field
              component={Select}
              getOptionLabel={(coreUser: CoreUser): string => `${coreUser.firstname} ${coreUser.lastname}`}
              getOptionValue={(coreUser: CoreUser): string => coreUser["@id"]}
              isLoading={isLoading([GetCoreUsers, GetCurrentUser], apiErrors, apiPendingRequests, apiSuccess)}
              isMulti={true}
              isSearchable={true}
              label={t`offer.ownerAM`}
              name="owners"
              placeholder={t`offer.ownerAM.placeholder`}
              options={coreUsers}
              violations={violations}
            />
          </div>
          <div className="formGroup">
            <Field
              component={Select}
              getOptionLabel={(coreUser: CoreUser): string => `${coreUser.firstname} ${coreUser.lastname}`}
              getOptionValue={(coreUser: CoreUser): string => coreUser["@id"]}
              isLoading={isLoading([GetCoreUsers, GetCurrentUser], apiErrors, apiPendingRequests, apiSuccess)}
              isMulti={false}
              isSearchable={true}
              label={t`offer.closingParty`}
              name="closingParty"
              placeholder={t`offer.closingParty.placeholder`}
              options={
                coreUsers
                && coreUsers.filter(
                  (item: CoreUser) =>
                    (item.roles.includes(coreUserRoles.am) || item.roles.includes(coreUserRoles.nb)) && item.enabled,
                )
              }
              violations={violations}
            />
          </div>
          <div className="formGroup">
            <div className="field">
              <label>{t`offer.contacts`}</label>
              <Field component={Contacts} name="contacts" violations={violations} />
            </div>
          </div>
        </div>
        <div className="formGroup formInlineGroup">
          <div className={cx("field inline", styles.radioInlineFieldContainer)}>
            <Field
              component={Radio}
              placeholder={t`offer.formule`}
              label={t`offer.formule`}
              name="formule"
              options={Object.values(offerFormule)}
              getOptionLabel={(option: string, value?: string): JSX.Element => (
                <button type="button" className={cx(styles.radioBtn, { [styles.active]: value === option })}>
                  {t({ id: transOffer.formule[option] })}
                </button>
              )}
              violations={violations}
            />
          </div>
          <div>
            <Field
              component={Input}
              type="number"
              placeholder={t`offer.setup.placeholder`}
              label={t`offer.setup.label`}
              name="setUp"
              violations={violations}
            />
          </div>
          <div>
            <Field
              component={Input}
              type="number"
              placeholder={t`offer.commission.placeholder`}
              label={t`offer.commission.label`}
              name="commission"
              violations={violations}
            />
          </div>
        </div>
        <div className="formPart">
          <h2 className="formPartTitle">{t`offer.matchingProfile.head`}</h2>
          <div className="formGroup formInlineGroup">
            <div>
              <Field
                label={t`offer.salaries`}
                component={Checkbox}
                getOptionLabel={(value: SalaryExpectations): string => value.value}
                isLoading={
                  apiPendingRequests.length > 0
                  && apiPendingRequests.some((e: ApiRequest) => GetSalaryExpectations.REQUEST === e.type)
                }
                name="salary"
                options={salaryExpectations ?? []}
                violations={violations}
              />
            </div>
            <div>
              <Field
                label={t`offer.experienceYears`}
                component={Checkbox}
                getOptionLabel={(value: ExperienceYears): string => value.value}
                isLoading={
                  apiPendingRequests.length > 0
                  && apiPendingRequests.some((e: ApiRequest) => GetExperienceYears.REQUEST === e.type)
                }
                name="experienceYears"
                options={experienceYears ?? []}
                violations={violations}
              />
            </div>
          </div>
          <div className="formGroup">
            <Field
              name="stapleLocation"
              component={Select}
              getOptionLabel={(place: DesiredPlace): string => place.value}
              getOptionValue={(place: DesiredPlace): string => place["@id"]}
              isLoading={isLoading([GetDesiredPlaces], apiErrors, apiPendingRequests, apiSuccess)}
              isMulti={false}
              isSearchable={true}
              label={t`offer.stapleLocation`}
              options={desiredPlaces}
              placeholder={t`offer.stapleLocation.placeholder`}
              violations={violations}
            />
          </div>
          <div className="formGroup">
            <Field
              name="offerLocations"
              component={Select}
              getOptionLabel={(place: DesiredPlace): string => place.value}
              getOptionValue={(place: DesiredPlace): string => place["@id"]}
              isLoading={isLoading([GetDesiredPlaces], apiErrors, apiPendingRequests, apiSuccess)}
              isMulti
              isSearchable
              label={t`offer.offerLocations`}
              options={desiredPlaces}
              placeholder={t`offer.offerLocations.placeholder`}
              violations={violations}
            />
          </div>
          <div className="formGroup">
            <Field
              name="remotePreferences"
              component={Select}
              getOptionLabel={(place: DesiredPlace): string => place.value}
              getOptionValue={(place: DesiredPlace): string => place["@id"]}
              isLoading={isLoading([GetDesiredPlaces], apiErrors, apiPendingRequests, apiSuccess)}
              isMulti
              label={t`offer.remotePreferences`}
              options={remotePreferences}
              placeholder={t`offer.remotePreferences.placeholder`}
              violations={violations}
            />
          </div>
          <div className="formGroup">
            <Field
              component={Select}
              getOptionLabel={(resp: Responsibility): string => resp.value}
              getOptionValue={(resp: Responsibility): string => resp["@id"]}
              isClearable={false}
              isLoading={isLoading([GetResponsibilities], apiErrors, apiPendingRequests, apiSuccess)}
              isMulti={false}
              isSearchable={true}
              label={t`offer.responsibility`}
              name="coreResponsibility"
              options={sortedResponsibilities}
              placeholder={t`offer.responsibility.placeholder`}
              parse={(cr: Responsibility): Responsibility => {
                setCoreResponsibility(cr);
                return cr;
              }}
              violations={violations}
            />
          </div>
          <div className="formGroup">
            <Field
              component={Select}
              getOptionLabel={(resp: Responsibility): string => resp.value}
              getOptionValue={(resp: Responsibility): string => resp["@id"]}
              isClearable={true}
              isLoading={isLoading([GetResponsibilities], apiErrors, apiPendingRequests, apiSuccess)}
              isMulti={true}
              isSearchable={true}
              label={t`offer.responsibilities`}
              name="responsibilities"
              options={sortedResponsibilities}
              placeholder={t`offer.responsibilities.placeholder`}
              format={(options: Responsibility[]): Responsibility[] =>
                options.map((e) => ({
                  ...e,
                  isFixed: values && values.coreResponsibility && values.coreResponsibility["@id"] === e["@id"],
                }))
              }
              violations={violations}
            />
          </div>
          <div className="formGroup">
            <div className={cx("field inline", styles.radioInlineFieldContainer)}>
              <Field
                component={Radio}
                label={t`offer.level`}
                name="level"
                placeholder={t`offer.level.placeholder`}
                options={Object.values(offerLevel)}
                getOptionLabel={(option: string, value?: string): JSX.Element => (
                  <button type="button" className={cx(styles.radioBtn, { [styles.active]: value === option })}>
                    {t({ id: transOffer.level[option] })}
                  </button>
                )}
                violations={violations}
              />
            </div>
          </div>
          <div className="formGroup">
            <div className={cx("field inline", styles.radioInlineFieldContainer)}>
              <Field
                component={Radio}
                label={t`offer.tier`}
                name="tier"
                options={tierOptions}
                getOptionLabel={(option: string, value?: string): JSX.Element => (
                  <button type="button" className={cx(styles.radioBtn, { [styles.active]: value === option })}>
                    {t({ id: transOffer.tier[option] })}
                  </button>
                )}
                violations={violations}
              />
            </div>
          </div>
          <div className="formGroup">
            <div className={cx("field inline", styles.radioInlineFieldContainer)}>
              <Field
                component={Radio}
                label={t`offer.status`}
                name="status"
                options={Object.values(offerStatuses)}
                getOptionLabel={(option: string, value?: string): JSX.Element => (
                  <button type="button" className={cx(styles.radioBtn, { [styles.active]: value === option })}>
                    {t({ id: transOffer.status[option] })}
                  </button>
                )}
                violations={violations}
              />
            </div>
          </div>
        </div>
        <div className="formBtnContainer">
          <div className="cancelBtncontainer">
            <Button as={Link} content={t`Constant.cancel`} icon="times" negative to={routes.offers.search} />
          </div>
          <div className="validateBtnsContainer">
            <Button
              content={t`OfferForm.fields.submit`}
              icon="save outline"
              onClick={(): void => {
                setFormSubmission("toOffers");
              }}
            />
            <Button
              content={t`OfferForm.fields.OfferAdviceForm`}
              icon="arrow right"
              labelPosition="right"
              onClick={(): void => {
                setFormSubmission("advice");
              }}
              primary
            />
          </div>
        </div>
      </Form>
      <MainFormModal
        onClose={(): void => setModalIsVisible(false)}
        onProceed={modalOnProceed}
        open={modalIsVisible}
        size="large"
        values={values}
      />
    </>
  );
};
