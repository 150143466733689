import { t } from "@lingui/macro";
import * as React from "react";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";
import LoaderComp from "../../../components/Utils/Loader";
import { State } from "../../../interfaces/state";
import styles from "./index.module.scss";

export const Recap: React.FC = () => {
  const company = useSelector((state: State) => state.company);

  return !!!company ? (
    <LoaderComp />
  ) : (
    <div className={styles.recapWrapper}>
      <div className={styles.container}>
        <div className={styles.part}>
          <div className={styles.partTitle}>{t`company.foundersBackground`}</div>
          <div className={styles.partText}>
            <ReactMarkdown>{company.foundersBackground}</ReactMarkdown>
          </div>
        </div>
        <div className={styles.part}>
          <div className={styles.partTitle}>{t`company.description`}</div>
          <div className={styles.partText}>
            <ReactMarkdown>{company.description}</ReactMarkdown>
          </div>
        </div>
        <div className={styles.part}>
          <div className={styles.partTitle}>{t`company.specificities`}</div>
          <div className={styles.partText}>
            <ReactMarkdown>{company.specificities}</ReactMarkdown>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.partMainTitle}>{t`company.keyFigures.head`}</div>
        <div className={styles.part}>
          <div className={styles.partTitle}>{t`company.fundsRaisedAmount`}</div>
          <div className={styles.partText}>{company.fundsRaisedAmount || <span>&mdash;</span>}</div>
        </div>
        <div className={styles.part}>
          <div className={styles.partTitle}>{t`company.employeesNumber`}</div>
          <div className={styles.partText}>{company.employeesNumber || <span>&mdash;</span>}</div>
        </div>
        <div className={styles.part}>
          <div className={styles.partTitle}>{t`company.employeesAverageAge`}</div>
          <div className={styles.partText}>{company.employeesAverageAge || <span>&mdash;</span>}</div>
        </div>
      </div>
    </div>
  );
};
