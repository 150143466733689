import { t } from "@lingui/macro";
import * as React from "react";
import { Link } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";
import { OfferFilterHandler } from "../../components/Filters/OfferFilterHandler";
import { OfferList } from "../../components/Offers/OfferList";

import { routes } from "../../routes";

import "../../styles/search.scss";

export const OfferSearchScene: React.FC = (): JSX.Element => (
  <div className="searchContainer">
    <div className="searchTitle">
      {t`OfferSearchScene.title`}
      <Button className="primaryButton" floated="right" as={Link} to={routes.offers.new}>
        <Icon name="add square" />
        {t`OfferSearchScene.offers.add`}
      </Button>
    </div>
    <div className="contentContainer">
      <div className="searchFilters">
        <OfferFilterHandler />
      </div>
      <div className="searchResults">
        <OfferList />
      </div>
    </div>
  </div>
);
