import { AxiosResponse } from "axios";
import { FilterContext } from "../../../interfaces/api/filter";
import { HydraCollection } from "../../../interfaces/api/hydra-collection";
import { Contact } from "../../../interfaces/resources/contact";
import { ExperienceYears } from "../../../interfaces/resources/experienceYears";
import { Offer, OfferLocation, OfferSummary } from "../../../interfaces/resources/offer";
import { Responsibility } from "../../../interfaces/resources/responsibility";
import { SalaryExpectations } from "../../../interfaces/resources/salaryExpectations";
import { Talent } from "../../../interfaces/resources/talent";
import client from "../client";
import { generateFilterQuery, getCollectionStringValue, getStringValue } from "../helpers";

function joinOfferRoles(
  coreResponsibility: Responsibility | undefined,
  responsibilities: Responsibility[],
): Responsibility[] {
  return coreResponsibility
    && !responsibilities.some((responsibility: Responsibility) => responsibility["@id"] === coreResponsibility["@id"])
    ? [coreResponsibility, ...responsibilities]
    : responsibilities;
}

function formatOfferData(req: Partial<Offer>) {
  if (req.applications) {
    delete req.applications;
  }
  const stapleLocation = getStringValue(req.stapleLocation, "@id");
  const offerLocations = [...(req.offerLocations ?? []), stapleLocation].reduce(
    (arr: string[], currentValue: OfferLocation | string | undefined) => {
      const locationIri = getStringValue(currentValue, "@id");
      if (locationIri && !arr.includes(locationIri)) {
        return [...arr, locationIri];
      }
      return arr;
    },
    [],
  );

  return {
    ...req,
    closingParty: getStringValue(req.closingParty, "@id"),
    commission: req.commission,
    company: getStringValue(req.company, "@id"),
    contacts: req.contacts ? req.contacts.map((elem: Contact) => getStringValue(elem, "@id")) : undefined,
    coreResponsibility: getStringValue(req.coreResponsibility, "@id"),
    discardedTalents: req.discardedTalents ? req.discardedTalents.map((item: Talent) => item["@id"]) : undefined,
    experienceYears: req.experienceYears
      ? req.experienceYears.map((elem: ExperienceYears) => getStringValue(elem, "@id"))
      : undefined,
    formule: req.formule,
    level: req.level,
    offerLocations: offerLocations.length > 0 ? offerLocations : undefined,
    owners: getCollectionStringValue(req.owners, "@id"),
    responsibilities: req.responsibilities
      ? joinOfferRoles(req.coreResponsibility, req.responsibilities).map((elem: any) => getStringValue(elem, "@id"))
      : undefined,
    salary: req.salary ? req.salary.map((elem: SalaryExpectations) => getStringValue(elem, "@id")) : undefined,
    setUp: req.setUp,
    stapleLocation,
    status: getStringValue(req.status, "value"),
  };
}

export function getOffers(filterContext: FilterContext): Promise<AxiosResponse<HydraCollection<Offer>>> {
  const query = encodeURI(generateFilterQuery(filterContext));
  return client.get<HydraCollection<Offer>>(`/api/v2/offers${query}`);
}

export function getTalentOffers(talentIri: string): Promise<AxiosResponse<HydraCollection<Offer>>> {
  return client.get<HydraCollection<Offer>>(`${talentIri}/offers`);
}

export function getOffer(iri: string): Promise<AxiosResponse<Offer>> {
  return client.get<Offer>(iri);
}

export function getOffersSummary() {
  return client.get<HydraCollection<OfferSummary>>("/api/v2/offers?groups[]=internal:read:offer:only-names");
}

export function createOffer(offer: Partial<Offer>) {
  return client.post("/api/v2/offers", formatOfferData(offer));
}

export function editOffer(offer: Partial<Offer>, validation?: string) {
  if (offer && offer["@id"]) {
    return client.put(`${offer["@id"]}${validation ? `/${validation}` : ""}`, formatOfferData(offer));
  }
  return;
}

export function sendShortlist(values: any) {
  return client.post(`${values.offer}/shortlist`, {
    ...values,
    primaryRecipients: values.primaryRecipients && values.primaryRecipients.map((e: any) => e.value).join("|"),
    secondaryRecipients: values.secondaryRecipients && values.secondaryRecipients.map((e: any) => e.value).join("|"),
  });
}

export function deleteOffer(iri: string) {
  return client.delete(iri);
}
