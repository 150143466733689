import { t } from "@lingui/macro";
import cx from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { PageWithTabs, TabPanel, TabTrigger } from "../../components/Tabs";
import { State } from "../../interfaces/state";
import { ApplicationsDashboard } from "../../pages/ApplicationsDashboardPage";
import { hashes } from "../../routes";
import { Contacts } from "./Contacts";
import styles from "./index.module.scss";
import { Recap } from "./Recap";

interface TabProps {
  slug: string;
  tabName: React.ReactNode;
  component?: JSX.Element | null;
}

export const OfferDetails: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const offerState = useSelector((state: State) => state.offer);
  const tabHashes = React.useRef(hashes.offerDetails).current;
  const locationActivePanel = !!location.hash ? location.hash : tabHashes.recap;
  const [activePanel, setActivePanel] = React.useState(locationActivePanel);
  const [tabs, setTabs] = React.useState<TabProps[]>([]);
  const addHashToUrl = (h: string): void => {
    if (location.hash !== h) {
      navigate(h);
    }
  };

  React.useEffect(() => {
    const hash = !!location.hash
      ? [hashes.dashboard.inProcess, hashes.dashboard.preProcess].includes(location.hash)
        ? tabHashes.manageApplications
        : location.hash
      : tabHashes.recap;

    setActivePanel(hash);
  }, [location, tabHashes]);

  React.useEffect(() => {
    setTabs([
      {
        tabName: t`OfferDetails.tabs.general`,
        slug: tabHashes.recap,
        component: <Recap />,
      },
      {
        tabName: t`OfferDetails.tabs.managedApplications`,
        slug: tabHashes.manageApplications,
        component: offerState && <ApplicationsDashboard mode="offer" />,
      },
      {
        tabName: t`OfferDetails.tabs.contacts`,
        slug: tabHashes.contacts,
        component: <Contacts />,
      },
    ]);
  }, [tabHashes]);
  return (
    <PageWithTabs defaultActivePanel={activePanel} defaultActiveClass={styles.activeTab}>
      <div className={styles.container}>
        <div className={styles.tabTriggerListContainer}>
          <ul className={styles.tabTriggerList} data-testid="tabtrigger-list">
            {tabs.map((tab) => {
              return (
                <li key={`talent-detail-tab--${tab.slug}`}>
                  <TabTrigger
                    tabKey={tab.slug}
                    onClick={(): void => {
                      addHashToUrl(tab.slug);
                    }}
                    addWrapper={false}
                  >
                    <div className={cx(styles.tabTriggerContent)} data-testid={`tab-${tab.slug}`}>
                      {tab.tabName}
                    </div>
                  </TabTrigger>
                </li>
              );
            })}
          </ul>
        </div>
        <div className={styles.pagePanelContainer}>
          {tabs.map((tab) => {
            return (
              <TabPanel key={`talent-detail-panel--${tab.slug}`} panelKey={tab.slug}>
                <div className={styles.tabPanelContainer}>{tab.component}</div>
              </TabPanel>
            );
          })}
        </div>
      </div>
    </PageWithTabs>
  );
};
