import { t } from "@lingui/macro";
import * as React from "react";
import { Tooltip } from "../../../components/Tooltip";
import { Label } from "../../labels";
import styles from "./index.module.scss";

interface Props {
  className?: string;
}

const TooltipContent: React.FC = () => {
  return (
    <div className={styles.tooltip}>
      <div>Offre marketplace</div>
      <div>Le client s&apos;occupe de la chasse, et de son pipe.</div>
    </div>
  );
};

export const OfferMarketplaceLabel: React.FC<Props> = (props: Props) => {
  const { className } = props;
  return (
    <Tooltip content={<TooltipContent />}>
      <Label type="purple" content={t`Constant.marketplace`} className={!!className ? className : undefined} />
    </Tooltip>
  );
};
