import { t } from "@lingui/macro";
import cx from "classnames";
import { Field, Formik, Form } from "formik";
import MultiRangeSlider from "multi-range-slider-react";
import React, { FC, useEffect, useState } from "react";
import InputRange from "react-input-range";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { GetDesiredPlaces } from "../../../../../actionTypes/desiredPlaces";
import { GetResponsibilities } from "../../../../../actionTypes/responsibilities";
import { EditTalentProfile } from "../../../../../actionTypes/talentProfile";

import Yup from "../../../../../components/App/Yup";
import { Select } from "../../../../../components/Form/Formik/Select";
import { ModalWrapper } from "../../../../../components/ModalWrapper";
import { ResponsibilitySelect } from "../../../../../components/ResponsibilitySelect";
import { useApiSelector, useGetResourceHook, usePrevious } from "../../../../../components/Utils/hook";
import LoaderComp from "../../../../../components/Utils/Loader";
import { Violation } from "../../../../../interfaces/api/violation";
import { DesiredPlace, desiredPlacesRemote } from "../../../../../interfaces/resources/desiredPlace";
import { ExperienceYears } from "../../../../../interfaces/resources/experienceYears";
import {
  formatResponsibilities,
  Responsibility,
  ResponsibilityWithSeniority,
} from "../../../../../interfaces/resources/responsibility";
import { SalaryExpectations } from "../../../../../interfaces/resources/salaryExpectations";
import { TalentProfile } from "../../../../../interfaces/resources/talentProfile";
import { State } from "../../../../../interfaces/state";
import { getDesiredPlacesAction } from "../../../../../services/api/desiredPlaces/actions";
import { extractIdFromIri } from "../../../../../services/api/helpers";
import { getResponsibilitiesAction } from "../../../../../services/api/responsibilities/actions";
import {
  createTalentProfileResponsibilityAction,
  deleteTalentProfileResponsibilityAction,
  editTalentProfileAction,
  editTalentProfileResponsibilityAction,
} from "../../../../../services/api/talent/talentProfile/actions";
import globalStyles from "../../../../../styles/global.module.scss";
import { getAddDeletePutTalentResponsibility } from "../../../../../utils/helpers";

import styles from "./index.module.scss";

interface Props {
  onCloseModal: () => void;
  showCancelCta?: boolean;
  talentProfile: TalentProfile;
}

interface EditTalentProfileValues {
  coreResponsibility: Responsibility;
  desiredPlaces: DesiredPlace[];
  experienceYears: ExperienceYears;
  remotePreferences: DesiredPlace[];
  salaryExpectations: SalaryExpectations[];
}

interface CtaProps {
  talentProfile: TalentProfile;
}

export const EditCandidateProfileModalCta: FC<CtaProps> = (props: CtaProps) => {
  const { talentProfile } = props;
  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => {
    setOpenModal(false);
  };
  return (
    <>
      <button
        className={cx(globalStyles.cta, globalStyles.primaryCta)}
        onClick={() => {
          setOpenModal(true);
        }}
      >
        {t`CandidateProfileCard.cta.text`}
      </button>
      <ModalWrapper open={openModal} onCloseModal={closeModal}>
        <EditCandidateProfileModal talentProfile={talentProfile} onCloseModal={closeModal} />
      </ModalWrapper>
    </>
  );
};

export const EditCandidateProfileModal: FC<Props> = (props: Props) => {
  const { onCloseModal, showCancelCta = true, talentProfile } = props;
  const { apiErrors, apiSuccess, apiPendingRequests } = useApiSelector();
  const desiredPlacesState = useSelector((state: State) => state.desiredPlaces);
  const desiredPlaces = desiredPlacesState.filter((e) => !desiredPlacesRemote.includes(e.value));
  const remotePreferences = desiredPlacesState.filter((e) => desiredPlacesRemote.includes(e.value));
  const responsibilities = useSelector((state: State) => state.responsibilities);
  const dispatch = useDispatch();
  const [error, setError] = useState<boolean | string>(false);
  const [errors, setErrors] = useState<Violation[]>([]);
  const [success, setSuccess] = useState(false);
  const [notChanged, setNotChanged] = useState(false);
  const [selected, setSelected] = React.useState<ResponsibilityWithSeniority[] | null>(null);
  const [expValue, setExpValue] = React.useState<number>(talentProfile?.experience ? talentProfile.experience : 0);
  const [defaultMinSlryValue, setDefaultMinSlryValue] = React.useState<number>(30);
  const [defaultMaxSlryValue, setDefaultMaxSlryValue] = React.useState<number>(53);
  const [minSlryValue, setMinSlryValue] = React.useState<number | undefined>(undefined);
  const [maxSlryValue, setMaxSlryValue] = React.useState<number | undefined>(undefined);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const prevApiSuccess = usePrevious(apiSuccess);
  const prevApiErrors = usePrevious(apiErrors);
  const prevTalentProfile = usePrevious(talentProfile);
  const editTalentProfile = (tp: Partial<TalentProfile>) => dispatch(editTalentProfileAction(tp));
  const validationSchema = Yup.object().shape({
    desiredPlaces: Yup.array()
      .of(
        Yup.object()
          .shape({
            "@id": Yup.string(),
            value: Yup.string(),
          })
          .required("required"),
      )
      .nullable()
      .required(),
    coreResponsibilities: Yup.object()
      .shape({
        "@id": Yup.string(),
        value: Yup.string(),
      })
      .nullable()
      .required(),
  });
  const initialValues = {
    coreResponsibility: talentProfile.coreResponsibility,
    desiredPlaces: talentProfile.desiredPlaces.filter((e) => !desiredPlacesRemote.includes(e.value)) || [],
    experienceYears: talentProfile.experienceYears,
    remotePreferences: talentProfile.desiredPlaces.filter((e) => desiredPlacesRemote.includes(e.value)) || [],
    salaryExpectations: talentProfile.salaryExpectations,
  };

  const getResponsibilities = (): void => {
    dispatch(getResponsibilitiesAction());
  };
  const getDesiredPlaces = (): void => {
    dispatch(getDesiredPlacesAction());
  };
  const handleSelectedRespChange = (result: ResponsibilityWithSeniority[]): void => setSelected(result);

  const onSubmit = (values: EditTalentProfileValues) => {
    const { respsToAdd, respsToDelete, respsToPut } = getAddDeletePutTalentResponsibility(talentProfile, selected);
    const sortByIri = (a: any, b: any) => {
      const aId = extractIdFromIri(a["@id"]);
      const bId = extractIdFromIri(b["@id"]);

      return aId - bId;
    };
    const vals = {
      ...values,
      desiredPlaces: values.desiredPlaces.sort(sortByIri),
      remotePreferences: (values as any).remotePreferences.sort(sortByIri),
    } as any;
    const dPlaces = [...vals.desiredPlaces, ...vals.remotePreferences];
    const valuesNotChanged = JSON.stringify(vals) === JSON.stringify(initialValues);
    const salaryExpNotChanged =
      talentProfile.expectedRemuneration?.fixedSalary === minSlryValue
      && talentProfile.expectedRemuneration?.fullPackage === maxSlryValue;
    const responsiblitiesNotChanged = !respsToAdd.length && !respsToDelete.length && !respsToPut.length;
    const experienceNotChanged = talentProfile.experience === expValue;

    // On fait un call seulement si les données sont différentes
    if (valuesNotChanged && salaryExpNotChanged && responsiblitiesNotChanged && experienceNotChanged) {
      setNotChanged(true);
      setTimeout(onCloseModal, 500);
      return;
    }

    if (talentProfile) {
      if (respsToAdd.length) {
        respsToAdd.forEach((resp) => dispatch(createTalentProfileResponsibilityAction(resp, talentProfile["@id"])));
      }

      if (respsToDelete.length) {
        respsToDelete.forEach((resp) => dispatch(deleteTalentProfileResponsibilityAction(resp)));
      }

      if (respsToPut.length) {
        respsToPut.forEach((resp) => dispatch(editTalentProfileResponsibilityAction(resp)));
      }
    }

    editTalentProfile({
      "@id": talentProfile["@id"],
      desiredPlaces: dPlaces,
      coreResponsibility: values.coreResponsibility,
      experience: expValue,
      expectedRemuneration: {
        fixedSalary: minSlryValue ? minSlryValue : defaultMinSlryValue,
        fullPackage: maxSlryValue ? maxSlryValue : defaultMaxSlryValue,
      },
    });
  };

  useGetResourceHook(apiErrors, apiPendingRequests, apiSuccess, GetDesiredPlaces, desiredPlaces, getDesiredPlaces);

  useGetResourceHook(
    apiErrors,
    apiPendingRequests,
    apiSuccess,
    GetResponsibilities,
    responsibilities,
    getResponsibilities,
  );

  useEffect(() => {
    if (prevTalentProfile?.["@id"] !== talentProfile["@id"]) {
      const talentResp: ResponsibilityWithSeniority[] = [...talentProfile.responsibilities].map((r) => ({
        ...r.responsibility,
        seniority: r.seniority,
      }));
      setSelected(talentResp);

      const expRem = talentProfile?.expectedRemuneration;
      if (expRem?.fixedSalary && expRem?.fullPackage) {
        setDefaultMinSlryValue(expRem.fixedSalary);
        setDefaultMaxSlryValue(expRem.fullPackage);
      }
    }
  }, [talentProfile, prevTalentProfile]);

  React.useEffect(() => {
    const senioritiesMissing = !!selected && selected.some((s) => !!!s.seniority);
    setIsDisabled(!!!selected?.length || senioritiesMissing);
  }, [selected]);

  useEffect(() => {
    if (
      prevApiSuccess
      && apiSuccess
      && prevApiSuccess.length !== apiSuccess.length
      && apiSuccess.some((e) => EditTalentProfile.SUCCESS === e.type)
    ) {
      setError(false);
      setErrors([]);
      setSuccess(true);
      setTimeout(onCloseModal, 500);
    }
  }, [apiSuccess, prevApiSuccess, onCloseModal, setError, setErrors, setSuccess]);

  useEffect(() => {
    if (
      prevApiErrors
      && apiErrors
      && prevApiErrors.length !== apiErrors.length
      && apiErrors.some((e) => EditTalentProfile.FAILURE === e.type)
    ) {
      const apiError = apiErrors.find((e) => EditTalentProfile.FAILURE === e.type);
      if (apiError) {
        setErrors(apiError.payload.violations || []);
        setError(apiError.payload.message);
        setSuccess(false);
      }
    }
  }, [apiErrors, prevApiErrors, setError, setErrors, setSuccess]);

  return (
    <div className={styles.modal}>
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <h1 className={globalStyles.mainTitle}>
            <ReactMarkdown
              components={{
                p: React.Fragment,
              }}
            >
              {t`EditCandidateProfileModal.title`}
            </ReactMarkdown>
          </h1>
        </div>
        {talentProfile && selected !== null ? (
          <Formik
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            initialValues={initialValues}
            enableReinitialize
          >
            {({ isSubmitting }): JSX.Element => (
              <>
                <Form className="form">
                  <div className="formGroup">
                    <Field
                      name="coreResponsibility"
                      component={Select}
                      isMulti={false}
                      isSearchable={true}
                      label={t`CandidateProfileCard.coreResponsibility.label`}
                      placeholder={t`talentProfile.responsibilities.placeholder`}
                      getOptionLabel={(option: Responsibility): string => option.value}
                      getOptionValue={(option: Responsibility): string => option["@id"]}
                      isLoading={apiPendingRequests.some((item) => GetResponsibilities.REQUEST === item.type)}
                      options={formatResponsibilities(responsibilities)}
                    />
                  </div>
                  <div className="formGroup">
                    <div>
                      <label className="formLabel">Niveau d&apos;expérience totale</label>
                    </div>
                    <div className={styles.inputRangeContainer}>
                      <InputRange
                        maxValue={16}
                        minValue={0}
                        step={1}
                        formatLabel={(value): string => {
                          return value === 0
                            ? "< 1 an"
                            : value === 1
                            ? `${value} an`
                            : value === 16
                            ? "+15 ans"
                            : `${value} ans`;
                        }}
                        value={expValue}
                        onChange={(val): void => {
                          setExpValue(val as number);
                        }}
                      />
                    </div>
                  </div>
                  <div className="formGroup">
                    <div>
                      <label className="formLabel">Salaire souhaité</label>
                    </div>
                    <div className={styles.inputRangeContainer}>
                      <MultiRangeSlider
                        min={0}
                        max={150}
                        step={1}
                        minValue={defaultMinSlryValue}
                        maxValue={defaultMaxSlryValue}
                        labels={["0k", "150k"]}
                        ruler={false}
                        onChange={(e): void => {
                          setMinSlryValue(e.minValue);
                          setMaxSlryValue(e.maxValue);
                        }}
                      />
                    </div>
                  </div>
                  <div className="formGroup">
                    <Field
                      name="desiredPlaces"
                      component={Select}
                      getOptionLabel={(item: DesiredPlace): string => item.value}
                      getOptionValue={(item: DesiredPlace): string => item["@id"]}
                      isLoading={apiPendingRequests.some((e) => e.type === GetDesiredPlaces.REQUEST)}
                      isSearchable={true}
                      isClearable={false}
                      isMulti={true}
                      label={t`CandidateProfileCard.desiredPlaces.label`}
                      options={desiredPlaces}
                      violation={errors}
                    />
                  </div>
                  <div className="formGroup">
                    <Field
                      name="remotePreferences"
                      component={Select}
                      getOptionLabel={(item: DesiredPlace): string => item.value}
                      getOptionValue={(item: DesiredPlace): string => item["@id"]}
                      isLoading={apiPendingRequests.some((e) => e.type === GetDesiredPlaces.REQUEST)}
                      isSearchable={true}
                      isClearable={false}
                      isMulti={true}
                      label={t`CandidateProfileCard.remotePreferences.label`}
                      options={remotePreferences}
                      violation={errors}
                    />
                  </div>
                  {responsibilities.length > 0 ? (
                    <div className="formGroup">
                      <ResponsibilitySelect
                        onChange={handleSelectedRespChange}
                        options={responsibilities}
                        value={selected}
                      />
                    </div>
                  ) : null}
                  {success || error || notChanged ? (
                    <div className={styles.messagesContainer}>
                      {notChanged ? (
                        <div className={cx(styles.message, styles.successMessage)}>
                          <h4>{t`EditCandidateProfileModal.notChanged.header`}</h4>
                          <p>{t`EditCandidateProfileModal.notChanged.content`}</p>
                        </div>
                      ) : null}
                      {success ? (
                        <div className={cx(styles.message, styles.successMessage)}>
                          <h4>{t`EditCandidateProfileModal.success.header`}</h4>
                          <p>{t`EditCandidateProfileModal.success.content`}</p>
                        </div>
                      ) : null}
                      {error ? (
                        <div className={cx(styles.message, styles.errorMessage)}>
                          <h4>{t`EditCandidateProfileModal.error.header`}</h4>
                          <p>
                            {t`EditCandidateProfileModal.error.content`} {error}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  <div className={styles.formBtnContainer}>
                    {showCancelCta ? (
                      <div>
                        <button
                          type="button"
                          className={cx(globalStyles.cta, globalStyles.darkCta, globalStyles.inverted)}
                          onClick={onCloseModal}
                        >
                          {t`Constant.cancel`}
                        </button>
                      </div>
                    ) : null}
                    <div className={styles.submitCtaContainer}>
                      <button
                        className={cx(globalStyles.cta, globalStyles.primaryCta)}
                        type="submit"
                        disabled={isSubmitting || isDisabled}
                      >
                        {t`EditCandidateProfileModal.cta.submit`}
                      </button>
                    </div>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        ) : (
          <>
            <LoaderComp />
          </>
        )}
      </div>
    </div>
  );
};
