import { t } from "@lingui/macro";
import React, { FC, MouseEvent } from "react";
import ReactMarkdown from "react-markdown";
import { Button, Card, Divider, Header, Icon, Label, List, Message, Modal, ModalProps } from "semantic-ui-react";
import { InterviewStep } from "../../../../../interfaces/resources/interviewStep";
import { OfferTalentView } from "../../../../../interfaces/resources/offer";
import transInterviewStepType from "../../../../../translations/constants/interviewStepType";

interface FormValues {
  interviewSteps?: InterviewStep[];
  offerTalentView?: OfferTalentView;
}

interface Props extends Pick<ModalProps, "open" | "size" | "onClose"> {
  formValues: FormValues;
  onProceed?: () => void;
}

const AdviceFormModal: FC<Props> = (props: Props) => {
  const { open, size, onClose, onProceed, formValues } = props;
  const { interviewSteps, offerTalentView } = formValues;

  function handleClose(e: MouseEvent<HTMLElement>) {
    if (onClose) {
      onClose(e, {});
    }
  }

  function handleProceed(e: MouseEvent<HTMLElement>) {
    if (onProceed) {
      onProceed();
      if (onClose) {
        onClose(e, {});
      }
    }
  }

  function renderActions() {
    return (
      <Modal.Actions>
        <Button negative onClick={(e) => handleClose(e)} content={t`Constant.cancel`} icon="times" />
        <Button primary onClick={(e) => handleProceed(e)} icon="checkmark" content={t`OfferForm.fields.continue`} />
      </Modal.Actions>
    );
  }

  function renderInterviewSteps() {
    const iSteps = interviewSteps;
    if (!iSteps || iSteps.length === 0) {
      return <Header as={"h3"}>Nothing to see here</Header>;
    }

    return (
      <List>
        {iSteps.map((step, i) => (
          <List.Item key={step.title + i.toString()}>
            <Card fluid>
              <Card.Content>
                <Card.Header>{step.title}</Card.Header>
                <Label attached="top right" content={(i + 1).toString()} color="orange" />
                <Card.Meta></Card.Meta>
                <Card.Description>
                  <ReactMarkdown>{step.description}</ReactMarkdown>
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <Label.Group>
                  {step.interviewStepTypes.map((type, j) => (
                    <Label
                      key={type.label + j.toString()}
                      color="blue"
                      content={t({ id: transInterviewStepType.label[type.label] })}
                    />
                  ))}
                </Label.Group>
              </Card.Content>
            </Card>
          </List.Item>
        ))}
      </List>
    );
  }

  return (
    <Modal open={open} size={size} dimmer="blurring" onClose={onClose} className="mainFormModal">
      <Modal.Header>{t`AdviceForm.title`}</Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          {renderInterviewSteps()}
          <Divider />
          {offerTalentView && offerTalentView.elinoiAdvice && (
            <Message icon info>
              <Icon name="info circle" />
              <Message.Content>
                <Message.Header content={t`offer.elinoiAdvice`} />
                <ReactMarkdown>{offerTalentView.elinoiAdvice}</ReactMarkdown>
              </Message.Content>
            </Message>
          )}
        </Modal.Description>
      </Modal.Content>
      {renderActions()}
    </Modal>
  );
};

export default AdviceFormModal;
