import { t } from "@lingui/macro";
import { Field, Formik } from "formik";

import React, { FC, MouseEvent } from "react";
import { Button, Form, Modal, ModalProps } from "semantic-ui-react";
import { string } from "yup";
import { getUserImage } from "../../../../components/App/Image";

import Yup from "../../../../components/App/Yup";
import RoundImage from "../../../../components/Design/RoundImage";
import Input from "../../../../components/Form/Formik/Input";
import Radio from "../../../../components/Form/Formik/Radio";
import { RefusedReason } from "../../../../interfaces/resources/application";
import { Talent as TalentProps } from "../../../../interfaces/resources/talent";
import { ColumnProps } from "../../../../pages/ApplicationsDashboardPage/Column/data";
import transApplication from "../../../../translations/constants/application";

import "../RefuseOptionsModal/index.scss";

interface Props extends Pick<ModalProps, "open" | "onClose" | "size"> {
  talent: TalentProps;
  onProceed(reason: RefusedReason, status: any): void;
  column?: ColumnProps;
}

export const RefuseTextFieldModal: FC<Props> = (props: Props) => {
  const refusedValidationSchema = Yup.object().shape({
    refusedReason: string().required(),
    refusedStatus: string().required(),
  });

  const { open, size, talent, onClose, onProceed, column } = props;

  function handleClose(e: MouseEvent<HTMLElement>): void {
    if (onClose) {
      onClose(e, {});
    }
  }

  function handleProceed(values: any): void {
    onProceed(values.refusedReason, values.refusedStatus);
  }

  function renderHeader(): JSX.Element {
    return <Modal.Header>{t`RefuseApplication.title`}</Modal.Header>;
  }

  return (
    <Modal open={open} size={size} onClose={onClose} className="refuseApplication">
      {renderHeader()}
      <div className="content">
        <div className="profile">
          <RoundImage size="40px" {...getUserImage(talent.image)} />
          <p>
            {talent.firstname} {talent.lastname}
          </p>
        </div>
        <Formik
          onSubmit={(values: any): any => {
            handleProceed(values);
          }}
          validationSchema={refusedValidationSchema}
          initialValues={{
            refusedReason: "",
            refusedStatus: "",
          }}
        >
          {({ handleSubmit }): JSX.Element => (
            <>
              {column ? (
                <Form onSubmit={handleSubmit}>
                  <div className="field inline">
                    <Field
                      component={Radio}
                      name="refusedStatus"
                      options={column.refuseOptions}
                      getOptionLabel={(option: string, value?: string): JSX.Element => (
                        <Button className={value === option ? "active" : ""}>
                          {t({ id: transApplication.status[option] })}
                        </Button>
                      )}
                      getOptionValue={(option: string): string => option}
                    />
                  </div>
                  <Field component={Input} label={t`RefuseApplication.title`} name="refusedReason" />
                  <div className="actions">
                    <Button negative type="button" content={t`Constant.cancel`} onClick={handleClose} icon="times" />
                    <Button primary icon="checkmark" content={t`TalentEditionForm.submit`} type="submit" />
                  </div>
                </Form>
              ) : (
                <div style={{ color: "red" }}>Missing column</div>
              )}
            </>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
