import { connect } from "react-redux";
import { getFormValues } from "redux-form";

import { OFFER_ADVICE_FORM } from "../../../../../components/Offers/OfferForm";

import AdviceFormModal from "../../../../../components/Offers/OfferForm/AdviceForm/AdviceFormModal/AdviceFormModal";
import { Offer } from "../../../../../interfaces/resources/offer";

const mapStateToProps = (state: any) => {
  const offer: Offer | null = state.offer;

  return {
    formValues: getFormValues(OFFER_ADVICE_FORM)(state) as unknown as any,
    initialValues: {
      ...(offer || {}),
    },
  };
};

export default connect(mapStateToProps)(AdviceFormModal);
