import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";

import { GetCalendlyEvents } from "../../../actionTypes/calendlyEvents";
import { CalendlyEvent } from "../../../interfaces/resources/calendlyEvent";
import { apiFailureAction } from "../actions";
import { getCalendlyEventsActionSuccessAction } from "./actions";
import * as apiClient from "./api";

export function* getCalendlyEvents(action: AnyAction) {
  try {
    const response: AxiosResponse<{
      "hydra:member": CalendlyEvent[];
    }> = yield call(apiClient.getCalendlyEvents, action.talent);
    yield put(getCalendlyEventsActionSuccessAction(response.data["hydra:member"]));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export default function* calendlyEventSaga() {
  yield takeLatest(GetCalendlyEvents.REQUEST, getCalendlyEvents);
}
