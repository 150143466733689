import { Contact } from "./contact";
import { Contract } from "./contract";
import { CoreUser } from "./coreUser";
import { Offer } from "./offer";
import { Responsibility } from "./responsibility";
import { Sector } from "./sector";

export interface Company {
  "@context": string;
  "@id": string;
  "@type": string;
  closingParty?: CoreUser;
  companyImages: string[];
  competitors?: any;
  contacts: Contact[];
  contract: Contract | null;
  createdAt: string;
  creationDate: string;
  description: string;
  employeesAverageAge: number;
  employeesNumber: number;
  foundersBackground: string;
  fundraisingStage: string;
  fundsRaisedAmount: number;
  hasActiveOffers: boolean;
  image: {
    url: string;
  };
  keyValues: string;
  name: string;
  note?: string | null;
  offers: Offer[];
  owner: CoreUser;
  plannedHires: number;
  potentialJobs: Responsibility[];
  recommendation?: string;
  searchUrgency: string;
  searchStage: string;
  message: string;
  issues: string;
  sectors: Sector[];
  slug: string;
  specificities: string;
  status: string;
  turnover: string;
  updatedAt: string;
  website: string;
}

export interface CompanyImage {
  "@context": string;
  "@id": string;
  "@type": string;
  company: string;
  image: {
    url: string;
  };
}

export const companyStatuses = {
  called: "called",
  closed: "closed",
  contractSent: "contract_sent",
  hunted: "hunted",
  incomingLead: "incoming_lead",
  introducedTo: "introduced_to",
  physicalMeeting: "physical_meeting",
  refused: "refused",
  community: "community",
};
