import cx from "classnames";
import React from "react";
import { useInstantSearch } from "react-instantsearch-hooks-web";
import { CreateApplication, EditApplication } from "../../actionTypes/applications";
import { EditOffer } from "../../actionTypes/offers";
import globalStyles from "../../styles/global.module.scss";
import { useApiSelector, usePrevious } from "../Utils/hook";

export const Refresh = () => {
  const { apiSuccess } = useApiSelector();
  const { refresh } = useInstantSearch();
  const refreshTimer = React.useRef<NodeJS.Timeout | null>(null);
  const prevApiSuccess = usePrevious(apiSuccess);

  React.useEffect(() => {
    const apiSuccessIntersection = [...apiSuccess].slice(prevApiSuccess?.length);
    if (
      apiSuccessIntersection.some((action) =>
        [CreateApplication.SUCCESS, EditApplication.SUCCESS, EditOffer.SUCCESS].includes(action.type),
      )
    ) {
      refreshTimer.current = setTimeout(() => {
        refresh();
      }, 1000);
    }
  }, [apiSuccess, prevApiSuccess, refresh, refreshTimer]);

  React.useEffect(() => {
    return () => {
      if (refreshTimer.current !== null) clearTimeout(refreshTimer.current);
    };
  }, [refreshTimer]);

  return (
    <button
      className={cx(globalStyles.cta, globalStyles.aquaCta, globalStyles.inverted)}
      type="button"
      onClick={() => {
        refresh();
      }}
    >
      <svg width="1rem" height="1rem" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <title>Refresh the search results</title>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
        />
      </svg>
    </button>
  );
};
