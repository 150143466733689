import { ActionType } from "../interfaces/api/actionType";

export const CreateApplication: ActionType = {
  FAILURE: "CREATE_APPLICATION_FAILURE",
  REQUEST: "CREATE_APPLICATION_REQUEST",
  SUCCESS: "CREATE_APPLICATION_SUCCESS",
};

export const EditApplication: ActionType = {
  FAILURE: "EDIT_APPLICATION_FAILURE",
  REQUEST: "EDIT_APPLICATION_REQUEST",
  SUCCESS: "EDIT_APPLICATION_SUCCESS",
};

export const DeleteApplication: ActionType = {
  FAILURE: "DELETE_APPLICATION_FAILURE",
  REQUEST: "DELETE_APPLICATION_REQUEST",
  SUCCESS: "DELETE_APPLICATION_SUCCESS",
};

export const GetApplication: ActionType = {
  FAILURE: "GET_APPLICATION_FAILURE",
  REQUEST: "GET_APPLICATION_REQUEST",
  SUCCESS: "GET_APPLICATION_SUCCESS",
};

export const GetApplicationForCollection: ActionType = {
  FAILURE: "GET_APPLICATION_FOR_COLLECTION_FAILURE",
  REQUEST: "GET_APPLICATION_FOR_COLLECTION_REQUEST",
  SUCCESS: "GET_APPLICATION_FOR_COLLECTION_SUCCESS",
};

export const GetApplications: ActionType = {
  FAILURE: "GET_APPLICATIONS_FAILURE",
  REQUEST: "GET_APPLICATIONS_REQUEST",
  SUCCESS: "GET_APPLICATIONS_SUCCESS",
};

export const GetApplicationsAccepted: ActionType = {
  FAILURE: "GET_APPLICATIONS_ACCEPTED_FAILURE",
  REQUEST: "GET_APPLICATIONS_ACCEPTED_REQUEST",
  SUCCESS: "GET_APPLICATIONS_ACCEPTED_SUCCESS",
};

export const SortApplication: ActionType = {
  FAILURE: "SORT_APPLICATION_FAILURE",
  REQUEST: "SORT_APPLICATION_REQUEST",
  SUCCESS: "SORT_APPLICATION_SUCCESS",
};

export const PostApplicationMessage: ActionType = {
  FAILURE: "POST_APPLICATION_MESSAGE_FAILURE",
  REQUEST: "POST_APPLICATION_MESSAGE_REQUEST",
  SUCCESS: "POST_APPLICATION_MESSAGE_SUCCESS",
};

export const ReadApplicationMessages: ActionType = {
  FAILURE: "READ_APPLICATION_MESSAGES_FAILURE",
  REQUEST: "READ_APPLICATION_MESSAGES_REQUEST",
  SUCCESS: "READ_APPLICATION_MESSAGES_SUCCESS",
};

export const CreateApplicationAccepted: ActionType = {
  FAILURE: "CREATE_APPLICATION_ACCEPTED_FAILURE",
  REQUEST: "CREATE_APPLICATION_ACCEPTED_REQUEST",
  SUCCESS: "CREATE_APPLICATION_ACCEPTED_SUCCESS",
};

export const EditApplicationAccepted: ActionType = {
  FAILURE: "EDIT_APPLICATION_ACCEPTED_FAILURE",
  REQUEST: "EDIT_APPLICATION_ACCEPTED_REQUEST",
  SUCCESS: "EDIT_APPLICATION_ACCEPTED_SUCCESS",
};
