import { t } from "@lingui/macro";
import * as React from "react";
import Select from "react-select";
import { Accordion, Icon } from "semantic-ui-react";

import { Sector } from "../../../interfaces/resources/sector";

import { defaultStyles } from "../../Form/Select";

interface Props {
  activeIndexes?: number[];
  handleClick?: (e: any, titleProps: any) => void;
  index?: number;
  items: any;
  multiple?: boolean;
  onChange: (values: any) => void;
  selected: Sector[];
}

export const SectorFilter: React.FC<Props> = ({
  activeIndexes,
  handleClick,
  index,
  items,
  multiple,
  onChange,
}: Props) => {
  const active = activeIndexes ? activeIndexes.some((item: number) => item === index) : false;
  return (
    <div>
      <Accordion.Title active={active} index={index} onClick={handleClick}>
        <h3>
          {t`SectorFilter.title`}
          <Icon name={active ? "chevron up" : "chevron down"} />
        </h3>
      </Accordion.Title>
      <Accordion.Content active={active}>
        <Select
          getOptionLabel={(option: Sector): string => option.value}
          getOptionValue={(option: Sector): string => option["@id"]}
          isMulti={!!multiple}
          onChange={onChange}
          options={items}
          styles={defaultStyles}
        />
      </Accordion.Content>
    </div>
  );
};
