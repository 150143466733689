import { connect } from "react-redux";
import { Dispatch } from "redux";

import Profile from "../../../components/CoreUsers/Profile";

import { getCurrentUserAction, editCoreUserAction, getCoreUsersAction } from "../../../services/api/coreUser/actions";

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    apiErrors: state.apiErrors,
    apiPendingRequests: state.apiPendingRequests,
    apiSuccess: state.apiSuccess,
    currentUser: state.currentUser,
    initialValues: ownProps.state === "currentUser" ? state.currentUser : ownProps.coreUser,
    state: ownProps.state,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getCoreUsers: () => dispatch(getCoreUsersAction()),
    getCurrentUser: () => dispatch(getCurrentUserAction()),
    submitCoreUser: (req: any, form?: string, redirectOnSuccess?: boolean | string) =>
      dispatch(editCoreUserAction(req, form, redirectOnSuccess)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
