import { t } from "@lingui/macro";
import cx from "classnames";
import * as React from "react";
import { Draggable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { CardApplication } from "../../../blocks/cardApplication";
import { Application, applicationStatuses } from "../../../interfaces/resources/application";
import { coreUserRoles } from "../../../interfaces/resources/coreUser";
import { DashboardMode } from "../../../interfaces/resources/dashboard";
import { State } from "../../../interfaces/state";
import { extractPageFromIri } from "../../../services/api/helpers";
import { getOfferApplicationsAction } from "../../../services/api/offer/applications/actions";
import globalStyles from "../../../styles/global.module.scss";
import { GroupedApp } from "../Column";
import { ColumnProps } from "../Column/data";

import styles from "./index.module.scss";

interface Props {
  groupedApp: GroupedApp;
  setModalOpen: any;
  mode: DashboardMode | undefined;
  modalOpen: boolean;
  modalAppId: string;
  column: ColumnProps;
  editApplication: (iri: string, req: Partial<Application>) => void;
  isRefusedMode: boolean;
  onDateSelect: (date: Date, application: Application) => void;
}

export const ApplicationsGroup: React.FC<Props> = (props: Props) => {
  const {
    column,
    groupedApp,
    mode,
    modalAppId,
    modalOpen,
    setModalOpen,
    onDateSelect,
    editApplication,
    isRefusedMode,
  } = props;
  const currentUser = useSelector((state: State) => state.currentUser);
  const dispatch = useDispatch();
  const [showList, setShowList] = React.useState(
    column.id !== "column-0" || isRefusedMode || mode !== "suivi" || !!currentUser?.roles.includes(coreUserRoles.tim),
  );
  const getOfferApplications = (group: GroupedApp) => {
    if (group.id) {
      dispatch(
        getOfferApplicationsAction(
          group.id,
          [
            {
              multiple: true,
              name: "status",
              value: [applicationStatuses.contacted, applicationStatuses.interesting],
            },
          ],
          true,
        ),
      );
    }
  };
  return (
    <div className={styles.applicationsGroup}>
      {groupedApp.name ? (
        <div className={styles.offerName}>
          {groupedApp.name} {typeof groupedApp.count === "number" ? `(${groupedApp.count})` : null}
        </div>
      ) : null}
      {column.id === "column-0" && !!groupedApp.count ? (
        <div className={styles.getAppsCtaContainer}>
          <button
            type="submit"
            className={cx(globalStyles.cta, globalStyles.primaryCta, globalStyles.smaller)}
            onClick={() => {
              if (!!!groupedApp.applications.length) {
                getOfferApplications(groupedApp);
                setShowList(true);
                return;
              }
              setShowList(!showList);
            }}
          >
            {!showList || !!!groupedApp.applications.length ? "Afficher" : "Cacher"}
          </button>
        </div>
      ) : null}
      {column.id === "column-3" ? (
        <div className={styles.getAppsCtaContainer}>
          <Link
            className={cx(styles.columnCta, globalStyles.cta, globalStyles.primaryCta)}
            to={extractPageFromIri("offers", groupedApp.applications[0].offer["@id"]) + "/shortlist"}
          >
            {t`ApplicationDashboard.Column.shortlist`}
          </Link>
        </div>
      ) : null}
      {(column.id === "column-0" && !!!groupedApp.applications.length && !!groupedApp.count) || !showList ? null : (
        <div>
          {groupedApp.applications.length > 0 ? (
            groupedApp.applications.map((application: Application, index: number) => {
              return (
                <Draggable
                  key={`draggable-card-${column.id}-${application["@id"]}-${index}`}
                  draggableId={application["@id"]}
                  index={index}
                  isDragDisabled={column.isDragDisabled || isRefusedMode}
                >
                  {(provided, snapshot): JSX.Element => (
                    <>
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={styles.cardContainer}
                      >
                        <CardApplication
                          setModalOpen={setModalOpen}
                          modalOpen={modalOpen}
                          modalAppId={modalAppId}
                          application={application}
                          column={column}
                          editApplication={editApplication}
                          isRefusedMode={isRefusedMode}
                          onDateSelect={onDateSelect}
                        />
                      </div>
                      {snapshot.isDragging && (
                        <>
                          <div className={cx(styles.cardContainer, styles.cardCloneContainer)}>
                            <CardApplication
                              setModalOpen={setModalOpen}
                              modalOpen={modalOpen}
                              modalAppId={modalAppId}
                              application={application}
                              column={column}
                              editApplication={editApplication}
                              isRefusedMode={isRefusedMode}
                              onDateSelect={onDateSelect}
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}
                </Draggable>
              );
            })
          ) : (
            <div className="emptyList">{t`ApplicationDashboard.Column.empty`}</div>
          )}
        </div>
      )}
    </div>
  );
};
