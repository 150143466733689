import { FilterContext } from "../../../interfaces/api/filter";
import { CoreUser } from "../../../interfaces/resources/coreUser";

import client from "../client";
import { generateFilterQuery } from "../helpers";

export function getCurrentUser() {
  return client.get("/api/v2/core_users/current");
}

export function getCoreUser(iri: string) {
  return client.get(iri);
}

export function getCoreUsers() {
  return client.get("/api/v2/core_users");
}

export function deleteCoreUser(iri: string) {
  return client.delete(iri);
}

export function createCoreUser(coreUser: CoreUser) {
  return client.post("/api/v2/core_users", coreUser);
}

export function editCoreUser(coreUser: Partial<CoreUser>) {
  if (coreUser && coreUser["@id"]) {
    return client.put(coreUser["@id"], {
      ...coreUser,
    });
  }
  return;
}

export function getCoreUserApplications(coreUser: CoreUser, filterContext?: FilterContext, page?: number) {
  let query = "";
  if (filterContext) {
    query = generateFilterQuery(
      page ? [...filterContext, { multiple: false, name: "page", value: page }] : filterContext,
    );
  } else {
    query = page ? `?page=${page}` : "";
  }
  return client.get(`${coreUser["@id"]}/applications${query}`);
}
