import { AnyAction } from "redux";
import {
  CreateQualification,
  CreateReactivation,
  DeleteTalent,
  EditQualification,
  EditTalent,
  FilterTalentsByName,
  GetTalent,
  GetTalentForCollection,
  GetTalents,
  SortTalent,
  clearTalentsState,
} from "../../../actionTypes/talents";

import { FilterContext } from "../../../interfaces/api/filter";
import { Talent, QualificationFormProps } from "../../../interfaces/resources/talent";
import { getSuccessType } from "../helpers";

export function createQualificationAction(req: QualificationFormProps, form?: string) {
  return {
    form,
    req,
    type: CreateQualification.DEFAULT,
  };
}

export function createReactivationAction(req: QualificationFormProps, form?: string) {
  return {
    form,
    req,
    type: CreateReactivation.DEFAULT,
  };
}

export function deleteTalentAction(iri: string) {
  return {
    iri,
    type: DeleteTalent.REQUEST,
  };
}

export function deleteTalentSuccessAction(iri: string) {
  return {
    iri,
    type: DeleteTalent.SUCCESS,
  };
}

export function editQualificationAction(req: QualificationFormProps, form?: string) {
  return {
    form,
    req,
    type: EditQualification.DEFAULT,
  };
}

export function editTalentAction(iri: string, req: any, form?: string, validation?: string) {
  return {
    form,
    iri,
    req,
    type: EditTalent.REQUEST,
    validation,
  };
}

export function editTalentSuccessAction(talent: Talent, form?: string) {
  return {
    form,
    talent,
    type: EditTalent.SUCCESS,
  };
}

export function filterTalentsByNameAction(text: string) {
  return {
    text,
    type: FilterTalentsByName.REQUEST,
  };
}

export function filterTalentsByNameSuccessAction(talentNames: Talent[]) {
  return {
    talentNames,
    type: FilterTalentsByName.SUCCESS,
  };
}

export function getTalentAction(iri: string) {
  return {
    iri,
    type: GetTalent.REQUEST,
  };
}

export function getTalentSuccessAction(talent: Talent, action: AnyAction) {
  return {
    iri: talent["@id"],
    talent,
    type: getSuccessType(action),
  };
}

export function getTalentForCollectionAction(iri: string) {
  return {
    iri,
    type: GetTalentForCollection.REQUEST,
  };
}

export function getTalentsAction(filterContext: FilterContext, summaryOnly = false) {
  return {
    filterContext,
    summaryOnly,
    type: GetTalents.REQUEST,
  };
}

export function getTalentsSuccessAction(talents: Talent[], hydraView?: any, hydraTotalItems?: number) {
  return {
    hydraTotalItems,
    hydraView,
    talents,
    type: GetTalents.SUCCESS,
  };
}

export function sortTalentAction(iri: string, req: { status: string }, validation?: string) {
  return {
    iri,
    req,
    type: SortTalent.REQUEST,
    validation,
  };
}

export function sortTalentSuccessAction(talent: Talent, validation?: string) {
  return {
    talent,
    validation,
    type: SortTalent.SUCCESS,
  };
}

export function clearTalentsStateAction() {
  return {
    type: clearTalentsState.DEFAULT,
  };
}
