import { t } from "@lingui/macro";
import cx from "classnames";
import * as React from "react";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";
import LoaderComp from "../../../components/Utils/Loader";
import { State } from "../../../interfaces/state";
import styles from "./index.module.scss";

export const Recap: React.FC = () => {
  const offer = useSelector((state: State) => state.offer);
  const offerInternalView = useSelector((state: State) => state.offerInternalView);
  const offerTalentView = useSelector((state: State) => state.offerTalentView);

  return !!!offer ? (
    <LoaderComp />
  ) : (
    <div className={styles.recapWrapper}>
      <div className={cx(styles.container, styles.toKnowContainer)}>
        <div className={styles.header}>
          <div className={styles.titleContainer}>
            <h2 className={styles.title}>
              <ReactMarkdown
                components={{
                  p: React.Fragment,
                }}
              >
                {t`OfferRecap.toKnow.title`}
              </ReactMarkdown>
            </h2>
          </div>
        </div>
        <div className={styles.part}>
          <div className={styles.partTitle}>{t`offer.offerInternalView.expectedProfile`}</div>
          <div className={styles.partText}>
            {offerInternalView?.expectedProfile ? (
              <ReactMarkdown>{offerInternalView.expectedProfile}</ReactMarkdown>
            ) : (
              t`offer.offerInternalView.expectedProfile.empty`
            )}
          </div>
        </div>
        <div className={styles.part}>
          <div className={styles.partTitle}>{t`offer.offerInternalView.outstandingAspects`}</div>
          <div className={styles.partText}>
            {offerInternalView?.outstandingAspects ? (
              <ReactMarkdown>{offerInternalView.outstandingAspects}</ReactMarkdown>
            ) : (
              t`offer.offerInternalView.outstandingAspects.empty`
            )}
          </div>
        </div>
      </div>
      <div className={cx(styles.container, styles.descriptionContainer)}>
        <div className={styles.header}>
          <div className={styles.titleContainer}>
            <h2 className={styles.title}>
              <ReactMarkdown
                components={{
                  p: React.Fragment,
                }}
              >
                {t`OfferRecap.description.title`}
              </ReactMarkdown>
            </h2>
          </div>
        </div>
        <div className={styles.part}>
          <div className={styles.partTitle}>{t`offer.offerTalentView.context`}</div>
          <div className={styles.partText}>
            {offerTalentView?.context ? (
              <ReactMarkdown>{offerTalentView.context}</ReactMarkdown>
            ) : (
              t`offer.offerTalentView.context.empty`
            )}
          </div>
        </div>
        <div className={styles.part}>
          <div className={styles.partTitle}>{t`offer.offerTalentView.description`}</div>
          <div className={styles.partText}>
            {offerTalentView?.description ? (
              <ReactMarkdown>{offerTalentView.description}</ReactMarkdown>
            ) : (
              t`offer.offerTalentView.description.empty`
            )}
          </div>
        </div>
        <div className={styles.part}>
          <div className={styles.partTitle}>{t`offer.offerTalentView.expectedProfile`}</div>
          <div className={styles.partText}>
            {offerTalentView?.expectedProfile ? (
              <ReactMarkdown>{offerTalentView.expectedProfile}</ReactMarkdown>
            ) : (
              t`offer.offerTalentView.expectedProfile.empty`
            )}
          </div>
        </div>
        <div className={styles.part}>
          <div className={styles.partTitle}>{t`offer.offerTalentView.requirements`}</div>
          <div className={styles.partText}>
            {offerTalentView?.requirements ? (
              <ReactMarkdown>{offerTalentView.requirements}</ReactMarkdown>
            ) : (
              t`offer.offerTalentView.requirements.empty`
            )}
          </div>
        </div>
        <div className={styles.part}>
          <div className={styles.partTitle}>{t`offer.offerTalentView.howToShine`}</div>
          <div className={styles.partText}>
            {offerTalentView?.howToShine ? (
              <ReactMarkdown>{offerTalentView.howToShine}</ReactMarkdown>
            ) : (
              t`offer.offerTalentView.howToShine.empty`
            )}
          </div>
        </div>
        <div className={styles.part}>
          <div className={styles.partTitle}>{t`offer.offerTalentView.toLearn`}</div>
          <div className={styles.partText}>
            {offerTalentView?.toLearn ? (
              <ReactMarkdown>{offerTalentView.toLearn}</ReactMarkdown>
            ) : (
              t`offer.offerTalentView.toLearn.empty`
            )}
          </div>
        </div>
        <div className={styles.part}>
          <div className={styles.partTitle}>{t`offer.offerTalentView.team`}</div>
          <div className={styles.partText}>
            {offerTalentView?.team ? (
              <ReactMarkdown>{offerTalentView.team}</ReactMarkdown>
            ) : (
              t`offer.offerTalentView.team.empty`
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
