import { FilterContext } from "../../../interfaces/api/filter";
import { Contact } from "../../../interfaces/resources/contact";
import client from "../client";
import { generateFilterQuery } from "../helpers";

export function createContact(req: Partial<Contact>) {
  return client.post("/api/v2/contacts", req);
}

export function getContact(filterContext: FilterContext = []) {
  const query = encodeURI(generateFilterQuery(filterContext));
  return client.get(`/api/v2/contacts${query}`);
}

export function editContact(iri: string, contact: Partial<Contact>) {
  return client.put(iri, contact);
}

export function deleteContact(iri: string) {
  return client.delete(iri);
}

export function sendFirstClientContactEmail(iri: string) {
  return client.post(`${iri}/firstClientContactEmail`, {});
}
