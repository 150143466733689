import { Formik, FormikConfig } from "formik";
import React, { FC } from "react";
import { Form } from "semantic-ui-react";
import { PhoneCall, phoneCallTypes } from "../../../../interfaces/resources/phoneCall";
import { Talent } from "../../../../interfaces/resources/talent";
import { PhoneCallFormValues } from "./helpers";
import PhoneCallSchema from "./PhoneCallForm.validation";
import PhoneCallFormFields from "./PhoneCallFormFields";

import "./index.scss";

interface Props {
  readOnly: boolean;
  talent: Talent;
  onSubmit(values: Partial<PhoneCall>): void;
}

export const PhoneCallForm: FC<Props> = ({ talent, onSubmit, readOnly }: Props) => {
  const formProps: FormikConfig<PhoneCallFormValues> = {
    initialValues: {
      applicationsToConfirm: [],
      applicationsToPitch: [],
      applicationsToSubmit: [],
      notes: "",
      title: "",
      type: phoneCallTypes.filter_spontaneous_application,
    },
    onSubmit: (values, { resetForm, setSubmitting }) => {
      setSubmitting(true);
      onSubmit(values);
      setTimeout(() => {
        setSubmitting(false);
        resetForm();
      }, 1500);
    },
    validationSchema: PhoneCallSchema,
  };

  return (
    <Formik {...formProps}>
      {(): JSX.Element => (
        <>
          <Form className="phoneCallFormContainer">
            <PhoneCallFormFields talent={talent} readOnly={readOnly} />
          </Form>
        </>
      )}
    </Formik>
  );
};
