import { t } from "@lingui/macro";
import * as React from "react";
import ReactMarkdown from "react-markdown";
import { Talent } from "../../../../interfaces/resources/talent";

import { HistoryItem } from "./HistoryItem";
import styles from "./index.module.scss";

interface Props {
  talent: Talent | null;
}

export interface EventProps {
  label: string;
  date: string;
  type: "segment" | "status";
}

export const TalentHistory: React.FC<Props> = (props) => {
  const { talent } = props;
  const [statusEvents, setStatusEvents] = React.useState<EventProps[]>([]);
  const [segmentEvents, setSegmentEvents] = React.useState<EventProps[]>([]);

  React.useEffect(() => {
    if (!talent) {
      return;
    }

    const statusEventsArr: EventProps[] = [];
    const segmentEventsArr: EventProps[] = [];
    talent.talentStatusEvents.forEach((tse) => {
      statusEventsArr.push({
        label: tse.status,
        date: tse.createdAt,
        type: "status",
      });
    });
    talent.talentSegmentEvents?.forEach((seg) => {
      segmentEventsArr.push({
        label: seg.segment,
        date: seg.createdAt,
        type: "segment",
      });
    });

    setStatusEvents(statusEventsArr);
    setSegmentEvents(segmentEventsArr);
  }, [talent]);

  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        <h2 className={styles.title}>
          <ReactMarkdown
            components={{
              p: React.Fragment,
            }}
          >{t`TalentHistory.title`}</ReactMarkdown>
        </h2>
      </div>
      <div className={styles.cardBody}>
        <ul className={styles.eventList}>
          {statusEvents.map((ev) => {
            return (
              <li key={`${ev.date}-${ev.label}`} className={styles.eventItem}>
                <HistoryItem event={ev} />
              </li>
            );
          })}
        </ul>
        <ul className={styles.eventList}>
          {segmentEvents.map((ev) => {
            return (
              <li key={`${ev.date}-${ev.label}`} className={styles.eventItem}>
                <HistoryItem event={ev} />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
