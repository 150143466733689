import { t } from "@lingui/macro";
import { Field, Formik, FormikHelpers } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Form, Icon, Message } from "semantic-ui-react";

import Yup from "../../../components/App/Yup";
import { DatePicker } from "../../../components/Form/Formik/DatePicker";
import Input from "../../../components/Form/Formik/Input";
import Switch from "../../../components/Form/Formik/Switch";
import { useApiSelector, usePrevious } from "../../../components/Utils/hook";
import { ApiError } from "../../../interfaces/api/error";
import { ApiRequest } from "../../../interfaces/api/request";
import { ApiSuccess } from "../../../interfaces/api/success";
import { Violation } from "../../../interfaces/api/violation";
import { Application } from "../../../interfaces/resources/application";
import { createApplicationAcceptedAction } from "../../../services/api/applications/actions";

import styles from "./index.module.scss";

interface FormProps {
  commission: number | undefined;
  fixedSalary: number | undefined;
  replacement: boolean | undefined;
  startDate: string | undefined;
  fullPackage: number | undefined;
}

interface Props {
  closeModal: () => void;
  application: Application;
}

const APPLICATION_ACCEPTED_FORM = "applicationAccepted";

const ApplicationAcceptedSchema = Yup.object().shape({
  commission: Yup.string().required(),
  fixedSalary: Yup.string().required(),
  replacement: Yup.string().required(),
  startDate: Yup.string().required(),
  fullPackage: Yup.string().required(),
});

export const ApplicationAcceptedForm: FC<Props> = ({ closeModal, application }: Props) => {
  const dispatch = useDispatch();

  const { apiErrors, apiPendingRequests, apiSuccess } = useApiSelector();
  const [error, setError] = useState<boolean | string>(false);
  const [errors, setErrors] = useState<Violation[]>([]);
  const [success, setSuccess] = useState(false);
  const prevApiSuccess = usePrevious(apiSuccess);
  const prevApiErrors = usePrevious(apiErrors);

  useEffect(() => {
    if (
      prevApiSuccess
      && !prevApiSuccess.some((e: ApiSuccess) => APPLICATION_ACCEPTED_FORM === e.form)
      && apiSuccess
      && apiSuccess.some((e: ApiSuccess) => APPLICATION_ACCEPTED_FORM === e.form)
    ) {
      setError(false);
      setErrors([]);
      setSuccess(true);
      setTimeout(closeModal, 1000);
    }
  }, [apiSuccess, prevApiSuccess, closeModal, setError, setErrors, setSuccess]);

  useEffect(() => {
    if (
      (!prevApiErrors || !prevApiErrors.some((e: ApiError) => APPLICATION_ACCEPTED_FORM === e.form))
      && apiErrors
      && apiErrors.some((e: ApiError) => APPLICATION_ACCEPTED_FORM === e.form)
    ) {
      const apiError = apiErrors.find((e: ApiError) => APPLICATION_ACCEPTED_FORM === e.form);
      if (apiError) {
        setErrors(apiError.payload.violations || []);
        setError(apiError.payload.message);
        setSuccess(false);
      }
    }
  }, [apiErrors, prevApiErrors, setError, setErrors, setSuccess]);

  return (
    <div>
      <h1 className={styles.formTitle}>
        <Icon.Group size="large">
          <Icon name="tag" size="small" />
        </Icon.Group>
        {t`ApplicationAcceptedForm.title`}
      </h1>
      <Formik
        onSubmit={(values, { setSubmitting }: FormikHelpers<FormProps>): void => {
          dispatch(
            createApplicationAcceptedAction(
              {
                application: application,
                talent: application.talent,
                offer: application.offer,
                coreUsers: application.offer.owners,
                tim: application.talent.talentManager,
                ...values,
              },
              APPLICATION_ACCEPTED_FORM,
            ),
          );
          setTimeout(() => setSubmitting(false), 500);
        }}
        validationSchema={ApplicationAcceptedSchema}
        initialValues={{
          commission: application.applicationAccepted?.commission
            ? application.applicationAccepted.commission
            : undefined,
          fixedSalary: application.applicationAccepted?.fixedSalary
            ? application.applicationAccepted.fixedSalary
            : undefined,
          replacement: application.applicationAccepted?.replacement
            ? application.applicationAccepted.replacement
            : false,
          startDate: application.applicationAccepted?.startDate ? application.applicationAccepted.startDate : undefined,
          fullPackage: application.applicationAccepted?.fullPackage
            ? application.applicationAccepted.fullPackage
            : undefined,
        }}
      >
        {({ handleSubmit, isSubmitting }): JSX.Element => (
          <Form
            onSubmit={handleSubmit}
            loading={apiPendingRequests.some((e: ApiRequest) => APPLICATION_ACCEPTED_FORM === e.form)}
            success={success}
            error={!!error || errors.length > 0}
          >
            <Message
              success
              header={t`PropsTalentEditionFormProps.success.header`}
              content={t`PropsTalentEditionFormProps.success.content`}
              icon="check circle"
            />
            <Message
              error
              header={t`PropsTalentEditionFormProps.error.header`}
              content={
                <div>
                  {t`PropsTalentEditionFormProps.error.content`}: {error}
                </div>
              }
              icon="times circle"
            />
            <div className={styles.formGroup}>
              <Field
                component={Input}
                type="number"
                placeholder={t`talentAccepted.commission.placeholder`}
                label={t`talentAccepted.commission.label`}
                name="commission"
                violation={errors}
              />
            </div>
            <div className={styles.formGroup}>
              <Field
                component={Input}
                type="number"
                placeholder={t`talentAccepted.fixedSalary.placeholder`}
                label={t`talentAccepted.fixedSalary.label`}
                name="fixedSalary"
                violation={errors}
              />
            </div>
            <div className={styles.formGroup}>
              <Field
                component={Input}
                type="number"
                placeholder={t`talentAccepted.fullPackage.placeholder`}
                label={t`talentAccepted.fullPackage.label`}
                name="fullPackage"
                violation={errors}
              />
            </div>
            <div className={styles.formGroup}>
              <Field
                component={DatePicker}
                dateFormat="dd/MM/yyyy"
                label={t`talentAccepted.startDate.label`}
                name="startDate"
                violation={errors}
              />
            </div>
            <div className={styles.formGroup}>
              <Field
                coloroff="negative-red"
                coloron="positive-green"
                component={Switch}
                label={t`talentAccepted.replacement.label`}
                name="replacement"
                violation={errors}
              />
            </div>
            <div className={styles.formBtnContainer}>
              <Button primary content={t`ApplicationAcceptedForm.submit`} disabled={isSubmitting} type="submit" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
