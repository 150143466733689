import { t } from "@lingui/macro";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { GetOffer, GetOfferApplications } from "../../../actionTypes/offers";
import { GetPhoneCalls } from "../../../actionTypes/phoneCalls";
import { GetTalentCards } from "../../../actionTypes/talentCards";
import { GetTalentProfile } from "../../../actionTypes/talentProfile";
import { GetTalentQualification } from "../../../actionTypes/talentQualification";

import { OfferInfo } from "../../../blocks/offerInfo";
import { Application, applicationStatuses } from "../../../interfaces/resources/application";
import { CoreUser } from "../../../interfaces/resources/coreUser";
import { Offer } from "../../../interfaces/resources/offer";
import { PhoneCall } from "../../../interfaces/resources/phoneCall";
import { Qualification } from "../../../interfaces/resources/qualification";
import { Talent } from "../../../interfaces/resources/talent";
import { TalentProfile } from "../../../interfaces/resources/talentProfile";

import { getOfferAction, sendShortlistAction } from "../../../services/api/offer/actions";
import { getOfferApplicationsAction } from "../../../services/api/offer/applications/actions";
import { getPhoneCallsAction } from "../../../services/api/talent/phoneCalls/actions";
import { getTalentProfileAction } from "../../../services/api/talent/talentProfile/actions";
import { getTalentQualificationAction } from "../../../services/api/talent/talentQualification/actions";

import { useApiSelector, useGetResourceHook } from "../../Utils/hook";
import LoaderComp from "../../Utils/Loader";
import ShortlistForm from "./Form";
import "./index.scss";

interface Props {
  iri: string;
}

const Shortlist: React.FunctionComponent<Props> = ({ iri }: Props) => {
  const preShortlistStatuses = [applicationStatuses.awaitingShortlist, applicationStatuses.shortlistReady];
  const { apiErrors, apiPendingRequests, apiSuccess } = useApiSelector();
  const dispatch = useDispatch();
  const currentUser: CoreUser | null = useSelector((state: any) => state.currentUser);
  const offer: Offer | null = useSelector((state: any) => state.offer);
  const offerApplications: Application[] = useSelector((state: any) =>
    state.offerApplications.filter((application: Application) => preShortlistStatuses.includes(application.status)),
  );
  const qualifications: { [iri: string]: Qualification } = useSelector((state: any) => state.qualifications);
  const talentProfiles: { [iri: string]: TalentProfile } = useSelector((state: any) => state.talentProfiles);
  const phoneCalls: PhoneCall[] = useSelector((state: any) => state.shortlistPhoneCalls);

  const sendShortlist = (values: any, form: string) => {
    dispatch(sendShortlistAction(values, form));
  };

  const getOffer = (offerIri: string) => dispatch(getOfferAction(offerIri));
  useGetResourceHook(apiErrors, apiPendingRequests, apiSuccess, GetOffer, null, getOffer, [iri]);

  const getOfferApplications = (offerIri: string) =>
    dispatch(
      getOfferApplicationsAction(offerIri, [
        {
          multiple: true,
          name: "status",
          value: preShortlistStatuses,
        },
      ]),
    );
  useGetResourceHook(apiErrors, apiPendingRequests, apiSuccess, GetOfferApplications, [], getOfferApplications, [iri]);

  React.useEffect(() => {
    const getTalentProfile = (talentIri: string) => dispatch(getTalentProfileAction(talentIri));

    if (offerApplications.length > 0 && !apiPendingRequests.some((e) => e.type === GetTalentProfile.REQUEST)) {
      for (const application of offerApplications) {
        const talentProfileIri =
          typeof application.talent.talentProfile === "string"
            ? application.talent.talentProfile
            : application.talent.talentProfile["@id"];

        const talentProfile = talentProfiles[talentProfileIri];
        const isRequesting = apiPendingRequests.some(
          (req) => req.type === GetTalentProfile.REQUEST && req.talentProfile === talentProfileIri,
        );
        if (typeof talentProfileIri === "string" && !isRequesting && !talentProfile) {
          getTalentProfile(talentProfileIri);
        }
      }
    }
  }, [offerApplications, talentProfiles, apiPendingRequests, dispatch]);

  React.useEffect(() => {
    const getTalentQualification = (qualifIri: string) => dispatch(getTalentQualificationAction(qualifIri));
    const talentProfilesKeys = Object.keys(talentProfiles);

    if (
      talentProfilesKeys.length > 0
      && !apiPendingRequests.some((e) => [GetTalentProfile.REQUEST, GetTalentQualification.REQUEST].includes(e.type))
    ) {
      for (const key of talentProfilesKeys) {
        const qualificationIri = talentProfiles[key].lastQualification;
        const qualification = qualificationIri && qualifications[qualificationIri["@id"]];
        const isRequesting = apiPendingRequests.some(
          (req) =>
            req.type === GetTalentQualification.REQUEST
            && qualificationIri
            && req.qualificationIri === qualificationIri["@id"],
        );
        if (qualificationIri && typeof qualificationIri["@id"] === "string" && !isRequesting && !qualification) {
          getTalentQualification(qualificationIri["@id"]);
        }
      }
    }
  }, [talentProfiles, qualifications, apiPendingRequests, dispatch]);

  React.useEffect(() => {
    const talentsKeys: Talent[] = [];
    const getTalentPhoneCalls = (talentIri: string) => dispatch(getPhoneCallsAction(talentIri));

    offerApplications.forEach((item: Application) => {
      talentsKeys.push(item.talent);
    });

    for (const key of talentsKeys) {
      if (
        !phoneCalls.some((p) => {
          const pIri = typeof p.talent === "string" ? p.talent : p.talent?.["@id"];
          return pIri === key["@id"];
        })
        && !apiErrors.some((e) => e.type === GetPhoneCalls.FAILURE && e.iri === key["@id"])
        && !apiPendingRequests.some((e) => e.type === GetPhoneCalls.REQUEST && e.iri === key["@id"])
        && !apiSuccess.some((e) => e.type === GetPhoneCalls.SUCCESS && e.talentIri === key["@id"])
      ) {
        getTalentPhoneCalls(key["@id"]);
      }
    }
  }, [dispatch, apiErrors, offerApplications, apiPendingRequests, apiSuccess, phoneCalls]);
  const isLoading =
    apiPendingRequests.length > 0
    && apiPendingRequests.some((e: any) =>
      [GetOffer.REQUEST, GetOfferApplications.REQUEST, GetTalentCards.REQUEST].includes(e.type),
    );

  return isLoading ? (
    <LoaderComp />
  ) : offer && currentUser ? (
    <div className="shortlistContainer">
      <OfferInfo offer={offer} mode="minimal" />
      <ShortlistForm offer={offer} applications={offerApplications} sendShortlist={sendShortlist} />
    </div>
  ) : (
    <div>{t`offers.empty`}</div>
  );
};

export default Shortlist;
