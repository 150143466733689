import { connect } from "react-redux";
import { Dispatch } from "redux";

import { TalentPage } from "../../../pages/TalentPage";
import { deleteTalentAction, editTalentAction, getTalentAction } from "../../../services/api/talent/actions";

interface OwnProps {
  iri: string;
}

const mapStateToProps = (state: any, ownProps: OwnProps) => {
  return {
    apiErrors: state.apiErrors,
    apiPendingRequests: state.apiPendingRequests,
    apiSuccess: state.apiSuccess,
    iri: ownProps.iri,
    linkedInProfile: state.talentLinkedInProfile,
    talent: state.talent,
    talentProfile: state.talentProfile,
    talentQualifications: state.talentQualifications,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
  return {
    deleteTalent: () => dispatch(deleteTalentAction(ownProps.iri)),
    editTalent: (iri: string, req: any, form?: string) => dispatch(editTalentAction(iri, req, form || "")),
    getTalent: (iri: string) => dispatch(getTalentAction(iri)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TalentPage);
