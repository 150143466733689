import cx from "classnames";
import { FieldProps } from "formik";
import * as React from "react";
import { Switch as DesignSwitch } from "../../../Design/Switch";

interface SwitchProps extends FieldProps {
  className?: string;
  label?: string;
  coloron: string;
  coloroff?: string;
  setOptionValue?: (checked: boolean) => any;
}

const Switch = ({
  className,
  coloron,
  coloroff,
  setOptionValue,
  field,
  form: { setFieldValue },
  label,
  ...rest
}: SwitchProps): JSX.Element => {
  return (
    <div className={cx({ [className || ""]: !!className })}>
      {label ? <label className="formLabel">{label}</label> : null}
      <DesignSwitch
        {...rest}
        coloron={coloron}
        coloroff={coloroff}
        className={className}
        checked={!!field.value}
        onChange={(_e: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
          setFieldValue(field.name, setOptionValue ? setOptionValue(checked) : checked);
        }}
      />
    </div>
  );
};

export default Switch;
