import { format } from "date-fns";
import { FieldProps, useField } from "formik";
import * as React from "react";
import ReactDatePicker from "react-datepicker";
import { Form } from "semantic-ui-react";

import { Violation } from "../../../../interfaces/api/violation";
import { getViolationMessage } from "../../../Utils/hook";
import ErrorMessage from "../ErrorMessage";

interface DatePickerInputProps extends FieldProps {
  error?: string;
  label?: string;
  required?: boolean;
  violations?: Violation[];
}

export const DatePicker = ({
  field: { name },
  form,
  label,
  required,
  violations,
  ...rest
}: DatePickerInputProps): JSX.Element => {
  const [field, meta] = useField(name);
  const error = violations && getViolationMessage(name, violations);
  return (
    <Form.Field error={(meta.touched && !!meta.error) || !!error} name={name} required={required}>
      {label ? <label>{label}</label> : null}
      <ReactDatePicker
        {...field}
        {...rest}
        dateFormat="dd/MM/yyyy HH:mm"
        placeholderText="Sélectionner une date"
        locale="fr"
        onChange={(value: Date): void => form.setFieldValue(field.name, format(value, "yyyy-MM-dd HH:mm:ss"))}
      />
      <ErrorMessage name={name} message={error} />
    </Form.Field>
  );
};
