import { t } from "@lingui/macro";
import cx from "classnames";
import { differenceInCalendarDays, format, parseISO } from "date-fns";
import * as React from "react";
import { FiChevronDown } from "react-icons/fi";
import ReactMarkdown from "react-markdown";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ContactsList } from "../../components/Contacts/ContactsList";
import ResponsibilitiesCompact from "../../components/Responsibilities/ResponsibilitiesCompact";
import { CopyClip } from "../../components/Utils/CopyClip";
import { getTalentOfferUrl } from "../../components/Utils/helpers";
import LoaderComp from "../../components/Utils/Loader";
import { Offer, offerStatuses } from "../../interfaces/resources/offer";
import { extractPageFromIri } from "../../services/api/helpers";
import { editOfferAction } from "../../services/api/offer/actions";
import globalStyles from "../../styles/global.module.scss";
import transOffer from "../../translations/constants/offer";
import { CompanyImg } from "../companyImg";
import { Label } from "../labels";
import { OfferMarketplaceLabel } from "../labels/OfferMarketplaceLabel";
import { PersonProfileImg } from "../personProfileImg";
import { DeleteOfferModalCta } from "./DeleteOfferModal";

import styles from "./index.module.scss";

interface Props {
  offer: Offer;
  mode?: "minimal" | "complete";
}

export const OfferInfo: React.FunctionComponent<Props> = (props: Props) => {
  const { offer, mode = "complete" } = props;
  const dispatch = useDispatch();
  const [isOpenDropdown, setIsOpenDropdown] = React.useState(false);
  const [offerStatusDate, setOfferStatusDate] = React.useState<string>("");
  const [activeDays, setActiveDays] = React.useState<number>(0);
  const lateStade = [60, 90];
  const editOffer = (req: Partial<Offer>) => dispatch(editOfferAction(req, "status", "is_enabled"));
  const editOfferStatus = (status: string): void => {
    editOffer({
      "@id": offer["@id"],
      status,
    });
  };

  React.useEffect(() => {
    if (offer.offerStatusEvents) {
      offer.offerStatusEvents.forEach((ose) => {
        if (offerStatuses.activated === ose.status) {
          setActiveDays(differenceInCalendarDays(new Date(), parseISO(ose.createdAt)));
          setOfferStatusDate(
            t({
              id: "OfferInfo.activeStatusDays",
              values: {
                days: differenceInCalendarDays(new Date(), parseISO(ose.createdAt)),
              },
            }),
          );
          return false;
        }
        if (offerStatuses.desactivated === ose.status) {
          setOfferStatusDate(
            t({
              id: "OfferInfo.desactivatedStatusDate",
              values: {
                date: format(parseISO(ose.createdAt), "dd/MM/yyyy"),
              },
            }),
          );
          return false;
        }
      });
    }
  }, [offer]);

  return (
    <div className={styles.offerInfoContainer}>
      {!!!offer ? (
        <div className={styles.loaderCompContainer}>
          <LoaderComp />
        </div>
      ) : (
        <>
          <div className={styles.topContainer}>
            <div>
              {offer.owners && offer.owners.length > 0 ? (
                offer.owners.map((owner, index) => (
                  <>
                    <div key={index} className={styles.ownerImgContainer}>
                      <PersonProfileImg alt={owner.firstname} image={owner.image} />
                    </div>
                    <div className={styles.ownerName}>{owner.firstname}</div>
                  </>
                ))
              ) : (
                <div className={styles.text}>{t`Constant.noOwner`}</div>
              )}
            </div>
            <div className={styles.labelsContainer}>
              {offer.mode ? (
                <div>
                  <Label content={t({ id: transOffer.mode[offer.mode] })} />
                </div>
              ) : null}
              {offer.isMarketplace ? (
                <div>
                  <OfferMarketplaceLabel />
                </div>
              ) : null}
            </div>
            {mode === "complete" ? (
              <div>
                <div>
                  <Link
                    to={`${extractPageFromIri("offers", offer["@id"])}/edit`}
                    className={cx(styles.cta, globalStyles.cta, globalStyles.primaryCta)}
                  >
                    {t`Constant.update`}
                  </Link>
                </div>
                <div>
                  <DeleteOfferModalCta offer={offer} className={styles.cta} />
                </div>
              </div>
            ) : null}
          </div>
          <div className={styles.middleContainer}>
            <div className={styles.offerImgStatusContainer}>
              <div className={styles.offerImgContainer}>
                <CompanyImg src={offer.company.image?.url} />
              </div>
              <div>
                <div className={styles.statusDropdownContainer}>
                  <div
                    className={cx(
                      styles.statusDropdownCta,
                      {
                        [styles.isOpen]: isOpenDropdown,
                      },
                      styles[offer.status],
                    )}
                    onClick={() => {
                      setIsOpenDropdown(!isOpenDropdown);
                    }}
                  >
                    <span>{t({ id: transOffer.status[offer.status] })}</span>
                    <FiChevronDown />
                  </div>
                  {isOpenDropdown ? (
                    <div className={styles.statusDropdownListContainer}>
                      <ul className={styles.statusDropdownList}>
                        {Object.values(offerStatuses).map((stus, i) => {
                          const isDisabled = offer.status === stus;
                          return (
                            <li key={`dropdown-list-${stus}-${i}`}>
                              <div
                                className={cx(styles.statusDropdownItem, { [styles.disabled]: isDisabled })}
                                onClick={() => {
                                  if (!isDisabled) {
                                    editOfferStatus(stus);
                                    setIsOpenDropdown(false);
                                  }
                                }}
                              >
                                {t({ id: transOffer.status[stus] })}
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className={styles.offerCompanyNamesContainer}>
              <div>
                <h3 className={styles.companyName}>
                  <a
                    href={extractPageFromIri("companies", offer.company["@id"])}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.companyNameLink}
                  >
                    {offer.company.name}
                  </a>
                </h3>
              </div>
              <div>
                <h3 className={styles.offerTitle}>
                  <a
                    href={extractPageFromIri("offers", offer["@id"])}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.offerTitleLink}
                  >
                    {offer.title}
                  </a>
                </h3>
              </div>
            </div>
            {mode === "complete" ? (
              <div>
                <div className={styles.offerInfosAdminContainer}>
                  <h5>Critères</h5>
                  <ul>
                    <li>
                      Tier : <span>{t({ id: transOffer.tier[offer.tier] })}</span>
                    </li>
                    <li>
                      Poste :{" "}
                      <span>{offer.coreResponsibility?.value ? offer.coreResponsibility.value : "Aucun poste"}</span>
                    </li>
                    <li>
                      Salaire : <span>{offer.printSalaries ? offer.printSalaries : "Aucun salaire"}</span>
                    </li>
                    <li>
                      Experience :{" "}
                      <span>{offer.printExperienceYears ? offer.printExperienceYears : "Aucune experience"}</span>
                    </li>
                    <li>
                      Villes : <span>{offer.stapleLocation?.value ? offer.stapleLocation.value : "Aucune ville"}</span>
                    </li>
                    <li>
                      Postes secondaires :{" "}
                      <span>
                        {offer.responsibilities?.length ? (
                          <ResponsibilitiesCompact
                            responsibilities={offer.responsibilities.filter((resp) =>
                              offer.coreResponsibility ? resp["@id"] !== offer.coreResponsibility["@id"] : true,
                            )}
                          />
                        ) : (
                          "Aucun poste secondaire"
                        )}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            ) : null}
            <div className={styles.adminContactWrapper}>
              {mode === "complete" ? (
                <div className={styles.offerInfosAdminContainer}>
                  <h5>Administratif</h5>
                  <ul>
                    <li>
                      Formule : <span>{t({ id: transOffer.formule[offer.formule] })}</span>
                    </li>
                    <li>
                      Contrat : <span>{`${offer.setUp}€ + ${offer.commission}%`}</span>
                    </li>
                  </ul>
                </div>
              ) : null}
              <ContactsList contacts={offer.contacts} contactsNumber={2} title={t`offer.contacts.title`} />
            </div>
          </div>
          {mode === "complete" ? (
            <div className={styles.bottomContainer}>
              <div className={styles.bottomCtasContainer}>
                <div>
                  <CopyClip
                    default={getTalentOfferUrl(offer)}
                    ctaText={t`OfferInfo.talentUrl.cta.text`}
                    title={t`OfferInfo.talentUrl.cta.title`}
                  />
                </div>
              </div>
              <div>
                <div
                  className={cx(styles.text, {
                    [styles.late1]: !offer.recurring && activeDays >= lateStade[0],
                    [styles.late2]: !offer.recurring && activeDays >= lateStade[1],
                  })}
                >
                  <ReactMarkdown
                    components={{
                      p: React.Fragment,
                    }}
                  >
                    {offerStatusDate}
                  </ReactMarkdown>
                </div>
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};
