import { createInsightsMiddleware } from "instantsearch.js/es/middlewares";
import * as React from "react";
import { useInstantSearch } from "react-instantsearch-hooks-web";
import { useSelector } from "react-redux";
import aa from "search-insights";
import { State } from "../../interfaces/state";
import { extractIdFromIri } from "../../services/api/helpers";

export const InsightsMiddleware = () => {
  const { use } = useInstantSearch();
  const currentUser = useSelector((state: State) => state.currentUser);

  React.useLayoutEffect(() => {
    if (!!currentUser) {
      aa("setUserToken", `coreUser-${extractIdFromIri(currentUser["@id"])}`);
    }
    const middleware = createInsightsMiddleware({
      insightsClient: aa,
      onEvent: (insightEvent, searchInsight) => {
        const { insightsMethod, payload, eventType } = insightEvent;
        if (searchInsight && insightsMethod && eventType === "click") {
          searchInsight(insightsMethod, payload);
        }
      },
    });

    return use(middleware);
  }, [use, currentUser]);

  return null;
};
