import { connect } from "react-redux";
import { Dispatch } from "redux";

import CompanyFilterHandler from "../../../components/Filters/CompanyFilterHandler";
import { getSectorsAction } from "../../../services/api/sector/actions";
import { applyFilterAction, resetFilterAction } from "../../../services/state/actions";

const mapStateToProps = (state: any) => {
  return {
    apiPendingRequests: state.apiPendingRequests,
    filterContext: state.filterContext,
    sectors: state.sectors,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    applyFilter: (filterContext: any) => dispatch(applyFilterAction(filterContext)),
    getSectors: () => dispatch(getSectorsAction()),
    resetFilter: () => dispatch(resetFilterAction()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyFilterHandler);
