import { ActionType, DefaultActionType } from "../interfaces/api/actionType";

export const ClearTalentNames = {
  DEFAULT: "CLEAR_TALENT_NAMES_DEFAULT",
};

export const CreateQualification: DefaultActionType = {
  DEFAULT: "CREATE_QUALIFICATION_DEFAULT",
  FAILURE: "CREATE_QUALIFICATION_FAILURE",
};

export const CreateReactivation: DefaultActionType = {
  DEFAULT: "CREATE_REACTIVATION_DEFAULT",
  FAILURE: "CREATE_REACTIVATION_FAILURE",
};

export const EditQualification: DefaultActionType = {
  DEFAULT: "EDIT_QUALIFICATION_DEFAULT",
  FAILURE: "EDIT_QUALIFICATION_FAILURE",
};

export const DeleteTalent: ActionType = {
  FAILURE: "DELETE_TALENT_FAILURE",
  REQUEST: "DELETE_TALENT_REQUEST",
  SUCCESS: "DELETE_TALENT_SUCCESS",
};

export const EditTalent: ActionType = {
  FAILURE: "EDIT_TALENT_FAILURE",
  REQUEST: "EDIT_TALENT_REQUEST",
  SUCCESS: "EDIT_TALENT_SUCCESS",
};

export const FilterTalentsByName: ActionType = {
  FAILURE: "FILTER_TALENTS_BY_NAME_FAILURE",
  REQUEST: "FILTER_TALENTS_BY_NAME_REQUEST",
  SUCCESS: "FILTER_TALENTS_BY_NAME_SUCCESS",
};

export const GetTalent: ActionType = {
  FAILURE: "GET_TALENT_FAILURE",
  REQUEST: "GET_TALENT_REQUEST",
  SUCCESS: "GET_TALENT_SUCCESS",
};

export const GetTalentForCollection: ActionType = {
  FAILURE: "GET_TALENT_PROFILE_FOR_COLLECTION_FAILURE",
  REQUEST: "GET_TALENT_PROFILE_FOR_COLLECTION_REQUEST",
  SUCCESS: "GET_TALENT_PROFILE_FOR_COLLECTION_SUCCESS",
};

export const GetTalentOffers: ActionType = {
  FAILURE: "GET_TALENT_OFFERS_FAILURE",
  REQUEST: "GET_TALENT_OFFERS_REQUEST",
  SUCCESS: "GET_TALENT_OFFERS_SUCCESS",
};

export const GetTalents: ActionType = {
  FAILURE: "GET_TALENTS_FAILURE",
  REQUEST: "GET_TALENTS_REQUEST",
  SUCCESS: "GET_TALENTS_SUCCESS",
};

export const SortTalent: ActionType = {
  FAILURE: "SORT_TALENT_FAILURE",
  REQUEST: "SORT_TALENT_REQUEST",
  SUCCESS: "SORT_TALENT_SUCCESS",
};

export const clearTalentsState = {
  DEFAULT: "CLEAR_TALENTS_DEFAULT",
};
