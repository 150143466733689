import { FilterContext } from "../../../interfaces/api/filter";
import { Talent } from "../../../interfaces/resources/talent";
import client from "../client";
import { generateFilterQuery, getStringValue } from "../helpers";

export function getTalent(iri: string) {
  return client.get(iri);
}

export function filterTalentsByName(text: string) {
  return client.get(`/api/v2/talent/only-names?nameorphone=${encodeURIComponent(text)}`);
}

export function deleteTalent(iri: string) {
  return client.delete(iri);
}

export function getTalents(filterContext: FilterContext) {
  const query = encodeURI(generateFilterQuery(filterContext));
  return client.get(`/api/v2/talent${query}`);
}

export function getTalentsSummary(filterContext: FilterContext) {
  const query = encodeURI(generateFilterQuery(filterContext));
  return client.get(`/api/v2/talent${query}&groups[]=talent:count&itemsPerPage=1`);
}

export function editTalent(talent: Partial<Talent>, validation?: string) {
  return client.put(`${talent["@id"]}${validation ? `/${validation}` : ""}`, formatTalentData(talent));
}

function formatTalentData(req: Partial<Talent>) {
  return {
    ...req,
    talentManager: req.talentManager === null ? null : getStringValue(req.talentManager, "@id"),
  };
}
