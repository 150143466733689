import { t } from "@lingui/macro";
import * as React from "react";
import { useSelector } from "react-redux";
import { Placeholder } from "semantic-ui-react";

import { GetHuntTalents } from "../../../../actionTypes/hunt";
import { Talent } from "../../../../interfaces/resources/talent";
import { State } from "../../../../interfaces/state";
import { useApiSelector } from "../../../Utils/hook";

import "./index.scss";

import ContactedTalentsShort from "./TalentShort";

export const ContactedTalents: React.FC = () => {
  const { apiPendingRequests } = useApiSelector();
  const contactedTalents = useSelector((state: State) => state.contactedTalents);
  const isLoading =
    apiPendingRequests.length > 0 && apiPendingRequests.some((e: any) => GetHuntTalents.REQUEST === e.type);

  return (
    <div className="controlPanel">
      <div className="cardList ContactedTalents ">
        <h2 className="title">{t`ContactedTalents.title` + ` (${contactedTalents.length})`}</h2>
        <div className="cardListCardsContainer">
          {contactedTalents.length > 0 ? (
            contactedTalents.map((item: Talent, i: number) => {
              return <ContactedTalentsShort key={i} talent={item} />;
            })
          ) : isLoading ? (
            <div className="full-width">
              <Placeholder>
                <Placeholder.Header image>
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder.Header>
              </Placeholder>
              <Placeholder>
                <Placeholder.Header image>
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder.Header>
              </Placeholder>
              <Placeholder>
                <Placeholder.Header image>
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder.Header>
              </Placeholder>
              <Placeholder>
                <Placeholder.Header image>
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder.Header>
              </Placeholder>
            </div>
          ) : (
            t`ContactTalent.emptylist`
          )}
        </div>
      </div>
    </div>
  );
};
