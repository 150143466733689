import { AnyAction } from "redux";
import { EditApplication } from "../../../../actionTypes/applications";
import { PostPhoneCall } from "../../../../actionTypes/phoneCalls";
import { GetTalentApplications } from "../../../../actionTypes/talentApplications";
import { GetTalent } from "../../../../actionTypes/talents";

import { Application } from "../../../../interfaces/resources/application";

export function talentApplicationsToPitch(state: Application[] = [], action: AnyAction) {
  switch (action.type) {
    case GetTalentApplications.SUCCESS:
      return "talentApplicationsToPitch" === action.stateKey ? action.talentApplications : state;
    case GetTalent.SUCCESS:
    case PostPhoneCall.SUCCESS:
      return [];
    default:
      return state;
  }
}

export function talentApplicationsToConfirm(state: Application[] = [], action: AnyAction) {
  switch (action.type) {
    case GetTalentApplications.SUCCESS:
      return "talentApplicationsToConfirm" === action.stateKey ? action.talentApplications : state;
    case GetTalent.SUCCESS:
    case PostPhoneCall.SUCCESS:
      return [];
    default:
      return state;
  }
}

export function talentApplications(state: Application[] = [], action: AnyAction) {
  switch (action.type) {
    case EditApplication.SUCCESS:
      const newApplications = [...state];
      const index = state.findIndex((a: Application) => a["@id"] === action.application["@id"]);
      if (index !== -1) {
        newApplications[index] = action.application;
      }
      return newApplications;
    case GetTalentApplications.SUCCESS:
      return "talentApplications" === action.stateKey ? action.talentApplications : state;
    case GetTalent.SUCCESS:
      return [];
    default:
      return state;
  }
}
