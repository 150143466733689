import { t } from "@lingui/macro";
import * as React from "react";
import { useDispatch } from "react-redux";
import { Button, Confirm, Icon, Modal } from "semantic-ui-react";
import { Contact } from "../../../interfaces/resources/contact";
import { deleteContactAction } from "../../../services/api/contact/actions";

import { ContactForm } from "../ContactForm";

import styles from "./index.module.scss";

export interface ContactModalTrigger {
  handleOpen: () => void;
}

const ModalTrigger = ({ handleOpen }: ContactModalTrigger): JSX.Element => (
  <div className="ui button secondary secondaryButton" onClick={handleOpen}>
    <Icon name="plus square outline" />
    {t`ContactModal.trigger`}
  </div>
);

interface Props {
  contact?: Contact;
  customTrigger?: (handleOpen: ContactModalTrigger) => JSX.Element;
}

export const ContactModal: React.FC<Props> = ({ contact, customTrigger }: Props): JSX.Element => {
  const [modalOpen, setModalOpen] = React.useState(false);

  const handleOpen = (): void => setModalOpen(true);
  const handleClose = (): void => setModalOpen(false);

  return (
    <Modal
      size="tiny"
      trigger={customTrigger ? customTrigger({ handleOpen }) : <ModalTrigger handleOpen={handleOpen} />}
      open={modalOpen}
      onClose={handleClose}
    >
      <Modal.Header>
        <Icon name="times" onClick={handleClose} />
      </Modal.Header>
      <Modal.Content>
        <ContactForm closeModal={handleClose} contact={contact} />
      </Modal.Content>
    </Modal>
  );
};

interface ContactConfirmDeletionModalProps {
  contact: Contact;
  customTrigger?: (handleOpen: ContactModalTrigger) => JSX.Element;
}

export const ContactConfirmDeletionModal: React.FC<ContactConfirmDeletionModalProps> = ({
  contact,
  customTrigger,
}: ContactConfirmDeletionModalProps): JSX.Element => {
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = React.useState(false);

  const handleOpen = (): void => setModalOpen(true);
  const handleClose = (): void => setModalOpen(false);

  return (
    <Confirm
      open={modalOpen}
      onCancel={handleClose}
      header={
        <div className="header">
          <Icon name="warning sign" />
          {t`contactConfirmDeletetion.header`}
        </div>
      }
      content={t`contactConfirmDeletetion.content`}
      cancelButton={t`Constant.cancel`}
      confirmButton={t`contactConfirmDeletetion.confirm`}
      onConfirm={(): void => {
        dispatch(deleteContactAction(contact["@id"]));
        handleClose();
      }}
      trigger={
        customTrigger ? customTrigger({ handleOpen }) : <Button basic icon="close" onClick={(): void => handleOpen()} />
      }
    />
  );
};

interface ContactConfirmSoftDeletionModalProps {
  customTrigger?: (handleOpen: ContactModalTrigger) => JSX.Element;
  onPostConfirm: () => void;
}

export const ContactConfirmSoftDeletionModal: React.FC<ContactConfirmSoftDeletionModalProps> = ({
  customTrigger,
  onPostConfirm,
}: ContactConfirmSoftDeletionModalProps): JSX.Element => {
  const [modalOpen, setModalOpen] = React.useState(false);

  const handleOpen = (): void => setModalOpen(true);
  const handleClose = (): void => setModalOpen(false);

  return (
    <Confirm
      open={modalOpen}
      onCancel={handleClose}
      header={
        <div className="header">
          <Icon name="warning sign" />
          {t`contactConfirmDeletetion.header`}
        </div>
      }
      content={t`contactConfirmDeletetion.content`}
      cancelButton={t`Constant.cancel`}
      confirmButton={t`contactConfirmDeletetion.confirm`}
      onConfirm={(): void => {
        onPostConfirm();
        handleClose();
      }}
      trigger={
        customTrigger ? customTrigger({ handleOpen }) : <Button basic icon="close" onClick={(): void => handleOpen()} />
      }
    />
  );
};

interface ContactImportCompanyContactsModalProps {
  contacts: Contact[];
  customTrigger?: (handleOpen: ContactModalTrigger) => JSX.Element;
  onPostConfirm: (selectedContacts: Contact[]) => void;
}

export const ContactImportCompanyContactsModal: React.FC<ContactImportCompanyContactsModalProps> = ({
  contacts,
  customTrigger,
  onPostConfirm,
}: ContactImportCompanyContactsModalProps): JSX.Element => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedContacts, setSelectedContacts] = React.useState<Contact[]>([]);

  const handleOpen = (): void => setModalOpen(true);
  const handleClose = (): void => setModalOpen(false);

  return (
    <Confirm
      open={modalOpen}
      onCancel={handleClose}
      header={<div className="header">{t`ContactForm.import.title`}</div>}
      content={
        <ul className={styles.contactsList}>
          {contacts.map((contact: Contact, i: number) => (
            <li key={`${contact["@id"]}-${i}`} className={styles.contactItem}>
              <div
                className={styles.contactItemContent}
                onClick={(): void => {
                  if (selectedContacts.includes(contact)) {
                    const cindex = selectedContacts.indexOf(contact);
                    selectedContacts.splice(cindex, 1);
                    setSelectedContacts([...selectedContacts]);
                  } else {
                    setSelectedContacts([...selectedContacts, contact]);
                  }
                }}
              >
                <input type="checkbox" checked={selectedContacts.includes(contact)} />
                <span className={styles.contactName}>
                  {contact.firstname} {contact.lastname} <em>({contact.email})</em>
                </span>
              </div>
            </li>
          ))}
        </ul>
      }
      confirmButton={t`contactConfirmDeletetion.confirm`}
      onConfirm={(): void => {
        onPostConfirm(selectedContacts);
        setSelectedContacts([]);
        handleClose();
      }}
      trigger={
        customTrigger ? customTrigger({ handleOpen }) : <Button basic icon="close" onClick={(): void => handleOpen()} />
      }
    />
  );
};
