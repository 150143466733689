import { AnyAction } from "redux";
import { CreateLinkedinResume, EditLinkedInProfile } from "../../../../actionTypes/linkedInProfile";
import { GetTalent } from "../../../../actionTypes/talents";
import { LinkedInProfile } from "../../../../interfaces/resources/linkedInProfile";

export function talentLinkedInProfile(state: LinkedInProfile | null = null, action: AnyAction) {
  switch (action.type) {
    case EditLinkedInProfile.SUCCESS:
      return action.linkedInProfile;
    case GetTalent.SUCCESS:
    case CreateLinkedinResume.SUCCESS:
      return action.talent.linkedInProfile;
    default:
      return state;
  }
}
