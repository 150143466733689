import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import { CreateOfferInternalView, EditOfferInternalView, GetOfferInternalView } from "../../../../actionTypes/offers";
import { OfferInternalView } from "../../../../interfaces/resources/offer";

import { apiFailureAction } from "../../actions";
import { getOfferInternalViewSuccessAction } from "./actions";
import * as apiClient from "./api";

export function* createOfferInternalView(action: any) {
  try {
    const { data: offerInternalView } = yield call(apiClient.createOfferInternalView, action.offerInternalView);
    yield put({
      form: action.form,
      offerInternalView,
      type: CreateOfferInternalView.SUCCESS,
    });
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* editOfferInternalView(action: any) {
  try {
    const { data: offerInternalView } = yield call(apiClient.editOfferInternalView, action.offerInternalView);
    yield put({
      form: action.form,
      offerInternalView,
      type: EditOfferInternalView.SUCCESS,
    });
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* getOfferInternalView(action: AnyAction) {
  try {
    const response: AxiosResponse<OfferInternalView> = yield call(apiClient.getOfferInternalView, action.iri);
    yield put(getOfferInternalViewSuccessAction(response.data));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export default function* offerInternalViewSaga() {
  yield takeEvery(CreateOfferInternalView.REQUEST, createOfferInternalView);
  yield takeLatest(EditOfferInternalView.REQUEST, editOfferInternalView);
  yield takeLatest(GetOfferInternalView.REQUEST, getOfferInternalView);
}
