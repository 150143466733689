import React, { FC } from "react";
import { Divider, Placeholder, Segment } from "semantic-ui-react";

export const TalentListLoadingPlaceholder: FC = () => (
  <div className="full-width">
    {Array.from({ length: 3 }).map((_, i) => (
      <Placeholder key={i}>
        <Placeholder.Header image>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
      </Placeholder>
    ))}
  </div>
);

export const TalentLoadingPlaceholder: FC = () => (
  <div className="full-width">
    <Segment basic loading className="full-width">
      <Placeholder>
        <Placeholder.Header image>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
        <Divider />
        <Placeholder.Line />
      </Placeholder>
    </Segment>
    <div className="details full-width">
      <Segment basic loading className="reset block">
        <Placeholder>
          <Placeholder.Header>
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      </Segment>
      <Segment basic loading className="reset block">
        <Placeholder>
          <Placeholder.Header>
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      </Segment>
    </div>
  </div>
);
