import * as React from "react";
import { useParams } from "react-router-dom";

import OfferFormContainer from "../../containers/Offers/OfferForm/OfferFormContainer";
import { generateIriFromId } from "../../services/api/helpers";

export const OfferEditScene: React.FC = (): JSX.Element => {
  const { id } = useParams();
  return (
    <div>
      <OfferFormContainer iri={generateIriFromId("offers", Number(id))} state="edition" />
    </div>
  );
};
