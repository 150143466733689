import { t } from "@lingui/macro";
import cx from "classnames";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Accordion, Button, Icon } from "semantic-ui-react";
import { EditApplication } from "../../actionTypes/applications";
import { GetPhoneCalls } from "../../actionTypes/phoneCalls";
import { GetTalentCard } from "../../actionTypes/talentCards";
import { OfferInfo } from "../../blocks/offerInfo";
import { PersonProfileImg } from "../../blocks/personProfileImg";
import { PhoneCallComp } from "../../blocks/talentDetails/PhoneCalls/PhoneCall";
import { ModalWrapper } from "../../components/ModalWrapper";
import TalentCardForm from "../../components/Offers/Shortlist/Form/TalentCardForm";
import { initializeValues } from "../../components/Offers/Shortlist/Form/TalentCardForm/talentCardHelper";
import { TalentCardModal } from "../../components/TalentCardModal";
import { useApiSelector } from "../../components/Utils/hook";
import { ApiRequest } from "../../interfaces/api/request";
import {
  Application,
  applicationStatuses,
  applicationStatusesPreProcess,
} from "../../interfaces/resources/application";
import { CoreUser } from "../../interfaces/resources/coreUser";
import { PhoneCall } from "../../interfaces/resources/phoneCall";
import { TalentCard } from "../../interfaces/resources/talentCard";
import { State } from "../../interfaces/state";
import { editApplicationAction, getApplicationAction } from "../../services/api/applications/actions";
import { extractPageFromIri, generateIriFromId } from "../../services/api/helpers";
import { getPhoneCallsAction } from "../../services/api/talent/phoneCalls/actions";
import {
  createTalentCardAction,
  editTalentCardAction,
  getTalentCardAction,
} from "../../services/api/talentCard/actions";
import globalStyles from "../../styles/global.module.scss";
import styles from "./index.module.scss";

export const TalentCardPage: React.FC = () => {
  const params: any = useParams();
  const dispatch = useDispatch();
  const application = useSelector((state: State) => state.application);
  const talentCardsState = useSelector((state: State) => state.talentCards);
  const tStateIndex = talentCardsState.findIndex((e: TalentCard) => application?.["@id"] === e.application?.["@id"]);
  const { apiErrors, apiPendingRequests, apiSuccess } = useApiSelector();
  const editApplication = (req: Partial<Application>) => req["@id"] && dispatch(editApplicationAction(req["@id"], req));
  const currentUser: CoreUser = useSelector((state: any) => state.currentUser);
  const formName = application ? `talent-card-${application["@id"]}` : "talent card";
  const phoneCalls: PhoneCall[] = useSelector((state: any) => state.shortlistPhoneCalls);
  const [importedTalentCard, setImportedTalentCard] = React.useState<TalentCard | null>(null);
  const [showNotes, setShowNotes] = React.useState<boolean>(false);
  const [openModal, setOpenModal] = React.useState(false);
  // does a talent card already exist in application ?
  const talentCard =
    tStateIndex >= 0 ? talentCardsState[tStateIndex] : application?.talentCard ? application.talentCard : undefined;

  // does a talent card already exist ?
  // if not initialize values
  const initialValues = talentCard
    ? talentCard
    : application
    ? initializeValues(
        application,
        application.talent.talentProfile.lastQualification,
        application.talent.linkedInProfile,
        currentUser,
        importedTalentCard,
      )
    : null;

  const onCloseModal = () => {
    setOpenModal(false);
  };
  const createTalentCard = (talentC: Partial<TalentCard>, form: string) => {
    dispatch(createTalentCardAction(talentC, form));
  };
  const editTalentCard = (talentC: Partial<TalentCard>, form: string) => {
    dispatch(editTalentCardAction(talentC, form));
  };

  const onClickImportTalentCard = () => {
    // dispatch the call to get the most recent talentCard
    const tcId = application?.talent.lastTalentCardId;
    if (!!tcId) {
      dispatch(getTalentCardAction(generateIriFromId("talent_cards", tcId)));
    }
  };

  React.useEffect(() => {
    // check for the call of the most recent talent card and sets it in the state
    const tcId = application?.talent.lastTalentCardId
      ? generateIriFromId("talent_cards", application?.talent.lastTalentCardId)
      : undefined;
    if (
      !!!importedTalentCard
      && apiSuccess.some((e) => e.type === GetTalentCard.SUCCESS && e.talentCard["@id"] === tcId)
    ) {
      apiSuccess.map((apiSucc) => {
        if (apiSucc.type === GetTalentCard.SUCCESS && apiSucc.talentCard["@id"] === tcId) {
          setImportedTalentCard(apiSucc.talentCard);
        }

        return apiSucc;
      });
    }
  }, [apiErrors, apiPendingRequests, apiSuccess, application, importedTalentCard]);

  React.useEffect(() => {
    // get the application from id in params
    if (params && !application) {
      dispatch(getApplicationAction(generateIriFromId("applications", params.id)));
    }
  }, [params, dispatch, application]);

  React.useEffect(() => {
    // get the talent's notes
    const getTalentPhoneCalls = (talentIri: string) => dispatch(getPhoneCallsAction(talentIri));

    if (
      application
      && !phoneCalls.length
      && !apiErrors.some((e) => e.type === GetPhoneCalls.FAILURE && e.iri === application.talent["@id"])
      && !apiPendingRequests.some((e) => e.type === GetPhoneCalls.REQUEST && e.iri === application.talent["@id"])
      && !apiSuccess.some((e) => e.type === GetPhoneCalls.SUCCESS && e.talentIri === application.talent["@id"])
    ) {
      getTalentPhoneCalls(application.talent["@id"]);
    }
  }, [dispatch, apiErrors, apiPendingRequests, apiSuccess, phoneCalls, application]);

  React.useEffect(() => {
    if (apiSuccess.some((suc) => suc.form === formName) && !!talentCard?.["@id"]) {
      setOpenModal(true);
    }
  }, [apiSuccess, formName, talentCard]);

  const closeTab = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
  };

  return (
    <div className={styles.container}>
      {application ? (
        <div>
          <div className={styles.offerInfoContainer}>
            <OfferInfo offer={application.offer} mode="minimal" />
          </div>
          <div className={styles.closeButton}>
            <Button secondary inverted onClick={() => closeTab()} content="Fermer" icon="times" />
          </div>
          <div className={styles.item}>
            <div className={styles.itemHeader}>
              {apiPendingRequests.length > 0
              && apiPendingRequests.some(
                (req: ApiRequest) => EditApplication.REQUEST === req.type && application["@id"] === req.iri,
              ) ? (
                <div className={styles.loaderCheckContainer}>
                  <Icon loading name="spinner" style={{ float: "none" }} />
                </div>
              ) : applicationStatuses.shortlistReady === application.status ? (
                <div className={styles.loaderCheckContainer}>
                  <Icon name="check" color="green" style={{ float: "none" }} />
                </div>
              ) : null}

              <div className={styles.talentImageContainer}>
                <PersonProfileImg
                  image={application.talent.image}
                  alt={`${application.talent.firstname} ${application.talent.lastname}`}
                />
              </div>
              <div className={styles.talentNameContainer}>
                <Link
                  to={extractPageFromIri("talents", application.talent["@id"])}
                  target="_blank"
                  className={styles.talentNameLink}
                  onClick={(e) => e.stopPropagation()}
                >
                  {`${application.talent.firstname} ${application.talent.lastname}`}
                </Link>
              </div>
              {applicationStatusesPreProcess.includes(application.status) ? (
                <div>
                  <button
                    type="button"
                    className={cx(styles.headerBtn, globalStyles.cta, globalStyles.primaryCta, globalStyles.inverted)}
                    onClick={(e) => {
                      e.stopPropagation();
                      editApplication({ "@id": application["@id"], status: applicationStatuses.shortlisted });
                    }}
                  >
                    {t`Shortlist.sendWithoutEmail`}
                  </button>
                </div>
              ) : null}
              <div>
                <button
                  type="button"
                  className={cx(styles.headerBtn, globalStyles.cta, globalStyles.darkCta, globalStyles.inverted)}
                  disabled={!!talentCard || !!importedTalentCard || !application.talent.lastTalentCardId}
                  onClick={onClickImportTalentCard}
                  title={
                    !!talentCard
                      ? t`Shortlist.importTalentCard.cta.title.modification`
                      : !!importedTalentCard
                      ? t`Shortlist.importTalentCard.cta.title.imported`
                      : !application.talent.lastTalentCardId
                      ? t`Shortlist.importTalentCard.cta.title.noTalentCard`
                      : undefined
                  }
                >
                  {t`Shortlist.importTalentCard.cta.text`}
                </button>
              </div>

              {phoneCalls.length > 0 ? (
                <div>
                  <button
                    type="button"
                    className={cx(styles.headerBtn, globalStyles.cta, globalStyles.primaryCta, globalStyles.inverted)}
                    onClick={(e): void => {
                      e.stopPropagation();
                      setShowNotes(!showNotes);
                    }}
                  >
                    {showNotes ? t`Shortlist.hideNotes` : t`Shortlist.seeNotes`}
                  </button>
                </div>
              ) : null}

              {application.talentCard?.["@id"] ? (
                <div>
                  <button
                    type="button"
                    className={cx(styles.headerBtn, globalStyles.cta, globalStyles.primaryCta, globalStyles.inverted)}
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenModal(true);
                    }}
                  >
                    {t`Shortlist.previewTalentCard`}
                  </button>
                </div>
              ) : null}
            </div>
            <div className={styles.itemContent}>
              <div className={styles.talentCardFormContainer}>
                <TalentCardForm
                  application={application}
                  editApplication={editApplication}
                  currentUser={currentUser}
                  form={formName}
                  initialValues={initialValues}
                  talentCard={talentCard}
                  createTalentCard={createTalentCard}
                  editTalentCard={editTalentCard}
                  talentProfile={application.talent.talentProfile}
                  mode="talentCard"
                />
              </div>
              <div className={cx(styles.notesContainer, { [styles.showNotes]: showNotes })}>
                <Accordion>
                  {phoneCalls.length > 0
                    ? phoneCalls.map((item: PhoneCall, i) => (
                        <PhoneCallComp
                          key={`${item["@id"]}-${i}`}
                          phoneCall={item}
                          coreUser={currentUser}
                          readOnly={true}
                          shortlist={true}
                        />
                      ))
                    : null}
                </Accordion>
              </div>
            </div>
          </div>

          <ModalWrapper open={openModal} onCloseModal={onCloseModal}>
            <TalentCardModal talentCardIri={talentCard?.["@id"]} onCloseModal={onCloseModal} mode="talentCard" />
          </ModalWrapper>
        </div>
      ) : null}
      <div className={styles.closeButton}>
        <Button secondary inverted onClick={() => closeTab()} content="Fermer" icon="times" />
      </div>
    </div>
  );
};
