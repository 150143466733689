import { t } from "@lingui/macro";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Dropdown, Menu } from "semantic-ui-react";
import logo from "../../images/logos/elinoi-logo-black.svg";
import { FilterClause } from "../../interfaces/api/filter";
import { coreUserRoles } from "../../interfaces/resources/coreUser";
import { State } from "../../interfaces/state";
import { routes } from "../../routes";
import { logoutAction, switchOffAction } from "../../services/api/authentication/actions";
import { getCurrentUserAction } from "../../services/api/coreUser/actions";
import { filterTalentsByNameAction } from "../../services/api/talent/actions";
import { applyFilterAction } from "../../services/state/actions";
import globalStyles from "../../styles/global.module.scss";
import { getUserImage } from "../App/Image";
import RoundImage from "../Design/RoundImage";
import { TalentNameFilter } from "../Filters/TalentNameFilter";
import { useApiSelector } from "../Utils/hook";

import "./index.scss";

export const Header: React.FC = () => {
  const dispatch = useDispatch();
  const { apiPendingRequests } = useApiSelector();
  const currentUser = useSelector((state: State) => state.currentUser);
  const talentNames = useSelector((state: State) => state.talentNames);
  const isSwitchOn = useSelector((state: State) => state.isSwitchOn);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const switchOff = (): void => {
    dispatch(switchOffAction());
  };
  const logout = (): void => {
    dispatch(logoutAction());
  };
  const filterTalentsByName = (txt: string) => {
    dispatch(filterTalentsByNameAction(txt));
  };
  const applyFilter = (filterClause: FilterClause<any>) => dispatch(applyFilterAction(filterClause));

  React.useEffect(() => {
    dispatch(getCurrentUserAction());
  }, [isSwitchOn, dispatch]);

  React.useEffect(() => {
    if (currentUser) {
      setIsAdmin(currentUser.roles.includes(coreUserRoles.admin));
    }
  }, [currentUser]);

  return (
    <>
      <div className={globalStyles.headerWrapper}>
        <Menu borderless className="appHeader">
          <Menu.Item as={Link} to={routes.app.home} className="header-logo-link">
            <img src={logo} alt="elinoï logo" />
          </Menu.Item>
          <Menu.Item as={Link} to={routes.app.dashboard} content={t`AppHeader.dashboard`} />
          <Menu.Item as={Link} to={routes.applications.dashboard} content={t`AppHeader.applicationDashboard`} />
          <Dropdown item text={"Gestion talents"}>
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to={routes.talents.sort} text={t`AppHeader.talentsSort`} />
              <Dropdown.Item as={Link} to={routes.talents.noQualifTalents} text={t`AppHeader.talentsNoQualifTalents`} />
              <Dropdown.Item as={Link} to={routes.talents.search} text={t`AppHeader.talentsSearch`} />
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown item text={"Rechercher"}>
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to={routes.offers.search} text={t`AppHeader.offersSearch`} />
              <Dropdown.Item as={Link} to={routes.talents.search} text={t`AppHeader.talentsSearch`} />
              <Dropdown.Item as={Link} to={routes.companies.search} text={t`AppHeader.companiesSearch`} />
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown item text={"Ajouter"}>
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to={routes.offers.new} text={t`AppHeader.offersNew`} />
              <Dropdown.Item as={Link} to={routes.companies.new} text={t`AppHeader.companiesNew`} />
            </Dropdown.Menu>
          </Dropdown>
          <Menu.Item as={Link} to={routes.hunts.hunt} content={t`AppHeader.AlgoliaSearch`} />
          <Menu.Menu position={"right"}>
            <TalentNameFilter
              apiPendingRequests={apiPendingRequests}
              compact={true}
              filterTalentsByName={filterTalentsByName}
              onSelect={(data: any) =>
                applyFilter({
                  multiple: false,
                  name: "talent",
                  value: data.value,
                })
              }
              applyFilter={() => null}
              talentNames={talentNames}
            />
            <Dropdown
              item={true}
              trigger={
                <span>
                  {currentUser ? (
                    <div>
                      <RoundImage className="image" size="35px" {...getUserImage(currentUser.image)} />
                      {!isSwitchOn
                        ? t({ id: "AppHeader.menu.title", values: { firstname: currentUser.firstname } })
                        : t({ id: "AppHeader.menu.title.onSwitch", values: { firstname: currentUser.firstname } })}
                    </div>
                  ) : null}
                </span>
              }
            >
              <Dropdown.Menu>
                {isAdmin && !isSwitchOn ? (
                  <Dropdown.Item as={Link} to={routes.admin.userList}>
                    {t`AppHeader.CoreUsersList`}
                  </Dropdown.Item>
                ) : null}
                <Dropdown.Item as={Link} to={routes.coreUser.profile}>
                  {t`AppHeader.menu.myAccount`}
                </Dropdown.Item>
                {!isSwitchOn ? (
                  <Dropdown.Item onClick={logout}>{t`AppHeader.menu.logout`}</Dropdown.Item>
                ) : (
                  <Dropdown.Item onClick={switchOff}>{t`AppHeader.menu.switchUserOff`}</Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        </Menu>
      </div>
    </>
  );
};
