import { ActionType } from "../interfaces/api/actionType";

export const ApiAuthenticationLogin: ActionType = {
  FAILURE: "AUTHENTICATION_LOGIN_FAILURE",
  REQUEST: "AUTHENTICATION_LOGIN_REQUEST",
  SUCCESS: "AUTHENTICATION_LOGIN_SUCCESS",
};

export const ApiAuthenticationLogout: ActionType = {
  FAILURE: "AUTHENTICATION_LOGOUT_FAILURE",
  REQUEST: "AUTHENTICATION_LOGOUT_REQUEST",
  SUCCESS: "AUTHENTICATION_LOGOUT_SUCCESS",
};

export const ApiAuthenticationRefresh: ActionType = {
  FAILURE: "AUTHENTICATION_REFRESH_FAILURE",
  REQUEST: "AUTHENTICATION_REFRESH_REQUEST",
  SUCCESS: "AUTHENTICATION_REFRESH_SUCCESS",
};

export const GoogleAuthenticationLogin: ActionType = {
  FAILURE: "GOOGLE_AUTHENTICATION_LOGIN_FAILURE",
  REQUEST: "GOOGLE_AUTHENTICATION_LOGIN_REQUEST",
  SUCCESS: "GOOGLE_AUTHENTICATION_LOGIN_SUCCESS",
};

export const GoogleAuthenticationLogout: ActionType = {
  FAILURE: "GOOGLE_AUTHENTICATION_LOGOUT_FAILURE",
  REQUEST: "GOOGLE_AUTHENTICATION_LOGOUT_REQUEST",
  SUCCESS: "GOOGLE_AUTHENTICATION_LOGOUT_SUCCESS",
};

export const SwitchOn: ActionType = {
  FAILURE: "SWITCH_ON_FAILURE",
  REQUEST: "SWITCH_ON_REQUEST",
  SUCCESS: "SWITCH_ON_SUCCESS",
};

export const SwitchOff = {
  DEFAULT: "SWITCH_OFF_DEFAULT",
};
