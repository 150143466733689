import { AnyAction } from "redux";
import { GetCompetences } from "../../../actionTypes/competences";
import { FilterContext } from "../../../interfaces/api/filter";
import { Competence } from "../../../interfaces/resources/competence";

export function getCompetencesAction(filterContext: FilterContext) {
  return {
    filterContext,
    type: GetCompetences.REQUEST,
  };
}

export function getCompetencesSuccessAction(competences: Competence[], action: AnyAction) {
  return {
    competences,
    filterContext: action.filterContext,
    type: GetCompetences.SUCCESS,
  };
}
