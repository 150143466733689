import { AnyAction } from "redux";

import { GetResponsibilityType, GetResponsibilityTypes } from "../../../actionTypes/responsibilityTypes";

import { ResponsibilityType } from "../../../interfaces/resources/responsibility";

export function getResponsibilityTypeAction(iri: string) {
  return {
    iri,
    type: GetResponsibilityType.REQUEST,
  };
}

export function getResponsibilityTypeSuccessAction(responsibilityType: ResponsibilityType) {
  return {
    responsibilityType,
    type: GetResponsibilityType.SUCCESS,
  };
}

export function getResponsibilityTypesAction(page?: number) {
  return {
    page,
    type: GetResponsibilityTypes.REQUEST,
  };
}

export function getResponsibilityTypesSuccessAction(responsibilityTypes: ResponsibilityType[], action: AnyAction) {
  return {
    page: action.page,
    responsibilityTypes,
    type: GetResponsibilityTypes.SUCCESS,
  };
}
