import { t } from "@lingui/macro";
import cx from "classnames";
import { Field, Formik, Form } from "formik";

import React, { FC } from "react";
import { useDispatch } from "react-redux";
import Yup from "../../../components/App/Yup";
import RichTextEditor from "../../../components/Form/Formik/RichTextEditor";

import { TalentProfile } from "../../../interfaces/resources/talentProfile";
import { editTalentProfileAction } from "../../../services/api/talent/talentProfile/actions";
import globalStyles from "../../../styles/global.module.scss";
import styles from "./index.module.scss";

interface Props {
  talentProfile: TalentProfile;
  onCloseModal: () => void;
}

const validationSchema = Yup.object().shape({
  note: Yup.string().nullable(),
});

export const TalentNoteModal: FC<Props> = (props: Props) => {
  const { talentProfile, onCloseModal } = props;
  const dispatch = useDispatch();
  const EDIT_NOTE_FORM = "talentProfileNote";

  const onSubmit = (values: Partial<TalentProfile>): void => {
    dispatch(
      editTalentProfileAction(
        {
          "@id": talentProfile["@id"],
          ...values,
        },
        EDIT_NOTE_FORM,
      ),
    );
    closeModal();
  };

  const closeModal = () => {
    if (typeof onCloseModal === "function") {
      onCloseModal();
    }
  };

  return (
    <div className={styles.modal}>
      <div className={styles.container}>
        <Formik
          onSubmit={onSubmit}
          initialValues={{ note: !!talentProfile.note ? talentProfile.note : undefined }}
          validationSchema={validationSchema}
        >
          {({ handleSubmit }): JSX.Element => (
            <Form onSubmit={handleSubmit}>
              <div className={styles.titleContainer}>
                <h3 className={styles.title}>{t`NoteModal.talent.title`}</h3>
              </div>
              <div className={styles.noteFieldContainer}>
                <Field
                  component={RichTextEditor}
                  name="note"
                  uniqId={`talentProfile-${talentProfile["@id"]}-notes`}
                  replaceIfEmpty={false}
                />
              </div>
              <div className={styles.ctaListContainer}>
                <div>
                  <button
                    type="button"
                    className={cx(globalStyles.cta, globalStyles.darkCta, globalStyles.inverted)}
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    {t`Constant.cancel`}
                  </button>
                </div>
                <div>
                  <button type="submit" className={cx(globalStyles.cta, globalStyles.primaryCta)}>
                    {t`NoteModal.cta.validate`}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
