import { t } from "@lingui/macro";
import cx from "classnames";
import { formatDistance, parseISO } from "date-fns";
import { fr } from "date-fns/locale";
import * as React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Icon, Item } from "semantic-ui-react";

import { Talent } from "../../../../interfaces/resources/talent";
import { TalentProfile } from "../../../../interfaces/resources/talentProfile";
import { extractPageFromIri } from "../../../../services/api/helpers";
import { transTalent } from "../../../../translations/constants/talent";
import { getUserImage } from "../../../App/Image";
import { GoldenTag } from "../../../Design/GoldenTag";

import "./index.scss";

interface Props {
  talent: Talent;
}

const TalentShort = ({ talent }: Props): JSX.Element => {
  const talentProfiles = useSelector((state: any) => state.talentProfiles);
  const getIsGolden = (tProfile: string | TalentProfile): boolean => {
    if (typeof tProfile === "string") {
      return talentProfiles[tProfile] ? talentProfiles[tProfile].golden : false;
    } else if (typeof tProfile === "object") {
      return tProfile.golden ? tProfile.golden : false;
    }

    return false;
  };
  const isGolden = getIsGolden(talent.talentProfile);

  return (
    <Item as={Link} to={extractPageFromIri("talents", talent["@id"])}>
      <Item.Image size="tiny" {...getUserImage(talent.image)} />
      <Item.Content>
        <Item.Header className="talent-short">
          <div className="talentInfoNameContainer">
            <span className="talentInfoName">{`${talent.firstname} ${talent.lastname}`}</span>
            {isGolden ? <GoldenTag /> : null}
            <span className={cx("statusLabel", { red: !talent.enabled })}>
              {talent.enabled ? t({ id: transTalent.status[talent.status] }) : t`talent.disabled.label`}
            </span>
          </div>
        </Item.Header>
        <Item.Description>
          <Icon name="linkedin square" />
          {talent.linkedInProfile.headline}
        </Item.Description>
        <Item.Extra>
          <Icon name="calendar alternate" />
          {formatDistance(new Date(), parseISO(talent.lastLogin), { locale: fr })}
        </Item.Extra>
      </Item.Content>
    </Item>
  );
};

export default TalentShort;
