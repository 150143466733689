import { AxiosResponse } from "axios";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import { CreateOfferTalentView, EditOfferTalentView, GetOfferTalentView } from "../../../../actionTypes/offers";
import { OfferTalentView } from "../../../../interfaces/resources/offer";

import { apiFailureAction } from "../../actions";
import { getOfferTalentViewSuccessAction } from "./actions";
import * as apiClient from "./api";

export function* createOfferTalentView(action: any): any {
  try {
    const response = yield call(apiClient.createOfferTalentView, action.offerTalentView);
    yield put({
      form: action.form,
      offerTalentView: response.data,
      type: CreateOfferTalentView.SUCCESS,
    });
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* editOfferTalentView(action: any): any {
  try {
    const response = yield call(apiClient.editOfferTalentView, action.offerTalentView);
    yield put({
      form: action.form,
      offerTalentView: response.data,
      type: EditOfferTalentView.SUCCESS,
    });
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* getOfferTalentView(action: any) {
  try {
    const response: AxiosResponse<OfferTalentView> = yield call(apiClient.getOfferTalentView, action.iri);
    yield put(getOfferTalentViewSuccessAction(response.data));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export default function* offerTalentViewSaga() {
  yield takeEvery(CreateOfferTalentView.REQUEST, createOfferTalentView);
  yield takeLatest(EditOfferTalentView.REQUEST, editOfferTalentView);
  yield takeLatest(GetOfferTalentView.REQUEST, getOfferTalentView);
}
