import { t } from "@lingui/macro";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Item, Segment } from "semantic-ui-react";

import { GetOffers } from "../../../actionTypes/offers";
import { Offer } from "../../../interfaces/resources/offer";
import { State } from "../../../interfaces/state";
import { extractPageNumberFromIri } from "../../../services/api/helpers";

import { getOffersAction } from "../../../services/api/offer/actions";
import { applyFilterAction } from "../../../services/state/actions";
import { usePrevious } from "../../Utils/hook";
import LoaderComp from "../../Utils/Loader";

import OfferShort from "../OfferShort";

export const OfferList: React.FC = () => {
  const dispatch = useDispatch();
  const { apiPendingRequests, filterContext, offers, offerView } = useSelector((state: State) => state);
  const prevFilterContext = usePrevious(filterContext);
  const loading = apiPendingRequests.length > 0 && apiPendingRequests.some((e: any) => e.type === GetOffers.REQUEST);

  React.useEffect(() => {
    const handleOnScroll = () => {
      const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
      const scrollHeight =
        (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
      const clientHeight = document.documentElement.clientHeight || window.innerHeight;
      const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

      if (scrolledToBottom) {
        const nextPage =
          offerView && offerView["hydra:next"] ? extractPageNumberFromIri(offerView["hydra:next"]) : null;
        if (nextPage && !loading) {
          dispatch(
            applyFilterAction({
              multiple: false,
              name: "page",
              value: nextPage,
            }),
          );
        }
      }
    };

    window.addEventListener("scroll", handleOnScroll);

    return () => {
      window.removeEventListener("scroll", handleOnScroll);
    };
  }, [loading, offerView, dispatch]);

  React.useEffect(() => {
    if (prevFilterContext !== filterContext) {
      dispatch(getOffersAction([...filterContext, { multiple: false, name: "itemsPerPage", value: 15 }]));
    }
  }, [filterContext, prevFilterContext, dispatch]);

  return (
    <Segment basic>
      <Item.Group>
        {offers.map((item: Offer, i: number) => (
          <OfferShort key={`offerlist-${item["@id"]}-${i}`} offer={item} />
        ))}
      </Item.Group>
      {loading ? <LoaderComp /> : offers.length === 0 ? <div>{t`offers.empty`}</div> : null}
    </Segment>
  );
};
