import { Dispatch, SetStateAction, createContext } from "react";

export interface OfferMainFormContextProps {
  formSubmissionType: string | null;
}

const defaultValue: OfferMainFormContextProps = {
  formSubmissionType: null,
};

const defaultUpdate: Dispatch<SetStateAction<OfferMainFormContextProps>> = () => defaultValue;

export const OfferMainFormContext = createContext({
  state: defaultValue,
  update: defaultUpdate,
});
