import { t } from "@lingui/macro";
import { FieldProps } from "formik";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Loader } from "semantic-ui-react";

import { GetOffers } from "../../../../../actionTypes/offers";

import { defaultStyles } from "../../../../../components/Form/Select";
import { useApiSelector, usePrevious } from "../../../../../components/Utils/hook";
import { Application, applicationStatuses } from "../../../../../interfaces/resources/application";
import { Offer, offerStatuses } from "../../../../../interfaces/resources/offer";
import { Talent } from "../../../../../interfaces/resources/talent";
import { State } from "../../../../../interfaces/state";
import { getOffersAction, getTalentOffersAction } from "../../../../../services/api/offer/actions";

import { getTalentApplicationsAction } from "../../../../../services/api/talent/talentApplication/actions";
import { PhoneCallFormValues } from "../helpers";
import styles from "./index.module.scss";
import { OfferLine } from "./OfferLine";

interface Props extends FieldProps {
  talent: Talent;
  readOnly: boolean;
}

type TOType = { talent: Talent; readOnly: boolean; field?: any; form?: any } | Props;

const TalentOfferList: React.FC<TOType> = ({ talent, field, form, readOnly }: TOType) => {
  const dispatch = useDispatch();
  const { apiPendingRequests } = useApiSelector();
  const talentProfile = useSelector((state: State) => state.talentProfile);
  const offersState = useSelector((state: State) => state.offers);
  const talentOffers = useSelector((state: State) => state.talentOffers);
  const talentApplications = useSelector((state: State) => state.talentApplications);

  const [offers, setOffers] = React.useState<Offer[]>([]);
  const [applications, setApplications] = React.useState<Application[]>(form?.values?.applicationsToSubmit || []);
  const [matchingOffers, setMatchingOffers] = React.useState<Offer[]>([]);

  const prevTalentProfile = usePrevious(talentProfile);

  const handleChange = (offer: Offer, status: string, goodMatch?: boolean) => {
    const index = applications.findIndex((item) => !!item.offer && item.offer["@id"] === offer["@id"]);
    const newState: Array<Partial<Application>> = [...applications];
    if (index > -1) {
      newState.splice(index, 1);
    } else {
      newState.push({ offer, status, goodMatch, talent });
    }

    setApplications(newState as Application[]);
    if (form && field) {
      form.setFieldValue(field.name as keyof PhoneCallFormValues, newState);
    }
  };

  const isLoading = apiPendingRequests.some((req) => req.type === GetOffers.REQUEST);

  React.useEffect(() => {
    const filteredOffers = offersState
      .filter((os) => {
        return (
          !talentApplications.some(
            (application: Application) =>
              application.offer["@id"] === os["@id"] && ![applicationStatuses.toContact].includes(application.status),
          ) && !talentOffers.some((off: Offer) => off["@id"] === os["@id"])
        );
      })
      .map((osf) => osf);

    if (JSON.stringify(offers) !== JSON.stringify(filteredOffers)) {
      setOffers(filteredOffers);
    }
  }, [offersState, talentApplications, talentOffers]);

  React.useEffect(() => {
    if (talentProfile && talentProfile["@id"] !== prevTalentProfile?.["@id"]) {
      dispatch(
        getOffersAction([
          {
            multiple: true,
            name: "status",
            value: [offerStatuses.activated],
          },
        ]),
      );
      dispatch(getTalentOffersAction(talent["@id"]));
    }
  }, [dispatch, talentProfile, prevTalentProfile]);

  React.useEffect(() => {
    setMatchingOffers(talentOffers);
  }, [talentOffers]);

  React.useEffect(() => {
    if (talent) {
      dispatch(getTalentApplicationsAction(talent["@id"], [], "talentApplications"));
    }
  }, [talent, dispatch]);

  if (isLoading) {
    return <TalentOfferListLoader />;
  }

  const isChecked =
    (status: string) =>
    (offer: Offer): boolean => {
      return applications.some((opt) => !!opt.offer && opt.offer["@id"] === offer["@id"] && opt.status === status);
    };

  return (
    <div className={styles.offerListSelectContainer}>
      <div>
        {readOnly ? (
          <div className={styles.totalContainer}>
            <h4>Total : {matchingOffers.length}</h4>
          </div>
        ) : null}
        {matchingOffers.length > 0 ? (
          <div className={styles.offerListContainer}>
            {matchingOffers.map((item) => (
              <OfferLine
                key={`${item["@id"]} - offerLine`}
                offer={item}
                checkedContacted={isChecked(applicationStatuses.contacted)(item)}
                checkedAwaitingShortlist={isChecked(applicationStatuses.awaitingShortlist)(item)}
                handleSelectContacted={handleChange}
                handleSelectAwaitingShortlist={handleChange}
                readOnly={readOnly}
              />
            ))}
          </div>
        ) : (
          <div className="emptyList">{t`TalentOfferList.placeholder.empty`}</div>
        )}
      </div>
      {!readOnly ? (
        <Select
          options={offers}
          isSearchable={true}
          getOptionLabel={(option: Offer): string => `${option.company.name} - ${option.title}`}
          getOptionValue={(option: Offer): string => option["@id"]}
          value={undefined}
          onChange={(value: any) => {
            setMatchingOffers(
              value && value["@id"] && (offers.length === 0 || offers.some((item) => item["@id"] !== value["@id"]))
                ? [value, ...matchingOffers]
                : matchingOffers,
            );
          }}
          styles={defaultStyles}
        />
      ) : null}
    </div>
  );
};

const TalentOfferListLoader: React.FC = () => (
  <div className="offerListContainer accessibleOfferList">
    <Loader active={true} inline="centered">
      Loading
    </Loader>
  </div>
);

export default TalentOfferList;
