import { t } from "@lingui/macro";
import * as React from "react";
import { Link } from "react-router-dom";
import { Button, Icon, Item, Label } from "semantic-ui-react";

import { Company } from "../../../../interfaces/resources/company";

import { extractPageFromIri } from "../../../../services/api/helpers";
import transCompany from "../../../../translations/constants/company";
import { getCompanyImage } from "../../../App/Image";

import styles from "./index.module.scss";

interface Props {
  company: Company;
}

export const CompanyShort: React.FC<Props> = ({ company }: Props) => {
  return (
    <Item className={styles.companyShortItem}>
      <Link
        className={styles.companyShortLink}
        target="_blank"
        to={extractPageFromIri("companies", company["@id"])}
      ></Link>
      <Item.Image size="tiny" {...getCompanyImage(company.image)} />
      <Item.Content>
        <Item.Header>
          <Icon name="circle" size="small" color={company.hasActiveOffers ? "green" : "red"} />
          {company.name}
          <Label className="statusLabel">{t({ id: transCompany.status[company.status] })}</Label>
        </Item.Header>
        <Item.Description>
          {company.sectors ? company.sectors.map((s) => s.value).join(" - ") : null}
          <Button
            className={styles.companyShortEditLink}
            target="_blank"
            as={Link}
            to={extractPageFromIri("companies", company["@id"]) + "/edit"}
          >
            <Icon name="pencil" />
            {t`Constant.update`}
          </Button>
        </Item.Description>
        <Item.Extra>
          <span>
            {company.contacts && company.contacts.length > 0
              ? `${company.contacts[0].firstname} ${company.contacts[0].lastname}`
              : null}
          </span>
        </Item.Extra>
      </Item.Content>
    </Item>
  );
};
