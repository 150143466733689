import { t } from "@lingui/macro";
import cx from "classnames";
import * as React from "react";
import { BsPatchCheckFill, BsPatchExclamationFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import QualificationForm from "../../components/QualificationForm";
import { PageWithTabs, TabPanel, TabTrigger } from "../../components/Tabs";
import { TalentMode, talentStatuses } from "../../interfaces/resources/talent";
import { State } from "../../interfaces/state";
import { ApplicationsDashboard } from "../../pages/ApplicationsDashboardPage";
import { hashes } from "../../routes";

import Applications from "./Applications";
import styles from "./index.module.scss";
import { LinkedInResume } from "./LinkedInResume";
import { PhoneCalls } from "./PhoneCalls";
import Recap from "./Recap";
import Referrals from "./Referrals";

interface Props {
  readOnly?: boolean;
  mode?: TalentMode;
}
interface TabProps {
  slug: string;
  tabName: React.ReactNode;
  component?: JSX.Element | null;
}

export const TalentDetails: React.FC<Props> = ({ readOnly = false, mode }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const talentProfile = useSelector((state: State) => state.talentProfile);
  const talentState = useSelector((state: State) => state.talent);
  const tabHashes = React.useRef(hashes.talentDetails).current;
  const locationActivePanel = !!location.hash
    ? [hashes.dashboard.inProcess, hashes.dashboard.preProcess].includes(location.hash)
      ? tabHashes.manageApplications
      : location.hash
    : tabHashes.recap;
  const [activePanel, setActivePanel] = React.useState(locationActivePanel);
  const [tabs, setTabs] = React.useState<TabProps[]>([]);

  const addHashToUrl = (h: string): void => {
    if (location.hash !== h) {
      navigate(h);
    }
  };

  React.useEffect(() => {
    const hash = !!location.hash
      ? [hashes.dashboard.inProcess, hashes.dashboard.preProcess].includes(location.hash)
        ? tabHashes.manageApplications
        : location.hash
      : tabHashes.recap;

    setActivePanel(hash);
  }, [location, tabHashes]);

  React.useEffect(() => {
    setTabs([
      {
        tabName: t`TalentDetails.tabs.general`,
        slug: tabHashes.recap,
        component: <Recap readOnly={readOnly} mode={mode} />,
      },
      {
        tabName: t`TalentDetails.tabs.phoneCall-notes`,
        slug: tabHashes.phoneCall,
        component:
          (talentProfile && talentProfile.lastQualification && talentState?.status !== talentStatuses.retry)
          || readOnly ? (
            <PhoneCalls readOnly={readOnly} />
          ) : (
            <QualificationForm edition={false} />
          ),
      },
      {
        tabName: t`TalentDetails.tabs.managedApplications`,
        slug: tabHashes.manageApplications,
        component: talentState && <ApplicationsDashboard mode="talent" />,
      },
      {
        tabName: t`TalentDetails.tabs.applicationsList`,
        slug: tabHashes.applicationList,
        component: <Applications />,
      },
      {
        tabName: t`TalentDetails.tabs.mentoring`,
        slug: tabHashes.referrals,
        component: <Referrals />,
      },
      {
        tabName: t`TalentDetails.tabs.linkedinResume`,
        slug: tabHashes.linkedInResume,
        component: <LinkedInResume />,
      },
    ]);
  }, [talentProfile, talentState, tabHashes, readOnly, mode]);
  return (
    <>
      <PageWithTabs defaultActivePanel={activePanel} defaultActiveClass={styles.activeTab}>
        <div className={styles.container}>
          <div className={styles.tabTriggerListContainer}>
            <ul className={styles.tabTriggerList} data-testid="tabtrigger-list">
              {tabs.map((tab) => {
                return (
                  <li key={`talent-detail-tab--${tab.slug}`}>
                    <TabTrigger
                      tabKey={tab.slug}
                      onClick={(): void => {
                        addHashToUrl(tab.slug);
                      }}
                      addWrapper={false}
                    >
                      <div className={cx(styles.tabTriggerContent)} data-testid={`tab-${tab.slug}`}>
                        {tab.tabName}
                        {tab.slug === tabHashes.linkedInResume ? (
                          <div
                            className={cx(styles.linkedinResumeSvg, {
                              [styles.present]: talentState?.linkedinResumes && talentState.linkedinResumes.length > 0,
                            })}
                          >
                            {talentState?.linkedinResumes && talentState.linkedinResumes.length > 0 ? (
                              <BsPatchCheckFill />
                            ) : (
                              <BsPatchExclamationFill />
                            )}
                          </div>
                        ) : null}
                      </div>
                    </TabTrigger>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={styles.pagePanelContainer}>
            {tabs.map((tab) => {
              return (
                <TabPanel key={`talent-detail-panel--${tab.slug}`} panelKey={tab.slug}>
                  <div className={styles.tabPanelContainer}>{tab.component}</div>
                </TabPanel>
              );
            })}
          </div>
        </div>
      </PageWithTabs>
    </>
  );
};
