import { t } from "@lingui/macro";
import cx from "classnames";
import { Field, useFormikContext } from "formik";
import React, { FC } from "react";
import Input from "../../../../../components/Form/Formik/Input";
import RichTextEditor from "../../../../../components/Form/Formik/RichTextEditor";
import { applicationStatuses } from "../../../../../interfaces/resources/application";
import { Talent } from "../../../../../interfaces/resources/talent";
import globalStyles from "../../../../../styles/global.module.scss";
import { ApplicationOfferListButtons } from "../ApplicationOfferList";
import { PhoneCallFormValues } from "../helpers";
import TalentOfferList from "../TalentOfferList";
import styles from "./index.module.scss";
interface Props {
  talent: Talent;
  readOnly: boolean;
}

export const PHONECALL_FORM = "PHONECALL_FORM";

const PhoneCallFormFields: FC<Props> = ({ talent, readOnly }: Props) => {
  const fk = useFormikContext<PhoneCallFormValues>();
  const uniqId = `${talent["@id"]}-${PHONECALL_FORM}`;

  return (
    <>
      {!readOnly ? (
        <>
          <div className="formGroup">
            <Field name="title" component={Input} disabled={fk.isSubmitting} label={t`phoneCall.title`} />
          </div>
          <div className="formGroup formTextAreaGroup">
            <Field
              name="notes"
              component={RichTextEditor}
              label={t`phoneCall.notes`}
              uniqId={`${uniqId}-notes`}
              replaceIfEmpty={false}
              disabled={fk.isSubmitting}
            />
          </div>
          <div className="offerListContainer">
            <h5>{t`PhoneCalls.toPitchOffers.title`}</h5>
            <Field
              name="applicationsToPitch"
              component={ApplicationOfferListButtons}
              statuses={[
                applicationStatuses.refusedTalent,
                applicationStatuses.refusedElinoi,
                applicationStatuses.awaitingShortlist,
              ]}
              stateKey="talentApplicationsToPitch"
              talent={talent}
              statusToFilter={[applicationStatuses.toPitch, applicationStatuses.callBooked]}
            />
          </div>
          <div className="offerListContainer">
            <h5>{t`phoneCalls.toConfirmOffers.title`}</h5>
            <Field
              name="applicationsToConfirm"
              component={ApplicationOfferListButtons}
              statuses={[applicationStatuses.refusedTalent, applicationStatuses.awaitingShortlist]}
              stateKey="talentApplicationsToConfirm"
              talent={talent}
              statusToFilter={[applicationStatuses.interesting, applicationStatuses.contacted]}
            />
          </div>
          <div className={styles.exigibleOffersContainer}>
            <h5>{t`qualification.labels.exigibleOffers`}</h5>
            <Field name="applicationsToSubmit" component={TalentOfferList} talent={talent} readOnly={readOnly} />
          </div>
          <div className={globalStyles.tipsContainer}>
            Astuces :
            <ol>
              <li>
                Tu ne trouves pas une offre dans la liste ? Vérifie ses candidatures existantes, il doit déjà en avoir
                une pour l&apos;offre en question. Si ce n&apos;est pas le cas, préviens les tech !
              </li>
            </ol>
          </div>
          <div className={styles.submitCtaContainer}>
            <button
              className={cx(globalStyles.cta, globalStyles.primaryCta)}
              type="submit"
              onClick={() => fk.handleSubmit()}
              disabled={fk.isSubmitting}
            >{t`PhoneCalls.labels.submit`}</button>
          </div>
        </>
      ) : (
        <>
          <h5>{t`qualification.labels.exigibleOffers`}</h5>
          <Field name="applicationsToSubmit" component={TalentOfferList} talent={talent} readOnly={readOnly} />
        </>
      )}
    </>
  );
};

export default PhoneCallFormFields;
