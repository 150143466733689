import { t } from "@lingui/macro";
import cx from "classnames";
import * as React from "react";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { GetResponsibilities } from "../../actionTypes/responsibilities";
import { GetTalents } from "../../actionTypes/talents";
import { MyTalentItem } from "../../blocks/myTalentItem";
import { coreUserRoles } from "../../interfaces/resources/coreUser";
import { Responsibility } from "../../interfaces/resources/responsibility";
import { sortOrder, transSortOrder } from "../../interfaces/resources/sort";
import { Talent, talentStatuses } from "../../interfaces/resources/talent";
import { State } from "../../interfaces/state";
import { extractNextPageNumberFromIri, extractPageNumberFromIri } from "../../services/api/helpers";
import { getResponsibilitiesAction } from "../../services/api/responsibilities/actions";
import { getTalentsAction } from "../../services/api/talent/actions";
import { applyFilterAction } from "../../services/state/actions";
import globalStyles from "../../styles/global.module.scss";
import { useApiSelector, useGetResourceHook } from "../Utils/hook";
import LoaderComp from "../Utils/Loader";
import styles from "./index.module.scss";

interface Props {
  title?: React.ReactNode;
}

interface SortOption {
  label: string;
  value: string;
}

export const MyTalents: FC<Props> = (props: Props) => {
  const { title } = props;
  const dispatch = useDispatch();
  const { apiPendingRequests, apiErrors, apiSuccess } = useApiSelector();
  const currentUser = useSelector((state: State) => state.currentUser);
  const stateTalents = useSelector((state: State) => state.talents);
  const talentView = useSelector((state: State) => state.talentView);
  const responsibilities = useSelector((state: State) => state.responsibilities);
  const viewPage = React.useRef(0);
  const filterContext = useSelector((state: State) => state.filterContext);
  const [talents, setTalents] = React.useState<Talent[]>([]);
  const [initLoading, setInitLoading] = React.useState(true);
  const [pageLoading, setPageLoading] = React.useState(false);
  const [show, setShow] = React.useState(!!!currentUser?.roles.includes(coreUserRoles.am));
  const [selectedResponsibilities, setSelectedResponsibilities] = React.useState<Responsibility[]>([]);
  const [selectedRespChange, setSelectedRespChange] = React.useState(false);
  const sortOptions = React.useRef<SortOption[]>([
    {
      label: t({ id: transSortOrder.talentProfileUpdate }),
      value: sortOrder.talentProfileUpdate,
    },
    {
      label: t({ id: transSortOrder.lastLogin }),
      value: sortOrder.lastLogin,
    },
    {
      label: t({ id: transSortOrder.lastQualificationDate }),
      value: sortOrder.lastQualificationDate,
    },
  ]).current;
  const [selectedSortOption, setSelectedSortOption] = React.useState<SortOption>(sortOptions[0]);
  const [selectedSortChange, setSelectedSortChange] = React.useState(false);

  const talentsLoading = apiPendingRequests.some((e: any) => GetTalents.REQUEST === e.type) && !pageLoading;

  const loadMoreTalents = React.useCallback(() => {
    const nextPage = talentView && talentView["hydra:next"] ? extractPageNumberFromIri(talentView["hydra:next"]) : null;
    if (nextPage) {
      setPageLoading(true);
      dispatch(
        applyFilterAction({
          multiple: false,
          name: "page",
          value: nextPage,
        }),
      );
    }
  }, [dispatch, talentView]);

  const handleOnScroll = React.useCallback(() => {
    const offset = 500;
    const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
    const clientHeight = document.documentElement.clientHeight || window.innerHeight;
    const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight - offset;

    if (scrolledToBottom && !pageLoading) {
      loadMoreTalents();
    }
  }, [loadMoreTalents, pageLoading]);

  useGetResourceHook(apiErrors, apiPendingRequests, apiSuccess, GetResponsibilities, responsibilities, () =>
    dispatch(getResponsibilitiesAction()),
  );

  React.useEffect(() => {
    const talentViewPage = talentView?.["@id"] && extractNextPageNumberFromIri(talentView["@id"]);
    if (talentViewPage && talentViewPage !== viewPage.current && !(initLoading || talentsLoading)) {
      viewPage.current = talentViewPage;
      setTalents([...talents, ...stateTalents]);
      setPageLoading(false);
    }
  }, [stateTalents, talents, talentView, initLoading, talentsLoading]);

  React.useEffect(() => {
    if (currentUser && show) {
      dispatch(
        getTalentsAction([
          ...filterContext,
          {
            multiple: false,
            name: "order",
            param: "talentProfile.updatedAt",
            value: "DESC",
          },
          {
            multiple: true,
            name: "groups",
            value: ["internal:read:talentManager:talents:collection"],
          },
          {
            multiple: false,
            name: "status",
            value: talentStatuses.accepted,
          },
          {
            multiple: true,
            name: "talentManager",
            value: [currentUser["@id"]],
          },
          { multiple: false, name: "itemsPerPage", value: 15 },
        ]),
      );
    }
  }, [currentUser, filterContext, dispatch, show]);

  React.useEffect(() => {
    if (!selectedRespChange) {
      return;
    }
    const r: string[] = [];
    selectedResponsibilities.map((d) => {
      r.push(d["@id"]);
      return d;
    });
    // Remise des parametres à 0 pour afficher la bonne liste de talents
    viewPage.current = 0;
    setSelectedRespChange(false);
    setInitLoading(true);
    setTalents([]);
    // Ajout du filtre du role principal
    dispatch(
      applyFilterAction({
        multiple: true,
        name: "talentProfile.coreResponsibility",
        value: r,
      }),
    );
  }, [dispatch, selectedResponsibilities, selectedRespChange]);

  React.useEffect(() => {
    if (!selectedSortChange) {
      return;
    }
    // Remise des parametres à 0 pour afficher la bonne liste de talents
    viewPage.current = 0;
    setSelectedRespChange(false);
    setInitLoading(true);
    setTalents([]);
    // Ajout du filtre du role principal
    dispatch(
      applyFilterAction({
        multiple: false,
        name: "order",
        param: selectedSortOption.value,
        value: "DESC",
      }),
    );
  }, [dispatch, selectedSortOption, selectedSortChange]);

  React.useEffect(() => {
    if (apiPendingRequests.some((e: any) => GetTalents.REQUEST === e.type) && initLoading) {
      setInitLoading(false);
    }
  }, [apiPendingRequests, initLoading]);

  React.useEffect(() => {
    window.addEventListener("scroll", handleOnScroll);

    return () => {
      window.removeEventListener("scroll", handleOnScroll);
    };
  }, [handleOnScroll]);

  return (
    <>
      <div className={styles.titleCtaContainer}>
        {!!title ? title : null}
        <div>
          <button
            type="button"
            className={cx(globalStyles.cta, globalStyles.darkCta, globalStyles.inverted)}
            onClick={() => setShow(!show)}
          >
            {show ? "Cacher" : "Voir"}
          </button>
        </div>
      </div>
      {show ? (
        <>
          <div className={styles.filtersContainer}>
            <div>
              <label htmlFor="">Rôle Principal :</label>
              <Select
                classNamePrefix="status-filter"
                isMulti={true}
                isSearchable={true}
                isClearable={true}
                getOptionLabel={(resp) => resp.value}
                getOptionValue={(resp) => resp["@id"]}
                placeholder="Rôle principal"
                onChange={(datas) => {
                  setSelectedResponsibilities(datas as Responsibility[]);
                  setSelectedRespChange(true);
                }}
                value={selectedResponsibilities}
                options={responsibilities}
              />
            </div>
            <div>
              <label htmlFor="">Trié par :</label>
              <Select
                classNamePrefix="status-filter"
                placeholder="Rôle principal"
                onChange={(data) => {
                  if (data) {
                    setSelectedSortOption(data);
                    setSelectedSortChange(true);
                  }
                }}
                value={selectedSortOption}
                options={sortOptions}
              />
            </div>
          </div>
          {initLoading || talentsLoading ? (
            <div>
              <LoaderComp />
            </div>
          ) : (
            <div className={styles.pageContent}>
              <ul className={styles.talentsList}>
                {talents.map((talent, i) => (
                  <li key={`myTalentsPage-talent-item-${talent["@id"]}-${i}`} className={styles.talentItem}>
                    <MyTalentItem talent={talent} />
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      ) : null}
    </>
  );
};
