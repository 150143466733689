import { AxiosResponse } from "axios";
import { push } from "connected-react-router";
import { AnyAction } from "redux";
import { call, put, take, takeEvery, takeLatest } from "redux-saga/effects";

import { GetTalentProfile } from "../../../actionTypes/talentProfile";
import {
  CreateQualification,
  CreateReactivation,
  DeleteTalent,
  EditQualification,
  EditTalent,
  FilterTalentsByName,
  GetTalent,
  GetTalentForCollection,
  GetTalents,
  SortTalent,
} from "../../../actionTypes/talents";

import { Talent, talentStatuses } from "../../../interfaces/resources/talent";
import { routes } from "../../../routes";

import { apiFailureAction } from "../actions";
import {
  deleteTalentSuccessAction,
  editTalentSuccessAction,
  filterTalentsByNameSuccessAction,
  getTalentsSuccessAction,
  getTalentSuccessAction,
  sortTalentSuccessAction,
} from "./actions";
import * as apiClient from "./api";
import { editLinkedInProfileAction } from "./linkedInProfile/actions";
import { editTalentProfileAction, getTalentProfileAction } from "./talentProfile/actions";
import { createTalentQualificationAction, editTalentQualificationAction } from "./talentQualification/actions";

export function* deleteTalent(action: AnyAction) {
  try {
    yield call(apiClient.deleteTalent, action.iri);
    yield put(deleteTalentSuccessAction(action.iri));
    yield put(push(routes.app.home));
    alert("Le talent a bien été supprimé");
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* editTalent(action: AnyAction): any {
  try {
    const { linkedInProfile, talentProfile, ...talent } = action.req;
    if (linkedInProfile && linkedInProfile["@id"]) {
      yield put(editLinkedInProfileAction(linkedInProfile));
    }
    // editTalentProfile should not be called when submitting a reactivation form to avoid double calls to editTalentProfile
    if (talentProfile && talentProfile["@id"] && action.form !== "reactivation") {
      yield put(editTalentProfileAction(talentProfile));
    }
    const response = yield call(apiClient.editTalent, talent, action.validation);

    yield put(editTalentSuccessAction(response.data, action.form));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* filterTalentsByName(action: AnyAction) {
  try {
    const response: AxiosResponse<{ "hydra:member": Talent[] }> = yield call(
      apiClient.filterTalentsByName,
      action.text,
    );
    yield put(filterTalentsByNameSuccessAction(response.data["hydra:member"]));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* getTalent(action: AnyAction) {
  try {
    const response: AxiosResponse<Talent> = yield call(apiClient.getTalent, action.iri);
    const talent = response.data;
    if (action.type === GetTalent.REQUEST) {
      yield put(
        getTalentProfileAction(
          typeof talent.talentProfile === "string" ? talent.talentProfile : talent.talentProfile["@id"],
        ),
      );
      yield take(GetTalentProfile.SUCCESS);
    }
    yield put(getTalentSuccessAction(talent, action));
  } catch (e) {
    yield put(apiFailureAction(e, action, { iri: action.iri }));
  }
}

export function* getTalents(action: AnyAction) {
  try {
    const summary = action.summaryOnly;
    const response: AxiosResponse<{
      "hydra:member": Talent[];
      "hydra:totalItems": number;
      "hydra:view": any;
    }> = yield call(summary ? apiClient.getTalentsSummary : apiClient.getTalents, action.filterContext);
    yield put(
      getTalentsSuccessAction(
        response.data["hydra:member"],
        response.data["hydra:view"],
        response.data["hydra:totalItems"],
      ),
    );
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* sortTalent(action: AnyAction) {
  try {
    const { req, validation } = action;
    const { linkedInProfile, talentProfile, ...talent } = req;

    if (req.hasOwnProperty("linkedInProfile")) {
      yield put(editLinkedInProfileAction(linkedInProfile));
    }
    if (req.hasOwnProperty("talentProfile")) {
      yield put(editTalentProfileAction(talentProfile));
    }

    const response: AxiosResponse<Talent> = yield call(
      apiClient.editTalent,
      { "@id": action.iri, ...talent },
      validation,
    );

    yield put(sortTalentSuccessAction(response.data, validation));
  } catch (e) {
    yield put(apiFailureAction(e, action, { iri: action.iri }));
  }
}

export function* createQualification(action: AnyAction) {
  try {
    const { qualification, talentProfile, talentProfileRem, ...talent } = action.req;

    const resp: AxiosResponse<Talent> = yield call(apiClient.editTalent, talent, action.validation);
    yield put(
      editTalentProfileAction(
        {
          ...talentProfile,
          expectedRemuneration: {
            fixedSalary: talentProfileRem[0],
            fullPackage: talentProfileRem[1],
          },
          responsibilities: [],
        },
        action.form,
        action.validation,
      ),
    );

    if (talent.status === talentStatuses.refused) {
      yield put(createTalentQualificationAction(qualification, talent["@id"], action.form, "post_refused"));
    } else if (talent.status === talentStatuses.accepted) {
      yield put(createTalentQualificationAction(qualification, talent["@id"], action.form, "post_accepted"));
    }

    yield put(editTalentSuccessAction(resp.data, action.form));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* createReactivation(action: AnyAction) {
  try {
    const { qualification, talentProfile, talentProfileRem, ...talent } = action.req;

    yield put(
      editTalentProfileAction(
        {
          ...talentProfile,
          expectedRemuneration: {
            fixedSalary: talentProfileRem[0],
            fullPackage: talentProfileRem[1],
          },
          responsibilities: [],
        },
        action.form,
        action.validation,
      ),
    );

    yield put(createTalentQualificationAction(qualification, talent["@id"], action.form));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* editQualification(action: AnyAction) {
  try {
    const { talentProfile, qualification, talentProfileRem, ...talent } = action.req;

    const resp: AxiosResponse<Talent> = yield call(apiClient.editTalent, talent, action.validation);

    yield put(
      editTalentProfileAction(
        {
          ...talentProfile,
          expectedRemuneration: {
            fixedSalary: talentProfileRem[0],
            fullPackage: talentProfileRem[1],
          },
          responsibilities: [],
        },
        action.form,
      ),
    );
    yield put(editTalentQualificationAction(qualification, talent["@id"], action.form));

    yield put(editTalentSuccessAction(resp.data, action.form));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export default function* talentsSaga() {
  yield takeLatest(CreateQualification.DEFAULT, createQualification);
  yield takeLatest(CreateReactivation.DEFAULT, createReactivation);
  yield takeEvery(DeleteTalent.REQUEST, deleteTalent);
  yield takeLatest(EditQualification.DEFAULT, editQualification);
  yield takeEvery(EditTalent.REQUEST, editTalent);
  yield takeLatest(FilterTalentsByName.REQUEST, filterTalentsByName);
  yield takeLatest(GetTalent.REQUEST, getTalent);
  yield takeEvery(GetTalentForCollection.REQUEST, getTalent);
  yield takeLatest(GetTalents.REQUEST, getTalents);
  yield takeEvery(SortTalent.REQUEST, sortTalent);
}
