import { t } from "@lingui/macro";
import * as React from "react";
import { Search } from "semantic-ui-react";

import { GetOffers } from "../../../actionTypes/offers";
import { ApiRequest } from "../../../interfaces/api/request";

interface Props {
  apiPendingRequests: ApiRequest[];
  onChange: (e: any, data: any) => void;
  text: string;
}

export const OfferNameFilter: React.FC<Props> = ({ apiPendingRequests, text, onChange }: Props) => (
  <Search
    loading={apiPendingRequests.some((item: ApiRequest) => GetOffers.REQUEST === item.type)}
    onSearchChange={onChange}
    placeholder={t`OfferNameFilter.placeholder`}
    showNoResults={false}
    value={text}
  />
);
