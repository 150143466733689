import { ActionType } from "../interfaces/api/actionType";

export const EditLinkedInProfile: ActionType = {
  FAILURE: "EDIT_LINKED_IN_PROFILE_FAILURE",
  REQUEST: "EDIT_LINKED_IN_PROFILE_REQUEST",
  SUCCESS: "EDIT_LINKED_IN_PROFILE_SUCCESS",
};

export const CreateLinkedinResume: ActionType = {
  FAILURE: "CREATE_LINKEDIN_RESUME_FAILURE",
  REQUEST: "CREATE_LINKEDIN_RESUME_REQUEST",
  SUCCESS: "CREATE_LINKEDIN_RESUME_SUCCESS",
};
