import { GetResponsibilityType, GetResponsibilityTypes } from "../../../actionTypes/responsibilityTypes";
import { ResponsibilityType } from "../../../interfaces/resources/responsibility";

export function responsibilityType(state: ResponsibilityType | null = null, action: any) {
  switch (action.type) {
    case GetResponsibilityType.SUCCESS:
      return action.responsibilityType;
    default:
      return state;
  }
}

export function responsibilityTypes(state: ResponsibilityType[] = [], action: any) {
  switch (action.type) {
    case GetResponsibilityTypes.SUCCESS:
      return !!action.page ? [...state, ...action.responsibilityTypes] : action.responsibilityTypes;
    default:
      return state;
  }
}
