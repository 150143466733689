import { t } from "@lingui/macro";
import cx from "classnames";

import * as React from "react";
import { useDispatch } from "react-redux";
import { ModalWrapper } from "../../../components/ModalWrapper";
import { Company } from "../../../interfaces/resources/company";
import { deleteCompanyAction } from "../../../services/api/company/actions";
import globalStyles from "../../../styles/global.module.scss";
import styles from "./index.module.scss";

interface Props {
  company: Company;
  onCloseModal: () => void;
}

interface CtaProps {
  company: Company;
  className?: string;
}

export const DeleteCompanyModalCta: React.FC<CtaProps> = (props: CtaProps) => {
  const { company, className = "" } = props;
  const [openModal, setOpenModal] = React.useState(false);
  const closeModal = () => {
    setOpenModal(false);
  };
  return (
    <>
      <button
        type="button"
        className={cx(className, globalStyles.cta, globalStyles.alertCta)}
        onClick={() => {
          setOpenModal(true);
        }}
      >
        {t`Constant.delete`}
      </button>
      <ModalWrapper open={openModal} onCloseModal={closeModal}>
        <DeleteCompanyModal company={company} onCloseModal={closeModal} />
      </ModalWrapper>
    </>
  );
};

export const DeleteCompanyModal: React.FC<Props> = (props: Props) => {
  const { company, onCloseModal } = props;
  const dispatch = useDispatch();

  const closeModal = () => {
    if (typeof onCloseModal === "function") {
      onCloseModal();
    }
  };

  const onConfirmation = () => {
    dispatch(deleteCompanyAction(company["@id"]));
    closeModal();
  };

  return (
    <div className={styles.modal}>
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <h3 className={styles.title}>{t`companyDelete.confirm.header`}</h3>
        </div>
        <div>
          <p className={styles.text}>{t`companyDelete.confirm.content`}</p>
        </div>
        <div className={styles.ctaListContainer}>
          <div>
            <button
              type="button"
              onClick={closeModal}
              className={cx(globalStyles.cta, globalStyles.darkCta, globalStyles.inverted)}
            >
              {t`Constant.cancel`}
            </button>
          </div>
          <div>
            <button type="button" className={cx(globalStyles.cta, globalStyles.alertCta)} onClick={onConfirmation}>
              {t`Constant.delete`}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
