import cx from "classnames";
import * as React from "react";

import { BiCheck } from "react-icons/bi";
import { Seniority } from "..";
import { ResponsibilityWithSeniority } from "../../../interfaces/resources/responsibility";

import styles from "./index.module.scss";

interface Props {
  job: ResponsibilityWithSeniority;
  isChecked?: boolean;
  onClick?: () => void;
  onSeniorityChange?: (seniority: Seniority) => void;
}

export const responsibilitySeniority: { [key: string]: Seniority } = {
  junior: "Sans management",
  midlevel: "Management < 5 personnes",
  senior: "Management > 5 personnes",
};

export const Job: React.FC<Props> = (props: Props) => {
  const { job, onClick, onSeniorityChange, isChecked } = props;
  const onClickJob = (): void => {
    if (typeof onClick === "function") {
      onClick();
    }
  };

  const onChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.stopPropagation();
    if (typeof onSeniorityChange === "function") {
      onSeniorityChange(e.target.value as Seniority);
    }
  };
  return (
    <div className={cx(styles.job, { [styles.checked]: !!isChecked })} onClick={onClickJob}>
      <div className={styles.jobTopPart}>
        <span className={styles.customCheck}>
          <BiCheck />
        </span>
        <span className={styles.jobName}>{job.value}</span>
        {isChecked ? (
          <div className={styles.senioritySelectContainer}>
            <select
              name="seniority"
              defaultValue={job.seniority || responsibilitySeniority.junior}
              className={styles.senioritySelect}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={onChangeSelect}
            >
              <option value={responsibilitySeniority.junior}>Sans Management</option>
              <option value={responsibilitySeniority.midlevel}>{"Management < 5 personnes"}</option>
              <option value={responsibilitySeniority.senior}>{"Management > 5 personnes"}</option>
            </select>
          </div>
        ) : null}
      </div>
    </div>
  );
};
