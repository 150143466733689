import { t } from "@lingui/macro";

interface Const {
  roles: { [key: string]: string };
}

const transCoreUser: Const = {
  roles: {
    ROLE_ACCOUNT_MANAGER: t`coreUser.roles.ROLE_ACCOUNT_MANAGER`,
    ROLE_ADMIN: t`coreUser.roles.ROLE_ADMIN`,
    ROLE_COACH: t`coreUser.roles.ROLE_COACH`,
    ROLE_USER: t`coreUser.roles.ROLE_USER`,
    ROLE_NEW_BIZ: t`coreUser.roles.ROLE_NEW_BIZ`,
  },
};

export default transCoreUser;
