import { t } from "@lingui/macro";

export interface LabelType {
  default: "default";
  black: "black";
  blue: "blue";
  green: "green";
  lightGreen: "light_green";
  purple: "purple";
  red: "red";
  yellow: "yellow";
  accepted_missing_infos: "accepted_missing_infos";
  before_last_lap: "before_last_lap";
  last_lap: "last_lap";
  client_application: "client_application";
  offer_lap: "offer_lap";
  offer_standby: "offer_standby";
  offer_spontaneous: "offer_spontaneous";
  refused_elinoi: "refused_elinoi";
  refused_startup: "refused_startup";
  refused_talent: "refused_talent";
  interesting: "interesting";
  contacted: "contacted";
  tim: "tim";
  am: "am";
}

export const labelType: LabelType = {
  default: "default",
  black: "black",
  blue: "blue",
  green: "green",
  lightGreen: "light_green",
  purple: "purple",
  red: "red",
  yellow: "yellow",
  accepted_missing_infos: "accepted_missing_infos",
  before_last_lap: "before_last_lap",
  last_lap: "last_lap",
  client_application: "client_application",
  offer_lap: "offer_lap",
  offer_standby: "offer_standby",
  offer_spontaneous: "offer_spontaneous",
  refused_elinoi: "refused_elinoi",
  refused_startup: "refused_startup",
  refused_talent: "refused_talent",
  interesting: "interesting",
  contacted: "contacted",
  tim: "tim",
  am: "am",
};

export const applicationLabelTrans: { [key in keyof LabelType]?: string } = {
  offer_standby: t`Label.offerStandby`,
  last_lap: t`Label.lastLap`,
  before_last_lap: t`Label.beforeLastLap`,
  offer_lap: t`Label.offerLap`,
  refused_elinoi: t`Label.refusedElinoi`,
  refused_startup: t`Label.refusedStartup`,
  refused_talent: t`Label.refusedTalent`,
  accepted_missing_infos: t`Label.accepted_missing_infos`,
  yellow: t`Label.accepted_missing_infos`,
  red: t`Label.accepted_missing_infos`,
  offer_spontaneous: t`Label.offer_spontaneous`,
  interesting: t`Label.application.interesting`,
  contacted: t`Label.application.contacted`,
  client_application: t`Label.client.application`,
};
