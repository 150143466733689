import cx from "classnames";
import * as React from "react";

import { Application, applicationStatuses } from "../../../../interfaces/resources/application";
import { Offer } from "../../../../interfaces/resources/offer";

import { refreshMarkdownContent } from "../../../Form/Formik/Markdown";

import { useApiSelector } from "../../../Utils/hook";
import EmailForm from "./EmailForm";
import styles from "./index.module.scss";

import "./index.scss";
import { ShortlistItem } from "./ShortListItem";

interface Props {
  applications: Application[];
  offer: Offer;
  sendShortlist: (values: any, form: string) => void;
}

const ShortlistForm: React.FC<Props> = ({ applications, offer, sendShortlist }: Props) => {
  const { apiSuccess, apiPendingRequests, apiErrors } = useApiSelector();
  const [ignoredApplications, setIgnoredApplications] = React.useState<string[]>([]);
  const [activeIndex, setActiveIndex] = React.useState<number>(-1);

  React.useEffect(() => {
    if (applications.length > 0) {
      setActiveIndex(
        applications.findIndex(
          (application: Application) => application.status === applicationStatuses.awaitingShortlist,
        ),
      );
    }
  }, [applications]);

  const handleClick = (i: number) => {
    refreshMarkdownContent();
    setActiveIndex(i === activeIndex ? -1 : Number(i));
  };

  const switchToNextApplication = () => {
    refreshMarkdownContent();
    setActiveIndex(
      applications.length === activeIndex
        ? -1
        : applications.findIndex(
            (application: Application, i: number) =>
              i > activeIndex && application.status === applicationStatuses.awaitingShortlist,
          ),
    );
  };
  const removeTalentCard = (iri: string) => setIgnoredApplications([...ignoredApplications, iri]);
  return (
    <div className="shortlistFormContainer">
      <div className={styles.shortlistItemContainer}>
        {applications.map((application: Application, index: number) => {
          const formName = `talent-card-${application["@id"]}`;
          return (
            <div key={formName} className={cx("talentItem", styles.shortListItem)}>
              <ShortlistItem
                application={application}
                formName={formName}
                index={index}
                activeIndex={activeIndex}
                removeTalentCard={removeTalentCard}
                onClickHeaderItem={handleClick}
                switchToNextApplication={switchToNextApplication}
              />
            </div>
          );
        })}
      </div>
      <div className={styles.shortlistEmailFormContainer}>
        <EmailForm
          apiErrors={apiErrors}
          apiPendingRequests={apiPendingRequests}
          apiSuccess={apiSuccess}
          applications={applications}
          ignoredApplications={ignoredApplications}
          offer={offer}
          sendShortlist={sendShortlist}
        />
      </div>
    </div>
  );
};

export default ShortlistForm;
