import { t } from "@lingui/macro";
import cx from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";

import { CompanyImg } from "../../../../blocks/companyImg";
import { applicationStatuses } from "../../../../interfaces/resources/application";
import { Offer } from "../../../../interfaces/resources/offer";
import { extractPageFromIri } from "../../../../services/api/helpers";
import globalStyles from "../../../../styles/global.module.scss";
import transApplication from "../../../../translations/constants/application";

import styles from "./index.module.scss";

interface Props {
  checked: boolean;
  offer: Offer;
  handleSelectProps: (o: Offer) => void;
}

export const OfferLine: React.FC<Props> = (props: Props) => {
  const { checked, offer, handleSelectProps } = props;
  const onClickContacted = (e: any): void => {
    e.stopPropagation();
    return handleSelectProps(offer);
  };

  return (
    <div className={styles.offerLine}>
      <div className={styles.itemHeader}>
        <div className={styles.companyImgContainer}>
          <CompanyImg src={offer.company?.image?.url} alt={offer.title} />
        </div>
        <div className={styles.offerName}>
          <Link
            className={styles.offerNameLink}
            target="_blank"
            rel="noopener noreferrer"
            to={extractPageFromIri("offers", offer["@id"])}
          >
            {offer.name}
          </Link>
        </div>
        <div className={styles.salaryExpContainer}>
          <div>{offer.printSalaries}</div>
          <div>{offer.printExperienceYears}</div>
        </div>
      </div>
      <div className={styles.itemBody}>
        <div className={styles.ctaContainer}>
          <div>
            <button
              type="button"
              className={cx(styles.cta, globalStyles.cta, globalStyles.primaryCta, globalStyles.inverted, {
                [styles.active]: checked,
              })}
              onClick={onClickContacted}
            >
              <span>{t({ id: transApplication.status[applicationStatuses.contacted] })}</span>
            </button>
          </div>
        </div>
        <div className={styles.ownerContainer}>
          AM: {offer.owners?.length > 0 ? offer.owners.map((owner) => owner.firstname).join(", ") : "Aucun"}
        </div>
      </div>
    </div>
  );
};
