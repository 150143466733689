import { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";

import { GetExperienceYears } from "../../../actionTypes/experienceYears";
import { ExperienceYears } from "../../../interfaces/resources/experienceYears";
import { apiFailureAction } from "../actions";
import { extractNextPageNumberFromIri } from "../helpers";
import { getExperienceYearsAction, getExperienceYearsSuccessAction } from "./actions";
import * as apiClient from "./api";

export function* getExperienceYears(action: any) {
  try {
    const response: AxiosResponse<{
      "hydra:member": ExperienceYears[];
      "hydra:view"?: {
        "hydra:next"?: string;
      };
    }> = yield call(apiClient.getExperienceYears, action.page);
    yield put(getExperienceYearsSuccessAction(response.data["hydra:member"], action));
    if (response.data["hydra:view"] && response.data["hydra:view"]["hydra:next"]) {
      yield put(getExperienceYearsAction(extractNextPageNumberFromIri(response.data["hydra:view"]["hydra:next"])));
    }
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export default function* getExperienceYearsSaga() {
  yield takeLatest(GetExperienceYears.REQUEST, getExperienceYears);
}
