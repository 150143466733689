import { LOCATION_CHANGE } from "redux-first-history";
import { CreateCompany, EditCompany, GetCompanies, GetCompany } from "../../../actionTypes/companies";

import { Company } from "../../../interfaces/resources/company";
import { routes } from "../../../routes";

export function companies(state: any[] = [], action: any): Company[] {
  switch (action.type) {
    case GetCompanies.SUCCESS:
      if (
        !action.companies["hydra:view"].hasOwnProperty("hydra:first")
        || action.companies["hydra:view"]["@id"] === action.companies["hydra:view"]["hydra:first"]
      ) {
        return action.companies["hydra:member"];
      }
      return [...state, ...action.companies["hydra:member"]];
    case GetCompanies.FAILURE:
      return state;
    case EditCompany.SUCCESS:
      state.splice(
        state.findIndex((e: Company) => action.company["@id"] === e["@id"]),
        1,
        action.company,
      );
      return [...state];
    default:
      return state;
  }
}

export function company(state: Company | null = null, action: any): Company | null {
  switch (action.type) {
    case EditCompany.SUCCESS:
    case GetCompany.SUCCESS:
    case CreateCompany.SUCCESS:
      return action.company;
    case LOCATION_CHANGE:
      switch (action.payload.location.pathname) {
        case routes.companies.search:
          return null;
        case routes.offers.search:
          return null;
        default:
          return state;
      }
    case GetCompany.FAILURE:
      return state;
    default:
      return state;
  }
}

export function companyView(state: any = null, action: any) {
  switch (action.type) {
    case GetCompanies.SUCCESS:
      return action.companies["hydra:view"] || state;
    default:
      return state;
  }
}
