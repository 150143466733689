import { t } from "@lingui/macro";
import cx from "classnames";
import React, { SyntheticEvent } from "react";

import defaultLogo from "../../images/defaults/company.png";

import styles from "./index.module.scss";

interface Props {
  classNames?: string;
  src?: string;
  alt?: string;
  isRounded?: boolean;
}

export const CompanyImg: React.FC<Props> = (props: Props) => {
  const { classNames = "", src = "", alt = "", isRounded = true } = props;
  const imgSrc = src ? src : defaultLogo;

  return (
    <div className={cx(styles.companyImgContainer, { [classNames]: !!classNames }, { [styles.isRounded]: isRounded })}>
      <div className={cx(styles.companyImgContent)}>
        <img
          onError={(i: SyntheticEvent<HTMLImageElement>): string => (i.currentTarget.src = defaultLogo)}
          className={styles.companyImg}
          src={imgSrc}
          alt={alt ? alt : t`alts.logo.company`}
        />
      </div>
    </div>
  );
};
