import { differenceInDays, parseISO } from "date-fns";

interface LocalItemProps {
  key: string;
  data: ItemDataProps[];
}
interface ItemDataProps {
  date: string;
  value: string;
  version: number;
}

const allRichEditorStorage = () => {
  const archive: LocalItemProps[] = [];
  const keys = Object.keys(localStorage);

  for (const key of keys) {
    if (RegExp("api/v2|OFFER_MAIN_FORM|OFFER_ADVICE_FORM").test(key)) {
      const data = localStorage.getItem(key);
      archive.push({
        key,
        data: data === null ? [] : JSON.parse(data),
      });
    }
  }

  return archive;
};
export const cleanLocalStorage = () => {
  const arr = allRichEditorStorage();
  const now = new Date();
  for (const elem of arr) {
    elem.data = elem.data.reduce((prev: ItemDataProps[], curr: ItemDataProps) => {
      const diff = differenceInDays(now, parseISO(curr.date));
      if (diff < 7) {
        prev.push(curr);
      }
      return prev;
    }, []);

    if (elem.data.length) {
      localStorage.setItem(elem.key, JSON.stringify(elem.data));
    } else {
      localStorage.removeItem(elem.key);
    }
  }
};
