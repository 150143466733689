import * as React from "react";
import { useLocation } from "react-router-dom";

export const ErrorNotFoundScene: React.FC = (): JSX.Element => {
  const location = useLocation();
  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
};
