import { ErrorMessage as ErrorMessageFormik, ErrorMessageProps } from "formik";
import React, { FC } from "react";

import styles from "./index.module.scss";

interface OwnErrorMessageProps extends ErrorMessageProps {
  message?: string | undefined;
}

export const ErrorWording = ({ message }: { message: string }) => <div className={styles.errorMessage}>{message}</div>;

const ErrorMessage: FC<OwnErrorMessageProps> = ({ name, message }: OwnErrorMessageProps) => {
  return (
    <>
      <ErrorMessageFormik name={name} render={(msg: string) => <ErrorWording message={msg} />} />
      {!!message ? <ErrorWording message={message} /> : undefined}
    </>
  );
};

export default ErrorMessage;
