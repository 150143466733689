import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { change, getFormValues } from "redux-form";

import CompanyForm, { COMPANY_FORM } from "../../../components/Companies/CompanyForm";
import { Company } from "../../../interfaces/resources/company";

import { createCompanyAction } from "../../../services/api/company/actions";
import { getCoreUsersAction } from "../../../services/api/coreUser/actions";
import { getResponsibilitiesAction } from "../../../services/api/responsibilities/actions";
import { getSectorsAction } from "../../../services/api/sector/actions";

const mapStateToProps = (state: any) => {
  return {
    apiErrors: state.apiErrors,
    apiPendingRequests: state.apiPendingRequests,
    apiSuccess: state.apiSuccess,
    contact: state.contact,
    coreUsers: state.coreUsers,
    currentUser: state.currentUser,
    formValues: getFormValues(COMPANY_FORM)(state),
    responsibilities: state.responsibilities,
    sectors: state.sectors,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    changeFormValue: (field: string, value: any) => dispatch(change(COMPANY_FORM, field, value)),
    getCoreUsers: () => dispatch(getCoreUsersAction()),
    getResponsibilities: () => dispatch(getResponsibilitiesAction()),
    getSectors: () => dispatch(getSectorsAction()),
    submitCompany: (req: Partial<Company>, redirectOnSuccess?: boolean | string) =>
      dispatch(createCompanyAction(req, COMPANY_FORM, redirectOnSuccess)),
  };
};

const CreateCompanyForm = (props: any) => <CompanyForm {...props} state="creation" />;

export default connect(mapStateToProps, mapDispatchToProps)(CreateCompanyForm);
