import { AnyAction } from "redux";
import {
  ApiAuthenticationLogin,
  ApiAuthenticationLogout,
  GoogleAuthenticationLogin,
  SwitchOff,
  SwitchOn,
} from "../../../actionTypes/authentication";
import { GetCurrentUser } from "../../../actionTypes/coreUsers";
import { CoreUser } from "../../../interfaces/resources/coreUser";
import { JWT_TOKEN_KEY, JWT_TOKEN_KEY_TEMP } from "../helpers";

export interface AuthProps {
  isAuthed: boolean;
  currentUser?: null | CoreUser;
}

const defaultAuthState: AuthProps = {
  isAuthed: localStorage.getItem(JWT_TOKEN_KEY) !== null,
};
const defaultSwitch: boolean = localStorage.getItem(JWT_TOKEN_KEY_TEMP) !== null;

export function auth(state: AuthProps = defaultAuthState, action: AnyAction) {
  if (state.currentUser === null && action.type === GetCurrentUser.SUCCESS) {
    return {
      isAuthed: false,
    };
  }
  switch (action.type) {
    case ApiAuthenticationLogin.SUCCESS:
      return {
        isAuthed: true,
      };
    case ApiAuthenticationLogin.FAILURE:
    case ApiAuthenticationLogout.SUCCESS:
    case GoogleAuthenticationLogin.FAILURE:
      return {
        isAuthed: false,
      };
    case ApiAuthenticationLogout.FAILURE:
    default:
      return state;
  }
}

export function isSwitchOn(state: boolean = defaultSwitch, action: any) {
  switch (action.type) {
    case SwitchOn.SUCCESS:
      return true;
    case SwitchOff.DEFAULT:
      return false;
    default:
      return state;
  }
}
