import { t } from "@lingui/macro";
import * as React from "react";
import { useSelector } from "react-redux";
import { GetCompany } from "../../../actionTypes/companies";
import { ContactsListExpanded } from "../../../components/Contacts/ContactsListExpanded";
import { useApiSelector } from "../../../components/Utils/hook";
import LoaderComp from "../../../components/Utils/Loader";

import "./index.scss";

export const Contacts: React.FC = () => {
  const { apiPendingRequests } = useApiSelector();
  const company = useSelector((state: any) => state.company);

  const isLoading =
    !company || (apiPendingRequests.length > 0 && apiPendingRequests.some((e) => GetCompany.REQUEST === e.type));

  return isLoading ? (
    <LoaderComp />
  ) : (
    <div className="companyContainer">
      <div className="companyListContainer blockContainer">
        <ContactsListExpanded contacts={company.contacts} title={t`company.contacts.title`} />
      </div>
    </div>
  );
};
