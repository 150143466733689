import {
  ResponsibilityWithSeniority,
  TalentProfileResponsibility,
  Responsibility,
} from "../interfaces/resources/responsibility";
import { TalentProfile } from "../interfaces/resources/talentProfile";

export const getAddDeletePutTalentResponsibility = (
  talentProfile: TalentProfile,
  respToSort: ResponsibilityWithSeniority[] | null,
): {
  respsToAdd: Partial<ResponsibilityWithSeniority>[];
  respsToDelete: Partial<TalentProfileResponsibility>[];
  respsToPut: Partial<TalentProfileResponsibility>[];
} => {
  const respos = respToSort || [];
  const respsToPut: Partial<TalentProfileResponsibility>[] = [];
  const respsToDelete: Partial<TalentProfileResponsibility>[] = [];
  const respsToAdd = respos.filter(
    (resp) => !talentProfile.responsibilities.some((r) => resp["@id"] === r.responsibility["@id"]),
  );

  talentProfile.responsibilities.forEach((r: TalentProfileResponsibility) => {
    if (!respos.some((res: Responsibility) => res["@id"] === r.responsibility["@id"])) {
      respsToDelete.push(r);
    }
    if (respos.some((respo) => respo["@id"] === r.responsibility["@id"] && respo.seniority !== r.seniority)) {
      const respo = respos.find((res) => res["@id"] === r.responsibility["@id"]);
      if (respo) {
        respsToPut.push({ "@id": r["@id"], seniority: respo.seniority });
      }
    }
  });
  return { respsToAdd, respsToDelete, respsToPut };
};
