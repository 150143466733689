import { LinkedInProfile } from "../../../../interfaces/resources/linkedInProfile";
import client from "../../client";

export function editLinkedInProfile(linkedInProfile: Partial<LinkedInProfile>) {
  if (linkedInProfile["@id"]) {
    return client.put(linkedInProfile["@id"], linkedInProfile);
  }
}

export function createLinkedinResume(iri: string, file: string) {
  return client.put(iri, file);
}
