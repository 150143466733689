import { Application, ApplicationAccepted } from "./application";
import { Attachment } from "./attachment";
import { CoreUser } from "./coreUser";
import { DesiredPlace } from "./desiredPlace";
import { LinkedInProfile } from "./linkedInProfile";
import { LinkedinResume } from "./linkedinResume";
import { Responsibility } from "./responsibility";
import { TalentProfile } from "./talentProfile";
import { TalentSearchStatusEventsProps, TalentSegmentEventsProps, TalentStatusEventsProps } from "./talentStatusEvents";

export interface Talent {
  "@id": string;
  applications: Application[];
  attachments: Attachment[];
  createdAt: string;
  email: string;
  enabled: boolean;
  firstname: string;
  golden?: boolean;
  image: {
    url: string;
  };
  lastLogin: string;
  lastname: string;
  lastReactivationStatus?: string | null;
  lastTalentCardId?: number;
  lastTalentSearchStatusEvent?: TalentSearchStatusEventsProps;
  lastTalentStatusEvent?: TalentStatusEventsProps;
  lateStageApplications?: LateStageApplication[];
  linkedInProfile: LinkedInProfile;
  linkedinResumes?: LinkedinResume[];
  nextCallsBooked?: string[];
  phone: string;
  previousReactivationStatus?: string | null;
  processCount?: number;
  prospect?: string | null;
  queuedReason: string;
  reactivationStatus?: string | null;
  refererWebsite?: string;
  referred?: string;
  referrals?: string[];
  remindCallingAt: string;
  segment?: string;
  searchComplements: string;
  searchStatus: string;
  sendProspect?: boolean;
  slug: string;
  status: string;
  talentProfile: TalentProfile;
  talentStatusEvents: TalentStatusEventsProps[];
  talentSegmentEvents?: TalentSegmentEventsProps[];
  talentSearchStatusEvents: TalentSearchStatusEventsProps[];
  talentManager?: CoreUser;
  applicationsAccepted?: ApplicationAccepted[];
  updatedAt: string;
  documentFile?: string;
  sorter?: CoreUser;
}

export interface TalentView {
  "@id": string;
  "hydra:first": string;
  "hydra:last": string;
  "hydra:next": string;
}
export interface QualificationFormProps {
  "@id": string;
  qualification: any;
  status?: string;
  segment?: string;
  talentProfile: {
    "@id"?: string;
    desiredPlaces: DesiredPlace[];
    experience?: number;
    responsibilities: Responsibility[];
    coreResponsibility: Responsibility | undefined;
  };
  talentProfileRem: number[];
  notes: string | undefined;
}

export interface TalentEditionFormProps {
  "@id"?: string;
  firstname?: string;
  lastname?: string;
  email?: string;
  phone?: string;
  linkedInProfile?: {
    "@id"?: string;
    headline?: string;
    url?: string;
  };
  prospect?: string | null;
  segment?: string;
  searchComplements?: string;
  searchStatus?: string;
  searchStatusReason?: string | null;
  searchStatusData: { [key: string]: string | number | null };
  sendProspect?: boolean;
  talentManager?: CoreUser | null;
}

export type TalentMode = "new" | "hunt";

export interface LateStageApplication {
  applicationId: number;
  name: string;
  stage: string;
}

export const talentSegments = {
  segment1: "segment_1",
  segment2: "segment_2",
  segment4: "segment_4",
};

export const talentCallTypes = {
  call15: "call_15_min",
  call20: "call_20_min",
  call30: "call_30_min",
  call45: "call_45_min",
};

export const talentStatuses = {
  accepted: "status_accepted",
  blacklisted: "status_blacklisted",
  callBooked: "status_call_booked",
  cLevel: "status_to_c_level",
  pending: "status_pending",
  queued: "status_queued",
  refused: "status_denied",
  retry: "status_retry",
  searching: "status_retry",
  toCoach: "status_to_call",
  toOnboard: "status_to_onboard",
  toTest: "status_to_test",
};

export const talentCategories = {
  club: "segment_1",
  fastTrack: "segment_2",
  refused: "status_denied",
  blacklisted: "status_blacklisted",
};

export const talentReactivationStatuses = {
  to_contact: "to_contact",
  do_nothing: "do_nothing",
  refused: "refused",
  searching: "searching",
  passive_searching: "passive_searching",
  not_searching: "not_searching",
  contacted: "contacted",
};

export const talentSortedStatuses = [
  talentStatuses.toTest,
  talentStatuses.toCoach,
  talentStatuses.toOnboard,
  talentStatuses.cLevel,
];

export const searchStatuses = {
  searching: "searching",
  passive_searching: "passive_searching",
  not_searching: "not_searching",
};
export const searchStatusReasons = {
  job_found: "job_found",
  keep_current_job: "keep_current_job",
  project_launch: "project_launch",
  other: "other",
};

export const talentQueuedReasons: any = {
  city: "queued_reason_city",
  role: "queued_reason_role",
  student: "queued_reason_student",
};
