import { AnyAction } from "redux";
import { GetSalaryExpectations } from "../../../actionTypes/salaryExpectations";
import { SalaryExpectations } from "../../../interfaces/resources/salaryExpectations";

export function getSalaryExpectationsAction(page?: number) {
  return {
    page,
    type: GetSalaryExpectations.REQUEST,
  };
}

export function getSalaryExpectationsSuccessAction(salaryExpectations: SalaryExpectations[], action: AnyAction) {
  return {
    page: action.page,
    salaryExpectations,
    type: GetSalaryExpectations.SUCCESS,
  };
}
