import { t } from "@lingui/macro";
import * as React from "react";
import { Label } from "../.";
import { Tooltip } from "../../../components/Tooltip";
import { applicationStatuses } from "../../../interfaces/resources/application";
import { Offer, offerLabelStatuses, offerStatuses } from "../../../interfaces/resources/offer";
import transOffer from "../../../translations/constants/offer";
import styles from "./index.module.scss";

interface Props {
  offer: Offer;
  className?: string;
}

const TooltipContent: React.FC<{ labelStatus?: string }> = (props: { labelStatus?: string }) => {
  const { labelStatus } = props;

  if (!labelStatus) {
    return null;
  }

  return (
    <div className={styles.tooltip}>
      <div>{labelStatus}</div>
    </div>
  );
};

export const OfferStatusLabel: React.FC<Props> = (props: Props) => {
  const { offer, className } = props;
  const hasApplicationAccepted = offer.applications?.some((ap) => ap.status === applicationStatuses.accepted);
  const isDesactivated = !hasApplicationAccepted && offer.status === offerStatuses.desactivated;
  const isClosed = hasApplicationAccepted && offer.status === offerStatuses.desactivated;
  const type = isDesactivated ? undefined : "green";
  const labelStatus = isDesactivated
    ? offerLabelStatuses.desactivated
    : isClosed
    ? offerLabelStatuses.closed
    : offerLabelStatuses.activated;

  return (
    <Tooltip content={<TooltipContent labelStatus={labelStatus} />}>
      <Label
        type={type}
        content={t({ id: transOffer.labelStatus[labelStatus] })}
        className={!!className ? className : undefined}
      />
    </Tooltip>
  );
};
