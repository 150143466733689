import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import { CreateApplication, EditApplication } from "../../../../actionTypes/applications";
import { EditPhoneCall, GetPhoneCalls, PostPhoneCall, DeletePhoneCall } from "../../../../actionTypes/phoneCalls";
import { PhoneCall } from "../../../../interfaces/resources/phoneCall";

import { apiFailureAction } from "../../actions";
import * as applicationApiClient from "../../applications/api";
import * as apTalentApplication from "../talentApplication/api";
import { deletePhoneCallSuccessAction } from "./actions";
import * as apiClient from "./api";

export function* deletePhoneCall(action: AnyAction) {
  try {
    yield call(apiClient.deletePhoneCall, action.phoneCall);

    yield put(deletePhoneCallSuccessAction(action.phoneCall));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* editPhoneCall(action: AnyAction): any {
  try {
    const phoneCall = Object.assign({}, action.phoneCall);
    const applicationIris: string[] = [];

    if (phoneCall.hasOwnProperty("applications") && phoneCall.applications.length > 0) {
      const tApplications = yield call(apTalentApplication.getTalentApplications, phoneCall.talent["@id"]);
      const originalApplications = tApplications.data["hydra:member"];

      for (const application of phoneCall.applications) {
        const originalApplication = originalApplications.find((e: any) => e.offer["@id"] === application.offer["@id"]);
        if (originalApplication) {
          if (originalApplication === application.status) {
            applicationIris.push(originalApplication["@id"]);
            continue;
          }
          const resp = yield call(applicationApiClient.editApplication, originalApplication["@id"], {
            status: application.status,
          });
          yield put({
            application: resp,
            type: EditApplication.SUCCESS,
          });
          applicationIris.push(resp.data["@id"]);
        } else {
          const resp = yield call(applicationApiClient.createApplication, application);
          yield put({
            application: resp.data,
            type: CreateApplication.SUCCESS,
          });
          applicationIris.push(resp.data["@id"]);
        }
      }
    }
    phoneCall.applications = applicationIris;

    const response: AxiosResponse<PhoneCall> = yield call(apiClient.editPhoneCall, phoneCall, action.validation);
    yield put({
      form: action.form,
      phoneCall: response.data,
      type: EditPhoneCall.SUCCESS,
    });
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* getPhoneCalls(action: AnyAction) {
  try {
    const response: AxiosResponse<{ "hydra:member": PhoneCall[] }> = yield call(apiClient.getPhoneCalls, action.iri);
    yield put({
      talentIri: action.iri,
      phoneCalls: response.data["hydra:member"],
      type: GetPhoneCalls.SUCCESS,
    });
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* postPhoneCall(action: AnyAction): any {
  try {
    const phoneCall = Object.assign({}, action.req);
    const applicationIris: string[] = [];

    if (phoneCall.hasOwnProperty("applications") && phoneCall.applications.length > 0) {
      const tApplications = yield call(apTalentApplication.getTalentApplications, phoneCall.talent["@id"]);
      const originalApplications = tApplications.data["hydra:member"];

      for (const application of phoneCall.applications) {
        const originalApplication = originalApplications.find((e: any) => e.offer["@id"] === application.offer["@id"]);
        if (originalApplication) {
          if (originalApplication === application.status) {
            applicationIris.push(originalApplication["@id"]);
            continue;
          }
          const resp = yield call(applicationApiClient.editApplication, originalApplication["@id"], {
            status: application.status,
            goodMatch: application.goodMatch,
          });
          yield put({
            application: resp,
            type: EditApplication.SUCCESS,
          });
          applicationIris.push(resp.data["@id"]);
        } else {
          const resp = yield call(applicationApiClient.createApplication, application);
          yield put({
            application: resp.data,
            type: CreateApplication.SUCCESS,
          });
          applicationIris.push(resp.data["@id"]);
        }
      }
    }
    phoneCall.applications = applicationIris;
    const response: AxiosResponse<PhoneCall> = yield call(apiClient.postPhoneCall, phoneCall);
    yield put({
      phoneCall: response.data,
      type: PostPhoneCall.SUCCESS,
    });
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export default function* phoneCallsSaga() {
  yield takeEvery(EditPhoneCall.REQUEST, editPhoneCall);
  yield takeEvery(PostPhoneCall.REQUEST, postPhoneCall);
  yield takeLatest(DeletePhoneCall.REQUEST, deletePhoneCall);
  yield takeEvery(GetPhoneCalls.REQUEST, getPhoneCalls);
}
