import { t } from "@lingui/macro";

export const sortOrder = {
  lastLogin: "lastLogin",
  lastQualificationDate: "talentProfile.lastQualificationDate",
  talentProfileUpdate: "talentProfile.updatedAt",
};

export const transSortOrder = {
  lastLogin: t`SortOrder.lastLogin`,
  lastQualificationDate: t`SortOrder.talentProfile.lastQualificationDate`,
  talentProfileUpdate: t`SortOrder.talentProfile.updatedAt`,
};
