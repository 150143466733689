import { t } from "@lingui/macro";
import * as React from "react";
import { Accordion, Checkbox, Icon, Item } from "semantic-ui-react";
import { transTalent } from "../../../translations/constants/talent";

interface Props {
  activeIndexes?: number[];
  handleClick?: (e: any, titleProps: any) => void;
  index?: number;
  multiple: boolean;
  onChange: (data: any) => void;
  selected: any;
}

export const SearchStatusFilter: React.FC<Props> = ({
  activeIndexes,
  handleClick,
  index,
  multiple,
  onChange,
  selected,
}: Props) => {
  const active = activeIndexes ? activeIndexes.some((item: number) => item === index) : false;
  return (
    <div>
      <Accordion.Title active={active} index={index} onClick={handleClick}>
        <h3>
          {t`SearchStatusFilter.title`}
          <Icon name={active ? "chevron up" : "chevron down"} />
        </h3>
      </Accordion.Title>
      <Accordion.Content active={active}>
        <Item.Group>
          {Object.keys(transTalent.searchStatus).map((item: any, i: number) => (
            <Item key={i}>
              <Checkbox
                checked={Array.isArray(selected) ? selected.some((e: string) => e === item) : selected === item}
                label={t({ id: transTalent.searchStatus[item] })}
                multiple={multiple}
                onClick={(_: any, data: any): void => onChange(data)}
                value={item}
              />
            </Item>
          ))}
          <Item>
            <Checkbox
              checked={Array.isArray(selected) && selected.length === 0}
              label={t`SearchStatusFilter.all`}
              multiple={!multiple}
              onClick={(_: any, data: any): void => onChange(data)}
            />
          </Item>
        </Item.Group>
      </Accordion.Content>
    </div>
  );
};
