import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import {
  CreateCoreUser,
  DeleteCoreUser,
  EditCoreUser,
  GetCoreUserApplications,
  GetCoreUsers,
  GetCurrentUser,
} from "../../../actionTypes/coreUsers";
import { Application } from "../../../interfaces/resources/application";
import { CoreUser } from "../../../interfaces/resources/coreUser";
import { apiFailureAction } from "../actions";
import { extractNextPageNumberFromIri } from "../helpers";
import {
  createCoreUserSuccessAction,
  deleteCoreUserSuccessAction,
  editCoreUserSuccessAction,
  getCoreUserApplicationsAction,
  getCoreUsersAction,
  getCoreUsersSuccessAction,
  getCurrentUserSuccessAction,
} from "./actions";
import * as apiClient from "./api";

export function* getCurrentUser(action: AnyAction) {
  try {
    const response: AxiosResponse<CoreUser> = yield call(apiClient.getCurrentUser);
    yield put(getCurrentUserSuccessAction(response.data));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* getCoreUsers(action: AnyAction) {
  try {
    const response: AxiosResponse<{
      "hydra:member": CoreUser[];
    }> = yield call(apiClient.getCoreUsers);
    yield put(getCoreUsersSuccessAction(response.data["hydra:member"]));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* deleteCoreUser(action: AnyAction) {
  try {
    yield call(apiClient.deleteCoreUser, action.iri);
    yield put(deleteCoreUserSuccessAction());
    yield put(getCoreUsersAction());
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* createCoreUser(action: AnyAction) {
  try {
    const response: AxiosResponse<CoreUser> = yield call(apiClient.createCoreUser, action.coreUser);
    yield put(createCoreUserSuccessAction(response.data));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* editCoreUser(action: AnyAction) {
  try {
    const response: AxiosResponse<CoreUser> = yield call(apiClient.editCoreUser, action.coreUser);
    yield put(editCoreUserSuccessAction(response.data, action));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export function* getCoreUserApplications(action: AnyAction) {
  try {
    const response: AxiosResponse<{
      "hydra:member": Application[];
      "hydra:view"?: {
        "hydra:next"?: string;
      };
    }> = yield call(apiClient.getCoreUserApplications, action.coreUser, action.filterContext, action.page);
    const applications = response.data["hydra:member"];
    yield put({
      applications,
      page: action.page,
      filterContext: action.filterContext,
      type: GetCoreUserApplications.SUCCESS,
    });
    if (response.data["hydra:view"] && response.data["hydra:view"]["hydra:next"]) {
      yield put(
        getCoreUserApplicationsAction(
          action.coreUser,
          action.filterContext,
          extractNextPageNumberFromIri(response.data["hydra:view"]["hydra:next"]),
        ),
      );
    }
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export default function* getCurrentUserSaga() {
  yield takeEvery(CreateCoreUser.REQUEST, createCoreUser);
  yield takeEvery(DeleteCoreUser.REQUEST, deleteCoreUser);
  yield takeLatest(EditCoreUser.REQUEST, editCoreUser);
  yield takeLatest(GetCoreUserApplications.REQUEST, getCoreUserApplications);
  yield takeLatest(GetCoreUsers.REQUEST, getCoreUsers);
  yield takeLatest(GetCurrentUser.REQUEST, getCurrentUser);
}
