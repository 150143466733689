import React from "react";
import { useHits } from "react-instantsearch-hooks-web";
import { AlgoliaTalentCard } from "../../pages/AlgoliaSearchPage/AlgoliaTalentCard";

export const Hits = (props: { segment: string | undefined }) => {
  const { segment } = props;
  const { hits, sendEvent } = useHits();

  return (
    <div className="ais-Hits">
      <ol className="ais-Hits-list">
        {hits.map((hit) => (
          <li key={hit.objectID} className="ais-Hits-item">
            <AlgoliaTalentCard hitTalent={hit} sendEvent={sendEvent} segment={segment} />
          </li>
        ))}
      </ol>
    </div>
  );
};
