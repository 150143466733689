import { t } from "@lingui/macro";
import * as React from "react";
import { useSelector } from "react-redux";

import { GetOffer } from "../../../actionTypes/offers";
import { ContactsListExpanded } from "../../../components/Contacts/ContactsListExpanded";
import { useApiSelector } from "../../../components/Utils/hook";
import LoaderComp from "../../../components/Utils/Loader";
import { State } from "../../../interfaces/state";
import styles from "./index.module.scss";

export const Contacts: React.FC<Record<string, unknown>> = () => {
  const { apiPendingRequests } = useApiSelector();
  const offer = useSelector((state: State) => state.offer);

  const isLoading =
    !offer || (apiPendingRequests.length > 0 && apiPendingRequests.some((e) => GetOffer.REQUEST === e.type));

  return isLoading ? (
    <LoaderComp />
  ) : (
    <div className={styles.offerContainer}>
      <ContactsListExpanded contacts={offer.contacts} title={t`offer.contacts.title`} />
    </div>
  );
};
