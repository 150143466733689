import { AnyAction } from "redux";
import { LOCATION_CHANGE } from "redux-first-history";

import { CreateLinkedinResume } from "../../../../actionTypes/linkedInProfile";
import {
  CreateTalentProfileResponsibility,
  DeleteTalentProfileResponsibility,
  EditTalentProfile,
  GetTalentProfile,
} from "../../../../actionTypes/talentProfile";
import { GetTalent } from "../../../../actionTypes/talents";

import { TalentProfile } from "../../../../interfaces/resources/talentProfile";
import { routes } from "../../../../routes";

export function talentProfile(state: TalentProfile | null = null, action: AnyAction) {
  switch (action.type) {
    case GetTalentProfile.SUCCESS:
    case EditTalentProfile.SUCCESS:
      return action.talentProfile;
    case CreateLinkedinResume.SUCCESS:
      return action.talent.talentProfile;
    case LOCATION_CHANGE:
      return [routes.talents.show].includes(action.payload.location.pathname) ? null : state;
    case GetTalent.REQUEST:
      return null;
    case DeleteTalentProfileResponsibility.SUCCESS:
      // return talentProfile with removed responsibilities
      return {
        ...state,
        responsibilities: state?.responsibilities.filter((r) => r["@id"] !== action.talentProfileResponsibility),
      };
    case CreateTalentProfileResponsibility.SUCCESS:
      return {
        ...state,
        responsibilities: state?.responsibilities.concat(action.talentProfileResponsibility),
      };
    default:
      return state;
  }
}

export function talentProfiles(state: { [iri: string]: TalentProfile } = {}, action: AnyAction) {
  switch (action.type) {
    case EditTalentProfile.SUCCESS:
    case GetTalentProfile.SUCCESS:
      return {
        ...state,
        [action.talentProfile["@id"]]: action.talentProfile,
      };
    case CreateLinkedinResume.SUCCESS:
      return {
        ...state,
        [action.talent.talentProfile["@id"]]: action.talent.talentProfile,
      };
    default:
      return state;
  }
}
