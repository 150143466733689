import { GetHuntTalents } from "../../../actionTypes/hunt";

import { FilterContext } from "../../../interfaces/api/filter";

export function getHuntTalentsAction(filterContext: FilterContext) {
  return {
    filterContext,
    type: GetHuntTalents.REQUEST,
  };
}
