import { t } from "@lingui/macro";
import * as React from "react";
import AvatarEditor from "react-avatar-editor";
import { WrappedFieldInputProps, WrappedFieldMetaProps } from "redux-form";
import { Button, Form, Icon, Image, Modal } from "semantic-ui-react";
// eslint-disable-next-line import/no-unresolved
import { SemanticWIDTHS } from "semantic-ui-react/dist/commonjs/generic";

import { getImage } from "../../App/Image";
import placeholder from "../../App/Image/img/default.png";
import RoundImage from "../../Design/RoundImage";

import "./index.scss";

interface AvatarEditorProps {
  input: WrappedFieldInputProps;
  meta: WrappedFieldMetaProps;
  width?: SemanticWIDTHS;
  rounded?: boolean;
  default?: string | null;
}

interface AvatarEditorState {
  image: string;
  open: boolean;
  rotate: number;
  scale: number;
}

class Avatar extends React.PureComponent<AvatarEditorProps, AvatarEditorState> {
  private editor: any;
  constructor(props: AvatarEditorProps) {
    super(props);
    this.state = {
      image: this.props.input.value,
      open: false,
      rotate: 0,
      scale: 1,
    };
  }

  public componentDidUpdate = (prevProps: AvatarEditorProps) => {
    if ((!prevProps.input.value || prevProps.input.value !== this.props.input.value) && this.props.input.value) {
      this.setState({ image: this.props.input.value });
    }
  };

  public handleFileChange = (e: any) => this.setState({ image: e.target.files[0] });

  public handleScaleChange = (_: any, data: any) => this.setState({ scale: Number(data.value) });

  public handleRotateLeft = () => this.setState({ rotate: this.state.rotate - 90 });
  public handleRotateRight = () => this.setState({ rotate: this.state.rotate + 90 });

  public handleOpen = () => this.setState({ open: true });
  public handleClose = () => this.setState({ open: false });

  public handleEditorRef = (editor: any) => (this.editor = editor);

  public handleSave = () => {
    if (this.editor) {
      const base64Image = this.editor.getImage().toDataURL();
      this.props.input.onChange(base64Image);
      this.setState({
        image: base64Image,
        open: false,
        rotate: 0,
        scale: 1,
      });
    }
  };

  public render() {
    return (
      <Form.Field className="avatarField" width={this.props.width}>
        {this.props.rounded ? (
          <RoundImage
            size="80px"
            {...getImage({ url: this.state.image || this.props.default || "" })}
            onClick={this.handleOpen}
          />
        ) : (
          <Image
            size="tiny"
            {...getImage({ url: this.state.image || this.props.default || "" })}
            onClick={this.handleOpen}
          />
        )}
        <Modal open={this.state.open} onClose={this.handleClose} size="tiny" className="avatarEditor">
          <Modal.Header>
            {t`AvatarEditor.title`}
            <Icon name="close" style={{ float: "right", opacity: "0.3" }} onClick={this.handleClose} />
          </Modal.Header>
          <Modal.Content>
            <AvatarEditor
              image={this.state.image || String(this.props.default) || placeholder}
              ref={this.handleEditorRef}
              crossOrigin="anonymous"
              width={200}
              height={200}
              borderRadius={200}
              color={[255, 255, 255, 0.8]}
              border={5}
              scale={this.state.scale}
              rotate={this.state.rotate}
            />
            <Form>
              <Form.Group widths="equal">
                <Form.Input
                  type="range"
                  label={t`AvatarEditor.labels.scale`}
                  min="1"
                  max="5"
                  step="0.2"
                  value={this.state.scale}
                  onChange={this.handleScaleChange}
                />
                <Form.Field>
                  <label>{t`AvatarEditor.labels.rotate`}</label>
                  <span onClick={this.handleRotateLeft}>
                    <Icon name="undo" />
                    {t`AvatarEditor.labels.rotateLeft`}
                  </span>
                  <span onClick={this.handleRotateRight}>
                    <Icon name="redo" />
                    {t`AvatarEditor.labels.rotateRight`}
                  </span>
                </Form.Field>
              </Form.Group>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <label htmlFor="file">
              <Icon name="folder open outline" />
              {t`AvatarEditor.labels.changeFile`}
            </label>
            <input id="file" type="file" onChange={this.handleFileChange} style={{ display: "none" }} />
            <Button disabled={this.state.image === ""} primary onClick={this.handleSave}>
              <Icon name="check" />
              {t`AvatarEditor.labels.validate`}
            </Button>
          </Modal.Actions>
        </Modal>
      </Form.Field>
    );
  }
}

export default Avatar;
