import { t } from "@lingui/macro";
import * as React from "react";
import { Button, Icon, Modal } from "semantic-ui-react";

import EditCoreUserContainer from "../../../containers/CoreUsers/EditCoreUserContainer";

import { CoreUser } from "../../../interfaces/resources/coreUser";

import "./index.scss";

const ModalTrigger = ({ openModal }: { openModal: () => void }) => (
  <Button icon="pencil" content={t`Constant.update`} onClick={openModal} />
);

interface Props {
  coreUser: CoreUser;
}

export default class CoreUserEditionModal extends React.PureComponent<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = { modalOpen: false };
  }

  public handleOpen = () => this.setState({ modalOpen: true });

  public handleClose = () => this.setState({ modalOpen: false });

  public render() {
    return (
      <Modal
        trigger={<ModalTrigger openModal={this.handleOpen} />}
        open={this.state.modalOpen}
        onClose={this.handleClose}
      >
        <Modal.Header>
          <Icon name="close" onClick={this.handleClose} />
        </Modal.Header>
        <Modal.Content>
          <div className="editUserModalContent">
            <EditCoreUserContainer state="coreUser" coreUser={this.props.coreUser} />
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}
