import { ApplyFilter } from "../../actionTypes/internalState";

import { FilterClause, FilterContext } from "../../interfaces/api/filter";

export function applyFilterAction(filterClause: FilterClause<any>) {
  return {
    filterClause,
    type: ApplyFilter.DEFAULT,
  };
}

export function shareFilterContextAction(filterContext: FilterContext) {
  return {
    filterContext,
    type: ApplyFilter.SHARE,
  };
}

export function resetFilterAction() {
  return {
    type: ApplyFilter.RESET,
  };
}
