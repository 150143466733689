import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { call, put, takeEvery } from "redux-saga/effects";

import { GetTalentApplications } from "../../../../actionTypes/talentApplications";
import { Application } from "../../../../interfaces/resources/application";

import { apiFailureAction } from "../../actions";
import { getTalentApplicationsSuccessAction } from "./actions";
import * as apiClient from "./api";

export function* getTalentApplications(action: AnyAction) {
  try {
    const response: AxiosResponse<{ "hydra:member": Application[] }> = yield call(
      apiClient.getTalentApplications,
      action.iri,
      action.filterContext,
    );
    yield put(getTalentApplicationsSuccessAction(response.data["hydra:member"], action.stateKey));
  } catch (e) {
    yield put(apiFailureAction(e, action, { stateKey: action.stateKey }));
  }
}

export default function* talentApplicationsSaga() {
  yield takeEvery(GetTalentApplications.REQUEST, getTalentApplications);
}
