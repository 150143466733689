import cx from "classnames";
import * as React from "react";
import { BiCheck } from "react-icons/bi";
import { Responsibility, ResponsibilityWithSeniority } from "../../interfaces/resources/responsibility";

import { GroupedResponsibility, groupResponsibilities } from "./helpers";
import styles from "./index.module.scss";
import { Job } from "./Job";

interface Props {
  isLoading?: boolean;
  onChange: (responsibilities: Responsibility[]) => void;
  options: Responsibility[];
  value: ResponsibilityWithSeniority[];
  hasTooltip?: boolean;
}

export type Seniority = "Sans management" | "Management < 5 personnes" | "Management > 5 personnes";

export const ResponsibilitySelect: React.FC<Props> = (props: Props) => {
  const { onChange, options, value } = props;
  const [selected, setSelected] = React.useState<ResponsibilityWithSeniority[]>(value);
  const [initLoading, setInitLoading] = React.useState(true);
  const [selectedFamilies, setSelectedFamilies] = React.useState<string[]>([]);
  const grouped = groupResponsibilities(options);

  const updateSelected = (responsibility: Responsibility): void => {
    const index = selected.findIndex((elem: ResponsibilityWithSeniority) => elem["@id"] === responsibility["@id"]);
    let newList: ResponsibilityWithSeniority[];
    responsibility.seniority = "Sans management";
    if (index !== -1) {
      newList = [...selected];
      newList.splice(index, 1);
    } else {
      newList = [...selected, responsibility];
    }
    setSelected(newList);
    onChange(newList);
  };

  const updateSeniority = (responsibility: ResponsibilityWithSeniority, sen: Seniority): void => {
    const index = selected.findIndex((elem) => elem["@id"] === responsibility["@id"]);
    let newList: ResponsibilityWithSeniority[] = [];

    if (index !== -1) {
      newList = [...selected];
      newList[index].seniority = sen;
    }

    setSelected(newList);
    onChange(newList);
  };

  const onClickFamily = (fam: GroupedResponsibility): void => {
    const families = [...selectedFamilies];
    const famIndex = families.indexOf(fam.id);
    if (famIndex > -1) {
      families.splice(famIndex, 1);
      const newList: ResponsibilityWithSeniority[] = selected.filter((resp) => {
        return !!!fam.options.some((elem: ResponsibilityWithSeniority) => elem["@id"] === resp["@id"]);
      });
      setSelected(newList);
      onChange(newList);
    } else {
      families.push(fam.id);
    }
    setSelectedFamilies(families);
  };

  React.useEffect(() => {
    if (initLoading) {
      setInitLoading(false);
      const initSelectedFamilies: string[] = [];
      selected.map((s) => {
        const repType = s.responsibilityType["@id"];
        if (!initSelectedFamilies.includes(repType)) {
          initSelectedFamilies.push(repType);
        }
        return s;
      });
      setSelectedFamilies(initSelectedFamilies);
    }
  }, [selected, initLoading]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.familiesPartContainer}>
        <div className={styles.partHeader}>
          <h3 className={styles.partLabel}>Les familles de métiers qui l&apos;intéressent</h3>
        </div>
        <div className={styles.familiesNavList}>
          {grouped.map((fam) => {
            const isSelected = selectedFamilies.includes(fam.id);
            return (
              <div key={fam.id} className={styles.familyNavItem}>
                <div
                  className={cx(styles.familyNavItemContent, { [styles.selected]: isSelected })}
                  onClick={(): void => {
                    onClickFamily(fam);
                  }}
                >
                  <span className={styles.customCheck}>
                    <BiCheck />
                  </span>
                  <span>{fam.label}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div>
        {selectedFamilies.length ? (
          <>
            <div className={styles.partHeader}>
              <div className={styles.partTitle}>
                <h3 className={styles.partLabel}>Les différents postes</h3>
              </div>
            </div>
            <div className={styles.familyList}>
              {grouped.map((fam) => {
                const jobs = fam.options;
                return selectedFamilies.includes(fam.id) ? (
                  <div key={`family-options-list-${fam.id}`}>
                    <h4 className="formLabel">{fam.label}</h4>
                    <div className={styles.jobList}>
                      {jobs.map((job) => {
                        const selectedJob = selected.find((e) => e["@id"] === job["@id"]);
                        if (!!selectedJob) {
                          job.seniority = selectedJob.seniority;
                        }
                        return (
                          <div className={styles.jobItem} key={`job-item-${fam.id}-${job["@id"]}`}>
                            <Job
                              job={job}
                              isChecked={!!selectedJob}
                              onClick={(): void => {
                                updateSelected(job);
                              }}
                              onSeniorityChange={(seniority: Seniority): void => {
                                updateSeniority(job, seniority);
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null;
              })}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};
