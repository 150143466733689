import { t } from "@lingui/macro";
import { Trans } from "@lingui/react";
import React, { FC, Fragment, MouseEvent, ReactNode } from "react";
import ReactMarkdown from "react-markdown";
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Image,
  Label,
  List,
  Modal,
  ModalProps,
  Rating,
  Segment,
} from "semantic-ui-react";

import { Company } from "../../../../../interfaces/resources/company";
import { Contact } from "../../../../../interfaces/resources/contact";
import { CoreUser } from "../../../../../interfaces/resources/coreUser";
import { DesiredPlace } from "../../../../../interfaces/resources/desiredPlace";
import { ExperienceYears } from "../../../../../interfaces/resources/experienceYears";
import { Responsibility, ResponsibilityType } from "../../../../../interfaces/resources/responsibility";
import { SalaryExpectations } from "../../../../../interfaces/resources/salaryExpectations";

import { getCompanyImage } from "../../../../App/Image";
import CompanyPlaceholder from "../../../../App/Image/img/company.png";

import "./index.scss";

interface FormValues {
  company?: Company;
  title?: string;
  offerInternalView: {
    expectedProfile?: string;
    oustandingAspects?: string;
  } | null;
  offerTalentView: {
    team?: string;
    toLearn?: string;
    howToShine?: string;
    requirements?: string;
    expectedProfile?: string;
    description?: string;
    context?: string;
  } | null;
  owner?: CoreUser;
  contacts?: Contact[];
  recurring?: boolean;
  salary?: SalaryExpectations[];
  experienceYears?: ExperienceYears[];
  stapleLocation?: DesiredPlace;
  responsibilities?: Responsibility[];
  responsibilityType?: ResponsibilityType;
  tier?: string;
}

interface Props extends Pick<ModalProps, "open" | "onClose" | "size"> {
  onProceed?: () => void;
  values: FormValues;
}

const MainFormModal: FC<Props> = (props: Props) => {
  const { onClose, onProceed, values, open, size } = props;
  const {
    company,
    title,
    offerInternalView,
    offerTalentView,
    owner,
    contacts,
    recurring,
    salary,
    experienceYears,
    stapleLocation,
    tier,
    responsibilities,
  }: FormValues = values;

  function handleClose(e: MouseEvent<HTMLElement>) {
    if (onClose) {
      onClose(e, {});
    }
  }

  function handleProceed(e: MouseEvent<HTMLElement>) {
    if (onProceed) {
      onProceed();
      if (onClose) {
        onClose(e, {});
      }
    }
  }

  function renderActions() {
    return (
      <Modal.Actions>
        <Button negative onClick={(e) => handleClose(e)} content={t`Constant.cancel`} icon="times" />
        <Button primary onClick={(e) => handleProceed(e)} icon="checkmark" content={t`OfferForm.fields.continue`} />
      </Modal.Actions>
    );
  }

  function renderMarkdowns() {
    const items: FormValues = {
      offerInternalView,
      offerTalentView,
    };

    return (
      <List>
        {Object.keys(items).reduce<React.ReactNode[]>((acc, c) => {
          const category = c as keyof typeof items;
          const categoryItems = items[category];
          return [
            ...acc,
            categoryItems && (
              <Fragment key={category}>
                {Object.keys(categoryItems).map((ik, i) => {
                  const itemKey = ik as keyof typeof categoryItems;
                  const item = categoryItems[itemKey];

                  if (item && itemKey && String(itemKey).charAt(0) !== "@" && !String(item).startsWith("/api/")) {
                    return (
                      <List.Item key={`${i}:${itemKey}`} as={Container} fitted="true" style={{ marginBottom: "1rem" }}>
                        <Segment padded>
                          <Header>
                            <Trans id={`offer.${category}.${itemKey}`} />
                          </Header>
                          <Divider />
                          <ReactMarkdown>{item}</ReactMarkdown>
                        </Segment>
                      </List.Item>
                    );
                  }
                  return null;
                })}
                <Divider />
              </Fragment>
            ),
          ];
        }, [])}
      </List>
    );
  }

  function renderOthers() {
    const sal = salary;
    const expY = experienceYears;
    const stapleL = stapleLocation;
    const resp = responsibilities;
    const tierValue = tier;
    const items: Array<{ renderer: ReactNode; section: string }> = [
      {
        renderer: sal && sal.length > 0 && (
          <Label.Group>
            {sal.map((range, i) => (
              <Label key={range.value.length * i ** 2} content={range.value} />
            ))}
          </Label.Group>
        ),
        section: "offer.salaries",
      },
      {
        renderer: recurring !== undefined && (
          <Button.Group>
            <Button positive={!recurring} disabled>
              {t`offer.recurring.false`}
            </Button>
            <Button.Or />
            <Button positive={recurring} disabled>
              {t`offer.recurring.true`}
            </Button>
          </Button.Group>
        ),
        section: "offer.type.head",
      },
      {
        renderer: expY && expY.length > 0 && (
          <Label.Group>
            {expY.map((range, i) => (
              <Label key={`${range.value}:${i}`} content={range.value} />
            ))}
          </Label.Group>
        ),
        section: "offer.experienceYears",
      },
      {
        renderer: !!stapleL && <Label>{stapleL && stapleL.value}</Label>,
        section: "offer.stapleLocation",
      },
      {
        renderer: tierValue && (
          <Rating icon="star" disabled maxRating={4} defaultRating={5 - parseInt(tierValue.split("_")[1], 10)} />
        ),
        section: "offer.tier",
      },
      {
        renderer: resp && resp.length > 0 && (
          <Label.Group>
            {resp.map((responsibility, i) => (
              <Label key={`${responsibility.value}:${i}`} content={responsibility.value} />
            ))}
          </Label.Group>
        ),
        section: "offer.responsibilities",
      },
    ];

    return (
      <Grid relaxed padded columns="3">
        {items.map(({ renderer, section }, i) => (
          <Grid.Column key={i}>
            <Header dividing>
              <Trans id={section} />
            </Header>
            {renderer || <p>N/A</p>}
          </Grid.Column>
        ))}
      </Grid>
    );
  }

  return (
    <Modal open={open} size={size} dimmer="blurring" onClose={onClose} className="mainFormModal">
      <Modal.Header className="header">
        {company && title && `${company.name} - ${title}`}
        <Label.Group className="labels">
          {owner && owner.firstname && owner.lastname && (
            <Label size="mini" color="orange" content={`${owner.firstname} ${owner.lastname}`} detail="Responsable" />
          )}
          {contacts
            && contacts.map((contact) => (
              <Label
                key={contact["@id"]}
                size="mini"
                color="teal"
                content={`${contact.firstname} ${contact.lastname}`}
                detail="Contact"
              />
            ))}
        </Label.Group>
      </Modal.Header>
      <Modal.Content image scrolling>
        <Image size="small" {...((company && getCompanyImage(company.image)) || { src: CompanyPlaceholder })} wrapped />
        <Modal.Description>
          {renderMarkdowns()}
          {renderOthers()}
        </Modal.Description>
      </Modal.Content>
      {renderActions()}
    </Modal>
  );
};

export default MainFormModal;
