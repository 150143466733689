import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";

import { GetQuizAnswers } from "../../../../actionTypes/quizAnswer";
import { QuizAnswer } from "../../../../interfaces/resources/quizAnswer";

import { apiFailureAction } from "../../actions";
import { getQuizAnswersActionSuccessAction } from "./actions";
import * as apiClient from "./api";

export function* getQuizAnswers(action: AnyAction) {
  try {
    const response: AxiosResponse<{ "hydra:member": QuizAnswer[] }> = yield call(
      apiClient.getQuizAnswers,
      action.talent,
    );
    yield put(getQuizAnswersActionSuccessAction(response.data["hydra:member"]));
  } catch (e) {
    yield put(apiFailureAction(e, action));
  }
}

export default function* quizAnswersSaga() {
  yield takeLatest(GetQuizAnswers.REQUEST, getQuizAnswers);
}
