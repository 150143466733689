import companyPlaceholder from "./img/company.png";
import defaultPlaceholder from "./img/default.png";
import userPlaceholder from "./img/user.png";

export const getImage = (image?: { url: string }, placeholder: string = defaultPlaceholder) => {
  return {
    onError: (i: any) => (i.target.src = placeholder),
    src: image && image.url ? image.url : placeholder,
  };
};

export const getCompanyImage = (image?: { url: string }) => {
  return getImage(image, companyPlaceholder);
};

export const getUserImage = (image?: { url: string }) => {
  return getImage(image, userPlaceholder);
};
