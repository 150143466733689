import { t } from "@lingui/macro";
import cx from "classnames";
import { format, formatDistance, parseISO } from "date-fns";
import { fr } from "date-fns/locale";
import * as React from "react";
import { BiInfoCircle, BiMessageRoundedDots } from "react-icons/bi";
import { FaLinkedinIn, FaPencilAlt } from "react-icons/fa";
import { FiChevronDown } from "react-icons/fi";
import { GoMail } from "react-icons/go";
import { ImWhatsapp } from "react-icons/im";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { GoldenTag } from "../../components/Design/GoldenTag";
import { Switch } from "../../components/Design/Switch";
import { ModalWrapper } from "../../components/ModalWrapper";
import { TalentProfileMode } from "../../components/Talents/SortNewTalents/NewTalentProfile";
import GmailMailto from "../../components/Utils/GmailMailto";
import { getCorrectTalentProfile, getIsOutdated } from "../../components/Utils/helpers";
import LoaderComp from "../../components/Utils/Loader";
import { LinkedInProfile } from "../../interfaces/resources/linkedInProfile";
import { Talent, talentCategories, talentSegments, talentStatuses } from "../../interfaces/resources/talent";
import { State } from "../../interfaces/state";
import { extractPageFromIri } from "../../services/api/helpers";
import { editTalentAction } from "../../services/api/talent/actions";
import { editTalentProfileAction } from "../../services/api/talent/talentProfile/actions";
import globalStyles from "../../styles/global.module.scss";
import { transTalent } from "../../translations/constants/talent";
import { ApplicationAcceptedLabel } from "../labels/ApplicationAcceptedLabel";
import { LateStageLabel } from "../labels/LateStageLabel";
import { PersonaLabel } from "../labels/PersonaLabel";
import { SearchStatusLabel } from "../labels/SearchStatusLabel";
import { PersonProfileImg } from "../personProfileImg";
import { DeleteTalentModalCta } from "./DeleteTalentModal";
import { EditTalentModal } from "./EditTalentModal";

import styles from "./index.module.scss";
import { TalentNoteModal } from "./TalentNoteModal";

interface Props {
  newTalent?: boolean;
  linkedInHeadline?: string;
  linkedInUrl?: string;
  linkedInProfile: LinkedInProfile;
  onUrlChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  currentJob?: string;
  onCurrentJobChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  currentCompany?: string;
  onCurrentCompanyChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  talent: Talent;
  deleteTalent?: () => void;
  makeUnclickable?: boolean;
  readOnly?: boolean;
  mode?: TalentProfileMode;
}

export const TalentInfo: React.FunctionComponent<Props> = (props: Props) => {
  const {
    mode,
    linkedInUrl,
    onUrlChange,
    currentJob,
    onCurrentJobChange,
    currentCompany,
    onCurrentCompanyChange,
    readOnly = false,
  } = props;
  const dispatch = useDispatch();
  const talent = useSelector((state: State) => state.talent);
  const linkedInProfileState = useSelector((state: State) => state.talentLinkedInProfile);
  const talentProfileState = useSelector((state: State) => state.talentProfile);
  const talentProfilesState = useSelector((state: State) => state.talentProfiles);
  const linkedInProfile =
    talent?.linkedInProfile["@id"] === linkedInProfileState?.["@id"] ? linkedInProfileState : talent?.linkedInProfile;
  const talentProfile = getCorrectTalentProfile(talent, talentProfileState, talentProfilesState);
  const calendlyEvents = useSelector((state: State) => state.calendlyEvents);
  const [isOpenDropdown, setIsOpenDropdown] = React.useState(false);
  const [goldenSwitchChecked, setGoldenSwitchChecked] = React.useState(talentProfile ? talentProfile.golden : false);
  const [openTalentEdidtionModal, setOpenTalentEdidtionModal] = React.useState<boolean>(false);
  const [openTalentNoteModal, setOpenTalentNoteModal] = React.useState<boolean>(false);

  const hasLinkedInProfilUrl =
    (linkedInUrl && linkedInUrl !== "https://") || (linkedInProfile?.url && linkedInProfile.url !== "https://");
  const whatsappPhone = talent?.phone ? talent.phone.replace("+", "").replace(/\s/g, "") : null;
  const hubspotUrl = !!talentProfile?.hubspotId
    ? `https://app.hubspot.com/contacts/4144470/contact/${talentProfile.hubspotId}`
    : undefined;
  const categoriesList: Array<keyof typeof talentCategories> = ["club", "fastTrack", "refused", "blacklisted"];

  const isOutdated = getIsOutdated(talentProfile);
  const isModeNew = mode === "new";
  const editTalent = (iri: string, req: any) => dispatch(editTalentAction(iri, req));
  const editTalentCategorie = (category: keyof typeof talentCategories): void => {
    if (!!!talent) {
      throw new Error("Talent manquant");
    }
    const tObj = {
      "@id": talent["@id"],
      segment: talent.segment,
      status: talent.status,
    };
    switch (category) {
      case "club":
        tObj.status = talentStatuses.accepted;
        tObj.segment = talentSegments.segment1;
        break;
      case "fastTrack":
        tObj.status = talentStatuses.accepted;
        tObj.segment = talentSegments.segment2;
        break;
      case "refused":
        tObj.status = talentStatuses.refused;
        tObj.segment = talentSegments.segment4;
        break;
      case "blacklisted":
        tObj.status = talentStatuses.blacklisted;
        tObj.segment = talentSegments.segment4;
        break;
    }
    if (talent.status !== tObj.status || talent.segment !== tObj.segment) {
      editTalent(talent["@id"], tObj);
    }
  };

  const onCloseTalentEdidtionModal = () => {
    setOpenTalentEdidtionModal(false);
  };

  const onCloseTalentNoteModal = () => {
    setOpenTalentNoteModal(false);
  };

  const onNewTalentGoldenSwitchClick = (_e: React.SyntheticEvent, val: any): void => {
    if (talentProfile) {
      setGoldenSwitchChecked(val);
      dispatch(
        editTalentProfileAction({
          "@id": talentProfile["@id"],
          golden: val,
        }),
      );
    }
  };

  return (
    <div className={styles.talentInfoContainer}>
      {!!!talent ? (
        <div className={styles.loaderCompContainer}>
          <LoaderComp />
        </div>
      ) : (
        <>
          <div className={styles.topContainer}>
            <div>
              {talent.talentManager ? (
                <>
                  <div className={styles.timImgContainer}>
                    <PersonProfileImg alt={talent.talentManager.firstname} image={talent.talentManager.image} />
                  </div>
                  <div className={styles.timName}>{talent.talentManager.firstname}</div>
                </>
              ) : (
                <div className={styles.text}>{t`Constant.noTim`}</div>
              )}
            </div>
            <div className={styles.topItemLabelContainer}>
              {talentProfile && !!talentProfile.persona && talentProfile?.persona === "active_performer" ? (
                <div>
                  <PersonaLabel persona={talentProfile.persona} />
                </div>
              ) : null}
              {talent.searchStatus ? (
                <div>
                  <SearchStatusLabel talent={talent} />
                </div>
              ) : null}
              {talent.applicationsAccepted?.length ? (
                <div>
                  <ApplicationAcceptedLabel applicationsAccepted={talent.applicationsAccepted} />
                </div>
              ) : null}
            </div>

            <div className={styles.labelsContainer}>
              {talentProfile && talentProfile.golden ? (
                <div>
                  <GoldenTag />
                </div>
              ) : null}
              {talent.lateStageApplications?.map((lap, i) => {
                return <LateStageLabel key={`application-card-late-stage-label-${lap.applicationId}-${i}`} lap={lap} />;
              })}
            </div>
            {readOnly ? null : (
              <div>
                <div>
                  <button
                    className={cx(styles.cta, globalStyles.cta, globalStyles.primaryCta)}
                    type="button"
                    onClick={() => {
                      setOpenTalentEdidtionModal(true);
                    }}
                  >
                    {t`Constant.update`}
                  </button>
                </div>
                <div>
                  <DeleteTalentModalCta talent={talent} className={styles.cta} />
                </div>
              </div>
            )}
          </div>
          <div className={styles.middleContainer}>
            <div className={styles.talentImgStatusContainer}>
              <div className={styles.talentImgContainer}>
                <PersonProfileImg image={talent.image} />
              </div>
              <div>
                <div className={styles.statusDropdownContainer}>
                  <div
                    className={cx(styles.statusDropdownCta, {
                      [styles.isOpen]: isOpenDropdown,
                      [styles.isEnabled]: talent.enabled && !isModeNew,
                    })}
                    onClick={() => {
                      if (talent.enabled && !isModeNew) setIsOpenDropdown(!isOpenDropdown);
                    }}
                  >
                    <span>
                      {calendlyEvents.length > 0 && talent.status === talentStatuses.callBooked
                        ? `${t({ id: transTalent.status[talent.status] })} ${format(
                            parseISO(calendlyEvents[calendlyEvents.length - 1].startTime),
                            "dd/MM/yyyy",
                          )}`
                        : talent.status !== talentStatuses.accepted || !!!talent.segment
                        ? t({ id: transTalent.status[talent.status] })
                        : t({ id: transTalent.segments[talent.segment] })}
                    </span>
                    {talent.enabled && !isModeNew ? <FiChevronDown /> : null}
                  </div>
                  {isOpenDropdown ? (
                    <div className={styles.statusDropdownListContainer}>
                      <ul className={styles.statusDropdownList}>
                        {categoriesList.map((cat, i) => {
                          const categorieLitteral = talentCategories[cat];
                          const isDisabled = talent.status === categorieLitteral;
                          return (
                            <li key={`dropdown-list-${cat}-${i}`}>
                              <div
                                className={cx(styles.statusDropdownItem, { [styles.disabled]: isDisabled })}
                                onClick={() => {
                                  if (!isDisabled) {
                                    editTalentCategorie(cat);
                                    setIsOpenDropdown(false);
                                  }
                                }}
                              >
                                {cat === "blacklisted" || cat === "refused"
                                  ? t({ id: transTalent.status[categorieLitteral] })
                                  : t({ id: transTalent.segments[categorieLitteral] })}
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className={styles.talentContactInfosContainer}>
              <div>
                <h3 className={styles.nameTitle}>
                  <a
                    href={extractPageFromIri("talents", talent["@id"])}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.nameLink}
                  >
                    {talent.firstname} {talent.lastname}
                  </a>
                </h3>
              </div>
              {isModeNew && talentProfile ? (
                <div className={styles.newTalentGoldenSwitch}>
                  {t`NewTalent.golden.switch`}{" "}
                  <Switch
                    checked={goldenSwitchChecked}
                    onChange={onNewTalentGoldenSwitchClick}
                    coloron="positive-green"
                    coloroff="negative-red"
                  />
                </div>
              ) : null}
              {isModeNew ? (
                <div>
                  Post actuel :{" "}
                  <input
                    type="text"
                    className={styles.postInput}
                    name="talentProfile.currentJob"
                    id="talentProfile.currentJob"
                    onChange={(e) => {
                      if (typeof onCurrentJobChange === "function") {
                        onCurrentJobChange(e);
                      }
                    }}
                    value={(!!currentJob && currentJob) || talentProfile?.currentJob || ""}
                  />
                  <span className={styles.postInputSeparator}>@</span>
                  <input
                    type="text"
                    className={styles.postInput}
                    name="talentProfile.currentCompany"
                    id="talentProfile.currentCompany"
                    onChange={(e) => {
                      if (typeof onCurrentCompanyChange === "function") {
                        onCurrentCompanyChange(e);
                      }
                    }}
                    value={(!!currentCompany && currentCompany) || talentProfile?.currentCompany || ""}
                  />
                </div>
              ) : null}
              <div>
                {isModeNew ? (
                  <div className={styles.linkedInFormIconContainer}>
                    <div>
                      <FaLinkedinIn />
                    </div>
                    <div>
                      <input
                        type="text"
                        className={styles.linkedInInput}
                        name="linkedInProfile.url"
                        id="linkedInProfile.url"
                        onChange={(e) => {
                          if (typeof onUrlChange === "function") {
                            onUrlChange(e);
                          }
                        }}
                        value={linkedInUrl || linkedInProfile?.url || ""}
                        placeholder={t`linkedInProfile.url`}
                      />
                      <div className={styles.linkedInProfilLinkContainer}>
                        {hasLinkedInProfilUrl ? (
                          <a href={linkedInUrl || linkedInProfile?.url} target="_blank" rel="noopener noreferrer">
                            {t`TalentInfo.goTolinkedInProfile`}
                          </a>
                        ) : (
                          <a
                            href={encodeURI(
                              "https://www.linkedin.com/search/results/all/?keywords="
                                + `${talent.firstname + " " + talent.lastname}&origin=GLOBAL_SEARCH_HEADER`,
                            )}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {t`TalentInfo.linkedInSearch`}
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                ) : talentProfile?.currentJob || talentProfile?.currentCompany ? (
                  <div className={cx(styles.iconLinkContainer, styles.linkedInTitleLinkContainer)}>
                    <div>
                      {talentProfile?.currentJob} @ {talentProfile?.currentCompany}
                    </div>{" "}
                    <div
                      className={cx(
                        styles.currentCompanyTooltipContainer,
                        globalStyles.labelTooltipContainer,
                        globalStyles.top,
                      )}
                    >
                      <span className={globalStyles.labelTooltip}>
                        Clique pour vérifier si c&apos;est l&apos;un de nos clients
                      </span>
                      <a
                        href="https://www.notion.so/elinoi/a617830e23f34e0d993bfea5eaea83fe?v=f550cbb2d20840b2953538f3f875bc97"
                        target="_blank"
                        rel="noreferrer"
                        className={styles.currentCompanyTooltipLink}
                      >
                        <BiInfoCircle />
                      </a>
                    </div>
                  </div>
                ) : !linkedInProfile?.headline || !linkedInProfile?.url ? (
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        setOpenTalentEdidtionModal(true);
                      }}
                      className={cx(
                        styles.editLinkedInCta,
                        globalStyles.cta,
                        globalStyles.primaryCta,
                        globalStyles.inverted,
                      )}
                    >
                      <FaPencilAlt />
                      <span>{t`linkedInProfile.headline.empty`}</span>
                    </button>
                  </div>
                ) : (
                  <div className={cx(styles.iconLinkContainer, styles.linkedInTitleLinkContainer)}>
                    <FaLinkedinIn />
                    <a href={linkedInProfile.url} rel="noopener noreferrer" target="_blank">
                      <span>{linkedInProfile.headline || t`linkedInProfile.headline.empty`}</span>
                    </a>
                  </div>
                )}
              </div>
              <div>
                <div className={styles.iconLinkContainer}>
                  <GoMail />
                  <GmailMailto className={styles.emailLink} to={talent.email} />
                </div>
              </div>
              <div>
                <span>{talent?.phone}</span>
              </div>
            </div>
            {talentProfile ? (
              <div className={styles.noteContainer}>
                <div className={styles.noteCtaContainer}>
                  <button
                    type="button"
                    className={cx(styles.noteCta, globalStyles.cta, globalStyles.aquaCta, globalStyles.inverted)}
                    onClick={() => {
                      setOpenTalentNoteModal(true);
                    }}
                  >
                    <BiMessageRoundedDots /> Ajouter une note
                  </button>
                  <ModalWrapper open={openTalentNoteModal} onCloseModal={onCloseTalentNoteModal}>
                    <TalentNoteModal talentProfile={talentProfile} onCloseModal={onCloseTalentNoteModal} />
                  </ModalWrapper>
                </div>
                {talentProfile.note ? (
                  <div className={styles.noteTextContainer}>
                    <ReactMarkdown>{talentProfile.note}</ReactMarkdown>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
          <div className={styles.bottomContainer}>
            <div className={styles.bottomCtasContainer}>
              {talent?.phone ? (
                <div>
                  <a
                    className={cx(globalStyles.cta, globalStyles.whatsappCta)}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://wa.me/${whatsappPhone}`}
                  >
                    <ImWhatsapp /> {t`Action.whatsapp.contact.text`}
                  </a>
                </div>
              ) : null}

              {linkedInProfile?.url ? (
                <div>
                  <a
                    className={cx(globalStyles.cta, globalStyles.linkedInCta)}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={linkedInProfile?.url}
                  >
                    {t`Action.linkedIn.seeProfile.text`}
                  </a>
                </div>
              ) : null}

              {hubspotUrl ? (
                <div>
                  <a
                    className={cx(globalStyles.cta, globalStyles.hubspotCta)}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={hubspotUrl}
                  >
                    {t`Action.hubspot.seeProfile.text`}
                  </a>
                </div>
              ) : null}
            </div>
            {talent.status === talentStatuses.queued ? (
              <div>
                <div className={styles.text}>
                  {t`TalentInfo.queuedReason.text`}{" "}
                  <strong>{t({ id: transTalent.queuedReason[talent.queuedReason] })}</strong>
                </div>
              </div>
            ) : null}

            <div>
              <div className={cx({ [styles.outdated]: isOutdated })}>
                <div className={styles.text}>
                  <ReactMarkdown
                    components={{
                      p: React.Fragment,
                    }}
                  >
                    {talentProfile?.lastTalentProfileEvent
                      ? t({
                          id: "TalentInfo.updateParams.text",
                          values: {
                            date: formatDistance(parseISO(talentProfile.lastTalentProfileEvent.createdAt), new Date(), {
                              locale: fr,
                            }),
                          },
                        })
                      : t`TalentInfo.updateParams.empty.text`}
                  </ReactMarkdown>
                </div>
                <div className={styles.text}>
                  <ReactMarkdown
                    components={{
                      p: React.Fragment,
                    }}
                  >
                    {talentProfile?.lastQualificationDate
                      ? t({
                          id: "TalentInfo.lastQualif.text",
                          values: {
                            date: formatDistance(parseISO(talentProfile.lastQualificationDate), new Date(), {
                              locale: fr,
                            }),
                          },
                        })
                      : t`TalentInfo.lastQualif.empty.text`}
                  </ReactMarkdown>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div className={styles.text}>
                  <ReactMarkdown
                    components={{
                      p: React.Fragment,
                    }}
                  >
                    {t({
                      id: "TalentInfo.lastLogin.text",
                      values: {
                        date: formatDistance(parseISO(talent.lastLogin), new Date(), { locale: fr }),
                      },
                    })}
                  </ReactMarkdown>
                </div>
                <div className={styles.text}>
                  <ReactMarkdown
                    components={{
                      p: React.Fragment,
                    }}
                  >
                    {t({
                      id: "TalentInfo.createdAt.text",
                      values: {
                        date: formatDistance(parseISO(talent.createdAt), new Date(), { locale: fr }),
                      },
                    })}
                  </ReactMarkdown>
                </div>
              </div>
            </div>
          </div>
          <ModalWrapper open={openTalentEdidtionModal} onCloseModal={onCloseTalentEdidtionModal}>
            <EditTalentModal closeModal={onCloseTalentEdidtionModal} />
          </ModalWrapper>
        </>
      )}
    </div>
  );
};
