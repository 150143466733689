import { put, race, take, takeEvery } from "redux-saga/effects";

import { ApiAuthenticationRefresh } from "../../actionTypes/authentication";
import { logoutAction, refreshAction } from "./authentication/actions";
import {
  getFailType,
  getSuccessType,
  JWT_TOKEN_KEY,
  JWT_TOKEN_KEY_TEMP,
  REFRESH_TOKEN_KEY,
  removeTokens,
} from "./helpers";

const ignoreActionTypes = [ApiAuthenticationRefresh.REQUEST];

export function monitorableAction(action: any) {
  return action.type.includes("REQUEST") && ignoreActionTypes.every((fragment) => !action.type.includes(fragment));
}

export function* monitor(monitoredAction: any) {
  const { fail } = yield race({
    fail: take(getFailType(monitoredAction)),
    success: take(getSuccessType(monitoredAction)),
  });

  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
  const jwt = localStorage.getItem(JWT_TOKEN_KEY);
  if (!refreshToken || !jwt) {
    removeTokens("login");
  }

  if (fail && fail.payload && fail.payload.status === 401 && refreshToken) {
    yield put(refreshAction(refreshToken));

    const { success } = yield race({
      fail: take(ApiAuthenticationRefresh.FAILURE),
      success: take(ApiAuthenticationRefresh.SUCCESS),
    });

    if (success) {
      yield put(monitoredAction);
    } else {
      yield put(logoutAction());
      removeTokens(JWT_TOKEN_KEY_TEMP);
    }
  }
}

export default function* apiSaga() {
  yield takeEvery(monitorableAction, monitor);
}
