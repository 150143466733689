import { CreateOfferTalentView, EditOfferTalentView, GetOfferTalentView } from "../../../../actionTypes/offers";
import { OfferTalentView } from "../../../../interfaces/resources/offer";

export function createOfferTalentViewAction(form?: string, redirectOnSuccess?: boolean | string) {
  return {
    form,
    redirectOnSuccess,
    type: CreateOfferTalentView.REQUEST,
  };
}

export function editOfferTalentViewAction(form?: string, redirectOnSuccess?: boolean | string) {
  return {
    form,
    redirectOnSuccess,
    type: EditOfferTalentView.REQUEST,
  };
}

export function getOfferTalentViewAction(iri: string) {
  return {
    iri,
    type: GetOfferTalentView.REQUEST,
  };
}

export function getOfferTalentViewSuccessAction(offerTalentView: OfferTalentView) {
  return {
    offerTalentView,
    type: GetOfferTalentView.SUCCESS,
  };
}
