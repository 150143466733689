import { t } from "@lingui/macro";
import * as React from "react";
import Select from "react-select";

import { Accordion, Icon } from "semantic-ui-react";
import { ApiFilter } from "../../../interfaces/api/filter";
import { Company } from "../../../interfaces/resources/company";

import { defaultStyles } from "../../Form/Select";

interface Props extends ApiFilter<Company> {
  activeIndexes?: number[];
  handleClick?: (e: any, titleProps: any) => void;
  index?: number;
}
export const CompanyFilter: React.FC<Props> = ({
  activeIndexes,
  handleClick,
  index,
  items,
  onChange,
  selected,
}: Props) => {
  const active = activeIndexes ? activeIndexes.some((item: number) => item === index) : false;
  return (
    <div>
      <Accordion.Title active={active} index={index} onClick={handleClick}>
        <h3>
          {t`CompanyTalentFilter.title`}
          <Icon name={active ? "chevron up" : "chevron down"} />
        </h3>
      </Accordion.Title>
      <Accordion.Content active={active}>
        <Select
          getOptionLabel={(option: Company): string => option.name}
          getOptionValue={(option: Company): string => option["@id"]}
          isClearable={true}
          isMulti={true}
          isSearchable={true}
          onChange={(options: any) =>
            onChange(
              options.map((item: Company) => {
                return {
                  value: item["@id"],
                };
              }),
            )
          }
          options={items}
          placeholder={t`CompanyTalentFilter.placeholder`}
          styles={defaultStyles}
          value={selected}
        />
      </Accordion.Content>
    </div>
  );
};
