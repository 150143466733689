import { t } from "@lingui/macro";
import cx from "classnames";
import { Field, Formik, Form } from "formik";
import * as React from "react";
import { Message } from "semantic-ui-react";

import { GetTalentCards } from "../../../../../actionTypes/talentCards";
import { GetTalentProfile } from "../../../../../actionTypes/talentProfile";
import { GetTalentQualification } from "../../../../../actionTypes/talentQualification";
import { ApiError } from "../../../../../interfaces/api/error";
import { ApiRequest } from "../../../../../interfaces/api/request";
import { ApiSuccess } from "../../../../../interfaces/api/success";
import { Violation, FormState } from "../../../../../interfaces/api/violation";
import { Application, applicationStatuses } from "../../../../../interfaces/resources/application";
import { CoreUser } from "../../../../../interfaces/resources/coreUser";
import { TalentCard } from "../../../../../interfaces/resources/talentCard";
import { TalentProfile } from "../../../../../interfaces/resources/talentProfile";
import globalStyles from "../../../../../styles/global.module.scss";
import transPhoneCall from "../../../../../translations/constants/phoneCall";

import Yup from "../../../../App/Yup";
import Input from "../../../../Form/Formik/Input";
import Radio from "../../../../Form/Formik/Radio";
import RichTextEditor from "../../../../Form/Formik/RichTextEditor";
import { isReinitializeable } from "../../../../Utils/helpers";
import { useApiSelector } from "../../../../Utils/hook";
import styles from "./index.module.scss";

interface Props {
  application: Application;
  createTalentCard: (talentCard: Partial<TalentCard>, form: string) => void;
  currentUser: CoreUser;
  editApplication: (req: Partial<Application>) => void;
  editTalentCard: (talentCard: Partial<TalentCard>, form: string) => void;
  form: string;
  initialValues: any;
  switchToNextApplication?: () => void;
  talentCard?: TalentCard;
  talentProfile: TalentProfile;
  handleModalClose?: () => void;
  setCurrentApplication?: (app: Application | null) => void;
  onSuccess?: () => void;
  mode?: "shortlist" | "talentCard";
}

const availabilityOptions = ["notice_asap", "notice_1_month", "notice_3_months", "notice_>3_months"];

const validationSchema = Yup.object().shape({
  salary: Yup.object().shape({
    fixedSalary: Yup.number().min(1).required(),
    fullPackage: Yup.number().min(Yup.ref("fixedSalary")).max(999).required(),
  }),
  description: Yup.string(),
  bridges: Yup.string(),
  positions: Yup.string().required(),
  availability: Yup.string(),
  education: Yup.string(),
});

const TalentCardForm: React.FC<Props> = ({
  application,
  editApplication,
  currentUser,
  form,
  initialValues,
  talentCard,
  createTalentCard,
  editTalentCard,
  talentProfile,
  onSuccess,
  mode,
}: Props) => {
  const uniqId = React.useRef(`${application["@id"]}-talentCardForm`).current;
  const { apiErrors, apiPendingRequests, apiSuccess } = useApiSelector();
  const [violations, setViolations] = React.useState<Violation[]>([]);
  const [formState, setFormState] = React.useState<FormState>(undefined);
  const [triggerOnSuccess, setTriggerOnSuccess] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isInfosShow, setIsInfosShow] = React.useState<boolean>(mode === "talentCard" ? true : false);

  const ownHandleSubmit = (values: Partial<TalentCard>) => {
    if ((talentCard && talentCard.hasOwnProperty("@id")) || values.hasOwnProperty("@id")) {
      const iri = values["@id"] || (talentCard && talentCard["@id"]);
      if (iri) {
        editTalentCard({ ...values, "@id": iri }, form);
      }
    } else {
      createTalentCard(values, form);
    }
    if (mode === "shortlist") {
      editApplication({ "@id": application["@id"], owner: currentUser, status: applicationStatuses.shortlistReady });
    }
    setTriggerOnSuccess(true);
  };

  const onClickShowInfosCta = () => {
    setIsInfosShow(!isInfosShow);
  };

  React.useEffect(() => {
    if (typeof onSuccess === "function" && formState === "success" && triggerOnSuccess) {
      onSuccess();
      setTriggerOnSuccess(false);
    }
  }, [formState, triggerOnSuccess, onSuccess]);

  React.useEffect(() => {
    if (apiSuccess.some((suc: ApiSuccess) => suc.form === form)) {
      setViolations([]);
      setFormState("success");
      const timer = setTimeout(() => {
        setFormState(undefined);
      }, 10000);
      return () => clearTimeout(timer);
    }

    if (apiErrors.some((err: ApiError) => err.form === form)) {
      const apiError = apiErrors.find((err: ApiError) => err.form === form);
      if (apiError) {
        setViolations(apiError.payload.violations || []);
      }
    }
  }, [apiSuccess, apiErrors, form]);

  React.useEffect(() => {
    setIsLoading(
      apiPendingRequests.length > 0
        && apiPendingRequests.some((req: ApiRequest) => {
          return (
            req.form === form
            || req.type === GetTalentCards.REQUEST
            || (req.type === GetTalentProfile.REQUEST && req.talentProfile === application.talent.talentProfile["@id"])
            || (talentProfile
              && req.type === GetTalentQualification.REQUEST
              && talentProfile.lastQualification
              && req.qualificationIri === talentProfile.lastQualification["@id"])
          );
        }),
    );
  }, [apiPendingRequests, form, talentProfile, application]);

  return (
    <>
      <Formik
        onSubmit={ownHandleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={isReinitializeable(apiErrors, apiPendingRequests, apiSuccess)}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit} className={cx("form", { loading: isLoading })}>
            <div className="formGroup">
              <h3 className={cx("formLabel", styles.amField)}>Salaire demandé</h3>
              <div className={cx("formGroup formInlineGroup double", styles.salaryGroup)}>
                <Field
                  name="salary.fixedSalary"
                  component={Input}
                  type="number"
                  id={`${uniqId}-fixedSalary`}
                  label={t`talentCard.salary.fixedSalary`}
                  violations={violations}
                />
                <Field
                  name="salary.fullPackage"
                  component={Input}
                  type="number"
                  id={`${uniqId}-fullPackage`}
                  label={t`talentCard.salary.fullPackage`}
                  violations={violations}
                />
              </div>
            </div>
            <div className="formPart">
              <div className="formGroup formTextAreaGroup">
                <div className="formGroup formTextAreaGroup">
                  <Field
                    name="description"
                    component={RichTextEditor}
                    className={styles.amField}
                    uniqId={`${uniqId}-context`}
                    label={t`talentCard.description`}
                    labelClassName="talentItem-form-label"
                    violations={violations}
                    isRequired={false}
                    replaceIfEmpty={false}
                  />
                  <Field
                    name="bridges"
                    component={RichTextEditor}
                    className={styles.amField}
                    uniqId={`${uniqId}-bridges`}
                    label={t`talentCard.bridges`}
                    labelClassName="talentItem-form-label"
                    violations={violations}
                    isRequired={false}
                    replaceIfEmpty={false}
                  />
                </div>
              </div>
            </div>

            <div className={styles.showInfosTitleCtaContainer}>
              <h3 className={styles.showInfosTitle}>Informations pré-remplies</h3>
              {mode !== "talentCard" ? (
                <div>
                  <button
                    className={cx(globalStyles.cta, globalStyles.aquaCta, globalStyles.inverted)}
                    type="button"
                    onClick={onClickShowInfosCta}
                  >
                    {isInfosShow ? "masquer" : "voir"}
                  </button>
                </div>
              ) : null}
            </div>
            <div className={cx("formPart", { [styles.hiddenFormPart]: !isInfosShow })}>
              <div className="formGroup formTextAreaGroup">
                <Field
                  name="positions"
                  component={RichTextEditor}
                  uniqId={`${uniqId}-positions`}
                  label={t`talentCard.positions`}
                  labelClassName="talentItem-form-label"
                  violations={violations}
                  replaceIfEmpty={false}
                />
              </div>
              <div className="formGroup">
                <Field
                  name="availability"
                  component={Radio}
                  className={styles.availabilityField}
                  label={t`talentCard.availability`}
                  getOptionLabel={(option: string) => t({ id: transPhoneCall.priorNotice[option] })}
                  getOptionValue={(option: string) => option}
                  options={availabilityOptions}
                  violations={violations}
                />
              </div>
            </div>

            {formState === "success" || formState === "error" ? (
              <div className={styles.messagesContainer}>
                {formState === "success" ? (
                  <Message
                    success
                    content={t`talentCard.success.content`}
                    header={t`talentCard.success.header`}
                    icon="check circle"
                  />
                ) : null}
                {formState === "error" ? (
                  <Message
                    error
                    content={t`talentCard.error.content`}
                    header={t`talentCard.error.header`}
                    icon="times circle"
                  />
                ) : null}
              </div>
            ) : null}
            <div className={styles.submitCtaContainer}>
              <button
                className={cx(globalStyles.cta, globalStyles.primaryCta, styles.submitCta)}
                type="submit"
              >{t`talentCard.submit`}</button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default TalentCardForm;
