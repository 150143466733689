import * as React from "react";
import { createPortal } from "react-dom";

interface Props extends React.PropsWithChildren {
  addWrapper?: boolean;
  content?: string | JSX.Element | null;
}

const ActualTooltip: React.FC<React.PropsWithChildren> = (props) => {
  const { children } = props;
  const rootElem = document.getElementById("root");
  return rootElem ? createPortal(children, rootElem) : null;
};

export const Tooltip: React.FC<Props> = (props: Props) => {
  const { children, content, addWrapper = true } = props;
  const [isVisible, setIsVisible] = React.useState(false);
  const $trigger = React.useRef<HTMLDivElement>(null);
  const $toolip = React.useRef<HTMLDivElement>(null);

  const onMouseEnter = (e: React.MouseEvent) => {
    e.stopPropagation();
    if ($toolip.current) {
      const left = e.pageX + 10;
      const bottom = e.pageY + 10;
      $toolip.current.style.top = "0";
      $toolip.current.style.left = "0";
      $toolip.current.style.transform = `translate(${left}px, ${bottom}px)`;
    }
    setIsVisible(true);
  };
  const onMouseLeave = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsVisible(false);
  };

  const onMouseMove = (e: React.MouseEvent) => {
    e.stopPropagation();
    if ($toolip.current) {
      const left = e.pageX + 10;
      const bottom = e.pageY + 10;
      $toolip.current.style.top = "0";
      $toolip.current.style.left = "0";
      $toolip.current.style.transform = `translate(${left}px, ${bottom}px)`;
    }
  };

  if (!addWrapper) {
    return (
      <>
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            const newProps = {
              className: child.props.className,
              ref: $trigger,
            };
            return React.cloneElement(child, newProps, child.props.children);
          }
        })}
      </>
    );
  }

  return (
    <>
      <div ref={$trigger} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onMouseMove={onMouseMove}>
        {children}
      </div>
      {isVisible && content ? (
        <ActualTooltip>
          <div ref={$toolip} style={{ position: "absolute", pointerEvents: "none", zIndex: 1 }}>
            {content}
          </div>
        </ActualTooltip>
      ) : null}
    </>
  );
};
