import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import { usePrevious } from "../Utils/hook";
import { FORMATS } from "./common/constants";
import DataExportContext from "./common/DataExportContext";
import { FormatType, DataExportContextProps, DataExportContextState } from "./common/types";
import DataExportView from "./DataExportView";

export interface Props<T> {
  initialValues: T;
  formats?: FormatType[];
  refreshOnInitialValuesUpdate?: boolean;
  onSave?(values: Partial<T>): void;
}

function DataExport<T>(props: Props<T>) {
  const { initialValues, refreshOnInitialValuesUpdate, formats } = props;
  const prevInitialValues = usePrevious(initialValues);

  const allowedFormats: FormatType[] = formats || FORMATS;
  const [state, setState] = useState<DataExportContextState<T>>({
    allowedFormats,
    format: allowedFormats[0],
    initialValues,
    values: initialValues,
  });
  const prevState = usePrevious(state);

  const onValuesChange = (newValues: T) => setState({ ...state, values: newValues });
  const onFormatChange = (newFormat: FormatType) => setState({ ...state, format: newFormat });
  const resetValues = () => setState({ ...state, values: state.initialValues });

  useEffect(() => {
    if (refreshOnInitialValuesUpdate && !!prevInitialValues && initialValues !== prevInitialValues) {
      setState({ ...state, values: initialValues, initialValues });
    }
  }, [state, initialValues, refreshOnInitialValuesUpdate, setState, prevInitialValues]);

  function exportData<K extends BlobPart[]>(data: K, type: string, name?: string) {
    const blob = new Blob(data, { type });
    const fileName = name || `${Date.now()}_data-export.${state.format}`;

    saveAs(blob, fileName);
  }

  const contextState: DataExportContextProps<T> = {
    exportData,
    onFormatChange,
    onValuesChange,
    prevState,
    resetValues,
    state,
  };

  return (
    <DataExportContext.Provider value={contextState}>
      <DataExportView />
    </DataExportContext.Provider>
  );
}

export default DataExport;
