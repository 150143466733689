import { t } from "@lingui/macro";
import { Field, Formik } from "formik";
import { useEffect, useState } from "react";
import * as React from "react";
import { useDispatch } from "react-redux";
import { Button, Form, Icon, Message } from "semantic-ui-react";

import { ApiError } from "../../../interfaces/api/error";
import { ApiRequest } from "../../../interfaces/api/request";
import { ApiSuccess } from "../../../interfaces/api/success";
import { Violation } from "../../../interfaces/api/violation";
import { Contact } from "../../../interfaces/resources/contact";
import { createContactAction, editContactAction } from "../../../services/api/contact/actions";

import Yup from "../../App/Yup";
import Input from "../../Form/Formik/Input";
import { Phone } from "../../Form/Formik/Phone";
import { useApiSelector, usePrevious } from "../../Utils/hook";

const CONTACT_FORM = "contact";

const ContactFormSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  firstname: Yup.string().required(),
  lastname: Yup.string().required(),
  phone: Yup.string().nullable(),
  role: Yup.string(),
});

interface Props {
  closeModal: () => void;
  contact?: Contact;
}

export const ContactForm: React.FC<Props> = ({ closeModal, contact }: Props) => {
  const [error, setError] = useState<boolean | string>(false);
  const [errors, setErrors] = useState<Violation[]>([]);
  const [success, setSuccess] = useState(false);

  const { apiErrors, apiPendingRequests, apiSuccess } = useApiSelector();
  const dispatch = useDispatch();

  const submitContact = (value: Partial<Contact>) =>
    dispatch(contact ? editContactAction(value, "contact") : createContactAction(value, "contact"));

  const prevApiErrors = usePrevious(apiErrors);
  const prevApiSuccess = usePrevious(apiSuccess);

  useEffect(() => {
    if (
      (!prevApiErrors || !prevApiErrors.some((e: ApiError) => CONTACT_FORM === e.form))
      && apiErrors
      && apiErrors.some((e: ApiError) => CONTACT_FORM === e.form)
    ) {
      const apiError = apiErrors.find((e: ApiError) => CONTACT_FORM === e.form);
      if (apiError) {
        setError(apiError.payload.message);
        setErrors(apiError.payload.violations || []);
        setSuccess(false);
      }
    }
  }, [apiErrors, prevApiErrors, setError, setErrors, setSuccess]);

  useEffect(() => {
    if (
      prevApiSuccess
      && !prevApiSuccess.some((e: ApiSuccess) => CONTACT_FORM === e.form)
      && apiSuccess
      && apiSuccess.some((e: ApiSuccess) => CONTACT_FORM === e.form)
    ) {
      setError(false);
      setErrors([]);
      setSuccess(true);
      setTimeout(closeModal, 1000);
    }
  }, [apiSuccess, prevApiSuccess, closeModal, setError, setErrors, setSuccess]);

  return (
    <div className="contactForm">
      <h2>
        <Icon.Group size="large">
          <Icon circular inverted />
          <Icon name="user plus" size="small" />
        </Icon.Group>
        {contact ? t`ContactForm.edit.title` : t`ContactForm.create.title`}
      </h2>
      <Formik
        onSubmit={(values: any, { setSubmitting }): void => {
          submitContact(values);
          setTimeout(() => setSubmitting(false), 500);
        }}
        validationSchema={ContactFormSchema}
        initialValues={
          contact
            ? contact
            : {
                email: "",
                firstname: "",
                lastname: "",
                phone: "",
                role: "",
              }
        }
      >
        {({ handleSubmit, isSubmitting }): JSX.Element => (
          <Form
            error={!!error || errors.length > 0}
            loading={apiPendingRequests.some((e: ApiRequest) => CONTACT_FORM === e.form)}
            onSubmit={handleSubmit}
            success={success}
          >
            <Message
              content={t`ContactForm.success.content`}
              header={t`ContactForm.success.header`}
              icon="check circle"
              success
            />
            <Message
              content={
                <div>
                  {t`ContactForm.error.content`}: {error}
                </div>
              }
              header={t`ContactForm.error.header`}
              icon="times circle"
              error
            />
            <Form.Group>
              <Field
                component={Input}
                label={t`contact.firstname`}
                name="firstname"
                placeholder={t`contact.firstname.placeholder`}
                violation={errors}
              />
              <Field
                component={Input}
                label={t`contact.lastname`}
                name="lastname"
                placeholder={t`contact.lastname.placeholder`}
                violation={errors}
              />
            </Form.Group>
            <Form.Group>
              <Field
                component={Input}
                label={t`contact.email`}
                name="email"
                placeholder={t`contact.email.placeholder`}
                violation={errors}
              />
              <Field
                component={Phone}
                label={t`contact.phone`}
                name="phone"
                placeholder={t`contact.phone.placeholder`}
                violation={errors}
                isRequired={false}
              />
            </Form.Group>
            <Field
              component={Input}
              label={t`contact.role`}
              name="role"
              placeholder={t`contact.role.placeholder`}
              violation={errors}
              isRequired={false}
            />
            <Button
              content={contact ? t`ContactForm.edit.submit` : t`ContactForm.create.submit`}
              disabled={isSubmitting}
              primary
              type="submit"
            />
            <Button content={t`Constant.cancel`} type="button" onClick={closeModal} />
          </Form>
        )}
      </Formik>
    </div>
  );
};
