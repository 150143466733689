import { call, put, takeLatest } from "redux-saga/effects";

import { GetHuntTalents } from "../../../actionTypes/hunt";

import { Application, applicationStatuses } from "../../../interfaces/resources/application";
import { Talent } from "../../../interfaces/resources/talent";
import * as applicationApiClient from "../applications/api";

export function* getHuntTalents(action: any): any {
  try {
    const response = yield call(applicationApiClient.getApplications, action.filterContext);
    const talentsToContact: Talent[] = [];
    const contactedTalents: Talent[] = [];
    response.data["hydra:member"].forEach((item: Application) => {
      if (item.status === applicationStatuses.toContact) {
        let talent = talentsToContact.find((e: Talent) => e["@id"] === item.talent["@id"]);
        if (talent) {
          talent.applications.push(item);
        } else {
          talent = item.talent;
          talent.applications = [item];
          talentsToContact.push(talent);
        }
      }
      if (item.status === applicationStatuses.contacted) {
        const talent = item.talent;
        talent.applications = [item];
        contactedTalents.push(talent);
      }
    });
    yield put({
      contactedTalents,
      pageType: action.pageType,
      talentsToContact,
      type: GetHuntTalents.SUCCESS,
    });
  } catch (e: any) {
    yield put({
      payload: {
        message: (e.response && e.response.data && e.response.data.message) || e.response.data["hydra:description"],
        status: e.response && e.response.status,
      },
      type: GetHuntTalents.FAILURE,
    });
  }
}

export default function* huntSaga() {
  yield takeLatest(GetHuntTalents.REQUEST, getHuntTalents);
}
